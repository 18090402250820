// Portal = whether or not the portal has translations for the given language

export default [

    { portal: true, code: 'en', name: 'english' },
    { portal: false, code: 'aa', name: 'afar' },
    { portal: false, code: 'ab', name: 'abkhazian' },
    { portal: false, code: 'ae', name: 'avestan' },
    { portal: false, code: 'af', name: 'afrikaans' },
    { portal: false, code: 'ak', name: 'akan' },
    { portal: false, code: 'am', name: 'amharic' },
    { portal: false, code: 'an', name: 'aragonese' },
    { portal: false, code: 'ar', name: 'arabic' },
    { portal: false, code: 'as', name: 'assamese' },
    { portal: false, code: 'av', name: 'avaric' },
    { portal: false, code: 'ay', name: 'aymara' },
    { portal: false, code: 'az', name: 'azerbaijani' },
    { portal: false, code: 'ba', name: 'bashkir' },
    { portal: false, code: 'be', name: 'belarusian' },
    { portal: false, code: 'bg', name: 'bulgarian' },
    { portal: false, code: 'bh', name: 'bihari languages' },
    { portal: false, code: 'bi', name: 'bislama' },
    { portal: false, code: 'bm', name: 'bambara' },
    { portal: false, code: 'bn', name: 'bengali' },
    { portal: false, code: 'bo', name: 'tibetan' },
    { portal: false, code: 'br', name: 'breton' },
    { portal: false, code: 'bs', name: 'bosnian' },
    { portal: false, code: 'ca', name: 'catalan' },
    { portal: false, code: 'ce', name: 'chechen' },
    { portal: false, code: 'ch', name: 'chamorro' },
    { portal: false, code: 'co', name: 'corsican' },
    { portal: false, code: 'cr', name: 'cree' },
    { portal: false, code: 'cs', name: 'czech' },
    { portal: false, code: 'cu', name: 'old Church Slavonic' },
    { portal: false, code: 'cv', name: 'chuvash' },
    { portal: false, code: 'cy', name: 'welsh' },
    { portal: false, code: 'da', name: 'danish' },
    { portal: true, code: 'de', name: 'german' },
    { portal: false, code: 'dv', name: 'divehi' },
    { portal: false, code: 'dz', name: 'dzongkha' },
    { portal: false, code: 'ee', name: 'ewe' },
    { portal: false, code: 'el', name: 'greek' },
    { portal: false, code: 'eo', name: 'esperanto' },
    { portal: true, code: 'es', name: 'spanish' },
    { portal: true, code: 'et', name: 'estonian' },
    { portal: false, code: 'eu', name: 'basque' },
    { portal: false, code: 'fa', name: 'persian' },
    { portal: false, code: 'ff', name: 'fulah' },
    { portal: false, code: 'fi', name: 'finnish' },
    { portal: false, code: 'fj', name: 'fijian' },
    { portal: false, code: 'fo', name: 'faroese' },
    { portal: true, code: 'fr', name: 'french' },
    { portal: false, code: 'fy', name: 'western frisian' },
    { portal: false, code: 'ga', name: 'irish' },
    { portal: false, code: 'gd', name: 'gaelic' },
    { portal: false, code: 'gl', name: 'galician' },
    { portal: false, code: 'gn', name: 'guarani' },
    { portal: false, code: 'gu', name: 'gujarati' },
    { portal: false, code: 'gv', name: 'manx' },
    { portal: false, code: 'ha', name: 'hausa' },
    { portal: false, code: 'he', name: 'hebrew' },
    { portal: false, code: 'hi', name: 'hindi' },
    { portal: false, code: 'ho', name: 'hiri motu' },
    { portal: false, code: 'hr', name: 'croatian' },
    { portal: false, code: 'ht', name: 'haitian' },
    { portal: false, code: 'hu', name: 'hungarian' },
    { portal: false, code: 'hy', name: 'armenian' },
    { portal: false, code: 'hz', name: 'herero' },
    { portal: false, code: 'ia', name: 'interlingua' },
    { portal: false, code: 'id', name: 'indonesian' },
    { portal: false, code: 'ie', name: 'interlingue' },
    { portal: false, code: 'ig', name: 'igbo' },
    { portal: false, code: 'ii', name: 'sichuan yi' },
    { portal: false, code: 'ik', name: 'inupiaq' },
    { portal: false, code: 'io', name: 'ido' },
    { portal: false, code: 'is', name: 'icelandic' },
    { portal: false, code: 'it', name: 'italian' },
    { portal: false, code: 'iu', name: 'inuktitut' },
    { portal: false, code: 'ja', name: 'japanese' },
    { portal: false, code: 'jv', name: 'javanese' },
    { portal: false, code: 'ka', name: 'georgian' },
    { portal: false, code: 'kg', name: 'kongo' },
    { portal: false, code: 'ki', name: 'kikuyu' },
    { portal: false, code: 'kj', name: 'kuanyama' },
    { portal: false, code: 'kk', name: 'kazakh' },
    { portal: false, code: 'kl', name: 'kalaallisut' },
    { portal: false, code: 'km', name: 'central khmer' },
    { portal: false, code: 'kn', name: 'kannada' },
    { portal: false, code: 'ko', name: 'korean' },
    { portal: false, code: 'kr', name: 'kanuri' },
    { portal: false, code: 'ks', name: 'kashmiri' },
    { portal: false, code: 'ku', name: 'kurdish' },
    { portal: false, code: 'kv', name: 'komi' },
    { portal: false, code: 'kw', name: 'cornish' },
    { portal: false, code: 'ky', name: 'kirghiz' },
    { portal: false, code: 'la', name: 'latin' },
    { portal: false, code: 'lb', name: 'luxembourgish' },
    { portal: false, code: 'lg', name: 'ganda' },
    { portal: false, code: 'li', name: 'limburgan' },
    { portal: false, code: 'ln', name: 'lingala' },
    { portal: false, code: 'lo', name: 'lao' },
    { portal: false, code: 'lt', name: 'lithuanian' },
    { portal: false, code: 'lu', name: 'luba-katanga' },
    { portal: false, code: 'lv', name: 'latvian' },
    { portal: false, code: 'mg', name: 'malagasy' },
    { portal: false, code: 'mh', name: 'marshallese' },
    { portal: false, code: 'mi', name: 'maori' },
    { portal: false, code: 'mk', name: 'macedonian' },
    { portal: false, code: 'ml', name: 'malayalam' },
    { portal: false, code: 'mn', name: 'mongolian' },
    { portal: false, code: 'mr', name: 'marathi' },
    { portal: false, code: 'ms', name: 'malay' },
    { portal: false, code: 'mt', name: 'maltese' },
    { portal: false, code: 'my', name: 'burmese' },
    { portal: false, code: 'na', name: 'nauru' },
    { portal: false, code: 'nd', name: 'north ndebele' },
    { portal: false, code: 'ne', name: 'nepali' },
    { portal: false, code: 'ng', name: 'ndonga' },
    { portal: false, code: 'nl', name: 'dutch' },
    { portal: true, code: 'no', name: 'norwegian' },
    { portal: false, code: 'nr', name: 'south ndebele' },
    { portal: false, code: 'nv', name: 'navajo' },
    { portal: false, code: 'ny', name: 'chichewa' },
    { portal: false, code: 'oc', name: 'occitan' },
    { portal: false, code: 'oj', name: 'ojibwa' },
    { portal: false, code: 'om', name: 'oromo' },
    { portal: false, code: 'or', name: 'oriya' },
    { portal: false, code: 'os', name: 'ossetian' },
    { portal: false, code: 'pa', name: 'panjabi' },
    { portal: false, code: 'pi', name: 'pali' },
    { portal: false, code: 'pl', name: 'polish' },
    { portal: false, code: 'ps', name: 'pushto' },
    { portal: false, code: 'pt', name: 'portuguese' },
    { portal: false, code: 'qu', name: 'quechua' },
    { portal: false, code: 'rm', name: 'romansh' },
    { portal: false, code: 'rn', name: 'rundi' },
    { portal: false, code: 'ro', name: 'romanian' },
    { portal: false, code: 'ru', name: 'russian' },
    { portal: false, code: 'rw', name: 'kinyarwanda' },
    { portal: false, code: 'sa', name: 'sanskrit' },
    { portal: false, code: 'sc', name: 'sardinian' },
    { portal: false, code: 'sd', name: 'sindhi' },
    { portal: false, code: 'se', name: 'swedish' },
    { portal: false, code: 'sg', name: 'sango' },
    { portal: false, code: 'si', name: 'sinhala' },
    { portal: false, code: 'sk', name: 'slovak' },
    { portal: false, code: 'sl', name: 'slovenian' },
    { portal: false, code: 'sm', name: 'samoan' },
    { portal: false, code: 'sn', name: 'shona' },
    { portal: false, code: 'so', name: 'somali' },
    { portal: false, code: 'sq', name: 'albanian' },
    { portal: false, code: 'sr', name: 'serbian' },
    { portal: false, code: 'ss', name: 'swati' },
    { portal: false, code: 'st', name: 'sotho' },
    { portal: false, code: 'su', name: 'sundanese' },
    { portal: false, code: 'sw', name: 'swahili' },
    { portal: false, code: 'ta', name: 'tamil' },
    { portal: false, code: 'te', name: 'telugu' },
    { portal: false, code: 'tg', name: 'tajik' },
    { portal: false, code: 'th', name: 'thai' },
    { portal: false, code: 'ti', name: 'tigrinya' },
    { portal: false, code: 'tk', name: 'turkmen' },
    { portal: false, code: 'tl', name: 'tagalog' },
    { portal: false, code: 'tn', name: 'tswana' },
    { portal: false, code: 'to', name: 'tonga' },
    { portal: false, code: 'tr', name: 'turkish' },
    { portal: false, code: 'ts', name: 'tsonga' },
    { portal: false, code: 'tt', name: 'tatar' },
    { portal: false, code: 'tw', name: 'twi' },
    { portal: false, code: 'ty', name: 'tahitian' },
    { portal: false, code: 'ug', name: 'uighur' },
    { portal: false, code: 'uk', name: 'ukrainian' },
    { portal: false, code: 'ur', name: 'urdu' },
    { portal: false, code: 'uz', name: 'uzbek' },
    { portal: false, code: 've', name: 'venda' },
    { portal: false, code: 'vi', name: 'vietnamese' },
    { portal: false, code: 'vo', name: 'volapük' },
    { portal: false, code: 'wa', name: 'walloon' },
    { portal: false, code: 'wo', name: 'wolof' },
    { portal: false, code: 'xh', name: 'xhosa' },
    { portal: false, code: 'yi', name: 'yiddish' },
    { portal: false, code: 'yo', name: 'yoruba' },
    { portal: false, code: 'za', name: 'zhuang' },
    { portal: false, code: 'zh', name: 'chinese' },
    { portal: false, code: 'zu', name: 'zul' },
    { portal: false, code: 'zh-tw', name: 'taiwanese' },
];