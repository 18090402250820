<template>
    <div>
        <v-list-item
                style="cursor: pointer;"
                class="root-item"
                @click.stop="pushFolderRoute('root-shared-folder', {isShared: true})"
                @contextmenu.prevent=""
        >
            <v-icon :color="(folderId === 'root-shared-folder') ? 'accent' : ''">mdi-folder-lock</v-icon>
            <span>{{ $t('sharingContext.rootFolderPrefix') + ' ' + rootFolderLabel }}</span>
        </v-list-item>

        <v-treeview
                dense
                :items="folderTree"
                item-text="nameTranslated"
                item-key="id"
                rounded
                :open="openFolders"
                v-if="folderTree.length > 0"
                @update:open="openFoldersChange"
        >
            <template v-slot:label="{item}">
                <v-list-item
                        dense
                        style="cursor: pointer;"
                        @click.stop="pushFolderRoute(item.id, item)"
                        @contextmenu.prevent=""
                >
                    <v-icon :color="item.id === folderId ? 'accent' : ''">mdi-folder-lock</v-icon>
                    <span>&nbsp;{{ item.nameTranslated }}</span>
                </v-list-item>
            </template>
        </v-treeview>
    </div>
</template>

<script>
export default {
    name: "FolderStructureSharedContentFolders",

    props: {
        folderModule: {
            required: true,
            type: String
        },
        folderId: {
            type: String
        },
        rootFolderLabel: {
            required: true,
            type: String
        },
        itemModules: {
            required: false,
            type: Array
        }
    },
    data() {
        return {
            openFolders: [],
        }
    },
    computed: {
        folderModuleTranslated() {
            return this.$store.getters[this.folderModule + '/translated'];
        },

        folderTree() {
            const tree = [];
            const groupedByFromContextName = {};

            this.folderModuleTranslated.forEach(i => {
                if (i.shared) {
                    const fromContextName = i.fromContextName || 'Content Provider';
                    const contextId = i.contextId || 'unknown';

                    if (i.fromContextName === null) {
                        if (i.parent === null || i.parent === 'root-shared-folder') {
                            tree.push({
                                nameTranslated: i.nameTranslated,
                                id: i.id,
                                children: this.findChildrenForFolder(i),
                                isShared: i.shared
                            });
                        }
                        return;
                    }

                    if (!groupedByFromContextName[contextId]) {
                        groupedByFromContextName[contextId] = {
                            id: `contextId-${contextId}`,
                            nameTranslated: fromContextName,
                            children: [],
                            isShared: i.shared
                        };
                    }
                    let used;

                    for (const key in groupedByFromContextName) {
                        if (groupedByFromContextName.hasOwnProperty(key)) {
                            used = this.containsObjectWithId(groupedByFromContextName[key].children, i.id);
                        }
                    }

                    if (!used && i.parent === null || i.parent === 'root-shared-folder') {
                        groupedByFromContextName[contextId].children.push({
                            nameTranslated: i.nameTranslated,
                            id: i.id,
                            children: this.findChildrenForFolder(i),
                            isShared: i.shared
                        });
                    } else if (!used && i.parent) {
                        const parentFolder = this.folderModuleTranslated.find(folder => folder.id === i.parent);
                        if (parentFolder) {
                            const parentContextId = parentFolder.contextId || 'unknown';
                            if (!groupedByFromContextName[parentContextId]) {
                                groupedByFromContextName[parentContextId] = {
                                    id: `contextId-${parentContextId}`,
                                    nameTranslated: parentFolder.fromContextName || 'Resource Provider',
                                    children: [],
                                    isShared: i.shared
                                };
                            }
                            groupedByFromContextName[parentContextId].children.push({
                                nameTranslated: i.nameTranslated,
                                id: i.id,
                                children: this.findChildrenForFolder(i),
                                isShared: i.shared
                            });
                        }
                    }
                }

            });

            Object.values(groupedByFromContextName).forEach(folder => {
                tree.push(folder);
            });

            this.itemModules.forEach(m => {
                const moduleState = this.$store.state[m];
                if (this.$validator.isEmpty(moduleState)) {
                    console.error(`Missing CRUD module for ${m}`)
                } else {
                    this.$store.state[m].items.forEach(i => {
                        if (i.shared && i.fromContextName !== null) {
                            const contextId = i.contextId || 'unknown';
                            const fromContextName = i.fromContextName || 'Content Provider';

                            if (!groupedByFromContextName[contextId]) {
                                groupedByFromContextName[contextId] = {
                                    id: `contextId-${contextId}`,
                                    nameTranslated: fromContextName,
                                    children: [],
                                    isShared: i.shared
                                };
                                tree.push(groupedByFromContextName[contextId]);
                            }
                        }
                    })
                }
            });

            Array.sort(tree, 'nameTranslated');

            return tree;
        }
    },
    methods: {
        containsObjectWithId(children, id) {
            // Loop through each object in the list
            for (const obj of children) {
                // Check if the current object has the desired id
                if (obj.id === id) {
                    return true;
                }

                // If the object has children, recursively check the children
                if (obj.children && this.containsObjectWithId(obj.children, id)) {
                    return true;
                }
            }
            return false;
        },
        pushFolderRoute(folderId, item) {
            const contextId = item && item.contextId ? item.contextId : null;
            const isShared = item && item.isShared ? item.isShared : false;
            this.$emit("push-folder-route", folderId, contextId, isShared);
        },
        openFoldersChange(open) {
            this.openFolders = open;
        },
        findChildrenForFolder(f) {
            const subFolders = this.$store.getters[this.folderModule + '/translated'].filter(i => i.parent === f.id);
            const children = [];

            subFolders.forEach(c => {
                children.push({
                    id: c.id,
                    nameTranslated: c.nameTranslated,
                    children: this.findChildrenForFolder(c)
                });
            });
            Array.sort(children, 'nameTranslated');
            return children;
        }
    }
}
</script>

<style scoped>

</style>