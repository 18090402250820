<template>
    <v-main>
        <router-view
            class="mirage-main-view"
            :style='$app.portalThemeActive($app.portalThemes.THEME_PPDS) ? "height: " + mainViewHeightStyle + "px" : ""'
            :key="$route.path"/>
    </v-main>
</template>

<script>
export default {
    name: 'TheMainView',
    data() {
        return {
            windowHeight: window.innerHeight
        }
    },
    computed: {
        mainViewHeightStyle() {
            return (this.windowHeight - 100);
        }
    }
}
</script>

<style lang="scss">
    .tablet {
        .v-main {
            padding-left: 240px !important;
        }
    }

    div[id^=__lpform_] {
        display: none;
    }
</style>
