/* Spanish translations */
export default {
	"generic": {
		"set": "Definir",
		"onboard": "Incorporar",
		"view": "Ver",
		"open": "Abrir",
		"days": "días",
		"minutes": "minutos",
		"log": "Registro",
		"event": "Evento",
		"eventReason": "Motivo",
		"occuredAt": "Se produjo a las",
		"lastKeepalive": "Últimas keepalive",
		"allocated": "Asignados",
		"active": "Activo",
		"inactive": "Inactivo",
		"firmware": "Firmware",
		"from": "De",
		"to": "Para",
		"ipValidationErrorMsg": "Debe contener una dirección IP válida",
		"networks": "Redes",
		"ipAddress": "Dirección IP",
		"host": "Host",
		"total": "Total",
		"add": "Añadir ",
		"all": "Todos",
		"addWithType": "Añadir {type} | Añadir {type}s",
		"agent": "Agente | Agentes",
		"signageScreen": "Pantalla de señalización",
		"agentStatus": "Estado de agente",
		"delete": "Eliminar ",
		"deleteAll": "Eliminar todo ",
		"deleteWithType": "Eliminar {type} | Eliminar {amount} {type}s",
		"deleteConfirm": "¿Está seguro de que desea borrar esto? {type}? | ¿Está seguro de que desea eliminar estos {amount} {type}s?",
		"deleteConfirmWithIdentifier": " ¿Está seguro de que desea eliminar {type} {id}?",
		"deleted": "{type} eliminado con éxito | {type}s eliminados con éxito ",
		"deleteFailed": "{type} no se pudo eliminar | {type}s no se pudieron eliminar ",
		"deletedWithIdentifier": "{id} eliminado con éxito ",
		"deletedWithTypeAndIdentifier": "{type} {id} eliminado ",
		"deleteUserConfirmation": "Confirmo que quiero eliminar {type}. Reconozco que esta operación es definitiva y la {type} no se puede recuperar.| Confirmo que deseo eliminar estos {amount} {type}s. I acknowledge that this operation is final and the {type}s no se pueden recuperar.",
		"fetchFailed": "Error en la búsqueda de {itemName}",
		"imported": "{cnt} {type} importado con éxito | {cnt} {type}s importados con éxito ",
		"saved": "{type} guardado satisfactoriamente | {type}s guardados satisfactoriamente ",
		"updated": "{type} actualizado atisfactoriamente | {type}s actualizados atisfactoriamente ",
		"created": "{type} creado satisfactoriamente | {type}s creados satisfactoriamente ",
		"selectType": " Seleccione {type}",
		"manageType": "Administrar {type}",
		"itemReverted": "Elemento revertido | Elementos revertidos ",
		"valueDuplicate": "{value} ya existe ",
		"remove": "Eliminar",
		"disable": "Desactivar",
		"disabled": "Desactivado",
		"download": "Descargar ",
		"enable": "Habilitar ",
		"enabled": "Habilitado",
		"systemDefault": " Sistema por defecto ",
		"removeWithType": "Eliminar {type} | Eliminar {type}s",
		"removeConfirm": "¿Está seguro de que desea eliminar este {type}? | ¿Está seguro de que desea eliminar estos {type}s?",
		"removeConfirmWithTarget": " ¿Está seguro de que desea eliminar {type} de este {target}? | ¿Está seguro de que desea eliminar estos {type}s de estos {target}?",
		"group": "Grupo | Grupos",
		"rename": "renombrar",
		"renameWithType": "renombrar {type}",
		"renamedTo": "{type} renombrar a  {name}",
		"create": "Crear",
		"createToolTip": "Crear sin cerrar",
		"createAndClose": "Crear y cerrar ",
		"createWithType": "Crear {type}",
		"createNew": "Crear nuevo ",
		"createNewWithType": "Crear nuevo {type} | Crear nuevos {type}s",
		"takeEffectImmediatly": " Esto entrará en vigor inmediatamente ",
		"newWithType": "Nuevo {type} | Nuevos {type}s",
		"actions": "Acciones ",
		"yes": "Sí",
		"no": "No",
		"none": "Ninguno",
		"move": "Mover",
		"loading": "Cargando",
		"unknown": "Desconocido",
		"typeToSearch": "Empezar a escribir para buscar",
		"enterSearchTerm": "Introducir término de búsqueda",
		"noSearchMatches": "No hay coincidencias en la búsqueda",
		"cancel": "Cancelar",
		"close": "Cerrar",
		"gotIt": "Entendido",
		"required": "Requerido",
		"numberRequired": "Número requerido",
		"fieldRequired": "Campo requerido",
		"inputRequired": "Entrada requerida",
		"integration": "Integración | Integraciones",
		"selectionRequired": "Selección requerida",
		"nameRequired": "Nombre requerido",
		"inputLongerThan": "La entrada debe ser superior a {length} carácter | La entrada debe ser superior a {length} carácteres",
		"countLessThan": "La cifra debe ser de menos de {length}",
		"inputShorterThan": "La entrada debe tener menos de {length} caracteres",
		"nameInvalidFormat": "El nombre solo debe contener letras, números y espacios",
		"madeYet": "No {type} se ha hecho aún | No {type}s se han hecho aún ",
		"noMoreItemsAvailable": "No queda ningún {type} disponible | No quedan más {type} disponibles",
		"searchCriteria": "No {type}s que coincidan con los criterios de búsqueda indicados ",
		"search": " Buscar ",
		"save": "Guardar ",
		"saveAndClose": "Guardar y cerrar ",
		"saveWithType": " Guardar {type} | Guardar {type}s",
		"editItem": "Editar elemento | Editar elementos ",
		"edit": "Edicion | Ediciones ",
		"editWithType": "Editar {type} | Editar {type}s",
		"shareName": "Compartir {type}",
		"action": "Acción | Acciónes ",
		"refresh": "Actualizar ",
		"reset": "Resetear",
		"resetRefresh": "Reiniciar y actualizar {type}",
		"lostChanges": "Todos los cambios se perderán ",
		"noAvailable": "No disponible {type}s",
		"channelTable": "Tabla de canales ",
		"done": "Listo ",
		"name": "Nombre ",
		"nickname": "Apodo",
		"externalId": "Identificación externa",
		"crmRef": "Ref. de CRM",
		"industry": "Industria",
		"filename": "Archivo ",
		"content": "Contenido ",
		"fullscreen": "Pantalla completa ",
		"exitFullscreen": "Salir de pantalla completa ",
		"copy": "Copiar ",
		"copyToClipboard": "Copiar al portapapeles ",
		"copiedToClipboard": "Contenido copiado en el portapapeles ",
		"copyToSite": "Copiar en el sitio ",
		"preview": "Vista previa ",
		"reload": "Recarga ",
		"unsaved": " Los cambios no guardados se perderán ",
		"type": " Tipo | Tipos",
		"text": "Texto",
		"tag": " Etiqueta | Etiquetas",
		"item": "Artículo | Artículos ",
		"title": " Título ",
		"fullTitle": "Título completo ",
		"editor": "Editor",
		"number": "Numbero | Numberos",
		"top": " Arriba ",
		"left": "Izquierda",
		"width": " Ancho",
		"height": "Altura",
		"zIndex": "z-index",
		"field": "Campo | Campos",
		"key": "Clave | Claves",
		"description": "Descripción",
		"setup": " Configuración",
		"status": " Estado | Estados",
		"multiple": "Múltiple",
		"default": "Por defecto",
		"general": "General",
		"configuration": "Configuración",
		"config": "Config",
		"select": "Seleccionar",
		"addAll": "añadir todo",
		"removeAll": "quitar todo",
		"previous": "Anterior",
		"next": "siguiente",
		"finish": "Terminar",
		"nameLonger": "El nombre debe tener más de {length} caracteres",
		"selectAll": " Seleccionar todo",
		"deselectAll": "Deseleccionar todo",
		"code": "Codigo",
		"continue": "Continuar",
		"stay": "Permanecer",
		"back": "volver",
		"language": "Idioma",
		"languageName": "Nombre del Idioma",
		"languageCode": "codigo del Idioma",
		"reseller": "Revendedor | Revendedores",
		"resource": "Recurso | Recursos",
		"override": "Anulación | Anulaciones",
		"insufficientPrivileges": "La cuenta no tiene suficientes privilegios ",
		"theme": " Tema | Temas ",
		"distributor": "Distribuidor | Distribuidores ",
		"domain": "Dominio| Dominios",
		"preregister": "Pre-registro ",
		"pleaseConfirm": "Por favor, confirme ",
		"switchTo": " Cambiar a...",
		"home": "Inicio ",
		"lastLoggedIn": "Última conexión ",
		"summary": " Resumen ",
		"noData": "No hay datos que mostrar...",
		"vendor": " Vendedor | Vendedores ",
		"model": "Modelo | Modelos",
		"user": "Usuario",
		"schedule": " Horario | Horarios ",
		"revert": "Revertir ",
		"revertAllChanges": "¿Revertir todos los cambios desde el último guardado?",
		"revertNoChanges": "No hay cambios que revertir",
		"channels": "Canal | Canales ",
		"deployment": "Despliegue | Despliegue ",
		"filter": "Filtro ",
		"accept": "Aceptar ",
		"api": "API",
		"timeZone": " Zona horaria | Zonas horarias ",
		"register": "Registro ",
		"caseSensitive": "Sensible a las mayúsculas y minúsculas ",
		"optional": "Opcional: ",
		"errorOccurred": "Se ha producido un error ",
		"on": "On",
		"off": "Off",
		"standby": "Modo de espera",
		"idle": "Sin actividad",
		"notSet": " No se ha establecido ",
		"exportCSV": "Exportar CSV ",
		"importCSV": "Importar CSV",
		"success": " Éxito ",
		"uploaded": " Subido",
		"hasUnsavedChangesHeader": "Cambios sin guardar ",
		"hasUnsavedChangesMessage": "Sus cambios se perderán si cierra.<br>Continuar?",
		"defaultErrorMsg": "Mensaje de error predeterminado",
		"notes": "Notas",
		"genericNotes": "Notas genéricas",
		"internalNotes": "Notas internas",
		"whitelabelDomain": "Dominio sin marca",
		"createNewSchedule": "Crear nueva programación",
		"addToSchedule": "Añadir a programación existente",
		"readOnly": "Solo lectura",
		"information": "información",
		"custom": "Personalizado",
		"url": "URL",
		"realm": "Dominio de Keycloak",
		"noResult": "Ningún resultado | Sin resultados",
		"sharingContext": "Proveedor de recursos | Proveedores de recursos",
		"sharingSuccess": "Uso compartido actualizado",
		"sharingError": "No se ha podido actualizar el uso compartido",
		"lastPublished": "Últimos publicados",
		"notPublished": "{0} no está publicado actualmente",
		"itemType": "{0}",
		"shareAll": "Todos",
		"shareSubGroup": "Subgrupo",
		"shareSingle": "Único"
	},
	"validation": {
		"characterMaxLength": "No puede haber más de {value} caracteres",
		"valueHigherThan": "Debe ser un valor mayor que {value}",
		"valueHigherThanOrEqualTo": "Debe ser un valor mayor o igual que {value}",
		"valueLowerOrEqualTo": "Debe ser un valor menor o igual que {value}",
		"powerOnOverrideFrom": "Debe estar antes de \"Anular hasta\"",
		"powerOnOverrideTo": "Debe estar después de \"Anular desde\""
	},
	"help": {
		"getSupportTitle": " Contactar con el servicio de asistencia ",
		"getSiteInformationTitle": "Información del sitio",
		"notAvailable": " No disponible ",
		"openDocumentation": " Documentación abierta ",
		"getSupportBody": "<span style=\"font-weight: bold;\"> Correo electrónico:</span> {email}<br><span style=\"font-weight: bold;\">Telefono:</span> {phone}<br><br>{text}",
		"notAvailableInHomeScreen": " La información de soporte no está disponible en la pantalla de inicio.<br> Por favor, cambie a un contexto de sitio.",
		"documentation": " Documentación ",
		"contactSupport": " Contactar con el servicio de asistencia ",
		"mobileNavigationTip": " Pulse el botón superior izquierdo para abrir el panel de navegación "
	},
	"eula": {
		"updated": {
			"title": "Acuerdo de licencia de usuario final actualizado",
			"text": "<span style='font-size: 16px;font-style: italic;'>Hemos actualizado nuestro Acuerdo de licencia de usuario final, léalo atentamente y acéptelo para continuar</span> <br><br>"
		}
	},
	"welcome": {
		"title": " Bienvenido {user}",
		"languageHeader": "Idioma",
		"languageText": " Bienvenido al portal de gestión de Enterprise Cloud.<br><br> Es el centro principal de la solución de medios de comunicación de la empresa en la nube, el punto de acceso para distribuidores, revendedores, propietarios de sitios y usuarios de sitios.<br><br> Comience por seleccionar su idioma preferido:",
		"homeScreenText": " Dependiendo de su cuenta, se conectará a un \"Site Dashboard\" o un \"Portal Dashboard\" y esta será su futura pantalla de inicio para trabajar con Enterprise Cloud.<br><br><div style=\"font-weight: bold;\"> Panel de control </div> Para los usuarios con acceso estándar a un solo sitio, se les llevará directamente a su propio \"Site Dashboard\" al iniciar la sesión en el sistema.<br> En este punto de partida tienes una visión general de las pantallas de instalación, estadísticas de hospitalidad e información administrativa. nLos elementos específicos del menú, los recursos y las opciones disponibles pueden variar en función del tipo de cuenta, las licencias activas y el nivel de acceso concedido.<br><br><div style=\"font-weight: bold;\">Portal Dashboard</div> Para revendedores, propietarios de sitios y usuarios de sitios con acceso a más de una instalación de Enterprise Cloud, el \"Portal Dashboard\" es el punto de partida inicial en la plataforma.<br> Aquí se gestionan los recursos y se accede a las estadísticas combinadas de todos sus sitios, se crean usuarios, se configuran nuevas instalaciones, se comprueban los detalles de las licencias y muchas otras tareas administrativas.<br> Los elementos de menú y las opciones específicas de esta área administrativa de la plataforma variarán en función del tipo de cuenta, las licencias activas y el nivel de acceso concedido.<br><br> Siempre se puede llegar al \"Portal Dashboard\" seleccionándolo en el menú contextual superior izquierdo desde cualquier página. Desde el mismo menú contextual superior izquierdo también puede acceder a todos sus sitios para trabajar en cada una de las instalaciones disponibles en su cuenta.",
		"siteText": "Si usted es un usuario de un solo sitio, se conectará automáticamente a su sitio una vez que inicie sesión en el sistema. Para los usuarios con acceso a varios sitios, seleccione el contexto del sitio en el menú contextual de la parte superior izquierda.<br>El nivel de acceso que tenga determinará los recursos que están disponibles para usted en el contexto del sitio."
	},
	"language": {
		"english": "Inglés",
		"norwegian": "Noruego",
		"swedish": "Sueco",
		"finnish": "Finlandés",
		"russian": "Ruso",
		"italian": "Italiano",
		"french": "Francés",
		"german": "Alemán",
		"spanish": "Español",
		"estonian": "Estonio",
		"atLeastOne": "Al menos un idioma debe ser rellenado"
	},
	"date": {
		"today": "Hoy",
		"yesterday": "ayer",
		"daysAgo": "Hace {days} días",
		"dateRequired": "Fecha requerida",
		"dateNotSet": "No establecido",
		"timeRequired": "Hora requerida",
		"timeFormat": "La hora debe estar en formato 12:00",
		"amPmTimeFormat": "La hora debe tener el formato h:mm AM/PM",
		"daily": "Diario",
		"weekly": "semanal",
		"time": "Hora",
		"date": "Fecha",
		"timeOfDay": "Hora del dia",
		"monday": "Lunes",
		"tuesday": "Martes",
		"wednesday": "Miercoles",
		"thursday": "Jueves",
		"friday": "Viernes",
		"saturday": "Sabado",
		"sunday": "Domingo",
		"startTime": "Start time",
		"endTime": "Hora de finalización",
		"weekday": "Dia de la Semana",
		"endTimeAfter": "a hora de finalización debe ser posterior a la hora de inicio",
		"duration": "Duracion",
		"inSeconds": "en segundos"
	},
	"router": {
		"agents": "Agentes",
		"contentConfiguration": "Configuración",
		"documents": "Documentos",
		"resource": "Recurso",
		"resources": " Recursos ",
		"hospitality": "Hospitalidad",
		"dashboard": "Panel de control",
		"media": "Media",
		"housekeeping": "Housekeeping",
		"screens": "Pantallas",
		"tv": "TV",
		"playlists": "Listas de reproducción",
		"signage": "Señalización",
		"sites": "Sitios",
		"customersAndLicenses": "Clientes y licencias",
		"sitesAndLicenses": " Sitios y licencias ",
		"externalSystems": "Sistemas externos",
		"reseller": " Revendedor ",
		"resellers": " Revendedores",
		"manageResellers": " Gestionar los revendedores ",
		"distributor": " Distribuidor ",
		"distributors": " Distribuidores",
		"domain": "Dominio",
		"domains": "Dominios",
		"usageReport": " Informe de uso ",
		"accessReport": " Informe de acceso ",
		"resellerUsersAndAccess": " Usuarios y acceso ",
		"distributorUsersAndAccess": " Usuarios y acceso ",
		"resellerThemes": "temas",
		"distributorThemes": "temas",
		"content": "Contenido",
		"admin": "Admin",
		"administration": " Administración ",
		"system": "Sistema",
		"rooms": "salas",
		"virtualRemoteClients": "Sesiones de VRC",
		"housekeepingRooms": "salas",
		"locations": " Ubicaciones ",
		"locationSetup": " Configuración de la ubicación ",
		"guests": " Invitados ",
		"guestMessages": "Mensajes",
		"booking": " Reservar ",
		"wakeups": " Despertadores ",
		"config": "Config",
		"screensConfig": "Config",
		"housekeepingConfig": "Config",
		"streamAllocatorConfig": "Config",
		"configuration": " Configuración ",
		"issues": " Problemas ",
		"scheduledTasks": " Tareas programadas ",
		"screen": " Pantalla ",
		"screenConfigurations": " Configuraciones ",
		"tvChannels": " Canales de TV ",
		"tvScreens": " Pantallas de TV ",
		"tvScreensTheme": "Pantallas de TV",
		"signageScreens": "Pantallas de señalización",
		"signageOverview": "Vista previa",
		"signageSchedules": "Programaciones",
		"signageConfig": "Config",
		"event": "Evento",
		"events": "Eventos",
		"wayfinding": "Indicaciones",
		"wayfindingIcons": "Indicaciones",
		"logos": "Logotipos",
		"integrationLocations": "Integraciones",
		"eventConfig": "Config",
		"schedules": " Horarios ",
		"schedulemapping": " Mapa de horarios ",
		"installCodes": " Instalar códigos ",
		"interactivePages": " Menús interactivos ",
		"templatePages": "Páginas de plantilla",
		"channelTables": "Tablas de canales",
		"mediaChannels": "Canales de medios",
		"templates": "Plantillas",
		"apiTemplates": "Plantillas de API",
		"pages": "Páginas",
		"imageresources": "Imágenes",
		"videoresources": "Vídeos",
		"fontresources": "Fuentes",
		"contexts": "Contextos",
		"sharingContexts": "Proveedores de recursos",
		"accounts": "Cuentas",
		"access": "Acceso",
		"themeImport": "Temas",
		"themes": "Temas",
		"usersAndAccess": "Usuarios y acceso",
		"userAccounts": "Cuentas de usuario",
		"apiAccounts": "Cuentas API",
		"owners": "Propietarios",
		"areas": "Áreas",
		"licenses": "Licencias",
		"products": "Productos",
		"roles": "Roles",
		"roleManagement": "Roles",
		"privilegeSets": "Conjuntos de privilegios",
		"requiredPermissions": "No tiene los permisos necesarios para esta vista",
		"systemConfig": "Configuración del sistema",
		"tvSearch": "Búsqueda de TV",
		"pmsConfiguration": "Configuración del PMS",
		"pmsLogEntries": "Entradas de registro de PMS",
		"resourceSharing": "Compartir",
		"resourcePublishing": "Publicar",
		"publishAndShare": "Publicar y compartir",
		"themePreview": "Vista previa",
		"watermark": "Marca de agua",
		"castManagement": "Administración de difusiones",
		"chromecasts": "Chromecasts",
		"chromecastConfig": "Configuración de Chromecast",
		"unregisteredCasts": "Difusiones desregistradas",
		"activeCastSessions": "Sesiones activas",
		"castConfiguration": "Configuración",
		"castStatus": "Estado",
		"castStatistics": "Estadísticas",
		"castEventLogs": "Registro de eventos",
		"streamAllocator": "Asignador de transmisiones",
		"streamManagement": "Transmisiones",
		"streamAllocatorLog": "Registro del asignador de transmisiones",
		"deletedContexts": "Contextos eliminados",
		"duplicateRegisteredClients": "Clientes registrados duplicados"
	},
	"appbar": {
		"helpAndSupport": "Ayuda y soporte",
		"notifications": "Notificaciones",
		"languages": "Selección de idioma",
		"settings": "Ajustes y utilidades",
		"contextSelector": "Seleccionar sitio",
		"availableSites": "Sitios disponibles",
		"availableCustomers": "Clientes disponibles",
		"availableThemes": "Temas disponibles",
		"searchSites": "Buscar sitios",
		"searchCustomers": "Buscar clientes",
		"searchThemes": "Buscar temas",
		"availableSharingContexts": "Proveedores de recursos disponibles",
		"searchSharingContexts": "Buscar proveedores de recursos"
	},
	"admin": {
		"admin": "Admin",
		"account": "Cuenta | Cuentas",
		"accountRemoved": "Cuenta eliminada con éxito",
		"accountRemoveFailed": "Cuenta no eliminada",
		"accountCreated": "Nueva cuenta añadida",
		"accountSaved": "Datos de acceso guardados",
		"accountType": "Tipo de cuenta",
		"accountEnabled": "Cuenta habilitada",
		"accountAccess": "Acceso a la cuenta",
		"apiAccount": "Cuenta API | Cuentas API",
		"deleteAccount": "Eliminar cuenta",
		"removeAccount": "Eliminar cuenta",
		"removeAccessRights": "Eliminar derechos de acceso",
		"removeAccessRightsInfo": "Eliminar los derechos de acceso de esta cuenta",
		"disableAPIAccount": "Desactivar cuenta API",
		"enableAPIAccount": "Habilitar cuenta de API",
		"addApiInfo": "La nueva cuenta de API se habilitará automáticamente para el acceso",
		"accountApiState": "Esta cuenta de API es actualmente {state}",
		"accountApiStateWithContextId": "Esta cuenta de API está actualmente en {state} para contextId {contextId}",
		"disableApiAccountTitle": "¿Desactivar la cuenta API?",
		"disableApiAccountWarning": "Desactivar una cuenta API hace que el token de acceso se elimine permanentemente del sistema. Puede volver a habilitar la cuenta, pero esto generará un nuevo token",
		"disableApiAccountConfirm": "Por favor, confirme que desea eliminar permanentemente el token de acceso",
		"deleteUserAccountConfirm": "Por favor, confirme que desea eliminar esta cuenta de este",
		"deleteUserAccountTitle": "¿Eliminar la cuenta de usuario?",
		"deleteUserAccountSuccess": "Cuenta de usuario eliminada",
		"deleteApiAccountSuccess": "Cuenta de API eliminada",
		"deleteAccountGenericFailed": "¡No se pudo eliminar la entrada!",
		"removedUserAccessSuccess": "Acceso de usuario eliminado con éxito",
		"removedUserAccessFailed": "No se ha podido eliminar el acceso de usuario",
		"saveAccountFailed": "¡No se pudo guardar la entrada!",
		"accountDataSaved": "Datos de la cuenta guardados",
		"apiTokenWarning": "¡Sólo verá este token una vez! Copie este token",
		"apiTokenTitle": "Token de acceso a la API",
		"customer": "Cliente | Clientes",
		"role": "Rol | Roles",
		"privilegeSet": "Conjunto de privilegios | Conjuntos de privilegios",
		"accountsWithRole": "Cuentas asignadas '{role}'",
		"rolesWithProduct": "Roles con '{product}'",
		"site": "Sitio | Sitios",
		"system": "Sistema | Sistemas",
		"country": "País | Los paises",
		"area": "Area | Areas",
		"brand": "Marca| Marcas",
		"screensLimit": "Límite de pantallas",
		"licensed": "Límite de pantallas",
		"sitesLimit": "Límite de sitios",
		"deleteCustomer": "Eliminar cliente",
		"deleteSite": "Eliminar sitio",
		"deleteLegacyWarningText": "ADVERTENCIA: La eliminación de una referencia del sistema externo es una acción no recuperable.<br>Esta acción no tendrá ningún efecto en el sistema externo, pero la referencia se eliminará y su cuenta ya no tendrá visibilidad sobre los datos de estado de este sistema.<br>NINGUNO DE ESTOS ELEMENTOS PUEDE RECUPERARSE.",
		"deleteSiteWarningText": " ADVERTENCIA: La eliminación de un sitio es una acción no recuperable.<br>La eliminación de un sitio también eliminará todos los datos relacionados, incluyendo: pantallas, páginas de menú, imágenes/fondos, plantillas, documentos, salas, licencias y más.<br>NINGUNO DE ESTOS ELEMENTOS PUEDE SER RECUPERADO",
		"deleteSiteWarningTextPPDS": "ADVERTENCIA: La eliminación de un cliente es una acción no recuperable.<br>Al eliminar un cliente, también se eliminarán todos los datos relacionados, como pantallas, plantillas, imágenes/fuentes, programaciones, etc.<br>NINGUNO DE ESTOS ELEMENTOS PUEDE RECUPERARSE.",
		"deleteSiteConfirmationLabel": "Introduzca \"{title}\" en este campo de texto para permitir la eliminación",
		"copyResources": "Copiar recursos",
		"copyResourcesToSite": "Copiar recursos a {title}",
		"copyResourcesFromTo": "¿Desea copiar todos los recursos del sitio {from} al sitio {to}?",
		"moveSiteFromTo": "¿Desea mover el sitio del revendedor {from} al revendedor {to}?",
		"moveSite": "Mover sitio {title}",
		"provisioned": "Aprovisionado",
		"maxSizeProvisioned": "Max sites provisioned",
		"addReseller": "Añadir revendedor",
		"context": "Contexto | Contextos",
		"contextType": "Tipo de contexto | Tipos de contexto",
		"systemRole": "Rol del sistema | Roles del sistema",
		"owner": "Propietario | Propietarios",
		"license": "Licencias | Licencia | Licencias",
		"totalLicenses": "Total de licencias",
		"feature": "Característica | Características",
		"changeSite": "Cambio de sitio",
		"product": "Producto | Productos",
		"itemId": "ID de elemento",
		"customId": "ID de cliente",
		"access": "Acceso | Accesos",
		"reseller": "Revendedores | Revendedores | Revendedores",
		"noResellerAccess": "No tiene acceso a ningún revendedor",
		"noDistributorAccess": "No tiene acceso a ningún distribuidor",
		"distributor": "Distribuidores | Distribuidor | Distribuidores",
		"domain": "Dominios | Dominio | Dominios",
		"resource": "Recurso | Recursos",
		"accountName": "Nombre de la cuenta",
		"displayName": "Nombre para mostrar",
		"email": "Correo electrónico",
		"phone": "Teléfono",
		"formDisabledSSO": "Se ha deshabilitado la configuración de cuenta de usuario porque su sesión actual se ha iniciado a través de un proveedor de inicio de sesión único externo.",
		"created": "Creado",
		"password": "Contraseña",
		"repeatPassword": "Repetir contraseña",
		"passwordHint": "La contraseña debe contener al menos 8 caracteres, una combinación de letras minúsculas y mayúsculas, cifras y caracteres especiales.",
		"addNewAccess": "Añadir nuevo acceso",
		"newAccount": "Nueva cuenta de usuario",
		"addAccount": "Añadir cuenta de {tipo}",
		"newApiAccount": "Nueva cuenta API",
		"editApiAccount": "Editar cuenta API",
		"accessFetchFailed": "Fallo en la obtención del acceso a la cuenta",
		"accessUpdateFailed": "No se ha podido configurar el acceso de cuenta",
		"unknownAccess": "Acceso desconocido",
		"accessToFeatures": "Acceso a la función {features} | Acceso a la función {features}",
		"passwordLength": "La contraseña debe tener 8 caracteres",
		"passwordMatch": "Las contraseñas deben coincidir",
		"contextAccess": "Acceso contextual",
		"noFeaturesAccess": "No se han añadido funciones a este acceso",
		"removeAccess": "Eliminar el acceso",
		"home": "Inicio",
		"read": "Leer",
		"create": "Crear",
		"update": "Actualizar",
		"delete": "Eliminar",
		"oneAccessRelation": "Se requiere al menos una relación de acceso",
		"editLicense": "Editar licencia",
		"contractRef": "Ref. de contrato",
		"activationDate": "Fecha de activación",
		"expirationDate": "Fecha de caducidad",
		"noFeaturesSelected": "Todavía no se ha seleccionado ninguna función",
		"availableFeatures": "Funciones disponibles",
		"expirationAfter": "La fecha de caducidad debe ser posterior a la fecha de activación",
		"newLicense": "Nueva licencia",
		"userAccount": "Cuenta de usuario",
		"userAdminError": "No se ha podido añadir el derecho de administrador del usuario",
		"createUserAccount": "Crear una nueva cuenta de usuario",
		"noAvailableUsers": "No hay cuentas de usuario disponibles",
		"selectUser": "Seleccione una cuenta de usuario",
		"selectUserHelp": "Selecciona un usuario aquí si quieres darle acceso de administrador al contexto seleccionado y a las funciones de esta nueva licencia.<br><br>Si este contexto se entrega a un socio para que lo mantenga y lo configure, puedes crear un nuevo usuario administrador aquí.<br><br>También puedes seleccionar tu propio usuario, si quieres tener todos los derechos de administrador para este nuevo contexto.<br><br>O puedes dejar el campo vacío para saltarte este paso por completo.",
		"createNewContext": "Crear un nuevo contexto",
		"noAvailableContexts": "No hay contextos disponibles",
		"selectContext": "Seleccione un contexto",
		"selectContextHelp": "Un contexto puede ser una instancia de Enterprise CTRL o un espacio de trabajo para un gestor de recursos, y es necesario para una licencia.<br><br>Por favor, seleccione o cree uno ahora",
		"createNewSite": "Crear un nuevo sitio",
		"noAvailableSites": "No hay sitios disponibles",
		"selectSite": "Seleccione un sitio",
		"selectSiteHelp": "Un sitio es una ubicación física en la que existe un contexto - un hotel, por ejemplo.<br><br>En este paso puede seleccionar o crear un sitio para utilizar esta nueva licencia.",
		"deploymentInfo": "Un despliegue empresarial estándar con un sitio perteneciente",
		"managedBy": "Gestionado por",
		"deployedAt": "Desplegado en",
		"locatedIn": "Ubicado en",
		"belongsTo": "Pertenece a",
		"streetAddress": "Dirección",
		"postalCode": "Código postal",
		"contactName": "Nombre de contacto",
		"contactEmail": "Correo electrónico de contacto",
		"contactPhone": "Teléfono de contacto",
		"contactInfo": "Información de contacto",
		"defaultLanguage": "Idioma por defecto",
		"availableLanguages": "Idiomas disponibles",
		"createAccount": "Crear cuenta",
		"createArea": "Crear área",
		"createLicense": "Crear licencia",
		"createDistributor": "Crear distribuidor",
		"createDomain": "Crear dominio",
		"createReseller": "Crear distribuidor",
		"lastChange": "Último cambio",
		"createOwner": "Crear propietario",
		"createProduct": "Crear producto",
		"createSite": "Crear sitio",
		"createCustomer": "Crear cliente",
		"createResource": "Crear recurso",
		"createTheme": "Crear tema",
		"createSharingContext": "Crear proveedor de recursos",
		"createRole": "Crear rol",
		"roleName": "Nombre del rol",
		"newRole": "Nuevo rol",
		"newPrivilegeSet": "Nuevo conjunto de privilegios",
		"newDistributor": "Nuevo distribuidor",
		"newDomain": "Nuevo dominio",
		"selectRole": "Seleccionar roles",
		"noRolesAccess": "No se han añadido roles a este acceso",
		"removeRole": "Eliminar",
		"accessToRoles": "Roles {roles} | Roles {roles}",
		"availableRole": "Rol disponible | Roles disponibles",
		"limits": "Límite | Límites",
		"oldPassword": "Contraseña antigua",
		"newPassword": "Nueva contraseña",
		"repeatNewPassword": "Repetir nueva contraseña",
		"vendorConfig": "Configuración del proveedor",
		"usingCustomSupportInfo": "Información de soporte personalizada",
		"myAccount": "Mi cuenta",
		"userSaveFailedGeneral": "No se pudo guardar la información del usuario",
		"userSaveFailedEmail": "No se pudo actualizar la dirección de correo electrónico del usuario",
		"userChangeEmailHeader": "Para cambiar su dirección de correo electrónico debe proporcionar su contraseña",
		"updateEmail": "Actualizar el correo electrónico",
		"userSaveFailedPassword": "No se pudo actualizar la contraseña del usuario",
		"userUpdatePasswordTitle": "Actualizar contraseña",
		"userUpdatePasswordDescription": "Si tiene éxito, esto cerrará todas las sesiones activas de su usuario actual. ¿Procede?",
		"changeYourPassword": "Cambie su contraseña",
		"changePassword": "Cambiar la contraseña",
		"share": "Compartir",
		"addNewShare": "Compartir con...",
		"addApiAccount": "Añadir cuenta API",
		"addUserAccount": "Añadir cuenta de usuario",
		"resellerLicenseInfoSite": "Los revendedores pueden aprovisionar sitios y licencias. Usted está gestionando",
		"resellerLicenseInfoCustomer": "Los revendedores pueden aprovisionar clientes y licencias. Está administrando",
		"distributorLicenseInfo": "Los distribuidores pueden aprovisionar a los revendedores. Usted está gestionando",
		"newReseller": "Nuevo revendedor",
		"editAccessFor": "Editar acceso para",
		"editAccessToReseller": "Editar acceso a revendedor",
		"distributorAccess": "Acceso al distribuidor",
		"domainAccess": "Acceso de dominio",
		"resellerAccess": "Acceso a revendedor",
		"themeAccess": "Acceso al tema",
		"siteAccess": "Acceso al sitio",
		"deleteDistributorText": "Solo se puede eliminar un distribuidor si no tiene revendedores ni temas aprovisionados.<br>¿Confirma que desea eliminar este distribuidor?",
		"deleteDistributorFailedText": "No se pudo eliminar el distribuidor: {details}",
		"distributorHasProvisionedContext": "hay revendedores y/o temas aprovisionados vinculados al distribuidor",
		"deleteDomainText": "Solo se puede eliminar un dominio si no tiene distribuidores ni temas aprovisionados.<br>¿Confirma que desea eliminar este dominio?",
		"deleteDomainFailedText": "No se pudo eliminar el dominio: {details}",
		"domainHasProvisionedContext": "hay distribuidores y/o temas aprovisionados vinculados al dominio",
		"externalSystemsHeading": "La información del sistema externo se puede editar aquí",
		"createPrivilegeSet": "Crear conjunto de privilegios",
		"privilegeSetName": "Nombre del conjunto de privilegios",
		"systemPrivilegeSet": "Conjunto de privilegios del sistema",
		"noPrivilegeSetsAdded": "No se ha añadido ningún conjunto de privilegios",
		"chooseTypePrivilegeSet": "Elija un 'Tipo de contexto' antes de seleccionar Conjuntos de privilegios",
		"forceSSO": "Forzar SSO",
		"forceSSOHint": "Utilícelo si desea limitar el acceso de usuario a un proveedor de SSO externo específico.",
		"idp": "Forzar SSO",
		"copyRole": "Copiar desde un rol existente",
		"roleProductsHint": "Este rol solo estará disponible para los productos seleccionados",
		"roleProductDisabledHint": "Solo se aplica a roles de implementación que no son del sistema",
		"copyOfName": "Copia de {name}",
		"contextPrivilegeSetSelectionTitle": "Selección de acceso",
		"contextPrivilegeSelectionDescription": "Elija a qué menús y submenús del portal tendrá acceso este rol",
		"deletedContext": "Contexto eliminado",
		"finalizeDelete": "Finalizar eliminación",
		"undeleteConfirmMsg": "¿Seguro que desea recuperar este contexto? | ¿Seguro que desea recuperar estos {amount} contextos?",
		"undelete": "Recuperar",
		"undeleteSuccess": "Contexto recuperado | {amount} contextos recuperados",
		"undeleteFailed": "No se ha podido recuperar",
		"finalizeDeleteConfirmMsg": "¿Seguro que desea finalizar la eliminación de este contexto? | ¿Seguro que desea finalizar la eliminación de estos {amount} contextos?",
		"finalizeDeleteSuccess": "Eliminación de contexto finalizada | Eliminación de {amount} contextos finalizada",
		"finalizeDeleteFailed": "No se ha podido finalizar la eliminación",
		"activateSite": "Activar sitio",
		"activateSiteDescription": "Al activar un sitio, se eliminarán todas las marcas de agua y comenzará el plan de facturación",
		"activateSiteConfirmTex": "Entiendo que esta acción es definitiva",
		"siteActivated": "Sitio activado",
		"duplicateRegisteredClients": "Cliente registrado duplicado",
		"userId": "ID de usuario",
		"copiedAuthToken": "Token de autenticación copiado",
	},
	"hospitality": {
		"hospitality": " Hospitalidad ",
		"room": " Sala | Salas ",
		"additionalRooms": "Habitaciones adicionales",
		"occupancy": " Ocupación ",
		"guest": " Invitado | Invitados ",
		"wakeup": "Despertador | Despertadores",
		"guestMessage": "Mensajes| Mensaje | Mensajes",
		"stay": " Estancia | Estancias ",
		"account": " Cuenta | Cuentas ",
		"virtualRemoteClients": {
			"session": "sesión | sesiones",
			"roomName": "Habitación",
			"screenName": "TV",
			"sessionId": "ID de sesión",
			"remoteClientId": "VRC ID",
			"created": "Creado en",
			"tvOnline": "TV online",
			"vrcOnline": "VRC online"
		},
		"config": {
			"chromecastCheckoutLabel": "Check out de Chromecast",
			"chromecastCheckoutExplanation": "Restablecer los Chromecasts asociados a la habitación cuando el huésped haga el check out.",
			"chromecastFacilityIdLabel": "ID de instalación de Chromecast",
			"chromecastTokenLabel": "Token de Chromecast",
			"serviceMenuCodeLabel": "Código de menú de servicio",
			"nightTimeToggleLabel": "Noche",
			"nightTimeInfo": "Período en que los televisores no se encenderán para ningún evento excepto para el servicio de despertador.",
			"nightTimeFromLabel": "Noche desde",
			"nightTimeToLabel": "Noche hasta"
		}
	},
	"dashboard": {
		"welcome": "Bienvenido {name}",
		"signedInto": "Actualmente ha iniciado sesión en el sitio {site}",
		"occupancyLastNextWeek": "Ocupación última y próxima semana",
		"upcomingWakeups": "Próximos despertares",
		"mediaChannels": "Canales de medios definidos",
		"activeInstallCodes": "¡Códigos de instalación activos!",
		"newIssuesLastDays": "Nuevos temas en los últimos {días} días",
		"newLastDays": "Novedades en los últimos {días} días",
		"account": "Cuenta de usuario | Cuentas de usuario",
		"apiAccount": "Cuenta API | Cuentas API",
		"occupancyPercent": "Ocupación (%)",
		"sectionHelp": "Cerrar y abrir las secciones del panel de control haciendo clic en sus respectivas cabeceras",
		"filterSites": "Filtrar sitios por",
		"filterCustomers": "Filtrar clientes por",
		"clickOnMap": "Hacer clic en un elemento del mapa para ver los detalles",
		"displayingData": "Visualización de los datos de {amount} site | Visualización de los datos de {amount} sites",
		"noFeaturesActivated": "No hay características activadas...",
		"clearSelection": "Borrar selección",
		"details": "Detalles",
		"openExternal": "Abrir portal de admón.",
		"openSite": "Abrir sitio",
		"openSiteError": "No se puede abrir el sitio de tipo {tipo}",
		"openTvPreview": "Abrir vista previa de TV",
		"notManaged": "No gestionado",
		"vacant": "Vacante",
		"managementAccess": "Acceso a la gestión",
		"mergeCCs": "Fusionar difusiones duplicadas",
		"mapView": "Vista de mapa",
		"listView": "Vista de lista",
		"stats": "Estadísticas",
		"map": "Mapa",
		"list": "Lista",
		"noReportGenerated": "Ningún informe generado",
		"buildReport": "Crear informe",
		"rebuildReport": "Volver a crear informe",
		"generateStatisticsReport": "Generar informe de estadísticas",
		"processingData": "Procesando datos...",
		"configureFields": "Configurar campos",
		"downloadSelected": "Descarga seleccionada",
		"searchAllFields": "Buscar todos los campos visibles",
		"cluster": "Clúster",
		"installedTvs": "TV instalados",
		"product": "PRODUCTO",
		"filter": "FILTRAR",
		"hideDemos": "Ocultar demos",
		"hideWhitelabeled": "Ocultar elementos sin marca",
		"hideWatermarked": "Ocultar elementos con marca de agua",
		"licenseInfo": "Información de licencia",
		"tvLicenses": "Licencias de TV",
		"signageLicenses": "Licencias de señalización",
		"castLicenses": "Licencias de difusión",
		"products": "Productos"
	},
	"drawer": {
		"userSettings": " Configuración del usuario ",
		"contextButtonHelp": " Haga clic en el menú hamburguesa de la barra superior para pasar a otro sitio o a la pantalla de inicio ",
		"mainHelp": " Pase el ratón sobre el menú de la izquierda para ampliarlo, este menú contiene los principales elementos de navegación para el sitio actual o la pantalla de inicio "
	},
	"userMenu": {
		"theme": "Tema",
		"dark": "Oscuridad",
		"notification": "Notificación | Notificaciones",
		"pinMenu": "Menú Pin",
		"all": "Todos",
		"success": "Éxito",
		"info": "Información",
		"resetHints": "Restablecer pistas",
		"settings": "Configuración de la cuenta",
		"logOut": "Cerrar la sesión",
		"confirmLogout": "Por favor, confirme",
		"hintsReset": "Se han restablecido los consejos",
		"timeDisplay": "Reloj de 12 horas"
	},
	"reorderList": {
		"dragDropHelp": " Arrastre y suelte los elementos de la lista para reordenarlos "
	},
	"baseTable": {
		"selected": "{selected} seleccionado {type} | {selected} seleccionado {type}s",
		"enableMultiselect": "Habilitar multiselección",
		"disableMultiselect": "Desactivar multiselección",
		"searchLabel": "Buscar por todos los campos visibles",
		"searchTooltip": "Encadenar palabras clave con '&'",
		"export": "Exportar a Excel"
	},
	"channelTables": {
		"channelTable": "Tabla de canales de TV | Tablas de canales de TV",
		"radioChannelTable": "Tabla de canales de radio | Tablas de canales de radio",
		"channelTableGroup": "Grupo de tablas de canales | Grupos de tablas de canales",
		"tableGroup": "Grupo de tablas | Grupos de tablas",
		"dragDropHere": "Arrastrar y soltar canales aquí",
		"newName": "Nuevo nombre",
		"availableChannels": "Canales disponibles",
		"addAllChannels": "Añadir todos los canales",
		"selectedChannels": "Canales seleccionados",
		"removeAllChannels": "Eliminar todos los canales",
		"unknownChannel": "Canal desconocido",
		"noGroups": "No hay grupos en esta tabla",
		"table": "Tabla | Tablas",
		"clickToChange": "Haga clic aquí para cambiar la tabla",
		"createFirst": "Cree su primera tabla de canales",
		"headerSelectHint": "Haga clic en el nombre de la tabla en la cabecera para seleccionar otra tabla para editar",
		"dragDropChannels": "Arrastre y suelte los canales para seleccionarlos y reordenarlos",
		"channelTableName": "Nombre de la tabla de canales",
		"new": "Nuevo",
		"deleteText": "Elimine la tabla de canales \"{name}\", <br>Asegúrese de que esta tabla de canales no se utiliza en ninguna configuración de pantalla",
		"saveGroupError": "No se puede guardar el grupo",
		"saveGroupChannelsError": "Error al guardar los canales en el grupo",
		"saveChannelsError": "Error al guardar los canales",
		"appendToGroupError": "Error al guardar en el grupo",
		"createGroupError": "Error al crear el grupo",
		"createdGroup": "Grupo {name} creado",
		"deletedGroup": "Grupo {name} eliminado",
		"channelsSaved": "Canales guardados"
	},
	"event": {
		"defaultEventValues": "Valor de evento predeterminado | Valores de evento predeterminados",
		"eventDeleteSuccess": "El evento se ha eliminado",
		"eventDeleteFailed": "No se ha podido eliminar el evento",
		"createEvent": "Crear evento",
		"editEvent": "Editar evento",
		"deleteEvent": "Eliminar evento",
		"eventPrivate": "Privado",
		"startTime": "Hora de inicio",
		"startDate": "Fecha de inicio",
		"endTime": "Hora de finalización",
		"endDate": "Fecha de finalización",
		"eventHeader": "Eventos - {date}",
		"eventPrivateHint": "(ocultar título y host en indicaciones y pantallas de reuniones)",
		"eventInFuture": "El evento debe tener una fecha futura",
		"eventEndBeforeStart": "El evento debe finalizar después de la hora de inicio",
		"eventOverlappingDescription": "Ya existe un evento solapado en esta ubicación. ¿Seguro que desea crear este evento?",
		"eventOverlapping": "Eventos solapados",
		"copyEvent": "Copiar evento {name}",
		"copyEventToLocation": "Copiar evento a ubicación",
		"makeCopyEvent": "Hacer una copia del evento",
		"privateTitle": "Título privado",
		"noLocations": "No se han encontrado ubicaciones",
		"wayfindingConfiguration": "Configuración de indicaciones | Configuraciones de indicaciones",
		"saveWayfindingConfig": "Guardar configuración de indicaciones",
		"saveWayfindingConfigFailed": "",
		"integrationLocations": "Ubicación de integración | Ubicaciones de integración",
		"manageLogos": "Administrar logotipos",
		"selectLogo": "Seleccionar logotipo",
		"replaceLogo": "Sustituir logotipo",
		"removeLogo": "Eliminar logotipo",
		"manageIcons": "Administrar iconos",
		"eventRetention": "Conservación de eventos",
		"eventRetentionDescription": "Cantidad de días que se conservarán los eventos en el sistema una vez finalizados.",
		"maxEventDuration": "Duración",
		"maxEventDurationDescription": "Duración máxima del evento al reservar en el dispositivo de reserva de sala",
		"applyLogoByHost": "Aplicar logotipo por nombre de host",
		"logoMappingDesc": "Aplicar automáticamente un logotipo al crear un evento, en función del nombre del host. Recuerde añadir la asignación de hosts al logotipo para que esto funcione.",
		"hostMapping": "Asignación de hosts",
		"addHostMapping": "Añadir asignación de host",
		"failedToSaveMapping": "No se ha podido guardar la asignación",
		"mappingAlreadyExists": "La asignación de hosts ya existe en un logotipo"
	},
	"tvMenu": {
		"tvMenuPage": "Página de menú de TV | Páginas de menú de TV",
		"menuPage": "Página de menú | Páginas de menú",
		"subtitle": "Subtítulos | Subtítulos",
		"audioLanguage": "Idioma de audio | Idiomas de audio",
		"subtitleLanguage": "Idioma de los subtítulos | Idiomas de los subtítulos",
		"epgMenu": "Menú EPG | Menús EPG",
		"offlineMenu": "Menú sin conexión | Menús sin conexión",
		"externalInput": "Menú de entrada externa | Menús de entrada externa",
		"wakeupMenu": "Menú de orden de despertador | Menús de orden de despertador",
		"defaultWakeupAlarmMenu": "Menú de alarma de despertador predeterminado",
		"powerOnMenu": "Menú de encendido | Menús de encendido",
		"messageNotificationMenu": "Menú de notificación de mensajes",
		"messageDisplayMenu": "Menú de visualización de mensajes"
	},
	"documents": {
		"page": "Página | Páginas",
		"document": "Documento | Documentos",
		"selectPage": "Seleccionar una página"
	},
	"resources": {
		"resource": "Recurso | Recursos",
		"font": "Fuente | Fuentes",
		"image": "Imagen | Imágenes",
		"video": "Vídeo | Vídeos",
		"playlist": "Lista de reproducción | Listas de reproducción",
		"template": "Plantilla | Plantillas",
		"compositeMenuPages": "Página de plantilla de señalización | Páginas de plantillas de señalización",
		"logo": "Logotipo | Logotipos",
		"wayfindingIcon": "Icono de indicaciones | Iconos de indicaciones",
		"uploadedYet": "Todavía no se ha subido ningún {tipo}",
		"selected": "{cantidad} {tipo} seleccionada | {cantidad} {tipo}seleccionada",
		"copyUrl": "Copiar URL",
		"copyResources": "¿Copiar recurso en {dest}? | ¿Copiar recursos de {cuentas} en {dest}?",
		"moveResources": "¿Mover el recurso a la carpeta {dest}? | ¿Mover {contar} recursos a la carpeta {dest}?",
		"moveResource": "¿Mover {src} a la carpeta {dst}?",
		"copying": "Copiando",
		"copyToFolderSuccess": "Se ha realizado la copia de {resourceName} en la carpeta {folderName}",
		"copyToFolderFailure": "Ha fallado la copia de {resourceName} en la carpeta {folderName}",
		"copyFinishedWithError": "Finalizado con error",
		"copySuccessDescription": " | {amount} recurso copiado con éxito en {contexto} | {amount} recursos copiados con éxito en {context}",
		"circularReferenceErrorMsg": "{amount} {type} poseen referencias circulares, edítelas/elimínelas para poder guardar.",
		"failedToCopy": "Fallo en la copia",
		"downloadFile": "Descargando archivo | Descargando archivos",
		"downloadingName": "Descargando {name}",
		"downloadFailed": "¡Descarga fallida!",
		"selectImage": "Seleccione una imagen",
		"selectFont": "Seleccione una fuente",
		"selectVideo": "Seleccionar un vídeo",
		"newPlaylist": "Nueva lista de reproducción",
		"upload": "Subir",
		"uploadMedia": "Subir media",
		"uploadResource": "Subir recurso",
		"uploadFileType": "Cargar {type} | Cargar {type}/s",
		"uploadSuccessful": "Carga exitosa - {tipo} se está procesando ahora",
		"uploadError": "Error durante la carga - por favor, inténtelo de nuevo",
		"replaceResource": "Sustituir {resource}",
		"replaceResourceTitle": "Sustituir {resource}: {name}",
		"replaceResourceDescription": "¡ADVERTENCIA! Los elementos sustituidos todavía están almacenados en caché en la nube, por lo que pasará un tiempo antes de que la nueva imagen se muestre en las pantallas.<br><br>¿Seguro que desea sustituir el/la {resource} actual por <br>\"{name}\"?",
		"resourceReplaced": "{resource} sustituido",
		"resourceReplacedFailed": "No se ha podido sustituir {resource}",
		"editImageBefore": "Editar imagen antes de subirla",
		"uploaderTitle": "Cargar {tipo}",
		"unsupportedFile": "No se admiten archivos del tipo {type}",
		"unsupportedFileSize": "Los archivos no pueden tener un tamaño superior a {size}",
		"unsupportedFileNameAndSize": "Los archivo {fileName} no pueden tener un tamaño superior a {size}",
		"exceedingMaxPdfPages": "Se supera el límite máximo de {limit} páginas en el PDF",
		"wrongType": "Sólo se permite subir archivos del tipo {type}s",
		"quickBrownFox": "El zorro marrón rápido salta sobre el perro perezoso",
		"addOneOrMore": "Añada las imágenes, vídeos y fuentes que desee cargar",
		"addOneOrMoreExcludeVideos": "Añada las imágenes, fuentes y archivos PDF que desea cargar",
		"addType": "Añada el/la {type} que desea cargar | Añada los/las {type} que desea cargar",
		"pressUpload": "Pulse subir para guardar los archivos añadidos en el sistema",
		"rotateLeft": "Girar 45 grados a la izquierda",
		"rotateRight": "Girar 45 grados a la derecha",
		"crop": "Recortar",
		"addResources": "Añadir recursos",
		"uploadSuccessfulHover": "Cargar con éxito",
		"addDocument": "Añadir documento",
		"addTemplate": "Añadir plantilla",
		"potentialUsageFound": "Se encontró el siguiente uso potencial",
		"potentialUsageFoundInItems": "Se ha encontrado un uso potencial para los elementos",
		"folderNotEmpty": "La carpeta no está vacía",
		"deleteFolderWithUsage": "Estas carpetas contienen elementos con uso potencial",
		"deleteFolderWarning": "Al eliminar esta carpeta también se eliminará permanentemente todo su contenido, incluidas las subcarpetas y los recursos",
		"deleteFolderConfirm": "Confirmo que quiero eliminar permanentemente esta carpeta y su contenido",
		"newPlaylistFromPdf": "nueva lista reproducción de pdf",
		"displayDuration": "Mostrar duración",
		"pdfUploadFolderError": "No se ha podido crear la carpeta multimedia para PDF, cargando a la carpeta de archivos multimedia raíz",
		"tag": "Etiqueta | Etiquetas",
		"addTag": "Añadir etiqueta",
		"transcodeVideo4K": "El vídeo estará disponible en 4K poco después de subirlo.",
		"transcodeVideoHD": "El vídeo estará disponible en HD poco después de subirlo."
	},
	"mediaChannels": {
		"mediaChannels": "mediaChannels",
		"mediaChannel": "Canal de medios | Canales de medios",
		"globalMediaChannels": "Canales de medios globales",
		"globalMapping": "Asignación global",
		"address": "Dirección",
		"port": "Puerto",
		"serviceId": "ID de servicio",
		"frequency": "Frecuencia (kHz)",
		"modulation": "Modulación",
		"symbolRate": "SymbolRate",
		"bandwidth": "Ancho de banda",
		"satelliteId": "ID de satélite",
		"polarization": "Polarización",
		"programNumber": "Número de programa",
		"majorNumber": "Número de canal principal",
		"minorNumber": "Número de canal secundario",
		"type": "Tipo",
		"audioOnly": "Sólo audio",
		"createChannel": "Crear {type} de canal",
		"save": "Guardar canales",
		"saveTooltip": "Esto guardará los canales de todos los tipos",
		"clickToChange": "Haga clic aquí para cambiar el tipo de canal",
		"reset": "Restablecer y actualizar canales",
		"types": "Tipos de canales",
		"webRadioSearch": "Búsqueda de radio en la web",
		"unknownBitrate": "Desconocido",
		"placeholderChannelName": "Nombre del canal",
		"updateFailed": "Falló la actualización de los canales multimedia",
		"deleteFailed": "¡No se pudo eliminar el canal!",
		"createFailed": "¡No se pudo crear el canal!",
		"updateSuccess": "¡Canales de comunicación actualizados!",
		"deleteSuccess": "¡Canal de comunicación eliminado!",
		"createSuccess": "¡Canal de comunicación creado!",
		"validateFailed": "Un canal de tipo {type} tiene un nombre vacío"
	},
	"templates": {
		"templateErrorMessages": {
			"deserializationErrorMsg": " El motor de plantillas no pudo analizar los datos de la API. Compruebe la configuración de Parámetros fijos / Prueba / Conector de la API para asegurarse de que se obtienen los datos correctos."
		},
		"dataFetchErrorMessages": {
			"unknownHost": " La URL es desconocida al intentar obtener datos de la API. Esto puede deberse a un error tipográfico en la URL del conector de la API y/o en el parámetro de la ruta fija/de prueba.",
			"protocolError": " Error en el protocolo HTTP al intentar obtener datos de la API. Esto podría ser causado por un error en la URL del Conector de la API y/o el parámetro de la ruta fija/de prueba.",
			"unknownError": " Error al obtener los datos de la API. Compruebe la configuración de los parámetros del Conector de la API / Fijo/prueba.",
			"code300ErrorMsg": "La API devolvió el código 300: Opciones múltiples, una indicación de que existen varias opciones para el recurso solicitado. Compruebe la configuración de los parámetros de prueba/fijos.",
			"code400ErrorMsg": "La API devolvió el código 400: Solicitud errónea. La API no pudo entender la solicitud debido a una sintaxis no válida. Compruebe la configuración del conector/los parámetros de prueba/fijos.",
			"code401ErrorMsg": "La API devolvió el código 401: No autorizado. No está autorizado a realizar esta solicitud. Compruebe el conector de la API para los encabezados correctos",
			"code403ErrorMsg": "La API devolvió el código de estado 403: Prohibido. Esto podría significar que el conector o los parámetros fijos están configurados con una autorización incorrecta, no válida o desactualizada.",
			"code404ErrorMsg": "La API devolvió el código de estado 404: No encontrado. Esto puede significar que la URL es válida pero que no existe el recurso en sí. Las API también podrían enviar esta respuesta en lugar de la 403 para ocultar la existencia de un recurso a un cliente no autorizado. Asegúrese de que la configuración del conector/los parámetros de prueba/fijos sea correcta.",
			"code406ErrorMsg": "La API devolvió el código 406: No aceptable. La API no encontró ningún contenido que cumpla con los criterios indicados. Compruebe los parámetros de prueba/fijos.",
			"code415ErrorMsg": "La API devolvió el código 415: Tipo de archivo multimedia no admitido. Compruebe  la configuración del conector de la API. Trate de configurar el encabezado personalizado Content-Type en un formato compatible con la API",
			"code500ErrorMsg": "La API devolvió el código de estado 500: Error interno del servidor. Compruebe la configuración de los parámetros de prueba/fijos.",
			"apiTimeout": "Se ha agotado el tiempo de respuesta de la API. La API podría no estar disponible temporalmente."
		},
		"serializationErrors": {
			"jsonParseError": "No se han podido analizar los datos JSON devueltos por la API; compruebe la configuración del conector de la API/los parámetros fijos."
		},
		"internalErrors": {
			"code500ErrorMsg": "Hay un problema con nuestros sistemas, vuelva a intentarlo en unos momentos."
		},
		"noDataYet": "No hay datos todavía",
		"noDataFoundYet": "¡No se han encontrado datos todavía!",
		"save": "Guarda la plantilla sin cerrar la ventana",
		"setATest": "Establecer una prueba {name}",
		"infoUrlDefault": "URL a la página de información de la API",
		"infoUrlTooltip": "Cómo usar la fuente",
		"error": "Error al evaluar la plantilla",
		"httpMethodRequired": "Método HTTP requerido",
		"nameRequired": "Nombre requerido",
		"paramType": "Tipo de parámetro",
		"addItem": "Añadir elemento",
		"preview": "Generar vista previa",
		"template": "Plantilla | Plantillas",
		"templateRequired": "Plantilla requerida",
		"reset": "Restablecer los cambios realizados en esta plantilla desde el último guardado",
		"source": "Fuente | Fuentes",
		"sourceRequired": "Fuente requerida",
		"fixedParameters": "Parámetros fijos",
		"setAFixed": "Establecer un {nombre} fijo",
		"templateExample": "Ejemplo de plantilla",
		"dataExample": "Ejemplo de datos",
		"httpMethod": "Método HTTP",
		"markup": "Marcado",
		"sourceError": "Error de carga de la fuente",
		"selectConfigure": "Seleccione y configure la plantilla",
		"failedSource": "Error en la búsqueda de la fuente de la plantilla",
		"failedSourceFetch": "Error al buscar la información de la fuente",
		"templateSource": "Fuente de la plantilla | Fuentes de la plantilla",
		"rootName": "Nombre de la raíz",
		"apiConnector": "Conector de la API | Conectores de la API",
		"createTemplate": "Crear plantilla",
		"createWithoutClosing": "Crear plantilla sin cerrar la ventana",
		"saveWithoutClosing": "Guardar plantilla sin cerrar la ventana",
		"fetchApiData": "Obtener datos de la API",
		"cacheForSeconds": "Almacenamiento en caché no habilitado (0 segundos) | Plantilla de API de almacenamiento en caché durante {value} segundo |Plantilla de API de almacenamiento en caché durante {value} segundos",
		"cacheForSecondsError": "El número debe estar comprendido entre 0 y 1800",
		"defaultTemplateErrorExplanation": "Contenido que se mostrará en el TV si la plantilla contiene errores."
	},
	"configSchedules": {
		"defaultConfig": "Predeterminado",
		"dailyOverrides": "Anulaciones diarias",
		"weeklyOverrides": "Anulaciones semanales",
		"editTimeslot": "Editar intervalo de tiempo",
		"deleteTimeslot": "Eliminar intervalo de tiempo",
		"dragStopTime": "Arrastre para cambiar la hora de parada",
		"defaultContentExplanation": "Esta configuración se ejecuta todo el día en la pantalla, a menos que quede anulada por configuraciones diarias, semanales o de calendario.",
		"editScheduleName": "Editar nombre de programación",
		"tabDefault": "Predeterminado",
		"tabDaily": "Diariamente",
		"tabWeekly": "Semanalmente",
		"tabCalendar": "Calendario",
		"addTimeslotHelpText": "Haga clic para añadir un nuevo intervalo de tiempo o simplemente haga clic en un hueco en el calendario."
	},
	"scheduleMapping": {
		"createNew": "Crear nueva asignación de horarios",
		"rename": "Renombrar {title}",
		"created": "Nueva asignación de horarios creada",
		"updated": "Asignación de horarios actualizada",
		"currentSchedule": "Horario actual",
		"deleteHeading": "¿Eliminar la asignación de horarios {title}?",
		"deleteText": "Cualquier pantalla que forme parte de esta asignación se moverá al grupo de asignación de horarios por defecto",
		"addMapping": "Añadir mapeo",
		"screens": "Pantalla | Pantallas",
		"screensHeadingInfo": "Pantallas de TV utilizando el conjunto de reglas. Haga clic con el botón derecho para mover",
		"moveTo": "Mover a...",
		"rulesHeading": "Las reglas se evalúan en el orden en que aparecen aquí",
		"rulesDescription": "Las reglas personalizadas aún no son compatibles",
		"newRule": "Nueva regla",
		"defaultSchedule": "Programa por defecto",
		"screensMoved": "Pantallas movidas",
		"createFailed": "No se ha podido crear la asignación",
		"updateFailed": "No se ha podido actualizar la asignación"
	},
	"schedulePlans": {
		"editor": {
			"notInUse": "Planes de programación que no se encuentran en uso. Pulse 'Crear' para comenzar el uso.\n Generaremos automáticamente un plan de programación 'predeterminado' que contiene su programación/configuración de pantalla actual, además del nuevo plan de programación que cree.",
			"newForEach": "Programaciones independientes para cada pantalla",
			"singleNew": "Programación compartida para todas las pantallas",
			"copyExisting": "Copiar plan de programación existente",
			"removeWhenActive": "<b>Este plan de programación se encuentra definido actualmente como Activo</b>. Si decide eliminarlo, todas las pantallas cambiarán al plan de programación predeterminado. <br><br> ¿Seguro que desea eliminar este plan de programación?"
		},
		"screenEditor": {
			"newScheduleForEach": "Crear una nueva programación para cada plan de programación",
			"individualSelectionDescription": "Selección individual"
		},
		"type": "Plan de programación | Planes de programación",
		"setActive": "Definir como activo",
		"setActiveConfirmTitle": "¿Desea activar el plan de programación?",
		"disabledDeleteForDefault": "La función Eliminar plan de programación predeterminado solo está habilitada cuando no hay otros planes de programación",
		"setActiveConfirmText": "Esta acción definirá el plan de programación '{title}' como activo. Todas las pantallas de este sitio tendrán las programaciones definidas en el Plan de programación {title} configuradas como sus programaciones activas. ¿Desea continuar?",
		"setActiveSuccess": "Plan de programación {name} definido como activo",
		"setActiveFailed": "No se ha podido definir el plan de programación {name} como activo"
	},
	"screens": {
		"screensMissingSchedulesTitle": "Pantallas a las que les faltan programaciones",
		"screensMissingSchedulesText": "A las siguientes pantallas les faltan programaciones, lo que puede deberse a la interrupción del proceso de 2 pasos para crear/mover pantallas.",
		"screenStatus": "Estado de la pantalla | Estado de la pantalla",
		"tvScreenStatus": "Estado de pantalla de TV",
		"signageDisplayStatus": "Estado de visualización de señalización",
		"screens": "Pantallas",
		"livePreview": "Vista previa en directo",
		"displays": "Visualizaciones",
		"onlineScreens": "Pantallas de TV online",
		"screenSetup": "Configuración de pantalla | Configuraciones de pantalla",
		"screenSetupCreated": "Configuración de pantalla creada",
		"screenSetupUpdated": "Configuración de pantalla actualizada",
		"defaultScreenConfig": "Configuración por defecto",
		"tvScreen": "Pantalla de TV | Pantallas de TV",
		"signageScreen": "Pantalla de señalización | Pantallas de señalización",
		"tvType": "Tipo de TV | Tipos de TV",
		"clientType": "Tipo de cliente | Tipos de cliente",
		"tvSoftware": "Software de TV",
		"editName": "Editar nombre",
		"tvClient": "Cliente de TV | Clientes de TV",
		"unknownSetup": "Configuración desconocida",
		"createFirstSchedule": "Crear su primer horario",
		"schedule": "Horario | Horarios",
		"copyFromConfig": "Copiar de config (optional)",
		"quicklyCopyConfig": "Copiar rápidamente otra configuración",
		"mainMenu": "Menú principal | Menús principales",
		"welcomeMenu": "Menú de bienvenida | Menús de bienvenida",
		"channelMenu": "Menú de canales de TV | Menús de canales de TV",
		"radioChannelMenu": "Menú de canales de radio | Menús de canales de radio",
		"startVolume": "Volumen inicial",
		"maxVolume": "Volumen máximo",
		"autoPowerOffHrs": "Apagado automático al cabo de {hrs} h",
		"enableAutoPowerOff": "Habilitar apagado automático",
		"audioLanguageMenu": "Menú de idioma de audio",
		"subtitleLanguageMenu": "Menú de idioma de los subtítulos",
		"externalInputMenu": "Menú de entrada externa | Menús de entrada externa",
		"noScreenSetups": "Todavía no se ha creado ninguna configuración de pantalla",
		"selectSlotType": "Seleccione el tipo de ranura",
		"scheduleEditorCalendarHint": "Haga clic en cualquier lugar del calendario para añadir una nueva franja horaria",
		"editSlot": "Editar franja horaria",
		"newSlot": "Nueva franja horaria",
		"createNewConfig": "Crear nueva configuración",
		"timeslotOverlap": "La hora de inicio y finalización seleccionada se solapa con otra franja horaria. Por favor, inténtelo de nuevo con otras horas",
		"volume": "Volumen",
		"durationRequired": "Duración requerida",
		"durationLongerThan": "La duración debe ser superior a 0 segundos",
		"messageInJson": "Mensaje en formato JSON",
		"validJson": "Debe ser JSON válido",
		"sendMessageToScreen": "Enviar {type} de mensaje a {screens} de pantalla | Enviar {type} de mensaje a {pantallas} de pantalla",
		"sendMessage": "Enviar mensaje",
		"updateStatus": "Actualizar estado",
		"pushConfig": "Configuración push",
		"pushContent": "Insertar contenido",
		"upgradeAgentSoftware": "Actualizar SW de agente",
		"upgradeAgentSoftwareNotification": "Al hacerlo, se enviará un mensaje al agente para que actualice su software. El agente podría reiniciarse como parte de la actualización, lo que ocasionaría una breve interrupción del servicio del agente. Si no hay actualizaciones disponibles, no se realizará ninguna acción.",
		"poweredOn": "Encendido",
		"poweredOff": "Apagado",
		"powerOn": "Encendido",
		"powerOff": "Apagar",
		"reboot": "Reiniciar",
		"rebootRequired": "Para aplicar la rotación de pantalla, es necesario reiniciar.<br><br>¿Desea reiniciar ahora?",
		"jvmUptime": "Tiempo de actividad de JVM",
		"rebootRequested": "Reinicio solicitado",
		"appUpdateRequested": "Solicitud de actualización de la aplicación",
		"setVolume": "Ajustar volumen",
		"displayMessage": "Mostrar mensaje",
		"displayAddress": "Mostrar dirección",
		"displayName": "Mostrar nombre",
		"resetSoftAP": "Restablecer contraseña de SoftAP",
		"displayPage": "Mostrar página",
		"tuneChannel": "Sintonizar canal",
		"enableDebug": "Activar depuración",
		"reAuthenticate": "Volver a autenticar",
		"reAuthenticateMsg": "Está a punto de volver a autenticar la pantalla {screen}. Confirme para continuar.",
		"getScreenshot": "Obtener captura de pantalla",
		"sendPushMessage": "Enviar mensaje push",
		"openClientPreview": "Abrir vista previa del cliente",
		"sendMgmntMessage": "Enviar mensaje de admón.",
		"moveToLocation": "Mover a ubicación",
		"changeSchedule": "Cambiar programación",
		"selectSchedule": "Seleccionar programación",
		"manage": "Gestionar",
		"moveScreens": "Mover {screens} pantalla | Mover {screens} pantallas",
		"deleteScreens": "¿Eliminar la pantalla {screens}? | Eliminar {screens} pantallas",
		"setContentScheduleForScreenExistingNameMsg": "Ya existe una programación con el nombre {screenName}, la nueva programación se creará con el nombre {uniqueName}.",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Conflicto de nombres en los siguientes planes de programación:<br>",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> - Se creará la nueva programación con el nombre <span style='font-style: italic;'>{uniqueName}</span>.<br>",
		"move": "Mover",
		"name": "Nombre",
		"ip": "IP",
		"mac": "MAC",
		"rotation": "Rotación",
		"deviceType": "Tipo de dispositivo",
		"appVersion": "Versión de la aplicación",
		"firmwareVersion": "Versión del firmware",
		"osVersion": "Versión del SO",
		"details": "Detalles",
		"occupied": "Ocupado",
		"online": "En línea",
		"offline": "Desconectado",
		"power": "Potencia",
		"state": "Estado",
		"public": "Público",
		"clientId": "ID de cliente",
		"setRotation": "Definir rotación para la pantalla | Definir rotaciones para las pantallas {count}",
		"id": "ID de cliente",
		"locationTitle": "Ubicación",
		"previewOnlyOne": "Por favor, seleccione sólo una pantalla para la vista previa",
		"screensMoved": "{screens} pantalla movida a otra ubicación | {screens} pantallas movidas a otra ubicación",
		"installCode": "Instalar código | Instalar códigos",
		"createInstallCode": "Crear código de instalación",
		"code": "Código",
		"createSchedule": "Crear una programación",
		"created": "Creado",
		"createdBy": "Creado por",
		"expiryDate": "Fecha de caducidad",
		"configInUse": "Error: La configuración se está utilizando",
		"configInUseDescription": "{name} se está utilizando en las siguientes programaciones:",
		"scheduleInUse": "Error: La programación se está utilizando",
		"scheduleInUseDescription": "{name} se está utilizando en las siguientes asignaciones de programación:",
		"configTvSettingsHeading": "Ajustes de TV",
		"configChannelsHeading": "Canales",
		"configMenusHeading": "Menús",
		"addConfiguration": "Añadir configuración",
		"groupBy": "Agrupar por",
		"setSchedule": "Establecer horario...",
		"upgradeApp": "Actualizar aplicación",
		"helpText": "Introducir mensaje para mostrar antes de apagar.<br>Si no se establece ningún mensaje se apagará(n) instantáneamente la(s) pantalla(s)",
		"newTvScreen": "Nueva pantalla de TV",
		"clientIdRequired": "Se requiere la identificación del cliente",
		"pickLocation": "Elija una ubicación",
		"noLocation": "No hay ubicaciones disponibles",
		"roomRequired": "Se requiere una sala",
		"roomSearchPlaceholder": "Empezar a escribir para buscar",
		"tvPreRegisteredOn": "TV pre-registrada en",
		"tvRegisteredOn": "TV registrada en",
		"tvRegisteredCloseText": "Cierre esta pestaña.",
		"pickContext": "Elige un contexto",
		"noContext": "No hay contextos disponibles",
		"contextRequired": "Contexto requerido",
		"clientToken": "Ficha de cliente",
		"clientTokenRequired": "Se requiere el token de cliente",
		"couldNotFindItemToEdit": "No se ha podido encontrar el elemento a editar",
		"uptime": "Tiempo de actividad",
		"memoryUsage": "Uso de la memoria",
		"requestScreenshot": "Solicitando captura de pantalla, por favor espere...",
		"requestScreenshotOffline": "No se puede solicitar una captura de pantalla desde un dispositivo desconectado...",
		"requestScreenshotPoweredOff": "No se puede solicitar una captura de pantalla desde un dispositivo apagado...",
		"requestNewScreenshot": "Haga clic para solicitar una nueva captura de pantalla...",
		"requestUpdate": "Solicitar actualización",
		"serial": "Serie",
		"firmware": "Firmware",
		"resolution": "Resolución",
		"system": "Sistema",
		"publicIp": "IP pública",
		"softAP": "SoftAP",
		"currentConfig": "Configuración actual",
		"rcAddr": "Dirección RC",
		"network": "Red",
		"timeOnDevice": "Tiempo en el dispositivo",
		"openPreview": "Abrir vista previa",
		"screenInfo": "Información de la pantalla",
		"reloadAllMediaTitle": "Volver a cargar todos los archivos multimedia",
		"reloadAllMediaDescription": "¿Desea eliminar todos los archivos multimedia descargados en {amount} dispositivo y volver a descargarlos? ¿Desea eliminar todos los archivos multimedia descargados en {amount} dispositivos y volver a descargarlos?",
		"moveToScheduleTitle": "Establecer mapeo de horario",
		"changeScreenScheduleDescription": "¿Desea cambiar la programación de pantalla a {mapping}?",
		"changeScreenScheduleSuccess": "Programación de pantalla cambiada a {mapping}",
		"moveToScheduleDescription": "¿Mover {amount} pantallas a la programación {mapping}?",
		"moveToScheduleSuccess": "Nuevo horario establecido para {amount} pantallas",
		"moveToSite": "Mover pantalla al sitio",
		"moveScreenToSiteMsg": "¡Atención! Está a punto de mover esta pantalla a otro sitio, por favor confirme para continuar",
		"moveScreenToSiteSuccess": "Pantalla movida a un nuevo sitio",
		"moveScreenToSiteNotAllowed": "No se permite mover la pantalla a este sitio",
		"moveScreenToSiteNotAllowedNoLicense": "No se puede mover la pantalla a este sitio: El sitio no tiene la licencia {license} activada.",
		"moveScreenToSiteNotAllowedLimitReached": "No se puede mover la pantalla a este sitio: Se ha alcanzado el límite de licencias para este tipo de pantalla.",
		"createScreenLimitReachedMsg": "No se puede crear la nueva pantalla. Se ha alcanzado el límite de licencias para este tipo de pantalla",
		"successfullyAuthenticated": "Pantalla reautenticada con éxito",
		"canNotReAuthenticate": "No se puede volver a autenticar una pantalla ya autenticada",
		"appMode": "Modo de aplicación",
		"forceUpdate": "Forzar actualización",
		"services": "Servicios",
		"upgradablePackages": "Paquetes actualizables",
		"authCodeNotFound": "El código de autenticación no es válido o se agotó el tiempo de espera. Por favor revise la pantalla para un código válido.",
		"lastReboot": "Último reinicio",
		"storage": "Almacenamiento",
		"authCreateNewSchedule": "Esto creará una nueva programación con el mismo nombre que la pantalla creada e incluirá la pantalla en esa programación.<br>Al desmarcar esta casilla, puede decidir añadir la pantalla a una programación existente.",
		"authUseExistingSchedule": "Seleccione una programación existente a la que añadir la nueva pantalla:",
		"registrationCode": "Código de registro de pantalla",
		"registrationCodeLength": "6 dígitos"
	},
	"booking": {
		"booking": "Reserva | Reservas",
		"numberOfBookings": "{number} reserva | {number} reservas",
		"book": "Reserva",
		"bookNights": "Reservar {nights} de estancia",
		"night": "Noche | Noches",
		"stayFromTo": "{from} hasta {to}",
		"stayCheckedIn": "- check in",
		"calendar": "Calendario",
		"availabilityError": "Fallo al recuperar los datos de disponibilidad de la reserva",
		"bookingsForDate": "Reservas para {date}",
		"noBookingsForDate": "No hay reservas para {date}",
		"failedToRetrieve": "Fallo en la recuperación de reservas",
		"timeslotTaken": "Esa franja horaria está ocupada, por favor, intente otra"
	},
	"guests": {
		"anonymous": "Anónimo",
		"language": "Idioma",
		"unspecified": "No especificado",
		"pickRoom": "Elige una habitación disponible",
		"checkInDate": "Fecha de entrada",
		"checkInTime": "Hora de entrada",
		"checkOutDate": "Fecha de salida",
		"checkOutTime": "Hora de salida",
		"newCheckIn": "Nuevo check-in",
		"checkIn": "Check-in",
		"checkInGuest": "Registrar huésped",
		"editGuest": "Editar huésped",
		"checkOut": "Check-out",
		"getBill": "Obtener factura",
		"guestCheckOut": "Check out huésped",
		"billItemName": "Nombre del artículo",
		"billItemAmountMax4Chars": "amnt",
		"billItemCurrencyMax3Chars": "cur",
		"billDataError": "No se pudo obtener la información de la factura ({status})",
		"confirmCheckout": "Confirmar salida",
		"editStay": "Editar estancia",
		"editBooking": "Editar reserva",
		"newBooking": "Nueva reserva",
		"checkInBeforeOut": "La salida debe ser posterior a la entrada",
		"noGuests": "No hay huéspedes",
		"overrideTvConfig": "Anular la asignación de configuración de TV",
		"setTvConfigConfirm": "¿Desea anular la asignación de configuración de TV \"{configName}\" en el/los huésped/es {guestCnt} seleccionado/s?<br>Esta configuración se revertirá en el check out.",
		"clearTvConfigConfirm": "¿Desea borrar la asignación de configuración de TV anulada en el/los huésped/es {guestCnt} seleccionado/s?<br>Al hacerlo, se aplicará la asignación de configuración establecida por el sistema.",
		"setTvConfigSuccess": "{amount} televisor se ha reconfigurado|{amount} televisores se han reconfigurado",
		"sendMessage": "Enviar mensaje",
		"guestsNumber": "{number} de huéspedes | {number} de huéspedes",
		"person": "Persona | Personas",
		"forceCheckOutTitle": "Forzar el check out",
		"forceCheckOutMessage": " Este huésped fue registrado automáticamente a través de un sistema externo. El sistema externo es responsable de volver a registrar la salida del huésped. Al forzar esta salida, el huésped no saldrá del sistema externo y podría causar problemas. ¿Proceder?"
	},
	"guestMessages": {
		"to": "Para",
		"unreadMessages": "Mensajes no leídos",
		"subject": "Asunto",
		"create": "Nuevo mensaje",
		"view": "Ver mensaje",
		"createdHeader": "Enviado",
		"readHeader": "Leer",
		"displayedHeader": "Notificado",
		"senderName": "De",
		"instant": "Visualización instantánea",
		"messageTemplate": "Plantilla de mensaje | Plantillas de mensajes",
		"useTemplate": "Utilizar plantilla"
	},
	"wakeups": {
		"roomAccount": "Habitación (account)",
		"tvMenu": "Menú de TV a mostrar",
		"customTvMenu": "Utilice el menú de TV personalizado para mostrar",
		"wakeupInFuture": "Despertar debe ser en el futuro",
		"wakeupExists": "El servicio de despertador ya existe",
		"create": "Crear despertar",
		"daily": "Diario",
		"status": "Estado",
		"delivered": "Entregado",
		"notDelivered": "No entregado",
		"displayed": "Visualizado",
		"notDisplayed": "No mostrado",
		"confirmed": "Confirmado",
		"notConfirmed": "No confirmado",
		"editWakeup": "Editar despertar",
		"deliveredWakeup": "Despertador entregado",
		"newWakeup": "Despertar nuevo"
	},
	"scheduledTasks": {
		"dailyNewSheets": "Hojas nuevas diarias",
		"roomType": "Tipo de habitación",
		"allTypes": "Todos los tipos",
		"roomTypeEmpty": "Dejar vacío para establecer esta tarea en todas las habitaciones",
		"when": "Cuando",
		"createTask": "Crear tarea",
		"specificRooms": "Habitaciones específicas"
	},
	"issues": {
		"maintenanceType": "Tipo de mantenimiento",
		"changeStatus": "Cambiar estado",
		"setIssueTo": "Establecer asunto a...",
		"editIssue": "Editar asunto",
		"created": "Creado",
		"closed": "Cerrado",
		"issueSetTo": "Asunto en {room} puesto en {status}",
		"issuesSetTo": "Número en {room} establecido en {status}",
		"newIssue": " Nuevo asunto | Nuevos asuntos",
		"editingIssues": "Edición de {issues} asuntos",
		"issueUpdated": "Asunto de {type} en {room} actualizado",
		"issueAdded": "Asunto de {type} añadido a {room}",
		"urgent": "Urgente",
		"flagAsClosed": "Marcar como cerrado",
		"issueSetup": "Configuración de la incidencia"
	},
	"locations": {
		"location": "Ubicación | Ubicaciones",
		"createRooms": "Crear salas",
		"createLocations": "Crear ubicaciones",
		"occupation": "Ocupación",
		"locatedIn": "Situado en",
		"locationSetTo": "{location} establecida en {estado}",
		"locationsSetTo": "{locations} habitaciones establecidas en {estado}",
		"locationSetup": "Configuración de ubicación",
		"category": "Categoría | Categorías",
		"setRoomTo": "Configurar {room} a...",
		"editRoom": "Editar sala",
		"changeStatus": "Cambiar estado",
		"changeCategory": "Cambiar categoría",
		"changeLocation": "Cambiar ubicación",
		"addTag": "Añadir etiqueta",
		"removeTag": "Eliminar etiqueta",
		"addIssue": "Añadir asunto",
		"tagRemoved": "Etiqueta {tag} eliminada de {rooms} habitaciones",
		"tagAdded": "Etiqueta {tag} añadida a {rooms} habitaciones",
		"categoryChange": "Categoría de {room} cambiada a {category} | {rooms} cambiada a la categoría {category}",
		"newRooms": "Nuevas habitaciones",
		"newLocations": "Nuevas ubicaciones",
		"roomNumber": "Número de habitación",
		"locationNumber": "Número de ubicación",
		"startRange": "Rango de inicio",
		"endRange": "Rango final",
		"parentLocations": "Ubicaciones de los padres",
		"startRangeRequired": "Se requiere el rango inicial",
		"startRangeLower": "El rango inicial debe ser menor que el rango final",
		"endRangeRequired": "Se requiere el rango final",
		"endRangeHigher": "El rango final debe ser mayor que el rango inicial",
		"change": "Cambiar",
		"newLocation": "Nueva ubicación",
		"changeLocationOf": "Cambiar ubicación de {room} | Cambiar ubicación de {sala}",
		"locationChangeSuccess": "Cambio de ubicación con éxito",
		"roomTag": "Etiqueta de habitación | Etiquetas de habitación",
		"newTag": "Nueva etiqueta",
		"newStatus": "Nuevo estado",
		"issueDeletedFrom": "Asunto eliminado de {room}",
		"locationCategory": "Categoría de ubicación | Categorías de ubicación",
		"roomCategory": "Categoría de sala | Categorías de sala",
		"newCategory": "Nueva categoría",
		"bedroomTv": "Televisión de la habitación",
		"meetingRoomTV": "TV de sala de reuniones"
	},
	"serviceConfig": {
		"saveConfig": " Guardar configuración",
		"disableDailyRebootWarning": "Advertencia: Esto impedirá que algunos dispositivos reciban actualizaciones de software automáticas",
		"forceTvMode": "Esto mostrará los canales de TV cuando se encienda el televisor, en lugar del menú principal configurado",
		"rebootTime": "Hora de reinicio",
		"rebootTimeInvalid": "Hora de reinicio no válida",
		"instantContentPush": "Inserción de contenido instantáneo",
		"instantContentPushDescription": "Al habilitar esta opción, se insertarán todos los cambios de contenido en los dispositivos afectados inmediatamente y sin preguntar.",
		"useInternalURL": "Esta opción hará que el TV utilice la URL de TV interna"
	},
	"login": {
		"terms": "Términos",
		"resetPassword": "Restablecer contraseña",
		"language": "Idioma",
		"username": "Nombre de usuario",
		"password": "Contraseña",
		"logIn": "Iniciar sesión",
		"cloudManagementPortal": "Portal de gestión",
		"enterUsername": "Introduzca su nombre de usuario y contraseña",
		"enterEmail": "Introduzca su dirección de correo electrónico",
		"invalidEmail": "Dirección de correo electrónico no válida",
		"resetMessage": "Se han enviado más instrucciones a {email}",
		"resetFailedMessage": "La solicitud de restablecimiento de contraseña falló {message}",
		"setNewPassword": "Introduzca una nueva contraseña",
		"redirectToLoginPageTitle": "Redirigir a la página de inicio de sesión",
		"redirectToLoginPageText": "Ahora será redirigido a la página de inicio de sesión"
	},
	"latLng": {
		"openMap": "Abrir",
		"lat": "Latitud",
		"lng": "Longitud",
		"selectPosition": "Seleccione una ubicación",
		"addressHint": "Puede escribir manualmente la dirección",
		"addressWarning": "Asegúrese de que la latitud y la longitud coinciden con la dirección",
		"searchFieldLabel": "Buscar la dirección",
		"address": "Dirección",
		"locationAddressErrorMessage": "Póngase en contacto con el administrador de la licencia para añadir la información de la dirección",
		"maxLength": "Longitud máxima"
	},
	"folder": {
		"newResources": "Nuevos recursos",
		"uploadFile": "Subir archivo",
		"folder": "Carpeta | Carpetas",
		"noFolders": "Todavía no hay carpetas",
		"doubleClickSelect": "Doble clic para seleccionar",
		"newFolder": "Nueva carpeta",
		"copy": "Copiar",
		"cut": "Cortar",
		"paste": "Pegar",
		"selected": "{amount} seleccionada {tipo} | {amount} seleccionado {types}",
		"deleting": "Borrar {amount} elemento | Borrar {amount} elementos",
		"root": "Raíz",
		"home": "Inicio",
		"emptyFolder": "Esta carpeta está vacía",
		"createFolder": "Crear carpeta",
		"deleteFolder": "Eliminar carpeta",
		"moveToFolder": "Mover a la carpeta",
		"copyToFolder": "Copiar a carpeta",
		"editFolder": "Editar carpeta",
		"imageSize": "Tamaño de la imagen",
		"unknownImageSize": "Tamaño de imagen desconocido"
	},
	"auth": {
		"loginNotAvailable": "Servicio no disponible - por favor, inténtelo más tarde",
		"wrongUserPass": "Nombre de usuario y/o contraseña incorrectos",
		"responseError": "Error en la respuesta del servidor",
		"noCredentials": "Credenciales no proporcionadas - no se puede iniciar sesión",
		"errorMirageSession": "Ha iniciado sesión correctamente, pero se ha producido un problema al cargar el portal.<br><br>Intente volver a cargarlo o póngase en contacto con su responsable/equipo de asistencia.",
		"errorMirageSessionPPDS": "Ha iniciado sesión correctamente, pero su cuenta de usuario no tiene acceso a ningún cliente de PPDS Wave.<br><br>Intente volver a cargarlo o póngase en contacto con su responsable/equipo de asistencia.",
		"invalidMirageSession": "Ha iniciado sesión correctamente, pero se ha deshabilitado el acceso a The Cloud Portal.<br><br>Pruebe con otro proveedor de inicio de sesión o póngase en contacto con su responsable/equipo de asistencia.",
		"invalidMirageSessionPPDS": "Ha iniciado sesión correctamente, pero se ha deshabilitado el acceso a PPDS Wave Creator.<br><br>Pruebe con otro proveedor de inicio de sesión o póngase en contacto con su responsable/equipo de asistencia.",
		"unknownMirageSession": "Ha iniciado sesión correctamente, pero se ha producido un error desconocido.<br><br>Pruebe con otro proveedor de inicio de sesión o póngase en contacto con su responsable/equipo de asistencia."
	},
	"accessPanel": {
		"resellerTitle": "Gestión de acceso de usuarios de revendedores",
		"resellerDescription": "Configurar el usuario y el acceso a la API para:<br>a) gestionar el revendedor para aprovisionar sitios y licencias (<span style='font-weight: bold;'>Acceso a la cuenta</span>). Esto NO concede acceso a los sitios.<br>b) acceder a los sitios de este revendedor(<span style='font-weight: bold;'>Acceso a sitios</span>). Esto otorga el nivel de acceso dado a TODOS los sitios que pertenecen a este revendedor.",
		"distributorTitle": "Gestión del acceso de los usuarios del distribuidor",
		"distributorDescription": "Configurar el usuario y el acceso a la API para:<br>a) gestionar el distribuidor para aprovisionar a los revendedores (<span style='font-weight: bold;'>Acceso a la cuenta</span>). Esto NO concede acceso a los revendedores.<br>b) acceder a los revendedores de este distribuidor (<span style='font-weight: bold;'>Acceso a revendedores</span>). Esto otorga el nivel de acceso dado a TODOS los revendedores que pertenecen a este distribuidor.",
		"domainTitle": "Administración de acceso de usuario a dominios",
		"domainDescription": "Configure el acceso de usuario y de API para:<br>a) administrar el dominio para aprovisionar distribuidores (<span style='font-weight: bold;'>Acceso de cuenta</span>). Esto NO otorga acceso a los distribuidores.<br>b) acceder a estos distribuidores de dominio (<span style='font-weight: bold;'>Acceso de distribuidor</span>). Esto otorga el nivel de acceso correspondiente a TODOS los distribuidores que pertenecen a este dominio.",
		"resellerAccess": "Acceso del revendedor",
		"sharingContextsAccess": "Acceso a dominios para compartir",
		"deploymentAccess": "Acceso al sitio",
		"themeAccess": "Acceso al tema",
		"distributorAccess": "Acceso de distribuidor",
		"domainAccess": "Acceso de dominio",
		"accessControl": "Control de acceso",
		"accessControlDescription": "Seleccione el revendedor para editar",
		"accessError": "No tiene acceso a la administración de usuarios para ningún {contextType}"
	},
	"theme": {
		"theme": "Tema | Temas",
		"importWindowHeader": "Establecer carpeta de destino",
		"editThemeImage": "Editar imagen de tema",
		"imagePlaceholder": "No se ha establecido ninguna imagen",
		"editThemeCard": "Editar tarjeta de tema",
		"publishSnapshot": "Publicar tema",
		"publishDescription": "La publicación de un tema sobrescribirá cualquier versión anterior publicada. No tiene efecto en los sitios que importaron las versiones anteriores",
		"publishSharingDescription": "El uso compartido de la versión publicada se configura en Inicio -> Revendedor -> Temas. Para configurarlo, necesitará un acceso compartido de revendedor.",
		"lastPublished": "Última publicación",
		"ThemeNotPublished": "El tema no está publicado actualmente",
		"resourceStats": "Estadísticas de recursos",
		"editorInfo": "Tenga en cuenta que debe comprobar los derechos de autor de las imágenes y la disposición general de todo el conjunto de elementos antes de hacer clic en \"Publish Snapshot\", lo que permitirá que todas las cuentas de usuario designadas importen el tema",
		"noFeaturesActivated": "No hay funciones activadas...",
		"importInfo": "Importar temas de menús interactivos completos. No se modificará ningún contenido existente",
		"importInfoTheme": "Importar otros temas a este tema",
		"import": "Importar",
		"folderExists": "La carpeta ya existe",
		"importLoader": "Importando tema",
		"importSuccess": "Your theme has been successfully imported and can be found under \"Content\"",
		"importSuccessAsync": "The import job has started. Please wait a couple of minutes before working with the imported theme",
		"importError": "Elimine las carpetas con el nombre: {folder} bajo \"Content\" e intente importar de nuevo",
		"previewConfiguration": "Configuración de la vista previa del tema",
		"savePreviewError": "¡No se pudo guardar la configuración!",
		"launchPreview": "Lanzamiento de la vista previa",
		"noConfigurationFound": "ERROR No se han encontrado conjuntos de configuración...",
		"themePublished": "Tema publicado",
		"published": "publicado",
		"themePublishedError": "No se ha podido publicar el tema",
		"themeSharingSuccess": "Tema compartido actualizado",
		"themeSharingError": "No se puede actualizar la compartición",
		"themeEmptyError": "No se puede configurar el uso compartido antes de publicar el tema",
		"fetchError": "No se pueden obtener los datos de compartición actuales",
		"editTitle": "Editar tarjeta para establecer un título",
		"editDescription": "Editar tarjeta para establecer una descripción",
		"shareWith": "Compartir con",
		"shareAll": "Todos",
		"shareSubGroup": "Subgrupo",
		"shareSingle": "Simple",
		"successfullyImported": "Importado con éxito",
		"successfullyImportedAsync": "Se inició el trabajo de importación",
		"importFailed": "Importación fallida",
		"newTheme": "Nuevo tema",
		"preview": "Vista previa del tema",
		"previewNotAvailable": "Vista previa no disponible - póngase en contacto con el servicio de asistencia",
		"previewInfo": "Inicie un cliente de TV virtual en su navegador para probar el tema.<br>Esta vista previa no tendrá marca de agua",
		"loadThemesError": "Imposible recuperar los temas",
		"themeControl": "Control del tema",
		"themeControlDescription": "Seleccione el tema a editar",
		"enablePublishInfo": "Configure toda la información de publicación (título, descripción e imagen del tema) para habilitar la publicación",
		"enableSharing": "Debe configurar al menos un recurso compartido para su tema para poder publicarlo",
		"panel": {
			"title": "Gestión de temas para revendedores",
			"description": "Configurar temas"
		},
		"themeName": "Nombre del tema"
	},
	"sharingContext": {
		"type": "Contexto para compartir | Contextos para compartir",
		"rootFolderPrefix": "Suministrado",
		"new": "Nuevo proveedor de recursos",
		"deleteConfirmTitle": "Eliminar proveedor de recursos",
		"deleteConfirmMsg": "Confirme si desea eliminar este proveedor de recursos",
		"editorInfo": "Cualquier cambio realizado en los archivos multimedia en el contexto de este proveedor de recursos podría estar disponible instantáneamente para todos los sitios con los que comparte este proveedor.<br>Tenga también MUCHO cuidado al eliminar elementos, ya que podrían estar utilizándose en los sitios.",
		"deleteWarning": "¡ADVERTENCIA! Tenga MUCHO cuidado al eliminar elementos, ya que podrían estar utilizándose en sitios",
		"deleteConfirm": "Confirmo que deseo eliminar este/a {type} | Confirmo que deseo eliminar estos/as {type}"
	},
	"signage": {
		"signage": "Señalización",
		"schedule": "Programación | Programaciones",
		"addTimeslot": "Añadir intervalo de tiempo",
		"contentSchedule": "Programación de contenido|Programaciones de contenido",
		"noSchedules": "No se han configurado programaciones. Añada una pantalla utilizando el código del instalador o cree una programación.",
		"mergeDefaultTitle": "Fusionar con contenido predeterminado",
		"mergeDefaultConfirm": "¿Seguro que desea fusionar este intervalo de tiempo con el contenido predeterminado?",
		"mergeDefaultTimeslotTitle": "FUSIONANDO CON CONTENIDO PREDETERMINADO"
	},
	"pms": {
		"pmsHeader": "Configuración del sistema de gestión de la propiedad",
		"vendor": "Vendedor",
		"accessToken": "Token de acceso",
		"tigerAccessTokenHint": "Código de autenticación para la API de TigerTMS: también llamado 'wsUserKey' en los sistemas TigerTMS",
		"siteName": "Nombre del sitio",
		"tigerSiteNameHint": "El identificador único para este hotel será generado por TigerTMS",
		"directoryId": "ID de directorio",
		"soapApiUrl": "Punto de conexión de API de SOAP",
		"soapApiUrlHint": "URL para la comunicación con iLink de TigerTMS: el valor predeterminado es 'https://checkout.tigertms.com/GenericEXT/' si se deja vacío",
		"linkCheckerIn": "Habilitar la monitorización de estado para la comunicación ENTRANTE con TigerTMS",
		"linkCheckerOut": "Habilitar la monitorización de estado para la comunicación SALIENTE con TigerTMS",
		"enableDailyReset": "Habilitar reinicio diario",
		"pmsLink": "Vínculo de PMS",
		"lastMsgReceived": "(Último/s recibidos hace {time})",
		"hour": "hora",
		"minute": "minuto",
		"forceSync": "forzar sincronización",
		"forceSyncSuccess": "¡Se ha enviado la solicitud de sincronización!",
		"forceSyncError": "¡La solicitud de sincronización ha fallado!",
		"pmsGetVendorError": "¡No se pudo obtener la lista de proveedores de {pms}!",
		"ip": "IP",
		"port": "Puerto",
		"useChecksum": "Utilizar suma de comprobación",
		"pmsVendor": "Proveedor de PMS |Proveedores de PMS",
		"defaultTimeoutSeconds": "Tiempo de espera de mensaje predeterminado (segundos)",
		"charSet": "Conjunto de caracteres"
	},
	"cast": {
		"config": {
			"adminConfigHeader": "Configuración del sistema de administración de Chromecast",
			"authToken": "Token de autenticación",
			"authTokenHint": "Token de seguridad para el sistema de administración de Chromecast.",
			"networks": {
				"createNew": "Crear nuevo/a",
				"addNetworkWithType": "Añadir red de {type}",
				"addChromecastNetwork": "Añadir Chrom",
				"useExisting": "Utilizar existente/s",
				"interfaceName": "Nombre de interfaz",
				"parentInterface": "Interfaz principal",
				"networkPrefixLength": "Longitud del prefijo de red",
				"vlanTag": "Etiqueta de VLAN",
				"chromecastNetworks": "Redes de Chromecast",
				"clientNetworks": "Redes de cliente",
				"proxyIp": "IP de proxy",
				"vlanId": "VlanId",
				"interface": "Interfaz",
				"parentIface": "Iface principal",
				"ipAddressHint": "Introduzca la dirección IP para esta interfaz. Será la dirección donde los dispositivos de esta red puedan acceder a este servidor.",
				"ipPlaceholder": "192.168.0.10",
				"networkPrefixPlaceholder": "24",
				"vlanTagPlaceholder": "10"
			},
			"hostPlaceholder": "0.0.0.0",
			"networkProxyHost": "Host de proxy de red",
			"networkProxyHostHint": "La dirección de host para configurar la conexión entre el portal y el sistema de administración de Chromecast.",
			"proxy": "Proxy",
			"chromecastProxy": "Configuración de proxy de Chromecast",
			"chromecastNetworkType": "Red de Chromecast",
			"chromecastProxyServerNetworkConfig": "Configuración de red del servidor proxy de Chromecast",
			"chromecastProxyServerStatus": "Estado del servidor proxy de Chromecast",
			"networkInterfaceStatus": "Estado de la interfaz de red",
			"chromecastProxyServerSignageStatus": "Estado de señalización del servidor proxy de Chromecast",
			"proxyIsInternetGateway": "El proxy es la puerta de enlace de Internet",
			"internetInterface": "Interfaz de Internet",
			"isGatewayForChromecasts": "Es puerta de enlace para Chromecasts",
			"isDHCPForChromecasts": "Es DHCP para Chromecasts",
			"isGatewayForClients": "Es puerta de enlace para clientes",
			"isDHCPForClients": "Es DHCP para clientes",
			"chromecastProxyServerForcedReturnPath": "Ruta de retorno forzado del servidor proxy de Chromecast",
			"webInterfaceForceReturnPath": "Ruta de retorno forzado de interfaz web",
			"webInterfaceForceReturnPathHint": "Déjelo en blanco, a menos que haya varias redes de cliente. Se utiliza para indicar a qué interfaz debe el servidor devolver la respuesta para cualquier solicitud de los clientes.",
			"forcedReturnPathGateway": "Puerta de enlace de ruta de retorno forzado",
			"forcedReturnPathGatewayPlaceholder": "Puerta de enlace predeterminada",
			"forcedReturnPathGatewayHint": "Es la puerta de enlace conectada a la red de ruta de retorno forzado. Déjela en blanco para usar la puerta de enlace predeterminada del servidor Chromecast",
			"advancedNetworking": "Redes avanzadas",
			"proxyServerConfig": "Configuración de servidor proxy",
			"chromecastConfig": "Configuración de Chromecast",
			"pairingCodeConfig": "Configuración de código de emparejamiento",
			"clientRegistrationHost": "Host de registro de cliente",
			"clientRegistrationHostHint": "Introduzca el dominio o la dirección IP que señala a este servidor en la red de cliente. No añada http:// ni ningún esquema de dirección URL.",
			"clientRegistrationHostPlaceholder": "difusión.ejemplo.com",
			"guestWifiName": "Nombre de wifi de huésped",
			"guestWifiNameHint": "El nombre de wifi que se mostrará en pantalla cuando se utilice el modo independiente. Consulte la documentación sobre personalización para obtener más información.",
			"guestWiFiPassword": "Contraseña de wifi de huésped",
			"guestWiFiPasswordHint": "La contraseña de wifi que se mostrará en pantalla cuando se utilice el modo independiente. Consulte la documentación sobre personalización para obtener más información.",
			"enableSignageIntegration": "Habilitar la integración de señalización",
			"globalChromecastName": "Nombre global de Chromecast",
			"globalChromecastNameHint": "Nombre de Chromecast que se debe mostrar a todos los huéspedes.",
			"globalChromecastNamePlaceholder": "Chromecast en la habitación",
			"timeZone": "Zona horaria",
			"timeZoneHint": "Esta es la zona horaria para todos los Chromecast conectados.",
			"locale": "Idioma",
			"localeHint": "Este es el idioma de visualización de todos los Chromecast conectados.",
			"pairingCodeSet": "Conjunto del código de emparejamiento",
			"pairingCodeSetHint": "Conjunto de caracteres para generar código de emparejamiento. Los caracteres que se introduzcan aquí serán los únicos que se utilizarán para generar el código.",
			"pairingCodeIsCaseSensitive": "En el código de emparejamiento se distingue entre mayúsculas y minúsculas",
			"pairingCodeLength": "Longitud de código de emparejamiento",
			"pairingCodeTimeoutMinutes": "Minutos de tiempo de espera del código de emparejamiento",
			"pairingCodeTimeoutMinutesHint": "Cuánto tiempo debe ser válido cada código antes de mostrar un nuevo código. Cuando se utiliza, se muestra un nuevo código en todos los casos. (De forma predeterminada, 10 minutos)",
			"pairTimeoutHours": "horas de tiempo de espera de emparejamiento",
			"pairTimeoutHoursHint": "Cuánto tiempo debe estar un huésped emparejado antes de que se desconecte automáticamente. (De forma predeterminada, 5 horas). Para usar un tiempo establecido por día, marque la siguiente casilla de verificación y configure cada día por separado.",
			"useCustomTimeoutTimePerDayInstead": "Utilice un tiempo de espera personalizado por día en su lugar",
			"networkProxySaveError": "Error al guardar la configuración de proxy de red",
			"saveError": "Error al guardar la configuración",
			"saveSuccess": "Configuración guardada",
			"connectionSuccess": "Conexión establecida",
			"connectionFailure": "No se pudo establecer conexión",
			"interfaceFetchFailure": "No se pudieron recuperar las interfaces",
			"reloadTooltipDefault": "Obtiene datos guardados",
			"resetTooltip": "Restablece la configuración actual obteniendo una guardada.",
			"routingOfClientChromecastSessions": "Admite enrutamiento de sesiones de Chromecast de cliente",
			"routingOfClientNetworkMulticast": "Admite enrutamiento de multidifusión de red de cliente",
			"supportNetflix": "Admite Netflix en difusiones Gen4 (AndroidTV)",
			"failedToLoadMsg": "No se ha podido cargar la configuración"
		},
		"eventLogs": {
			"typeDescription": "Registro de eventos",
			"time": "Hora",
			"eventName": "Nombre de evento",
			"castUdn": "UDN de difusión",
			"castName": "Nombre de difusión",
			"clientVid": "VID de cliente",
			"additionalData": "Datos adicionales"
		},
		"response": {
			"chromecastFetchFailed": "No se han podido obtener Chromecasts.",
			"unregisteredCastFetchFailed": "No se han podido obtener Chromecasts desregistrados",
			"activeSessionFetchFailed": "No se han podido obtener sesiones activas",
			"statusFetchFailed": "No se han podido obtener estados de Chromecast",
			"statisticsFetchFailed": "No se han podido obtener estadísticas",
			"eventLogFetchFailed": "No se ha podido obtener el registro de eventos",
			"chromecastProxyConfigFetchFailed": "No se ha podido obtener la configuración de proxy de Chromecast",
			"networksFetchFailed": "No se han podido obtener redes",
			"advancedNetworkingFetchFailed": "No se ha podido obtener la configuración de red avanzada"
		},
		"status": {
			"reassociationBtnText": "Reasociación a SD",
			"reassociationTitle": "¿Desea reasociar la señalización?",
			"reassociationWarning": "Se perderá la asociación actual con la señalización digital. Solo debe utilizarse en los casos en que la difusión no pueda registrarse con la señalización digital o cuando se haya eliminado la pantalla asociada anteriormente en la señalización digital. \n ¿Desea crear una nueva asociación?",
			"reassociationMsg": "¿Desea crear una nueva asociación?",
			"reassociationSuccess": "Reasociado a SD",
			"reassociationFailed": "No se ha podido reasociar a SD",
			"version": "Versión",
			"serverId": "ID de servidor",
			"licensedFor": "Con licencia para",
			"status": "Estado",
			"eno1": "eno1",
			"wip58s0": "wip58s0",
			"clientId": "ID de cliente",
			"clientToken": "Token de cliente",
			"installerToken": "Token de instalador",
			"installerCode": "Código de instalador",
			"connected": "Conectado a servidor de inserción",
			"authenticated": "Autenticado"
		},
		"chromecasts": {
			"info": {
				"castConfig": "Configuración de Chromecast",
				"deviceAlias": "Alias de dispositivo",
				"deviceName": "Nombre de dispositivo",
				"uptime": "Tiempo de actividad",
				"isConfigurable": "Se puede configurar",
				"wifiMac": "MAC de wifi",
				"ssid": "SSID"
			},
			"refreshStatusBtn": "Actualizar estado",
			"refreshStatusSuccess": "Los datos de Chromecast se están actualizando.\n Esto podría llevar algún tiempo, en función del número de Chromecasts que tenga. Pulse 'Volver a cargar' para ver los datos actualizados",
			"refreshStatusFailed": "No se ha podido actualizar el estado.",
			"saveSuccess": "Se ha actualizado el Chromecast",
			"saveFailed": "No se ha podido actualizar el Chromecast",
			"unregister": "Desregistrar",
			"showConfig": "Mostrar configuración",
			"unregisterConfirmTitle": "¿Desea desregistrar Chromecast?",
			"unregisterConfirmMsg": "¿Seguro que desea desregistrar {cast}?",
			"unregisterSuccess": "Se ha desregistrado {cast}",
			"unregisterFailed": "No se ha podido desregistrar {cast}.",
			"updateStatusSuccess": "Estado actualizado para {number} Chromecast | Estado actualizado para {number} Chromecasts.",
			"updateStatusFailed": "No se ha podido actualizar el estado.",
			"showNameSuccess": "Mostrando nombre para {number} Chromecast | Mostrando nombres para {number} Chromecasts.",
			"showNameFailed": "No se ha podido mostrar el nombre.",
			"rebootSuccess": "Se ha reiniciado {number} Chromecast | Se han reiniciado {number} Chromecasts.",
			"rebootFailed": "No se han podido reiniciar los Chromecasts.",
			"deletePairingsConfirmTitle": "Eliminar emparejamientos",
			"deletePairingsConfirmMsg": "¿Desea eliminar emparejamientos para la habitación?",
			"deletePairingsSuccess": "Emparejamientos eliminados para {number} habitación | Emparejamientos eliminados para {number} habitaciones.",
			"deletePairingsFailed": "No se han podido eliminar los emparejamientos para la habitación {room}.",
			"forceStartCastingFailed": "No se ha podido forzar el inicio de difusión para la difusión {cast}.",
			"chromecastPairingCode": "Código de emparejamiento de Chromecast"
		},
		"unregisteredCasts": {
			"title": "Difusión desregistrada",
			"register": "Registrar",
			"registerChromecast": "Registrar Chromecast",
			"registerSuccess": "Chromecast registrado {cast} en la habitación {room}",
			"genericFailure": "No se ha podido registrar el Chromecast",
			"limitReachedFailure": "Se ha alcanzado el límite de registro de Chromecast",
			"deleteCastConfirmTitle": "Eliminar Chromecast",
			"deleteCastConfirmMsg": "¿Seguro que desea eliminar este Chromecast?",
			"deleteCastFailed": "No se ha podido eliminar el Chromecast",
			"deleteCastSuccess": "Chromecast eliminado"
		},
		"statistics": {
			"fetch": "Obtener",
			"usageInHours": "Uso de la difusión en horas",
			"noOfPairingsPerDay": "Número de emparejamientos por día",
			"appUsageInHours": "Uso de la aplicación en horas",
			"consecutiveHourFilterLabel": "Filtrar eventos",
			"consecutiveHourFilterHint": "Filtra eventos con más horas que la cantidad establecida.",
			"downloadVisiblePeriod": "Descargar periodo visible",
			"exportPairings": "Exportar emparejamientos",
			"exportAppUsage": "Exportar uso de la aplicación"
		},
		"activeSessions": {
			"title": "Sesión activa",
			"pinOrUnpin": "Anclar o desanclar este emparejamiento",
			"pinned": "Anclado",
			"pinSuccess": "Cliente anclado",
			"unpinSuccess": "Cliente desanclado",
			"multiplePinUnpinSuccess": "{clients} anclado/desanclado",
			"pinFailed": "No se pudo anclar el cliente",
			"disconnect": "Desconectar cliente",
			"disconnectConfirmTitle": "Desconectar cliente",
			"disconnectConfirmMsg": "¿Desea desconectar el cliente de Chromecast?",
			"disconnectSuccess": "Cliente desconectado",
			"disconnectFailed": "No se ha podido desconectar el cliente"
		},
		"type": "Chromecast | Chromecasts",
		"runningApp": "Aplicación en ejecución",
		"available": "Disponible",
		"connection": "Con.",
		"mac": "MAC",
		"macAddress": "Dirección MAC",
		"paired": "Emparejado",
		"udn": "UDN",
		"signalNoiseLevel": "S/N",
		"client": "CLI",
		"configurable": "Configurable",
		"connected": "Conectado",
		"createdAt": "Creado en",
		"actions": {
			"updateStatus": "Actualizar estado",
			"deletePairings": "Eliminar emparejamientos",
			"showName": "Mostrar nombre",
			"stopCurrentSession": "Reiniciar",
			"generatePairingCode": "Generar código de emparejamiento",
			"exportSelectedToCsv": "Exportar seleccionados a CSV",
			"forceStartCasting": "Forzar inicio"
		},
		"networkProxySaveConfirmationMsg": "¿Seguro que desea guardar? \n Si la configuración se define de forma incorrecta, la funcionalidad de Chromecast fallará.",
		"castServiceOfflineMsg": "El servicio Chromecast no está disponible actualmente, posiblemente debido a que el servidor está sin conexión o a un error de configuración en los ajustes de Administración/Configuración de Chromecast."
	},
	"streamManagement": {
		"allocatedStreams": {
			"device": "Dispositivo | Dispositivos",
			"allocatedStream": "Transmisión asignada",
			"itemType": "Dispositivo",
			"addDevice": "Añadir dispositivo",
			"editDevice": "Editar dispositivo",
			"deallocateStreamTitle": "¿Desea desasignar la transmisión?",
			"deallocateStreamConfirmMsg": "¿Seguro que desea desasignar la transmisión?",
			"editAllocatedStream": "Editar transmisión asignada",
			"mediaUrl": "URL de archivos multimedia",
			"streamAllocatorConfig": "Configuración del asignador de transmisiones",
			"deallocate": "Desasignar",
			"deleteDeviceMsg": "ADVERTENCIA: Al eliminar dispositivos, se finalizará inmediatamente la sesión de cualquier TV asignado.",
			"deleteDeviceConfirm": "¿Seguro que desea eliminar el dispositivo?",
			"chromecastIntegrationDescription": "Seleccionar para habilitar la integración de Chromecast"
		},
		"config": {
			"minTimeoutWarn": "El tiempo de espera mínimo son 2 minutos"
		},
		"allocationLogEntries": {
			"eventType": {
				"allocationFailure": "No se ha podido asignar la transmisión"
			},
			"eventReason": {
				"noAvailableDevices": "No hay dispositivos disponibles"
			},
			"title": "Registro de asignación de transmisiones",
			"itemType": "Entrada del registro de asignación de transmisiones"
		}
	},
	"jobs": {
		"jobList": {
			"title": "Notificaciones"
		},
		"startMsgs": {
			"imageUpload": "Se ha iniciado la carga de la imagen {title}",
			"fontUpload": "Se ha iniciado la carga de la fuente {title}",
			"themeImport": "Se ha iniciado la importación del tema {title}",
			"logoUpload": "Se ha iniciado la carga del logotipo {title}"
		},
		"successMsgs": {
			"imageUpload": "Se ha cargado la imagen {title}",
			"fontUpload": "Se ha cargado la fuente {title}",
			"logoUpload": "Se ha cargado el logotipo {title}"
		},
		"failedMsgs": {
			"imageUpload": "No se ha podido cargar la imagen {title}",
			"fontUpload": "No se ha podido cargar la fuente {title}",
			"logoUpload": "No se ha podido cargar el logotipo {title}"
		}
	},
	"content": {
		"config": {
			"resourceserver": "Servidor de recursos",
			"resourcesyncservice": "Servicio de sincronización de recursos",
			"networkproxy": "Proxy de red",
			"resourceServerPort": "Puerto del servidor de recursos",
			"resourceServerPortHint": "Introduzca el puerto del servidor de recursos",
			"resourceServerFileLocation": "Ubicación de archivos del servidor de recursos",
			"resourceServerFileLocationHint": "Introduzca la ubicación de archivos para el servidor de recursos",
			"resourceSyncServiceFileLocation": "Ubicación de archivos del servicio de sincronización de recursos",
			"resourceSyncServiceFileLocationHint": "Introduzca la ubicación de archivos donde se deben almacenar todos los archivos del servicio de sincronización de recursos",
			"enableChangingURL": "Habilitar para cambiar la URL",
			"saveConfig": "Guardar configuración",
			"saveConfigWarn": "¿Seguro que desea guardar? Si la configuración se define de forma incorrecta, la funcionalidad de servicio fallará.",
			"saveSuccess": "Configuración guardada",
			"configSaveError": "Error al guardar la configuración",
			"rangeError": "Solo se permiten puertos entre el 1024 y el 49151",
			"checkConfig": "Comprobar configuración",
			"configurationOK": "Configuración correcta",
			"errorWithConfiguration": "Error de configuración",
			"missingConfig": "Pulse VOLVER A CARGAR para obtener la configuración",
			"changeURLWarn": "Advertencia: Si la URL se define de forma incorrecta, la funcionalidad de servicio fallará."
		}
	}
}
