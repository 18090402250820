import BaseCrudModule from '@/store/modules/base/BaseCrudModule';

class BaseContextModule extends BaseCrudModule {
    constructor(api, fetchEndpoint = '', getters) {
        super(api, fetchEndpoint, getters);

        this.addGetters({
            managedBy: (state, getters) => (managedBy) => {
                return getters['sorted'].filter(context => context.managedBy === managedBy);
            },
            managedByMap: (state) => {
                const ctxMap = {};
                const legacies = state.items;
                legacies.forEach(legacy => {
                    if (!ctxMap.hasOwnProperty(legacy.managedBy)) {
                        ctxMap[legacy.managedBy] = [];
                    }
                    ctxMap[legacy.managedBy].push(legacy);
                });
                return ctxMap;
            },
            /** OPTIMIZATION to have a cached way of getting count based on managedBy */
            countManagedByMap: (state) => {
                const countMap = {};
                const legacies = state.items;
                legacies.forEach(legacy => {
                    if (countMap.hasOwnProperty(legacy.managedBy)) {
                        countMap[legacy.managedBy]++;
                    } else {
                        countMap[legacy.managedBy] = 1;
                    }
                });
                return countMap;
            },
            withDirectAccess: () => {
                const items = this.cloneItems().filter(context => {
                    return (context.indirectAccess !== true);
                });
                if(Array.isArray(items)) { Array.sort(items, 'name'); }
                return items;
            },
            withLicensesManagedByMap: (state, getters, rootState, rootGetters) => {
                const managedByMap = {};
                const licenseMap = rootGetters['licenses/byTargetMap'];

                this.cloneItems().forEach(context => {
                    if (!managedByMap.hasOwnProperty(context.managedBy)) {
                        managedByMap[context.managedBy] = [];
                    }
                    if (licenseMap.hasOwnProperty(context.id)) {
                        context.licenses = licenseMap[context.id];
                    } else {
                        context.licenses = [];
                    }
                    managedByMap[context.managedBy].push(context);
                });
                return managedByMap;
            },
        });
    }
}

export default BaseContextModule;
