import apis from '@/lib/api/index';
import Vue from "vue";
import BaseCrudModule from "@/store/modules/base/BaseCrudModule";

const thisModule = 'overviewContexts';

const module = new BaseCrudModule(apis[thisModule], '', null, thisModule);

module.addGetters({
    withStatus: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const licensesByTargetMap = rootGetters['licenses/byTargetMap'];

        if (Array.isArray(data)) {
            data.forEach((entry) => {
                for (const product in entry.products) {
                    const item = entry.products[product];
                    /**
                     * TODO - move mapping into overview serverside?
                     * @type {number}
                     */
                    item.creDate = item.activationDate;
                    item.contractRefs = [];
                    item.maxCastsLic = 0;
                    item.maxSignageLic = 0;
                    item.licenses = [];
                    item.pmsVendor = '';
                    item.flightLicenses = '';

                    const currentDate = new Date();

                    switch (product) {
                        case 'mirage':
                            item.roomCnt = parseInt(_.get(item, 'hospLocStat.totLocs', 0));
                            item.occupied = parseInt(_.get(item, 'hospLocStat.occLocs', 0));
                            item.cliStat = Vue.prototype.$clone(item.cliStat);

                            //Tv Clients
                            item.numberOfClients = _.get(item, 'cliStat.totClis', 0);
                            item.offlineClients = _.get(item, 'cliStat.olClis', 0);
                            const tvDeviceTypes = _.get(item, 'cliStat.devCnt', {});
                            item.maxClientsLic = 0;
                            item.tvTypes = {};
                            item.tvMod = {};
                            item.tvFw = {};

                            //Signage Clients
                            item.numberOfSigClients = _.get(item, 'cliStat.totSigClis', 0);
                            item.sigMod = {}
                            item.sigClientTypes = {};
                            item.offlineSigClients = _.get(item, 'cliStat.olSigClis', 0);
                            const signageDeviceTypes = _.get(item, 'cliStat.sigDevCnt', {});
                            const signageFw = _.get(item, 'cliStat.sigFw');
                            item.sigFw = []

                            //Cast
                            item.numberOfCasts = _.get(item, 'castStat.registeredChromecasts');
                            item.castVersion = _.get(item, 'castStat.castVersion');
                            item.castOnline = _.get(item, 'castStat.online');

                            //Agent Client
                            item.agentOnline = _.get(item, 'cliStat.agentOnline');
                            item.agentVersion = _.get(item, 'cliStat.agentVersion');

                            item.licenses = licensesByTargetMap.hasOwnProperty(entry.id) ? Array.from(licensesByTargetMap[entry.id].filter (lic => {
                                const toDate = new Date(lic.toDate).setHours(11, 59,59);
                                if (currentDate <= toDate) {
                                    return lic.productName
                                }
                            }), lic => lic.productName ) : [];


                            if (signageFw && signageFw.length > 0) {
                                signageFw.forEach( function (it) {
                                    if (it !== "-") {
                                        item.sigFw.push(it);
                                    }
                                });
                            }

                            for (const key in tvDeviceTypes) {
                                const parts = key.split(',');
                                if (parts.length > 0 && typeof parts[0] === 'string') {
                                    const [vendor, deviceType] = parts[0].split('/');
                                    //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                                    if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                                        if (!item.tvTypes.hasOwnProperty(vendor)) {
                                            item.tvTypes[vendor] = tvDeviceTypes[key];
                                        } else {
                                            item.tvTypes[vendor] += tvDeviceTypes[key];
                                        }
                                    }
                                    if (deviceType === 'TV' && parts.length > 1 && parts[1] !== '-') {
                                        if (!item.tvMod.hasOwnProperty(parts[1])) {
                                            item.tvMod[parts[1]] = tvDeviceTypes[key];
                                        } else {
                                            item.tvMod[parts[1]] += tvDeviceTypes[key];
                                        }
                                    }
                                }
                            }

                            for (const key in signageDeviceTypes) {
                                const parts = key.split(',');
                                if (parts.length > 0 && typeof parts[0] === 'string') {
                                    const [vendor, deviceType] = parts[0].split('/');
                                    //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                                    if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                                        if (!item.sigClientTypes.hasOwnProperty(vendor)) {
                                            item.sigClientTypes[vendor] = signageDeviceTypes[key];
                                        } else {
                                            item.sigClientTypes[vendor] += signageDeviceTypes[key];
                                        }
                                    }
                                    if (deviceType === 'SIGNAGE' && parts.length > 1 && parts[1] !== '-') {
                                        if (!item.sigMod.hasOwnProperty(parts[1])) {
                                            item.sigMod[parts[1]] = signageDeviceTypes[key];
                                        } else {
                                            item.sigMod[parts[1]] += signageDeviceTypes[key];
                                        }
                                    }
                                }
                            }

                            if (licensesByTargetMap.hasOwnProperty(entry.id)) {
                                licensesByTargetMap[entry.id].forEach(license => {
                                    const toDate = new Date(license.toDate).setHours(11, 59,59);

                                    if (license.contractRef) {
                                        item.contractRefs.push(license.contractRef);
                                    }
                                    if (Array.isArray(license.featureProvisions) && currentDate <= toDate) {
                                        license.featureProvisions.forEach((featureProvision) => {
                                            switch (featureProvision.feature) {
                                                case 'Clients':
                                                case 'CastManagement':
                                                    if (Array.isArray(featureProvision.resourceProvisions)) {
                                                        featureProvision.resourceProvisions.forEach((resourceProvision) => {
                                                            if (Array.isArray(resourceProvision.limits)) {
                                                                resourceProvision.limits.forEach((limit) => {
                                                                    //console.log(resourceProvision.resource + " " + limit.limit + ":" + limit.value);
                                                                    if (resourceProvision.resource === 'Clients' && limit.limit === 'TvClientCount') {
                                                                        item.maxClientsLic += limit.value;
                                                                    } else if (resourceProvision.resource === 'Clients' && limit.limit === 'SignageClientCount') {
                                                                        item.maxSignageLic += limit.value;
                                                                    } else if (resourceProvision.resource === 'RegisteredCasts' && limit.limit === 'Count') {
                                                                        item.maxCastsLic += limit.value;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                    break;
                                                case 'Pms':
                                                    if (Array.isArray(featureProvision.configurationProvisions)) {
                                                        featureProvision.configurationProvisions.forEach((configurationProvision) => {
                                                            if (configurationProvision.configurationName === 'PmsVendor' && configurationProvision.values.length > 0) {
                                                                item.pmsVendor = configurationProvision.values[0];
                                                            }
                                                        });
                                                    }
                                                    break;
                                                case 'Flights':
                                                    let prefix = 'Iata Codes: ';
                                                    if (!item.flightLicenses.includes(prefix)) {
                                                        item.flightLicenses = prefix;
                                                    }

                                                    let foundIataCodes = false;

                                                    featureProvision.resourceProvisions.forEach(resourceProvision => {
                                                        if (resourceProvision.resource === 'Flights') {
                                                            if (resourceProvision.limits[0].limit === 'IataCode' && Array.isArray(resourceProvision.limits[0].stringValues) && resourceProvision.limits[0].stringValues.length > 0) {
                                                                item.flightLicenses = item.flightLicenses + resourceProvision.limits[0].stringValues[0] + ' ';
                                                                foundIataCodes = true;
                                                            }
                                                        }
                                                    });

                                                    if (!foundIataCodes) {
                                                        item.flightLicenses += 'None';
                                                    }
                                            }
                                        });
                                    }

                                });
                            }

                            break;
                        case 'ctrl':
                        case 'enterprise':
                            item.occupied = isNaN(item.occ) ? 0 : item.occ;
                            item.maxClientsLic = item.maxCli;
                            item.numberOfClients = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.clientsOnline = isNaN(item.cliOnl) ? 0 : item.cliOnl;
                            item.offlineClients = item.numberOfClients - parseInt(item.clientsOnline);
                            break;
                        case 'chromecast':
                            item.occupied = isNaN(item.occ) ? 0 : item.occ;
                            item.maxClientsLic = item.maxCli;
                            item.maxCastsLic = item.maxClientsLic;
                            item.maxClientsLic = 0;
                            item.numberOfClients = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.numberOfCasts = isNaN(item.cliCnt) ? 0 : item.cliCnt;
                            item.clientsOnline = isNaN(item.cliOnl) ? 0 : item.cliOnl;
                            item.offlineClients = item.numberOfClients - parseInt(item.clientsOnline);
                            break;
                    }
                }
            });
            return data;
        }
        return [];
    },
    idMapWithStatus: (state, getters) => {
        return Map.createFromArray(getters.withStatus, 'id');
    }
});

export default module;
