import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from "vue";

const thisModule = 'eventLocations';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    withFullTitle: (state, getters, rootState, rootGetters) => {

        const data = []
        if (Array.isArray(state.items) && state.items.length > 0) {

            const clonedItems = module.cloneItems()
            data.push(...clonedItems);
            //const locationsIdMap = getters['idMapByGetter']('withFullTitleTagsAndParents');

            const locationCategoriesIdMap = rootGetters['locationCategories/idMapByGetter']('translated');
            data.forEach((item) => {
                item.categoryTitle = (item.categoryId !== null)
                        ? Map.retrieveValue(locationCategoriesIdMap, item.categoryId, 'titleTranslated')
                        : '';
                item.titleTranslated = Vue.prototype.$tm(item.title);
                item.fullTitle = item.categoryTitle === null ? item.titleTranslated :
                        item.categoryTitle + " " + item.titleTranslated;
            });

            Array.sort(data, 'fullTitle');
        }

        return data;
    },

});

export default module;
