import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from "vue";

const thisModule = 'systemContextConfigs';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    byIds: (state) => (contextIds) => {

        if(!Array.isArray(contextIds) || contextIds.length === 0) {
            return [];
        }
        const contextMap = _.keyBy(contextIds, item => item);
        return Vue.prototype.$clone(state.items.filter(context => contextMap.hasOwnProperty(context.contextId)));
    },
    contextIdMap: () => {
        return Map.createFromArray(module.cloneItems(), 'contextId');
    },
    deploymentsWithContextInfo: (state, getters, rootState, rootGetters) => {
        const contextMap = rootGetters['deployments/idMap'];
        const items = module.cloneItems().filter( item => contextMap.hasOwnProperty(item.contextId) );
        items.forEach(item => {
            if(contextMap.hasOwnProperty(item.contextId)) {
                const ctx = contextMap[item.contextId];
                item.name = ctx.name;
                item.belongsTo = ctx.belongsTo;
                item.managedBy = ctx.managedBy;
            }
        });
        Array.sort(items, 'name');
        return items;
    },
    deploymentsWithContextInfoManagedByMap: (state, getters) => {
        const managedByMap = {};
        getters.deploymentsWithContextInfo.forEach(context => {
            if (!managedByMap.hasOwnProperty(context.managedBy)) {
                managedByMap[context.managedBy] = [];
            }
            managedByMap[context.managedBy].push(context);
        });
        return managedByMap;
    }
});

export default module;
