import apis from '@/lib/api';
import Validator from "@/lib/helpers/Validator";
import Vue from "vue";

const defaultSettings = {
    darkMode: false,
    language: 'en',
    welcomeDisplayed: false,
    acceptedEulaVersion: 0,
    notifications: 'all'
};

const state = {
    initialized: false,
    fetching: false,
    account: { settings: defaultSettings }
};

const actions = {
    async saveSetting({state, commit, rootState}, data) {

        const settings = Array.isArray(data) ? data : [ data ];

        let hasUpdate = false;
        for(const i in settings) {
            const setting = settings[i];
            if (!state.account.settings.hasOwnProperty(setting.key) || (state.account.settings[setting.key] !== setting.val)) {
                hasUpdate = true;
                commit('setSetting', setting);
            }
        }
        if(!hasUpdate) {
            return;
        }

        if (Validator.isEmpty(rootState.auth.account.id)) {
            console.error("Cannot save settings - no account ID");
            return;
        }
        await apis['accounts'].patch({ settings: state.account.settings }, rootState.auth.account.id);
    },
    async saveNameAndPhone({state, commit, rootState}, data) {
        const { name, phone } = data;

        if(!name) {
            console.error("Cannot save contact info - name missing");
            return;
        }

        const response = await apis['accounts'].patch({
            contactInformation: {
                name: name,
                phone: phone
            }
        }, rootState.auth.account.id);

        if (response.status < 400) {
            const account = response.data;
            if (Validator.isNotEmpty(account)) {
                account.settings = {...defaultSettings, ...account.settings};
                commit('setAccount', account);
            }
        }
        return response;
    },
    async changeEmail({state, commit, rootState}, data) {
        const { email, password } = data;

        if(!email || !password) {
            console.error("Cannot save contact info - data missing");
            return;
        }

        const response = await apis['accounts'].patch({
            name: email,
            password: password
        }, rootState.auth.account.id);

        if (response.status < 400) {
            const account = response.data;
            if (Validator.isNotEmpty(account)) {
                account.settings = {...defaultSettings, ...account.settings};
                commit('setAccount', account);
            }
        }
        return response;
    },
    async changePassword({state, commit, rootState}, data) {
        const { oldPassword, newPassword } = data;

        if(!oldPassword || !newPassword) {
            console.error("Cannot update password - data missing");
            return;
        }

        const response = await apis['accounts'].patch({
            password: oldPassword,
            passwordCredentials: {
                password: newPassword
            }
        }, rootState.auth.account.id);

        if (response.status < 400) {
            const account = response.data;
            if (Validator.isNotEmpty(account)) {
                account.settings = {...defaultSettings, ...account.settings};
                commit('setAccount', account);
            }
        }
        return response;
    },
    async fetch({commit, rootState, dispatch}, userId) {

        const result = { success: false, errorMessage: '' };
        if (Validator.isEmpty(rootState.auth.account.id)) {
            result.errorMessage = 'UserId not provided - unable to get settings';
            return result;
        }
        console.log("USER ID: " + rootState.auth.account.id);
        const response = await apis['accounts'].get('/' + rootState.auth.account.id);

        if (response.status < 400) {
            const account = response.data;
            if (Validator.isNotEmpty(account)) {
                result.success = true;
                account.settings = { ...defaultSettings, ...account.settings};
                const token = Vue.$keycloak.tokenParsed;
                account.contactInformation = {
                    "name": token.name,
                    "phone": token.phone_number,
                    "email": token.email
                };
                commit('setAccount', account);
                commit('setInitialized', true);
                return result;
            } else {
                result.errorMessage = "Empty response from server";
                commit('setAccount', { settings: defaultSettings });
                commit('setInitialized', false);
                return result;
            }
        } else if (response.status === 401) {
            result.errorMessage = 'Wrong username and/or password';
            console.log(`Authentication failed: ${response.status} (${response.message})`);
        } else {
            result.errorMessage = 'An error occurred - please try again later';
            console.log(`User settings loading failed: ${response.status} (${response.message})`)
        }
        return result;
    },
    clearSettings({commit}) {
        commit('setAccount', { settings: defaultSettings });
        commit('setInitialized', false);
    }
};

const mutations = {
    setAccount(state, account) {
        state.account = account;
    },
    setSetting(state, { key, val }) {
        state.account.settings[key] = val;
    },
    setInitialized(state, initialized) {
        state.initialized = initialized;
    },
    setFetching(state) {
        state.fetching = true;
    }
};

const getters = {
    setting: (state) => (key) => {
        return state.account.settings.hasOwnProperty(key) ? state.account.settings[key] : null;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
