import Vue from 'vue';
import './lib/extenders';
import vuetify from './plugins/plugins';
import App from './App';
import i18n from './i18n';
import router from './router';
import apis from './lib/api/index';
import store from './store/index.js';
import lodash from 'lodash';
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'reset-css';
import './plugins/leaflet';
import English from './i18n/lang/en/translations';
import DumpMissingTranslations from './i18n/dumpMissingTranslations';
import JsonCSV from 'vue-json-csv';
import '@/plugins/keycloak-plugin.js';
import PortalTheme from '@/lib/theme/PortalTheme'
import AuthManager from "@/lib/helpers/AuthManager";
import {
    Storage,
    Validator,
    moment,
    ApiHelper,
    UrlManager,
    Resources
} from './lib/helpers/index';

Vue.config.productionTip = false;
Vue.prototype.$apis = apis;
Vue.prototype.$clone = data => structuredClone(data); //Vue.prototype.$clone = lodash.cloneDeep;
Vue.prototype.$lodash = lodash;
Vue.prototype.$storage = Storage;
Vue.prototype.$validator = Validator;
Vue.prototype.$responseCheck = ApiHelper.responseSuccessCheck;
Vue.prototype.$urlManager = UrlManager;
Vue.prototype.$moment = moment;
Vue.prototype.$resources = Resources;
Vue.prototype.$vuetify = vuetify;

Vue.component('downloadCsv', JsonCSV);

if (process.env.NODE_ENV === "production") {
    window.originalLog = console.log;
    console.log = () => {
    };
}

function startMiragePortal() {

    new Vue({
        vuetify,
        i18n,
        router,
        store: store(apis),
        render: h => h(App),
    }).$mount('#app');

    if (process.env.NODE_ENV !== "production") {
        window.dumpTranslations = () => {
            return English;
        };
        window.dumpMissingTranslations = () => {
            return DumpMissingTranslations();
        }
    }
}


// Init keycloak

let kcIdpHint = '';
const hostName = window.location.hostname;
if (hostName.includes('wave')) {
    kcIdpHint = 'wave';
}

const updateKeycloakToken = () => {
    if (Vue.$keycloak != null) {
        Vue.$keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                console.log('Keycloak token refreshed' + refreshed);
            } else {
                console.log('Keycloak token is still valid for another ' + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds, no need to refresh');
            }
        }).catch((e) => {
            console.error('Failed to refresh token: ', e);
        });
    }
};

Vue.$keycloak.onAuthRefreshError = () => {
    alert("You session has expired, redirecting to login page.");
    location.reload();
};

Vue.$keycloak.init({
    checkLoginIframe: false
}).then((authenticated) => {
    console.log('Authenticated to Keycloak? ', authenticated);
    if (authenticated) {
        Storage.set("keycloakToken", Vue.$keycloak.token);
        startMiragePortal();

        // Interval for token refresh
        setInterval(() => {
            updateKeycloakToken();
        }, (60 * 1000 * 5));
        addEventListener('focus', (event) => {
            updateKeycloakToken();
        });
    } else {
        if (AuthManager.getEnableAutoSSO() === "false") {
            Vue.$keycloak.login();
        } else {
            Vue.$keycloak.login({idpHint: kcIdpHint, scope: 'profile email phone'});
        }
    }
});

