import apis from '@/lib/api/index';
import ApiHelper from '@/lib/helpers/ApiHelper';
import Vue from 'vue';

const resCheck = ApiHelper.responseSuccessCheck;

const state = {
    dataSetChanged: { dataSet: "", created: null, updated: null, deleted: null },
    resourceUpdated: { resource: "", created: null, updated: null, deleted: null },
    registered: false,
    connected: false,
    subscribedContext: null
};

const mutations = {
    setDatasetChanged(state, data) {
        state.dataSetChanged = data;
    },
    setResourceUpdated(state, resource) {
        state.resourceUpdated = resource;
    },
    setRegistered(state, registered) {
        state.registered = registered;
    },
    setConnected(state, connected) {
        state.conected = connected;
    },
    setSubscribedContext(state, context) {
        state.subscribedContext = context;
    }
};

const actions = {
    reset: () => {
    },
    handleDataSetChanged: ({ commit }, { dataSet, created, updated, deleted }) => {
        commit('setDataSetChanged', { dataSet: dataSet, created: created, updated: updated, deleted: deleted });
    },
    handleResourceUpdated: ({ commit }, { feature, resource, created, updated, deleted }) => {
        commit('setResourceUpdated', { feature: feature, resource: resource, created: created, updated: updated, deleted: deleted });
    },
    handleRegister: ({ commit }, { registered }) => {
        commit('setRegistered', registered);
    },
    handleConnectionChange: ({ commit }, { connected }) => {
        commit('setConnected', connected);
    },
    async subscribeContextEvents({commit}, contextId) {
        const response = await apis['session'].put({ contextId: contextId }, 'subscribe');
        if (resCheck(response)) {
            commit('setSubscribedContext', contextId);
        } else {
            commit('setSubscribedContext', null);
        }
    },
    pushMessage: async ({commit}, {screens, messageType, messageData}) => {
        const clientIds = screens.filter((elem) => { return (elem.hasOwnProperty('online') && elem.online === true); }).map(s => s.id);
        if(clientIds.length === 0) {
            console.error("No online screens in push request!");
            return;
        }
        const pushRequest = {
            clients: clientIds,
            messageType: messageType,
            messageData: messageData
        };

        const response = await Vue.prototype.$apis['pushMessage'].post(pushRequest);
        // if (!Vue.prototype.$responseCheck(response)) {
        //     Vue.prototype.$notification.show('error', 'Push message error!');
        // }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
