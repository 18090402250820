class Storage {

    static get(key, defaultValue = null) {
        if (!localStorage.hasOwnProperty(key)) {
            return defaultValue;
        }
        return localStorage.getItem(key);
    }

    static getSession(key, defaultValue = null) {
        if (!sessionStorage.hasOwnProperty(key)) {
            return defaultValue;
        }
        return sessionStorage.getItem(key);
    }

    static clear(key) {
        localStorage.removeItem(key);
    }

    static getBoolean(key, defaultValue = false) {
        if (!localStorage.hasOwnProperty(key)) {
            return defaultValue;
        }

        return localStorage.getItem(key) === 'true';
    }

    static set(key, value) {
        localStorage.setItem(key, value);
    }

    static setSession(key, value) {
        sessionStorage.setItem(key, value);
    }
}

export default Storage;