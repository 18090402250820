import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from "vue";

const thisModule = 'allocationLogEntries';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    withExplanation: (state, getters, rootState, rootGetters) => {

        const data = [];

        if (Array.isArray(state.items) && state.items.length > 0) {

            const allocatedStreams = rootGetters['allocationLogEntries/sorted'];

            allocatedStreams.forEach((item) => {

                let tempItem = Vue.prototype.$clone(item);

                if (tempItem.event) {
                    switch(tempItem.event){
                        case "allocationFailure": {
                            tempItem.event = Vue.prototype.$app.$t('streamManagement.allocationLogEntries.eventType.allocationFailure');
                        }
                    }
                }

                if (tempItem.reason) {
                    switch (tempItem.reason) {
                        case "noAvailableDevices": {
                            tempItem.reason = Vue.prototype.$app.$t('streamManagement.allocationLogEntries.eventReason.noAvailableDevices');
                        }
                    }
                }



                data.push(tempItem);
            });
        }


        return data;
    }
});

export default module;