<!--suppress JSUnfilteredForInLoop -->
<template>
    <v-snackbar
        v-model="message.show"
        :bottom="message.bottom"
        :top="!message.bottom"
        right
        multi-line
        :vertical="$app.desktop"
        color="info"
        :timeout="10000000000"
    >
        <v-row>
            <v-col class="shrink" style="padding-right: 0;">
                <v-icon>help</v-icon>
            </v-col>
            <v-col>
                {{messageText}}
            </v-col>
        </v-row>
        <v-btn
            dark
            text
            :elevation="$app.desktop ? 1 : 0"
            @click="hideMessage"
        >
            {{$t('generic.gotIt')}}
        </v-btn>
    </v-snackbar>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: "TheHelpMessage",
        data() {
            return {
                messages: [],
                message: {
                    show: false,
                    text: null,
                    id: null
                }
            }
        },
        created() {
            Vue.prototype.$helpMessage = this;
        },
        watch: {
            messages(newMessages, oldMessages) {
                if (newMessages.length > oldMessages.length) {
                    if (!this.message.show) {
                        this.displayMessage();
                    }
                } else if (newMessages.length > 0) {
                    this.displayMessage();
                }
            }
        },
        computed: {
            messageText() {
                return this.$validator.isEmpty(this.message.text) ? '' : this.message.text;
            }
        },
        methods: {
            // Devices: desktop, mobile, all
            // component = {id: idOfHtmlElement, icon: arrow_forward, pos: {top: '50px', left: '20px'}}
            show(id, message, device = 'desktop', component, bottom = true) {
                // if (this.$app.desktop && device === 'mobile') {
                //     return;
                // }
                // if (!this.$app.desktop && device === 'desktop') {
                //     return;
                // }
                //
                // id = "help." + id;
                //
                // if (this.$validator.isEmpty(this.$storage.get(id)) || this.$storage.get(id) === 'null') {
                //     this.$storage.set(id, true);
                //
                //     this.messages.push({
                //         id: id,
                //         text: message,
                //         component: component,
                //         bottom: bottom
                //     });
                // }
            },
            displayMessage() {
                const message = this.messages[0];

                this.message = {
                    show: true,
                    text: message.text,
                    id: message.id,
                    component: message.component,
                    bottom: message.bottom,
                    iconDiv: null
                };

                let elem = null;

                if (this.$validator.isNotEmpty(message.component)) {
                    elem = document.getElementById(message.component.id);

                    if (this.$validator.isNotEmpty(elem)) {
                        this.message.iconDiv = document.createElement('div');
                        this.message.iconDiv.className += 'help-message-indicator material-icons';

                        for (let key in message.component.pos) {
                            this.message.iconDiv.style[key] = message.component.pos[key];
                        }

                        this.message.iconDiv.innerHTML = message.component.icon;
                        elem.appendChild(this.message.iconDiv);
                        this.message.elem = elem;
                    }
                }

                this.messageTimeout = setTimeout(() => {
                    this.hideMessage();
                }, 10000);
            },
            hideMessage() {
                clearTimeout(this.messageTimeout);

                if (this.$validator.isNotEmpty(this.message.iconDiv)) {
                    this.message.iconDiv.parentNode.removeChild(this.message.iconDiv);
                    //this.message.elem.className += ' hide-help-indicator';
                }

                this.message.show = false;
                this.messages.splice(0, 1);
            }
        }
    }
</script>

<style lang="scss">
    .help-message-indicator {
        position: absolute;
        font-size: 44px;
        height: 50px;
        width: 50px;
        background: #eeeeee;
        border-radius: 25px;
        border: 1px solid var(--v-accent-base);
        color: var(--v-accent-base);
        animation-name: helpMessageIndicatorAnim;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        pointer-events: none;
    }

    .hide-help-indicator .help-message-indicator {
        display: none;
    }

    @keyframes helpMessageIndicatorAnim {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        50% {
            opacity: 0.7;
            transform: scale(1.3);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }
</style>