import lodash from "lodash";
import Vue from 'vue';

Array.findFirst = (list, predicate, clone = false) => {
    const foundRecord = lodash.find(list, predicate);

    if (foundRecord) {
        return clone ? lodash.cloneDeep(foundRecord) : foundRecord;
    }

    return null;
};

Array.findFirstByField = (list, field, value, clone = false) => {
    return Array.findFirst(list, r => r[field] === value, clone);
};

Array.findAllByField = (list, field, value) => {
    const matches = [];

    for (let i = 0; i < list.length; i++) {
        if (list[i][field] === value) {
            matches.push(list[i]);
        }
    }

    return matches;
};

Array.merge = (list1, list2, allowDuplicates = false, idField = 'id') => {
    let list = list1.concat(list2);

    if (!allowDuplicates) {
        let map = new Map();

        list.forEach((item) => {
            map.set(item[idField], item);
        });

        list = [...map.values()];
    }

    return list;
};

Array.contains = (list, field, value) => {
    return Array.findFirstByField(list, field, value) !== null;
};

Array.remove = (list, toRemove) => {
    const index = list.indexOf(toRemove);

    if (index > -1) {
        list.splice(index, 1);
        return true;
    }

    return false;
};

Array.removeByField = (list, field, value) => {
    const index = list.findIndex(o => o[field] === value);

    if (index > -1) {
        list.splice(index, 1);
        return true;
    }

    return false;
};

Array.removeDuplicates = (list, idField) => {
    const ids = [];

    for (let i = 0; i < list.length; i++) {
        if (ids.includes(list[i][idField])) {
            list.splice(i, 1);
        } else {
            ids.push(list[i][idField]);
        }
    }
};

Array.removeMultipleByField = (list, field, toRemove) => {
    toRemove.forEach(item => {
        Array.removeByField(list, field, item[field]);
    });
};

Array.update = (list, item, idField) => {
    const index = list.findIndex(obj => obj[idField] === item[idField]);

    if (index > -1) {
        Vue.set(list, index, item);
        return list[index];
    } else {
        list.push(item);
        return null;
    }
};

Array.updateMultiple = (list, items, idField) => {
    items.forEach((item) => {
        Array.update(list, item, idField);
    });
};

Array.sort = (list, field, asc = true, caseSensitive = false) => {
    list.sort((a, b) => {
        if (caseSensitive) {
            if (a[field] > b[field]) {
                return asc ? 1 : -1;
            } else if (a[field] < b[field]) {
                return asc ? -1 : 1;
            }

            return 0;
        } else {
            try {
                if(a[field] === null || a[field] === undefined || b[field] === null || b[field] === undefined) {
                    return 0;
                } else if (a[field].toString().toLowerCase() > b[field].toString().toLowerCase()) {
                    return asc ? 1 : -1;
                } else if (a[field].toString().toLowerCase() < b[field].toString().toLowerCase()) {
                    return asc ? -1 : 1;
                }
            } catch (error) {
                console.error(error);
            }

            return 0;
        }
    });
};

Array.sortWithReturn = (list, fields, order = 'asc') => {
    return lodash.orderBy(list, fields, order);
};

Array.diff = (list1, list2, field) => {
    const diff = [];
    const longest = list1.length > list2.length ? list1 : list2;
    const shortest = list1.length < list2.length ? list1 : list2;

    longest.forEach((item) => {
        if (!Array.contains(shortest, field, item[field])) {
            diff.push(item);
        }
    });

    return diff;
};