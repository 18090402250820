<template>
    <BaseMenu
        v-model="show"
        :persistent="persistent"
        :close-on-click="closeOnClick"
        :pos-y="posY"
        :pos-x="posX"
    >
        <template v-slot:content>
            <v-list class="base-action-menu">
                <v-toolbar-title v-if="title !== null">{{typeof title !== 'string' ? title : title}}</v-toolbar-title>
                <slot name="actions"></slot>
                <v-divider v-if="!$app.desktop"></v-divider>
                <slot name="options"></slot>
                <v-list-item @click="show = false" v-if="closeEnabled">
                    <v-list-item-action>
                        <v-icon>close</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{$t('generic.close')}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </BaseMenu>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import BaseMenu from '../popup/BaseMenu.vue';

    export default {
        name: 'BaseActionMenu',
        mixins: [VModelShowMixin],
        components: {
            BaseMenu
        },
        props: {
            title: String,
            persistent: {
                type: Boolean,
                default: false
            },
            closeEnabled: {
                type: Boolean,
                default: true
            },
            closeOnClick: {
                type: Boolean,
                default: true
            },
            posX: {
                type: Number,
                default: undefined
            },
            posY: {
                type: Number,
                default: undefined
            }
        }
    }
</script>

<style lang="scss">
    .base-action-menu {
        padding-top: 4px !important;
        padding-bottom: 12px;
        max-width: 600px;

        .v-toolbar__title {
            min-width: 160px;
            font-size: 16px;
            padding: 10px 15px 10px 15px;
            font-weight: 500;
        }

        .v-menu--inline {
            display: none !important;
        }
    }

    .mobile .base-action-menu {
        max-width: 100%;
        width: 100%;
    }
</style>