<template>
    <v-navigation-drawer
        v-model="show"
        app
        v-if="!destroy"
        :right="$vuetify.rtl"
        :temporary="$vuetify.breakpoint.mdAndDown"
        mobile-breakpoint="200"
        :disable-route-watcher="true"
        touchless
        bottom
        :expand-on-hover="!$vuetify.breakpoint.mdAndDown && !$app.pinMenu"
        class="the-main-drawer elevation-0"
        :clipped="!$vuetify.breakpoint.mdAndDown"
        :width="300"
        :mini-variant.sync="mini"
        @mouseover.native="hovering = true"
        @mouseleave.native="hovering = false"
    >

        <v-list expand id="theMainDrawer">
            <div v-if="$vuetify.breakpoint.mdAndDown && this.$store.state.context.selectedContext.name">
                <div class="context-name">{{this.$store.state.context.selectedContext.name}}</div>
            </div>
            <template v-for="(route, index) in routes">
                <v-list-item
                        v-if="!route.group"
                        :key="route.path"
                        @click.stop="routerPush(route.path)"
                        class="route-item"
                        :class="currentPath === route.path ? 'selected-route' : ''"
                        :data-testid="'appDrawer-'+route.name"
                >
                    <v-list-item-action>
                        <v-icon>{{route.meta.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{$t('router.' + route.name)}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-group
                        v-if="route.group"
                        :key="index"
                        :prepend-icon="route.icon"
                        no-action
                        :group="route.path" 
                        :data-testid="'appDrawer-'+route.nameKey"
                >
                    <template v-slot:activator>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('router.' + route.nameKey)}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list-item
                            v-for="(subroute, index) in route.routes"
                            :key="index"
                            no-action
                            class="route-item"
                            :class="currentPath === subroute.path ? 'selected-route' : ''"
                            @click.stop="routerPush(subroute.path)"
                            :data-testid="'appSubDrawer-'+subroute.name"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{$t('router.' + subroute.name)}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </template>
            <div v-if="$vuetify.breakpoint.mdAndDown" class="bottom-spacer"></div>
        </v-list>

    </v-navigation-drawer>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import BaseMenu from '@/components/base/popup/BaseMenu.vue';
    import BaseSizedBox from "@/components/base/BaseSizedBox.vue";
    import MainMenuContextSelector from "../mainmenu/MainMenuContextSelector.vue";

    export default {
        name: 'TheMainDrawer',
        mixins: [VModelShowMixin],
        components: {
            BaseSizedBox,
            BaseMenu,
            MainMenuContextSelector
        },
        data() {
            return {
                routes: [],
                currentPath: null,
                destroy: false,
                hovering: false,
                mini: false
            }
        },
        mounted() {
            setTimeout(() => {
                this.show = !this.$vuetify.breakpoint.mdAndDown;
            }, 1);
            this.mini = !this.$app.pinMenu;

            this.buildRoutes();
            this.currentPath = this.$router.history.current.path;

            this.$helpMessage.show('drawerSettingsInfo', this.$t('drawer.mainHelp'), 'desktop', {
                id: 'theMainDrawer', icon: 'arrow_downward', pos: {top: '20px', left: '5px'}
            });
        },
        watch: {
            $route() {
                if (this.$vuetify.breakpoint.mdAndDown) {
                    this.show = false;
                }
                this.currentPath = this.$router.history.current.path;
            },
            '$app.pinMenu'() {
                this.mini = !this.$app.pinMenu;
            },
            '$store.state.context.selectedContext'() {
                this.buildRoutes();
            },
            '$store.state.deployments.items'() {
                this.buildRoutes();
            },
            '$store.state.resellers.items'() {
                this.buildRoutes();
            },
            '$store.state.distributors.items'() {
                this.buildRoutes();
            },
            '$store.state.legacies.items'() {
                this.buildRoutes();
            },
            '$store.state.context.mode'() {
                this.buildRoutes();
            },
            '$store.state.context.overviewItems'() {
                this.buildRoutes();
            },
            '$store.state.pushChannel.resourceUpdated'({resource, created, updated, deleted}) {
                if(resource.toLowerCase() === 'contexts' && typeof created === 'object' && Object.keys(created).length > 0) {
                    this.buildRoutes();
                }
            },
            '$vuetify.breakpoint.mdAndDown'(mobile) {
                this.buildRoutes();
                if (mobile) {
                    this.destroy = true;

                    this.$nextTick(() => {
                        this.destroy = false;
                    });
                } else {
                    this.show = true;
                }
            }
        },
        methods: {
            buildRoutes() {
                const routes = [];
                this.$router.options.routes.forEach(route => {
                    if (this.$routeHandler.routeAuthCheck(route, true)) {
                        const group = route.meta.group;

                        if (group !== null && group !== undefined) {
                            const existing = Array.findFirstByField(routes, 'path', group.path);

                            if (existing === null || route.path === '/config') {
                                routes.push({
                                    ...group,
                                    routes: [route],
                                    group: true
                                });
                            } else {
                                existing.routes.push(route);
                            }
                        } else {
                            routes.push({
                                ...route,
                                group: false
                            });
                        }
                    }
                });

                this.routes = routes;
            },
            routerPush(routePath) {
                if (this.$route.path !== routePath) {
                    this.$router.push(routePath)
                }
            }
        }
    }
</script>

<style lang="scss">
    .tablet .the-main-drawer {
        width: 240px !important;
    }

    .theme--dark {
        .selected-route {
            background-color: lighten(#424242, 5%) !important;
        }
    }

    .bottom-spacer {
        height: 80px;
    }

    .the-main-drawer {
        overflow-y: hidden;

        .v-navigation-drawer__content {
            overflow-y: hidden;
        }

        .context-tile, .user-tile {
            height: 56px;

            .v-list-item__title {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .context-name{
            font-weight: 500;
            padding: 10px 15px;
            background-color: var(--v-backgroundAlt-base);
        }

        .user-tile {
            .v-list-item__title {
                font-size: 20px;
                font-weight: 400;
            }

            .v-list-item__action {
                margin-right: 0 !important;
            }
        }

        .v-list {
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100%);
            padding: 12px 0 12px 0 !important;

            .v-list-item__title {
                font-weight: 500;
            }

            .v-list-group__header {
                margin-top: 5px;

                .v-list-item {
                    padding-left: 0 !important;
                }
            }

            .route-item {
                transition: all 0.2s ease-in-out;
                margin-right: 5px;
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
                margin-top: 5px;
            }

            .route-item:before, .route-item:after {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            .selected-route {
                color: var(--v-primary-base) !important;
                border-left: 5px solid var(--v-primary-base);
                background-color: var(--v-hover-base);

                .v-icon {
                    color: var(--v-primary-base) !important;
                }
            }
        }

        .v-subheader {
            height: 40px !important;
        }
    }

    .uniguest-theme {
        .the-main-drawer {

            .v-list-item {
                .v-list-item__title {
                    font-weight: 600;
                }
            }

            .v-list-item--active {
                .v-list-item__title {
                    color: #20A0DB;
                }
                .v-icon {
                    color: #FFC52B;
                }
            }

            .selected-route {
                border-left: 5px solid #FFC52B !important;

                .v-list-item__title {
                    color: #20A0DB;
                }

                .v-icon {
                    color: #FFC52B !important;
                }
            }
        }
    }
</style>
