/**
 * Define fixed text strings representing the different resource types.
 */
class ResourceTypes {

    static Image = 'image'
    static Video = 'video'
    static Font = 'font'
    static Logo = 'logo'
    static WayfindingIcon = 'wayfindingIcon'
    static PDF = 'pdf'

}

export default ResourceTypes;
