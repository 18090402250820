<template>
    <BaseModal
            :title="$t('help.getSiteInformationTitle')"
            :done-action-text="$t('generic.close')"
            :hide-all-actions="true"
            @done="done"
            v-model="open"
            :max-width="700"
    >
        <template v-slot:content>
            <v-card
                elevation="0"
                v-html="bodyMarkup"
            ></v-card>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from "../base/popup/BaseModal.vue";
import Vue from "vue";
import {mapGetters} from "vuex";

export default {
        name: "HelpGetSiteInfo",
        components: {
            BaseModal,
        },
        props: {
        },
        data() {
            return {
                open: false,
                bodyMarkup: ""
            }
        },
        mounted() {
            Vue.prototype.$helpGetSiteInfo = this;
        },
        computed: {
            ...mapGetters({
                vendorConfiguration: 'currentVendorConfiguration/configuration',
            }),
        },
    watch: {
        async '$store.state.context.selectedContext'(val) {
            if(val.hasOwnProperty('id')) {
                let markup = "Site ID: " + val.id + "<br>";
                const response = await this.$apis.resellers.get('/' + val.managedBy);

                markup += "Managed by: " + val.managedByName + "<br>";
                if (this.$responseCheck(response)) {
                    const reseller = response.data;
                    markup += `Distributor: ${reseller.managedByName} <br>`
                }
                markup += "Time-zone: " + val.timeZone + "<br>";
                this.bodyMarkup = markup;
            } else {
                this.bodyMarkup = "Not available";
            }
        },
    },
        methods: {
            show() {
                this.open = true;
            },
            done() {
                this.open = false;
            },
        }

    }
</script>

<style>
    .v-tabs-items {
        padding: 8px 16px;
    }
</style>