import AuthManager from '@/lib/helpers/AuthManager';
import Validator from '@/lib/helpers/Validator';
import Vue from 'vue';
import Storage from "@/lib/helpers/Storage";

import apis from '@/lib/api/index';
import ApiHelper from '@/lib/helpers/ApiHelper';
import i18n from '@/i18n'

const resCheck = ApiHelper.responseSuccessCheck;

const keepAliveRequestIntervalMs = 5 * 60 * 1000; // every 5 minutes

const state = {
    initializing: true,
    isAuthenticated: false,
    authToken: AuthManager.getAuthenticationToken(),
    sessionId: '',
    account: {},
    error: null,
    keepSessionAliveTimer: null
};

const actions = {
    async tryAutoLogin({commit, dispatch}) {
        if (AuthManager.getAuthenticationToken() === null) {
            commit('setInitializing', false);
            return false;
        }
        const response = await apis['session'].get('/validate');
        if (resCheck(response)) {
            commit('setSessionId', response.data.sessionId);
            commit('setAccount', response.data.account);
            commit('setIsAuthenticated', true);
            commit('setInitializing', false);
            dispatch("onSessionStateUpdated")
            return true;
        } else {
            commit('setIsAuthenticated', false);
            commit('setInitializing', false);
            dispatch("onSessionStateUpdated")
            return false;
        }
    },
    async validateLogin({commit, dispatch}) {
        let token = Storage.get('keycloakToken');
        let apiEndpoint = "/extauth";
        /*
        if (Vue.$keycloak.tokenParsed && Vue.$keycloak.tokenParsed.hasOwnProperty("domain")) {
            let domain = Vue.$keycloak.tokenParsed.domain.toString();
            if (domain.length > 0 && domain != "otrum") {
                apiEndpoint = `/domain/${domain}`;
            }
        }
        */
        const response = await apis['session'].post({"token":token}, apiEndpoint);
        if (resCheck(response)) {
            commit('setSessionId', response.data.sessionId);
            commit('setAccount', response.data.account);
            commit('setIsAuthenticated', true);
            commit('setAuthToken', response.data.token);
            AuthManager.setAuthenticationToken(response.data.token);
            AuthManager.setEnableAutoSSO(true);
            commit('setInitializing', false);
            dispatch("onSessionStateUpdated")
            return true;
        } else {
            commit('setIsAuthenticated', false);
            commit('setInitializing', false);
            dispatch("onSessionStateUpdated")
            commit('error', response.data);
            return false;
        }
    },
    async login({commit, dispatch}, credentials) {
        const result = {success: false, errorMessage: ''};
        if (Validator.isEmpty(credentials) || Validator.isEmpty(credentials.name) || Validator.isEmpty(credentials.password)) {
            result.errorMessage = i18n.t('auth.noCredentials');
            return result;
        }

        const response = await apis['session'].post(credentials);
        if (response.status < 400) {
            const authResponse = response.data;
            if (Validator.isNotEmpty(authResponse)) {
                result.success = true;
                commit('setSessionId', authResponse.sessionId);
                commit('setAccount', authResponse.account);
                commit('setIsAuthenticated', true);
                commit('setAuthToken', authResponse.token);
                AuthManager.setAuthenticationToken(authResponse.token);
                dispatch("onSessionStateUpdated")
                return result;
            } else {
                result.errorMessage = i18n.t('auth.responseError');
                commit('setAuthToken', '');
                commit('setIsAuthenticated', false);
                dispatch("onSessionStateUpdated")
                return result;
            }
        } else if (response.status === 401) {
            result.errorMessage = i18n.t('auth.wrongUserPass');
            commit('setIsAuthenticated', false);
            console.log(`Authentication failed: ${response.status} (${response.message})`);
            dispatch("onSessionStateUpdated")
        } else {
            result.errorMessage = i18n.t('auth.loginNotAvailable');
            commit('setIsAuthenticated', false);
            console.log(`Login failed: ${response.status} (${response.message})`)
            dispatch("onSessionStateUpdated")
        }
        return result;
    },
    async logout({commit, dispatch}) {
        await apis['session'].delete("");
        AuthManager.logout();
        commit('setSessionId', '');
        commit('setAccount', {});
        dispatch("onSessionStateUpdated")
    },
    async logoutKeycloak({commit, dispatch}, keycloak) {
        AuthManager.setEnableAutoSSO(false);
        await keycloak.logout();
    },
    onSessionStateUpdated({commit, state, dispatch}) {

        if (state.isAuthenticated === true && state.keepSessionAliveTimer === null) {

            const timer = window.setInterval(function () {
                        apis['session'].get(`/keepAlive`).then(
                                (response) => {
                                    if (resCheck(response)) {
                                        // everything OK - do nothing
                                        console.log("Session updated")
                                    } else {
                                        // session is no longer authorized
                                        console.log("Session no longer authorized - keepAlive API returned " + response.status + " , dispatching logout");
                                        dispatch("logout")
                                    }
                                },
                                (failure) => {
                                    // not able to contact server - unknown state - TODO: do nothing?
                                    console.log("Session update FAILED")
                                    dispatch("logout")
                                }
                        )
                    },
                    keepAliveRequestIntervalMs)
            commit("setKeepSessionAliveTimer", timer);
        } else if (state.isAuthenticated === false && state.keepSessionAliveTimer !== null) {
            window.clearInterval(state.keepSessionAliveTimer)
            commit("setKeepSessionAliveTimer", null);
        }
    }
};

const mutations = {
    error(state, error) {
        state.error = error;
        Vue.prototype.$notification.show('error', error);
    },
    setInitializing(state, init) {
        state.initializing = init;
    },
    setSessionId(state, sessionId) {
        state.sessionId = sessionId;
    },
    setAccount(state, account) {
        state.account = account;
        state.account.firstLogin = Storage.get('firstLogin') !== 'false';
        Storage.set('firstLogin', 'false');
    },
    setAuthToken(state, token) {
        state.authToken = token;
    },
    setIsAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    },
    setKeepSessionAliveTimer(state, keepSessionAliveTimer) {
        state.keepSessionAliveTimer = keepSessionAliveTimer;
    }
};

const getters = {
    accountRootContextId: (state) => {
        return state.account.contextId;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
