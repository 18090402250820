<template>
    <v-menu
            :close-on-content-click="true"
            offset-y
    >
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" data-testid="mmhHelp">
                <v-icon>help</v-icon>
            </v-btn>
        </template>

        <v-list>
            <template
                    v-for="item in helpOptions"
            >
                <v-divider v-if="item.divider === true"></v-divider>
                <v-list-item
                        :key="item.id"
                        @click="selectOption(item)"
                        :data-testid="item.seleniumId"
                >
                    <v-list-item-title>{{translate(item.title)}}</v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-menu>
</template>

<script>
    import UrlManager from '@/lib//helpers/UrlManager.js';

    export default {
        name: "MainMenuHelp",
        data: () => ({
            helpOptions: [{
                title: 'Site Information',
                cb: (ctx) => {
                    ctx.$helpGetSiteInfo.show();
                },
                id: 5,
                seleniumId: "mmhSiteInfo"
            }, {
                title: 'Documentation',
                link: UrlManager.getSupportPortalUrl(),
                id: 1,
                seleniumId: "mmhDocumentation"
            }, /*{
                title: 'Tutorials',
                id: 2
            }, */{
                title: 'Service Status',
                link: 'https://status.thecloudportal.com/?system=mirage',
                id: 4,
                divider: false
            }, {
                title: 'Contact Support',
                cb: (ctx) => {
                    ctx.$helpGetSupport.show();
                },
                id: 3,
                seleniumId: "mmhContactSupport",
                divider: true
            }
            ]
        }),
        computed: {
            languages() {
                return this.$app.languages.filter(lang => lang.portal === true);
            }
        },
        methods: {
            translate(value){
                switch (value){
                    case 'Documentation':
                        return this.$t('help.documentation')
                    case 'Contact Support':
                        return this.$t('help.contactSupport')
                    default:
                        return value
                }
            },
            selectOption(item) {

                if (item.link) {
                    this.$app.openNewTab(item.link);
                } else if (item.cb) {
                    item.cb(this);
                }
            }
        }
    }
</script>