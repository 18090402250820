import BaseCrudModule from '@/store/modules/base/BaseCrudModule';

const module = new BaseCrudModule();

module.addGetters({
    fetchAll: (state, getters, rootState) => {
        return [
            {
                value: "hospitality",
                text: "Hospitality"
            },
            {
                value: "healthcare",
                text: "Healthcare"
            },
            {
                value: "government",
                text: "Government"
            },
            {
                value: "retail",
                text: "Retail"
            },
            {
                value: "travelsAndTransportation",
                text: "Travels & Transportation"
            },
            {
                value: "education",
                text: "Education"
            },
            {
                value: "corporate",
                text: "Corporate"
            },
            {
                value: "foodAndBeverage",
                text: "Food & Beverage"
            },
            {
                value: "fitness",
                text: "Fitness"
            },
            {
                value: "leisure",
                text: "Leisure"
            },
            {
                value: "other",
                text: "Other"
            }
        ];
    }
});

export default module;