/* Norwegian translations */
export default {
    "generic": {
        "moveEntryUp": "Flytt opp",
        "moveEntryDown": "Flytt ned",
        "set": "Sett",
        "onboard": "Legg til",
        "view": "Se",
        "open": "Åpne",
        "days": "Dager",
        "minutes": "Minutter",
        "log": "Logg",
        "event": "Hendelse",
        "eventReason": "Grunn",
        "occuredAt": "Tidspunkt",
        "lastKeepalive": "Siste keepalive",
        "allocated": "Allokert",
        "active": "Aktiv",
        "inactive": "Inaktiv",
        "firmware": "Fastvare",
        "from": "Fra",
        "to": "Til",
        "ipValidationErrorMsg": "Må inneholde en gyldig IP-adresse",
        "networks": "Nettverk",
        "ipAddress": "IP-adresse",
        "host": "Vert",
        "total": "Total",
        "add": "Legg til",
        "all": "Alle",
        "addWithType": "Legg til {type} | Legg til {type}er",
        "agent": "Agent",
        "signageScreen": "Signage-skjerm",
        "agentStatus": "Agent status",
        "delete": "Slett",
        "deleteAll": "Slett alt",
        "deleteWithType": "Slett {type} | Slett {type}er",
        "deleteConfirm": "Er du sikker på at du ønsker å slette denne {type}? | Er du sikker på at du ønsker å slette disse {type}er?",
        "deleteConfirmWithIdentifier": "Er du sikker på at du ønsker å slette {type} {id}?",
        "deleted": "{type} slettet | {type}er slettet",
        "deleteFailed": "{type} kunne ikke slettes | {type}er kunne ikke slettes",
        "deletedWithIdentifier": "{id} slettet",
        "deletedWithTypeAndIdentifier": "{type}{id} slettet",
        "deleteUserConfirmation": "Jeg bekrefter at jeg vil slette denne {type}. Jeg erkjenner at denne operasjonen er endelig, og at {type} ikke kan gjenopprettes. | Jeg bekrefter at jeg ønsker å slette disse {amount} {type} -ene. Jeg erkjenner at denne operasjonen er endelig, og at {type} ikke kan gjenopprettes.",
        "fetchFailed": "Feil ved henting av {itemName}",
        "imported": "{cnt} {type} vellykket importert | {cnt} {type} er vellykket importert",
        "saved": "{type} lagret | {type}er lagret",
        "updated": "{type}oppdatert | {type}er oppdatert",
        "created": "{type} opprettet | {type} opprettet",
        "selectType": "Velg {type}",
        "manageType": "Behandle {type}",
        "itemReverted": "Enhet tilbakeført | Enheter tilbakeført",
        "valueDuplicate": "{value} eksisterer allerede",
        "remove": "Fjern",
        "disable": "Deaktiver",
        "disabled": "Deaktivere",
        "download": "Last ned",
        "enable": "Muliggjør",
        "enabled": "Muliggjøre",
        "systemDefault": "Systemfeil",
        "removeWithType": "Fjern {type} | Fjern {type}er",
        "removeConfirm": "Er du sikker på at du vil fjerne denne {type}? | Er du sikker på at du vil fjerne disse {type} ene?",
        "removeConfirmWithTarget": "Er du sikker på at du vil fjerne {type} fra dette {target}? | Er du sikker på at du vil fjerne disse {typene} fra dette {target}?",
        "group": "Gruppe | Grupper",
        "rename": "Endre navn",
        "renameWithType": "Endre navn til {type}",
        "renamedTo": "{type} endre navn til {name}",
        "create": "Opprett",
        "createToolTip": "Opprett uten å lukke",
        "createAndClose": "Opprett og lukk",
        "createWithType": "Opprett {type}",
        "createNew": "Opprett ny",
        "createNewWithType": "Opprett ny {type} | Opprett nye {type}er",
        "takeEffectImmediatly": "Dette trer i kraft umiddelbart",
        "newWithType": "Ny {type} | Ny {type}er",
        "actions": "Handlinger",
        "yes": "Ja",
        "no": "Nei",
        "none": "Ingen",
        "move": "Flytte",
        "loading": "Laster inn",
        "unknown": "Ukjent",
        "typeToSearch": "Begynn å skrive for å søke",
        "enterSearchTerm": "Skriv inn søkeord",
        "noSearchMatches": "Ingen søk samsvarer",
        "cancel": "Avbryt",
        "close": "Lukk",
        "gotIt": "Forstår",
        "required": "Påkrevd",
        "numberRequired": "Numerisk verdi påkrevd",
        "fieldRequired": "Felt kreves",
        "inputRequired": "Input kreves",
        "integration": "Integrasjon",
        "selectionRequired": "Valg kreves",
        "nameRequired": "Navn kreves",
        "inputLongerThan": "Input må være lengre enn {length} tegn | Input må være lengre enn {length} tegn",
        "countLessThan": "Verdien må være mindre enn {length}",
        "inputShorterThan": "Teksten må være kortere enn {length} tegn",
        "nameInvalidFormat": "Navnet kan bare inneholder bokstaver, tall og mellomrom",
        "madeYet": "Ingen {type} er laget ennå | Ingen {type}er er laget ennå",
        "noMoreItemsAvailable": "Ingen flere {type} tilgjengelig | Ingen flere {type}er tilgjengelige",
        "searchCriteria": "Ingen {type} samsvarer med de oppgitte søkekriteriene",
        "search": "Søk",
        "save": "Lagre",
        "saveAndClose": "Lagre og Lukk",
        "saveWithType": "Lagre {type} | Lagre {type}er",
        "editItem": "Rediger enhet | Rediger enheter",
        "edit": "Rediger | Rediger",
        "editWithType": "Rediger {type} | Rediger {type}er",
        "shareName": "Dele {type}",
        "action": "Handling | Handlinger",
        "refresh": "Oppdater",
        "reset": "Nullstill",
        "resetRefresh": "Omstart og oppdater {type}",
        "lostChanges": "Alle endringer vil gå tapt",
        "noAvailable": "Ingen tilgjengelige typer {type}er",
        "channelTable": "Kanaltabell",
        "done": "Ferdig",
        "name": "Navn",
        "nickname": "Kallenavn",
        "externalId": "Ekstern Id",
        "crmRef": "Crm referanse",
        "industry": "Industri",
        "filename": "Filnavn",
        "content": "Innhold",
        "fullscreen": "Fullskjerm",
        "exitFullscreen": "Lukk fullskjerm",
        "copy": "Kopi",
        "copyToClipboard": "Kopier til utklippstavle",
        "copiedToClipboard": "Innhold kopiert til utklippstavle",
        "copyToSite": "Kopier til lokasjon",
        "preview": "Forhåndsvisning",
        "reload": "Last inn på nytt",
        "unsaved": "Endringer som ikke er lagret vil gå tapt",
        "type": "Type | Typer",
        "text": "Tekst",
        "tag": "Tagg | Tagger",
        "item": "Enhet",
        "title": "Tittel",
        "fullTitle": "Full tittel",
        "editor": "Redaktør",
        "number": "Nummer | Nummer",
        "top": "Topp",
        "left": "Venstre",
        "width": "Vidde",
        "height": "Høyde",
        "zIndex": "Z-Indeks",
        "field": "Felt | Felter",
        "key": "Nøkkel | Nøkler",
        "description": "Beskrivelse",
        "setup": "Oppsett",
        "status": "Status | Status",
        "multiple": "Flere",
        "default": "Standard",
        "general": "Generell",
        "configuration": "Konfigurasjon",
        "config": "Konfig.",
        "select": "Velg",
        "addAll": "Legg til alle",
        "removeAll": "Fjern alle",
        "previous": "Forrige",
        "next": "Neste",
        "finish": "Avslutt",
        "nameLonger": "Navnet må være lengre enn {length} tegn",
        "selectAll": "Velg alle",
        "deselectAll": "Opphev alle",
        "code": "Kode",
        "continue": "Forsett",
        "stay": "Opphold",
        "back": "Tilbake",
        "language": "Språk",
        "languageName": "Språk navn",
        "languageCode": "Språk kode",
        "reseller": "Forhandler | Forhandlere",
        "resource": "Ressurs | Ressurser",
        "override": "Overstyring | Overstyringer",
        "insufficientPrivileges": "Kontoen har ikke tilstrekkelige privilegier",
        "theme": "Tema | Temaer",
        "distributor": "Distributør | Distributører",
        "domain": "Domene | Domener",
        "preregister": "Forhånds registrer",
        "pleaseConfirm": "Vennligst bekreft",
        "switchTo": "Skift til...",
        "home": "Hjem",
        "lastLoggedIn": "Sist logget inn",
        "summary": "Sammendrag",
        "noData": "Ingen data å vise",
        "vendor": "Leverandør | Leverandører",
        "model": "Modell | Modeller",
        "user": "Bruker",
        "schedule": "Timeplan | Timeplaner",
        "revert": "Reverser",
        "revertAllChanges": "Angre alle endringer siden sist lagring?",
        "revertNoChanges": "Ingen endringer å angre",
        "channels": "Kanal | Kanaler",
        "deployment": "Implementering | Implementeringer",
        "filter": "Filter",
        "accept": "Aksepter",
        "api": "API",
        "timeZone": "Tidssone | Tidssoner",
        "register": "Register",
        "caseSensitive": "Sensitiv sak",
        "optional": "Velgfri",
        "errorOccurred": "En feil oppsto",
        "on": "På",
        "off": "Av",
        "standby": "Stand-by",
        "idle": "Ledig",
        "notSet": "Ikke satt",
        "exportCSV": "Eksporter CSV",
        "importCSV": "Importer CSV",
        "success": "Suksess",
        "uploaded": "Lastet opp",
        "hasUnsavedChangesHeader": "Endringer ikke lagret",
        "hasUnsavedChangesMessage": "Endringene dine går tapt hvis du lukker.<br>Fortsett?",
        "defaultErrorMsg": "Standard feilmelding",
        "notes": "Notater",
        "genericNotes": "Generelle notater",
        "internalNotes": "Interne notater",
        "whitelabelDomain": "Whitelabel domene",
        "createNewSchedule": "LAge ny kjøreplan",
        "addToSchedule": "Legge til i kjøreplan",
        "readOnly": "Skrivebeskyttet",
        "information": "informasjon",
        "custom": "Tilpasset",
        "url": "URL",
        "realm": "Keycloak realm",
        "noResult": "Ingen resultat | Ingen resultater",
        "sharingContext": "Ressursdeler | Ressursdelere",
        "sharingSuccess": "Deling oppdatert",
        "sharingError": "Kunne ikke oppdatere deling",
        "lastPublished": "Siste publisering",
        "notPublished": "{0} er ikke publisert",
        "itemType": "{0}",
        "shareAll": "Alle",
        "shareSubGroup": "Undergruppe",
        "shareSingle": "Enkel"
    },
    "validation": {
        "characterMaxLength": "Maksimalt {value} tegn",
        "valueHigherThan": "Verdien må være høyere enn {value}",
        "valueHigherThanOrEqualTo": "Verdi må være høyere eller lik {value}",
        "valueLowerOrEqualTo": "Verdi må være lavere eller lik {value}",
        "powerOnOverrideFrom": "Må være før \"Overstyr til\"",
        "powerOnOverrideTo": "Må være etter \"Overstyr fra\""
    },
    "help": {
        "getSupportTitle": "Kontakt brukerstøtte",
        "getSiteInformationTitle": "Lokasjonsinformasjon",
        "notAvailable": "Ikke tilgjengelig",
        "openDocumentation": "Åpne dokumentasjon",
        "getSupportBody": "<span style=\"font-weight: bold;\">E-post:</span> {email}<br><span style=\"font-weight: bold;\">Telefon:</span> {phone}<br><br>{text}",
        "notAvailableInHomeScreen": "Brukerstøtte er ikke tilgjengelig på startskjermen.<br>Vennligst bytt til en lokasjon",
        "documentation": "Dokumentasjon",
        "contactSupport": "Kontakt brukerstøtte",
        "mobileNavigationTip": "Trykk på øvre venstre knapp for å åpne navigasjonsfeltet"
    },
    "eula": {
        "updated": {
            "title": "Oppdatert brukeravtale"
        }
    },
    "welcome": {
        "title": "Velkommen {user}",
        "languageHeader": "Språk",
        "languageText": "Velkommen til Enterprise Cloud-administrasjonsportalen. <br> <br>Dette er hovedstedet i Enterprise Cloud-medieløsningen, tilgangspunktet for distributører, forhandlere, lokasjonseiere og lokasjonsbrukere. <br> <br>Start med å velge ønsket språk:",
        "homeScreenText": "Avhengig av kontoen din, vil du være logget på et \"Site Dashboard\" eller et \"Portal Dashboard\" og dette vil være din fremtidige startskjerm for å jobbe med Enterprise Cloud. <br> <br > <div style=\"font-weight: bold;\"> Site Dashboard</div> For brukere med standard tilgang til én side, blir du ført direkte til ditt eget \"Site Dashboard\" når du logger på systemet. <br>På dette utgangspunktet har du en oversikt over installasjonsskjermbildene, gjestfrihetsstatistikk og administrativ info. \nDe spesifikke menyelementene, ressursene og alternativene som er tilgjengelige for deg, kan variere avhengig av kontotype, aktive lisenser og tilgangsnivå som er gitt.<br><br><div style=\"font-weight: bold;\">Portal Dashboard</div> For forhandlere, lokasjonseiere og lokasjonsbrukere med tilgang til mer enn én lokasjon, \"Portal Dashboard\" er det første utgangspunktet på plattformen. <br>Her administrerer du ressurser og får tilgang til kombinert statistikk for alle lokasjonene dine, oppretter brukere, setter opp nye innlegg tall, sjekk lisensdetaljer og mange andre administrative oppgaver. <br> De spesifikke menyelementene og alternativene på dette administrative området på plattformen vil variere avhengig av kontotype, aktive lisenser og tilgangsnivå som er gitt. <br> <br> Du kan når alltid \"Portal Dashboard\" ved å velge den fra hurtigmenyen øverst til venstre fra hvilken som helst side. Fra den samme hurtigmenyen øverst til venstre kan du også få tilgang til alle lokasjonene dine for å jobbe med hver enkelt installasjon som er tilgjengelig for kontoen din.",
        "siteText": "Hvis du er en bruker på en lokasjon, vil du automatisk logges på lokasjonen din når du logger deg på systemet. For brukere med tilgang til flere lokasjoner velger du områdekontekst i kontekstmenyen øverst til venstre. <br> Tilgangsnivået du har vil avgjøre hvilke ressurser som er tilgjengelige for deg i områdekonteksten."
    },
    "language": {
        "english": "Engelsk",
        "norwegian": "Norsk",
        "swedish": "Svensk",
        "finnish": "Finsk",
        "russian": "Russisk",
        "italian": "Italiensk",
        "french": "Fransk",
        "german": "Tysk",
        "spanish": "Spansk",
        "atLeastOne": "Minst ett språk må fylles ut"
    },
    "date": {
        "today": "I dag",
        "yesterday": "I går",
        "daysAgo": "{days} dager siden",
        "dateRequired": "Dato kreves",
        "dateNotSet": "Ikke satt",
        "timeRequired": "Tid kreves",
        "timeFormat": "Tidsformatet må være 12:00",
        "amPmTimeFormat": "12-timers tidsformat(AM/PM)",
        "daily": "Daglig",
        "weekly": "Ukentlig",
        "time": "Tid",
        "date": "Dato",
        "timeOfDay": "Tid på døgnet",
        "monday": "Mandag",
        "tuesday": "Tirsdag",
        "wednesday": "Onsdag",
        "thursday": "Torsdag",
        "friday": "Fredag",
        "saturday": "Lørdag",
        "sunday": "Søndag",
        "startTime": "Starttid",
        "endTime": "Sluttid",
        "weekday": "Ukedag",
        "endTimeAfter": "Sluttiden må komme etter starttiden",
        "duration": "Varighet",
        "inSeconds": "I sekunder"
    },
    "router": {
        "agents": "Agenter",
        "contentConfiguration": "Konfigurasjon",
        "documents": "Dokumenter",
        "resource": "Ressurs",
        "resources": "Ressurser",
        "hospitality": "Hotell",
        "dashboard": "Skrivebord",
        "media": "Media",
        "housekeeping": "Renhold",
        "screens": "Skjermer",
        "tv": "TV",
        "playlists": "Spillelister",
        "signage": "Signage",
        "sites": "Lokasjoner",
        "customersAndLicenses": "Kunder og lisenser",
        "sitesAndLicenses": "Lokasjoner og lisenser",
        "externalSystems": "Eksterne systemer",
        "reseller": "Forhandler",
        "resellers": "Forhandlere",
        "manageResellers": "Administrer forhandlere",
        "distributor": "Distributør",
        "distributors": "Distributører",
        "domain": "Domene",
        "domains": "Domener",
        "usageReport": "Bruksrapport",
        "accessReport": "Tilgangsrapport",
        "resellerUsersAndAccess": "Brukere og tilgang",
        "distributorUsersAndAccess": "Brukere og tilgang",
        "resellerThemes": "Tema",
        "distributorThemes": "Tema",
        "content": "Innhold",
        "admin": "Admin",
        "administration": "Administrasjon",
        "system": "System",
        "rooms": "Rom",
        "virtualRemoteClients": "VRC-sesjoner",
        "housekeepingRooms": "Rom",
        "locations": "Lokasjoner",
        "locationSetup": "Lokasjonsoppsett",
        "guests": "Gjester",
        "guestMessages": "Meldinger",
        "booking": "Booking",
        "wakeups": "Vekking",
        "config": "Konfigurasjon",
        "screensConfig": "Konfigurasjon",
        "housekeepingConfig": "Konfigurasjon",
        "streamAllocatorConfig": "Konfigurasjon",
        "configuration": "Konfigurasjon",
        "issues": "Feil",
        "scheduledTasks": "Planlagte oppgaver",
        "screen": "Skjerm",
        "screenConfigurations": "Konfigurasjoner",
        "tvChannels": "TV-kanaler",
        "tvScreens": "TV-skjermer",
        "tvScreensTheme": "TV-skjermer",
        "signageScreens": "Signage-skjermer",
        "signageOverview": "Oversikt",
        "signageSchedules": "Timeplaner",
        "signageConfig": "Konfigurasjon",
        "event": "Hendelser",
        "events": "Hendelser",
        "wayfinding": "Retningsvisning",
        "wayfindingIcons": "Retningsvisningsikoner",
        "logos": "Logoer",
        "integrationLocations": "Integrasjonslokasjoner",
        "eventConfig": "Henelseskonfigurasjon",
        "schedules": "Timeplaner",
        "schedulemapping": "Timeplanlegging",
        "installCodes": "Installer koder",
        "interactivePages": "Interaktive menyer",
        "templatePages": "Skjerm menyer",
        "channelTables": "Kanaltabeller",
        "mediaChannels": "Media-kanaler",
        "templates": "Maler",
        "apiTemplates": "API-maler",
        "pages": "Sider",
        "imageresources": "Bilder",
        "videoresources": "Videoer",
        "fontresources": "Fonter",
        "contexts": "Kontekst",
        "sharingContexts": "Ressursdelere",
        "accounts": "Kontoer",
        "access": "Tilgang",
        "themeImport": "Temaer",
        "themes": "Temaer",
        "usersAndAccess": "Brukere og tilgang",
        "userAccounts": "Brukerkontoer",
        "apiAccounts": "API kontoer",
        "owners": "Eiere",
        "areas": "Områder",
        "licenses": "Lisenser",
        "products": "Produkter",
        "roles": "Roller",
        "roleManagement": "Rediger roller",
        "privilegeSets": "Privilegiesett",
        "requiredPermissions": "Du har ikke de nødvendige tillatelsene for denne visningen",
        "systemConfig": "System Config",
        "tvSearch": "TV-søk",
        "pmsConfiguration": "PMS Konfigurasjon",
        "pmsLogEntries": "PMS-loggoppføringer",
        "resourceSharing": "Del",
        "resourcePublishing": "Publiser",
        "publishAndShare": "Publiser og del",
        "themePreview": "Forhåndsvisning",
        "watermark": "Vannmerke",
        "castManagement": "Cast styring",
        "chromecasts": "Chromecasts",
        "chromecastConfig": "Cast konfigurasjon",
        "unregisteredCasts": "Uregistrerte casts",
        "activeCastSessions": "Aktive cast-sesjoner",
        "castConfiguration": "Konfigurasjon",
        "castStatus": "Status",
        "castStatistics": "Statistikk",
        "castEventLogs": "Event logger",
        "streamAllocator": "Stream Allocator",
        "streamManagement": "Stream konfigurasjon",
        "streamAllocatorLog": "Stream Allocator Log",
        "deletedContexts": "Sluttede kontekster",
        "duplicateRegisteredClients": "Dupliserte registrerte klienter",
    },
    "appbar": {
        "helpAndSupport": "Hjelp og brukerstøtte",
        "notifications": "Varslinger",
        "languages": "Språkvalg",
        "settings": "Innstillinger og verktøy",
        "contextSelector": "Velg side",
        "availableSites": "Tilgjengelige lokasjoner",
        "availableCustomers": "Titgjengelige kunder",
        "availableThemes": "Titgjengelige temaer",
        "searchSites": "Søk lokasjoner",
        "searchCustomers": "Søk kunder",
        "searchThemes": "Søk temaer",
        "availableSharingContexts": "Tilgjengelige delingskontekster",
        "searchSharingContexts": "Søk delingskontekster",
    },
    "admin": {
        "admin": "Admin",
        "account": "Konto | Kontoer",
        "accountRemoved": "Kontoen ble fjernet",
        "accountRemoveFailed": "Kontoen ble ikke fjernet",
        "accountCreated": "Ny konto lagt til",
        "accountSaved": "Tilgangsdata lagret",
        "accountType": "Kontotype",
        "accountEnabled": "Konto aktivert",
        "accountAccess": "Konto tilgang",
        "apiAccount": "API konto | API kontoer",
        "deleteAccount": "Slett konto",
        "removeAccount": "Fjern konto",
        "removeAccessRights": "Fjern kontorettigheter",
        "removeAccessRightsInfo": "Fjern tilgangsrettigheter for denne kontoen",
        "disableAPIAccount": "Deaktiver API-konto",
        "enableAPIAccount": "Aktiver API-konto",
        "addApiInfo": "Den nye API-kontoen blir automatisk aktivert for tilgang",
        "accountApiState": "Denne API-kontoen er for øyeblikket {state}",
        "accountApiStateWithContextId": "Denne API-kontoen er for øyeblikket {state} for contextId {contextId}",
        "disableApiAccountTitle": "Deaktiver API-konto?",
        "disableApiAccountWarning": "Deaktivering av en API-konto fører til at tilgangstokenet slettes permanent fra systemet. Du kan aktivere kontoen på nytt, men dette vil generere et nytt token",
        "disableApiAccountConfirm": "Bekreft at du ønsker å slette tilgangstoken permanent.",
        "deleteUserAccountConfirm": "Vennligst bekreft at du vil fjerne denne kontoen fra dette",
        "deleteUserAccountTitle": "Fjern brukerkonto?",
        "deleteUserAccountSuccess": "Brukerkonto fjernet",
        "deleteApiAccountSuccess": "API-konto slettet",
        "deleteAccountGenericFailed": "Kunne ikke slette oppføringen!",
        "removedUserAccessSuccess": "Brukertilgang er fjernet",
        "removedUserAccessFailed": "Kunne ikke fjerne brukertilgang",
        "saveAccountFailed": "Kunne ikke lagre oppføringen!",
        "accountDataSaved": "Kontodata lagret",
        "apiTokenWarning": "Du vil bare se dette token en gang! Kopier dette token:",
        "apiTokenTitle": "API-tilgangstoken",
        "customer": "Kunde | Kunder",
        "role": "Rolle | Roller",
        "privilegeSet": "Privilegiesett | Privilegiesett",
        "accountsWithRole": "Kontoer med rolle \"{role}\"",
        "rolesWithProduct": "Roller med produkt \"{product}\"",
        "site": "Lokasjon | Lokasjoner",
        "system": "System | Systemer",
        "country": "Land | Land",
        "area": "Område | Områder",
        "brand": "Merke | Merker",
        "screensLimit": "Sidebegrensning",
        "licensed": "Lisensiert",
        "sitesLimit": "Lokasjon begrensning",
        "deleteCustomer": "Slett kunde",
        "deleteSite": "Slett lokasjon",
        "deleteLegacyWarningText": "ADVARSEL: Sletting av en ekstern systemreferanse kan ikke gjenopprettes.<br>Denne handlingen vil ikke ha effekt på det eksterne systemet, men referansen vill bli fjernet og din konto vil ikke lenger se status-data fra dette systemet.<br>INGEN AV DISSE ELEMENTENE KAN GJENNOPPRETTES.",
        "deleteSiteWarningText": "ADVARSEL: Sletting av en lokasjon er en handling som ikke kan gjenopprettes.<br>Slette en lokasjon vil også slette alle relaterte data, inkludert: skjermer, menysider, bilder / skrifttyper, maler, dokumenter, rom, lisenser og mer. <br> INGEN AV DISSE ELEMENTENE KAN GJENNOPPRETTES.",
        "deleteSiteWarningTextPPDS": "WARNING: Sletting av en kunde er en handling som ikke kan gjenopprettes.<br>Sletting av en kunde vil også slette all relatert data slik som: skjermer, maler, bilder, spillelister mm.<br> INGEN AV DISSE ELEMENTENE KAN GJENNOPPRETTES.",
        "deleteSiteConfirmationLabel": "Skriv inn \" {title} \"i dette tekstfeltet for å aktivere sletting",
        "copyResources": "Kopier resurser",
        "copyResourcesToSite": "Kopier resurser til {title}",
        "copyResourcesFromTo": "Kopier alle resurser fra lokasjon {from} til lokasjon {to}?",
        "moveSiteFromTo": "Flytt lokasjon fra forhandler {from} til forhandler {to}?",
        "moveSite": "Flytt lokasjon {title}",
        "provisioned": "Klargjort",
        "maxSizeProvisioned": "Maks antall lokasjoner opprettet",
        "addReseller": "Legg til forhandler",
        "context": "Kontekst | Kontekst",
        "contextType": "Konteksttype | Konteksttyper",
        "systemRole": "Systemrolle | Systemroller",
        "owner": "Eier | Eiere",
        "license": "Lisenser | Lisens | Lisenser",
        "totalLicenses": "Totale lisenser",
        "feature": "Funksjon | Funksjoner",
        "changeSite": "Endre lokasjon",
        "product": "Produkt | Produkter",
        "itemId": "Item ID",
        "customId": "Custom ID",
        "access": "Tilgang | Tilgang",
        "reseller": "Forhandlere | Forhandler | Forhandlere",
        "noResellerAccess": "Du har ikke tilgang til noen forhandlere",
        "noDistributorAccess": "Du har ikke tilgang til noen distributører",
        "distributor": "Distributører | Distributør | Distributører",
        "domain": "Domene | Domener",
        "resource": "Ressurs | Ressurser",
        "accountName": "Kontonavn",
        "displayName": "Visningsnavn",
        "email": "E-post",
        "phone": "Telefon",
        "formDisabledSSO": "Dine kontodetaljer er styrt av en ekstern identitetsleverandør. For å endre disse detaljene, må du kontakte din systemadministrator.",
        "created": "opprettet",
        "password": "Passord",
        "repeatPassword": "Gjenta passord",
        "passwordHint": "Passordet må inneholde minst 8 tegn, en stor bokstav, en liten bokstav og et tall",
        "addNewAccess": "Legg til ny tilgang",
        "newAccount": "Ny brukerkonto",
        "addAccount": "Legg til {type} konto",
        "newApiAccount": "Ny API-konto",
        "editApiAccount": "Rediger API-konto",
        "accessFetchFailed": "Henting av kontotilgang mislyktes",
        "accessUpdateFailed": "Oppdatering av tilgang mislyktes",
        "unknownAccess": "Ukjent tilgang",
        "accessToFeatures": "Tilgang til {features} -funksjonen | Access to {features} features",
        "passwordLength": "Passordet må inneholde 8 tegn",
        "passwordMatch": "Passord må samsvare",
        "contextAccess": "Konteksttilgang",
        "noFeaturesAccess": "Ingen funksjoner er lagt til i denne tilgangen",
        "removeAccess": "Fjern tilgang",
        "home": "Hjem",
        "read": "Les",
        "create": "Opprett",
        "update": "Oppdater",
        "delete": "Slett",
        "oneAccessRelation": "Det kreves minst en tilgangsrelasjon",
        "editLicense": "Rediger lisens",
        "contractRef": "Kontrakt ref",
        "activationDate": "Aktiveringsdato",
        "expirationDate": "Utløpsdato",
        "noFeaturesSelected": "Ingen funksjoner er valgt ennå",
        "availableFeatures": "Tilgjengelige funksjoner",
        "expirationAfter": "Utløpsdatoen må komme etter aktiveringsdatoen",
        "newLicense": "Ny lisens",
        "userAccount": "Brukerkonto",
        "userAdminError": "Brukeradministratorrettighet kunne ikke legges til",
        "createUserAccount": "Opprett en ny brukerkonto",
        "noAvailableUsers": "Ingen tilgjengelige brukerkontoer",
        "selectUser": "Velg en brukerkonto",
        "selectUserHelp": "Velg en bruker her hvis du vil gi dem administrator tilgang til den valgte konteksten og funksjonene til denne nye lisensen. <br> <br> Hvis denne konteksten overleveres til en partner for å vedlikeholde og sette opp du kan opprette en ny administratorbruker her. <br> <br> Du kan også velge din egen bruker hvis du vil ha full administratorrettigheter til denne nye konteksten. <br> <br> Eller du kan forlate felt tomt for å hoppe over dette trinnet helt.",
        "createNewContext": "Opprett en ny kontekst",
        "noAvailableContexts": "Ingen tilgjengelige kontekst",
        "selectContext": "Velg en kontekst",
        "selectContextHelp": "En kontekst kan være en forekomst av Enterprise CTRL eller et arbeidsområde for en ressursbehandling, og er nødvendig for en lisens. <br> <br> Velg eller opprett en nå.",
        "createNewSite": "Opprett et ny lokasjon",
        "noAvailableSites": "Ingen tilgjengelige lokasjoner",
        "selectSite": "Velg en lokasjon",
        "selectSiteHelp": "En lokasjon er et fysisk sted der en kontekst eksisterer - et hotell for eksempel. <br> <br> I dette trinnet kan du velge eller opprette en lokasjon som skal brukes til denne nye lisensen.",
        "deploymentInfo": "En standard distribusjon med tilhørende lokasjon",
        "managedBy": "Administrert av",
        "deployedAt": "Distribuert på",
        "locatedIn": "Ligger i",
        "belongsTo": "Hører til",
        "streetAddress": "Gateadresse",
        "postalCode": "Postnummer",
        "contactName": "Kontaktnavn",
        "contactEmail": "Kontakt-e-post",
        "contactPhone": "Kontakttelefon",
        "contactInfo": "Kontaktinformasjon",
        "defaultLanguage": "Standardspråk",
        "availableLanguages": "Tilgjengelige språk",
        "createAccount": "Opprett konto",
        "createArea": "Opprett område",
        "createLicense": "Opprett lisens",
        "createDistributor": "Opprett distributør",
        "createDomain": "Opprett domene",
        "createReseller": "Opprett forhandler",
        "lastChange": "Siste endring",
        "createOwner": "Opprett eier",
        "createProduct": "Opprett produkt",
        "createSite": "Opprett lokasjon",
        "createCustomer": "Opprett kunde",
        "createResource": "Opprett ressurs",
        "createTheme": "Lag tema",
        "createSharingContext": "Opprett delingskontekst",
        "createRole": "Opprett rolle",
        "roleName": "Rollenavn",
        "newRole": "Ny rolle",
        "newPrivilegeSet": "Nytt privilegiesett",
        "newDistributor": "Ny distributør",
        "newDomain": "Nytt domene",
        "selectRole": "Velg roller",
        "noRolesAccess": "Ingen roller er lagt til i denne tilgangen",
        "removeRole": "Fjern",
        "accessToRoles": "{roles} rolle | {roles} roller",
        "availableRole": "Tilgjengelig rolle | Tilgjengelige roller",
        "limits": "Grense | Grenser",
        "oldPassword": "Gammelt passord",
        "newPassword": "Nytt passord",
        "repeatNewPassword": "Gjenta nytt passord",
        "vendorConfig": "Leverandørkonfigurasjon",
        "usingCustomSupportInfo": "Tilpasset brukerstøtteinformasjon",
        "myAccount": "Min konto",
        "userSaveFailedGeneral": "Kunne ikke lagre brukerinformasjon",
        "userSaveFailedEmail": "Kunne ikke oppdatere brukerens e-postadresse",
        "userChangeEmailHeader": "For å endre e-postadressen din må du oppgi passordet ditt",
        "updateEmail": "Oppdater e-post",
        "userSaveFailedPassword": "Kunne ikke oppdatere brukerpassordet",
        "userUpdatePasswordTitle": "Oppdater passord",
        "userUpdatePasswordDescription": "Hvis det lykkes, vil dette logge ut alle aktive økter for din nåværende bruker. Fortsett?",
        "changeYourPassword": "Endre passordet ditt",
        "changePassword": "Endre passord",
        "share": "Del",
        "addNewShare": "Del med ...",
        "addApiAccount": "Legg til API-konto",
        "addUserAccount": "Legg til brukerkonto",
        "resellerLicenseInfoSite": "Forhandlere kan opprette lokasjoner og lisenser. Du administrerer",
        "resellerLicenseInfoCustomer": "Forhandlere kan opprette kunder og lisenser. Du administrerer",
        "distributorLicenseInfo": "Distributører kan opprette forhandlere. Du administrerer",
        "newReseller": "Ny forhandler",
        "editAccessFor": "Rediger tilgang for",
        "editAccessToReseller": "Rediger tilgang til forhandler",
        "distributorAccess": "Distributøradgang",
        "domainAccess": "Domenetilgang",
        "resellerAccess": "Forhandler tilgang",
        "themeAccess": "Tema tilgang",
        "siteAccess": "Lokasjonstilgang",
        "deleteDistributorText": "En distributør kan bare blir slettet om den ikke har noen reseller eller temaer provisjonert.<br>Vennligst bekreft at du ønsker å slette denne distributøren?",
        "deleteDistributorFailedText": "Kunne ikke slette distributør: {details}",
        "distributorHasProvisionedContext": "det er provisjonerte forhandlere og/eller temeaer koblet til denne distributøren",
        "deleteDomainText": "Et domene kan bare bli slettet om det ikke har noen distributører eller temaer provisjonert.<br>Vennligst bekreft at du ønsker å slette dette domenet?",
        "deleteDomainFailedText": "Kunne ikke slette domene: {details}",
        "domainHasProvisionedContext": "det er provisjonerte distributører og/eller temeaer koblet til dette domenet",
        "externalSystemsHeading": "Ekstern systeminformasjon kan bli redigert her",
        "createPrivilegeSet": "Opprett privilegiesett",
        "privilegeSetName": "Privilegiesettnavn",
        "systemPrivilegeSet": "Systemprivilegiesett",
        "noPrivilegeSetsAdded": "Ingne privilegiesett er lagt til",
        "chooseTypePrivilegeSet": "Velg en 'context type' før du velger privilegiesett",
        "forceSSO": "Tvunget SSO",
        "forceSSOHint": "Bruk dette hvis du ønsker å begrense brukeradgang til en spesifikk 3. parts SSO-leverandør",
        "idp": "Påtvunget SSO",
        "copyRole": "Kopier fra eksisterende rolle",
        "roleProductsHint": "Denne rollen vil kun være tilgjengelig for de valgte produktene",
        "roleProductDisabledHint": "Gjerlder kun for ikke-systemroller",
        "copyOfName": "Kopi av {name}",
        "contextPrivilegeSetSelectionTitle": "Tilgangsvalg",
        "contextPrivilegeSelectionDescription": "Velg hvilke portalmenyer, og undermenyer, denne rollen vil ha tilgang til",
        "deletedContext": "Slettet kontekst",
        "finalizeDelete": "Fullfør sletting",
        "undeleteConfirmMsg": "Er du sikker på at du ønsker å gjenopprette denne konteksten? | Er du sikker på at du ønsker å gjenopprette disse {amount} kontekstene?",
        "undelete": "Gjenopprett",
        "undeleteSuccess": "Kontekst gjenopprettet | {amount} kontekster gjenopprettet",
        "undeleteFailed": "Gjenopprettelse feilet",
        "finalizeDeleteConfirmMsg": "Er du sikker på at du ønsker å fullføre slettingen av denne konteksten? | Er du sikker på at du ønsker å fullføre slettingen av disse {amount} kontekstene?",
        "finalizeDeleteSuccess": "Kontekst slettet | {amount} kontekster slettet",
        "finalizeDeleteFailed": "Fullføring sletting feilet",
        "activateSite": "Aktiver lokasjon",
        "activateSiteDescription": "Aktivering av en lokasjon vil fjerne alle vannmerker og starte faktureringsplanen",
        "activateSiteConfirmTex": "Jeg forstår at denne handlingen er permanent",
        "siteActivated": "Lokasjon aktivert",
        "duplicateRegisteredClients": "Dupliserte registrerte klienter",
        "userId": "Bruker-ID"
    },
    "hospitality": {
        "hospitality": "Hotell",
        "room": "Rom | Rom",
        "additionalRooms": "Ekstra rom",
        "occupancy": "Opptatt",
        "guest": "Gjest | Gjester",
        "wakeup": "Vekke | Vekking",
        "guestMessage": "Meldinger | Melding | Meldinger",
        "stay": "Opphold",
        "account": "Konto | Kontoer",
        "virtualRemoteClients": {
            "session": "Sesjon",
            "roomName": "Romnavn",
            "screenName": "Skjermnavn",
            "sessionId": "Sesjons-ID",
            "remoteClientId" : "VRC ID",
            "created": "Lagd",
            "tvOnline": "TV online",
            "vrcOnline": "VRC online",
        },
        "config": {
            "chromecastCheckoutLabel": "Chromecast utsjekk",
            "chromecastCheckoutExplanation": "Når en gjest sjekker ut, vil Chromecasten bli tilbakestilt. Dette vil fjerne alle personlige data fra Chromecasten.",
            "chromecastFacilityIdLabel": "Chromecast fasilitets-ID",
            "chromecastTokenLabel": "Chromecast token",
            "serviceMenuCodeLabel": "Servicemeny kode",
            "nightTimeToggleLabel": "Nattmodus",
            "nightTimeInfo": "En periode hvor ingen TVer vil slås på automatisk unntatt for vekkinger. Dette er nyttig for å unngå å forstyrre gjester om natten.",
            "nightTimeFromLabel": "Nattmodus fra",
            "nightTimeToLabel": "Nattmodus til",
        }
    },
    "dashboard": {
        "welcome": "Velkommen {name}",
        "signedInto": "Du er for øyeblikket logget på lokasjonen {site}",
        "occupancyLastNextWeek": "Full booket forrige og neste uke",
        "upcomingWakeups": "Kommende vekkinger",
        "mediaChannels": "Definerte mediekanaler",
        "activeInstallCodes": "Aktive installasjonskoder!",
        "newIssuesLastDays": "Nye utgaver siste {days} dager",
        "newLastDays": "Ny de siste {days} dagene",
        "account": "Brukerkonto | Brukerkontoer",
        "apiAccount": "API-konto | API-kontoer",
        "occupancyPercent": "Full booket (%)",
        "sectionHelp": "Lukk og åpne dashborddeler ved å klikke på de respektive overskriftene",
        "filterSites": "Filtrer lokasjoner etter",
        "filterCustomers": "Filtrer kunder etter",
        "clickOnMap": "Klikk på elementet på kartet for detaljer",
        "displayingData": "Viser data for {amount} lokasjon | Viser data for {amount} lokasjoner",
        "noFeaturesActivated": "Ingen funksjoner aktivert ...",
        "clearSelection": "Fjern valg",
        "details": "Detaljer",
        "openExternal": "Åpne portal",
        "openSite": "Åpne lokasjon",
        "openSiteError": "Kan ikke åpne lokasjon av typen {type}",
        "openTvPreview": "Åpne TV forhåndsvisning",
        "notManaged": "Ikke administrert",
        "vacant": "Ledig",
        "managementAccess": "Adgang til ledelse",
        "mergeCCs": "Slå sammen Casts",
        "mapView": "Kartvisning",
        "listView": "Listevisning",
        "stats": "Statistikk",
        "map": "Kart",
        "list": "Liste",
        "noReportGenerated": "Ingen rapport generert",
        "buildReport": "Bygg Rapport",
        "rebuildReport": "Gjenoppbygge rapport",
        "generateStatisticsReport": "Generer statistikkrapport",
        "processingData": "Behandler data...",
        "configureFields": "Konfigurer felt",
        "downloadSelected": "Last ned valgt",
        "searchAllFields": "Søk i alle felter",
        "cluster": "Klynge",
        "installedTvs": "Installerte TVer",
        "product": "PRODUKT",
        "filter": "FILTER",
        "hideDemos": "Skjul Demos",
        "hideWhitelabeled": "Skjul hvitmerket",
        "hideWatermarked": "Skjul vannmerket",
        "licenseInfo": "Lisensinformasjon",
        "tvLicenses": "TV-lisenser",
        "signageLicenses": "Skiltlisenser",
        "castLicenses": "Cast-lisenser",
        "products": "Produkter",
    },
    "drawer": {
        "userSettings": "Brukerinnstillinger",
        "contextButtonHelp": "Klikk på hamburgermenyen i topplinjen for å bytte til en annen lokasjon eller til startskjermen",
        "mainHelp": "Hold markøren over menyen til venstre for å utvide den. Denne menyen inneholder hoved navigasjonselementene for gjeldende lokasjon eller startskjermbilde"
    },
    "userMenu": {
        "theme": "Tema",
        "dark": "Mørk",
        "notification": "Varsling | Varslinger",
        "pinMenu": "Pin-meny",
        "all": "Alle",
        "success": "Suksess",
        "info": "Info",
        "resetHints": "Tilbakestill tips",
        "settings": "Kontoinnstillinger",
        "logOut": "Logg ut",
        "confirmLogout": "Bekreft",
        "hintsReset": "Tips er tilbakestilt",
        "timeDisplay": "12-timers klokke"
    },
    "reorderList": {
        "dragDropHelp": "Dra og slipp listeelementer for å omorganisere dem"
    },
    "baseTable": {
        "selected": "{selected} selected {type} | {selected} selected {type}s",
        "enableMultiselect": "Skru på multivalg",
        "disableMultiselect": "Skru av multivalg",
        "searchLabel": "Søk alle synlige felt",
        "searchTooltip": "Sett samme søkeord ved å bruke '&'",
        "export": "Eksporter til excel"
    },
    "channelTables": {
        "channelTable": "TV-kanaltabell | TV-kanaltabeller",
        "radioChannelTable": "Radiokanaltabell | Radiokanaltabeller",
        "channelTableGroup": "Kanaltabellgruppe | Kanaltabellgrupper",
        "tableGroup": "Tabellgruppe | Tabellgrupper",
        "dragDropHere": "Dra og slipp kanaler hit",
        "newName": "Nytt navn",
        "availableChannels": "Tilgjengelige kanaler",
        "addAllChannels": "Legg til alle kanaler",
        "selectedChannels": "Valgte kanaler",
        "removeAllChannels": "Fjern alle kanaler",
        "unknownChannel": "Ukjent kanal",
        "noGroups": "Det er ingen grupper i denne tabellen",
        "table": "Tabell | Tabeller",
        "clickToChange": "Klikk her for å endre tabell",
        "createFirst": "Lag din første kanaltabell",
        "headerSelectHint": "Klikk på tabellnavnet i overskriften for å velge en annen tabell for redigering",
        "dragDropChannels": "Dra og slipp kanaler for å velge og omorganisere dem",
        "channelTableName": "Navn på kanaltabell",
        "new": "Ny",
        "deleteText": "Slett kanaltabell \"{name}\"? <br> Forsikre deg om at denne kanaltabellen ikke brukes i noen skjermkonfigurasjoner.",
        "saveGroupError": "Kan ikke lagre gruppe",
        "saveGroupChannelsError": "Feil ved lagring av kanaler i gruppen",
        "saveChannelsError": "Feil ved lagring av kanaler",
        "appendToGroupError": "Feil ved lagring i gruppe",
        "createGroupError": "Feil ved oppretting av gruppe",
        "createdGroup": "Gruppe {name} opprettet",
        "deletedGroup": "Gruppe {name} slettet",
        "channelsSaved": "Kanaler lagret"
    },
    "event": {
        "defaultEventValues": "Standardverdier for hendelse",
        "eventDeleteSuccess": "Hendelse slettet",
        "eventDeleteFailed": "Kunne ikke slette hendelse",
        "createEvent": "Opprett hendelse",
        "editEvent": "Rediger hendelse",
        "deleteEvent": "Slett hendelse",
        "eventPrivate": "Privat",
        "startTime": "Starttid",
        "startDate": "Startdato",
        "endTime": "Sluttid",
        "endDate": "Sluttdato",
        "eventHeader": "Hendelser - {date}",
        "eventPrivateHint": "(skjul tittel og vert på skjermer)",
        "eventInFuture": "Hendelsen må foregå i fremtiden",
        "eventEndBeforeStart": "Sluttid må være etter starttid",
        "eventOverlappingDescription": "En overlappende hendelse finnes for denne lokasjonen, er du sikker på at du vil opprette denne hendelsen?",
        "eventOverlapping": "Overlappende hendelse",
        "copyEvent": "Kopier hendelse {name}",
        "copyEventToLocation": "Kopier hendelse til lokasjon",
        "makeCopyEvent": "Kopier hendelse",
        "privateTitle": "Privat tittel",
        "noLocations": "Ingen lokasjoner funnet",
        "wayfindingConfiguration": "Veifinningskonfigurasjon | Veifinningskonfigurasjoner",
        "saveWayfindingConfig": "Lagre veifinningskonfigurasjon",
        "saveWayfindingConfigFailed": "Kunne ikke lagre veifinningskonfigurasjon",
        "integrationLocations": "Integreringslokasjon | Integreringslokasjoner",
        "manageLogos": "Behandle logoer",
        "selectLogo": "Velg logo",
        "replaceLogo": "Erstatt logo",
        "removeLogo": "Fjern logo",
        "manageIcons": "Behandle ikoner",
        "eventRetention": "Hendelsesbevaring",
        "eventRetentionDescription": "Angi hvor lenge hendelser skal beholdes i systemet",
        "maxEventDuration": "Hendelse",
        "maxEventDurationDescription": "Maksimal varighet for en hendelse når en booker på skjerm",
        "applyLogoByHost": "Legg til logoer basert på vertsnavn",
        "logoMappingDesc": "Automatisk legg til logoer basert på vertsnavn",
        "hostMapping": "Verts-mapping",
        "addHostMapping": "Legg til verts-mapping",
        "failedToSaveMapping": "Kunne ikke lagre mapping",
        "mappingAlreadyExists": "Mapping eksisterer allerede",
    },
    "tvMenu": {
        "tvMenuPage": "TV-menyside | TV-menysider",
        "menuPage": "Menyside | Menysider",
        "subtitle": "Undertekst | Undertekster",
        "audioLanguage": "Lydspråk",
        "subtitleLanguage": "Undertekstspråk",
        "epgMenu": "EPG meny | Menyer for EPG",
        "offlineMenu": "Meny for frakoblet modus",
        "externalInput": "Ekstern input meny | Menyer for Ekstern input",
        "wakeupMenu": "Vekking meny | Menyer for vekking",
        "defaultWakeupAlarmMenu": "Standard menyer for vekking",
        "powerOnMenu": "Slå på meny | Slå på menyen",
        "messageNotificationMenu": "Meldingsvarsling meny",
        "messageDisplayMenu": "Meny for meldingsvisning"
    },
    "documents": {
        "page": "Page | Pages",
        "document": "Dokument | Dokumenter",
        "selectPage": "Velg en side"
    },
    "resources": {
        "resource": "Ressurs | Ressurser",
        "font": "Font | Fonter",
        "image": "Bilde | Bilder",
        "video": "Video | Videoer",
        "playlist": "Spilleliste | Spillelister",
        "template": "Mal | Maler",
        "compositeMenuPages": "Mal | Maler",
        "logo": "Logo | Logoer",
        "wayfindingIcon": "Rettingssymbol | Retningssymboler",
        "uploadedYet": "Ingen {type} er lastet opp ennå",
        "selected": "{amount} {type} valgt | {amount} {type}er valgt",
        "copyUrl": "Kopier URL",
        "copyResources": "Kopier ressurs til {dest}? | Kopier {count} ressurser til {dest}?",
        "moveResources": "Flytt ressurs til mappe {dest}? | Flytt {count} ressurser til mappe {dest}?",
        "moveResource": "Flytt {src} til mappe {dst}?",
        "copying": "Kopiering",
        "copyToFolderSuccess": "Kopierte {resourceName} til mappe {folderName}",
        "copyToFolderFailure": "Kopiering av {resourceName} til mappe {folderName} misslyktes",
        "copyFinishedWithError": "Ferdig med feil",
        "copySuccessDescription": " | {amount} ressurs ble kopiert til {context} | {amount} ressurser ble kopiert til {context}",
        "circularReferenceErrorMsg": "{amount} {type} har sirkulære referanser, editer eller fjern disse for å kunne lagre.",
        "failedToCopy": "Kunne ikke kopiere",
        "downloadFile": "Last ned fil | Last ned filer",
        "downloadingName": "Laster ned {name}",
        "downloadFailed": "Nedlastning mislyktes!",
        "selectImage": "Velg et bilde",
        "selectFont": "Velg en font",
        "selectVideo": "Velg en video",
        "newPlaylist": "Ny spilleliste",
        "upload": "Last opp",
        "uploadMedia": "Last opp media",
        "uploadResource": "Last opp ressurs",
        "uploadFileType": "Last opp {type} | Last opp {type}er",
        "uploadSuccessful": "Opplasting vellykket - {type} behandles nå",
        "uploadError": "Feil under opplasting - prøv igjen",
        "cancelUploadConfirmTitle": "Cancel the remaining upload? | Cancel the {count} remaining uploads?",
        "cancelUploadConfirmMessage": "This will cancel the remaining uploads and close the dialog.",
        "replaceResource": "Erstatt {resource}",
        "replaceResourceTitle": "Erstatt {resource}: {name}",
        "replaceResourceDescription": "ADVARSEL! Elementer som erstattes er fortsatt mellomlagret i skyen så det vil ta tid før det nye bildet vises på skjermene.<br><br>Er du sikker på at du vil erstatte gjeldende {resource} med <br>\"{name}\"?",
        "resourceReplaced": "{resource} erstattet",
        "resourceReplacedFailed": "Kunne ikke erstatte {resource}",
        "editImageBefore": "Rediger bilde før du laster det opp",
        "uploaderTitle": "Last opp {type} s",
        "unsupportedFile": "Filer av typen {type} støttes ikke",
        "unsupportedFileSize": "Filen er for stor, maksimal størrelse er {size}",
        "unsupportedFileNameAndSize": "Filen {fileName} er for stor, maksimal størrelse er {size}",
        "exceedingMaxPdfPages": "For mange sider i PDF, maksimalt antall sider er {limit}",
        "wrongType": "Bare {type} er tillatt for opplasting",
        "quickBrownFox": "Den raske brunreven hopper over den late hunden",
        "addOneOrMore": "Legg til bildene, videoene og skriftene du vil laste opp",
        "addOneOrMoreExcludeVideos": "Legg til bildene, fontfilene og PDF-filer du vil laste opp",
        "addType": "Legg til {type} du ønsker å laste opp | Legg til {type} du ønsker å laste opp",
        "pressUpload": "Trykk på last opp for å lagre de tilføyde filene i systemet",
        "rotateLeft": "Roter 45 grader til venstre",
        "rotateRight": "Roter 45 grader til høyre",
        "crop": "Beskjær",
        "addResources": "Legg til ressurser",
        "uploadSuccessfulHover": "Lasting vellykket",
        "addDocument": "Legg til dokument",
        "addTemplate": "Legg til mal",
        "potentialUsageFound": "Følgende potensielle bruk ble funnet:",
        "potentialUsageFoundInItems": "Potensiell bruk ble funnet for gjenstander:",
        "folderNotEmpty": "Mappen er ikke tom",
        "deleteFolderWithUsage": "Disse mappene inneholder elementer med potensiell bruk",
        "deleteFolderWarning": "Hvis du sletter denne mappen, slettes også alt innholdet permanent, inkludert undermapper og ressurser.",
        "deleteFolderConfirm": "Jeg bekrefter at jeg vil slette denne mappen og dens innhold permanent",
        "newPlaylistFromPdf": "Ny spilleliste fra PDF",
        "displayDuration": "Visningsvarighet",
        "pdfUploadFolderError": "Kunne ikke opprette mappe for PDF, laster opp til rotmappe for media",
        "tag": "Tag | Tags",
        "addTag": "Legg til tag",
        "transcodeVideo4K": "Videoen vil være tilgjengelig i 4K kort tid etter opplasting",
        "transcodeVideoHD": "Videoen vil være tilgjengelig i HD kort tid etter opplasting"
    },
    "mediaChannels": {
        "mediaChannels": "mediakanaler",
        "mediaChannel": "Mediekanal | Mediekanaler",
        "globalMediaChannels": "Globale mediekanaler",
        "globalMapping": "Global mapping",
        "address": "Adresse",
        "port": "Port",
        "serviceId": "Service-ID",
        "frequency": "Freq (kHz)",
        "modulation": "Modulasjon",
        "symbolRate": "SymbolRate (kHz)",
        "bandwidth": "Båndbredde",
        "satelliteId": "Satellite ID",
        "polarization": "Polarization",
        "programNumber": "Programnummer",
        "majorNumber": "Major Number",
        "minorNumber": "Minor Number",
        "type": "Type",
        "audioOnly": "Bare lyd",
        "createChannel": "Opprett {type} kanal",
        "save": "Save channels",
        "saveTooltip": "Dette vil lagre kanaler av alle typer",
        "clickToChange": "Klikk her for å endre kanaltype",
        "reset": "Tilbakestill og oppdater kanaler",
        "types": "Kanaltyper",
        "webRadioSearch": "Nettradio søk",
        "unknownBitrate": "Ukjent",
        "placeholderChannelName": "Kanalnavn",
        "updateFailed": "Oppdatering av mediekanaler mislyktes",
        "deleteFailed": "Kunne ikke slette kanalen!",
        "createFailed": "Kunne ikke opprette kanal!",
        "updateSuccess": "Mediakanaler oppdatert",
        "deleteSuccess": "Mediakanaler slettet",
        "createSuccess": "Mediakanaler opprettet",
        "validateFailed": "En kanal av typen {type} har et tomt navn"
    },
    "templates": {
        "templateErrorMessages": {
            "deserializationErrorMsg": "Malmotoren kunne ikke analysere API-dataene. Kontroller innstillingene for faste parametere / test / API-koblinger for å sikre at riktige data blir hentet."
        },
        "dataFetchErrorMessages": {
            "unknownHost": "URL er ukjent når du prøver å hente data fra API. Dette kan være forårsaket av en skrivefeil i API Connector URL og / eller i en fast / testparameter.",
            "protocolError": "Feil i HTTP-protokoll når du prøver å hente data fra API. Dette kan være forårsaket av en skrivefeil i API Connector URL og / eller fast / testparameter.",
            "unknownError": "Feil under henting av API-data. Sjekk innstillinger for API-kontakt / fast / testparameter.",
            "code300ErrorMsg": "API returnerte statuskode 300 - Multiple Choices, an indication that there exists multiple options for the requested resource. Check test / fixed parameter settings.",
            "code400ErrorMsg": "API returnerte statuskode 400 - Bad Request, API could not understand the request due to invalid syntax. Check Connector / test / fixed parameter settings.",
            "code401ErrorMsg": "API returnerte statuskode 401 - Unauthorized, You are not authorized to make this request. Check the API Connector for correct headers",
            "code403ErrorMsg": "API returnerte statuskode 403 - forbidden. This could mean that Connector or fixed parameters is set up with incorrect, invalid or outdated authorization.",
            "code404ErrorMsg": "API returnerte statuskode 404 - Not Found. This can mean that the url is valid but the resource itself does not exist. APIs may also send this response instead of 403 to hide the existence of a resource from an unauthorized client. Make sure Connector / test /fixed parameter settings are correct.",
            "code406ErrorMsg": "API returnerte statuskode 406 Not acceptable, API didn't find any content that conforms to the criteria given. Check test / fixed parameters.",
            "code415ErrorMsg": "API returnerte statuskode 415 - Unsupported Media Type. Check API Connector settings. Try to set Content-Type custom header to a format supported by the API",
            "code500ErrorMsg": "API returned status code 500 - Internal Server error. Check test / fixed parameter settings.",
            "apiTimeout": "API Connector timeout. Sjekk innstillinger for API-kontakt / arametere.",
        },
        "serializationErrors": {
            "jsonParseError": "Kunne ikke analysere JSON-dataene som ble returnert fra API, vennligst sjekk API Connector / faste parameterinnstillinger."
        },
        "internalErrors": {
            "code500ErrorMsg": "Det er et problem med systemene våre, prøv igjen om en liten stund."
        },
        "noDataYet": "Ingen data ennå.",
        "noDataFoundYet": "Ingen data funnet ennå!",
        "save": "Lagre mal uten å lukke vinduet",
        "setATest": "Sett en test {name}",
        "infoUrlDefault": "URL til API-infoside",
        "infoUrlTooltip": "Hvordan bruke kilde",
        "error": "Feil ved evaluering av mal",
        "httpMethodRequired": "HTTP-metode kreves",
        "nameRequired": "Navn kreves",
        "paramType": "Parametertype",
        "addItem": "Legg til element",
        "preview": "Generer forhåndsvisning",
        "template": "Mal | Maler",
        "templateRequired": "Mal kreves",
        "reset": "Tilbakestill endringene som er gjort i denne malen siden siste lagring",
        "source": "Kilde | Kilder",
        "sourceRequired": "Kilde kreves",
        "fixedParameters": "Faste parametere",
        "setAFixed": "Angi et fast {name}",
        "templateExample": "Mal eksempel",
        "dataExample": "Dataeksempel",
        "httpMethod": "HTTP-metode",
        "markup": "Markup",
        "sourceError": "Feil: Kilde kunne ikke lastes",
        "selectConfigure": "Velg og konfigurer mal",
        "failedSource": "Feil: Kunne ikke finne malkilde",
        "failedSourceFetch": "Feil: Kunne ikke hente kildeinfo",
        "templateSource": "Mal kilde | Mal kilder",
        "rootName": "Root navn",
        "apiConnector": "API-kontakt | API-kontakter",
        "createTemplate": "Opprett mal",
        "createWithoutClosing": "Opprett mal uten å lukke vindu",
        "saveWithoutClosing": "Lagre mal uten å lukke vindu",
        "fetchApiData": "Hent API-data",
        "cacheForSeconds": "Buffer ikke aktivert (0 sekunder) | Cache API-mal for {value} sekund | Cache API-mal for {value} sekunder",
        "cacheForSecondsError": "Tallet må være mellom 0 og 1800",
        "defaultTemplateErrorExplanation": "Innhold som skal vises på TV hvis malen inneholder feil."
    },
    "configSchedules": {
        "defaultConfig": "Standard konfigurasjon",
        "dailyOverrides": "Daglige overstyringer",
        "weeklyOverrides": "Ukentlige overstyringer",
        "editTimeslot": "Rediger timeslot",
        "deleteTimeslot": "Slett timeslot",
        "dragStopTime": "Dra for å endre sluttid",
        "defaultContentExplanation": "Innhold som skal vises på skjermen hvis ingen andre overstyringer er aktive.",
        "editScheduleName": "Rediger navn på kjøreplan",
        "tabDefault": "Standard",
        "tabDaily": "Daglig",
        "tabWeekly": "Ukentlig",
        "tabCalendar": "Kalender",
        "addTimeslotHelpText": "Klikk for å legge til en ny timeslot, eller bare klikk på en åpning i kalenderen.",
    },
    "scheduleMapping": {
        "createNew": "Opprett ny planleggings kartlegging",
        "rename": "Gi nytt navn til {title}",
        "created": "Ny timekartlegging opprettet",
        "updated": "Tidsplanleggingskarting oppdatert",
        "currentSchedule": "Gjeldende tidsplan",
        "deleteHeading": "Slett Schedule Mapping {title}?",
        "deleteText": "Alle skjermbilder som er en del av denne kartleggingen vil bli flyttet til standard planleggingsgruppen for kartlegging.",
        "addMapping": "Legg til kartlegging",
        "screens": "Skjerm | Skjermer",
        "screensHeadingInfo": "TV-skjermer som bruker regelsett. Høyreklikk for å flytte.",
        "moveTo": "Flytt til ...",
        "rulesHeading": "Reglene evalueres i den rekkefølgen de vises her",
        "rulesDescription": "Egendefinerte regler støttes ennå ikke.",
        "newRule": "Ny regel",
        "defaultSchedule": "Standard tidsplan",
        "screensMoved": "Skjermene flyttet",
        "createFailed": "Kunne ikke opprette ny kjøreplan",
        "updateFailed": "Kunne ikke oppdatere kjøreplan",
    },
    "schedulePlans": {
        "editor": {
            "notInUse": "Overordnede kjøreplaner ikke i bruk. Trykk 'Opprett' for å begynne å bruke.\nVi genererer automatisk en 'Standard' overordnet kjøreplan som inneholder gjeldende skjermoppsett i tillegg til den nye overordnede kjøreplanen du oppretter.",
            "newForEach": "Separate kjøreplaner for hver skjerm",
            "singleNew": "En delt kjøreplan for alle skjermer",
            "copyExisting": "Koper eksisterende kjøreplansoppsett",
            "removeWhenActive": "<b>Denne overordnede kjøreplanen er for øyeblikket aktiv</b>. Hvis du velger å fjerne den, vil alle skjermer bli byttet til standard overordnet kjøreplan. <br><br> Er du sikker på at du vil fjerne denne overordnede kjøreplanen?"
        },
        "screenEditor": {
            "newScheduleForEach": "Lag en ny kjøreplan for hver overordnet kjøreplan",
            "individualSelectionDescription": "Individuelle valg"
        },
        "type": "Overordnet kjøreplan | Overordnede kjøreplaner",
        "setActive": "Set som aktiv",
        "setActiveConfirmTitle": "Set aktiv overordnet kjøreplan?",
        "disabledDeleteForDefault": "Sletting av standard overordnet kjøreplan er kun tillatt når det ikke eksisterer andre overordnede kjøreplaner.",
        "setActiveConfirmText": "Denne handlingen vil sette overordnet kjøreplan '{title}' aktiv. Alle skjermer vil ha kjøreplaner definert under overordnet kjøreplan '{title}' satt som sine aktive kjøreplaner. Ønsker du å fortsette?",
        "setActiveSuccess": "Overordnet kjøreplan '{title}' satt som aktiv",
        "setActiveFailed": "Kunne ikke sette overordnet kjøreplan '{title}' som aktiv"
    },
    "screens": {
        "screensMissingSchedulesTitle": "Skjermer som mangler kjøreplaner",
        "screensMissingSchedulesText": "Disse skjermene har ingen kjøreplaner. Dette kan ha skjedd om registreringsprosessen ble avbrutt før den var ferdig.",
        "screenStatus": "Skjermstatus | Skjermstatus",
        "tvScreenStatus": "TV-skjermstatus",
        "signageDisplayStatus": "Signage-skjermstatus",
        "screens": "Skjermer",
        "livePreview": "Sanntids forhåndsvisning",
        "displays": "Skjermer",
        "onlineScreens": "online TV-skjermer",
        "screenSetup": "Skjermoppsett | Skjermoppsett",
        "screenSetupCreated": "Skjermoppsett opprettet",
        "screenSetupUpdated": "Skjermoppsett oppdatert",
        "defaultScreenConfig": "Standardkonfigurasjon",
        "tvScreen": "TV-skjerm | TV-skjermer",
        "signageScreen": "Signage-skjerm | Signage-skjermer",
        "tvType": "TV-type | TV-typer",
        "clientType": "Klienttype | Klienttyper",
        "tvSoftware": "TV-programvare",
        "editName": "Rediger navn",
        "tvClient": "TV-klient | TV-klienter",
        "unknownSetup": "Ukjent oppsett",
        "createFirstSchedule": "Lag din første tidsplan",
        "schedule": "Tidsplan | Tidsplaner",
        "copyFromConfig": "Kopier fra config (valgfritt)",
        "quicklyCopyConfig": "Kopier raskt en annen konfigurasjon",
        "mainMenu": "Hovedmeny | Hovedmenyer",
        "welcomeMenu": "Velkomstmeny | Velkomstmenyer",
        "channelMenu": "TV-kanalmeny | TV-kanalmenyer",
        "radioChannelMenu": "Radiokanalmeny | Radiokanalmenyer",
        "startVolume": "Start volum",
        "maxVolume": "Maks volum",
        "autoPowerOffHrs": "Slå av automatisk etter {hrs} timer",
        "enableAutoPowerOff": "Aktiver automatisk avslåing",
        "audioLanguageMenu": "Lydspråkmeny",
        "subtitleLanguageMenu": "Språkmeny for teksting",
        "externalInputMenu": "Ekstern inngangsmeny | Eksterne inngangsmenyer",
        "noScreenSetups": "Ingen skjermoppsett er opprettet ennå",
        "selectSlotType": "Velg sporstype",
        "scheduleEditorCalendarHint": "Klikk hvor som helst i kalenderen for å legge til en ny tidsliste",
        "editSlot": "Rediger spor",
        "newSlot": "Nytt spor",
        "createNewConfig": "Opprett ny konfigurasjon",
        "timeslotOverlap": "Den valgte start- og sluttiden overlapper med en annen timeslot. Prøv igjen med andre tider.",
        "volume": "Volum",
        "durationRequired": "Varighet kreves",
        "durationLongerThan": "Varigheten må være lengre enn 0 sekunder",
        "messageInJson": "Melding i JSON-format",
        "validJson": "Må være gyldig JSON",
        "sendMessageToScreen": "Send {type} melding til {screens} skjerm | Send {type} melding til {screens} skjermer",
        "sendMessage": "Send melding",
        "updateStatus": "Oppdater status",
        "pushConfig": "Oppdater konfigurasjon",
        "pushContent": "Oppdatert innhold",
        "upgradeAgentSoftware": "Oppgrader Agent SW",
        "upgradeAgentSoftwareNotification": "Dette vil sende en melding til agenten om å oppgradere programvaren. Som en del av oppgraderingen kan agenten starte på nytt, noe som vil føre til et kort brudd på agenttjenesten. Vær oppmerksom på at hvis ingen oppdateringer er tilgjengelige, vil ingen handlinger bli utført.",
        "poweredOn": "Slått på",
        "poweredOff": "Slått av",
        "powerOn": "Slå på",
        "powerOff": "Slå av",
        "reboot": "Omstart",
        "rebootRequired": "Endringen krever en omstart av skjermen<br>Vil du starte om skjermen nå?",
        "jvmUptime": "JVM Oppetid",
        "rebootRequested": "Omstart forespurt",
        "appUpdateRequested": "Venter på oppdatering av applikasjon",
        "setVolume": "Still volum",
        "displayMessage": "Vis melding",
        "displayAddress": "Visningsadresse",
        "displayName": "Vis navn på skjerm",
        "resetSoftAP": "Tilbakestill SoftAP-passord",
        "displayPage": "Vis side",
        "tuneChannel": "Tune Channel",
        "enableDebug": "Aktiver feilsøking",
        "reAuthenticate": "Re-autentisere",
        "reAuthenticateMsg": "Du er i ferd med å re-autentisere skjermen {screen}. Vennligst bekreft for å fortsette.",
        "getScreenshot": "Få skjermbilde",
        "sendPushMessage": "Send push-melding",
        "openClientPreview": "Åpne forhåndsvisning av klient",
        "sendMgmntMessage": "Send Mgmnt-melding",
        "moveToLocation": "Flytt til sted",
        "changeSchedule": "Endre kjøreplan",
        "selectSchedule": "Velg kjøreplan",
        "manage": "Administer",
        "moveScreens": "Flytt {screens} skjerm | Flytt {screens} skjermer",
        "deleteScreens": "Slett {screens} skjerm? | Slett {screens} skjermer?",
        "setContentScheduleForScreenExistingNameMsg": "Planen med navn {screenName} eksisterer allerede, ny plan med navn {uniqueName} vil bli opprettet.",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Navnekollisjon i følgende tidsplaner:<br>",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> - Planen med navn <span style='font-style: italic;'>{screenName}</span> Ny plan med navn <span style='font-style: italic;'>{uniqueName}</span> vil bli opprettet.<br>",
        "move": "Flytt",
        "name": "Navn",
        "ip": "IP",
        "mac": "MAC",
        "rotation": "Rotasjon",
        "deviceType": "Enhetstype",
        "appVersion": "Appversjon",
        "firmwareVersion": "Firmware-versjon",
        "osVersion": "OS Versjon",
        "details": "Detaljer",
        "occupied": "Okkupert",
        "online": "Online",
        "offline": "Frakoblet",
        "power": "På",
        "state": "Tilstand",
        "public": "Offentlig",
        "clientId": "Klient ID",
        "setRotation": "Sett rotasjon",
        "id": "Klient-ID",
        "locationTitle": "Lokasjon",
        "previewOnlyOne": "Velg bare en for skjerm for forhåndsvisning",
        "screensMoved": "{screens} skjerm flyttet til et annet sted | {screens} skjermer flyttet til et annet sted",
        "installCode": "Installer kode| Installer koder",
        "createInstallCode": "Opprett installasjonskode",
        "code": "kode",
        "createSchedule": "Lag en tidsplan",
        "created": "opprettet",
        "createdBy": "Laget av",
        "expiryDate": "Utløpsdato",
        "configInUse": "Feil: Konfigurasjonen brukes",
        "configInUseDescription": "{name} er i bruk av følgende tidsplaner:",
        "scheduleInUse": "Feil: Tidsplanen brukes",
        "scheduleInUseDescription": "{name} er i bruk av følgende tidsplantilordninger:",
        "configTvSettingsHeading": "TV-innstillinger",
        "configChannelsHeading": "Kanaler",
        "configMenusHeading": "Menyer",
        "addConfiguration": "Legg til konfigurasjon",
        "groupBy": "Gruppere etter",
        "setSchedule": "Angi tidsplan...",
        "upgradeApp": "Oppgrader applikasjon",
        "helpText": "Skriv inn en melding som skal vises før den slås av. <br> Hvis du ikke stiller inn noen melding, slås skjermen (e) umiddelbart av.",
        "newTvScreen": "Ny TV-skjerm",
        "clientIdRequired": "Klient ID kreves",
        "pickLocation": "Velg et sted",
        "noLocation": "Det er ingen tilgjengelige steder",
        "roomRequired": "Rom kreves",
        "roomSearchPlaceholder": "Begynn å skrive for å søke",
        "tvPreRegisteredOn": "TV forhåndsregistrert på",
        "tvRegisteredOn": "TV registrert på",
        "tvRegisteredCloseText": "Lukk denne fanen.",
        "pickContext": "Velg en kontekst",
        "noContext": "Det er ingen tilgjengelige sammenhenger",
        "contextRequired": "Kontekst kreves",
        "clientToken": "Klient token",
        "clientTokenRequired": "Klienttoken kreves",
        "couldNotFindItemToEdit": "Kunne ikke finne elementet du vil redigere",
        "uptime": "Oppetid",
        "memoryUsage": "Mem bruk",
        "requestScreenshot": "Ber om skjermbilde, vent ...",
        "requestScreenshotOffline": "Kan ikke be om skjermbilde fra frakoblet enhet ...",
        "requestScreenshotPoweredOff": "Kan ikke be om skjermbilde fra avslått enhet ...",
        "requestNewScreenshot": "Klikk for å be om et nytt skjermbilde ...",
        "requestUpdate": "Be om oppdatering",
        "serial": "Serie",
        "firmware": "Firmware",
        "resolution": "Oppløsning",
        "system": "System",
        "publicIp": "Offentlig IP",
        "softAP": "Soft AP",
        "currentConfig": "Gjeldende konfigurasjon",
        "rcAddr": "RC addr",
        "network": "Nettverk",
        "timeOnDevice": "Tid på enhet",
        "openPreview": "Åpne forhåndsvisning",
        "screenInfo": "Skjerminfo",
        "reloadAllMediaTitle": "Last inn all media",
        "reloadAllMediaDescription": "Slett all media på {amount} enhet og last ned på nytt? | Slett all media på {amount} enheter og last ned på nytt?",
        "moveToScheduleTitle": "Angi planleggingskartlegging",
        "changeScreenScheduleDescription": "Endre kjøreplan for skjerm til {mapping}?",
        "changeScreenScheduleSuccess": "Endret kjøreplan til {mapping}",
        "moveToScheduleDescription": "flytt {amount} skjermer for å planlegge {mapping}?",
        "moveToScheduleSuccess": "Ny tidsplan satt for {amount} skjermer",
        "moveToSite": "Flytt skjerm til lokasjon",
        "moveScreenToSiteMsg": "Advarsel! Du er i ferd med å flytte denne skjermen til et annet lokasjon, bekreft for å fortsette.",
        "moveScreenToSiteSuccess": "Skjermen flyttet til ny lokasjon",
        "moveScreenToSiteNotAllowed": "Ikke tillatt å flytte skjermen til denne lokasjon",
        "moveScreenToSiteNotAllowedNoLicense": "Kan ikke flytte skjermen til denne lokasjon: Lokasjonen har ikke {license} lisenser tilgjengelig.",
        "moveScreenToSiteNotAllowedLimitReached": "Kan ikke flytte skjermen til denne lokasjon: Lisensgrensen for denne skjermtypen er nådd.",
        "createScreenLimitReachedMsg": "Kan ikke opprette ny skjerm: Lisensgrensen for denne skjermtypen er nådd.",
        "successfullyAuthenticated": "Skjermen ble re-autentisert",
        "canNotReAuthenticate": "Kan ikke re-autentisere en allerede autentisert skjerm",
        "appMode": "Applikasjonsmodus",
        "forceUpdate": "Tving oppdatering",
        "services": "Tjenester",
        "upgradablePackages": "Oppgraderbare pakker",
        "authCodeNotFound": "Autentiseringskoden er ikke gyldig lenger. Vennligst sjekk skjermen for en gyldig kode.",
        "lastReboot": "Siste omstart",
        "storage": "Lagring",
        "authCreateNewSchedule": "Dette vil opprette en ny kjøreplan med samme navn som den opprettede skjermen og plassere skjermen i den kjøreplanen. <br> Ved å ikke velge denne opsjonen, kan du velge å legge til skjermen i en allerede eksisterende kjøreplan.",
        "authUseExistingSchedule": "Velg en allerede eksisterende kjøreplan for å legge til skjermen i:",
        "registrationCode": "Registreringskode",
        "registrationCodeLength": "6 tall",
    },
    "booking": {
        "booking": "Booking | Booking",
        "numberOfBookings": "{number} booking | {number} bookinger",
        "book": "Book",
        "bookNights": "Book {netter} overnatting",
        "night": "Natt | Netter",
        "stayFromTo": "{from} til {to}",
        "stayCheckedIn": "- sjekket inn",
        "calendar": "Kalender",
        "availabilityError": "Kunne ikke hente tilgjengelighetsdata for bestilling",
        "bookingsForDate": "Bestillinger for {date}",
        "noBookingsForDate": "Ingen bestillinger for {date}",
        "failedToRetrieve": "Kunne ikke hente bookinger",
        "timeslotTaken": "Det tidspunktet er tatt, prøv et annet"
    },
    "guests": {
        "anonymous": "Anonymt",
        "language": "Språk",
        "unspecified": "Uspesifisert",
        "pickRoom": "Velg et ledig rom",
        "checkInDate": "Innsjekkingsdato",
        "checkInTime": "Innsjekkingstid",
        "checkOutDate": "Utsjekkingsdato",
        "checkOutTime": "Utsjekkingstid",
        "newCheckIn": "Ny innsjekking",
        "checkIn": "Innsjekking",
        "checkInGuest": "Sjekk inn gjest",
        "editGuest": "Rediger gjest",
        "checkOut": "Utsjekking",
        "getBill": "Få regning",
        "guestCheckOut": "Sjekk ut gjesten",
        "billItemName": "Artikkelnavn",
        "billItemAmountMax4Chars": "amnt",
        "billItemCurrencyMax3Chars": "cur",
        "billDataError": "Kunne ikke hente fakturainformasjon ({status})",
        "confirmCheckout": "Bekreft utsjekking",
        "editStay": "Rediger opphold",
        "editBooking": "Rediger booking",
        "newBooking": "Ny booking",
        "checkInBeforeOut": "Utsjekking må skje etter innsjekking.",
        "noGuests": "Ingen gjester",
        "overrideTvConfig": "Overstyr TV-konfigurasjon",
        "setTvConfigConfirm": "Overstyr TV-konfigurasjon \"{configMapping}\" for denne gjesten?<br>Konfigurasjonen vil bli gjenopprettet når gjesten sjekker ut.",
        "clearTvConfigConfirm": "Fjern overstyrt TV-konfigurasjon for denne gjesten?<br>Konfigurasjonen vil bli satt tilbake til standard.",
        "setTvConfigSuccess": "{amount} TV ble overstyrt | {amount} TVer ble overstyrt",
        "sendMessage": "Sende melding",
        "guestsNumber": "{number} gjest | {number} gjester",
        "person": "Person | Personer",
        "forceCheckOutTitle": "Tving utsjekking",
        "forceCheckOutMessage": "Denne gjesten ble automatisk sjekket inn via et eksternt system. Det eksterne systemet er ansvarlig for å sjekke ut gjesten igjen. Å tvinge denne kassen vil ikke sjekke ut gjesten fra det eksterne systemet og kan forårsake problemer. Vil du fortsette?"
    },
    "guestMessages": {
        "to": "Til",
        "unreadMessages": "Ulest melding",
        "subject": "Tittel",
        "create": "Ny melding",
        "view": "Se melding",
        "createdHeader": "Sendt",
        "readHeader": "Lest",
        "displayedHeader": "Varslet",
        "senderName": "Fra",
        "instant": "Umiddelbar visning",
        "messageTemplate": "Meldingsmal | Meldingsmaler",
        "useTemplate": "Bruk mal"
    },
    "wakeups": {
        "roomAccount": "Rom (konto)",
        "tvMenu": "TV-meny å vise",
        "customTvMenu": "Bruk egendefinert TV-meny for å vise",
        "wakeupInFuture": "Vekking må være i fremtiden",
        "wakeupExists": "Vekkingen eksisterer allerede",
        "create": "Opprett vekking",
        "daily": "Daglig",
        "status": "Status",
        "delivered": "Levert",
        "notDelivered": "Ikke levert",
        "displayed": "Vist",
        "notDisplayed": "Ikke vist",
        "confirmed": "Bekreftet",
        "notConfirmed": "Ikke bekreftet",
        "editWakeup": "Rediger vekking",
        "deliveredWakeup": "Levert vekking",
        "newWakeup": "Ny vekking"
    },
    "scheduledTasks": {
        "dailyNewSheets": "Daglige nye ark",
        "roomType": "Type rom | Romtyper",
        "allTypes": "Alle typer",
        "roomTypeEmpty": "La stå tomt for å sette denne oppgaven til alle rom",
        "when": "Når",
        "createTask": "Opprett oppgave",
        "specificRooms": "Spesifikke rom"
    },
    "issues": {
        "maintenanceType": "Vedlikeholdstype | Vedlikeholdstyper",
        "changeStatus": "Endre status",
        "setIssueTo": "Sett problemet til ...",
        "editIssue": "Rediger problem",
        "created": "opprettet",
        "closed": "Stengt",
        "issueSetTo": "Problem på {room} satt til {status}",
        "issuesSetTo": "{issues} problemer satt til {status}",
        "newIssue": "Ny utgave | Nye utgaver",
        "editingIssues": "Editing {issues} issues",
        "issueUpdated": "{type} utgave på {room} oppdatert",
        "issueAdded": "{type} utgave lagt til {room}",
        "urgent": "Haster",
        "flagAsClosed": "Flagg som lukket",
        "issueSetup": "Problemoppsett"
    },
    "locations": {
        "location": "Lokasjon | Lokasjoner",
        "createRooms": "Lag rom",
        "createLocations": "Opprett lokasjoner",
        "occupation": "Full booket",
        "locatedIn": "Ligger i",
        "locationSetTo": "{location} satt til {status}",
        "locationsSetTo": "{locations} rom satt til {status}",
        "locationSetup": "Stedoppsett",
        "category": "Kategori | Kategorier",
        "setRoomTo": "Sett {rom} til ...",
        "editRoom": "Rediger rom",
        "changeStatus": "Endre status",
        "changeCategory": "Endre kategori",
        "changeLocation": "Endre plassering",
        "addTag": "Legg til tag",
        "removeTag": "Fjern tagg",
        "addIssue": "Legg til problem",
        "tagRemoved": "Tag {tag} fjernet fra {rooms} rom",
        "tagAdded": "Tag {tag} lagt til i {rooms} rom",
        "categoryChange": "{room} kategori endret til {category} | {rooms} endret til category {category}",
        "newRooms": "Nye rom",
        "newLocations": "Nye steder",
        "roomNumber": "Romnummer",
        "locationNumber": "Stednummer",
        "startRange": "Startområde",
        "endRange": "End rangering",
        "parentLocations": "Overordnede steder",
        "startRangeRequired": "Startområde er påkrevd",
        "startRangeLower": "Startområdet må være lavere enn sluttområdet",
        "endRangeRequired": "Sluttområde kreves",
        "endRangeHigher": "Sluttområdet må være høyere enn startområdet",
        "change": "Endre",
        "newLocation": "Ny plassering",
        "changeLocationOf": "Endre plassering for {rom} rom | Endre plassering for {rom} rom",
        "locationChangeSuccess": "Stedsendring vellykket",
        "roomTag": "Rommerke | Rommerker",
        "newTag": "Ny tagg",
        "newStatus": "Ny status",
        "issueDeletedFrom": "Problem slettet fra {room}",
        "locationCategory": "Stedskategori | Stedskategorier",
        "roomCategory": "Romkategori | Romkategorier",
        "newCategory": "Ny kategori",
        "bedroomTv": "Soveroms-TV",
        "meetingRoomTV": "Møteroms-TV",
    },
    "serviceConfig": {
        "saveConfig": "Lagre konfigurasjon",
        "disableDailyRebootWarning": "Advarsel: Dette vil hindre at noen enheter mottar automatiske programvareoppdateringer",
        "forceTvMode": "Dette vil tvinge TV til å vise TV-kanaler når TV slås på i stedet for den konfigurerte hovedmenyen",
        "rebootTime": "Reboot-tid",
        "rebootTimeInvalid": "Reboot-tid ugyldig",
        "instantContentPush": "Umiddelbar oppdatering av innhold",
        "instantContentPushDescription": "Dette vil sende alle innholdsoppdateringer til berørte enheter umiddelbart uten varsel.",
        "useInternalURL": "Dette vil konfigurere TVer til å laste enkelte ressurser fra en intern proxy-kilde i stedet for å laste dem fra internett.",
    },
    "login": {
        "terms": "Vilkår",
        "resetPassword": "Tilbakestill passord",
        "language": "Språk",
        "username": "Brukernavn",
        "password": "Passord",
        "logIn": "Logg inn",
        "cloudManagementPortal": "Administrasjonsportal",
        "enterUsername": "Skriv inn brukernavn og passord",
        "enterEmail": "Skriv inn e-postadressen din",
        "invalidEmail": "Ugyldig e-postadresse",
        "resetMessage": "Ytterligere instruksjoner er sendt til {email}",
        "resetFailedMessage": "Tilbakestilling av passordforespørsel mislyktes: {message}",
        "setNewPassword": "Skriv inn nytt passord",
        "redirectToLoginPageTitle": "Viderekoble til påloggingsside",
        "redirectToLoginPageText": "Du blir nå omdirigert til påloggingssiden."
    },
    "latLng": {
        "openMap": "Åpne",
        "lat": "Breddegrad",
        "lng": "Lengdegrad",
        "selectPosition": "Velg et sted",
        "addressHint": "Du kan skrive inn adresse manuelt",
        "addressWarning": "Forsikre deg om at bredde- og lengdegrad samsvarer med adressen",
        "searchFieldLabel": "Søk etter adresse",
        "address": "Addresse",
        "locationAddressErrorMessage": "Kontakt lisensadministratoren din for å legge til adresseinformasjon",
        "maxLength": "Maks lengde"
    },
    "folder": {
        "newResources": "Nye ressurser",
        "uploadFile": "Last opp fil",
        "folder": "Mappe | Mapper",
        "noFolders": "Det er ingen mapper ennå",
        "doubleClickSelect": "Dobbeltklikk for å velge",
        "newFolder": "Ny mappe",
        "copy": "Kopier",
        "cut": "Klipp ut",
        "paste": "Lim inn",
        "selected": "{amount} valgt {type} | {amount} valgte {type}er",
        "deleting": "Slett {amount} enhet | Slett{amount} enheter",
        "root": "Root",
        "home": "Hjem",
        "emptyFolder": "Denne mappen er tom",
        "createFolder": "Opprett mappe",
        "deleteFolder": "Slett mappe",
        "moveToFolder": "Flytt til mappe",
        "copyToFolder": "Kopier til mappe",
        "editFolder": "Rediger mappe",
        "imageSize": "Bildestørrelse",
        "unknownImageSize": "Ukjent bildestørrelse"
    },
    "auth": {
        "loginNotAvailable": "Tjenesten er ikke tilgjengelig - prøv igjen senere",
        "wrongUserPass": "Feil brukernavn og / eller passord",
        "responseError": "Feil i svar fra serveren",
        "noCredentials": "Legitimasjonsinformasjon ikke oppgitt - kan ikke logge inn",
        "errorMirageSession": "Du har logget inn, men det oppstod et problem ved lasting av portalen.<br><br>Prøv å laste på nytt eller kontakt support.",
        "errorMirageSessionPPDS": "Du har logget inn, men kontoen din har ikke tilgang til noen PPDS Wave-kunder.<br><br>Prøv å laste på nytt eller kontakt support.",
        "invalidMirageSession": "Du har logget inn, men din tilgang til portalen er deaktivert.<br><br>Prøv en annen påloggingsleverandør eller kontakt support.",
        "invalidMirageSessionPPDS": "Du har logget inn, men tilgangen til PPDS Wave Creator er deaktivert.<br><br>Prøv en annen påloggingsleverandør eller kontakt support.",
        "unknownMirageSession": "Du har logget inn, men en ukjent feil har oppstått.<br><br>Prøv en annen påloggingsleverandør eller kontakt support."
    },
    "accessPanel": {
        "resellerTitle": "Administrator for tilgang til brukeradgang for forhandler",
        "resellerDescription": "Konfigurer bruker- og API-tilgang til: <br> a) administrer forhandleren til avsetningssteder og lisenser (<span style='font-weight:bold;'> Kontotilgang </span>). Dette gjør gir IKKE tilgang til lokasjonene. <br> b) få tilgang til disse forhandlerlokasjonene (<span style='font-weight: bold;'> Lokasjonstilgang </span>). Dette gir det gitte tilgangsnivået til ALLE lokasjoner som tilhører dette forhandler.",
        "distributorTitle": "Distribusjon av brukeradgangsadministrasjon",
        "distributorDescription": "Konfigurer bruker- og API-tilgang til: <br> a) administrer distributøren for avsetningsforhandlere (<span style='font-weight:bold;'> Kontotilgang </span>). Dette gir IKKE tilgang til forhandlerne. <br> b) få tilgang til forhandlerne forhandlere (<span style='font-weight: bold;'> Forhandlertilgang </span>). Dette gir det gitte tilgangsnivået til ALLE forhandlere som tilhører denne distributøren.",
        "domainTitle": "Domenebrukeradgangsadministrasjon",
        "domainDescription": "Konfigurer bruker- og API-tilgang til: <br> a) administrer domenet for å tildele distributører (<span style='font-weight:bold;'> Kontotilgang </span>). Dette gir IKKE tilgang til distributører. <br> b) få tilgang til denne domenets distributører (<span style='font-weight: bold;'> Distributørtilgang </span>). Dette gir det gitte tilgangsnivået til ALLE distributører som tilhører dette domenet.",
        "resellerAccess": "Forhandlertilgang",
        "sharingContextsAccess": "Delingskonteksttilgang",
        "deploymentAccess": "Lokasjonsadgang",
        "themeAccess": "Tematilgang",
        "distributorAccess": "Distibutørtilgang",
        "domainAccess": "Domeneadgang",
        "accessControl": "Adgangskontroll",
        "accessControlDescription": "Velg forhandleren du vil redigere",
        "accessError": "Du har ikke tilgang til brukeradministrasjon for noen {contextType}"
    },
    "theme": {
        "theme": "Tema | Temaer",
        "importWindowHeader": "Angi destinasjonsmappe",
        "editThemeImage": "Rediger temabilde",
        "imagePlaceholder": "Ingen bilder er satt",
        "editThemeCard": "Rediger temakort",
        "publishSnapshot": "Publiser tema",
        "publishDescription": "Publisering av et tema overskriver alle tidligere publiserte versjoner. Det har ingen effekt på lokasjoner som importerte de forrige versjonene.",
        "publishSharingDescription": "Deling av den publiserte versjonen er satt opp under Hjem -> Forhandler -> Temaer. Du trenger forhandlerdelingstilgang for å sette dette opp.",
        "lastPublished": "Sist publisert",
        "ThemeNotPublished": "Temat er ikke publisert",
        "resourceStats": "Ressursstatistikk",
        "editorInfo": "Vær oppmerksom på at du bør sjekke opphavsrettigheter og generell beredskap for hele pakken av elementer før du klikker på \"Publiser øyeblikksbilde\", slik at alle angitte brukerkontoer kan importere temaet",
        "noFeaturesActivated": "Ingen funksjoner aktivert ...",
        "importInfo": "Importer komplette interaktive menytemaer. Ingen av det eksisterende innholdet vil bli endret",
        "importInfoTheme": "Importer andre temaer til dette temaet.",
        "import": "Importer",
        "folderExists": "Mappen eksisterer allerede",
        "importLoader": "Importerer tema",
        "importSuccess": "Temaet ditt er importert og kan bli funnet under \"Innhold\"",
        "importSuccessAsync": "Importjobben er startet. Vennligst vent et par minutter før du jobber videre med innholdet.",
        "importError": "Slett mapper med navnet: {folder} under \"Innhold\" og prøv å importere igjen",
        "previewConfiguration": "Konfigurasjon av forhåndsvisning av tema",
        "savePreviewError": "Kunne ikke lagre konfigurasjon!",
        "launchPreview": "Start forhåndsvisning",
        "noConfigurationFound": "FEIL: Ingen konfigurasjonssett funnet...",
        "themePublished": "Tema publisert",
        "published": "publisert",
        "themePublishedError": "Kan ikke publisere tema",
        "themeSharingSuccess": "Temadeling oppdatert",
        "themeSharingError": "Kan ikke oppdatere deling",
        "themeEmptyError": "Kan ikke angi deling før du publiserer temaet",
        "fetchError": "Kan ikke hente nåværende delingsdata",
        "editTitle": "Rediger kort for å angi en tittel.",
        "editDescription": "Rediger kort for å angi en beskrivelse.",
        "shareWith": "Del med:",
        "shareAll": "Alt",
        "shareSubGroup": "Undergruppe",
        "shareSingle": "Singel",
        "successfullyImported": "Vellykket importert",
        "successfullyImportedAsync": "Import startet",
        "importFailed": "Import mislyktes",
        "newTheme": "Nytt tema",
        "preview": "Forhåndsvisning av tema",
        "previewNotAvailable": "Forhåndsvisning ikke tilgjengelig - kontakt support",
        "previewInfo": "Start en virtuell TV-klient i nettleseren din for å teste temaet. <br> Denne forhåndsvisningen blir ikke vannmerket.",
        "loadThemesError": "Kan ikke hente temaer",
        "themeControl": "Temakontroll",
        "themeControlDescription": "Velg temaet du vil redigere",
        "enablePublishInfo": "Angi all publiseringsinformasjon (tittel, beskrivelse og temabilde) for å aktivere publisering",
        "enableSharing": "Du må angi minst én deling for at temaet ditt skal kunne publiseres",
        "panel": {
            "title": "Forhandlerenes teamer",
            "description": "Konfigurer temaer"
        },
        "themeName": "Temanavn"
    },
    "sharingContext": {
        "type": "Delingskontekst | Delingskontekster",
        "rootFolderPrefix": "Delt",
        "new": "Ny ressursleverandør",
        "deleteConfirmTitle": "Slett ressursleverandør",
        "deleteConfirmMsg": "Vennligst bekreft at du vil slette denne ressursleverandøren",
        "editorInfo": "Vær oppmerksom på at alle endringer som gjøres i media i denne ressursleverandørkonteksten, potensielt vil være umiddelbart tilgjengelig for alle steder som denne leverandøren deler til.<br>Vær også VELDIG forsiktig med å slette elementer da de kan være i bruk av steder.",
        "deleteWarning": "ADVARSEL! Vær VELDIG forsiktig med å slette elementer da de kan være i bruk av lokasjoner",
        "deleteConfirm": "Bekreft at jeg vil slette denne {type} | Bekreft at jeg vil slette disse {type}ene"
    },
    "signage": {
        "signage": "Signage",
        "schedule": "kjøreplan|kjøreplaner",
        "addTimeslot": "Legg til timeslot",
        "contentSchedule": "",
        "noSchedules": "Ingen kjøreplaner er satt opp. Legg til en skjerm ved hjelp av installatørkoden, eller lag en tidsplan.",
        "mergeDefaultTitle": "Slå sammen med standardinnhold",
        "mergeDefaultConfirm": "Er du sikker på at du ønsker å slå sammen dette timeslot med standardinnhold?",
        "mergeDefaultTimeslotTitle": "FUSJONERER MED STANDARDINNHOLD",
        "startEndTimeInvalidMsg": "Slutttidspunktet er tidligere enn starttidspunktet! Lagring er deaktivert.",
        "manageDefaultContentBtn": "Administrer standardinnhold",
        "defaultContentDescription": "Dette innholdet kjører hele dagen på skjermen med mindre det overstyres av daglig, ukentlig eller kalenderbasert innhold.",
        "upcoming": "Kommende",
        "addNewDailyRecurring": "Legg til ny daglig gjentakende timeslot",
        "addNewWeekly": "Legg til ny ukentlig gjentakende timeslot",
        "addNewCalendarTimeslotFor": "Legg til ny dato-timeslot for ",
        "editDefaultContent": "Rediger standardinnhold",
        "editDailyRecurringTimeslot": "Rediger daglig gjentakende timeslot",
        "editWeeklyRecurringTimeslot": "Rediger ukentlig gjentakende timeslot",
        "editCalendarTimeslotFor": "Rediger dato-timeslot for ",
        "contentListTitle": "Dra ressurser fra høyre side til listen nedenfor for å bygge spillelisten din",
        "durationLabel": "Lengde(sekunder)",
        "deleteContentListEntry": "Fjern fra spilleliste",
        "interactivityDisplayDelay": "Interaktivitetsvisningsforsinkelse"
    },
    "pms": {
        "pmsHeader": "Eiendomsadministrasjonssystemkonfigurasjon",
        "vendor": "Leverandør",
        "accessToken": "Tilgangstoken",
        "tigerAccessTokenHint": "Autentiseringskode for TigerTMS API - Også kalt for 'wsUserKey' i TigerTMS systemer",
        "siteName": "Lokasjon navn",
        "tigerSiteNameHint": "Unikt navn for hotellet i TigerTMS",
        "directoryId": "Mappe ID",
        "soapApiUrl": "SOAP API Endepunkt",
        "soapApiUrlHint": "API endepunkt benytter https://checkout.tigertms.com/GenericEXT/ som standard med mindre annet er spesifisert.",
        "linkCheckerIn": "",
        "linkCheckerOut": "",
        "enableDailyReset": "Aktiver daglig tilbakestilling",
        "pmsLink": "PMS oppkobling",
        "lastMsgReceived": "",
        "hour": "time",
        "minute": "minutt",
        "forceSync": "tving synkronisering",
        "forceSyncSuccess": "Synkroniseringsforespørsel ble sendt!",
        "forceSyncError": "Synkroniseringsforespørsel mislyktes!",
        "pmsGetVendorError": "Kunne ikke hente {pms} leverandørlisten!",
        "ip": "IP",
        "port": "Port",
        "useChecksum": "Bruk sjekksum",
        "pmsVendor": "",
        "defaultTimeoutSeconds": "Meldings-timeout (sekunder)",
        "charSet": "Karaktersett"
    },
    "cast": {
        "config": {
            "adminConfigHeader":  "",
            "authToken":  "",
            "authTokenHint":  "",
            "networks": {
                "createNew":  "",
                "addNetworkWithType":  "",
                "addChromecastNetwork":  "",
                "useExisting":  "",
                "interfaceName":  "",
                "parentInterface":  "",
                "networkPrefixLength":  "",
                "vlanTag":  "",
                "chromecastNetworks":  "",
                "clientNetworks":  "",
                "proxyIp":  "",
                "vlanId":  "",
                "interface":  "",
                "parentIface":  "",
                "ipAddressHint":  "",
                "ipPlaceholder":  "",
                "networkPrefixPlaceholder":  "",
                "vlanTagPlaceholder":  "",
            },
            "hostPlaceholder":  "",
            "networkProxyHost":  "",
            "networkProxyHostHint":  "",
            "proxy":  "",
            "chromecastProxy":  "",
            "chromecastNetworkType":  "",
            "chromecastProxyServerNetworkConfig":  "",
            "chromecastProxyServerStatus":  "",
            "networkInterfaceStatus":  "",
            "chromecastProxyServerSignageStatus":  "",
            "proxyIsInternetGateway":  "",
            "internetInterface":  "",
            "isGatewayForChromecasts":  "",
            "isDHCPForChromecasts":  "",
            "isGatewayForClients":  "",
            "isDHCPForClients":  "",
            "chromecastProxyServerForcedReturnPath":  "",
            "webInterfaceForceReturnPath":  "",
            "webInterfaceForceReturnPathHint":  "",
            "forcedReturnPathGateway":  "",
            "forcedReturnPathGatewayPlaceholder":  "",
            "forcedReturnPathGatewayHint":  "",
            "advancedNetworking":  "",
            "proxyServerConfig":  "",
            "chromecastConfig":  "",
            "pairingCodeConfig":  "",
            "clientRegistrationHost":  "",
            "clientRegistrationHostHint":  "",
            "clientRegistrationHostPlaceholder":  "",
            "guestWifiName":  "",
            "guestWifiNameHint":  "",
            "guestWiFiPassword":  "",
            "guestWiFiPasswordHint":  "",
            "enableSignageIntegration":  "",
            "globalChromecastName":  "",
            "globalChromecastNameHint":  "",
            "globalChromecastNamePlaceholder":  "",
            "timeZone":  "",
            "timeZoneHint":  "",
            "locale":  "",
            "localeHint":  "",
            "pairingCodeSet":  "",
            "pairingCodeSetHint":  "",
            "pairingCodeIsCaseSensitive":  "",
            "pairingCodeLength":  "",
            "pairingCodeTimeoutMinutes":  "",
            "pairingCodeTimeoutMinutesHint":  "",
            "pairTimeoutHours":  "",
            "pairTimeoutHoursHint":  "",
            "useCustomTimeoutTimePerDayInstead":  "",
            "networkProxySaveError":  "",
            "saveError":  "",
            "saveSuccess":  "",
            "connectionSuccess":  "",
            "connectionFailure":  "",
            "interfaceFetchFailure":  "",
            "reloadTooltipDefault":  "",
            "resetTooltip":  "",
            "routingOfClientChromecastSessions":  "",
            "routingOfClientNetworkMulticast":  "",
            "supportNetflix":  "",
            "failedToLoadMsg":  "",
        },
        "eventLogs": {
            "typeDescription":  "",
            "time":  "",
            "eventName":  "",
            "castUdn":  "",
            "castName":  "",
            "clientVid":  "",
            "additionalData":  "",
        },
        "response": {
            "chromecastFetchFailed":  "",
            "unregisteredCastFetchFailed":  "",
            "activeSessionFetchFailed":  "",
            "statusFetchFailed":  "",
            "statisticsFetchFailed":  "",
            "eventLogFetchFailed":  "",
            "chromecastProxyConfigFetchFailed":  "",
            "networksFetchFailed":  "",
            "advancedNetworkingFetchFailed":  "",
        },
        "status": {
            "reassociationBtnText":  "",
            "reassociationTitle":  "",
            "reassociationWarning":  "",
            "reassociationMsg":  "",
            "reassociationSuccess":  "",
            "reassociationFailed":  "",

            "version":  "",
            "serverId":  "",
            "licensedFor":  "",
            "status":  "",
            "eno1":  "",
            "wip58s0":  "",
            "clientId":  "",
            "clientToken":  "",
            "installerToken":  "",
            "installerCode":  "",
            "connected":  "",
            "authenticated":  "",
        },
        "chromecasts": {
            "info": {
                "castConfig":  "",
                "deviceAlias":  "",
                "deviceName":  "",
                "uptime":  "",
                "isConfigurable":  "",
                "wifiMac":  "",
                "ssid":  "",
            },
            "refreshStatusBtn":  "",
            "refreshStatusSuccess":  "",
            "refreshStatusFailed":  "",
            "saveSuccess":  "",
            "saveFailed":  "",
            "unregister":  "",
            "showConfig":  "",
            "unregisterConfirmTitle":  "",
            "unregisterConfirmMsg":  "",
            "unregisterSuccess":  "",
            "unregisterFailed":  "",
            "updateStatusSuccess":  "",
            "updateStatusFailed":  "",
            "showNameSuccess":  "",
            "showNameFailed":  "",
            "rebootSuccess":  "",
            "rebootFailed":  "",
            "deletePairingsConfirmTitle":  "",
            "deletePairingsConfirmMsg":  "",
            "deletePairingsSuccess":  "",
            "deletePairingsFailed":  "",
            "forceStartCastingFailed":  "",
            "chromecastPairingCode":  "",
        },
        "unregisteredCasts": {
            "title":  "",
            "register":  "",
            "registerChromecast":  "",
            "registerSuccess":  "",
            "genericFailure":  "",
            "limitReachedFailure":  "",
            "deleteCastConfirmTitle":  "",
            "deleteCastConfirmMsg":  "",
            "deleteCastFailed":  "",
            "deleteCastSuccess":  "",
        },
        "statistics": {
            "fetch":  "",
            "usageInHours":  "",
            "noOfPairingsPerDay":  "",
            "appUsageInHours":  "",
            "consecutiveHourFilterLabel":  "",
            "consecutiveHourFilterHint":  "",
            "downloadVisiblePeriod":  "",
            "exportPairings":  "",
            "exportAppUsage":  "",
        },
        "activeSessions": {
            "title":  "",
            "pinOrUnpin":  "",
            "pinned":  "",
            "pinSuccess":  "",
            "unpinSuccess":  "",
            "multiplePinUnpinSuccess":  "",
            "pinFailed":  "",
            "disconnect":  "",
            "disconnectConfirmTitle":  "",
            "disconnectConfirmMsg":  "",
            "disconnectSuccess":  "",
            "disconnectFailed":  "",

        },
        "type": "Chromecast | Chromecasts",
        "runningApp": "",
        "available": "",
        "connection": "",
        "mac": "",
        "macAddress": "",
        "paired": "",
        "udn": "",
        "signalNoiseLevel": "",
        "client": "",
        "configurable": "",
        "connected": "",
        "createdAt": "",
        "actions": {
            "updateStatus": "",
            "deletePairings": "",
            "showName": "",
            "stopCurrentSession": "",
            "generatePairingCode": "",
            "exportSelectedToCsv": "",
            "forceStartCasting": ""
        },
        "networkProxySaveConfirmationMsg": "",
        "castServiceOfflineMsg": ""
    },
    "streamManagement": {
        "allocatedStreams": {
            "device": "",
            "allocatedStream": "",
            "itemType": "",
            "addDevice": "",
            "editDevice": "",
            "deallocateStreamTitle": "",
            "deallocateStreamConfirmMsg": "",
            "editAllocatedStream": "",
            "mediaUrl": "",
            "streamAllocatorConfig": "",
            "deallocate": "",
            "deleteDeviceMsg": "",
            "deleteDeviceConfirm": "",
            "chromecastIntegrationDescription": ""
        },
        "config": {
            "minTimeoutWarn": ""
        },
        "allocationLogEntries": {
            "eventType": {
                "allocationFailure": ""
            },
            "eventReason": {
                "noAvailableDevices": ""
            },
            "title": "",
            "itemType": ""
        }
    },
    "jobs": {
        "jobList": {
            "title": "Jobbliste",
        },
        "startMsgs": {
            "imageUpload": "Opplasting av bilde {title} startet",
            "fontUpload": "Opplasting av font {title} startet",
            "themeImport": "Import av tema {title} startet",
            "logoUpload": "Opplasting av logo {title} startet"
        },
        "successMsgs": {
            "imageUpload": "Opplastring av bilde {title} vellykket",
            "fontUpload": "Opplasting av font {title} vellykket",
            "logoUpload": "Opplasting av logo {title} vellykket",
        },
        "failedMsgs": {
            "imageUpload": "Oppplasting av bilde {title} feilet",
            "fontUpload": "Oppplasting av font {title} feilet",
            "logoUpload": "Oppplasting av logo {title} feilet",
        },

    },
    "content": {
        "config": {
            "resourceserver": "Ressursserver",
            "resourcesyncservice": "Ressurs synkroniseringstjeneste",
            "networkproxy": "Nettverksproxy",
            "resourceServerPort": "Ressursserver Port",
            "resourceServerPortHint": "Skriv inn porten som skal brukes av ressursserveren",
            "resourceServerFileLocation": "Ressursserver Filplassering",
            "resourceServerFileLocationHint": "Skriv inn filplasseringen som skal brukes av ressursserveren",
            "resourceSyncServiceFileLocation": "Ressurs synkroniseringstjeneste filplassering",
            "resourceSyncServiceFileLocationHint": "Skriver inn filplasseringen som skal brukes av ressurssynkroniseringstjenesten",
            "enableChangingURL": "Overstyr URL",
            "saveConfig": "Lagre konfigurasjon",
            "saveConfigWarn": "Er du sikker på at du vil lagre konfigurasjonen? Feil konfigurasjon kan føre til at systemet ikke fungerer som forventet.",
            "saveSuccess": "Konfigurasjon lagret",
            "configSaveError": "Kunn ikke lagre konfigurasjon",
            "rangeError": "Port må være i området 1024-49151",
            "checkConfig": "Test konfigurasjon",
            "configurationOK": "Konfigurasjon OK",
            "errorWithConfiguration": "Feil med konfigurasjon",
            "missingConfig": "Trykk på 'Test konfigurasjon' for å sjekke konfigurasjonen",
            "changeURLWarn": "Advarelse: Endring av URL vil føre til at alle eksisterende ressurser må lastes opp på nytt",
        }
    }
}