<template>
    <v-dialog
            v-model="showViewer"
            :content-class="$app.desktop ? 'resource-viewer-desktop' : 'resource-viewer-mobile'"
    >
        <v-card height="100%">
            <v-card-title>{{ resource ? resource.name : '' }}</v-card-title>
            <v-card-text style="height: calc(95% - 100px);">
                <v-container style="height: 100%;" fluid v-if="$validator.isNotEmpty(resource)">
                    <v-row style="height: 100%;">
                        <v-col md="8" sm="12" class="preview">
                            <v-img
                                    v-if="resourceType === 'image' && showViewer"
                                    :src="resource.resourceUrl + '?rev=' + resource.rev"
                                    contain
                                    height="100%"
                            >
                                <template v-slot:placeholder>
                                    <v-layout fill-height align-center justify-center>
                                        <v-progress-circular color="primary" size="80" indeterminate></v-progress-circular>
                                    </v-layout>
                                </template>
                            </v-img>
                            <video
                                    v-else-if="resourceType === 'video' && showViewer"
                                    style="width: 100%; height: 100%;"
                                    controls
                                    autoplay
                                    :src="resource.resourceUrl + '?session-id=' + sessionId"
                            ></video>
                            <div
                                    v-else-if="showViewer"
                                    class="font-preview"
                                    :style="`font-family: 'font-${resource.id}' !important;`"
                            >
                                ABCDEFGHIJKLMNOPQRSTUVWXYZ<br><br>{{
                                    $t('resources.quickBrownFox').toUpperCase()
                                }}<br><br>{{ $t('resources.quickBrownFox') }}
                            </div>
                        </v-col>
                        <v-col md="4" sm="12">
                            <v-card style="margin-top: 25px;">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title>{{ $t('generic.name') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ resource.name }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>{{ $t('generic.filename') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ formatOriginalFileName(resource.originalFilename) }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title>{{ $t('generic.uploaded') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ resource.createdDate }}</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item v-if="resource.width !== null && resource.width !== undefined">
                                        <v-list-item-title>{{ $t('generic.width') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ resource.width }}px</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item v-if="resource.height !== null && resource.height !== undefined">
                                        <v-list-item-title>{{ $t('generic.height') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ resource.height }}px</v-list-item-subtitle>
                                    </v-list-item>
                                    <v-list-item v-if="resource.duration !== null && resource.duration !== undefined">
                                        <v-list-item-title>{{ $t('date.duration') }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ formatDuration(resource.duration) }}</v-list-item-subtitle>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            <v-card style="margin-top: 25px; margin-bottom: 25px;">
                                <v-card-title>Resource usage</v-card-title>
                                <v-card-text style="padding-bottom: 0;" v-if="hasSignage">
                                    Signage schedules: <br>
                                    <v-chip v-for="page in this.resourceUsageSignageSchedules(resource.id, true)" :key="page" style="margin: 2px;" :outlined="page === 'None'" small>{{ page }}</v-chip>
                                </v-card-text>
                                <v-card-text style="padding-bottom: 0;" v-if="hasSignage">
                                    Template-pages: <br>
                                    <v-chip v-for="page in this.resourceUsageTemplatePages(resource.id, true)" :key="page" style="margin: 2px;" :outlined="page === 'None'" small>{{ page }}</v-chip>
                                </v-card-text>
                                <v-card-text style="padding-bottom: 0;" v-if="hasTv">
                                    Menu-pages: <br>
                                    <v-chip v-for="page in this.resourceUsageMenuPages(resource.id, true)" :key="page" style="margin: 2px;" :outlined="page === 'None'" small>{{ page }}</v-chip>
                                </v-card-text>
                                <v-card-text style="padding-bottom: 0;" v-if="hasTv">
                                    Static data-sources: <br>
                                    <v-chip v-for="page in this.resourceUsageDataStores(resource.id, true)" :key="page" style="margin: 2px;" :outlined="page === 'None'" small>{{ page }}</v-chip>
                                </v-card-text>
                                <v-card-text style="padding-bottom: 10px;">
                                    Documents: <br>
                                    <v-chip v-for="page in this.resourceUsageDocuments(resource.id, true)" :key="page" style="margin: 2px;" :outlined="page === 'None'" small>{{ page }}</v-chip>
                                </v-card-text>
                            </v-card>
                            <v-card style="margin-top: 25px; margin-bottom: 25px;">
                                <v-card-title>
                                    {{ resource.moduleName === 'logos' ? this.$t("event.hostMapping") : this.$tc("resources.tag", 2)}}
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            :disabled="resource.shared"
                                            @click.prevent.stop="addTag(resource)"
                                    >
                                        <v-icon>add</v-icon>
                                        {{ resource.moduleName === 'logos' ? this.$t("event.addHostMapping") : $t("resources.addTag") }}
                                    </v-btn>
                                </v-card-title>

                                <v-card-text>
                                    <v-row style="padding-top: 20px; padding-left: 10px">
                                        <v-chip-group
                                                v-for="(tag, i) in resource.tags"
                                                :key="i"
                                        >
                                            <v-chip
                                                    @click="removeTag(resource, tag)"
                                            >
                                                {{ tag }}<v-icon right size="20">close</v-icon>
                                            </v-chip>
                                        </v-chip-group>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions style="padding-top: 20px; padding-bottom: 20px;">
                <v-spacer></v-spacer>
                <v-btn
                        :elevation="$app.desktop ? 1 : 0"
                        @click="showViewer = false"
                >
                    {{ $t('generic.close') }}
                </v-btn>

                <v-spacer v-if="!$app.desktop"></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import contentUsageMixin from "@/mixins/contentUsageMixin.js";
import {mapState} from "vuex";

export default {
    name: "TheResourceViewer",
    mixins: [contentUsageMixin],
    data() {
        return {
            activeResource: 0,
            showViewer: false,
            selectedIndex: 0,
            resource: null,
            resourceType: 'image'
        }
    },

    computed: {
        ...mapState({
            sessionId: state => state.auth.sessionId,
            logos: state => state.logos.items
        }),
        hasSignage() {
            return this.$app.licenseLimitCheck('Clients', 'Clients', 'SignageClientCount', 1);
        },
        hasTv() {
            return this.$app.licenseLimitCheck('Clients', 'Clients', 'TvClientCount', 1);
        },
    },

    created() {
        Vue.prototype.$resourceViewer = this;
    },

    watch: {
        selectedIndex(index) {
            this.activeResource = this.$clone(index);
        }
    },

    methods: {
        show(resourceType, resource) {
            this.resourceType = resourceType;
            this.resource = resource;
            this.showViewer = true;
        },
        formatDuration(secs) {
            if (secs < 60) {
                return secs + "s";
            }
            let seconds = secs % 60;
            let minutes = Math.floor(secs / 60);
            if (minutes < 60) {
                return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
            }
            const hrs = Math.floor(minutes / 60);
            minutes = minutes % 60;
            return hrs + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
        },
        deleteSelected() {
            const type = this.$tc('resources.resource', 1);
            const resource = this.$clone(this.resource);

            if (this.hasAnyUsages(this.resource.id)) {
                this.$confirm.deleteWithCustomText(this.$tc('generic.deleteWithType', 1, {type: this.resourceType}),
                        this.$t('resources.potentialUsageFound') + "<br>" +
                        this.resourceUsageMenuPagesText(this.resource.id, false) +
                        this.resourceUsageDocumentsText(this.resource.id, false) +
                        this.resourceUsageDataStoresText(this.resource.id, false) + '<br>' +
                        this.$tc('generic.deleteWithType', 1, {type: this.resourceType}) + "?",
                        async () => {
                            const response = await this.$store.dispatch(this.resourceType + 's/delete', {item: resource});
                            this.showViewer = false;

                            if (this.$responseCheck(response)) {
                                this.$notification.deleted(type);
                            }
                        },
                        () => {
                        }
                );
            } else {
                this.$confirm.delete(type, 1, async () => {
                    const response = await this.$store.dispatch(this.resourceType + 's/delete', {item: resource});
                    this.showViewer = false;

                    if (this.$responseCheck(response)) {
                        this.$notification.deleted(type);
                    }
                });
            }
        },
        addTag(resource) {
            console.log(resource)
            let typeText = ""
            let addText = ""
            let notificationText = ""

            if(resource.moduleName === 'logos') {
                typeText = this.$t("event.hostMapping")
                addText = this.$t("event.addHostMapping")
                notificationText = this.$t("event.failedToSaveMapping")
            } else {
                typeText = this.$tc("resources.tag", 1)
                addText = this.$t("resources.addTag")
                notificationText = this.$t("resources.failedToSaveTags")
            }

            this.$prompt.show(addText, typeText, '', async (tag) => {
                resource.tags.push(tag)
                if (await this.updateResource(resource)) {
                    this.$notification.saved(typeText);
                } else {
                    this.$notification.show('error', notificationText)
                }
            }, null, (input) => {
                if (resource.moduleName !== 'logos') {
                    return null;
                }
                let tagAlreadyExists = false;
                this.logos.forEach(logo => {
                    if(!logo.shared){
                        logo.tags.some(tag => {
                            if (input.toLowerCase() === tag.toLowerCase()) {
                                tagAlreadyExists = true;
                            }
                        })
                    }
                })
                return tagAlreadyExists ? this.$t("event.mappingAlreadyExists") : null
            });
        },
        removeTag(resource, tag) {
            Array.remove(resource.tags, tag)

            let typeText = ""
            let notificationText = ""

            if(resource.moduleName === 'logos') {
                typeText = this.$t("event.hostMapping")
                notificationText = this.$t("event.failedToSaveMapping")
            } else {
                typeText = this.$tc("resources.tag", 1)
                notificationText = this.$t("resources.failedToSaveTags")
            }

            if (this.updateResource(resource)) {
                this.$notification.deleted(typeText);
            } else {
                this.$notification.show('error',notificationText);
            }
        },
        formatOriginalFileName(encodedFileName) {
          const regex = /%([0-9a-fA-F]{2})/g;
          if(encodedFileName) {
            return encodedFileName.replace(regex, (match, hexCode) => {
              return String.fromCharCode(parseInt(hexCode, 16));
            });
          }
        },
        async updateResource(resource) {
            const module = resource.moduleName;
            const response = await this.$store.dispatch(module + '/update', {item: resource});

            return this.$responseCheck(response);
        }
    }
}
</script>
<style lang="scss">
.resource-viewer-desktop {
    height: 95% !important;
    max-height: 95% !important;
    width: 95%;
    overflow: hidden;

    .v-card {
        display: flex !important;
        flex-direction: column;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }

    .v-card__title {
        height: 60px;
        padding-top: 8px;
    }

    .preview {
        height: 100%;
    }

    .font-preview {
        max-width: 100%;
        max-height: 100%;
        padding: 30px;
        background: white;
        color: black;
        font-size: 50px;
        line-height: 60px;
    }

}

.resource-viewer-mobile {
    height: 90% !important;
    max-height: 90% !important;
    width: 100%;
    overflow: hidden;

    .v-card {
        display: flex !important;
        flex-direction: column;
    }

    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }

    .v-card__title {
        height: 60px;
        padding-top: 8px;
    }

    .preview {
        height: 50% !important;
    }

    .font-preview {
        max-width: 100%;
        max-height: 100%;
        padding: 30px;
        background: white;
        color: black;
        font-size: 24px;
        line-height: 24px;
    }
}

</style>