import Vue, { PluginObject } from 'vue';
import Keycloak, { KeycloakConfig } from 'keycloak-js';

let kcUrl = process.env.VUE_APP_KEYCLOAK_URL_DEFAULT;
let kcDomain = process.env.VUE_APP_KEYCLOAK_REALM_DEFAULT;
let kcClient = process.env.VUE_APP_KEYCLOAK_CLIENT;

const hostName = window.location.hostname;
if (hostName.includes('wave')) {
    kcUrl = process.env.VUE_APP_KEYCLOAK_URL_PPDS;
    kcDomain = process.env.VUE_APP_KEYCLOAK_REALM_PPDS;
}
else if (hostName.includes('sys')) {
    kcUrl = process.env.VUE_APP_KEYCLOAK_URL_SYSTEM;
    kcDomain = process.env.VUE_APP_KEYCLOAK_REALM_SYSTEM;
}
else if (hostName.includes('uniguest')) {
    kcUrl = process.env.VUE_APP_KEYCLOAK_URL_UNIGUEST;
    kcDomain = process.env.VUE_APP_KEYCLOAK_REALM_UNIGUEST;
}

const _keycloak = new Keycloak({
    url: kcUrl,
    realm: kcDomain,
    clientId: kcClient
});

const Plugin = {
    install: Vue => {
        Vue.$keycloak = _keycloak;
    },
};
Plugin.install = Vue => {
    Vue.$keycloak = _keycloak;
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak;
            },
        },
    });
};

Vue.use(Plugin);

export default Plugin;
