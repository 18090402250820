import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'playlists';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withDurationById: () => {
        const data = module.cloneItems();

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.duration = 0;
                if(Array.isArray(item.elements)) {
                    item.elements.forEach(element => {
                        item.duration += parseInt(element.duration);
                    });
                }
            });
            Array.sort(data, 'name');
            return Map.createFromArray(data, 'id');
        }
        return {};
    }
});

export default module;