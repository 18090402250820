import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'compositeMenuPages';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withPath: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const folders = rootGetters['compositeMenuPageFolders/idMapByGetter']('translated');

        data.forEach(item => {
            let fullPath = item.name;
            let count = 0;

            if (typeof item.folder === 'string' && item.folder.length > 0) {
                let currFolder = folders.hasOwnProperty(item.folder) ? folders[item.folder] : null;
                while (currFolder && count < 5) {
                    fullPath = currFolder.nameTranslated + " / " + fullPath;
                    if (typeof currFolder.parent === 'string') {
                        currFolder = folders.hasOwnProperty(currFolder.parent) ? folders[currFolder.parent] : null;
                    } else {
                        currFolder = null;
                    }
                    count++;
                }
            }
            item.nameWithPath = fullPath;
        });
        return data;
    },
    byCategory: (state, getters, rootState) => (category) => {
        if (!category) {
            return getters.withPath;
        }
        const data = getters.withPath;

        Array.sort(data, 'name');

        return data.filter(item => {
            return (Array.isArray(item.categories) && item.categories.includes(category));
        });
    }
});

export default module;