<template>
    <BaseModal
        v-model="showModal"
        :title="selectionDisabled ? $t('generic.manageType', { type: itemType }) : $t('generic.selectType', { type: itemType })"
        custom-class="folder-structure-selector"
        :done-action-text="selectionDisabled ? $t('generic.close') : $t('generic.selectType', { type: itemType })"
        :done-action-disabled="!selectionDisabled && selected === null"
        :hide-cancel-action="selectionDisabled"
        @done="onDone"
    >
        <template v-slot:content>
            <FolderStructure
                v-if="folderModule !== null"
                :folder-module="folderModule"
                :item-modules="itemModules"
                :main-item-field="mainItemField"
                :item-type="itemType"
                :create-enabled="createEnabled"
                :router-support="false"
                :select-mode="true"
                :root-label="itemType"
                :add-button-text="$t('resources.uploadResource')"
                @create="create"
                @itemDoubleClick="selectItem"
                @itemSelected="onSelected"
                :selection-disabled="selectionDisabled"
                :multi-selection-disabled="multiSelectionDisabled"
            ></FolderStructure>
        </template>
    </BaseModal>
</template>

<script>
    import Vue from 'vue';
    import BaseMenu from "../base/popup/BaseMenu.vue";
    import BaseModal from "../base/popup/BaseModal.vue";
    import FolderStructure from "./FolderStructure.vue";
    import BaseActionItem from "../base/action/BaseActionItem.vue";
    import ResourceTypes from "@/lib/definitions/ResourceTypes.js";

    export default {
        name: "TheFolderStructureSelector",
        components: {BaseActionItem, FolderStructure, BaseModal, BaseMenu},
        data() {
            return {
                showModal: false,
                callbackFunction: null,
                folderModule: null,
                mainItemField: null,
                itemType: '',
                itemModules: [],
                selected: null,
                createEnabled: false,
                mediaType: null,
                selectionDisabled: false,
                multiSelectionDisabled: false,
                doubleClickToPreview: false
            }
        },
        created() {
            Vue.prototype.$folderStructureSelector = this;
        },
        methods: {
            show(itemType, folderModule, itemModules, mainModuleField, createEnabled, callback) {
                this.folderModule = folderModule;
                this.itemModules = itemModules;
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.mainItemField = mainModuleField;
                this.itemType = itemType;
                this.createEnabled = createEnabled;
                this.showModal = true;
            },
            onDone() {
                this.showModal = false;
                if(!this.doubleClickToPreview) {
                    this.callbackFunction(this.selected);
                }
            },
            onSelected(selected) {
                if(selected) {
                    for (let key in selected) {
                        if (selected.hasOwnProperty(key)) {
                            if(selected[key].hasOwnProperty('moduleName')){
                                if(selected[key].moduleName === 'images'){
                                    if(selected[key].uploadState !== "DONE"){
                                        return;
                                    }
                                }
                            }
                            this.selected = this.$clone(selected[key]);
                            return;
                        }
                    }
                }
                this.selected = null;
            },
            selectMenuPage(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'name';
                this.folderModule = 'interactiveMenuPageFolders';
                this.itemModules = ['interactiveMenuPages'];
                this.itemType = this.$tc('tvMenu.tvMenuPage', 1);
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = null;
            },
            selectDocument(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'name';
                this.folderModule = 'documentFolders';
                this.itemModules = ['documents'];
                this.itemType = this.$tc('documents.document', 1);
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.createEnabled = false;
                this.showModal = true;
                this.mediaType = null;
            },
            selectTemplate(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'nameTranslated';
                this.folderModule = 'templateFolders';
                this.itemModules = ['templates'];
                this.itemType = this.$tc('templates.template', 1);
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.createEnabled = false;
                this.showModal = true;
                this.mediaType = null;
            },
            selectResource(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'name';
                this.folderModule = 'resourceFolders';
                if(this.$app.licenseLimitCheck('Clients', 'Clients', 'SignageClientCount', 1)) {
                    this.itemModules = ['images', 'videos', 'fonts'];
                } else {
                    this.itemModules = ['images', 'fonts'];
                }
                this.itemType = this.$tc('resources.resource', 1);
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.createEnabled = false;
                this.showModal = true;
            },
            selectImage(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'name';
                this.folderModule = 'resourceFolders';
                this.itemModules = ['images'];
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.itemType = this.$tc('resources.image', 1);
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = ResourceTypes.Image;
            },
            selectVideo(callback) {
                this.selectionDisabled = false;
                this.mainItemField = 'name';
                this.folderModule = 'resourceFolders';
                this.itemModules = ['video'];
                this.itemType = this.$tc('resources.video', 1);
                this.createEnabled = false;
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.showModal = true;
                this.mediaType = ResourceTypes.Video;
            },
            selectWayfindingIcon(callback) {
                this.selectionDisabled = false;
                this.multiSelectionDisabled = true;
                this.mainItemField = 'name';
                this.folderModule = 'wayfindingIconFolders';
                this.itemModules = ['wayfindingIcons'];
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.itemType = this.$tc('resources.wayfindingIcon', 2);
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = ResourceTypes.WayfindingIcon;
            },
            manageWayfindingIcons(callback) {
                this.selectionDisabled = true;
                this.mainItemField = 'name';
                this.folderModule = 'wayfindingIconFolders';
                this.itemModules = ['wayfindingIcons'];
                this.callbackFunction = callback;
                this.doubleClickToPreview = true;
                this.itemType = this.$tc('resources.wayfindingIcon', 2);
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = ResourceTypes.WayfindingIcon;
            },
            selectLogo(callback) {
                this.selectionDisabled = false;
                this.multiSelectionDisabled = true;
                this.mainItemField = 'name';
                this.folderModule = 'logoFolders';
                this.itemModules = ['logos'];
                this.callbackFunction = callback;
                this.doubleClickToPreview = false;
                this.itemType = this.$tc('resources.logo', 2);
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = ResourceTypes.Logo;
            },
            manageLogos(callback) {
                this.selectionDisabled = true;
                this.mainItemField = 'name';
                this.folderModule = 'logoFolders';
                this.itemModules = ['logos'];
                this.callbackFunction = callback;
                this.doubleClickToPreview = true;
                this.itemType = this.$tc('resources.logo', 2);
                this.createEnabled = true;
                this.showModal = true;
                this.mediaType = ResourceTypes.Logo;
            },
            selectItem(i) {
                if(i) {
                    if(!this.doubleClickToPreview){
                        this.showModal = false;
                    }
                    this.callbackFunction(i);
                }
            },
            create(folder) {
                if (this.$validator.isEmpty(this.mediaType)) {
                    this.$resourceUploader.show(folder, this.folderModule);
                } else {
                    this.$resourceUploader.showCustom(folder, this.folderModule, { mediaType: this.mediaType })
                }
            }
        }
    }
</script>

<style lang="scss">
    .folder-structure-selector {
        height: 80vh !important;
        min-width: 80% !important;
        max-width: 80% !important;
        margin: 0 !important;

        .folder-structure {
            height: 100% !important;
        }

        .v-card__text {
            padding: 0 !important;
            overflow-y: hidden !important;

            .folder-structure-grid {
                min-height: 100% !important;
            }
        }

        .base-toolbar {
            position: absolute;
            top: 56px !important;
        }
    }

    .mobile .folder-structure-selector {
        max-width: 100% !important;

        .v-card__title {
            display: none;
        }

        .title-heading {
            position: absolute;
            top: 0 !important;
            padding-top: 12px;
        }

        .item-grid {
            margin-top: 107px !important;
            height: calc(70vh - 101px) !important;
            padding: 0 !important;
            overflow-x: hidden;
        }

        .folder-structure-grid {
            padding: 0 0 0 5px !important;
            min-width: 100% !important;

            .folder-structure-item {
                margin-left: 10px !important;
                width: calc(50vw - 18px) !important;
            }
        }
    }

    .desktop .folder-structure-selector {
        .item-grid {
            padding: 0 6px 0 6px !important;
            margin-top: 58px;
        }

        .folder-tree {
            margin-top: 58px;
        }
    }
</style>
