<template>
    <v-text-field
        v-model="value"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        @click:append="showPassword = !showPassword"
        :type="showPassword ? 'text' : 'password'"
        :rules="(this.$validator.isEmpty(value) && edit === true) ? [rules.matches] : [rules.min, rules.numeric, rules.lower, rules.upper, rules.special, rules.matches]"
        :label="label"
        :hint="progress === 100 ? '✔' : hint"
        :disabled="disabled"
        loading
        @change="changeHandler"
        :data-testid="dataSelector"
    >
        <template v-slot:progress>
            <v-progress-linear
                :value="progress"
                :color="color"
                absolute
                height="2"
            ></v-progress-linear>
        </template>
    </v-text-field>
</template>

<script>
    export default {
        name: 'PasswordField',

        props: {
            label: {
                default: "Password",
                type: String
            },
            hint: {
                default: "",
                type: String
            },
            disabled: {
                default: false,
                type: Boolean
            },
            edit: {
                default: false,
                type: Boolean
            },
            match: {
                default: "",
                type: String
            },
            dataSelector: {
                default: null
            }
        },

        data() {
            return {
                showPassword: false,
                value: "",
                minLength: 8,
                rules: {
                    required: val => !!val || 'This field is required',
                    min: val => val.length >= this.minLength || 'Must contain at least 8 characters',
                    numeric: val => /\d/.test(val) || 'Must contain numeric characters',
                    lower: val => /[a-z]/.test(val) || 'Must contain lowercase characters',
                    upper: val => /[A-Z]/.test(val) || 'Must contain UPPERCASE characters',
                    special: val => /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val) || 'Must contain at least 1 special character',
                    matches: val => (this.$validator.isEmpty(this.match) || val === this.match) || 'Passwords does not match',
                },
            }
        },

        computed: {
            progress() {
                let rulesDone = 0;
                if (this.rules.min(this.value) === true) rulesDone++;
                if (this.rules.numeric(this.value) === true) rulesDone++;
                if (this.rules.lower(this.value) === true) rulesDone++;
                if (this.rules.upper(this.value) === true) rulesDone++;
                if (this.rules.special(this.value) === true) rulesDone++;
                return Math.min(100, ((rulesDone * 100)/5))
            },
            color() {
                return ['black', 'warning', 'success'][Math.floor(this.progress/40)]
            }
        },

        methods: {
            changeHandler(v) {
                this.$emit('input', v);
            },
            resetPassword() {
                this.value = "";
            }
        }

    }
</script>

<style lang="scss">

</style>