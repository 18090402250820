import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'contextAccess';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    byAccountType: (state, getters) => (type) => {
        const typeLower = type.toLowerCase();
        const items = module.cloneItems();

        return items.filter(item => {
            return item.account.accountType === typeLower;
        });
    }
});

export default module;
