<template>
    <BaseEditor
        v-model="showSelector"
        :title="$t('latLng.selectPosition')"
        :done-action-text="$t('generic.save')"
        custom-class="lat-long-selector"
        @done="done"
        :max-width="800"
        :eager="true"
    >
        <template v-slot:content>
            <v-row>
                <v-text-field
                    :label="$t('latLng.lat')"
                    v-model="lat"
                    type="number"
                    :rules="[v => !!v || $t('generic.fieldRequired')]"
                    @change="centerMap"
                ></v-text-field>
                <v-text-field
                    :label="$t('latLng.lat')"
                    v-model="lng"
                    type="number"
                    :rules="[v => !!v || $t('generic.fieldRequired')]"
                    @change="centerMap"
                ></v-text-field>
            </v-row>
            <v-row>
                <v-text-field
                    :label="$t('latLng.address')"
                    v-model="displayAddress"
                    maxLength="255"
                    counter="255"
                    :rules="[v => !!v || $t('generic.fieldRequired'), v => (!!v && v.length < 255) || $t('latLng.maxLength')]"
                    :hint="$t('latLng.addressHint')"
                    persistent-hint
                    clearable
                ></v-text-field>
            </v-row>
            <div style="z-index: 1; height: 400px; width: 100%;">
                <l-map
                    v-if="showSelector"
                    :center="{lat: parseFloat(center.lat), lng: parseFloat(center.lng)}"
                    style="z-index: 1; color: black;"
                    :zoom="3"
                    class="themaptarget"
                    @click="select"
                    @ready="checkMapRef"
                    ref="map"
                    :max-bounds="bounds"
                    :min-zoom="3"
                >
                    <l-control-layers position="topright"></l-control-layers>
                    <l-tile-layer
                        v-for="tileProvider in tileProviders"
                        :key="tileProvider.name"
                        :name="tileProvider.name"
                        :visible="tileProvider.visible"
                        :url="tileProvider.url"
                        :attribution="tileProvider.attribution"
                        layer-type="base"/>

                    <l-marker
                        :lat-lng="{lat: parseFloat(lat), lng: parseFloat(lng)}"
                        :draggable="true"
                        :icon="mapMarker"
                        @drag="markerDrag"
                        @dragend="markerDragend"
                    >
                        <l-tooltip
                            :content="displayAddress"
                        ></l-tooltip>
                    </l-marker>
                </l-map>
            </div>
        </template>
    </BaseEditor>
</template>

<script>
import Vue from 'vue';
import BaseModal from "../base/popup/BaseModal.vue";
import BaseEditor from "../base/popup/BaseEditor.vue";
import SearchField from "../input/SearchField.vue";
import VGeosearch from 'vue2-leaflet-geosearch';
import {GeoSearchControl, OpenStreetMapProvider} from 'leaflet-geosearch';
import {latLngBounds} from "leaflet";

export default {
    name: "TheMapLocationSelector",
    components: {SearchField, BaseEditor, BaseModal, VGeosearch, GeoSearchControl},
    data() {
        return {
            showSelector: false,
            lat: 50,
            lng: 11.5,
            center: {
                lat: 0,
                lng: 0
            },
            callbackFunction: null,
            mapMarker: L.icon({
                iconUrl: require('@/assets/icon/markers/marker_online_32.svg'),
                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            bounds: latLngBounds([
                [-90, -180],
                [90, 180]
            ]),
            tileProviders: [
                {
                    name: 'Street',
                    visible: true,
                    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                    name: "Satellite",
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                }
            ],
            displayAddress: null,
        }
    },
    created() {
        Vue.prototype.$mapLocationSelector = this;
    },
    methods: {
        checkMapRef() {
            const mapRef = this.$refs.map;
            if (mapRef) {
                mapRef.mapObject.on('geosearch/showlocation', response => {
                    this.lat = response.location.y;
                    this.lng = response.location.x;
                    this.updateAddress()
                })
                const myProvider = new OpenStreetMapProvider();
                new GeoSearchControl({
                    provider: myProvider,
                    style: 'bar',
                    showPopup: false,
                    autoClose: true,
                    showMarker: false,
                    searchLabel: this.$t('latLng.searchFieldLabel'),
                }).addTo(mapRef.mapObject);
            }

        },
        show(lat, lng, address, callback) {
            if (this.$validator.isEmpty(lat) || this.$validator.isEmpty(lng)) {
                lat = 59.911491;
                lng = 10.757933;
            }

            if (this.$validator.isNotEmpty(address)) {
                this.displayAddress = this.$clone(address);
            } else {
                this.displayAddress = ""
            }

            this.lat = this.$clone(lat);
            this.lng = this.$clone(lng);
            this.callbackFunction = callback;
            this.centerMap();
            this.showSelector = true;
        },
        centerMap() {
            this.center = {lat: this.lat, lng: this.lng};
        },
        done() {
            if (typeof this.callbackFunction === 'function') {
                this.callbackFunction(this.lat, this.lng, this.displayAddress);
            }

            this.showSelector = false;
        },
        select(e) {
            if (e.originalEvent.target.getAttribute('class').indexOf('themaptarget') === -1) {
                /* Horrible hack */
                return;
            }
            console.log("SET: " + e.latlng);
            this.lat = e.latlng.lat;
            this.lng = e.latlng.lng;
            this.centerMap();
            this.updateAddress();
        },
        markerDrag(e) {
            console.log("DRAG: " + e.latlng);
            this.lat = e.latlng.lat;
            this.lng = e.latlng.lng;
        },
        markerDragend() {
            this.updateAddress();
            this.centerMap();
        },
        async updateAddress() {
            try {
                const response = await fetch(
                    `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.lat}&lon=${this.lng}`
                );
                if (this.$responseCheck(response)) {
                    const body = await response.json();
                    //{address}, {postcode}, {city/town/municipality}, {country}
                    let formattedAddress = ""
                    if(body.hasOwnProperty('address')){

                        if(body.address.hasOwnProperty('road')){
                            if(body.address.hasOwnProperty('house_number')){
                                formattedAddress = body.address.road + " " + body.address.house_number + ", ";
                            }
                            else{
                                formattedAddress = body.address.road + ", ";
                            }
                        }

                        if(body.address.hasOwnProperty('postcode')){
                            formattedAddress += body.address.postcode + ", ";
                        }

                        if(body.address.hasOwnProperty('city')){
                            formattedAddress += body.address.city + ", ";
                        }
                        else if(body.address.hasOwnProperty('town')){
                            formattedAddress += body.address.town + ", ";
                        }
                        else if(body.address.hasOwnProperty('municipality')){
                            formattedAddress += body.address.municipality + ", ";
                        }

                        if(body.address.hasOwnProperty('country')){
                            formattedAddress += body.address.country;
                        }

                        this.displayAddress = formattedAddress;
                    }
                    else{
                        this.displayAddress = "";
                    }
                }
            } catch (e) {
                console.error("Reverse Geocode Error ->", e)
            }
        }
    }
}
</script>

<style lang="scss">

.mobile .lat-long-selector {
    .vue-map-container {
        min-height: 60vh;
    }

    .row {
        .v-input {
            width: calc(50% - 8px);
        }
    }
}
</style>