<template>
    <BaseMenu
        v-model="showSelector"
    >
        <template v-slot:content>
            <v-list data-testid="languageSelectorList">
                <v-list-item
                    v-for="lang in languages"
                    :key="lang.code"
                    @click="select(lang)"
                >
                    <v-list-item-action>
                        <v-avatar size="24">
                            <img :src="require(`@/assets/icon/language/${$app.getFlagCode(lang.code)}.png`)" :alt="lang.code">
                        </v-avatar>
                    </v-list-item-action>
                    <v-list-item-title>{{$tc('language.' + lang.name, 2)}}</v-list-item-title>
                </v-list-item>
            </v-list>
        </template>
    </BaseMenu>
</template>

<script>
    import Vue from 'vue';
    import BaseMenu from "../base/popup/BaseMenu.vue";

    export default {
        name: "TheLanguageSelector",
        components: {BaseMenu},
        data() {
            return {
                callbackFunction: null,
                languages: [],
                showSelector: false
            }
        },
        created() {
            Vue.prototype.$languageSelector = this;
        },
        methods: {
            show(callback, languages) {
                if (this.$validator.isEmpty(languages)) {
                    languages = this.$app.languages;
                }
                this.callbackFunction = callback;
                this.languages = languages;
                this.showSelector = true;
            },
            select(lang) {
                if (typeof this.callbackFunction === 'function') {
                    this.callbackFunction(lang);
                }
                this.showSelector = false;
            }
        }
    }
</script>