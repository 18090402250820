import UrlManager from "../../../lib/helpers/UrlManager";

const state = {
    currPreviewScreen: null
};

const actions = {
    openPreview({commit, state}, data) {
        commit('openPreviewInWindow', data);
    },
    reset({commit, state}) {
    }
};

const mutations = {
    openPreviewInWindow(state, { screen, type = 'tv', width = 1920, height = 1080 }) {
        state.currPreviewScreen = screen;
        const url = (type === 'signage' ? UrlManager.getSignageAppUrl() : UrlManager.getTvAppUrl()) +
            "?preview-token=" + screen.clientToken + "&preview-duid=" + screen.id +
            "&width=" + width + "&height=" + height;
        /* Let max height in landscape be 820, max height for portrait is desktop-height - 200 */
        let previewWidth, previewHeight = 820;
        if(width > height) {
            previewHeight = Math.min(820, height);
        } else {
            previewHeight = Math.min(window.outerHeight - 200, height);
        }
        if(type === 'tv') {
            width += 160; /* Remote control */
        }
        const factor = previewHeight / height;
        previewWidth = Math.floor(width * factor);

        window.open(url, screen.name, 'height=' + previewHeight + ',width=' + previewWidth).focus();
    }
};

const getters = {
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
