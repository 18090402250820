import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'registeredCasts';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    availableCastsSorted: (state, getters, rootState, rootGetters) => {

        const data = [];
        const registeredCastsSorted = rootGetters['registeredCasts/sorted'];

        if (Array.isArray(registeredCastsSorted) && registeredCastsSorted.length > 0) {
            registeredCastsSorted.forEach((item) => {
                if (item.pairedWith.length === 0) {
                    data.push(item)
                }
            });
        }

        return data;
    },
    sortedWithConnectivity: (state, getters, rootState, rootGetters) => {
        const data = [];
        const registeredCastsSorted = rootGetters['registeredCasts/sorted'];

        if (Array.isArray(registeredCastsSorted) && registeredCastsSorted.length > 0) {
            registeredCastsSorted.forEach((item) => {
                const md = _.get(item, 'chromecast.properties.md', '');
                const isPhilips = md.startsWith('TPM');
                const readOnlyModeIsSet = item.chromecast.readOnlyMode !== undefined && item.chromecast.readOnlyMode !== null;
                if (isPhilips) {
                    item.configurable = 'not-applicable'
                } else if (readOnlyModeIsSet) {
                    item.configurable = (item.chromecast.readOnlyMode ? 'not-configurable' : 'configurable');
                } else {
                    item.configurable = 'unknown';
                }
                item.connectivity = isPhilips ?
                    (item.chromecast.available ? 'casting' : 'idle') :
                    (item.chromecast.available ? 'online' : 'offline');
                data.push(item)
            });
        }

        return data;
    }

});

export default module;