<template>
    <v-btn
        fab
        fixed
        :elevation="$app.desktop ? 1 : 0"
        :color="color"
        @click="$emit('buttonClick')"
        class="floating-action-button"
        :class="position"
        :loading="loading"
    >
        <v-icon>{{icon}}</v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: 'BaseFloatingButton',
        props: {
            color: {
                default: 'primary',
                type: String
            },
            icon: {
                default: 'menu',
                type: String
            },
            position: {
                type: String,
                default: 'first'
            },
            loading: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss">
    .floating-action-button {
        right: 10px;
    }

    .floating-action-button.first {
        bottom: 55px;
    }

    .floating-action-button.second {
        bottom: 117px;
    }

    .floating-action-button.third {
        bottom: 178px;
    }
</style>