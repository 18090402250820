import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

function createRoute(path, view, nameKey, { requiredAccess = [], group = null, icon = null, device = null, modes = [], routeId = null, additionalAccessToRoutesIds = null, requiredWhitelabel = null}) {
    return {
        name: nameKey || view,
        path: path,
        meta: {
            group: group,
            device: device,
            requiredAccess: requiredAccess,
            modes: modes,
            icon: icon,
            routeId: routeId,
            additionalAccessToRoutesIds: additionalAccessToRoutesIds,
            requiredWhitelabel: requiredWhitelabel
        },
        component: (resolve) => import(`@/views/${view}.vue`).then(resolve)
    }
}

Vue.use(Router);

const preparedRoutes = [];

routes.forEach((route) => {
    if(route.hasOwnProperty('views')) {
        route.views.forEach((view) => {
            preparedRoutes.push(createRoute(route.path + view.path, view.view, view.nameKey, {
                requiredAccess: view.requiredAccess,
                group: route,
                device: view.device,
                modes: view.modes,
                routeId: view.routeId,
                additionalAccessToRoutesIds: view.additionalAccessToRoutesIds,
                requiredWhitelabel: view.requiredWhitelabel
            }));
        });
    } else {
        preparedRoutes.push(createRoute(route.path, route.view, route.nameKey, { requiredAccess: route.requiredAccess, icon: route.icon, device: route.device, modes: route.modes, requiredWhiteabel: route.requiredWhitelabel }));
    }
});

const router = new Router({
    mode: 'history',
    routes: preparedRoutes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }

        return {top: 0, left: 0}
    }
});

export default router;
