import Validator from '@/lib/helpers/Validator';
import lodash from "lodash";
import PortalTheme from "@/lib/theme/PortalTheme.js";

const BaseCloudHostUrl = process.env.VUE_APP_API_BASE_URL;
const BaseCloudHostUrlProd = "https://api.thecloudportal.com";
const WebRadioServiceBaseUrl = BaseCloudHostUrlProd;

const camelCaseToKebabCase = function (camelCaseString) {
    return lodash.kebabCase(camelCaseString)
};

class UrlManager {

    static buildApiV1Url(feature, resource, subPath) {

        if (Validator.isEmpty(feature)) {
            throw "Illegal argument: featureName is empty"
        }

        if (Validator.isEmpty(resource)) {
            throw "Illegal argument: resource is empty"
        }

        let subPathString = "";
        if (Validator.isNotEmpty(subPath)) {
            subPathString = `/${subPath}`
        }

        const featureKebab = camelCaseToKebabCase(feature);
        const resourceKebab = camelCaseToKebabCase(resource);
        return `${BaseCloudHostUrl}/api/1/feature/${featureKebab}/${resourceKebab}${subPathString}`;
    }

    static buildUrl(service) {

        if (Validator.isEmpty(service)) {
            service = 'license';
        }

        if (service === 'webRadio') {
            return `${WebRadioServiceBaseUrl}/api/1/${service}`;
        }

        return `${BaseCloudHostUrl}/api/1/${service}`;
    }

    static getCurrentRoute() {
        let currentRoute = '';

        try {
            const href = window.location.href;
            const split = href.split('/');

            currentRoute = `/${split[split.length - 2]}/${split[split.length - 1]}`;
            currentRoute = currentRoute.split('?')[0];

            return currentRoute;
        } catch (err) {
            // Do nothing
        }

        return currentRoute;
    }

    static getLegacyImagesUrl() {
        return process.env.VUE_APP_LEGACY_IMAGES_URL;
    }

    static getSupportPortalUrl() {
        switch (PortalTheme.getTheme()) {
            case PortalTheme.availableThemes.THEME_PPDS:
                return process.env.VUE_APP_SUPPORT_URL_PPDS;
            case PortalTheme.availableThemes.THEME_UNIGUEST:
                return process.env.VUE_APP_SUPPORT_URL_UNIGUEST;
        }
        return process.env.VUE_APP_SUPPORT_URL;
    }

    static getMenuEditorUrl() {
        return process.env.VUE_APP_MENU_EDITOR_URL;
    }

    static getMenuSimpleEditorUrl() {
        return process.env.VUE_APP_MENU_SIMPLE_EDITOR_URL;
    }

    static getTvAppUrl() {
        return process.env.VUE_APP_TV_APP_URL;
    }

    static getSignageAppUrl() {
        return process.env.VUE_APP_SIGNAGE_APP_URL;
    }

    static getPushServiceHost() {
        return process.env.VUE_APP_API_PUSH_HOST;
    }

}

export default UrlManager;
