import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'hospitalityLocations';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    withLocation: (state, getters, rootState, rootGetters) => {

        const data = []
        if (Array.isArray(state.items) && state.items.length > 0) {

            const locationsIdMap = rootGetters['locations/idMapByGetter']('withFullTitleTagsAndParents');
            state.items.forEach((item) => {

                const itemLocation = Map.retrieve(locationsIdMap, item.id) || {};
                let parentLocation = "None"; // TODO: Translate
                if (Array.isArray(itemLocation.parentLocations) && itemLocation.parentLocations.length > 0) {
                    parentLocation = itemLocation.parentLocations[0];
                }

                const extendedItem = {
                    ...item,
                    occupation: item.occupied === true ? "Occupied" : "Vacant", // TODO: Translated versions!
                    titleTranslated: itemLocation.titleTranslated || "",
                    categoryTitle: itemLocation.categoryTitle || "",
                    fullTitle: itemLocation.fullTitle || "",
                    parentLocations: itemLocation.parentLocations,
                    parentLocation: parentLocation
                }

                data.push(extendedItem);
            });
        }

        return data;
    },

    withLocationAndGuestInfo: (state, getters, rootState, rootGetters) => {

        const data = getters['withLocation'];
        if (Array.isArray(data) && data.length > 0) {

            const guestsLocationIdMap = rootGetters['guests/idMapByGetter'](null, 'locationId', true);
            data.forEach((item) => {

                let guestNames = [];
                const guests = Map.retrieve(guestsLocationIdMap, item.id);
                if (Array.isArray(guests) && guests.length > 0) {
                    guests.forEach(g => {
                        guestNames.push(g.name);
                    });
                }

                item.guestNames = guestNames;
            });

        }

        return data;
    }
});

export default module;
