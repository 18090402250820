<template>
    <v-dialog
        :eager="eager"
        v-model="show"
        :width="width"
        :scrollable="!tabs"
        :max-width="maxWidth"
        :content-class="`${tabs ? 'tabs' : ''} ${customClass} base-dialog ${list ? 'list' : ''} ${hideAllActions ? 'hide-all-actions' : ''}`"
        :persistent="persistent"
        :retain-focus="retainFocus"
        @click:outside="clickOutsideFunction"
    >
        <v-card>
            <v-card-title :style="'margin-bottom: 10px; ' + ($app.darkMode ? '' : 'background: #f1f1f1;')">
                <v-toolbar-title>{{typeof title === 'string' ? title : ''}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="topbaractions"></slot>
                <v-btn
                    icon
                    @click.stop="show = false"
                    v-if="!hideClose"
                    class="base-dialog-btn-close"
                    data-testid="bdClose"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text
                style="overflow-x: hidden;"
            >
                <slot name="content"></slot>
            </v-card-text>
            <v-card-actions v-if="!hideAllActions">
                <slot
                    name="left_aligned_preactions"
                    v-if="customActions"
                ></slot>
                <v-spacer></v-spacer>
                <slot
                    name="preactions"
                    v-if="customActions"
                ></slot>
                <v-btn
                    text
                    :elevation="$app.desktop ? 1 : 0"
                    @click.stop="show = false"
                    v-show="!hideCancelAction"
                    class="base-dialog-btn-cancel"
                    data-testid="bdCancel"
                    :loading="processing"
                    outlined
                >
                    {{ cancelText !== '' ? cancelText : $t('generic.cancel')}}
                </v-btn>
                <slot
                    name="actions"
                    v-if="customActions"
                ></slot>
                <v-btn
                    v-else
                    color="primary"
                    :elevation="$app.desktop ? 1 : 0"
                    @click.stop="$emit('done')"
                    :disabled="doneActionDisabled"
                    :loading="processing"
                    class="base-dialog-btn-approve"
                    data-testid="bdApprove"
                >
                    {{doneActionText}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import PopupHistoryMixin from "@/mixins/popupHistoryMixin.js";

    export default {
        name: 'BaseDialog',
        mixins: [VModelShowMixin, PopupHistoryMixin],
        props: {
            customActions: {default: false, type: Boolean},
            doneActionText: {required: false, type: String},
            doneActionDisabled: {type: Boolean, default: false},
            title: {required: true},
            maxWidth: {default: 450, type: [ Number, String ]},
            width: {default: "", type: [ String, Number ]},
            customClass: {default: '', type: String},
            cancelText: {default: '', type: String},
            persistent: {default: false, type: Boolean},
            hideCancelAction: {default: false, type: Boolean},
            hideAllActions: {default: false, type: Boolean},
            hideClose: {default: false, type: Boolean},
            tabs: {default: false, type: Boolean},
            list: {default: false, type: Boolean},
            eager: {default: false, type: Boolean},
            retainFocus: {default: true, type: Boolean},
            processing: {default: false, type: Boolean},
            clickOutsideFunction: {default: function(){}, type: Function}
        }
    }
</script>

<style lang="scss">
    .base-dialog {
        overflow-y: visible;
        overflow-x: visible;
        min-width: 350px;

        .v-card__title {
            height: 60px;
            padding-top: 8px;
            //padding: 0 24px 0 28px;

            .v-toolbar__title {
                max-width: calc(100% - 48px);
            }
        }

        .v-card__actions {
            padding: 16px 22px 18px 22px;
        }

        .v-card__text {
            padding: 12px 36px 12px 36px;
        }

        .v-input {
            margin-bottom: 15px;
        }

        form {
            padding-top: 10px;
        }
    }

    .base-dialog.tabs {
        overflow-y: hidden;

        .v-card__text {
            padding: 0;
        }

        .v-tabs-bar__content {
            margin-left: 24px;
            margin-right: 24px;
            border-bottom: 2px solid rgb(241, 241, 241);
        }

        .v-tabs-bar {
            margin-bottom: 15px;
        }

        form {
            padding: 12px 36px 0 36px;
        }

        .vue-recycle-scroller {
            .v-subheader {
                padding-left: 36px !important;
            }
        }

        .v-window {
            padding-top: 10px;
            overflow-y: auto;
            max-height: calc(90vh - 60px - 76px - 48px - 10px);
        }
    }

    .base-dialog.list {
        .v-card__text {
            padding: 8px 0 0 0;
        }

        .v-list-item {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .base-dialog.list.hide-all-actions {
        .v-card__text {
            padding: 8px 0 30px 0;
        }
    }
</style>