import vue from 'vue';
import vuex from 'vuex';
import modules from './modules';
import getters from './getters';

const getterObject = {};

getters.forEach(map => {
    for (let key in map) {
        // noinspection JSUnfilteredForInLoop
        if (getterObject[key] !== undefined) {
            console.log('Duplicate vuex getter name: ' + key);
        } else {
            // noinspection JSUnfilteredForInLoop
            getterObject[key] = map[key];
        }
    }
});

vue.use(vuex);

export default function (apis) {
    return new vuex.Store({
        modules: modules(apis),
        getters: getterObject
    })
};
