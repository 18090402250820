import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from 'vue';

const thisModule = 'templates';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    translated: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const templateSourceIdMap = rootGetters['templateSources/idMap'];

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.templateSourceName = Map.retrieveValue(templateSourceIdMap,
                        item.templateSourceId, 'name', '');
                item.nameTranslated = Vue.prototype.$tm(item.name);
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    }
});

export default module;