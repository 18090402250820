import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import moment from 'moment';

const thisModule = 'guestMessages';

const module = new BaseCrudModule(apis[thisModule], '');


module.addGetters({
    processedWithLocation: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const accountsIdMap = rootGetters['roomAccounts/idMapByGetter']('withLocation');

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(accountsIdMap, item.receiverRoomAccountId, 'locationTitle');
                item.createdDateFormatted = item.createdDate ? moment(item.createdDate).format('MMMM Do YYYY HH:mm') : '';
                item.displayedDateFormatted = item.displayedDate ? moment(item.displayedDate).format('MMMM Do YYYY HH:mm') : '';
                item.readDateFormatted = item.readDate ? moment(item.readDate).format('MMMM Do YYYY HH:mm') : 'Not read';
            });

            //Array.sort(data, 'createdDate', false);
            return data;
        }
        return [];
    },
    unread: () => {
        return module.cloneItems().filter(message => message.readDate === null);
    }
});

export default module;