import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import moment from 'moment';

const thisModule = 'hospitalityGuests';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withLocationTitle: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const locationsIdMap = rootGetters['hospitalityLocations/idMapByGetter']('withLocationAndGuestInfo');

        if (Array.isArray(data)) {

            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(locationsIdMap, item.locationId, 'fullTitle');

                const langs = rootGetters['languageInfo/languages'];
                const lang = Array.findFirstByField(langs, 'code', item.language);
                if(lang && (typeof lang.name === 'string')) {
                    item.languageName = lang.name.capitalize();
                } else if(typeof item.language === 'string') {
                    item.languageName = item.language.capitalize();
                } else {
                    item.languageName = "";
                }

                item.checkInDateFormatted = moment.toDisplayFormatDateOnly(item.checkInDate);
                item.checkOutDateFormatted = moment.toDisplayFormatDateOnly(item.checkOutDate);

            });
            Array.sort(data, 'locationTitle', true);
            return data;
        }
        return [];
    },
    uniqueLocations: (state, getters) => {
        const map = getters.idMapByGetter('withLocationTitle', 'locationId', null);
        let data = [];
        for(const entry in map) {
            if(map.hasOwnProperty(entry)) {
                data.push({ id: entry, locationTitle: map[entry].locationTitle });
            }
        }
        return data;
    },
    uniqueLocationCount: (state, getters) => {
        const map = getters.idMapByGetter(null, 'locationId', null);
        let count = 0;
        for(const entry in map) {
            if(map.hasOwnProperty(entry)) {
                count++;
            }
        }
        return count;
    }
});

export default module;
