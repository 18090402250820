<template>
    <BaseDialog
        v-model="open"
        :max-width="500"
        :title="title"
        :hide-close="true"
        :custom-actions="true"
        :hide-cancel-action="true"
    >
        <template v-slot:content>
            <div v-html="text"></div>
            <div class="copy-text" v-html="copyText" data-testid="tcbcTextfield"></div>
        </template>
        <template v-slot:actions>
            <div>
                <v-btn
                        :color="confirmColor"
                        @click.stop="copy"
                        :elevation="$app.desktop ? 1 : 0"
                        text
                        data-testid="tcbcCopy"
                >
                    {{$t('generic.copyToClipboard')}}
                </v-btn>
                <v-btn
                        :color="confirmColor"
                        :elevation="$app.desktop ? 1 : 0"
                        @click.stop="confirm"
                        data-testid="tcbcClose"
                >
                    {{$t('generic.close')}}
                </v-btn>
            </div>
        </template>
    </BaseDialog>
</template>

<script>
    import Vue from 'vue';
    import BaseDialog from '@/components/base/popup/BaseDialog.vue';

    export default {
        name: 'TheClipBoardCopier',
        components: {
            BaseDialog
        },
        data() {
            return {
                title: '',
                text: '',
                copyText: '',
                confirmFunction: null,
                confirmColor: 'error',
                open: false
            }
        },
        created() {
            Vue.prototype.$clipboardCopy = this;
        },
        methods: {
            confirm() {
                this.open = false;
                this.confirmFunction();
            },
            copy() {
                this.$app.copyToClipboard(this.copyText)
            },
            show(title, text, copyText, confirmFunction = null) {
                this.open = true;
                this.title = title;
                this.text = text;
                this.copyText = copyText;
                this.confirmColor = 'primary';
                this.confirmFunction = confirmFunction;
            }
        }
    }
</script>

<style lang="scss">
    .copy-text {
        padding: 4px;
        border: 1px solid black;
    }
</style>