<template></template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
    name: "TheJobHandler",
    computed: {
        ...mapGetters({
            jobs: 'jobs/sorted'
        }),
        ...mapState({
            sessionId: state => state.auth.sessionId
        }),
    },
    watch: {
        jobs: function(newVals, oldVals) {
            const me = this;

            newVals.forEach(function (newVal) {
                const oldVal = oldVals.find(it => it.id === newVal.id);

                if (oldVal && newVal && !oldVal.completed && newVal.completed) {
                    if (me.sessionId !== newVal.sessionId) {
                        return;
                    }

                    if (newVal.state === 'Success') {
                        me.$notification.show('info', me.$t(newVal.successMsg, { title: newVal.title }));
                    } else {
                        me.$notification.show('error', me.$t(newVal.failMsg, { title: newVal.title }));
                    }
                }
            });
        }
    }
}
</script>

<style scoped></style>