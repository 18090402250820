import Vue from "vue";

const moduleMapper = {
    'Domain': "domains",
    'System': 'systems',
    'Sharing': "sharingContexts",
    'Content Provider': 'contentProviderContexts',
    'Distributor': 'distributors',
    'Reseller': 'resellers',
    'Deployment': 'deployments',
    'Theme': 'themes',
    'Legacy': 'legacies'
};

export default {
    processing: state => (modules) => {
        let processing = false;

        modules.forEach(module => {
            if (state[module].processing === true || state[module].fetching === true) {
                processing = true;
            }
        });

        return processing;
    },
    allContexts: (state, getters, rootState, rootGetters) => (omitLegacyContexts= false) => {
        const contextTypes = rootGetters['contextTypes/namesIncludingSystem']
        let items = [];
        contextTypes.forEach(ctxType => {

            if (omitLegacyContexts !== true || ctxType !== "Legacy") {

                if (!moduleMapper.hasOwnProperty(ctxType)) {
                    console.error("Could not map context-type: " + ctxType);
                } else {
                    if(moduleMapper[ctxType] !== null) {
                        const ctxModuleName = moduleMapper[ctxType];
                        const contextTypeModule = rootState[ctxModuleName];
                        const contextTypeItems = contextTypeModule.items;
                        const newItems = Vue.prototype.$clone(contextTypeItems);
                        items = items.concat(newItems);
                    }
                }

            }

        });
        if(Array.isArray(items)) { Array.sort(items, 'name'); }
        return items;
    },
    allContextsIdMap: (state, getters, rootState, rootGetters) => {
        return Map.createFromArray(rootGetters['allContexts'](false), 'id');
    },
    administrativeContexts: (state, getters, rootState, rootGetters) => {
        const administrativeContextTypes = rootGetters['contextTypes/namesOfAdministrativeTypes']
        let items = [];
        administrativeContextTypes.forEach(ctxType => {
            if(!moduleMapper.hasOwnProperty(ctxType)) {
                console.error("Could not map context-type: " + ctxType);
            } else {
                if(moduleMapper[ctxType] !== null) {
                    items = items.concat(Vue.prototype.$clone(rootState[moduleMapper[ctxType]].items));
                }
            }
        });
        if(Array.isArray(items)) { Array.sort(items, 'name'); }
        return items;
    }
}
