import {Storage} from "@/lib/helpers";

class BaseListHeaderModule {
    constructor() {
        this.state = {
            /*
        Each entry MUST contain:
        text: can be used for display directly, or user can use to as translatable key
        value: the value key
        initialEnabled: the initial state of the header(enabled/disabled) if never set
        OPTIONAL:
        convertToString: For exports, can be used to convert objects to strings
         */
            listHeaders: {},
            listId: null
        };

        this.namespaced = true;

        this.getters = {};

        this.actions = {
            setListHeaderEnabled({commit, state}, keyVal) {
                commit('setListHeaderEnabled', keyVal);
            },
            reset() {}
        };

        this.mutations = {
            setListHeaderEnabled(state, {key, enabled}) {
                Storage.set('listHeader.' + state.listId + '.' + key, enabled);
                state.listHeaders[key].enabled = enabled;
            }
        };
    }

    setListHeaders(headers) {
        if(this.state.listId === null) {
            throw ("List ID not set when trying to set list headers!");
        }

        for(const key in headers) {
            headers[key].enabled = Storage.getBoolean('listHeader.' + this.state.listId + '.' + key, headers[key].initialEnabled)
        }
        this.state.listHeaders = headers;
    }

    setListId(id) {
        this.state.listId = id;
    }

}

export default BaseListHeaderModule;