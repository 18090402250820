import Validator from './Validator';

class ApiHelper {

    static responseSuccessCheck(response) {
        if (Array.isArray(response)) {
            let success = true;

            response.forEach((res) => {
                if (Validator.isNotEmpty(res) && res.status >= 400) {
                    success = false;
                }
            });

            return success;
        } else {
            return Validator.isNotEmpty(response) && response.status < 400;
        }
    }
}

export default ApiHelper;