import English from './en/translations';
import Norwegian from './no/translations';
import French from './fr/translations';
import German from  './de/translations.js';
import Spanish from './es/translations';
import Estonian from './et/translations';
import Japanese from './ja/translations';

const translations = {
  en: English,
  no: Norwegian,
  fr: French,
  de: German,
  es: Spanish,
  et: Estonian,
  ja: Japanese
};

export default translations;
  