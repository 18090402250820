<template>
    <v-menu
            v-model="menu"
        :offset-y="true"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{on}">
            <v-badge
                :value="jobsToDisplay > 0"
                bottom
                overlap
                :color="failedJobs > 0 ? 'error' : 'grey'"
                offset-x="25"
                offset-y="25"
            >
                <template v-slot:badge>
                    <span>{{ jobsToDisplay }}</span>
                </template>
                <v-btn v-on="on" icon>
                    <v-icon color="dark grey">mdi-bell</v-icon>
                </v-btn>
            </v-badge>

        </template>

        <template v-if="jobsToDisplay > 0">
            <v-list class="job-list">
                <v-subheader class="jobs-header" v-if="!$vuetify.breakpoint.mdAndDown">
                    {{ $t('jobs.jobList.title') }}
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon color="dark grey" @click="menu = false">close</v-icon>
                    </v-btn>
                </v-subheader>
                <template
                        v-for="(item) in jobs"
                >
                    <v-list-item v-if="!item.deleted" :class="getItemBodyClass(item)">
                        <div v-if="item.state === 'Running'">
                            <v-progress-circular
                                    indeterminate
                                    size="15"
                                    width="2"
                                    color="primary"
                                    class="job-icon"
                            ></v-progress-circular>
                        </div>
                        <div
                                v-else
                        >
                            <v-icon v-if="item.state === 'Success'" class="job-icon">info</v-icon>
                            <v-icon v-else class="job-icon">warning</v-icon>
                        </div>

                        <b :style="item.state === 'Running' ? 'color:black;' : 'color:white;'">{{ generateText(item) }}</b>

                        <v-spacer></v-spacer>
                        <v-btn @click="deleteJob(item)" icon>
                            <v-icon class="job-icon-delete">close</v-icon>
                        </v-btn>
                    </v-list-item>

                </template>
            </v-list>
        </template>


    </v-menu>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "JobList",
    data() {
        return {
            menu: false
        }
    },
    computed: {
        ...mapGetters({
            jobs: 'jobs/sorted'
        }),
        failedJobs() {
            return this.jobs.filter(job => {
                return (!job.deleted && job.state === "Failed");
            }).length;
        },
        jobsToDisplay() {
            return this.jobs.filter(job => {
                return !job.deleted
            }).length;
        }
    },
    methods: {
        async deleteJob(item) {
            await this.$apis.jobs.post(item, '/mark-deleted/' + item.id);
        },
        getItemBodyClass(item) {
            switch (item.state) {
                case 'Running':
                    return 'job running';
                case 'Success':
                    return 'job success';
                case 'Failed':
                    return 'job failed';
            }
        },
        generateText(item) {
            switch (item.state) {
                case 'Running':
                    return this.$t(item.startMsg, {title: item.title});
                case 'Success':
                    return this.$t(item.successMsg, { title: item.title });
                case 'Failed':
                    return this.$t(item.failMsg, { title: item.title });
            }
        },
    }
}
</script>

<style lang="scss">
.jobs-header {
    font-size: 18px;
}
.job-list {
    padding: 5px;
}
.job-icon {
    margin-right: 10px;
    color: #000000 !important;
    opacity: 0.5;
}
.job-icon-delete {
    color: white !important;
}
.job {
    font-size: 14px;
    opacity: 0.9;
    padding: 10px 14px 10px 14px !important;
    margin-top: 5px;
    justify-content: normal !important;
    overflow: hidden;
    white-space: nowrap;
    border-radius:6px;
}
.job.running {
    background-color: #EEEEEE;
}
.job.success {
    background-color: #7ea450;
}
.job.failed {
    background-color: #be3333;
}

</style>