<template>
    <div
            class="folder-structure"
            @click="bodyClick"
    >
        <FolderStructureToolbar
                @search="searchChange"
                @refresh="refresh"
                :folder="folder"
                ref="toolbar"
                :add-button-text="createEnabled ? addButtonText : ''"
                :help-path="helpPath"
                @uploadItem="uploadItem"
                @back="back"
                :disable-add-button="isSharedFolder(folder) || this.itemReadOnly"
        >
            <template v-slot:primaryButtons>
                <slot name="primaryButtons"></slot>
            </template>
        </FolderStructureToolbar>

        <v-row>
            <v-col class="folder-tree elevation-1" v-if="$app.desktop">

                <v-spacer style="height: 16px;"></v-spacer>
                <v-btn
                        color="primary"
                        :elevation="0"
                        style="width: 100%;"
                        text
                        @click="createFolder(folder)"
                        data-testid="fsNewFolder"
                        :disabled="isSharedFolder(folder) || this.folderReadOnly"
                >
                    <v-icon>create_new_folder</v-icon>
                    &nbsp;{{ $t('folder.newFolder') }}
                </v-btn>
                <v-spacer style="height: 16px;"></v-spacer>

                <v-select
                        v-if="itemModulesAndLabels.length > 1"
                        :items="itemModulesAndLabels"
                        v-model="activeModules"
                        :label="$t('generic.filter')"
                        menu-props="offsetY"
                        multiple
                        hide-details
                        outlined
                        item-text="label"
                        item-value="module"
                >
                </v-select>
                <v-spacer v-if="itemModules.length > 1" style="height: 16px;"></v-spacer>

                <FolderStructureSharedContentFolders
                        v-if="hasSharedResources"
                        :folder-id="folder"
                        :root-folder-label="rootLabel"
                        :folder-module="folderModule"
                        @push-folder-route="pushFolderRoute"
                        :item-modules="activeModules"
                >
                </FolderStructureSharedContentFolders>
                <v-list-item
                        style="cursor: pointer;"
                        class="root-item"
                        @click.stop="pushFolderRoute(null)"
                        @click.right.stop="folderTreeRightClick(null)"
                        @contextmenu.prevent=""
                >
                    <v-icon :color="(folder == null) ? 'accent' : ''">folder</v-icon>
                    <span>{{ rootLabel }}</span>
                </v-list-item>
                <v-treeview
                        dense
                        :items="folderTree"
                        item-text="nameTranslated"
                        item-key="id"
                        rounded
                        :open="openFolders"
                        v-if="folderTree.length > 0"
                        @update:open="openFoldersChange"
                >
                    <template v-slot:label="{item}">
                        <v-list-item
                                dense
                                style="cursor: pointer;"
                                @click.stop="pushFolderRoute(item.id)"
                                @click.right.stop="folderTreeRightClick(item)"
                                @contextmenu.prevent=""

                        >
                            <v-icon :color="item.id === folder ? 'accent' : ''">folder</v-icon>
                            <span>&nbsp;{{ item.nameTranslated }}</span>
                        </v-list-item>
                    </template>
                </v-treeview>
            </v-col>
            <v-col class="item-grid">
                <v-container
                        v-if="!sharedFolderIds.includes(selectedFolderId)"
                        grid-list-md
                        fluid
                        @contextmenu.prevent=""
                        @click.right="bodyRightClick"
                        class="pa-2 folder-structure-grid"
                >
                    <v-layout wrap>
                        <div class="empty-text" v-if="items.length === 0">
                            {{ folder === null ? $tc('generic.madeYet', 2, {type: itemType}) : $t('folder.emptyFolder') }}
                        </div>
                        <template v-else v-for="(item, index) in items">
                            <FolderStructureItem
                                    v-if="!item.shared"
                                    :item="item"
                                    :index="index"
                                    :folder-module="folderModule"
                                    :selected="selected"
                                    :key="item.rev"
                                    @itemDoubleClick="itemDoubleClick"
                                    @itemClick="itemClick"
                                    @itemRightClick="itemRightClick"
                            >
                                <template v-slot:item="{item}">
                                    <slot name="item" :item="item"></slot>
                                </template>
                            </FolderStructureItem>
                        </template>
                    </v-layout>
                </v-container>
                <v-container
                        v-else
                        grid-list-md
                        fluid
                        @contextmenu.prevent=""
                        class="pa-2 folder-structure-grid"
                >
                    <v-layout wrap>
                        <div class="empty-text" v-if="items.length === 0">
                            {{ $t('folder.emptyFolder') }}
                        </div>
                        <template v-else v-for="(item, index) in items">
                            <FolderStructureItem
                                    v-if="item.shared"
                                    :item="item"
                                    :index="index"
                                    :folder-module="folderModule"
                                    :selected="selected"
                                    :key="item.rev"
                                    @itemDoubleClick="itemDoubleClick"
                                    @itemClick="itemClick"
                                    @itemRightClick="providedItemRightClick"
                            >
                                <template v-slot:item="{item}">
                                    <slot name="item" :item="item"></slot>
                                </template>
                            </FolderStructureItem>
                        </template>
                    </v-layout>
                </v-container>
            </v-col>
        </v-row>
        <FolderStructureGridActions
                v-model="gridActions.show"
                :item-in-clipboard="copied !== null"
                :folder-module="folderModule"
                :folder="gridActions.item"
                :item-type="itemType"
                :create-enabled="createEnabled"
                @paste="paste"
                @createFolder="createFolder(selectedFolderId)"
                @uploadItem="uploadItem"
                @create="createItem"
                @editFolder="doEditFolder"
                @moveFolder="moveFolder"
                @deleteFolder="deleteFolder"
        >
            <template v-slot:gridActions>

                <slot name="gridActions" :folder="gridActions.item === null ? folder : gridActions.item.id"></slot>
            </template>
        </FolderStructureGridActions>

        <FolderStructureItemActions
                v-model="itemActions.show"
                :item="itemActions.item"
                :selected="selected"
                :item-type="itemType"
                :main-item-field="mainItemField"
                @copy="copy"
        >
            <template v-slot:itemActions="{item}">
                <v-menu
                        offset-x
                        open-on-hover
                >
                    <template v-slot:activator="{on}">
                        <v-list-item v-on="on" @click.stop>
                            <v-list-item-action>
                                <v-icon color="primary">mdi-folder</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{ $t('folder.moveToFolder') }}</v-list-item-title>
                            <v-icon>chevron_right</v-icon>
                        </v-list-item>
                    </template>
                    <v-list>
                        <BaseActionItem
                                v-for="folderItem in currentFolders"
                                :key="folderItem.id"
                                :title="folderItem.id === null ? rootLabel : folderItem.nameTranslated"
                                icon="mdi-folder-download-outline"
                                color="primary"
                                :item="folderItem"
                                @itemClick="moveResources(folderItem)"
                        ></BaseActionItem>
                    </v-list>
                </v-menu>
                <slot name="itemActions" :item="item" :selected="selected"></slot>
                <BaseActionItem
                        v-if="replaceableResource(item)"
                        :title="$tc('resources.replaceResource', 1, {resource: getResourceTypeByModuleName(item.moduleName)})"
                        icon="mdi-file-replace-outline"
                        color="primary"
                        :item="item"
                        @itemClick="replaceResource"
                ></BaseActionItem>
                <input
                        v-show="false"
                        name="file"
                        type="file"
                        ref="fileInput"
                        accept="image/*"
                        @change="fileSelected($event, item)"
                >
                <v-list-item v-if="downloadEnabled" @click.stop="doDownload()">
                    <v-list-item-action>
                        <v-icon color="primary">mdi-download</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('resources.downloadFile', Object.keys(selected).length) }}
                    </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item && isSingleItem && item.moduleName !== 'templates'"
                             @click.stop="doEditItem">
                    <v-list-item-action>
                        <v-icon color="primary">mdi-pencil</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ $tc('generic.editItem', Object.keys(selected).length) }}</v-list-item-title>
                </v-list-item>
            </template>
        </FolderStructureItemActions>


        <BaseActionMenu
            v-model="providedItemActions.show"
            :title="'Provided item' + (Object.keys(selected).length > 1 ? 's' : '')"
            :persistent="false"
        >
            <template v-slot:actions>
                <v-menu
                    offset-x
                    open-on-hover
                >
                    <template v-slot:activator="{on}">
                        <v-list-item v-on="on" @click.stop>
                            <v-list-item-action>
                                <v-icon color="primary">mdi-folder</v-icon>
                            </v-list-item-action>
                            <v-list-item-title>{{ $t('folder.copyToFolder') }}</v-list-item-title>
                            <v-icon>chevron_right</v-icon>
                        </v-list-item>
                    </template>
                    <v-list>
                        <BaseActionItem
                            v-for="folderItem in currentFolders"
                            :key="folderItem.id"
                            :title="folderItem.id === null ? rootLabel : folderItem.nameTranslated"
                            icon="mdi-folder-download-outline"
                            color="primary"
                            :item="folderItem"
                            @itemClick="copyResourcesTo(folderItem)"
                        ></BaseActionItem>
                    </v-list>
                </v-menu>
            </template>
        </BaseActionMenu>

        <v-btn-toggle
                v-if="!$app.desktop && itemModules.length > 1"
                v-model="activeModules"
                multiple
                class="mobileToggle"
        >
            <v-btn
                    v-for="(item, idx) in itemModules"
                    :key="item"
                    :value="item"
            >
                {{ itemLabels[idx] }}
            </v-btn>
        </v-btn-toggle>

        <BaseFloatingButton
                icon="add"
                color="primary"
                position="second"
                @buttonClick="createItem(folder)"
                v-if="!$app.desktop && createEnabled"
        ></BaseFloatingButton>

        <BaseProcessingIndicator
                v-if="!selectMode"
                :processing="$store.getters.processing([folderModule, ...itemModules])"
        ></BaseProcessingIndicator>
    </div>
</template>

<script>
import BaseProcessingIndicator from "../../components/base/BaseProcessingIndicator.vue";
import FolderStructureToolbar from "./FolderStructureToolbar.vue";
import FolderStructureItemActions from "./FolderStructureItemActions.vue";
import FolderStructureGridActions from "./FolderStructureGridActions.vue";
import FolderStructureItem from "./FolderStructureItem.vue";
import BaseFloatingButton from "../base/BaseFloatingButton.vue";
import BaseActionItem from "@/components/base/action/BaseActionItem.vue";
import fileDownloaderMixin from "@/mixins/fileDownloaderMixin.js";
import contentUsageMixin from "@/mixins/contentUsageMixin.js";
import FolderStructureSharedContentFolders from "@/components/folderstructure/FolderStructureSharedContentFolders.vue";
import ResourceTypes from "@/lib/definitions/ResourceTypes.js";
import BaseActionMenu from "@/components/base/action/BaseActionMenu.vue";

const unsupportedFileExtensions = [];

export default {
    name: "FolderStructure",
    components: {
        BaseActionMenu,
        FolderStructureSharedContentFolders,
        BaseFloatingButton,
        FolderStructureItem,
        FolderStructureGridActions,
        FolderStructureItemActions,
        FolderStructureToolbar,
        BaseProcessingIndicator,
        BaseActionItem
    },
    mixins: [fileDownloaderMixin, contentUsageMixin],
    props: {
        folderModule: {
            required: true,
            type: String
        },
        itemModules: {
            default: () => [],
            type: Array
        },
        itemGetters: {
            default: () => [],
            type: Array
        },
        mainItemField: {
            required: true,
            type: String
        },
        routerSupport: {
            default: true,
            type: Boolean
        },
        selectMode: {
            default: false,
            type: Boolean
        },
        itemLabels: {
            required: false,
            default: () => [],
            type: Array
        },
        itemType: {
            required: true,
            type: String
        },
        helpPath: {
            type: String,
            default: ""
        },
        rootLabel: {
            default: "",
            type: String
        },
        addButtonText: {
            required: false,
            type: String
        },
        createEnabled: {
            default: false,
            type: Boolean
        },
        downloadEnabled: {
            default: false,
            type: Boolean
        },
        selectionDisabled: {
            default: false,
            type: Boolean
        },
        multiSelectionDisabled: {
            default: false,
            type: Boolean
        },
        folderReadOnly: {
            default: false,
            type: Boolean
        },
        itemReadOnly: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            folder: null,
            search: '',
            selected: {},
            selectedFolderId: null,
            lastSelected: null,
            activeModules: [],
            gridActions: {
                show: false,
                item: null
            },
            itemActions: {
                show: false,
                item: null
            },
            providedItemActions: {
                show: false,
                item: null
            },
            copied: null,
            openFolders: [],
            contextId: null,
            foldersWithItemInUsage: [],
            itemModulesAndLabels: []
        }
    },
    created() {
        //this.refresh();
        if (this.routerSupport) {
            const query = this.$route.query;
            if (query.folder !== undefined) {
                this.folder = query.folder;
            }
        } else {
            this.folder = null;
        }
    },
    computed: {
        sharedFolderIds() {
            const ids = ['root-shared-folder']
            this.folderModuleTranslated.forEach(folder => {
                if (folder.shared) {
                    ids.push(folder.id)
                }
            });
            this.folderModuleTranslated.forEach(folder => {
                if (folder.shared && folder.contextId) {
                    ids.push(`contextId-${folder.contextId}`);
                }
            });
            this.activeModules.forEach(m => {
                const moduleState = this.$store.state[m];
                if (this.$validator.isEmpty(moduleState)) {
                    console.error(`Missing CRUD module for ${m}`);
                } else {
                    this.$store.state[m].items.forEach(i => {
                        if (i.shared && i.contextId) {
                            const sharedFolderId = `contextId-${i.contextId}`;
                            if (!ids.includes(sharedFolderId)) {
                                ids.push(sharedFolderId);
                            }
                        }
                    });
                }
            });
            return ids;
        },
        hasSharedResources() {
            let hasShared = false;
            this.activeModules.forEach(m => {
                const moduleState = this.$store.state[m];
                if (this.$validator.isEmpty(moduleState)) {
                    console.error(`Missing CRUD module for ${m}`)
                } else {
                    this.$store.state[m].items.forEach(i => {
                        if (i.hasOwnProperty('shared')) {
                            if (i.shared) {
                                hasShared = true;
                            }
                        }
                    })
                }
            })
            return hasShared;
        },
        folderModuleTranslated() {
            return this.$store.getters[this.folderModule + '/translated'];
        },
        currentFolders() {
            const rootFolder = {nameTranslated: 'Root', id: null};

            const currentFoldersToBeSorted = this.folderModuleTranslated.filter(item => !item.shared);
            Array.sort(currentFoldersToBeSorted, 'nameTranslated');

            return [rootFolder, ...currentFoldersToBeSorted]
        },
        folderTree() {
            const tree = [];

            this.folderModuleTranslated.forEach(i => {
                if (i.parent === null) {
                    if (!i.shared) {
                        tree.push({
                            nameTranslated: i.nameTranslated,
                            id: i.id,
                            children: this.findChildrenForFolder(i),
                        });
                    }
                }
            });

            Array.sort(tree, 'nameTranslated');

            return tree;
        },
        items() {
            const allItems = [];
            const items = [];

            if (!this.$app.desktop) {
                const folders = this.folderModuleTranslated;
                console.log(this.folder);
                if (this.folder !== null) {
                    allItems.push({
                        type: 'back',
                        moduleName: null
                    });
                }
                folders.forEach(i => {
                    i.type = 'folder';
                    if (i.parent === this.folder && i.nameTranslated !== undefined && i.nameTranslated.toLowerCase().includes(this.search)) {
                        i.moduleName = this.folderModule;
                        allItems.push(i);
                    }
                });
            }

            this.activeModules.forEach(m => {
                const moduleState = this.$store.state[m];
                if (this.$validator.isEmpty(moduleState)) {
                    console.error(`Missing CRUD module for ${m}`);
                } else {
                    this.$store.state[m].items.forEach(i => {
                        if ((i.hasOwnProperty('folder') ? i.folder : null) === this.folder && typeof i[this.mainItemField] === 'string' &&
                                i[this.mainItemField].toLowerCase().includes(this.search)) {
                            i.moduleName = m;
                            if (i.folder === null && i.shared && i.fromContextName === null) {
                                i.folder = 'root-shared-folder';
                            }
                            if (i.folder === null && i.shared && i.fromContextName !== null) {
                                i.folder = `contextId-${i.contextId}`;
                            }
                            items.push(i);
                        }
                    })
                }
            });
            this.itemGetters.forEach(m => {
                this.$store.getters[m].forEach(i => {
                    if (i.folder === this.folder &&
                            ((typeof i[this.mainItemField] === 'string' && i[this.mainItemField].toLowerCase().includes(this.search)) ||
                                    this.search === '')) {
                        if (i.folder === null && i.shared && i.fromContextName === null) {
                            i.folder = 'root-shared-folder';
                        }
                        if (i.folder === null && i.shared && i.fromContextName !== null) {
                            i.folder = `contextId-${i.contextId}`;
                        }
                        i.moduleName = m.split('/')[0];
                        items.push(i);
                    }
                })
            });

            Array.sort(items, this.mainItemField);
            allItems.push(...items);
            return allItems;
        }
    },
    watch: {
        '$route.query'(query) {
            if (!this.routerSupport) {
                return;
            }

            if (query.folder !== undefined) {
                this.folder = query.folder;
            } else {
                this.folder = null;
            }

            if (query.contextId !== undefined) {
                this.contextId = query.contextId;
            } else {
                this.contextId = null;
            }

            this.selected = {};
            this.lastSelected = null;

            if (this.$refs.toolbar !== undefined) {
                this.$refs.toolbar.clearSearch();
            }
        },
        folderModule() {
            // this.refresh();
            this.openFolders = [];
        },
        folder(id) {
            this.openFolders.push(id);
            this.selectedFolderId = id;
        },
        selected(selected) {
            this.$emit('itemSelected', selected);
        },
        itemLabels: {
            handler() {
                this.updateModulesAndLabels()
            },
            immediate: true
        },
        itemModules: {
            handler() {
                this.folder = null;
                this.activeModules = this.itemModules;
                this.updateModulesAndLabels()
            },
            immediate: true
        }
    },
    methods: {
        updateModulesAndLabels() {
            const validator = this.$validator;
            const modules = this.itemModules
            const labels = this.itemLabels
            let modulesAndLabels = [];
            if (validator.isNotEmpty(modules) && validator.isNotEmpty(labels) && modules.length === labels.length) {
                // TODO: Here we assume correct index order between modules and labels
                modules.forEach(function (item, index) {
                    const label = labels[index]
                    const moduleAndLabel = {
                        module: item,
                        label: label
                    }
                    modulesAndLabels.push(moduleAndLabel)
                });
            }
            this.itemModulesAndLabels = modulesAndLabels;
        },
        getCurrentFolder() {
            return this.selectedFolderId;
        },
        findItemById(id) {
            return Array.findFirstByField(this.items, 'id', id)
        },
        isSharedFolder(folderId) {
            return this.sharedFolderIds.includes(folderId)
        },
        doEditItem() {
            const resource = this.$clone(this.findItemById(Object.keys(this.selected)[0]));
            if (resource === null) {
                console.error("EDIT resource - no item for id: " + Object.keys(this.selected)[0]);
                return;
            }
            const type = this.$tc('resources.resource', 1);

            this.itemActions.show = false;

            this.$prompt.show(this.$tc('generic.renameWithType', 1, {type: type}), this.$t('generic.name'), resource.name, async (name) => {
                resource.name = name;
                const response = await this.$store.dispatch(resource.moduleName + '/update', {item: resource});

                if (this.$responseCheck(response)) {
                    this.$notification.show('success', this.$t('generic.renamedTo', {name: name, type: type}));
                }
            }, {doneActionText: this.$t('generic.save')});
        },
        doEditFolder(folder) {
            this.$prompt.show(this.$tc('generic.renameWithType', 1, {type: "folder"}), this.$t('generic.name'), folder.nameTranslated, async (name) => {
                        const item = this.$clone(this.$store.getters[this.folderModule + '/itemById'](folder.id))
                        item.name = name
                        const response = await this.$store.dispatch(this.folderModule + '/update', {item: item});

                        if (this.$responseCheck(response)) {
                            this.$notification.show('success', this.$t('generic.renamedTo', {name: name, type: "folder"}));
                        }
                    }, {doneActionText: this.$t('generic.save')},
                    (input) => {
                        let isDuplicate = false
                        this.currentFolders.some(currentFolder => {
                            if ((!folder.hasOwnProperty('parent') && currentFolder.parent === null) || currentFolder.parent === folder.parent) {
                                if (currentFolder.nameTranslated.toLowerCase().trim() === input.toLowerCase().trim()) {
                                    isDuplicate = true
                                }
                            }
                        })
                        return isDuplicate ? this.$tc('generic.valueDuplicate', 1, {value: input}) : null
                    });
        },
        moveFolder({folder, dst}) {
            const clonedItem = this.$clone(this.$store.getters[this.folderModule + '/itemById'](folder.id));
            const foldersInFolder = this.findChildrenForFolder(dst)

            if (foldersInFolder.every(currentFolder => {
                if (currentFolder.nameTranslated.toLowerCase().trim() === folder.nameTranslated.toLowerCase().trim()) {
                    this.$notification.show('error', this.$tc('generic.valueDuplicate', 1, {value: folder.nameTranslated}));
                    return false
                }
                return true
            })) {
                this.$confirm.show(this.$t('screens.move'),
                        this.$t('resources.moveResource', {
                            src: folder.nameTranslated,
                            dst: dst.id === null ? this.rootLabel : dst.nameTranslated
                        }), async () => {
                            clonedItem.parent = dst.id;
                            const response = await this.$store.dispatch(this.folderModule + '/update', {item: clonedItem});
                            if (!this.$responseCheck(response)) {
                                this.$notification.show('error', this.$t('generic.errorOccurred'));
                            }
                        }, () => {
                        });
            }
        },
        async doDownload() {
            for (const itemId in this.selected) {
                if (this.selected.hasOwnProperty(itemId)) {
                    let url = this.selected[itemId].resourceUrl;
                    switch (this.selected[itemId].moduleName) {
                        case "videos":
                            url = this.selected[itemId].fullUrl;
                            break;
                    }
                    await this.downloadFile(url + '?rev=' + this.selected[itemId].rev, this.selected[itemId].name, true);
                }
            }
        },
        replaceableResource(item) {
            return item && this.isSingleItem && (item.moduleName === 'logos' || item.moduleName === 'wayfindingIcons')
        },
        replaceResource() {
            this.$refs.fileInput.click();
        },
        isSingleItem() {
            return Object.keys(this.selected).length === 1;
        },
        getResourceTypeByModuleName(moduleName) {
            switch (moduleName) {
                case "logos":
                    return ResourceTypes.Logo
                case "wayfindingIcons":
                    return "wayfinding icon"
                default:
                    return ResourceTypes.Image;
            }
        },
        async fileSelected(selectedFile, item) {
            const file = selectedFile.target.files[0];
            const extension = file.type.split('/')[file.type.split('/').length - 1];

            const moduleName = item.moduleName;
            const resourceType = this.getResourceTypeByModuleName(moduleName)

            if (unsupportedFileExtensions.includes(extension)) {
                this.$notification.show('error', this.$t('resources.unsupportedFile', {type: extension}));
            } else {
                this.$confirm.show(this.$tc('resources.replaceResourceTitle', 1, {
                    resource: resourceType,
                    name: this.lastSelected.name
                }), this.$tc('resources.replaceResourceDescription', 1, {resource: resourceType, name: file.name}), async () => {

                    const encodedFilename = encodeURIComponent(file.name);
                    let folderString = "";
                    if (this.folder != null) {
                        folderString = `&folder=${typeof this.folder === 'object' ? this.folder.id : this.folder}`;
                    }
                    const replaceIdString = `&replaceId=${this.lastSelected.id}`;

                    const response = await this.$apis[moduleName].get(`/upload?name=${encodedFilename}${folderString}${replaceIdString}`);

                    if (this.$responseCheck(response)) {
                        const form = document.createElement('form');
                        form.action = response.data.action;
                        form.method = response.data.method;
                        form.enctype = response.data.encType;

                        for (let key in response.data.formFields) {
                            const input = document.createElement('input')
                            input.setAttribute('type', 'hidden');
                            input.setAttribute('name', key);
                            input.setAttribute('value', key === 'x-amz-meta-name' ? file.name : response.data.formFields[key]);
                            form.appendChild(input);
                        }

                        const formData = new FormData(form);
                        formData.append('file', file, file.name);

                        const request = new XMLHttpRequest();

                        request.open(response.data.method, response.data.action);
                        request.send(formData);
                        this.$notification.show('success', this.$tc('resources.resourceReplaced', 1, {resource: resourceType}));
                    } else {
                        this.$notification.show('error', this.$tc('resources.resourceReplacedFailed', 1, {resource: resourceType}));
                    }
                }, () => {
                });
            }
            this.$refs.fileInput.value = "";
        },
        openFoldersChange(open) {
            this.openFolders = open;
        },
        findChildrenForFolder(f) {
            const subFolders = this.$store.getters[this.folderModule + '/translated'].filter(i => i.parent === f.id);
            const children = [];

            subFolders.forEach(c => {
                children.push({
                    id: c.id,
                    nameTranslated: c.nameTranslated,
                    children: this.findChildrenForFolder(c)
                });
            });
            Array.sort(children, 'nameTranslated');
            return children;
        },
        findItemsForFolder(f) {
            const items = [];
            this.activeModules.forEach(m => {
                this.$store.state[m].items.forEach(i => {
                    if (i.folder === f.id) {
                        console.log("activeModules items in folder");
                        items.push(i);
                    }
                })
            });

            this.itemGetters.forEach(m => {
                this.$store.getters[m].forEach(i => {
                    if (i.folder === f.id) {
                        console.log("itemGetters items in folder");
                        items.push(i);
                    }
                })
            });

            return items;
        },
        searchChange(search) {
            this.search = this.$validator.isNotEmpty(search) ? search : '';
        },
        back() {
            if (this.routerSupport) {
                this.$router.go(-1);
            } else {
                let goneBack = false;

                this.folderModuleTranslated.forEach(i => {
                    if (i.id === this.folder && !goneBack) {
                        console.log(i);
                        this.folder = i.parent;
                        goneBack = true;
                    }
                });
            }
        },
        createItem(folder) {
            // Change folder to selected folder where item will be saved, or else go to root folder
            this.pushFolderRoute(folder !== null ? folder.id : null);
            this.$emit('create', folder);
        },
        uploadItem() {
            this.$emit('create', this.folder);
        },
        createFolder(currentFolder) {
            this.$prompt.show(this.$t('folder.createFolder'), this.$t('generic.name'), null, async (name) => {
                        await this.$store.dispatch(this.folderModule + '/create', {
                            item: {
                                name: name,
                                contextId: this.$store.state.context.selectedContext.id,
                                parent: currentFolder !== null ? currentFolder : null
                            }
                        });
                    },
                    (input) => {
                        let isDuplicate = false
                        this.currentFolders.some(folder => {
                            if (folder.parent === currentFolder) {
                                if (folder.nameTranslated.toLowerCase().trim() === input.en.toLowerCase().trim()) {
                                    isDuplicate = true
                                }
                            }
                        })
                        return isDuplicate ? this.$t('theme.folderExists') : null
                    });
        },
        findFoldersWithItemsInUsage(folder) {
            const childrenForFolder = this.findChildrenForFolder(folder)
            if (childrenForFolder.length > 0) {
                childrenForFolder.forEach(subFolder => {
                    this.findFoldersWithItemsInUsage(subFolder)
                })
            }
            this.findItemsForFolder(folder).some(existingItem => {
                if (this.hasAnyUsages(existingItem.id)) {
                    this.foldersWithItemInUsage.push(folder)
                    return true;
                }
            })
        },
        async deleteFolder(folder) {
            this.foldersWithItemInUsage = [];
            if (folder.children.length > 0 || this.findItemsForFolder(folder).length > 0) {

                let existingItemsWithUsageExists = false;
                let existingFolderWithUsageText = this.$t('resources.deleteFolderWithUsage') + ":<br>";

                // recursive method
                this.findFoldersWithItemsInUsage(folder);

                if (this.foldersWithItemInUsage.length > 0) {
                    existingItemsWithUsageExists = true;
                    this.foldersWithItemInUsage.forEach(folderWithItemInUsage => {
                        existingFolderWithUsageText += '&bull; <b style="font-weight: bold;">' + folderWithItemInUsage.nameTranslated + "</b><br>";
                    })
                }

                if (existingItemsWithUsageExists) {
                    this.$confirm.deleteWithConfirmAndCustomText(this.$t('resources.folderNotEmpty'),
                            existingFolderWithUsageText, this.$t('resources.deleteFolderConfirm'),
                            async () => {
                                const response = await this.$store.dispatch(this.folderModule + '/delete', {item: folder});

                                if (this.$responseCheck(response)) {
                                    this.$notification.deleted(this.$tc('folder.folder', 1), 1);
                                    this.pushFolderRoute(null);
                                    this.selectedFolderId = null;
                                }
                            },
                            () => {
                            }
                    );
                } else {
                    this.$confirm.deleteWithConfirmAndCustomText(this.$t('resources.folderNotEmpty'), this.$t('resources.deleteFolderWarning'), this.$t('resources.deleteFolderConfirm'), async () => {
                        const response = await this.$store.dispatch(this.folderModule + '/delete', {item: folder});

                        if (this.$responseCheck(response)) {
                            this.$notification.deleted(this.$tc('folder.folder', 1), 1);
                            this.pushFolderRoute(null);
                            this.selectedFolderId = null;
                        }
                    }, () => {
                    });
                }
            } else {
                this.$confirm.delete(this.$tc('folder.folder', 1), 1, async () => {
                    const response = await this.$store.dispatch(this.folderModule + '/delete', {item: folder});

                    if (this.$responseCheck(response)) {
                        this.$notification.deleted(this.$tc('folder.folder', 1), 1);
                        this.pushFolderRoute(null);
                        this.selectedFolderId = null;
                    }
                }, () => {
                });
            }
        },
        async copyResourcesTo(folder) {
            for(const s in this.selected) {
                const item = this.$clone(this.selected[s]);
                const copyOfId = item.id;

                item.folder = folder.id;
                item.contextId = this.$store.state.context.selectedContext.id;
                delete item.shared;
                delete item.id;
                delete item.prelimId;
                delete item.version;

                let response = await this.$apis[item.moduleName].post(item, "?copyOfId=" + copyOfId);

                if (this.$responseCheck(response)) {
                    this.$notification.show('success', this.$t('resources.copyToFolderSuccess', {resourceName: item.name, folderName: folder.nameTranslated}));
                } else {
                    this.$notification.show('error', this.$t('resources.copyToFolderFailure', {resourceName: item.name, folderName: folder.name}));
                }
            }
        },
        moveResources(folder) {
            let count = 0;
            let names = "";
            for (const itemId in this.selected) {
                if (this.selected.hasOwnProperty(itemId)) {
                    count++;
                    names += this.selected[itemId].hasOwnProperty('nameTranslated') ? this.selected[itemId].nameTranslated + '<br>' : this.selected[itemId].name + '<br>';
                }
            }

            this.$confirm.show(this.$tc('resources.moveResources', count, {
                count: count,
                dest: folder.id === null ? this.rootLabel : folder.nameTranslated

            }), names, async () => {
                const failed = [];
                for (const itemId in this.selected) {
                    if (this.selected.hasOwnProperty(itemId)) {
                        const item = this.selected[itemId];

                        if (item.moduleName !== 'templates') {
                            let duplicateCounter = 0;
                            this.findItemsForFolder(folder).forEach(existingItem => {
                                let tempItemName = item.name;
                                tempItemName += duplicateCounter < 10 ? ' (0' + duplicateCounter + ')' : ' (' + duplicateCounter + ')';
                                if (item.name === existingItem.name || tempItemName === existingItem.name) {
                                    duplicateCounter++;
                                }
                            })

                            if (duplicateCounter > 0) {
                                item.name += duplicateCounter < 10 ? ' (0' + duplicateCounter + ')' : ' (' + duplicateCounter + ')';
                            }
                        }

                        item.folder = folder ? folder.id : null;
                        const response = await this.$store.dispatch(item.moduleName + '/update', {item: item});
                        if (!this.$responseCheck(response)) {
                            failed.push(this.selected[itemId].name);
                        }
                    }
                }
                this.selected = {};
            }, () => {
            });
        },
        paste() {
            const cut = this.$clone(this.copied.cut);
            const folder = this.gridActions.item === null ? this.folder : this.gridActions.item.id;

            this.copied.items.forEach(item => {
                item = this.$clone(item);

                if (item.moduleName === this.folderModule) {
                    item.parent = folder;
                } else {
                    item.folder = folder;
                }

                if (!cut) {
                    delete item.id;
                    delete item.prelimId;
                    delete item.version;
                }

                this.$store.dispatch(cut ? item.moduleName + '/update' : item.moduleName + '/create', {item: item});
            });

            this.gridActions.show = false;
            this.copied = null;
        },
        copy(item, cut = false) {
            this.copied = {
                cut: cut,
                items: this.$validator.isEmpty(this.selected) ? [item] : this.$lodash.values(this.selected)
            };

            this.itemActions.show = false;
        },
        refresh() {
            this.$store.dispatch(this.folderModule + '/fetch', {ignoreStale: true});

            this.activeModules.forEach(m => {
                this.$store.dispatch(m + '/fetch', {ignoreStale: true});
            });
            this.$emit('refresh');
        },
        bodyClick() {
            this.selected = {};
        },
        bodyRightClick() {
            this.gridActions.show = false;
            this.itemActions.show = false;

            if (this.folderReadOnly) {
                return;
            }

            this.$nextTick(() => {
                this.gridActions = {
                    show: true,
                    item: Array.findFirstByField(this.folderModuleTranslated, 'id', this.folder)
                }
            });
        },
        folderTreeRightClick(folder) {
            if (this.folderReadOnly) {
                return;
            }

            this.selectedFolderId = null;
            if (folder) {
                this.selectedFolderId = folder.id;
            }
            this.gridActions.show = false;
            this.itemActions.show = false;

            this.$nextTick(() => {
                this.gridActions = {
                    show: true,
                    item: folder
                }
            });

        },
        itemDoubleClick(item) {
            clearTimeout(this.clickTimeout);
            this.clickTimeout = null;

            if (item.type === 'back') {
                this.back();
            } else if (item.moduleName === this.folderModule) {
                this.pushFolderRoute(item.id);
            } else if (item.type !== 'font') {
                this.$emit('itemDoubleClick', item);
            }
        },
        pushFolderRoute(folderId, contextId = null, isShared = false) {

            this.$emit('sharedFolderSelected', isShared);

            if (this.routerSupport) {
                this.$router.push({
                    path: this.$router.history.current.name,
                    query: folderId === null ? {} : {folder: folderId, contextId}
                }).catch(reason => {
                    //console.error(reason);
                });
            } else {
                this.folder = folderId;
                this.contextId = contextId;
            }
        },
        providedItemRightClick(item) {
            if (this.itemReadOnly) {
                return;
            }

            if (!this.selected.hasOwnProperty(item.id)) {
                this.selected = {};
                this.$set(this.selected, item.id, item);
                this.lastSelected = item;
            }

            this.gridActions.show = false;
            this.itemActions.show = false;

            this.$nextTick(() => {
                this.providedItemActions = {
                    show: true,
                    item: item
                }
            })

        },
        itemRightClick(item) {
            if (this.itemReadOnly) {
                return;
            }

            if (!this.selected.hasOwnProperty(item.id)) {
                this.selected = {};
                this.$set(this.selected, item.id, item);
                this.lastSelected = item;
            }

            this.gridActions.show = false;
            this.itemActions.show = false;

            this.$nextTick(() => {
                this.itemActions = {
                    show: true,
                    item: item
                }
            })

        },
        itemClick(item) {

            if (this.selectionDisabled) {
                return;
            }
            if (!this.$app.desktop) {
                this.itemDoubleClick(item);
                return;
            }

            if (this.itemReadOnly) {
                return;
            }

            if (this.clickTimeout === null || this.clickTimeout === undefined) {
                this.clickTimeout = setTimeout(() => {
                    this.clickTimeout = null;

                    if (this.$app.ctrlPressed) {
                        if (this.multiSelectionDisabled !== true) {
                            if (this.selected[item.id] === undefined) {
                                this.$set(this.selected, item.id, item);
                                this.lastSelected = item;
                            } else {
                                this.$delete(this.selected, item.id);
                            }
                        }
                    } else if (this.$app.shiftPressed && this.lastSelected !== null) {
                        if (this.multiSelectionDisabled !== true) {
                            const indexOfLast = this.items.indexOf(this.lastSelected);
                            const indexOfCurrent = this.items.indexOf(item);

                            if (indexOfCurrent !== indexOfLast) {
                                if (indexOfLast > indexOfCurrent) {
                                    for (let i = indexOfCurrent; i <= indexOfLast; i++) {
                                        const item = this.items[i];
                                        this.$set(this.selected, item.id, item);
                                    }
                                } else {
                                    for (let i = indexOfLast; i <= indexOfCurrent; i++) {
                                        const item = this.items[i];
                                        this.$set(this.selected, item.id, item);
                                    }
                                }
                            }
                        }
                    } else {
                        if (this.selected[item.id] === undefined) {
                            this.selected = {};
                            this.$set(this.selected, item.id, item);
                            this.lastSelected = item;
                        } else {
                            this.selected = {};
                        }
                    }
                }, 250);
            }
        }
    }
}
</script>

<style lang="scss">
.folder-structure {
    height: calc(100vh - 64px);

    .root-item {
        max-height: 26px;
    }

    .root-item .v-icon {
        padding-right: 8px;
    }

    .folder-structure-grid {
        min-height: calc(100vh - 130px);
    }

    .row {
        margin: 0;
        width: 100%;
        height: calc(100% - 54px) !important;
    }

    .item-grid {
        height: 100%;
        overflow-y: auto;
    }

    .folder-tree {
        overflow-y: auto;
        height: 100%;
        padding-bottom: 10px;
        padding-left: 24px !important;
        max-width: 300px;

        .v-treeview {
            padding-top: 0;
        }

        .v-treeview-node {
            margin-top: 0;
        }

        .v-treeview-node__content {
            margin-top: 0;
        }

        .v-treeview-node__root {
            margin-top: 0;
        }
    }
}

.mobile .folder-structure {
    .item-grid {
        margin-top: 6px;
        padding: 0 4px 0 4px;
        height: auto;
        overflow-y: hidden;
    }
}

.mobileToggle {
    position: fixed;
    z-index: 10;
    left: 10px;
    bottom: 20px;
}
</style>

