import {mapState, mapGetters} from "vuex";

export default {
    data() {
    },
    computed: {
        ...mapGetters({
            menuPages: 'interactiveMenuPages/withPath',
            templatePages: 'compositeMenuPages/withPath'
        }),
        ...mapState({
            documents: state => state.documents.items,
            menuLists: state => state.menuLists.items,
            contentSchedules: state => state.contentSchedules.items,
            screenContents: state => state.screenContents.items
        })
    },
    methods: {
        hasAnyUsages(id) {
            return this.resourceUsageSignageSchedules(id).length > 0 ||
                   this.resourceUsageTemplatePages(id).length > 0 ||
                   this.resourceUsageMenuPages(id).length > 0 ||
                   this.resourceUsageDataStores(id).length > 0 ||
                   this.resourceUsageDocuments(id).length > 0;
        },

        resourceUsageSignageSchedules(id, verbose = false) {
            const contentMatches = [];
            this.screenContents.forEach(c => {
                c.elements.forEach(e => {
                    if(e.resourceId === id) {
                        contentMatches.push(c.id);
                    }
                });
            });
            /* Now find the schedule using the content */
            const scheduleCnt = {};
            contentMatches.forEach(m => {
                for(let i = 0; i < this.contentSchedules.length; i++) {
                    const s = this.contentSchedules[i];
                    if(s.defaultScreenContentId === m) {
                        scheduleCnt[s.name + " - default content"] = 0;
                        break;
                    }
                    for(let j = 0; j < s.daily.length; j++) {
                        const d = s.daily[j];
                        if(d.screenContentId === m) {
                            const key = s.name + " - daily";
                            if(scheduleCnt.hasOwnProperty(key)) {
                                scheduleCnt[key]++;
                            } else {
                                scheduleCnt[key] = 1;
                            }
                            break;
                        }
                    }
                    for(let j = 0; j < s.weekly.length; j++) {
                        const w = s.weekly[j];
                        if(w.screenContentId === m) {
                            const key = s.name + " - " + w.day;
                            if(scheduleCnt.hasOwnProperty(key)) {
                                scheduleCnt[key]++;
                            } else {
                                scheduleCnt[key] = 1;
                            }
                            break;
                        }
                    }
                    for(const date in s.dates) {
                        const d = s.dates[date];
                        for(let j = 0; j < d.length; j++) {
                            const e = d[j];
                            if(e.screenContentId === m) {
                                const key = s.name + " - " + date;
                                if(scheduleCnt.hasOwnProperty(key)) {
                                    scheduleCnt[key]++;
                                } else {
                                    scheduleCnt[key] = 1;
                                }
                                break;
                            }
                        }
                    }
                }
            });
            let res = [];
            for(const key in scheduleCnt) {
                if(scheduleCnt[key] === 0) {
                    res.push(key);
                } else {
                    res.push(key + ": " + scheduleCnt[key]);
                }
            }

            if(verbose && res.length == 0) { res.push("None"); }

            return res;
        },
        resourceUsageSignageSchedulesText(id, verbose = true) {
            const schedules = this.resourceUsageSignageSchedules(id);
            if(schedules.length > 0) {
                return schedules.join(", ");
            }
            return verbose ? this.$t('generic.none') : "";
        },
        resourceUsageTemplatePages(id, verbose = false) {
            const pages = [];
            this.templatePages.forEach( item => {
                if(item.config.indexOf(id) >= 0) {
                    pages.push(item.nameWithPath);
                }
            });

            if(verbose && pages.length == 0) { pages.push("None"); }

            return pages;
        },
        resourceUsageTemplatePagesText(id, verbose = true) {
            const pages = this.resourceUsageTemplatePages(id);
            if(pages.length > 0) {
                return pages.join(", ");
            }
            return verbose ? this.$t('generic.none') : "";
        },
        resourceUsageMenuPages(id, verbose = false) {
            const pages = [];
            this.menuPages.forEach( item => {
                if(item.config.indexOf(id) >= 0) {
                    pages.push(item.nameWithPath);
                }
            });

            if(verbose && pages.length == 0) { pages.push("None"); }

            return pages;
        },
        resourceUsageMenuPagesText(id, verbose = true) {
            const pages = this.resourceUsageMenuPages(id);
            if(pages.length > 0) {
                return pages.join(", ");
            }
            return verbose ? this.$t('generic.none') : "";
        },
        resourceUsageDataStores(id, verbose = false) {
            const menuLists = [];
            this.menuLists.forEach( item => {
                if(JSON.stringify(item.data).indexOf(id) >= 0) {
                    menuLists.push(item.name);
                }
            });

            if(verbose && menuLists.length == 0) { menuLists.push("None"); }

            return menuLists;
        },
        resourceUsageDataStoresText(id, verbose = true) {
            const menuLists = this.resourceUsageDataStores(id);
            if(menuLists.length > 0) {
                return menuLists.join(", ");
            }
            return verbose ? this.$t('generic.none') : "";
        },
        resourceUsageDocuments(id, verbose = false) {
            const documents = [];
            this.documents.forEach( item => {
                if(item.content.indexOf(id) >= 0) {
                    documents.push(item.name);
                }
            });

            if(verbose && documents.length == 0) { documents.push("None"); }

            return documents;
        },
        resourceUsageDocumentsText(id, verbose = true) {
            const documents = this.resourceUsageDocuments(id);
            if(documents.length > 0) {
                return documents.join(", ");
            }
            return verbose ? this.$t('generic.none') : "";
        }
    }
};