import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'streamDevices';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    withAllocatedStream: (state, getters, rootState, rootGetters) => {

        const data = [];

        if (Array.isArray(state.items) && state.items.length > 0) {

            const allocatedStreams = rootGetters['allocatedStreams/sorted'];
            const clientIdMap = rootGetters['clients/sorted']

            state.items.forEach((item) => {

                const allocatedStream = allocatedStreams.filter(it => it.deviceId === item.id)[0];

                if (allocatedStream) {
                    const client = clientIdMap.filter(it => it.id === allocatedStream.clientId)[0];

                    const extendedItem = {
                        ...item,
                        allocatedStreamId: allocatedStream.id,
                        allocated: !!allocatedStream,
                        clientName: client.name,
                        clientId: client.id,
                        lastAlive: allocatedStream.lastAlive
                    }
                    data.push(extendedItem);

                } else {
                    data.push(item);
                }



            });
        }

        return data;
    }
});

export default module;