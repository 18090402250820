<template>
    <v-toolbar
        dense
        class="base-toolbar py-1"
        color="toolbar"
        :class="(elevation ? 'elevation-soft' : 'elevation-0') + (dense ? 'dense' : '')"
    >
        <slot name="primary"></slot>
        <v-spacer></v-spacer>
        <SearchField
            auto-shrink
            @change="searchChange"
            v-if="searchEnabled"
            :tooltip="searchTooltip"
            :label="searchLabel"
            ref="searchField"
        ></SearchField>
        <slot name="secondary"></slot>
        <slot name="tertiary"></slot>
    </v-toolbar>
</template>
<script>
    import SearchField from "@/components/input/SearchField.vue";

    export default {
        name: 'BaseToolbar',
        components: {SearchField},
        props: {
            fixed: {
                default: true,
                type: Boolean
            },
            searchEnabled: {
                default: true,
                type: Boolean
            },
            searchLabel: {
                default: null,
                type: String
            },
            searchTooltip: {
                default: null,
                type: String
            },
            elevation: {
                default: true,
                type: Boolean
            },
            dense: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            searchChange(text) {
                this.$emit('search', text);
            },
            clearSearch() {
                this.$refs.searchField.clear();
            }
        }
    }
</script>

<style lang="scss">
    .base-toolbar {
        z-index: 2;
        padding-right: 0;
        height: 70px !important;
        overflow-y: hidden;
        width: 100%;

        .search-field {
            margin: 0 10px 0 10px;
        }

        transition: none !important;
        overflow-x: auto;

        .v-toolbar__content {
            padding: 18px 24px 0 24px !important;
        }
    }

    .base-toolbar.dense {
        .v-toolbar__content {
            padding: 0 14px 0 14px !important;
        }
    }

    .tablet .base-toolbar {
        width: calc(100vw - 240px);
    }

    .mobile .base-toolbar {
        width: 100vw;

        .v-toolbar__content {
            padding: 0 12px 0 16px !important;

            .v-toolbar__title {
                //margin-left: 10px;
                padding-left: 5px !important;
            }
        }
    }
</style>