import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'contextTypes';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    names: (state, getters) => {

        return state.items
                .filter(function (item) {
                    return item.isSystemContext === false
                })
                .map(function (item) {
                    return item.id
                });
    },
    namesIncludingSystem: (state, getters) => {

        return state.items
            .map(function (item) {
                return item.id
            });
    },
    namesOfAdministrativeTypes: (state, getters) => {
        return state.items
                .filter(function (item) {
                    return item.isAdministrative === true
                })
                .map(function (item) {
                    return item.id
                });
    },
    contextTypesManagedBy: (state) => (contextTypeName) => {
        const correctContextTypeName = contextTypeName.capitalize()
        return state.items
                .filter(function (item) {
                    return item.managedBy.findIndex((name) => name === correctContextTypeName) !== -1
                })
                .map(function (item) {
                    return item.id
                });
    }
});

export default module;
