import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'documents';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withMedia: (state, getters, rootState) => {
        const data = module.cloneItems();
        if (Array.isArray(data)) {
            data.forEach((item) => {
                if(typeof item.content === 'string') {
                    item.images = item.content.split('<img').length - 1;
                    item.videos = item.content.split('<video').length - 1;
                    item.headers = item.content.split('<h').length - 1;
                } else {
                    item.images = 0;
                    item.videos = 0;
                    item.headers = 0;
                }
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    }
});

export default module;