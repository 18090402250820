<template>
    <BaseModal
            :title="$t('help.getSupportTitle')"
            :done-action-text="$t('generic.close')"
            :hide-all-actions="true"
            @done="done"
            v-model="open"
            :max-width="700"
    >
        <template v-slot:content>
            <v-card
                elevation="0"
                v-html="bodyMarkup"
            ></v-card>
        </template>
    </BaseModal>
</template>

<script>
    import BaseModal from "../base/popup/BaseModal.vue";
    import Vue from "vue";
    import {mapGetters} from "vuex";

    export default {
        name: "HelpGetSupport",
        components: {
            BaseModal,
        },
        props: {
        },
        data() {
            return {
                open: false
            }
        },
        mounted() {
            Vue.prototype.$helpGetSupport = this;
        },
        computed: {
            ...mapGetters({
                vendorConfiguration: 'currentVendorConfiguration/configuration'
            }),
            bodyMarkup() {
                if(!this.$store.state.context.selectedContext.hasOwnProperty('id')) {
                    return this.$t('help.notAvailableInHomeScreen');
                } else if(this.$validator.isNotEmpty(this.vendorConfiguration)) {
                    return this.$t('help.getSupportBody', {
                        email:this.$validator.isNotEmpty(this.vendorConfiguration.contactInfo.email) ? '<a href="mailto:' + this.vendorConfiguration.contactInfo.email + '">' + this.vendorConfiguration.contactInfo.email + '</a>' : 'N/A',
                        phone: this.vendorConfiguration.contactInfo.phone,
                        text: this.vendorConfiguration.contactNote
                    });
                } else {
                    return this.$t('help.notAvailable');
                }
            }
        },
        methods: {
            show() {
                this.open = true;
            },
            done() {
                this.open = false;
            },
        }

    }
</script>

<style>
    .v-tabs-items {
        padding: 8px 16px;
    }
</style>