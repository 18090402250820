<template>
    <BaseEditor
        v-model="show"
        custom-class="image-editor"
        :max-width="$app.desktop ? $app.width - 200 : 100"
        title="Edit Image"
        :done-action-text="$t('generic.save')"
        :persistent="true"
        :eager="true"
        @done="done"
    >
        <template v-slot:content>
            <div class="cropper-container">
                <img :id="editorId" :src="imageSrc" alt="Image to edit">
            </div>

            <BaseSizedBox :height="$app.desktop ? 30 : 15"></BaseSizedBox>

            <div class="text-center" style="width: 100%;">
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn
                            icon
                            v-on="on"
                            @click="rotateLeft"
                        >
                            <v-icon>rotate_left</v-icon>
                        </v-btn>
                    </template>
                    {{$t('resources.rotateLeft')}}
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn
                            icon
                            v-on="on"
                            @click="crop"
                        >
                            <v-icon>crop</v-icon>
                        </v-btn>
                    </template>
                    {{$t('resources.crop')}}
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn
                            icon
                            v-on="on"
                            @click="rotateRight"
                        >
                            <v-icon>rotate_right</v-icon>
                        </v-btn>
                    </template>
                    {{$t('resources.rotateRight')}}
                </v-tooltip>
            </div>
        </template>
    </BaseEditor>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";
    import BaseEditor from "@/components/base/popup/BaseEditor.vue";
    import Cropper from 'cropperjs';
    import BaseSizedBox from "@/components/base/BaseSizedBox.vue";

    export default {
        name: "ImageEditor",
        mixins: [VModelShowMixin],
        components: {BaseSizedBox, BaseEditor},
        props: {
            image: null
        },
        data() {
            return {
                editorId: Math.floor(Math.random() + 100000),
                imageSrc: null,
                maxWidth: 600
            };
        },
        mounted() {
            const image = document.getElementById(this.editorId);
            const me = this;

            this.cropper = new Cropper(image, {
                viewMode: 1,
                dragMode: 'move',
                rotatable: true,
                movable: true,
                scalable: true,
                zoomOnWheel: true,
                zoomOnTouch: true,
                ready(event) {
                    let width = event.target.naturalWidth;
                    if (width < 500) width = 500;
                    me.maxWidth = width + 100;
                    me.cropper.reset();
                }
            });
        },
        watch: {
            show(show) {
                if (show && this.image !== null) {
                    this.$nextTick(() => {
                        this.setImageSrc(this.image.file);
                    });
                }
            }
        },
        methods: {
            rotateRight() {
                this.cropper.rotate(45);
            },
            rotateLeft() {
                this.cropper.rotate(-45);
            },
            crop() {
                this.cropper.crop();
                this.cropper.getCroppedCanvas().toBlob((blob) => {
                    this.setImageSrc(blob);
                });
            },
            setImageSrc(file) {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.imageSrc = event.target.result;
                    this.cropper.replace(event.target.result);
                };

                reader.readAsDataURL(file);
            },
            done() {
                this.cropper.crop();

                this.cropper.getCroppedCanvas().toBlob((blob) => {
                    blob.lastModified = this.image.file.lastModified;
                    blob.lastModifiedDate = this.image.file.lastModifiedDate;
                    blob.name = this.image.file.name;
                    this.$emit('done', blob);
                    this.show = false;
                });
            }
        }
    }
</script>

<style lang="scss">
    .image-editor {
        img {
            max-width: 100%;
        }

        .cropper-container {
            max-height: calc(90vh - 260px);
        }
    }
</style>