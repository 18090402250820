import apis from '@/lib/api/index';
import apiHelper from '@/lib/helpers/ApiHelper';
import Vue from "vue";

export default {

    async fetchStatsForMirageDeployments(sites) {

        let chunkSize = 200;

        let mirageSiteIdsNotLoaded = [];

        if(Array.isArray(sites)) {
            mirageSiteIdsNotLoaded = sites.filter(item => !item.hasOwnProperty('loaded') && item.productType === "mirage").map(e => e.facId);

        } else {
            for (const [key, value] of Object.entries(sites)) {
                if (!value.hasOwnProperty('loaded') && value.products.hasOwnProperty("mirage")) {
                    mirageSiteIdsNotLoaded.push(value.id);
                }
            }

        }

        if (mirageSiteIdsNotLoaded.length === 0) {
            return sites;
        }

        let chunks = this.chunkArray(mirageSiteIdsNotLoaded, chunkSize);
        const promises = [];

        for (let i = 0; i < chunks.length; i++) {
            promises.push(this.fetchStatsForChunk(chunks[i], sites));
        }

        try {
            const resp = await Promise.all(promises);
            const stats = [];
            for (let i = 0; i < resp.length; i++) {
                stats.push(...resp[i]);
            }

            if (Array.isArray(sites)) {
                sites.forEach(site => {
                    const updatedItem = stats.find(stat => stat.id === site.facId);
                    if (updatedItem) {
                        site = this.populateMirageDeploymentWithStats(site, updatedItem.statusByProduct['mirage']);
                        site.loaded = true;
                    }
                });
            } else {
                for (const [key, value] of Object.entries(sites)) {
                    const updatedItem = stats.find(stat => stat.id === value.id);
                    if (updatedItem) {
                        sites[key] = this.populateMirageDeploymentWithStats(value, updatedItem.statusByProduct['mirage']);
                        sites[key].loaded = true;
                    }
                }
            }

        } catch (e) {
            console.error(e);
        }

        return sites;
    },

    chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    },

    populateMirageDeploymentWithStats(itemToUpdate, updatedStats) {
        itemToUpdate.roomCnt = parseInt(_.get(updatedStats, 'hospLocStat.totLocs', 0));
        itemToUpdate.occupied = parseInt(_.get(updatedStats, 'hospLocStat.occLocs', 0));
        itemToUpdate.cliStat = Vue.prototype.$clone(updatedStats.cliStat);

        //Tv Clients
        itemToUpdate.numberOfClients = _.get(updatedStats, 'cliStat.totClis', 0);
        itemToUpdate.offlineClients = _.get(updatedStats, 'cliStat.olClis', 0);
        const tvDeviceTypes = _.get(updatedStats, 'cliStat.devCnt', {});
        itemToUpdate.tvTypes = {};
        itemToUpdate.tvMod = {};
        itemToUpdate.tvFw = {};

        //Signage Clients
        itemToUpdate.numberOfSigClients = _.get(updatedStats, 'cliStat.totSigClis', 0);
        itemToUpdate.sigMod = {}
        itemToUpdate.sigClientTypes = {};
        itemToUpdate.offlineSigClients = _.get(updatedStats, 'cliStat.olSigClis', 0);
        const signageDeviceTypes = _.get(updatedStats, 'cliStat.sigDevCnt', {});
        const signageFw = _.get(updatedStats, 'cliStat.sigFw');
        itemToUpdate.sigFw = []

        //Cast
        itemToUpdate.numberOfCasts = _.get(updatedStats, 'castStat.registeredChromecasts');
        itemToUpdate.castVersion = _.get(updatedStats, 'castStat.castVersion');
        itemToUpdate.castOnline = _.get(updatedStats, 'castStat.online');

        //Agent Client
        itemToUpdate.agentOnline = _.get(updatedStats, 'cliStat.agentOnline');
        itemToUpdate.agentVersion = _.get(updatedStats, 'cliStat.agentVersion');


        if (signageFw && signageFw.length > 0) {
            signageFw.forEach( function (it) {
                if (it !== "-") {
                    itemToUpdate.sigFw.push(it);
                }
            });
        }

        for (const key in tvDeviceTypes) {
            const parts = key.split(',');
            if (parts.length > 0 && typeof parts[0] === 'string') {
                const [vendor, deviceType] = parts[0].split('/');
                //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                    if (!itemToUpdate.tvTypes.hasOwnProperty(vendor)) {
                        itemToUpdate.tvTypes[vendor] = tvDeviceTypes[key];
                    } else {
                        itemToUpdate.tvTypes[vendor] += tvDeviceTypes[key];
                    }
                }
                if (deviceType === 'TV' && parts.length > 1 && parts[1] !== '-') {
                    if (!itemToUpdate.tvMod.hasOwnProperty(parts[1])) {
                        itemToUpdate.tvMod[parts[1]] = tvDeviceTypes[key];
                    } else {
                        itemToUpdate.tvMod[parts[1]] += tvDeviceTypes[key];
                    }
                }
            }
        }

        for (const key in signageDeviceTypes) {
            const parts = key.split(',');
            if (parts.length > 0 && typeof parts[0] === 'string') {
                const [vendor, deviceType] = parts[0].split('/');
                //vendor = vendor === '-' ? 'UNKNOWN' : vendor;
                if (vendor && vendor !== '-' && vendor !== 'BROWSER') {
                    if (!itemToUpdate.sigClientTypes.hasOwnProperty(vendor)) {
                        itemToUpdate.sigClientTypes[vendor] = signageDeviceTypes[key];
                    } else {
                        itemToUpdate.sigClientTypes[vendor] += signageDeviceTypes[key];
                    }
                }
                if (deviceType === 'SIGNAGE' && parts.length > 1 && parts[1] !== '-') {
                    if (!itemToUpdate.sigMod.hasOwnProperty(parts[1])) {
                        itemToUpdate.sigMod[parts[1]] = signageDeviceTypes[key];
                    } else {
                        itemToUpdate.sigMod[parts[1]] += signageDeviceTypes[key];
                    }
                }
            }
        }

        return itemToUpdate;
    },

    async fetchStatsForChunk(chunk, sites) {
        const resp = await apis['overviewContexts'].post(chunk, "/stats");
        if (apiHelper.responseSuccessCheck(resp)) {
            return resp.data
        } else {
            return [];
        }
    }
}