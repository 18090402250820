import BasicCrudApi from "../../lib/api/BasicCrudApi";
import apis from '@/lib/api/index';
import ApiHelper from '@/lib/helpers/ApiHelper';
import Storage from "@/lib/helpers/Storage"
import {Validator} from "@/lib/helpers";
import Vue from "vue";
import {AccessUtilities} from "@/lib/auth/AccessUtilities";

const resCheck = ApiHelper.responseSuccessCheck;

function getLastContext() {
    try {
        const lastCtxt = JSON.parse(Storage.getSession('lastContext', "{}"));
        if (lastCtxt === null || lastCtxt === undefined) {
            return {};
        }
        return lastCtxt;
    } catch (e) {
    }
    return {};
}

function setLastContext(ctx) {
    if (ctx === null) {
        Storage.setSession('lastContext', "{}");
    } else {
        Storage.setSession('lastContext', JSON.stringify(ctx));
    }
}


const state = {
    initializing: true,
    selectedContext: {},
    mode: 'loading',
    fetching: false,
    switchingAvailable: false,
    privileges: [],
    provisioning: [],
    overviewItems: []
};

const actions = {
    setLogin({commit}) {
        commit('setMode', 'login');
        commit('setInitializing', false);
    },
    setLoading({commit}) {
        commit('setMode', 'loading');
        commit('setInitializing', false);
    },
    async autoSelectContext({commit, rootGetters, dispatch}) {
        const lastCtx = getLastContext();
        // NOTE: Do not use browser-stored version of last used context as some values might have change since last time
        //       Fetch the latest version, if exists, based on last used ID
        let lastContextUsed = null;
        if (lastCtx.hasOwnProperty('id')) {
            lastContextUsed = rootGetters['deployments/itemById'](lastCtx.id);
            if (!lastContextUsed) {
                lastContextUsed = rootGetters['themes/itemById'](lastCtx.id);
            }
        }
        if (lastCtx.hasOwnProperty('id') && Validator.isNotEmpty(lastContextUsed)) {
            await dispatch('selectContext', lastContextUsed);
            commit('setInitializing', false);
        } else {
            const deploymentItems = rootGetters['deployments/withDirectAccess'];
            const themeItems = rootGetters['themes/withDirectAccess'];
            const sharingItems = rootGetters['sharingContexts/withDirectAccess'];
            const contentProviderItems = rootGetters['contentProviderContexts/withDirectAccess'];
            const items = deploymentItems.concat(themeItems).concat(sharingItems).concat(contentProviderItems);
            if (items.length === 1) {
                await dispatch('selectContext', items[0]);
                commit('setInitializing', false);
            } else {
                return new Promise(resolve => {
                    commit('setMode', 'home');
                    commit('setContext', {});
                    commit('setInitializing', false);
                    resolve();
                });
            }
        }
    },
    async autoSelectFirstContext({commit, rootState, dispatch}) {
        const items = rootState.deployments.items;
        if (items.length > 0) {
            await dispatch('selectContext', Vue.prototype.$clone(items[0]));
            commit('setInitializing', false);
        } else {
            return new Promise(resolve => {
                commit('setMode', 'home');
                commit('setContext', {});
                commit('setInitializing', false);
                resolve();
            });
        }
    },
    async loadOverviewContext({commit}) {
        commit('fetching');
        const response = await apis['accountAccesses'].get('/overview');
        if (resCheck(response)) {
            commit('doneFetching');
            commit('setOverviewItems', response.data);
            if (response.data.length > 1) {
                commit('setSwitchingAvailable', true);
            }
        } else {
            commit('setOverviewItems', []);
            commit('doneFetching');
            commit('setMode', 'error')
        }
    },
    async selectContext({commit}, context) {
        commit('fetching');
        const response = await apis['accountAccesses'].get('/context/' + context.id);
        if (resCheck(response)) {
            commit('doneFetching');
            commit('setPrivileges', response.data.privileges);
            commit('setProvisioning', response.data.provisioning);
            commit('setRouteIds', response.data.routeIds);
            commit('setContext', context);
            const contextType = (() => {
                if(context == null){
                    return 'site'
                }
                switch(context.contextType) {
                    case 'Theme':
                        return 'theme'
                    case 'Site':
                        return 'site'
                    case 'Sharing':
                        return 'sharing'
                    case 'Content Provider':
                        return 'content provider'
                    default:
                        return 'site'
                }
            })
            commit('setMode', contextType());
        } else {
            commit('setContext', {});
            commit('setMode', 'home');
            commit('doneFetching');
        }
    },
    async enableContextSwitching({commit}) {
        commit('setSwitchingAvailable', true);
    },
    clearContext({commit}) {
        console.log("Clearing context");
        commit('setPrivileges', []);
        commit('setProvisioning', []);
        commit('setRouteIds', []);
        commit('setContext', {});
        commit('setMode', 'home');
    }
};

const mutations = {
    setContext(state, context) {
        state.selectedContext = context;
        setLastContext(context);
        BasicCrudApi.setContextId(context.hasOwnProperty('id') ? context.id : null)
    },
    setMode(state, mode) {
        state.mode = mode;
    },
    fetching(state) {
        state.fetching = true;
    },
    doneFetching(state) {
        state.fetching = false;
    },
    setOverviewItems(state, data) {
        state.overviewItems = data;
    },
    setSwitchingAvailable(state, val) {
        state.switchingAvailable = val;
    },
    setInitializing(state, init) {
        state.initializing = init;
    },
    setPrivileges(state, privileges) {
        state.privileges = privileges;
    },
    setProvisioning(state, provisioning) {
        state.provisioning = provisioning;
    },
    setRouteIds(state, routeIds) {
        state.routeIds = routeIds;
    }
};

const getters = {
    hasAnyFeatures: (state) => {
        return AccessUtilities.hasAnyProvisioning(state.provisioning)
    },
    featuresAndPrivilegesIntersect: (state) => {
        return AccessUtilities.featuresAndPrivilegesIntersection(state.provisioning, state.privileges)
    },
    privilegeCheckCurrentContext: (state, getters) => (feature, resource, action = "read") => {
        if (!feature) {
            return false;
        }

        const featureEntry = Array.findFirst(getters['featuresAndPrivilegesIntersect'], item => item.feature === feature);
        if (!featureEntry) {
            return false;
        }

        if(!resource) {
            return true; /* Just checking for feature */
        }

        const resourceEntry = Array.findFirst(featureEntry.resourceProvisions, item => item.resource === resource);
        if (!resourceEntry) {
            return false;
        }
        return (resourceEntry.actions[action] === true);
    },
    systemPrivilegeCheck: (state) => (feature, resource, action) => {
        const systemCtx = Array.findFirst(state.overviewItems, item => item.contextType === 'Domain', null);
        if (!systemCtx) {
            return false;
        }
        return AccessUtilities.hasPrivilege(feature, resource, action, systemCtx.privileges)
    },
    privilegeCheck: (state) => (feature, resource, action, contextId) => {
        const ctx = Array.findFirst(state.overviewItems, item => item.contextId === contextId, null);
        if (!ctx) {
            return false;
        }
        return AccessUtilities.hasPrivilege(feature, resource, action, ctx.privileges)
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
