import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from 'vue';

const thisModule = 'locations';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withStatus: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        if (Array.isArray(data)) {
            const locationCategoriesIdMap = rootGetters['locationCategories/idMapByGetter']('translated');
            const guestsLocationIdMap = rootGetters['guests/idMapByGetter'](null, 'locationId', true);
            const locationTagsIdMap = rootGetters['locationTags/idMapByGetter']('translated');

            data.forEach((item) => {

                if (Vue.prototype.$validator.isEmpty(item.locations)) {
                    item.parentLocations = null;
                    item.parentLocation = "None";
                } else {
                    item.parentLocations = item.locations.split(',').map(locationId => {
                        const parent = getters.itemById(locationId);

                        if (parent !== null) {
                            parent.categoryTitle = Map.retrieveValue(locationCategoriesIdMap,
                                    parent.categoryId, 'titleTranslated');
                            parent.titleTranslated = Vue.prototype.$tm(parent.title);
                            parent.fullTitle = parent.categoryTitle === null ? parent.titleTranslated :
                                    `${parent.categoryTitle} ${parent.titleTranslated}`;

                            return parent.fullTitle;
                        }
                    });
                    item.parentLocation = item.parentLocations[0];
                }

                const guests = Map.retrieve(guestsLocationIdMap, item.id);
                if(Array.isArray(guests) && guests.length > 0) {
                    item.occupation = 'Occupied';
                    item.guests = guests;
                } else {
                    item.occupation = 'Vacant';
                    item.guests = [];
                }

                if(Vue.prototype.$validator.isEmpty(item.tags)) {
                    item.tagTitles = null;
                } else {
                    item.tagTitles = item.tags.split(',').map(tagId => {
                        const tag = Map.retrieve(locationTagsIdMap, tagId);
                        if (tag !== null) {
                            return tag.titleTranslated;
                        }
                    });
                }

                item.categoryTitle = Map.retrieveValue(locationCategoriesIdMap,
                        item.categoryId, 'titleTranslated');
                item.titleTranslated = Vue.prototype.$tm(item.title);
                item.fullTitle = item.categoryTitle === null ? item.titleTranslated :
                        `${item.categoryTitle} ${item.titleTranslated}`;

                item.guestNames = [];
                if(Array.isArray(item.guests) && item.guests.length > 0) {
                    item.guests.forEach(g => { item.guestNames.push(g.name); });
                }
            });

            Array.sort(data, 'fullTitle');
            return data;
        }
        return [];
    },
    withFullTitle: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        if (Array.isArray(data)) {
            const locationCategoriesIdMap = rootGetters['locationCategories/idMapByGetter']('translated');
            data.forEach((item) => {
                item.categoryTitle = item.categoryId !== null ? Map.retrieveValue(locationCategoriesIdMap,
                        item.categoryId, 'titleTranslated') : '';
                item.titleTranslated = Vue.prototype.$tm(item.title);
                item.fullTitle = item.categoryTitle === null ? item.titleTranslated :
                        item.categoryTitle + " " + item.titleTranslated;
            });

            Array.sort(data, 'fullTitle');
            return data;
        }
        return [];
    },
    withFullTitleTagsAndParents: (state, getters, rootState, rootGetters) => {
        const data = getters.withFullTitle;
        const locationCategoriesIdMap = rootGetters['locationCategories/idMapByGetter']('translated');
        const locationTagsIdMap = rootGetters['locationTags/idMapByGetter']('translated');

        data.forEach((item) => {
            item.fullTitleWithParent = item.fullTitle;
            if (Vue.prototype.$validator.isEmpty(item.locations)) {
                item.parentLocations = null;
            } else {
                item.parentLocations = item.locations.split(',').map(locationId => {
                    const parent = getters.itemById(locationId);

                    if (parent !== null) {
                        parent.categoryTitle = Map.retrieveValue(locationCategoriesIdMap,
                                parent.categoryId, 'titleTranslated');
                        parent.titleTranslated = Vue.prototype.$tm(parent.title);
                        parent.fullTitle = parent.categoryTitle === null ? parent.titleTranslated :
                                `${parent.categoryTitle} ${parent.titleTranslated}`;

                        return parent.fullTitle;
                    }
                });
                if(item.parentLocations?.length > 0) {
                    item.fullTitleWithParent = item.parentLocations[0] + ', ' + item.fullTitle;
                }
            }

            if(Vue.prototype.$validator.isEmpty(item.tags)) {
                item.tagTitles = null;
            } else {
                item.tagTitles = item.tags.split(',').map(tagId => {
                    const tag = Map.retrieve(locationTagsIdMap, tagId);
                    if (tag !== null) {
                        return tag.titleTranslated;
                    }
                });
            }
        });
        return data;

    },

    /**
     * Return locations that are not part of hospitality rooms nor event 'rooms', i.e. floors, buildings, etc.
     */
    nonRoomLocations: (state, getters, rootState, rootGetters) => {
        const hospitalityCategories = rootGetters['hospitalityServiceConfig/configValue']('locationCategories', []);
        const eventCategories = rootGetters['eventServiceConfig/configValue']('locationCategories', []);
        const allCategories = [];
        allCategories.push(hospitalityCategories)
        allCategories.push(eventCategories)
        return getters.withFullTitle.filter(i => !allCategories.includes(i.categoryId));
    },
    // hospitalityLocations: (state, getters, rootState, rootGetters) => {
    //     const categories = rootGetters['hospitalityServiceConfig/configValue']('locationCategories', []);
    //     if (categories.length > 0) {
    //         return getters.withFullTitle.filter(i => categories.includes(i.categoryId));
    //     }
    //     return [];
    // },

    // locationsWithFullTitleFilteredByEventsConfig: (state, getters, rootState, rootGetters) => {
    //     const categories = rootGetters['eventServiceConfig/configValue']('locationCategories', []);
    //     const locationsWithFullTitle = getters.withFullTitle.filter(i => categories.includes(i.categoryId));
    //
    //     return categories.length > 0 ? locationsWithFullTitle : [];
    // },
    // filteredByEventsConfig: (state, getters, rootState, rootGetters) => {
    //     const categories = rootGetters['eventServiceConfig/configValue']('locationCategories', []);
    //
    //     if(categories.length > 0) {
    //         return getters.withFullTitle.filter(i => categories.includes(i.categoryId))
    //     }
    //
    //     return []
    // },

});

export default module;
