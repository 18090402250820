import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import Validator from "@/lib/helpers/Validator";
import apis from '@/lib/api/index';
import moment from "moment";

const thisModule = 'licenses';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    deploymentLicenses: (state, getters, rootState, rootGetters) => {
        const items =  module.cloneItems();
        const deployments = rootGetters['deployments/idMap'];
        return items.filter(i => deployments.hasOwnProperty(i.targetContextId));
    },
    withLimitArray: (state, getters) => (deploymentsOnly = true) => {
        const items =  deploymentsOnly ? getters.deploymentLicenses : module.cloneItems();

        items.forEach((item) => {
            const provisions = item.featureProvisions;
            item.licenseLimits = [];
            provisions.forEach(function (feature) {
                const featureLimits = feature.limits;
                if (Validator.isNotEmpty(featureLimits)) {
                    featureLimits.forEach(function (limit) {
                        item.licenseLimits.push(`${feature.feature}/${limit.limit}: ${limit.value}`);
                    })
                }
            });
        });
        return items;
    },
    withLimitArrayAndProductsAndContexts: (state, getters, rootState, rootGetters) => (deploymentsOnly = true) => {
        const items = getters.withLimitArray(deploymentsOnly);

        const contexts = rootGetters['allContexts'](false);
        const products = rootState.products.items;

        return items.map(function(license) {
            let context;

            if (Validator.isNotEmpty(contexts)) {
                context = contexts.find(function (ctx) {
                    return ctx.id === license.targetContextId
                })
            }
            if (Validator.isEmpty(context)) {
                context = {name: "Unknown"};
            }

            let product;
            if (Validator.isNotEmpty(products)) {
                product = products.find(function (prod) {
                    return prod.id === license.productId
                })
            }
            if (Validator.isEmpty(product)) {
                product = {
                    name: "Unknown",
                    itemId: ""
                };
            }

            return {
                ...license,
                fromDateFormatted: moment.toDisplayFormatDateOnly(license.fromDate),
                toDateFormatted: moment.toDisplayFormatDateOnly(license.toDate),
                contextName: context.name,
                productName: product.name,
                productItemId: product.itemId
            };
        });
    },
    byTargetMap: (state, getters) => {
        const targetMap = {};
        getters.withLimitArrayAndProductsAndContexts().forEach(lic => {
            if (!targetMap.hasOwnProperty(lic.targetContextId)) {
                targetMap[lic.targetContextId] = [];
            }
            targetMap[lic.targetContextId].push(lic);
        });
        return targetMap;
    },
    allByTargetMap: (state, getters) => {
        const targetMap = {};
        getters.withLimitArrayAndProductsAndContexts(false).forEach(lic => {
            if (!targetMap.hasOwnProperty(lic.targetContextId)) {
                targetMap[lic.targetContextId] = [];
            }
            targetMap[lic.targetContextId].push(lic);
        });
        return targetMap;
    },
    /** OPTIMIZATION to have a cached way of getting count based on contextId */
    licenseCountByTargetMap: (state, getters) => {
        const countMap = {};
        const licenses = getters.deploymentLicenses;
        licenses.forEach(license => {
            if(countMap.hasOwnProperty(license.contextId)) {
                countMap[license.contextId]++;
            } else {
                countMap[license.contextId] = 1;
            }
        });
        return countMap;
    }
});

export default module;
