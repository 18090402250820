<template>
    <v-scale-transition>
        <BaseFloatingButton
            icon="keyboard_arrow_up"
            color="primary"
            @buttonClick="toTop(0, 500)"
            :position="$app.desktop ? 'first' : 'third'"
            :small="true"
            v-show="showBackToTop"
        ></BaseFloatingButton>
    </v-scale-transition>
</template>

<script>
    import Vue from 'vue';
    import BaseFloatingButton from "@/components/base/BaseFloatingButton.vue";

    const excludedViews = [
    ];

    export default {
        name: 'TheScroller',
        components: {BaseFloatingButton},
        data() {
            return {
                showBackToTop: false,
                top: 0
            }
        },
        created() {
            Vue.prototype.$scroller = this;

            window.addEventListener('scroll', (e) => {
                this.top = e.target.scrollingElement.scrollTop;
            });
        },
        watch: {
            top(top) {
                this.showBackToTop = top > 1200 && !excludedViews.includes(this.$router.history.current.path);
            }
        },
        methods: {
            toBottom(delay = 0, duration = 400, easing = 'easeInOutCubic') {
                this.down(999999, delay, duration, easing);
            },
            toTop(delay = 0, duration = 400, easing = 'easeInOutCubic') {
                this.up(0, delay, duration, easing);
            },
            down(pixels = 0, delay = 0, duration = 400, easing = 'easeInOutCubic') {
                setTimeout(() => {
                    this.$vuetify.goTo(pixels, {duration: duration, easing: easing});
                }, delay);
            },
            up(pixels = 0, delay = 0, duration = 400, easing = 'easeInOutCubic') {
                setTimeout(() => {
                    this.$vuetify.goTo(pixels, {duration: duration, easing: easing});
                }, delay);
            }
        }
    }
</script>