<template>
    <BaseToolbar
        @search="searchChange"
        ref="toolbar"
        :fixed="false"
        v-if="$app.desktop"
    >
        <template v-slot:primary>
            <v-btn
                    v-if="addButtonText !== ''"
                    color="primary"
                    :elevation="$app.desktop ? 1 : 0"
                    text
                    @click="$emit('uploadItem')"
                    :disabled="disableAddButton"
                    data-testid="fstAddItem"
            >
                <v-icon>add_circle</v-icon>
                &nbsp;{{addButtonText}}
            </v-btn>
            <slot name="primaryButtons"></slot>
        </template>
        <template v-slot:secondary>
            <v-btn
                v-if="showRefresh"
                icon
                @click="$emit('refresh')"
            >
                <v-icon>refresh</v-icon>
            </v-btn>
            <BaseHelpButton
                v-if="helpPath !== ''"
                :path="helpPath"
            ></BaseHelpButton>
        </template>
    </BaseToolbar>
    <BaseTitleHeading
        :back-enabled="false"
        v-else
        :title="$tc('resources.resource', 2)"
        :search-enabled="true"
        :fixed="false"
        @search="searchChange"
    ></BaseTitleHeading>
</template>

<script>
    import BaseToolbar from "../base/BaseToolbar.vue";
    import BaseTitleHeading from "../base/BaseTitleHeading.vue";
    import BaseHelpButton from "@/components/base/BaseHelpButton.vue";

    export default {
        name: "FolderStructureToolbar",
        components: {BaseHelpButton, BaseTitleHeading, BaseToolbar},
        props: {
            folder: {
                required: true
            },
            helpPath: {
                type: String,
                default: ""
            },
            addButtonText: {
                default: "",
                type: String
            },
            showRefresh: {
                default: false,
                type: Boolean
            },
            disableAddButton: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            searchChange(s) {
                this.$emit('search', s);
            },
            clearSearch() {
                if (this.$refs.toolbar !== undefined) this.$refs.toolbar.clearSearch();
            }
        }
    }
</script>