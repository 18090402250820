import apis from '@/lib/api';
import Validator from "@/lib/helpers/Validator";
import ApiHelper from "@/lib/helpers/ApiHelper";
const resCheck = ApiHelper.responseSuccessCheck;

const state = {
    initialized: false,
    fetching: false,
    vendorConfig: null
};

const actions = {
    async fetch({commit, rootState, dispatch}) {

        const result = { success: false, errorMessage: '' };
        if (Validator.isEmpty(rootState.context.selectedContext) || Validator.isEmail(rootState.context.selectedContext.id)) {
            result.errorMessage = 'Context ID not set - unable to get config';
            return result;
        }
        const response = await apis['currentVendorConfiguration'].get('/' + rootState.context.selectedContext.id);

        if (resCheck(response)) {
            if (Validator.isNotEmpty(response.data)) {
                result.success = true;
                commit('setConfig', response.data);
                commit('setInitialized', true);
                return result;
            } else {
                result.errorMessage = "Empty response from server";
                commit('setConfig', null);
                commit('setInitialized', false);
                return result;
            }
        } else {
            result.errorMessage = 'An error occurred - please try again later';
            commit('setConfig', null);
            commit('setInitialized', false);
            console.log('Vendor config load failed: ' + response.status + ' "' + response.message + '"');
        }
        return result;
    },
    reset({commit}) {
        commit('setConfig', null);
        commit('setInitialized', false);
    }
};

const mutations = {
    setConfig(state, config) {
        state.vendorConfig = config;
    },
    setInitialized(state, initialized) {
        state.initialized = initialized;
    },
    setFetching(state) {
        state.fetching = true;
    }
};

const getters = {
    configuration: (state) => {
        return state.vendorConfig;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
