import BaseCrudModule from '@/store/modules/base/BaseCrudModule';

const module = new BaseCrudModule();

module.addGetters({
    fetchAll: (state, getters, rootState) => {
        return [
            {
                "id": null,
                "name": "None",
                "description": "Allow login from all providers"
            },
            {
                "id": "microsoft",
                "name": "Active Directory",
                "description": "Only allow logins through Microsoft Active Directory"
            },
            {
                "id": "google",
                "name": "Google",
                "description": "Only allow logins through Google"
            }
        ];
    }
});

export default module;