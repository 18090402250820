import Validator from './Validator';

export default class Translator {
    static translateMap(map, lang, returnValue = '') {
        if (Validator.isEmpty(map)) {
            return returnValue;
        }

        if (Validator.isEmpty(map[lang])) {
            if (lang !== 'en' && Validator.isNotEmpty(map['en'])) {
                return map['en'];
            }

            for (let key in map) {
                // noinspection JSUnfilteredForInLoop
                if (Validator.isNotEmpty(map[key])) {
                    // noinspection JSUnfilteredForInLoop
                    return map[key];
                }
            }

            return returnValue;
        }

        return map[lang];
    }
}