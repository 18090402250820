<template>
    <v-progress-linear
        class="linear-processing-indicator"
        v-model="processingValue"
        :indeterminate="indeterminate"
        v-show="processingValue > 0 || isProcessing"
        :class="!fixed||'fixed'"
        :style="`${fixed ? `top: ${top}px` : ''}`"
        v-if="($app.desktop || forceType === 'linear') && forceType !== 'circle'"
    ></v-progress-linear>
    <v-progress-circular
        class="circle-processing-indicator"
        v-else
        v-model="processingValue"
        :indeterminate="indeterminate"
        color="primary"
        v-show="processingValue > 0 || isProcessing"
    ></v-progress-circular>
</template>

<script>
    export default {
        name: 'BaseProcessingIndicator',
        props: {
            fixed: {
                default: true,
                type: Boolean
            },
            top: {
                default: 102,
                type: Number
            },
            indeterminate: {
                default: false,
                type: Boolean
            },
            processing: {
                required: true,
                type: Boolean
            },
            forceType: {
                default: '',
                type: String
            }
        },
        data() {
            return {
                processingValue: 0,
                isProcessing: false
            }
        },
        watch: {
            processing(isProcessing) {
                if (this.indeterminate) {
                    this.isProcessing = isProcessing;
                } else {
                    if (isProcessing && this.processingValue === 0) {
                        this.processingInterval = setInterval(() => {
                            this.processingValue += 1;
                        }, 10)
                    } else {
                        clearInterval(this.processingInterval);
                        this.processingValue = 100;

                        setTimeout(() => {
                            this.processingValue = 0;
                        }, 500);
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    .linear-processing-indicator {
        margin: 0;
        height: 5px;

        > div {
            height: 5px;
        }

        .v-progress-linear__background {
            height: 5px !important;
        }
    }

    .linear-processing-indicator.fixed {
        position: fixed;
        width: calc(100vw - 56px);
    }

    .circle-processing-indicator {
        top: 12px;
        right: 12px;
        position: fixed;
    }
</style>