<template>
    <div :style="`height: ${height}px; width: ${width}px;`"></div>
</template>

<script>
    export default {
        name: 'BaseSizedBox',
        props: {
            height: {
                default: 0,
                type: [ Number, String ]
            },
            width: {
                default: 0,
                type: [ Number, String ]
            }
        }
    }
</script>