import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'areas';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    byType: (state, getters) => (type) => {
        const typeLower = type.toLowerCase();
        return getters.sorted.filter(area => {
            return area.type.toLowerCase() === typeLower;
        });
    }
});

export default module;
