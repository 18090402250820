import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './lang/index';
import Translator from "../lib/helpers/Translator";

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages
});

Vue.prototype.$tm = (map, langCode = null) => {
    return Translator.translateMap(map, langCode === null ? Vue.prototype.$app.language.code : langCode);
};

export default i18n;
