import {apiConfigurations} from '@/lib/api/index';

export default {

    data() {
        return {
            resourceTimeouts: {},
            batchTimeout: 2000,
            isSuperuser: false,
            isDomainUser: false,
            batchData: {
                update: {
                    'overviewContexts': {
                        timeout: 60,
                        timer: null,
                        pendingIds: [],
                        firstInstant: false,
                        lastInstantId: null
                    },
                    'clients': {
                        timeout: 5,
                        timer: null,
                        pendingIds: [],
                        firstInstant: true,
                        lastInstantId: null
                    }
                },
                create: {
                    'mediaChannels': {
                        timeout: 8,
                        timer: null,
                        pendingIds: [],
                        firstInstant: false,
                        lastInstantId: null
                    }
                }
            }
        }
    },
    watch: {
        '$store.state.pushChannel.resourceUpdated'({feature, resource, created, updated, deleted}) {
            this.handleResourceUpdated(feature, resource, created, updated, deleted);
        },
        async '$store.state.systems.items'(items) {
            this.isSuperuser = items.length > 0;
            if(this.isSuperuser && this.contextMode === 'home') {
                return Promise.all([
                    this.$store.dispatch('apiAccounts/fetch'),
                    this.$store.dispatch('accounts/fetch')
                ]);
            }
        },
        async '$store.state.domains.items'(items) {

            this.isDomainUser = items.length > 0;

            if (this.isDomainUser) {
                await this.$store.dispatch('systemContextConfigs/fetch');
                if(this.contextMode === 'home') {
                    return Promise.all( [
                        this.$store.dispatch('deletedContexts/fetch'),
                        this.$store.dispatch('duplicateRegisteredClients/fetch'),
                        this.$store.dispatch('apiAccounts/fetch'),
                        this.$store.dispatch('accounts/fetch')
                    ]);
                }
            }
        },
        async '$store.state.auth.isAuthenticated'(authenticated) {
            if (authenticated) {
                /* Stores to ALWAYS load when authenticated */
                return Promise.all([
                    this.$store.dispatch('userAccount/fetch'),
                    this.$store.dispatch('context/loadOverviewContext'),
                    this.$store.dispatch('deployments/fetch'),
                    this.$store.dispatch('themes/fetch'),
                    this.$store.dispatch('sharingContexts/fetch'),
                    this.$store.dispatch('contentProviderContexts/fetch'),
                    //await this.$store.dispatch('legacies/fetch');
                    //await this.$store.dispatch('resellers/fetch');
                    //await this.$store.dispatch('distributors/fetch');
                    this.$store.dispatch('systems/fetch'),
                    this.$store.dispatch('domains/fetch'),
                    this.$store.dispatch('contextTypes/fetch'),
                ]);
            }
        },
        '$store.state.context.selectedContext'(contextId) {
            try {
                for(const operation of Object.keys(this.batchData)) {
                    if(this.batchData.hasOwnProperty(operation)) {
                        for (const store of Object.keys(this.batchData[operation])) {
                            console.log(store);
                            if(this.batchData[operation].hasOwnProperty(store) && this.batchData[operation][store].pendingIds.length > 0) {
                                console.log("RESETTING " + store);
                                this.batchData[operation][store].pendingIds = [];
                            }
                        }
                    }
                }
            } catch(e) {
                console.error(e);
            }
        }
    },

    methods: {
        getAssociatedStore(feature, resource) {

            /* Include feature in check here if there are name-clashes */
            const entry = apiConfigurations.find(api => api.feature === feature && (api.virtualResource ? api.virtualResource === resource : api.resource === resource));
            if(entry) {
                return entry.moduleName ? entry.moduleName : entry.apiName;
            }
            console.error("Could not find " + feature + "/" + resource + " in API definitions... using old mapping");

            // Separate Hospitality/Locations from ContextLocations/Locations
            switch (feature) {
                case 'Hospitality':
                    switch (resource) {
                        case 'Locations':
                            return 'hospitalityLocations';
                        case 'GuestMessages':
                            return 'guestMessages';
                        case 'GuestMessageTemplates':
                            return 'guestMessageTemplates';
                        case 'FeatureConfigurations':
                            return 'hospitalityLocations';
                    }
                    break;
                case 'Pms':
                    switch (resource) {
                        case 'FeatureConfigurations':
                            return 'pmsStatus';
                        case 'Statuses':
                            return 'pmsStatus';
                    }
                    break;
                case "NetworkManagement":
                    switch (resource) {
                        case 'Proxies':
                            return 'networkProxies';
                    }
                    break;
                case "ContextManagement":
                    switch (resource) {
                        case 'SystemContextConfigurations':
                            return 'systemContextConfigs';
                        case 'Deployments':
                            return 'deployments';
                        case 'LegacyContexts':
                            return 'legacies';
                        case 'Themes':
                            return 'themes';
                        case 'Resellers':
                            return 'resellers';
                        case 'Distributors':
                            return 'distributors';
                    }
                    break;
                case "Clients":
                    switch (resource) {
                        case 'InstallCodes':
                            return 'installCodes';
                        case "Clients":
                            return "clients";
                    }
                    break;
                case "LicenseManagement":
                    switch (resource) {
                        case "Licenses":
                            return "licenses";
                    }
                    break;
                case "Overview":
                    switch (resource) {
                        case "Contexts":
                            return "overviewContexts";
                    }
                    break;
                case "Screens":
                    switch (resource) {
                        case "ContentSchedules":
                            return "contentSchedules";
                        case "ScreenContents":
                            return "screenContents";
                        case "CurrentContent":
                            return "currentScreenContents";
                        case "SchedulePlans":
                            return "schedulePlans";
                    }
                    break;
                case "ResourceManagement":
                    switch (resource) {
                        case 'Playlists':
                            return "playlists";
                        case 'PlaylistFolders':
                            return "playlist-folders";
                        case 'Logos':
                            return "logos";
                        case 'LogoFolders':
                            return "logo-folders";
                        case 'WayfindingIcons':
                            return "wayfinding-icons";
                        case 'WayfindingIconFolders':
                            return "wayfinding-icon-folders";
                        case 'Tables':
                            return "tables";
                        case 'TableItems':
                            return "table-items"
                        case 'TableSchemas':
                            return "table-schemas"

                    }
                    break;
                case "StreamAllocator":
                    switch (resource) {
                        case 'StreamDevices':
                            return 'streamDevices';
                        case 'AllocatedStreams':
                            return 'allocatedStreams';
                        case 'FeatureConfigurations':
                            return 'streamAllocatorServiceConfig';
                        case 'AllocationLogEntries':
                            return 'allocationLogEntries'
                    }
                    break;
                case "JobManagement": {
                    switch (resource) {
                        case 'Jobs':
                            return 'jobs'
                    }
                    break;
                }
                case "EventManagement": {
                    switch (resource) {
                        case 'Events':
                            return 'events'
                        case 'FeatureConfigurations':
                            return 'eventServiceConfig'
                        case 'Locations':
                            return 'eventLocations'
                        case 'ScreenWayfindingConfigurations':
                            return 'screenWayfindingConfigurations'
                        case 'Integrations':
                            return 'eventIntegrationConfigurations'
                        case 'IntegrationLocations':
                            return 'integrationLocations'
                        case 'ExternalLocations':
                            return 'externalLocations'
                    }
                    break;
                }
                case "Remote": {
                    switch (resource) {
                        case 'RemoteSessions':
                            return 'virtualRemoteClients'
                    }
                    break;
                }
            }

            // Oldie goldie switch... should really check feature
            switch(resource) {
                case 'Folders': return 'resourceFolders';
                case 'Channels': return 'mediaChannels';
                case 'Clients': return 'clients';
                case 'WakeUps': return 'wakeups';
                case 'ConfigurationSchedules': return 'configSchedules';
                case 'Locations': return 'locations';
                case 'ClientStatuses': return 'clientStatuses';
                case 'Images': return 'images';
                case 'Videos': return 'videos';
                case 'Fonts': return 'fonts';
                case 'Templates': return 'templates';
                case 'TemplateFolders': return 'templateFolders';
                case 'Documents': return 'documents';
                case 'DocumentFolders': return 'documentFolders';
                case 'Guests': return 'guests';
                case 'Screens': return 'screens';
                case 'InteractiveMenuPages': return 'interactiveMenuPages';
                case 'InteractiveMenuPageFolders': return 'interactiveMenuPageFolders';
                case 'CompositeMenuPages': return 'compositeMenuPages';
                case 'CompositeMenuPageFolders': return 'compositeMenuPageFolders';
                case 'ScheduleRuleSets': return 'scheduleRuleSets';
                case 'ScreenConfigurations' : return 'screenSetups';
                case 'MediaChannels': return 'mediaChannels';
                case 'Screenshots': return "";
                case 'RoomAccounts': return 'roomAccounts';
                case 'SharedContexts': return 'sharedContexts';
                case 'Accounts': return 'accounts';
                case 'Roles': return 'roles';
            }
            return ""; /* not supported */
        },

        operationOnIds(data, store, command) {
            if (this.$validator.isNotEmpty(data) && this.$validator.isNotEmpty(data.ids)) {
                for(let i = 0; i < data.ids.length; i++) {
                    const id = data.ids[i];
                    if(this.$validator.isNotEmpty(id)) {
                        console.log("Updating single item: " + id + " in " + store);
                        this.$store.dispatch(store + command, {id: id});
                    } else {
                        console.error("no ID for " + command);
                        console.error(data);
                    }
                }
            }
        },

        handleBatchOperation(operation, store, items) {
            if(!this.batchData.hasOwnProperty(operation) || !this.batchData[operation].hasOwnProperty(store)) {
                console.error("handleBatchOperation: no entry for " + operation + "/" + store);
                return;
            }
            const entry = this.batchData[operation][store];

            /* Allow consecutive single fetches of same id since these are most likely user initiated */
            if (entry.firstInstant && (entry.timer === null || entry.lastInstantId === items.ids[0])) {
                /* Always update first entry instant */
                entry.lastInstantId = items.ids[0];
                console.log("Fetching " + items.ids[0] + " instant");
                this.operationOnIds(items, store, '/fetchSingle');
            } else {
                items.ids.forEach(id => {
                    if (!entry.pendingIds.indexOf(id) >= 0) {
                        //console.log("Adding ID " + id);
                        entry.pendingIds.push(id);
                    } else {
                        console.log("ID " + id + " already pending");
                    }
                });
            }

            if (entry.timer === null) {
                console.log("Start batch timeout " + entry.timeout + " for " + operation + " on " + store);
                entry.timer = setTimeout(() => {
                    if(entry.pendingIds.length > 0) {
                        console.log("Batch updating operation " + operation + " for store " + store);
                        this.$store.dispatch(store + '/fetchMultiple', {ids: entry.pendingIds});
                        entry.pendingIds = [];
                    }
                    entry.timer = null;
                }, entry.timeout * 1000);
            }

        },

        handleResourceUpdated(feature, resource, created, updated, deleted) {

            if(!this.$app.appPrivilegeCheck(this.capitalizeFirstLetter(feature), this.capitalizeFirstLetter(resource))) {
                console.log("No access to updated feature/resource: " + feature + "/" + resource);
                return;
            }

            const store = this.getAssociatedStore(feature, resource);

            if(store === "") { return; }
            const storesToLoadFull = [ 'contentProviderContexts', 'sharedContexts', 'pmsStatus', 'eventServiceConfig'];

            if(storesToLoadFull.indexOf(store) >= 0) {
                this.handleUpdateOnId(store, updated);
            } else {
                if(created && Array.isArray(created.ids) && created.ids.length > 0 && this.batchData.create.hasOwnProperty(store)) {
                    //console.log("Batching update for " + store);
                    this.handleBatchOperation('create', store, created);
                } else {
                    this.operationOnIds(created, store, '/fetchSingle');
                }

                if(updated && Array.isArray(updated.ids) && updated.ids.length > 0 && this.batchData.update.hasOwnProperty(store)) {
                    //console.log("Batching update for " + store);
                    this.handleBatchOperation('update', store, updated);
                } else {
                    this.operationOnIds(updated, store, '/fetchSingle');
                }

                this.operationOnIds(deleted, store, '/deleteLocal');
                //console.log(`default resource handler received data set changed message for ${feature}/${resource}`);
            }

            /**
             * The system does not yet support sending the data via the push channel
             */
            if (typeof created === 'object') {
                if (this.$validator.isNotEmpty(created.items)) {
                    for(let i = 0; i < created.items.length; i++) {
                        const id = created.items[i].id;
                        if(this.$validator.isNotEmpty(id)) {
                            console.log("Creating new item in " + store);
                            this.$store.dispatch(store + '/createLocal', {item: created.items[i]});
                        } else {
                            console.error("Got CREATED item with no ID!");
                            console.error(created.items[i]);
                        }
                    }
                }
            }
            if (typeof updated === 'object') {
                if (this.$validator.isNotEmpty(updated.items)) {
                    for(let i = 0; i < updated.items.length; i++) {
                        const id = updated.items[i].id;
                        if(this.$validator.isNotEmpty(id)) {
                            console.log("Updating item in " + store);
                            this.$store.dispatch(store + '/updateLocal', {item: updated.items[i]});
                        } else {
                            console.error("Got UPDATED item with no ID!");
                            console.error(updated.items[i]);
                        }
                    }
                }
            }
        },

        /**
         * Old fallback for reloading full store
         * @param store
         * @param resource
         */
        handleUpdateOnId(store, resource) {
            if(this.$validator.isEmpty(store) || this.$validator.isEmpty(resource)) {
                console.error("handleUpdateOnId: invalid params");
                return;
            }
            if(this.$validator.isNotEmpty(this.resourceTimeouts[resource])) {
                clearTimeout(this.resourceTimeouts[resource]);
            }
            this.resourceTimeouts[resource] = setTimeout(() => {
                this.resourceTimeouts[resource] = null;
                console.log("Refreshing store " + store);
                this.$store.dispatch(store + '/fetch', {ignoreStale: true});
            }, this.batchTimeout);
        },
        capitalizeFirstLetter(s) {
            return s.charAt(0).toUpperCase() + s.slice(1);
        },
        hasAccessToModule(name) {
            const whitelist = [ 'languageInfo', 'roles' ];
            if(whitelist.indexOf(name) !== -1) {
                return true;
            }
            const entry = apiConfigurations.find(api => api.moduleName ? api.moduleName === name : api.apiName === name);
            if(entry) {
                //console.log("Found " + name + " feature: " + entry.feature + " resource: " + entry.resource);
                return this.$app.appPrivilegeCheck(entry.feature, entry.resource);
            }
            console.error("Module access - could not find " + name);
            return true;
        },
        autoLoadStores() {
            let checkAccess = true; /* Not checking access for HOME */
            let modulesToLoad = [];
            switch(this.contextMode) {
                case 'theme':
                    modulesToLoad = [
                        'locations',
                        'wakeups',
                        'guestMessages',
                        'guestMessageTemplates',
                        'clients',
                        'images',
                        'fonts',
                        'videos',
                        'templates',
                        'templateFolders',
                        'documents',
                        'documentFolders',
                        'resourceFolders',
                        'templateSources',
                        'interactiveMenuPageFolders',
                        'compositeMenuPageFolders',
                        'screenSetups',
                        'channelTables',
                        'guests',
                        'mediaChannels',
                        'globalChannels',
                        'roomAccounts',
                        'interactiveMenuPages',
                        'compositeMenuPages',
                        'installCodes',
                        'signageServiceConfig',
                        'hospitalityLocations',
                        'clientServiceConfig',
                        'hospitalityServiceConfig',
                        'roles',
                        'privilegeSets',
                        'contextAccess',
                        'locationTags',
                        'locationCategories',
                        'screens',
                        'languageInfo',
                        'snapshots',
                        'owners',
                        'areas',
                        'menuLists',
                        'pmsServiceConfig',
                        'playlists',
                        'playlistFolders',
                        'allocationLogEntries',
                        'jobs',
                        'screenWayfindingConfigurations',
                        'eventLocations',
                        'eventServiceConfig',
                        'logos',
                        'logoFolders',
                        'wayfindingIcons',
                        'wayfindingIconFolders',
                        'eventIntegrations',
                        'eventIntegrationConfigurations',
                        'integrationLocations',
                        'externalLocations',
                        'sharedContexts',
                        'virtualRemoteClients',
                        'contentSchedules',
                        'currentScreenContents',
                        'tables',
                        'tableItems',
                        'tableSchemas',
                        'tablesConfigurations'
                    ];
                    break;
                case 'site':
                    modulesToLoad = [
                        'locations',
                        'wakeups',
                        'guestMessages',
                        'guestMessageTemplates',
                        'clients',
                        'currentScreenContents',
                        'images',
                        'fonts',
                        'videos',
                        'templates',
                        'templateFolders',
                        'documents',
                        'documentFolders',
                        'resourceFolders',
                        'templateSources',
                        'interactiveMenuPageFolders',
                        'compositeMenuPageFolders',
                        'screenSetups',
                        'channelTables',
                        'guests',
                        'mediaChannels',
                        'globalChannels',
                        'roomAccounts',
                        'interactiveMenuPages',
                        'compositeMenuPages',
                        'configSchedules',
                        'contentSchedules',
                        'installCodes',
                        'signageServiceConfig',
                        'clientServiceConfig',
                        'hospitalityLocations',
                        'hospitalityServiceConfig',
                        'pmsServiceConfig',
                        'pmsStatus',
                        'roles',
                        'privilegeSets',
                        'contextAccess',
                        'locationTags',
                        'locationCategories',
                        'currentVendorConfiguration',
                        'screens',
                        'languageInfo',
                        'scheduleRuleSets',
                        'menuLists',
                        'networkProxies',
                        'screenContents',
                        'registeredCasts',
                        'allocatedStreams',
                        'streamDevices',
                        'streamAllocatorServiceConfig',
                        'playlists',
                        'playlistFolders',
                        'allocationLogEntries',
                        'jobs',
                        'logos',
                        'logoFolders',
                        'wayfindingIcons',
                        'wayfindingIconFolders',
                        'screenWayfindingConfigurations',
                        'eventLocations',
                        'eventServiceConfig',
                        'eventIntegrations',
                        'eventIntegrationConfigurations',
                        'integrationLocations',
                        'externalLocations',
                        'sharedContexts',
                        'schedulePlans',
                        'virtualRemoteClients',
                        'tables',
                        'tableItems',
                        'tableSchemas',
                        'tablesConfigurations'
                    ];
                    break;
                case 'sharing':
                    modulesToLoad = [
                        'logos',
                        'logoFolders',
                        'fonts',
                        'images',
                        'videos',
                        'jobs',
                        'resourceFolders',
                        'wayfindingIcons',
                        'wayfindingIconFolders'
                    ];
                    break;
                case 'content provider':
                    modulesToLoad = [
                        'locations',
                        'wakeups',
                        'guestMessages',
                        'guestMessageTemplates',
                        'clients',
                        'images',
                        'fonts',
                        'videos',
                        'templates',
                        'templateFolders',
                        'documents',
                        'documentFolders',
                        'resourceFolders',
                        'templateSources',
                        'interactiveMenuPageFolders',
                        'compositeMenuPageFolders',
                        'screenSetups',
                        'channelTables',
                        'guests',
                        'mediaChannels',
                        'globalChannels',
                        'roomAccounts',
                        'interactiveMenuPages',
                        'compositeMenuPages',
                        'installCodes',
                        'signageServiceConfig',
                        'hospitalityLocations',
                        'clientServiceConfig',
                        'hospitalityServiceConfig',
                        'roles',
                        'privilegeSets',
                        'contextAccess',
                        'locationTags',
                        'locationCategories',
                        'screens',
                        'languageInfo',
                        'snapshots',
                        'owners',
                        'areas',
                        'menuLists',
                        'pmsServiceConfig',
                        'playlists',
                        'playlistFolders',
                        'allocationLogEntries',
                        'jobs',
                        'screenWayfindingConfigurations',
                        'eventLocations',
                        'eventServiceConfig',
                        'logos',
                        'logoFolders',
                        'wayfindingIcons',
                        'wayfindingIconFolders',
                        'eventIntegrations',
                        'eventIntegrationConfigurations',
                        'integrationLocations',
                        'externalLocations',
                        'sharedContexts',
                        'virtualRemoteClients',
                        'contentSchedules',
                        'currentScreenContents',
                        'tables',
                        'tableItems',
                        'tableSchemas',
                        'tablesConfigurations'
                    ];
                    break;
                default:
                    checkAccess = false;
                    modulesToLoad = [
                        'overviewContexts',
                        'licenses',
                        'products',
                        'legacies',
                        'resellers',
                        'distributors',
                        'owners',
                        'areas',
                        'timeZones',
                        'roles',
                        'privilegeSets',
                        'features',
                        'systemConfig',
                        'domains',
                        'sharingContexts',
                        'contentProviderContexts',
                    ];

                    if(this.isSuperuser || this.isDomainUser) {
                        modulesToLoad.push('deletedContexts');
                        modulesToLoad.push('duplicateRegisteredClients');
                        modulesToLoad.push('accounts');
                        modulesToLoad.push('apiAccounts');
                    }
            }

            for (const module of modulesToLoad) {
                if(!checkAccess || this.hasAccessToModule(module)) {
                    this.$store.dispatch(module + '/fetch', {ignoreStale: true});
                } else {
                    //console.log("No access to " + module);
                }
            }
        },

        resetStores() {
            const modulesToIgnore = [
                'auth',
                'systemContextConfigs',
                'context',
                'userAccount',
                'overviewContexts',
                'vendorConfigurations',
                'deployments',
                'themes',
                'resellers',
                'distributors',
                'legacies',
                'systems',
                'contextTypes',
                'sharingContexts',
                'contentProviderContexts',
            ];

            for (let moduleNameSpace in this.$store._modulesNamespaceMap) {
                const moduleName = moduleNameSpace.replace('/', '');

                if (!modulesToIgnore.includes(moduleName)) {
                    //console.log("RESETTING " + moduleName)
                    this.$store.dispatch(`${moduleName}/reset`);
                }
            }
        },

    }

}
