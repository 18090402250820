<template>
    <BaseActionMenu
        v-model="showMenu"
        :title="$validator.isNotEmpty(folder) ? folder.nameTranslated : $t('generic.actions')"
    >
        <template v-slot:actions>
            <slot name="gridActions"></slot>
            <BaseActionItem
                v-if="createEnabled"
                :title="$tc('generic.newWithType', 1, {type: itemType})"
                icon="add"
                color="primary"
                @itemClick="create"
            ></BaseActionItem>
            <BaseActionItem
                :title="$t('folder.newFolder')"
                icon="create_new_folder"
                color="primary"
                @itemClick="$emit('createFolder')"
            ></BaseActionItem>
            <v-menu
                v-if="$validator.isNotEmpty(folder)"
                offset-x
                open-on-hover
            >
                <template v-slot:activator="{on}">
                    <v-list-item v-on="on" @click.stop>
                        <v-list-item-action>
                            <v-icon color="primary">mdi-folder</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>{{ $t('folder.moveToFolder') }}</v-list-item-title>
                        <v-icon>chevron_right</v-icon>
                    </v-list-item>
                </template>
                <v-list>
                    <BaseActionItem
                        v-for="folderItem in foldersAvailableForMove(folder)"
                        :key="folderItem.id"
                        :title="folderPath(folderItem)"
                        icon="mdi-folder-download-outline"
                        color="primary"
                        :item="folderItem"
                        @itemClick="$emit('moveFolder', { folder: folder, dst: folderItem})"
                    ></BaseActionItem>
                </v-list>
            </v-menu>
            <BaseActionItem
                v-if="itemInClipboard"
                :title="$t('folder.paste')"
                icon="note_add"
                color="primary"
                @itemClick="$emit('paste')"
            ></BaseActionItem>
            <BaseActionItem
                v-if="$validator.isNotEmpty(folder)"
                :title="$t('folder.editFolder')"
                icon="mdi-delete-outline"
                color="primary"
                @itemClick="$emit('editFolder', folder)"
            ></BaseActionItem>
            <BaseActionItem
                v-if="$validator.isNotEmpty(folder)"
                :title="$t('folder.deleteFolder')"
                icon="mdi-delete-outline"
                color="error"
                @itemClick="$emit('deleteFolder', folder)"
            ></BaseActionItem>
        </template>
    </BaseActionMenu>
</template>

<script>
    import BaseActionMenu from "../base/action/BaseActionMenu.vue";
    import BaseActionItem from "../base/action/BaseActionItem.vue";

    export default {
        name: "FolderStructureGridActions",
        components: {BaseActionItem, BaseActionMenu},
        props: {
            value: Boolean,
            itemInClipboard: Boolean,
            itemType: String,
            createEnabled: {
                required: true,
                type: Boolean
            },
            folderModule: {
                required: true,
                type: String
            },
            folder: {
                required: true
            }
        },
        computed: {
            showMenu: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            },
            folderModuleTranslated() {
                return this.$store.getters[this.folderModule + '/translated'];
            }
        },
        methods: {
            create() {
                this.showMenu = false;
                this.$emit('create', this.folder);
            },
            foldersAvailableForMove() {
                const all = [{nameTranslated: 'Root', id: null}].concat(this.folderModuleTranslated);
                if(!this.folder || !this.folderModule) {
                    return all;
                }
                const item = this.$store.getters[this.folderModule + '/itemById'](this.folder.id);
                let list = all.filter( dst => {
                    if(item !== null){
                        return (dst.id !== item.id && dst.id !== item.parent);
                    }
                    return null
                });

                const removeTree = (node, list, first) => {
                    let leaves = list.filter( i => { return (i.parent === node.id); });
                    leaves.forEach( l => {
                        removeTree(l, list, false);
                    });
                    if(!first) {
                        Array.remove(list, node);
                    }
                };

                removeTree(item, list, true);

                return list;
            },
            folderPath(baseFolder){

                if(!baseFolder){
                    return
                }

                let nameWithPath = baseFolder.nameTranslated

                let item = baseFolder;
                while(item && this.$validator.isNotEmpty(item.parent)){
                    item = this.$store.getters[this.folderModule + '/itemById'](item.parent);
                    if(item){
                        nameWithPath = item.name + "/" + nameWithPath;
                    }
                }

                if(nameWithPath.length > 100){
                    return '...' + nameWithPath.substring(nameWithPath.length-100, nameWithPath.length)
                }
                else{
                    return nameWithPath
                }
            }
        }
    }
</script>