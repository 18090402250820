import languages from '@/i18n/languages';
import {mapActions} from "vuex";
import PortalTheme from "@/lib/theme/PortalTheme";

// Mixin for handling app state
// NOTE: Only App.vue should use this
export default {
    data() {
        return {
            drawer: false,
            darkMode: false,
            pinMenu: true,
            notificationMode: 'all',
            language: null,
            amPmDisplay: false,
            mouseY: 0,
            mouseX: 0,
            height: 0,
            width: 0,
            shiftPressed: false,
            ctrlPressed: false,
            vPressed: false,
            cPressed: false,
            ctrlCPressed: false,
            ctrlVPressed: false,
            desktop: true,
            tablet: false,
            native: false,
            loadedFonts: [],
            languages: languages,
            innerHeight: window.innerHeight,
            persistSettings: false,
            portalThemes: PortalTheme.availableThemes,
            activePortalTheme: PortalTheme.availableThemes.THEME_DEFAULT
        }
    },
    created() {
        const langCode = this.$store.state.userAccount.account.settings.language;
        this.language = Array.findFirstByField(languages, 'code', langCode !== null ? langCode : this.$i18n.locale);
        if (this.language === null) {
            this.language = languages[0];
        }
        this.notificationMode = this.$storage.get('notificationMode', 'all');
        if(PortalTheme.getTheme() === PortalTheme.availableThemes.THEME_PPDS) {
            this.darkMode = false;
        } else {
            this.darkMode = this.$store.state.userAccount.account.settings.darkMode === true;
        }

        this.native = location.href.includes('?device=native');
        this.pinMenu = this.$storage.get('pinnedMenu') !== 'false';

        window.addEventListener('mousemove', (e) => {
            this.mouseX = e.clientX;
            this.mouseY = e.clientY;
        });

        window.addEventListener('resize', this.onResize);

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') this.shiftPressed = true;
            if (e.key === 'Control') this.ctrlPressed = true;
            if (e.key === 'c') this.cPressed = true;
            if (e.key === 'v') this.vPressed = true;

            if (this.cPressed && this.ctrlPressed) this.ctrlCPressed = true;
            if (this.vPressed && this.ctrlPressed) this.ctrlVPressed = true;

            this.$emit('keypress', { key: e.key, ctrl: e.ctrlKey, alt: e.altKey })
        });

        window.addEventListener('visibilitychange', () => {
            if (document.visibilityState !== 'visible') {
                this.cPressed = false;
                this.vPressed = false;
                this.shiftPressed = false;
                this.ctrlPressed = false;
                this.ctrlCPressed = false;
                this.ctrlVPressed = false;
            }
        });

        window.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') this.shiftPressed = false;

            if (e.key === 'Control') {
                this.ctrlPressed = false;
                this.ctrlCPressed = false;
                this.ctrlVPressed = false;
            }

            if (e.key === 'v') {
                this.vPressed = false;
                this.ctrlVPressed = false;
            }

            if (e.key === 'c') {
                this.cPressed = false;
                this.ctrlCPressed = false;
            }
        });

        this.deviceCheck();
        this.persistSettings = true;

        this.activePortalTheme = PortalTheme.getTheme();
    },
    computed: {
        appClasses() {
            let appClasses = this.desktop ? 'desktop' : 'mobile';

            if (this.native) appClasses += ' native';
            else appClasses += ' web';

            if (this.drawer) appClasses += ' drawer-open';
            if (this.tablet) appClasses += ' tablet';

            appClasses += ` ${this.contextMode !== 'login' ? 'logged-in' : 'logged-out'}`;

            if (this.activePortalTheme === PortalTheme.availableThemes.THEME_PPDS) {
                appClasses += ' ppds-theme';
            }

            if (this.activePortalTheme === PortalTheme.availableThemes.THEME_UNIGUEST) {
                appClasses += ' uniguest-theme';
            }

            return appClasses;
        },
        loadingContentClass() {
            switch(this.activePortalTheme) {
                case PortalTheme.availableThemes.THEME_PPDS:
                    return 'ppds-loading-content'
                case PortalTheme.availableThemes.THEME_UNIGUEST:
                    return 'uniguest-loading-content'
                default:
                    return 'loading-content'
            }
        },
    },
    watch: {
        language(language) {
            this.$i18n.locale = language.code;
            if(!this.persistSettings) { return; }
            this.saveUserSetting({ key: 'language', val: language.code });
        },
        darkMode(dark) {
            this.$vuetify.theme.dark = dark;
            if(!this.persistSettings) { return; }
            this.saveUserSetting({ key: 'darkMode', val: dark });
        },
        pinMenu(pin) {
            /**
             * Due to what appears to be a bug in vues update of v-main we need to do this
             */
            this.$storage.set('pinnedMenu', pin);
        },
        notificationMode(mode) {
            if(!this.persistSettings) { return; }
            this.saveUserSetting({ key: 'notifications', val: (typeof mode === 'string') ? mode : 'all' });
        },
        amPmDisplay(toggle) {
            this.saveUserSetting({ key: 'amPmDisplay', val: toggle });
        },
        '$store.state.userAccount.initialized'(val) {
            if(val === true) {
                this.persistSettings = false;
                if (this.activePortalTheme === PortalTheme.availableThemes.THEME_PPDS) {
                    this.darkMode = false;
                } else {
                    this.darkMode = this.$store.state.userAccount.account.settings.darkMode === true;
                }

                this.amPmDisplay = this.$store.state.userAccount.account.settings.amPmDisplay;
                this.$moment.amPmDisplay = this.$store.state.userAccount.account.settings.amPmDisplay;

                this.language = Array.findFirstByField(languages, 'code', this.$store.state.userAccount.account.settings.language !== null ? this.$store.state.userAccount.account.settings.language : this.$i18n.locale);
                if (this.language === null) {
                    this.language = languages[0];
                }
                this.$i18n.locale = this.language.code;

                this.notificationMode = this.$store.state.userAccount.account.settings.notifications;
                this.persistSettings = true;
            }
        },
        '$store.state.fonts.items'(items) {
            if(items && Array.isArray(items)) {
                items.forEach(f => {
                    if (!this.loadedFonts.includes('font-' + f.id)) {
                        if (f.filename) {
                            const fontFace = new FontFace('font-' + f.id, `url(${f.fullUrl})`);

                            this.loadedFonts.push('font-' + f.id);

                            fontFace.load().then((loadedFace) => {
                                document.fonts.add(loadedFace);
                            }).catch(() => {
                                // Do nothing
                            })
                        }
                    }
                });
            }
        }
    },
    methods: {
        ...mapActions({
            saveUserSetting: 'userAccount/saveSetting'
        }),
        onResize() {
            this.innerHeight = window.innerHeight;
            this.deviceCheck();
        },
        deviceCheck() {
            // TODO improve device check - only ipad works for tablets

            this.height = window.innerHeight;
            this.width = window.innerWidth;

            if (/Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                this.tablet = false;
                this.desktop = false;
            } else if (/(iPad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent)) {
                const tablet = window.innerHeight < window.innerWidth;
                this.tablet = tablet;
                this.desktop = tablet;
            } else {
                if (!this.desktop) {
                    setTimeout(() => {
                        this.$app.drawer = true;
                    }, 500);
                }

                this.desktop = true;
                this.tablet = false;
            }
        },
        getFlagCode(lang) {
            if(this.portalThemeActive(this.portalThemes.THEME_UNIGUEST)) {
                switch (lang) {
                    case 'en':
                        return 'us';
                }
            }
            return lang;
        },
        getPortalTheme() {
            return this.activePortalTheme;
        },
        portalThemeActive(activeTheme) {
            return activeTheme === this.activePortalTheme;
        }
    }
}
