import English from './lang/en/translations';
import French from './lang/fr/translations';
import Spanish from './lang/es/translations';
import German from './lang/de/translations';
import Norwegian from './lang/no/translations';

const DumpMissingTranslations = function () {

    let languages = {
        "French": French,
        "Spanish": Spanish,
        "German": German,
        "Norwegian": Norwegian
    };

    let missingTranslations = {
        "French": {},
        "Spanish": {},
        "German": {},
        "Norwegian": {}
    };

    let textStringsTotal = 0;
    let textStringsMissingTranslation = 0;

    for (const category in English) {

        for (const textString in English[category]) {

            for (const language in languages) {

                textStringsTotal++;

                if (!missingTranslations[language][category]) {
                    missingTranslations[language][category] = {}
                }

                if (!languages[language][category] || !languages[language][category][textString]) {
                    missingTranslations[language][category][textString] = "";
                    textStringsMissingTranslation++;
                }
                else {
                    missingTranslations[language][category][textString] = languages[language][category][textString];
                }

            }
        }
    }

    let textStringsCompleted = (textStringsTotal - textStringsMissingTranslation);
    let progress = Math.round((textStringsCompleted / textStringsTotal) * 100)

    console.log(missingTranslations);
    console.log('Translation progress: ' + progress + '% (' + textStringsMissingTranslation + ' of ' + textStringsTotal + ' strings remaining).');
    return missingTranslations;
}

export default DumpMissingTranslations
