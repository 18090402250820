import BaseListHeaderModule from "@/store/modules/base/BaseListHeaderModule";

const module = new BaseListHeaderModule();

module.setListId('homeSiteHeadersPPDS');

module.setListHeaders({
    'maxSignageLic': {
        text: 'Signage Licences',
        value: 'maxSignageLic',
        initialEnabled: false
    },
    'facId': {
        text: 'Facility ID',
        value: 'facId',
        initialEnabled: false,
        searchable: true
    },
    'licenses': {
        text: 'Licenses',
        value: 'licenses',
        convertToString: (item) => {
            return item['licenses'].join(', ');
        },
        initialEnabled: false,
        searchable: true
    },
    'numberOfSigClients': {
        text: 'Signage Screens',
        value: 'numberOfSigClients',
        initialEnabled: true
    },
    'brandName': {
        text: 'Brand',
        convertToString: (item) => {
            return item['brandName'];
        },
        value: 'brandName',
        initialEnabled: false,
        searchable: true
    },
    'chainName': {
        text: 'Chain',
        convertToString: (item) => {
            return item['chainName'];
        },
        value: 'chainName',
        initialEnabled: false,
        searchable: true
    },
    'resellerName': {
        text: 'Reseller',
        value: 'resellerName',
        initialEnabled: true,
        searchable: true
    },
    'ctry': {text: 'Country', value: 'ctry', initialEnabled: false, searchable: true},
    'demo': {text: 'Demo', value: 'demo', initialEnabled: false},
    'activationDate': {
        text: 'Act. Date',
        value: 'activationDate',
        initialEnabled: false,
        searchable: true
    },
});

export default module;