import Moment from 'moment';
import {extendMoment} from 'moment-range';

const moment = extendMoment(Moment);

moment.toDisplayFormat = (date, precise = true, amPmDisplay) => {
    const now = moment();
    const mdate = moment(date);

    if(!mdate.isValid()) {
        return "-";
    }

    const timeFormat = amPmDisplay ? 'h:mm A' : 'HH:mm'

    let daysBetween = now.diff(mdate, 'days');

    if (daysBetween === 0 && mdate.date() !== now.date()) {
        daysBetween++;
    }

    if (daysBetween === 0) {
        return precise ? `${mdate.format(timeFormat)} today` : 'Today';
    } else if (daysBetween === 1) {
        return precise ? `Yesterday at ${mdate.format(timeFormat)}` : 'Yesterday';
    } else if (daysBetween < 8) {
        return precise ? mdate.format(`dddd, ${timeFormat}`) : mdate.format('dddd');
    } else if (daysBetween < 365) {
        return precise ? mdate.format(`Do MMM ${timeFormat}`) : `${daysBetween} days ago`;
    } else {
        return precise ? mdate.format(`Do MMM, YYYY ${timeFormat}`) : 'More than 1 year ago';
    }
};

moment.toDisplayFormatDateOnly = (date) => {
    const mdate = moment(date);
    if(!mdate.isValid()) {
        return "-";
    }
    return mdate.format('Do MMM, YYYY');
};

moment.formatTimeDisplay = (time, amPmDisplay) => {
    return amPmDisplay ? moment(time).format("h:mm A") : moment(time).format("HH:mm")
}

moment.formatDateTimeDisplay = (time, format) => {

    return moment.amPmDisplay ? moment(time).format(format ? format : "MM-DD-yyyy h:mm A") : moment(time).format(format ? format : "DD/MM/yyyy HH:mm")
}

moment.from24To12HourTimeFormat = (time) => {
    return moment(time, "HH:mm").format("h:mm A");
};

moment.from12to24HourTimeFormat = (time) => {
    return moment(time, "h:mm A").format("HH:mm");
}

export default moment;