<!-- CMP height = 107 with search, 61 without -->

<template>
    <v-layout
        column
        class="title-heading"
        :class="!fixed||'fixed'"
    >
        <v-flex class="title">
            <v-row>
                <v-col class="shrink pa-0" v-if="backEnabled">
                    <v-btn
                        icon
                        @click="$emit('back')"
                    >
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                </v-col>
                <v-col :class="!backEnabled || 'pa-0'">
                    <v-toolbar-title>{{title}}</v-toolbar-title>
                </v-col>
            </v-row>
        </v-flex>
        <v-flex
            class="search"
            v-if="searchEnabled"
        >
            <SearchField
                @change="searchChange"
                :delayChangeEvent="true"
            ></SearchField>
        </v-flex>
    </v-layout>
</template>

<script>
    import SearchField from "@/components/input/SearchField.vue";

    export default {
        name: 'BaseTitleHeading',
        components: {SearchField},
        props: {
            searchEnabled: {
                default: false,
                type: Boolean
            },
            title: String,
            backEnabled: {
                default: false,
                type: Boolean
            },
            fixed: {
                default: false,
                type: Boolean
            }
        },
        methods: {
            searchChange(text) {
                this.$emit('search', text);
            }
        }
    }
</script>

<style lang="scss">
    .title-heading {
        padding: 16px 16px 12px 16px;
        background-color: var(--v-background-base);
        width: 100%;

        .title {
            height: 36px;

            .v-toolbar__title {
                height: 36px;
                line-height: 35px;
                padding-left: 5px;
            }
        }

        .search {
            padding-top: 8px;

            .search-field {
                min-width: 100%;
            }

            .v-input {
                margin: 0 !important;
            }
        }
    }

    .title-heading.fixed {
        position: fixed;
        top: 0;
    }
</style>