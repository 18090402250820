import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const module = new BaseCrudModule(apis.virtualRemoteClients);

module.addGetters({

    activeSessionsOnlyWithClientNames: (state, getters, rootState, rootGetters) => {
        let data = module.cloneItems().map(obj => ({ ...obj, name: 'Unknown', room: 'Unknown' }));

        if(Array.isArray(data)) {

            data = data.filter( it => {
                return it.remoteClientId != null
            });

            const clients = rootGetters['clients/withProcessedStatusAndOccupancy'];

            for (let i = 0; i < data.length; i++) {
                clients.forEach(client => {
                    if (client.id === data[i].screenClientId) {
                        data[i].name = client.name;
                        data[i].room = client.locationTitle;
                        data[i].screenClientOnline = client.online;
                    }
                })
            }
            Array.sort(data, 'room', true);
            return data;
        }
        return null;
    }

});

export default module;