export default class Resources {

    /**
     * This function allows us to quickly check if a video has a 4K resolution across multiple areas of the code.
     * @param width - The width of the video to compare
     * @param height - The height of the video to compare
     * @returns {boolean} - True if either width or height are above standard HD resolution sizing.
     */
    static isFile4kResolution(width, height) {
        return (width >= 3840 || height >= 2160)
    }

}