import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from 'vue';

const thisModule = 'templateSources';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withDeleteInfo: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        data.forEach((item) => {
            item.canBeDeleted = item.contextId === rootState.context.selectedContext.id;
            item.canBeEdited = item.contextId === rootState.context.selectedContext.id;
        });
        return data;
    }
});

export default module;