import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Vue from 'vue';

const thisModule = 'roomAccounts';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withLocation: (state, getters, rootState, rootGetters) => {
        const data = [];
        const locationsIdMap = rootGetters['hospitalityLocations/idMapByGetter']('withLocation');

        if (Array.isArray(state.items)) {
            state.items.forEach((item) => {

                if(locationsIdMap.hasOwnProperty(item.locationId)) {
                    const clonedItem = Vue.prototype.$clone(item);
                    clonedItem.locationTitle = locationsIdMap[item.locationId].fullTitle;
                    data.push(clonedItem);
                }
            });
            Array.sort(data, 'locationTitle', true);
        }
        return data;
    }
});

export default module;
