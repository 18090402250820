import ApiHelper from '@/lib/helpers/ApiHelper';
import moment from 'moment';
import Vue from 'vue';

const resCheck = ApiHelper.responseSuccessCheck;

class BaseServiceConfigModule {
    constructor(api) {
        this.namespaced = true;
        this.state = {
            processing: false,
            fetched: null,
            error: null,
            consecutiveErrorCnt: 0,
            config: {}
        };

        this.getters = {
            configValue: (state, getters) => (valueName, nullValue = null) => {
                const config = state.config;

                if (Vue.prototype.$validator.isEmpty(config)) {
                    return nullValue;
                }

                return Vue.prototype.$validator.isEmpty(config[valueName]) ? nullValue : config[valueName];
            }
        };

        this.actions = {
            fetchSingle({commit}, {id, pathExtension = '', requestConfig = {}} = {}) {
                /* For compatibility */
                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.get(pathExtension, requestConfig);
                    commit(resCheck(response) ? 'fetchSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            fetch({commit}, {pathExtension = null, requestConfig = {}} = {}) {
                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.get(pathExtension, requestConfig);
                    commit(resCheck(response) ? 'fetchSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            update({commit}, data) {
                const {item, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.put(item, item.id, requestConfig);
                    commit(resCheck(response) ? 'updateSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            reset({commit}) {
                commit('resetModule');
            }
        };

        this.mutations = {
            error(state, error) {
                state.processing = false;
                state.error = error;
                state.consecutiveErrorCnt++;
                //Vue.prototype.$notification.show('error', error);
            },
            processing(state) {
                state.processing = true;
            },
            fetchSuccess(state, config) {
                state.processing = false;
                state.fetched = moment();
                state.config = config;
                state.consecutiveErrorCnt = 0;
            },
            updateSuccess(state, config) {
                state.config = config;
                state.processing = false;
                state.consecutiveErrorCnt = 0;
            },
            resetModule(state) {
                state.processing = false;
                state.config = {};
                state.fetched = null;
                state.consecutiveErrorCnt = 0;
                state.error = null;
            }
        };
    }
}

export default BaseServiceConfigModule;
