import apis from '@/lib/api';
import languageList from '@/i18n/languages';
import Validator from "@/lib/helpers/Validator";
import ApiHelper from "@/lib/helpers/ApiHelper";
import Vue from 'vue';

const resCheck = ApiHelper.responseSuccessCheck;

const state = {
    initialized: false,
    fetching: false,
    languages: [],
    defaultLanguage: 'en'
};

const actions = {
    async fetch({commit, rootState, dispatch}) {

        const result = { success: false, errorMessage: '' };
        if (Validator.isEmpty(rootState.context.selectedContext) || Validator.isEmail(rootState.context.selectedContext.id)) {
            result.errorMessage = 'Context ID not set - unable to get languages';
            return result;
        }

        let apiEndpoint;

        switch (rootState.context.selectedContext.contextType) {
            case 'Deployment':
                apiEndpoint = 'languageInfoDeployment';
                break;
            case 'Theme':
                apiEndpoint = 'languageInfoTheme';
                break;
            case 'Content Provider':
                apiEndpoint = 'languageInfoContentProvider';
                break;
            default:
                throw new Error(`Unknown context type: ${rootState.context.selectedContext.contextType}`);
        }

        const response = await apis[apiEndpoint].get('', {}, rootState.context.selectedContext.id);

        if (resCheck(response)) {
            if (Validator.isNotEmpty(response.data)) {
                result.success = true;
                commit('setLanguages', response.data);
                commit('setDefaultLanguage', Array.findFirstByField(response.data, 'isDefault', true));
                commit('setInitialized', true);
                return result;
            } else {
                result.errorMessage = "Empty response from server";
                commit('setLanguages', []);
                commit('setDefaultLanguage', 'en');
                commit('setInitialized', false);
                return result;
            }
        } else {
            result.errorMessage = 'An error occurred - please try again later';
            commit('setLanguages', []);
            commit('setDefaultLanguage', 'en');
            console.log('Vendor config load failed: ' + response.status + ' "' + response.message + '"');
        }
        return result;
    },
    reset({commit}) {
        commit('setLanguages', []);
        commit('setDefaultLanguage', 'en');
        commit('setInitialized', false);
    }
};

const mutations = {
    setLanguages(state, languages) {
        const langList = [];

        languages.forEach(item => {
            const entry = Array.findFirstByField(languageList, 'code', item['iso-639-1']);
            if(entry) {
                langList.push({
                    code: item['iso-639-1'],
                    label: item.label,
                    name: entry.name
                });
            }
        });
        state.languages = langList;
    },
    setDefaultLanguage(state, defaultLanguage) {
        state.defaultLanguage = defaultLanguage;
    },
    setInitialized(state, init) {
        state.initialized = init;
    }
};

const getters = {
    languages: (state) => {
        return Vue.prototype.$clone(state.languages);
    },
    defaultLanguage: (state) => {
        return Vue.prototype.$clone(state.defaultLanguage);
    },
    checkinLanguages: (state, getters, rootState, rootGetters) => {
        const languages = rootGetters['languageInfo/languages'];

        const languageConfig = rootState.hospitalityServiceConfig;


        if (languages.length > 0  && languageConfig && languageConfig.fetched && languageConfig.config.availableLanguages) {
            const availableLanguages = languageConfig.config.availableLanguages;
            return languages.filter((it) => availableLanguages.find(({ code }) => it.code === code));
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
