import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Validator from "@/lib/helpers/Validator";
import Vue from 'vue';
import moment from 'moment';

const thisModule = 'wakeups';

const module = new BaseCrudModule(apis[thisModule], '');

/**
 * Figure out when the alarm was last supposed to be triggered.
 * Check if the timestamp passed is after the last alarm
 * @param time the timestamp to check to be more recent then the last alarm
 * @param alarm the set time for the alarm. Only hours and minutes count
 * @param now servers now time
 * @returns {boolean}
 */
const validRecurring = (time, alarm, now) => {
    let lastAlarm = Vue.prototype.$clone(now);
    lastAlarm.setHours(alarm.getHours());
    lastAlarm.setMinutes(alarm.getMinutes());
    lastAlarm.setSeconds(0);
    if(lastAlarm.getTime() > now.getTime()) {
        /* Step back one day */
        lastAlarm.setDate(lastAlarm.getDate() - 1);
    }
    return (time.getTime() > lastAlarm.getTime());
};

module.addGetters({
    withLocationTitle: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        const accountsIdMap = rootGetters['roomAccounts/idMapByGetter']('withLocation');

        if (Array.isArray(data)) {
            data.forEach((item) => {
                item.locationTitle = Map.retrieveValue(accountsIdMap, item.roomAccountId, 'locationTitle');
                item.dateFormatted = item.daily ? "Daily" : moment(item.timeToWake).format('MMMM Do YYYY');
                item.timeFormatted = moment(item.timeToWake).format('HH:mm');
            });

            Array.sort(data, 'timeToWake', false);
            return data;
        }
        return [];
    },
    pending: () => {
        const data = module.cloneItems();
        const pending = [];

        data.forEach(item => {
            if (item.daily || Validator.isEmpty(item.deliveredTimeUtc)) {
                pending.push(item);
            }
        });
        return pending;
    },
    withLocationTitleAndDelivery: (state, getters) => {
        const items = getters['withLocationTitle'];
        items.forEach(item => {
            if(!item.daily) {
                /* One time alarm, just check if set */
                item.isDelivered = Validator.isNotEmpty(item.deliveredTimeUtc);
                item.isDisplayed = item.isDelivered && Validator.isNotEmpty(item.displayedTimeUtc);
                item.isConfirmed = item.isDelivered && Validator.isNotEmpty(item.confirmedTimeUtc);
            } else {
                item.isDelivered = false;
                item.isDisplayed = false;
                item.isConfirmed = false;

                try {
                    const now = new Date(item.nowUtc);
                    const alarm = new Date(item.timeToWakeUtc);
                    if (Validator.isNotEmpty(item.deliveredTimeUtc)) {
                        item.isDelivered = validRecurring(new Date(item.deliveredTimeUtc), alarm, now);
                    }
                    if (Validator.isNotEmpty(item.displayedTimeUtc)) {
                        item.isDisplayed = validRecurring(new Date(item.displayedTimeUtc), alarm, now);
                    }
                    if (Validator.isNotEmpty(item.confirmedTimeUtc)) {
                        item.isConfirmed = validRecurring(new Date(item.confirmedTimeUtc), alarm, now);
                    }
                } catch(e) {
                    console.error("Error calculating wakeup delivery");
                    console.error(e);
                }
            }
        });
        return items;
    }
});

export default module;