<template>
    <v-menu
            :close-on-content-click="false"
            offset-y
    >
        <template v-slot:activator="{on}">
            <v-btn icon v-on="on" data-testid="mmlsLanguageSelector">
                <v-avatar v-on="on" size="24px">
                    <img :src="require(`@/assets/icon/language/${$app.getFlagCode($app.language.code)}.png`)" :alt="$app.language.code">
                </v-avatar>
            </v-btn>
        </template>
        <v-list>
            <v-list-item
                    v-for="lang in languages"
                    :key="lang.code"
                    @click="selectLanguage(lang)"
                    :data-testid="'mmlsLanguageCode-'+lang.code"
            >
                <v-list-item-action>
                    <v-avatar size="24">
                        <img :src="require(`@/assets/icon/language/${$app.getFlagCode(lang.code)}.png`)" :alt="lang.code">
                    </v-avatar>
                </v-list-item-action>
                <v-list-item-title>{{$tc('language.' + lang.name, 2)}}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>

export default {
        name: "MainMenuLanguageSelector",
        computed: {
            languages() {
                return this.$app.languages.filter(lang => lang.portal === true);
            }
        },
        methods: {
            selectLanguage(lang) {
                this.$app.language = lang;
            }
        }
    }
</script>