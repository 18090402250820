<template>
    <v-menu
        v-if="$app.desktop && !$app.tablet"
        v-model="show"
        offset-y
        :position-y="yPos"
        :position-x="xPos"
        :close-on-click="true"
        :close-on-content-click="closeOnClick"
        absolute
        :content-class="customClass"
        :eager="eager"
        z-index=1000
    >
        <slot name="content"></slot>
    </v-menu>
    <v-bottom-sheet
        v-else
        v-model="show"
        :persistent="persistent"
        :content-class="customClass"
        width="100%"
    >
        <div class="base-menu-sheet-header" v-if="headerEnabled">
            <v-row>
                <v-col class="shrink">
                    <v-btn icon @click.stop="show = false">
                        <v-icon style="opacity: 0.6;">close</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-subheader class="pa-0 text--black">
                        {{title === 'actions' ? $t('generic.action', 2) : title}}
                    </v-subheader>
                </v-col>
            </v-row>
        </div>
        <div class="base-menu-sheet-container" v-if="headerEnabled">
            <slot name="content"></slot>
        </div>
        <slot v-else name="content"></slot>
    </v-bottom-sheet>
</template>

<script>
    import VModelShowMixin from "@/mixins/vModelShowMixin.js";

    export default {
        name: 'BaseMenu',
        mixins: [VModelShowMixin],
        props: {
            closeOnClick: {default: true, type: Boolean},
            persistent: {default: false, type: Boolean},
            headerEnabled: {default: false, type: Boolean},
            title: {default: 'actions', type: String},
            eager: {default: true, type: Boolean},
            customClass: {default: '', type: String},
            posX: {
                type: Number,
                default: undefined
            },
            posY: {
                type: Number,
                default: undefined
            }
        },
        data() {
            return {
                xPos: 0,
                yPos: 0
            }
        },
        watch: {
            show(s) {
                if (s) {
                    this.xPos = this.posX === undefined ? this.$app.mouseX : this.posX;
                    this.yPos = this.posY === undefined ? this.$app.mouseY : this.posY;
                }
            }
        }
    }
</script>

<style lang="scss">
    .base-menu-sheet-header {
        background: var(--v-background-base);
        position: fixed;
        width: 100%;
        z-index: 1;
    }

    .base-menu-sheet-container {
        padding-top: 50px;
        background: var(--v-background-base);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        max-height: 90vh;
        max-height: -webkit-fill-available;
    }
</style>