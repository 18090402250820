import Vue from 'vue';
import lodash from "lodash";

Map.retrieve = (map, key, nullValue = null) => {
    const item = map[key];
    return item === undefined ? nullValue : item;
};

Map.retrieveValue = (map, key, valueField, nullValue = null) => {
    const item = Map.retrieve(map, key);
    if (item === null) return nullValue;
    return item[valueField] === undefined ? nullValue : item[valueField];
};

Map.createFromArray = (array, field, valueAsList = false) => {
    if(!array) {
        return {};
    }
    if (valueAsList) {
        const map = {};

        for (let i = 0; i < array.length; i++) {
            if (map[array[i][field]] === undefined) {
                Vue.set(map, array[i][field], []);
            }
            map[array[i][field]].push(array[i]);
        }

        return map;
    } else {
        return lodash.keyBy(array, o => o[field]);
    }
};