import {Validator} from "@/lib/helpers";

/**
 * Check a privilege set for a match for the given feature, resource and action.
 * Will only match against defined arguments for feature, resource and action.
 * If e.g. action is not defined, then the match will only check feature and resource and return
 * true if these match regardless of whether any actions are allowed.
 * Also, if resource is omitted (and thus implicitly also actions), then only a match against the feature is performed.
 *
 * @param feature   Feature to check privilege against
 * @param resource  Resource to check privilege against
 * @param action    Action to check privilege against
 * @param privileges Privileges to check
 * @returns true If the given contextAccessData contains the specified privileges.
 */
function hasPrivilege(feature, resource, action, privileges) {

    if (!privileges) {
        return false;
    }
    if (!feature) {
        return true;
    }

    const ctxFeature = Array.findFirst(privileges, item => item.feature === feature);
    if (!ctxFeature) {
        return false;
    }
    if (!resource) {
        return true;
    }

    const ctxResource = Array.findFirst(ctxFeature.resources, item => item.resource === resource);
    if (!ctxResource) {
        return false;
    }
    if (!action) {
        return true;
    }

    return (ctxResource.actions[action] === true);
}

/**
 * Check a provision set for a match for the given feature, resource and action.
 * Will only match against defined arguments for feature and resource.
 * If e.g. resource is omitted, then only a match against the feature is performed.
 *
 * @param feature   Feature to check privilege against
 * @param resource  Resource to check privilege against
 * @param provisioning Provisioning to check
 * @returns true If the given contextAccessData contains the specified provisioning.
 */
function hasProvisioning(feature, resource, provisioning) {

    if (!provisioning) {
        return false;
    }
    if (!feature) {
        return true;
    }

    const ctxFeature = Array.findFirst(provisioning, item => item.feature === feature);
    if (!ctxFeature) {
        return false;
    }
    if (!resource) {
        return true;
    }

    const ctxResource = Array.findFirst(ctxFeature.resources, item => item.resource === resource);
    const missingResource = !ctxResource
    return !missingResource
}

/**
 * Check a privilege set and provisioning for a match for the given feature, resource and action.
 * Will only match against defined arguments for feature, resource and action.
 * If e.g. action is not defined, then the match will only check feature and resource and return
 * true if these match regardless of whether any actions are allowed.
 * Also, if resource is omitted (and thus implicitly also actions), then only a match against the feature is performed.
 *
 * @param feature   Feature to check against
 * @param resource  Resource to check against
 * @param action    Action to check against
 * @param accessData Object containing privileges and provisioning property
 * @returns true If the given contextAccessData contains the specified privilege and provisioning.
 */
function hasAccess(feature, resource, action, accessData) {

    const hasPriv = hasPrivilege(feature, resource, action, accessData.privileges)
    const hasProv = hasProvisioning(feature, resource, accessData.provisioning)

    return hasPriv && hasProv
}

/**
 * Returns list of feature/resource sets that are part of both the privilege and provisioning sets.
 * @param provisioning Provisioning
 * @param privileges    Privileges
 * @returns An list of the intersection of features/resources from the privilege and provisioning sets.
 */
function featuresAndPrivilegesIntersection(provisioning, privileges) {

    let result = [];

    if (!Array.isArray(provisioning) || !Array.isArray(privileges)) {
        return result;
    }

    provisioning.forEach(contextProvisioning => {

        const accountPrivileges = Array.findFirstByField(privileges, 'feature', contextProvisioning.feature);
        if (Validator.isNotEmpty(accountPrivileges)) {
            const commonResources = accountPrivileges.resources.filter(p =>
                    contextProvisioning.resourceProvisions.find(resourceProvision => resourceProvision.resource === p.resource) !== undefined
            )
            // TODO: Should we not only push feature if it actually has any matching resources??
            result.push({
                feature: contextProvisioning.feature,
                resourceProvisions: commonResources
            });
        }
    });

    return result;
}

/**
 * Simply checks if there are any provisions.
 * @param provisioning
 * @returns {boolean}
 */
function hasAnyProvisioning(provisioning) {
    return (Array.isArray(provisioning) && provisioning.length > 0);
}

/**
 * Simply checks if there are any privileges.
 * @param privileges
 * @returns {boolean}
 */
function hasAnyPrivileges(privileges) {
    return (Array.isArray(privileges) && privileges.length > 0);
}

export const AccessUtilities = {
    hasAccess: hasAccess,
    hasPrivilege: hasPrivilege,
    hasProvisioning: hasProvisioning,
    featuresAndPrivilegesIntersection: featuresAndPrivilegesIntersection,
    hasAnyProvisioning: hasAnyProvisioning,
    hasAnyPrivileges: hasAnyPrivileges
}
