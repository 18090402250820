import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import Validator from "@/lib/helpers/Validator";
import Vue from 'vue';
import ApiHelper from "../../../lib/helpers/ApiHelper";

const thisModule = 'products';
const resCheck = ApiHelper.responseSuccessCheck;

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    featureListing: (state, getters, rootState, rootGetters) => {
        const data = getters.sorted;
        return data.map(function (product) {
            if (Validator.isEmpty(product.featureProvisions)) {
                return {id: product.id, name: product.name, features: [], contextType: product.contextType}
            } else {
                const featureNames = product.featureProvisions.map(function (featureProvision) {
                    return featureProvision.feature
                });
                return {
                    ...product,
                    features: featureNames
                }
            }
        });
    },
    /**
     * Make sure all the needed provisioning data-structures are present
     */
    sanitizedItem: (state, getters, rootState, rootGetters) => (id) => {
        const entry = getters.itemById(id);

        if(!entry) {
            return null;
        }
        const items = Array.sortWithReturn(entry.featureProvisions, ['feature'], ['asc'])
        /* Make sure all provisions are included */
        items.forEach( item => {
            if(!Array.isArray(item.configurationProvisions)) { item.configurationProvisions = [] }
            if(!Array.isArray(item.resourceProvisions)) { item.resourceProvisions = [] }
            const feature = rootGetters['features/idMapBy']("feature")[item.feature];
            if(feature) {
                feature.configurationSpecifications.forEach(configurationSpecification => {
                    if(!Array.findFirstByField(item.configurationProvisions, 'configurationName', configurationSpecification.configurationName)) {
                        console.log(item.feature + " : Configuration NOT FOUND. Adding : " + configurationSpecification.configurationName);
                        item.configurationProvisions.push({
                            configurationName: configurationSpecification.configurationName,
                            values: []
                        });
                    }
                });
                /* Make sure all limits are in place */
                item.resourceProvisions.forEach(resourceProvision => {
                    let r = Array.findFirstByField(feature.resourceSpecifications, 'resource', resourceProvision.resource);
                    if(r) {
                        r.limits.forEach(resource => {
                            if(!Array.findFirstByField(resourceProvision.limits, "limit", resource)) {
                                console.log("Adding missing limit " + resource);
                                resourceProvision.limits.push({
                                    limit: resource,
                                    value: 0
                                });
                            }
                        });
                    }
                });

            } else {
                console.error("Could not find feature " + item.feature);
            }

        });
        return entry;
    }
});

module.addActions({
    updateWithPathExtension({commit}, data) {
        const {item, pathExtension, requestConfig = {}} = data;

        commit('processing');

        return new Promise(async (resolve) => {
            const response = await apis[thisModule].putWithPathExtension(item, item.id, pathExtension ,requestConfig);
            commit(resCheck(response) ? 'updateSuccess' : 'error', response.data);
            resolve(response);
        });
    }
});

export default module;