import Storage from "@/lib/helpers/Storage"
import Validator from '@/lib/helpers/Validator';

const AUTHENTICATION_TOKEN = 'authenticationToken';
const SCREEN_REGISTER_AUTHENTICATION_TOKEN = 'srAuthenticationToken';
let currAuthToken = null;
let AuthManagerInitialized = false;
const originalUrlParams = new URLSearchParams(window.location.search);
/**
 * Convenience module to persist token and share with none-Vue modules
 */

class AuthManager {

    static getUrlParams() {
        return originalUrlParams;
    }

    static getAuthenticationToken() {
        if(!AuthManagerInitialized) {
            const queryAuth = AuthManager.getUrlParams().get('auth');

            switch(queryAuth) {
                case 'screenRegister':
                    currAuthToken = Storage.get(SCREEN_REGISTER_AUTHENTICATION_TOKEN, null);
                    return currAuthToken;
                default:
                    currAuthToken = Storage.getSession(AUTHENTICATION_TOKEN, null);
            }

            if(currAuthToken === null) {
                currAuthToken = Storage.get(AUTHENTICATION_TOKEN, null);
                if(currAuthToken !== null) {
                    /* Clear - we have grabbed it */
                    console.log("Grabbed last saved token!");
                    Storage.set(AUTHENTICATION_TOKEN, null);
                }
            }
            AuthManagerInitialized = true;
            window.addEventListener('beforeunload', (event) => {
                if(currAuthToken) {
                    console.log("Saving token");
                    Storage.set(AUTHENTICATION_TOKEN, currAuthToken);
                }
            });
        }
        return currAuthToken;
    }

    static setAuthenticationToken(token) {
        const queryAuth = AuthManager.getUrlParams().get('auth');
        if(queryAuth === 'screenRegister') {
            Storage.set(SCREEN_REGISTER_AUTHENTICATION_TOKEN, token);
            currAuthToken = token;
        } else if(Validator.isNotEmpty(token)) {
            Storage.setSession(AUTHENTICATION_TOKEN, token);
            currAuthToken = token;
        } else {
            Storage.setSession(AUTHENTICATION_TOKEN, null);
            currAuthToken = null;
        }
    }

    static getAuthenticationHeader() {
        const authentication = AuthManager.getAuthenticationToken();
        if (Validator.isNotEmpty(authentication)) {
            return {'Authorization': 'Bearer ' + authentication};
        } else {
            return {};
        }
    }

    static logout() {
        this.setAuthenticationToken(null);
    }

    static setEnableAutoSSO(val) {
        Storage.set('enableAutoSSO', val);
    }
    static getEnableAutoSSO() {
        return Storage.get('enableAutoSSO');
    }
};

export default AuthManager;