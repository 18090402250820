import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';
import ApiHelper from "@/lib/helpers/ApiHelper";

const thisModule = 'mediaChannels';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withImageUrl: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const image = Map.retrieveValue(rootGetters['globalChannels/idMap'], item.globalId, 'image', null);
                item.imageUrl = image === null ? null : 'https://image.otrum.net/mediachannel/' + image;
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    },
    withImageUrlAndMediaChannelId: (state, getters, rootState, rootGetters) => {
        const data = module.cloneItems();
        if (Array.isArray(data)) {
            data.forEach((item) => {
                const image = Map.retrieveValue(rootGetters['globalChannels/idMap'], item.globalId, 'image', null);
                item.imageUrl = image === null ? null : 'https://image.otrum.net/mediachannel/' + image;
                item.mediaChannelId = item.id;
            });
            Array.sort(data, 'name');
            return data;
        }
        return [];
    },
    withImageUrlAndMediaChannelIdByType: (state, getters) => (type) => {
        const data = getters.withImageUrlAndMediaChannelId;
        const typeLower = type.toLowerCase();

        return data.filter(c => c.type === typeLower);
    }
});

module.addActions(
    {
        buildMediaChannelOfType: ({state, actions, commit, rootState, dispatch}, { type }) => {
            const mediaChannel = {
                type: type.toLowerCase(),
                tuningParameters: {},
                contextId: rootState.context.selectedContext.id,
                name: '',
                globalId: null,
                tableId: Math.floor(Math.random() * 1000)
            };

            switch(mediaChannel.type) {
                case 'udp':
                    mediaChannel.tuningParameters['ipAddress'] = null;
                    mediaChannel.tuningParameters['ipPort'] = null;
                    mediaChannel.tuningParameters['serviceId'] = null;
                    mediaChannel.tuningParameters['audioOnly'] = 'false';
                    mediaChannel.tuningParameters['rtp'] = 'false';
                    mediaChannel.tuningParameters['drm'] = 'false';
                    break;
                case 'dvbc':
                    mediaChannel.tuningParameters['frequency'] = null;
                    mediaChannel.tuningParameters['modulation'] = null;
                    mediaChannel.tuningParameters['symbolRate'] = null;
                    mediaChannel.tuningParameters['serviceId'] = null;
                    mediaChannel.tuningParameters['drm'] = 'false';
                    break;
                case 'dvbs':
                    mediaChannel.tuningParameters['frequency'] = null;
                    mediaChannel.tuningParameters['satelliteId'] = null;
                    mediaChannel.tuningParameters['serviceId'] = null;
                    mediaChannel.tuningParameters['polarization'] = null;
                    mediaChannel.tuningParameters['programNumber'] = 0;
                    break;
                case 'dvbt':
                    mediaChannel.tuningParameters['frequency'] = null;
                    mediaChannel.tuningParameters['modulation'] = null;
                    mediaChannel.tuningParameters['serviceId'] = null;
                    mediaChannel.tuningParameters['drm'] = 'false';
                    break;
                case 'pal':
                    mediaChannel.tuningParameters['frequency'] = null;
                    break;
                case 'http':
                    mediaChannel.tuningParameters['type'] = null;
                    mediaChannel.tuningParameters['url'] = null;
                    mediaChannel.tuningParameters['audioOnly'] = 'false';
                    break;
            }
            return mediaChannel;
        }
    }
);

export default module;