import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api';
import Validator from '@/lib/helpers/Validator';

const thisModule = 'duplicateRegisteredClients';

function getBestNetworkValues(obj) {
    if (obj === null || obj === undefined) {
        return null;
    }
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key].ip !== null && obj[key].ip !== "") {
            return obj[key];
        }
    }
    return null;
}

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    withStatus: (state, getters, rootState, rootGetters) => {
        const contextIdMap = Map.createFromArray(rootGetters['allContexts'](false), 'id');

        const data = module.cloneItems();
        if(Array.isArray(data)) {

            data.forEach(item => {
                item.firstTry = item.firstDuplicateRegister;
                item.lastTry = item.lastDuplicateRegister;

                const site = contextIdMap[item.contextId];

                if (site) {
                    item.siteName = site.name;
                }
                if(Validator.isNotEmpty(item.status)) {
                    const deviceType = item.status.deviceType;
                    const deviceModel = item.status.deviceModel;
                    const network = getBestNetworkValues(item.status.network);

                    item.poweredOn = item.online === true && item.status.poweredOn;
                    item.network = JSON.stringify(item.status.network);
                    item.ip = network !== null && network !== undefined ? network.ip : 'Unknown';
                    item.mac = network !== null && network !== undefined ? network.mac : 'Unknown';
                    item.deviceType = deviceType + '/' + deviceModel;
                    item.firmwareShort = (typeof item.status.firmwareVersion === 'string') ?
                        item.status.firmwareVersion.substr(0, 20) + (item.status.firmwareVersion.length > 20 ? '...' : '') : 'N/A';
                } else {
                    item.poweredOn = false;
                    item.network = null;
                    item.ip = null;
                    item.mac = null;
                    item.deviceType = null;
                    item.resolution = null;
                    item.firmwareShort = '-';
                    item.lastReboot = null;
                    item.lastRebootTxt = null;
                }
            });
            Array.sort(data, 'name', true);
            return data;
        }
        return [];
    }
});

export default module;
