import BaseListHeaderModule from "@/store/modules/base/BaseListHeaderModule";

const module = new BaseListHeaderModule();

module.setListId('homeSiteHeaders');

module.setListHeaders({
        'facId': {
            text: 'Facility ID',
            value: 'facId',
            initialEnabled: false,
            searchable: true
        },
        'extId': {text: 'External ID', value: 'extId', initialEnabled: false, searchable: true},
        'crmRef': {text: 'CRM Ref', value: 'crmRef', initialEnabled: false, searchable: true},
        'industry': {text: 'Industry', value: 'industry', initialEnabled: false, searchable: true},
        'contractRefs': {
            text: 'Contract Refs',
            value: 'contractRefs',
            convertToString: (item) => { return item['contractRefs'].join(', '); },
            initialEnabled: false,
            searchable: true
        },
        'nick': {text: 'Nickname', value: 'nick', initialEnabled: false, searchable: true},
        'wlbl': {text: 'Whitelabel', value: 'wlbl', initialEnabled: false},
        'productName': {text: 'System', value: 'productName', initialEnabled: false, searchable: true},
        'licenses': {
            text: 'Licenses',
            value: 'licenses',
            convertToString: (item) => { return item['licenses'].join(', '); },
            initialEnabled: false,
            searchable: true
        },
        'brandName': {
            text: 'Brand',
            value: 'brandName',
            initialEnabled: false,
            searchable: true
        },
        'chainName': {
            text: 'Chain',
            value: 'chainName',
            initialEnabled: false,
            searchable: true
        },
        'resellerName': {
            text: 'Reseller',
            value: 'resellerName',
            initialEnabled: true,
            searchable: true
        },
        'distributorName': {
            text: 'Distributor',
            value: 'distributorName',
            initialEnabled: false,
            searchable: true
        },
        'ctry': { text: 'Country', value: 'ctry', initialEnabled: false, searchable: true },
        'pmsUp': { text: 'PMS', value: 'pmsUp', initialEnabled: false },
        'pmsVendor': { text: 'PMS Vendor', value: 'pmsVendor', initialEnabled: false, searchable: true },
        'roomCnt': {text: 'Rooms', value: 'roomCnt', initialEnabled: true},
        'occupied': {text: 'Occ.', value: 'occupied', initialEnabled: true},
        'maxClientsLic': {
            text: 'TV Licences',
            value: 'maxClientsLic',
            initialEnabled: false
        },
        'maxSignageLic': {
            text: 'Signage Licences',
            value: 'maxSignageLic',
            initialEnabled: false
        },
        'maxCastsLic': {
            text: 'Cast Licences',
            value: 'maxCastLic',
            initialEnabled: false
        },
        'numberOfClients': {
            text: 'TVs',
            value: 'numberOfClients',
            initialEnabled: true
        },
        'offlineClients': {
            text: 'Off. TVs',
            value: 'offlineClients',
            initialEnabled: true
        },
        'tvTypes': {
            text: 'TV Types',
            value: 'tvTypes',
            convertToString: (item) => { return Object.keys(item.tvTypes).map(key => key + ":" + item.tvTypes[key]).join(','); },
            initialEnabled: true,
            searchable: true
        },
        'tvMod': {
            text: 'TV Models',
            value: 'tvMod',
            convertToString: (item) => { return Object.keys(item.tvMod).map(key => key + ":" + item.tvMod[key]).join(','); },
            initialEnabled: false,
            searchable: true
        },
        'tvFw': {
            text: 'TV Firmwares',
            value: 'tvFw',
            convertToString: (item) => { return Object.keys(item.tvFw).map(key => key + ":" + item.tvFw[key]).join(','); },
            initialEnabled: false,
            searchable: true
        },
        'sigFw': {
            text: 'Signage Screen Firmwares',
            value: 'sigFw',
            convertToString: (item) => { return Object.keys(item.sigFw).map(key => key + ":" + item.sigFw[key]).join(','); },
            initialEnabled: false,
            searchable: true
        },
        'numberOfSigClients': {
            text: 'Signage Screens',
            value: 'numberOfSigClients',
            initialEnabled: true
        },
        'sigMod': {
            text: 'Signage Screen Models',
            value: 'sigMod',
            convertToString: (item) => { return Object.keys(item.sigMod).map(key => key + ":" + item.sigMod[key]).join(','); },
            initialEnabled: false,
            searchable: true
        },
        'sigClientTypes': {
            text: 'Signage Screen Types',
            value: 'sigClientTypes',
            convertToString: (item) => { return Object.keys(item.sigClientTypes).map(key => key + ":" + item.sigClientTypes[key]).join(','); },
            initialEnabled: true,
            searchable: true
        },
        'offlineSigClients': {
            text: 'Off. Signage Screens',
            value: 'offlineSigClients',
            initialEnabled: true
        },
        'castOnline': {
            text: 'Cast Server Online',
            value: 'castOnline',
            initialEnabled: false,
            searchable: true
        },
        'numberOfCasts': {
            text: 'Installed Chromecasts',
            value: 'numberOfCasts',
            initialEnabled: false,
            searchable: true
        },
        'castVersion': {
            text: 'Cast Server version',
            value: 'castVersion',
            initialEnabled: false,
            searchable: true
        },
        'agentOnline': {
            text: 'Agent Online',
            value: 'agentOnline',
            initialEnabled: false,
            searchable: true
        },
        'agentVersion': {
            text: 'Agent Version',
            value: 'agentVersion',
            initialEnabled: false,
            searchable: true
        },
        'flightLicenses': {
            text: 'Flight Licenses',
            value: 'flightLicenses',
            initialEnabled: false,
            searchable: true
        },

        // 'pendingWakeups': { text: 'Wakeups', value: 'pendingWakeups', initialEnabled: false },
        'ver': { text: 'Version', value: 'ver', initialEnabled: false, searchable: true },
        'os': { text: 'OS', value: 'os', initialEnabled: false, searchable: true },
        'tz': {text: 'Timezone', value: 'tz', initialEnabled: true, searchable: true },
        'srvTime': { text: 'Server Time', value: 'srvTime', initialEnabled: false },
        // 'hasTvScreenshot': { text: 'TV Screenshot', value: 'hasTvScreenshot', initialEnabled: false },
        'uptime': { text: 'Uptime', value: 'uptime', initialEnabled: false },
        'demo': {text: 'Demo', value: 'demo', initialEnabled: false},
        'scrShot': {text: 'Screenshot', value: 'scrShot', initialEnabled: false},
        'watermarked': {
            text: 'Watermarked',
            value: 'watermarked',
            convertToString: (item) => {
                if (!['enterprise', 'ctrl', 'chromecast'].includes(item.productType)) {
                    return item['watermarked'].join(',');
                }
                return (item['licVal'] === true || item['licVal'] === 'true') ? "" : "license-invalid"
            },
            initialEnabled: false
        },
        // 'updatedDate': { text: 'Last Update', value: 'updatedDate', initialEnabled: false },
        'urlName': { text: 'Legacy URL name', value: 'urlName', initialEnabled: false, searchable: true },
        'creationDate': {
            text: 'Creation Date',
            value: 'creationDate',
            initialEnabled: false,
            convertToString: (item) => {
                return item.creationDateString;
            },
            searchable: true
        },
        'productionDate': {
            text: 'Production Date',
            value: 'productionDate',
            initialEnabled: false,
            convertToString: (item) => {
                return item.productionDateString;
            },
            searchable: true
        },
});

export default module;