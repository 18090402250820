
class PortalTheme {
    static availableThemes = {
        THEME_DEFAULT: Symbol("theme_default"),
        THEME_PPDS: Symbol("theme_ppds"),
        THEME_UNIGUEST: Symbol('theme_uniguest')
    }

    static getTheme() {
        const hostName = window.location.hostname;

        if (hostName.includes('wave')) {
            return PortalTheme.availableThemes.THEME_PPDS;
        } else if (hostName.includes('uniguest')){
            return PortalTheme.availableThemes.THEME_UNIGUEST;
        } else {
            if(typeof window.location.search === 'string') {
                if(window.location.search.includes('?uniguest-theme')) {
                    return PortalTheme.availableThemes.THEME_UNIGUEST;
                } else if(window.location.search.includes('?ppds-theme')) {
                    return PortalTheme.availableThemes.THEME_PPDS
                }
            }
            return PortalTheme.availableThemes.THEME_DEFAULT;
        }
    }

}

export default PortalTheme