import BaseContextModule from "@/store/modules/contexts/BaseContextModule";
import ApiHelper from "../../../lib/helpers/ApiHelper";

const resCheck = ApiHelper.responseSuccessCheck;

class BaseDomainModule extends BaseContextModule {

    constructor(api, fetchEndpoint = '', getters) {

        super(api, fetchEndpoint, getters);

        this.addActions({
            createWithResources({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                let actualPathExtension = `/createWithResources`
                if(pathExtension !== undefined && pathExtension !== null) {
                    actualPathExtension= actualPathExtension + pathExtension
                }

                return new Promise(async (resolve) => {
                    const response = await api.post(item, actualPathExtension, requestConfig);
                    commit(resCheck(response) ? 'createSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            updateWithResources({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                let actualPathExtension = `/withResources`
                if (pathExtension !== undefined && pathExtension !== null) {
                    actualPathExtension = actualPathExtension + pathExtension
                }

                return new Promise(async (resolve) => {
                    const response = await api.putWithPathExtension(item, item.id, actualPathExtension,requestConfig);
                    commit(resCheck(response) ? 'updateSuccess' : 'error', response.data);
                    resolve(response);
                });
            },

        })
    }
}

export default BaseDomainModule;
