/* German translations */
export default {
	"generic": {
		"set": "Eingestellt",
		"onboard": "An Bord",
		"view": "Ansicht",
		"open": "Öffnen",
		"days": "Tage",
		"minutes": "Minuten",
		"log": "Protokoll",
		"event": "Event",
		"eventReason": "Grund",
		"occuredAt": "Aufgetreten um",
		"lastKeepalive": "Letztes Keepalive",
		"allocated": "Zugewiesen",
		"active": "Aktiv",
		"inactive": "Inaktive",
		"firmware": "Firmware",
		"from": "Von",
		"to": "An",
		"ipValidationErrorMsg": "Muss eine gültige IP-Adresse enthalten",
		"networks": "Netzwerke",
		"ipAddress": "IP-Adresse",
		"host": "Host",
		"total": "Gesamt",
		"add": "Hinzufügen",
		"all": "Alle",
		"addWithType": "{type} hinzufügen | {type}n hinzufügen",
		"agent": "Agent | Agenten",
		"signageScreen": "Signage-Bildschirm",
		"agentStatus": "Agentenstatus",
		"delete": "Löschen",
		"deleteAll": "Alles löschen",
		"deleteWithType": "{type} löschen | {amount} {type}n löschen",
		"deleteConfirm": "Sind Sie sicher, dass Sie {type} löschen möchten? | Sind Sie sicher, dass Sie {amount} {type}n löschen möchten?",
		"deleteConfirmWithIdentifier": "Sind Sie sicher, dass Sie {type} {id} löschen möchten?",
		"deleted": "{type} erfolgreich gelöscht | {type}n erfolgreich gelöscht",
		"deleteFailed": "{type} konnte nicht gelöscht werden | {type}n konnten nicht gelöscht werden",
		"deletedWithIdentifier": "{id} erfolgreich gelöscht",
		"deletedWithTypeAndIdentifier": "{type} {id} gelöscht",
		"deleteUserConfirmation": " Ich bestätige, dass ich diesen {type} löschen möchte. Ich bestätige, dass dieser Vorgang endgültig ist und der {type} nicht wiederhergestellt werden kann.|Ich bestätige, dass ich diese {amount} {type}n löschen möchte. Ich bestätige, dass dieser Vorgang endgültig ist und die {type}n nicht wiederhergestellt werden können.",
		"fetchFailed": "Fehler beim Aufrufen vom {itemName}",
		"imported": "{cnt} {type} erfolgreich importiert | {cnt} {type}n erfolgreich importiert",
		"saved": "{type} erfolgreich gespeichert | {type}n erfolgreich gespeichert",
		"updated": "{type} erfolgreich aktualisiert | {type}n erfolgreich aktualisiert",
		"created": "{type} erfolgreich erstellt | {type}n erfolgreich gesperrt",
		"selectType": "{type} auswählen",
		"manageType": "{type} verwalten",
		"itemReverted": "Item ungekehrt|Items umgekehrt",
		"valueDuplicate": "{value} existiert bereits",
		"remove": "Entfernen",
		"disable": "Deaktivieren",
		"disabled": "Deaktiviert",
		"download": "Herunterladen",
		"enable": "Aktivieren",
		"enabled": "Aktiviert",
		"systemDefault": "Systemstandard",
		"removeWithType": "{type} entfernen | {type}n entfernen",
		"removeConfirm": "Sind Sie sicher, dass Sie{type} entfernen wollen? | Sind Sie sicher, dass Sie diese {type}n entfernen wollen?",
		"removeConfirmWithTarget": "Sind Sie sicher, dass Sie diesen {type} von diesem {target} entfernen wollen? | Sind Sie sicher, dass Sie diese {type}n von diesem {target} entfernen wollen? ",
		"group": "Gruppe | Gruppen",
		"rename": "Umbenennen",
		"renameWithType": "{type} umbenennen",
		"renamedTo": "{type} umbenannt in {name}",
		"create": "Erstellen",
		"createToolTip": "Erstellen ohne schließen",
		"createAndClose": "Erstellen & schließen",
		"createWithType": "{type} erstellen",
		"createNew": "Neu erstellen",
		"createNewWithType": "Dieses neue{type} erstellen | Diese neuen {type}n erstellen",
		"takeEffectImmediatly": "Das wird sofort in Kraft treten",
		"newWithType": "Neuer {type} | Neue {type}n",
		"actions": "Aktionen",
		"yes": "Ja",
		"no": "Nein",
		"none": "Keine",
		"move": "Verschieben",
		"loading": "Lädt",
		"unknown": "Unbekannt",
		"typeToSearch": "Eingeben um zu suchen",
		"enterSearchTerm": "Suchbegriff eingeben",
		"noSearchMatches": "Keine Treffer gefunden",
		"cancel": "Abbrechen",
		"close": "Schließen",
		"gotIt": "Verstanden",
		"required": "Benötigt",
		"numberRequired": "Zahl erforderlich",
		"fieldRequired": "Feld benötigt",
		"inputRequired": "Eingabe benötigt",
		"integration": "Integration | Integrationen",
		"selectionRequired": "Auswahl benötigt",
		"nameRequired": "Name benötigt",
		"inputLongerThan": "Eingabe muss länger als {length} Zeichen sein  | Eingabe muss länger als {length} Zeichen sein",
		"countLessThan": "Anzahl muss kleiner sein als {length}",
		"inputShorterThan": "Die Eingabe muss kürzer als {length} Zeichen sein",
		"nameInvalidFormat": "Der Name sollte nur Buchstaben, Zahlen und Leerzeichen enthalten",
		"madeYet": "Noch kein {type} erstellt | Noch keine {type}n erstellt",
		"noMoreItemsAvailable": "Kein {type} mehr verfügbar | Keine {type} mehr verfügbar",
		"searchCriteria": "Keine {type}n passend zu den Suchkriterien",
		"search": "Suchen",
		"save": "Speichern",
		"saveAndClose": "Speichern & Schließen",
		"saveWithType": "{type} speichern | {type}n speichern",
		"editItem": "Artikel bearbeiten | Artikel bearbeiten",
		"edit": "Bearbeitung | Bearbeitungen",
		"editWithType": "{type} bearbeiten | {type}n bearbeiten",
		"shareName": "Teilen {type}",
		"action": "Aktion | Aktionen",
		"refresh": "Aktualisieren",
		"reset": "Zurücksetzen",
		"resetRefresh": " {type} zurücksetzen und aktualisieren",
		"lostChanges": "Alle Änderungen gehen verloren",
		"noAvailable": "Keine verfügbaren {type}n",
		"channelTable": "Kanaltabelle",
		"done": "Fertig",
		"name": "Name",
		"nickname": "Spitzname",
		"externalId": "Externe ID",
		"crmRef": "CRM-Ref",
		"industry": "Industrie",
		"filename": "Dateiname",
		"content": "Inhalt",
		"fullscreen": "Vollbild",
		"exitFullscreen": "Vollbild schließen",
		"copy": "Kopieren",
		"copyToClipboard": "In die Zwischenablage kopieren",
		"copiedToClipboard": "Inhalt in die Zwischenablage kopieren",
		"copyToSite": "Auf Seite kopieren",
		"preview": "Vorschau",
		"reload": "Neu laden",
		"unsaved": "Ungespeicherte Änderungen gehen verloren",
		"type": "Typ | Typen",
		"text": "Text",
		"tag": "Tag | Tags",
		"item": "Artikel | Artikel",
		"title": "Titel",
		"fullTitle": "Ganzer Titel",
		"editor": "Editor",
		"number": "Nummer | Nummern",
		"top": "Oben",
		"left": "Links",
		"width": "Breite",
		"height": "Höhe",
		"zIndex": "z-Index",
		"field": "Feld | Felder",
		"key": "Schlüssel | Schlüssel",
		"description": "Beschreibung",
		"setup": "Setup",
		"status": "Status | Status",
		"multiple": "Mehrere",
		"default": "Standard",
		"general": "Allgemein",
		"configuration": "Konfiguration",
		"config": "Config",
		"select": "Auswählen",
		"addAll": "Alle hinzufügen",
		"removeAll": "Alle entfernen",
		"previous": "Vorheriges",
		"next": "Nächstes",
		"finish": "Beenden",
		"nameLonger": "Name muss länger als {length} Zeichen sein",
		"selectAll": "Alle auswählen",
		"deselectAll": "Alle abwählen",
		"code": "Code",
		"continue": "Fortfahren",
		"stay": "Aufenthalt",
		"back": "Zurück",
		"language": "Sprache",
		"languageName": "Sprache Name",
		"languageCode": "Srachencode",
		"reseller": "Reseller | Reseller",
		"resource": "Ressource | Ressourcen",
		"override": "Überschreibung | Überschreibungen",
		"insufficientPrivileges": "Konto hat keine ausreichenden Privilegien",
		"theme": "Thema | Themen",
		"distributor": "Verteiler | Verteiler",
		"domain": "Domain | Domains",
		"preregister": "Voranmeldung",
		"pleaseConfirm": "Bitte bestätigen",
		"switchTo": "Wechseln zu...",
		"home": "Start",
		"lastLoggedIn": "Zuletzt eingeloggt",
		"summary": "Zusammenfsassung",
		"noData": "Keine Daten zum Anzeigen...",
		"vendor": "Verkäufer | Verkäufer",
		"model": "Modell | Modelle",
		"user": "Benutzer",
		"schedule": "Plan | Pläne",
		"revert": "Umkehren",
		"revertAllChanges": "Alle Änderungen seit dem letzten Speichern rückgängig machen?",
		"revertNoChanges": "Keine Änderungen zum Zurücksetzen",
		"channels": "Kanal | Kanäle",
		"deployment": "Einsatz | Einsätze",
		"filter": "Filter",
		"accept": "Akzeptieren",
		"api": "API",
		"timeZone": "Zeitzone | Zeitzonen",
		"register": "Registrieren",
		"caseSensitive": "Groß- und Kleinschreibung beachten",
		"optional": "Optional",
		"errorOccurred": "Ein Fehler ist aufgetreten",
		"on": "An",
		"off": "Aus",
		"standby": "Standby",
		"idle": "Leerlauf",
		"notSet": "Nicht festgelegt",
		"exportCSV": "CSV exportieren",
		"importCSV": "CSV importieren",
		"success": "Erfolg",
		"uploaded": "Hochgeladen",
		"hasUnsavedChangesHeader": "Ungespeicherte Änderungen",
		"hasUnsavedChangesMessage": "Ihre Änderungen gehen verloren wenn Sie jetzt schließen.<br>Fortfahren?",
		"defaultErrorMsg": "Standard-Fehlermeldung",
		"notes": "Anmerkungen",
		"genericNotes": "Allgemeine Hinweise",
		"internalNotes": "Interne Hinweise",
		"whitelabelDomain": "Whitelabel-Domain",
		"createNewSchedule": "Neuen Zeitplan erstellen",
		"addToSchedule": "Zum vorhandenen Zeitplan hinzufügen",
		"readOnly": "Schreibgeschützt",
		"information": "Informationen",
		"custom": "Benutzerdefiniert",
		"url": "URL",
		"realm": "Keycloak-Bereich",
		"noResult": "Keine Ergebnisse | Keine Ergebnisse",
		"sharingContext": "Ressourcenanbieter | Ressourcenanbieter",
		"sharingSuccess": "Freigabe aktualisiert",
		"sharingError": "Die Freigabe konnte nicht aktualisiert werden",
		"lastPublished": "Zuletzt veröffentlicht",
		"notPublished": "{0} ist derzeit nicht veröffentlicht",
		"itemType": "{0}",
		"shareAll": "Alle",
		"shareSubGroup": "Untergruppe",
		"shareSingle": "Einzeln"
	},
	"validation": {
		"characterMaxLength": "Darf nicht mehr als {value} Zeichen umfassen",
		"valueHigherThan": "Der Wert muss höher sein als {value}",
		"valueHigherThanOrEqualTo": "Der Wert muss größer oder gleich {value} sein",
		"valueLowerOrEqualTo": "Der Wert muss kleiner oder gleich {value} sein",
		"powerOnOverrideFrom": "Muss vor \"Überschreiben mit\" stehen",
		"powerOnOverrideTo": "Muss nach \"Überschreiben von\" stehen"
	},
	"help": {
		"getSupportTitle": "Support kontaktieren",
		"getSiteInformationTitle": "Website-Informationen",
		"notAvailable": "Nicht verfügbar",
		"openDocumentation": "Dokumentation öffnen",
		"getSupportBody": "<span style=\"font-weight: bold;\">E-mail:</span> {email}<br><span style=\"font-weight: bold;\">Telefon:</span> {phone}<br><br>{text}",
		"notAvailableInHomeScreen": "Supportinformation ist auf der Startseite nicht verfügbar.<br>Bitte wechseln Sie auf eine andere Seite.",
		"documentation": "Dokumentation",
		"contactSupport": "Support kontaktieren",
		"mobileNavigationTip": "Klicken Sie auf die Schaltfläche oben links um die Navigationsleiste zu öffnen."
	},
	"eula": {
		"updated": {
			"title": "Nutzungsbedingungen aktualisiert",
			"text": "<span style='font-size: 16px;font-style: italic;'>Wir haben unsere Endbenutzer-Lizenzvereinbarung aktualisiert. Bitte lesen Sie sie durch und akzeptieren Sie sie, um fortzufahren</span> <br><br>"
		}
	},
	"welcome": {
		"title": "Willkommen {user}",
		"languageHeader": "Sprache",
		"languageText": " Willkommen im Enterprise Cloud Management-Portal.<br><br>Dies ist die Startseite der Enterprise Cloud Hospitality Media Solution, dem Zugangspunkt für Distributoren, Reseller, Seitenbesitzer und Seitenbenutzer.<br><br>Starten Sie mit der Auswahl Ihrer bevorzugten Sprache:",
		"homeScreenText": " Abhängig von Ihrem Konto werden Sie in ein \"Site Dashboard\" oder ein \"Portal Dashboard\" eingeloggt und dies ist Ihr zukünftiger Startbildschirm für die Arbeit mit Enterprise Cloud.<br><br><div style=\"font-weight: bold;\">Site Dashboard</div>Für Benutzer mit Standard-Einzel-Seiten-Zugang werden Sie direkt zu Ihrem eigenen \"Site Dashboard\" geführt, wenn Sie sich im System anmelden. <br>An diesem Startpunkt haben Sie einen Überblick über die Installationsbildschirme, Gastgewerbestatistiken und administrative Informationen.<br>Die spezifischen Menüpunkte, Ressourcen und Optionen, die Ihnen zur Verfügung stehen, können je nach Kontotyp, aktiven Lizenzen und gewährter Zugriffsebene variieren. <br><br><div style=\"font-weight: bold;\">Portal Dashboard</div>Für Reseller, Seitenbesitzer und Seitenbenutzer mit Zugriff auf mehr als eine Enterprise Cloud-Installation ist das \"Portal Dashboard\" der erste Startpunkt auf der Plattform.<br>Hier verwalten Sie Ressourcen und greifen auf kombinierte Statistiken für alle Ihre Seiten zu, legen Benutzer an, richten neue Installationen ein, prüfen Lizenzierungsdetails und viele andere administrative Aufgaben. <br>Die spezifischen Menüpunkte und Optionen in diesem administrativen Bereich der Plattform variieren in Abhängigkeit von Ihrem Account-Typ, den aktiven Lizenzen und der gewährten Zugriffsstufe.<br><br>Sie können das \"Portal-Dashboard\" immer erreichen, indem Sie es im linken oberen Kontextmenü von jeder Seite aus anwählen. Über dasselbe Kontextmenü oben links können Sie auch auf alle Ihre Websites zugreifen, um an jeder einzelnen Installation zu arbeiten, die für Ihr Konto verfügbar ist.",
		"siteText": " Wenn Sie ein Single-Site-Benutzer sind, werden Sie automatisch auf Ihrer Seite angemeldet, sobald Sie sich im System anmelden. Bei Benutzern mit Multi-Site-Zugang wählen Sie den Seitenkontext im Kontextmenü oben links.<br>Die Zugriffsebene, die Sie haben, bestimmt, welche Ressourcen Ihnen im Seitenkontext zur Verfügung stehen."
	},
	"language": {
		"english": "Englisch",
		"norwegian": "Norwegisch",
		"swedish": "Schwedisch",
		"finnish": "Finnisch",
		"russian": "Russisch",
		"italian": "Italienisch",
		"french": "Französisch",
		"german": "Deutsch",
		"spanish": "Spanisch",
		"estonian": "Estnisch",
		"atLeastOne": "Mindestens eine Sprache muss ausgewählt werden"
	},
	"date": {
		"today": "Heute",
		"yesterday": "Gestern",
		"daysAgo": "Vor {days} Tagen",
		"dateRequired": "Datum benötigt",
		"dateNotSet": "Nicht festgelegt",
		"timeRequired": "Zeit benötigt",
		"timeFormat": "Zeit muss im Format 12:00 sein",
		"amPmTimeFormat": "Die Uhrzeit muss im Format h:mm AM/PM vorliegen",
		"daily": "Täglich",
		"weekly": "Wöchentlich",
		"time": "Zeit",
		"date": "Datum",
		"timeOfDay": "Tageszeit",
		"monday": "Montag",
		"tuesday": "Dienstag",
		"wednesday": "Mittwoch",
		"thursday": "Donnerstag",
		"friday": "Freitag",
		"saturday": "Samstag",
		"sunday": "Sonntag",
		"startTime": "Startzeit",
		"endTime": "Endzeit",
		"weekday": "Wochenzeit",
		"endTimeAfter": "Endzeit muss nach der Startzeit sein",
		"duration": "Dauer",
		"inSeconds": "in Sekunden"
	},
	"router": {
		"agents": "Agenten",
		"contentConfiguration": "Konfiguration",
		"documents": "Dokumente",
		"resource": "Ressource",
		"resources": "Ressourcen",
		"hospitality": "Gastegewerbe",
		"dashboard": "Dashboard",
		"media": "Medien",
		"housekeeping": "Haushaltsführung",
		"screens": "Bildschirme",
		"tv": "TV",
		"playlists": "Playlists",
		"signage": "Signage",
		"sites": "Seiten",
		"customersAndLicenses": "Kunden und Lizenzen",
		"sitesAndLicenses": "Seiten & Lizensen",
		"externalSystems": "Externe Systeme",
		"reseller": "Reseller",
		"resellers": "Reseller",
		"manageResellers": "Reseller verwalten",
		"distributor": "Distributor",
		"distributors": "Distributoren",
		"domain": "Domain",
		"domains": "Domains",
		"usageReport": "Nutzungsbericht",
		"accessReport": "Zugriffsbericht",
		"resellerUsersAndAccess": "Benutzer & Zugriff",
		"distributorUsersAndAccess": "Benutzer & Zugriff",
		"resellerThemes": "Themen",
		"distributorThemes": "Themen",
		"content": "Inhalt",
		"admin": "Admin",
		"administration": "Administration",
		"system": "System",
		"rooms": "Zimmer",
		"virtualRemoteClients": "VRC-Sitzungen",
		"housekeepingRooms": "Zimmer",
		"locations": "Orte",
		"locationSetup": "Ort einrichten",
		"guests": "Gäste",
		"guestMessages": "Nachrichten",
		"booking": "Buchung",
		"wakeups": "Aufwachen",
		"config": "Config",
		"screensConfig": "Config",
		"housekeepingConfig": "Config",
		"streamAllocatorConfig": "Konfig",
		"configuration": "Konfiguration",
		"issues": "Fehler",
		"scheduledTasks": "Geplante Aufgaben",
		"screen": "Bildschirm",
		"screenConfigurations": "Konfigurationen",
		"tvChannels": "TV-Kanäle",
		"tvScreens": "TV-Bildschirme",
		"tvScreensTheme": "TV-Bilschirme",
		"signageScreens": "Signage-Bildschirme",
		"signageOverview": "Übersicht",
		"signageSchedules": "Zeitpläne",
		"signageConfig": "Konfig",
		"event": "Event",
		"events": "Events",
		"wayfinding": "Anweisungen",
		"wayfindingIcons": "Anweisungen",
		"logos": "Logos",
		"integrationLocations": "Integrationen",
		"eventConfig": "Konfig",
		"schedules": "Pläne",
		"schedulemapping": "Planzuordnung",
		"installCodes": "Installationscodes",
		"interactivePages": "Interaktive Menüs",
		"templatePages": "Vorlagenseiten",
		"channelTables": "Kanaltabellen",
		"mediaChannels": "Medienkanäle",
		"templates": "Vorlagen",
		"apiTemplates": "API-Vorlagen",
		"pages": "Seiten",
		"imageresources": "Bilder",
		"videoresources": "Videos",
		"fontresources": "Schriftarten",
		"contexts": "Kontexte",
		"sharingContexts": "Ressourcen-Anbieter",
		"accounts": "Konten",
		"access": "Zugriff",
		"themeImport": "Themen",
		"themes": "Themen",
		"usersAndAccess": "Benutzer & Zugriff",
		"userAccounts": "Benutzerkonten",
		"apiAccounts": "API-Konten",
		"owners": "Eigentümer",
		"areas": "Bereiche",
		"licenses": "Lizensen",
		"products": "Produkte",
		"roles": "Rollen",
		"roleManagement": "Rollen",
		"privilegeSets": "Berechtigungen",
		"requiredPermissions": "Sie besitzen nicht die benötigten Rechte für diese Ansicht",
		"systemConfig": "System Config",
		"tvSearch": "TV-Suche",
		"pmsConfiguration": "PMS-Konfiguration",
		"pmsLogEntries": "PMS-Protokolleinträge",
		"resourceSharing": "Teilen",
		"resourcePublishing": "Veröffentlichen",
		"publishAndShare": "Veröffentlichen & Teilen",
		"themePreview": "Vorschau",
		"watermark": "Wasserzeichen",
		"castManagement": "Cast-Management",
		"chromecasts": "Chromecasts",
		"chromecastConfig": "Chromecast-Konfiguration",
		"unregisteredCasts": "Nicht registrierte Casts",
		"activeCastSessions": "Aktive Sitzungen",
		"castConfiguration": "Konfiguration",
		"castStatus": "Status",
		"castStatistics": "Statistiken",
		"castEventLogs": "Eventprotokoll",
		"streamAllocator": "Stream-Zuordner",
		"streamManagement": "Streams",
		"streamAllocatorLog": "Stream-Zuordnerprotokoll",
		"deletedContexts": "Gelöschte Kontexte",
		"duplicateRegisteredClients": "Doppelt registrierte Kunden"
	},
	"appbar": {
		"helpAndSupport": "Hilfe & Support",
		"notifications": "Benachrichtigungen",
		"languages": "Sprachauswahl",
		"settings": "Einstellungen & Versorgung",
		"contextSelector": "Seite auswählen",
		"availableSites": "Verfügbare Seiten",
		"availableCustomers": "Verfügbare Kunden",
		"availableThemes": "Verfügbare Themen",
		"searchSites": "Nach Seiten suchen",
		"searchCustomers": "Kunden suchen",
		"searchThemes": "Suchthemen",
		"availableSharingContexts": "Verfügbare Ressourcenanbieter",
		"searchSharingContexts": "Ressourcenanbieter suchen"
	},
	"admin": {
		"admin": "Admin",
		"account": "Konto | Konten",
		"accountRemoved": "Konto erfolgreich entfernt",
		"accountRemoveFailed": "Konto wurde nicht entfernt",
		"accountCreated": "Neues Konto hinzugefügt",
		"accountSaved": "Zugriffsdaten gespeichert",
		"accountType": "Kontotyp",
		"accountEnabled": "Konto freigeschaltet",
		"accountAccess": "Kontozugriff",
		"apiAccount": "API-Konto | API-Konten",
		"deleteAccount": "Konto löschen",
		"removeAccount": "Konto entfernen",
		"removeAccessRights": "Zugriffsrechte entfernen",
		"removeAccessRightsInfo": "Zugriffsrechte für dieses Konto entfernen",
		"disableAPIAccount": "API-Konto deaktivieren",
		"enableAPIAccount": "API-Konto aktivieren",
		"addApiInfo": "Das neue API-Konto wird automatisch für den Zugriff freigeschaltet",
		"accountApiState": "Dieses API-Konto ist momentan {state}",
		"accountApiStateWithContextId": "Dieses API-Konto ist momentan {state} für contextId {contextId}",
		"disableApiAccountTitle": "API-Konto deaktivieren?",
		"disableApiAccountWarning": " Wenn Sie ein API-Konto deaktivieren, wird das Zugriffstoken dauerhaft aus dem System gelöscht. Sie können das Konto wieder aktivieren, aber dadurch wird ein neues Token erzeugt.",
		"disableApiAccountConfirm": "Bitte bestätigen Sie, dass Sie Ihr Zugriffstoken permanent löschen wollen.",
		"deleteUserAccountConfirm": "Bitte bestätigen Sie, dass Sie Ihr Konto entfernen möchten.",
		"deleteUserAccountTitle": "Benutzerkonto entfernen?",
		"deleteUserAccountSuccess": "Benutzerkonto entfernt",
		"deleteApiAccountSuccess": "API-Konto gelöscht",
		"deleteAccountGenericFailed": "Eintraq konnte nicht gelöscht werden!",
		"removedUserAccessSuccess": "Benutzerzugriff erfolgreich entfernt",
		"removedUserAccessFailed": "Benutzerzugriff konnte nicht entfernt werden",
		"saveAccountFailed": "Konnte Eintrag nicht speichern!",
		"accountDataSaved": "Kontodaten gespeichert",
		"apiTokenWarning": "Sie werden dieses Token nur ein Mal sehen! Speichern Sie das Token.",
		"apiTokenTitle": "API-Zugriffstoken",
		"customer": "Kunde | Kunden",
		"role": "Rolle | Rollen",
		"privilegeSet": "Berechtigung | Berechtigungen",
		"accountsWithRole": "{role}' zugeordnete Konten",
		"rolesWithProduct": "Rollen mit \"{product}\"",
		"site": "Seite | Seiten",
		"system": "System | Systeme",
		"country": "Land | Länder",
		"area": "Bereich | Bereiche",
		"brand": "Marke | Marken",
		"screensLimit": "Bildschirmlimit",
		"licensed": "Lizensert",
		"sitesLimit": "Seitenlimit",
		"deleteCustomer": "Kunde löschen",
		"deleteSite": "Seite löschen",
		"deleteLegacyWarningText": "WARNHINWEIS: Das Löschen einer externen Systemreferenz ist eine nicht wiederherstellbare Aktion.<br>Diese Aktion hat keine Auswirkungen auf das externe System, aber die Referenz wird entfernt und Ihr Konto kann keine Statusdaten mehr von diesem System sehen.<br>KEINES DIESER ELEMENTE KANN WIEDERHERGESTELLT WERDEN.",
		"deleteSiteWarningText": "WARNUNG: Das Löschen einer Site ist eine nicht wiederherstellbare Aktion.<br>Das Löschen einer Site löscht auch alle zugehörigen Daten, einschließlich: Bildschirme, Menü-Seiten, Bilder/Schriften, Vorlagen, Dokumente, Räume, Lizenzen und mehr.<br> KEINES DIESEN ITEMS können wiederhergestellt werden.",
		"deleteSiteWarningTextPPDS": "WARNHINWEIS: Das Löschen eines Kunden ist eine nicht wiederherstellbare Aktion.<br>Durch das Löschen eines Kunden werden auch alle zugehörigen Daten gelöscht, einschließlich: Bildschirme, Vorlagen, Bilder/Schriftarten, Zeitpläne und mehr.<br>KEINES DIESER ELEMENTE KANN WIEDERHERGESTELLT WERDEN.",
		"deleteSiteConfirmationLabel": "Geben Sie \"{title}\" in dieses Textfeld ein um die Löschung einzuleiten",
		"copyResources": "Ressourcen kopieren",
		"copyResourcesToSite": "Ressourcen kopieren nach {title}",
		"copyResourcesFromTo": "Alle Ressourcen von Website {from} zu Website {to} kopieren?",
		"moveSiteFromTo": "Website von Wiederverkäufer {from} zu Wiederverkäufer {to} verschieben?",
		"moveSite": "Website {title} verschieben",
		"provisioned": "Provisioniert",
		"maxSizeProvisioned": "Maximale Seitenanzahl provisioniert",
		"addReseller": "Reseller hinzufügen",
		"context": "Kontext | Kontexte",
		"contextType": "Kontexttyp | Kontexttypen",
		"systemRole": "Systemrolle | Systemrollen",
		"owner": "Besitzer | Besitzer",
		"license": "Lizenz | Lizenz | Lizensen",
		"totalLicenses": "Gesamtlizenzen",
		"feature": "Feature | Features",
		"changeSite": "Seite ändern",
		"product": "Produkt | Produkte",
		"itemId": "Element-ID",
		"customId": "Kunden-ID",
		"access": "Zugriff | Zugriffe",
		"reseller": "Reseller | Reseller | Reseller",
		"noResellerAccess": "Sie haben keinen Zugriff auf Reseller",
		"noDistributorAccess": "Sie haben keinen Zugriff auf Distributoren",
		"distributor": "Distributoren | Distributor | Distributoren",
		"domain": "Domains | Domain | Domains",
		"resource": "Ressource | Ressourcen",
		"accountName": "Kontoname",
		"displayName": "Anzeigename",
		"email": "Email",
		"phone": "Telefon",
		"formDisabledSSO": "Die Benutzerkontoeinstellungen wurden deaktiviert, da Ihre aktuelle Sitzung über einen externen Single-Sign-On-Anbieter angemeldet ist",
		"created": "Erstellt",
		"password": "Passwort",
		"repeatPassword": "Passwort wiederholen",
		"passwordHint": "Das Passwort muss aus mindestens 8 Zeichen mit einer Kombination aus Klein-/Großbuchstaben, numerischen Ziffern und Sonderzeichen bestehen",
		"addNewAccess": "Neuen Zugriff hinzufügen",
		"newAccount": "Neues Benutzerkonto",
		"addAccount": "{type} Konto hinzufügen",
		"newApiAccount": "Neues API-Konto",
		"editApiAccount": "Edit API account",
		"accessFetchFailed": "Kontozugriff fehlgeschlagen",
		"accessUpdateFailed": "Das Einrichten des Kontozugangs ist fehlgeschlagen",
		"unknownAccess": "Unbekannter Zugriff",
		"accessToFeatures": "Zugriff auf {features} Feature | Zugriff auf {features} Features",
		"passwordLength": "Passwort muss 8 Zeichen lang sein",
		"passwordMatch": "Passwörter müssen übereinstimmen",
		"contextAccess": "Kontextzugriff",
		"noFeaturesAccess": "Keine Features zu diesem Zugriff hinzugefügt",
		"removeAccess": "Zugriff entfernen",
		"home": "Startseite",
		"read": "Lesen",
		"create": "Erstellen",
		"update": "Aktualisieren",
		"delete": "Löschen",
		"oneAccessRelation": "Mindestens eine Zugriffsbeziehung benötigt",
		"editLicense": "Lizenz bearbeiten",
		"contractRef": "Kontrakt Ref",
		"activationDate": "Aktivierungsdatum",
		"expirationDate": "Ablaufdatum",
		"noFeaturesSelected": "Keine Features ausgewählt",
		"availableFeatures": "Verfügbare Features",
		"expirationAfter": "Ablaufdatum muss nach Aktivierungsdatum, kommen",
		"newLicense": "Neue Lizenz",
		"userAccount": "Benutzerkonto",
		"userAdminError": "Adminrechte konnten nicht hinzugefügt werden",
		"createUserAccount": "Neues Benutzerkonto erstellen",
		"noAvailableUsers": "Keine verfügbaren Benutzerkonten",
		"selectUser": "Benutzerkonto auswählen",
		"selectUserHelp": " Wählen Sie hier einen Benutzer aus, wenn Sie ihm Admin-Zugriff auf den ausgewählten Kontext und die Funktionen dieser neuen Lizenz geben möchten.<br><br>Wenn dieser Kontext an einen Partner zur Pflege und Einrichtung übergeben wird, können Sie hier einen neuen Admin-Benutzer anlegen.<br><br>Sie können auch Ihren eigenen Benutzer auswählen, wenn Sie volle Admin-Rechte für diesen neuen Kontext haben möchten.<br><br>Oder Sie können das Feld leer lassen, um diesen Schritt ganz zu überspringen.",
		"createNewContext": "Neuen Kontext erstellen",
		"noAvailableContexts": "Keine verfügbaren Kontexte",
		"selectContext": "Kontext auswählen",
		"selectContextHelp": " Ein Kontext kann eine Instanz von Enterprise CTRL oder ein Arbeitsbereich für einen Ressourcenmanager sein und wird für eine Lizenz benötigt.<br><br>Bitte wählen Sie jetzt einen aus oder erstellen Sie einen.",
		"createNewSite": "Neue Seite erstellen",
		"noAvailableSites": "Keine verfügbaren Seiten",
		"selectSite": "Seite auswählen",
		"selectSiteHelp": " Ein Standort ist ein physischer Ort, an dem ein Kontext existiert - ein Hotel zum Beispiel.<br><br>In diesem Schritt können Sie einen Standort auswählen oder erstellen, der für diese neue Lizenz verwendet werden soll.",
		"deploymentInfo": " Ein Standard-Unternehmenseinsatz mit einem zugehörigen Standort",
		"managedBy": "Verwaltet von",
		"deployedAt": "Eingesetzt bei",
		"locatedIn": "Standort in",
		"belongsTo": "Gehört zu",
		"streetAddress": "Adresse",
		"postalCode": "Postleitzahl",
		"contactName": "Kontaktname",
		"contactEmail": "Kontakt-Email",
		"contactPhone": "Kontaktnummer",
		"contactInfo": "Kontaktinfo",
		"defaultLanguage": "Standardsprache",
		"availableLanguages": "Verfügbare Sprachen",
		"createAccount": "Konto erstellen",
		"createArea": "Bereich erstellen",
		"createLicense": "Lizenz erstellen",
		"createDistributor": "Distributoren erstellen",
		"createDomain": "Domain erstellen",
		"createReseller": "Reseller erstellen",
		"lastChange": "Letzte Änderung",
		"createOwner": "Besitzer erstellen",
		"createProduct": "Produkt erstellen",
		"createSite": "Seite erstellen",
		"createCustomer": "Kunde erstellen",
		"createResource": "Ressource erstellen",
		"createTheme": "Thema erstellen",
		"createSharingContext": "Ressourcenanbieter erstellen",
		"createRole": "Rolle erstellen",
		"roleName": "Rollenname",
		"newRole": "Neue Rolle",
		"newPrivilegeSet": "Neue Berechtigungen",
		"newDistributor": "Neuer Distributor",
		"newDomain": "Neue Domain",
		"selectRole": "Rollen auswählen",
		"noRolesAccess": "Keine Rollen zu diesem Zugriff hinzugefügt",
		"removeRole": "Entfernen",
		"accessToRoles": "{roles} Rolle | {roles} Rollen",
		"availableRole": "Verfügbare Rolle | Verfügbare Rollen",
		"limits": "Limit | Limits",
		"oldPassword": " Altes Passwort",
		"newPassword": "Neues Passwort",
		"repeatNewPassword": "Neues Passwort wiederholen",
		"vendorConfig": "Verkäuferkonfiguration",
		"usingCustomSupportInfo": "Benutzerdefinierter Support Info",
		"myAccount": "Mein Konto",
		"userSaveFailedGeneral": "Konnte Benutzerdaten nicht speichern",
		"userSaveFailedEmail": "Konnte Email-Adresse nicht speichern",
		"userChangeEmailHeader": "Sie müssen Ihr Passwort eingeben um Ihre Email-Adresse zu ändern",
		"updateEmail": "Email aktualisieren",
		"userSaveFailedPassword": "Konnte Passwort nicht aktualisieren",
		"userUpdatePasswordTitle": "Passwort aktualisieren",
		"userUpdatePasswordDescription": "Falls erfolgreich werden Sie aus allen aktiven Sitzungen abgemeldet. Fortfahren?",
		"changeYourPassword": "Ihr Passwort ändern",
		"changePassword": "Passwort ändern",
		"share": "Teilen",
		"addNewShare": "Teilen mit...",
		"addApiAccount": "API-Konto hinzufügen",
		"addUserAccount": "Benutzerkonten hinzufügen",
		"resellerLicenseInfoSite": " Reseller können Standorte und Lizenzen bereitstellen. Sie verwalten",
		"resellerLicenseInfoCustomer": "Wiederverkäufer können Kunden und Lizenzen bereitstellen. Sie verwalten",
		"distributorLicenseInfo": "Distributoren können Reseller bereitstellen. Sie verwalten",
		"newReseller": "Neuer Reseller",
		"editAccessFor": "Zugriff bearbeiten für",
		"editAccessToReseller": "Zugriff für Reseller bearbeiten",
		"distributorAccess": "Distributoren-Zugriff",
		"domainAccess": "Domainzugang",
		"resellerAccess": "Reseller-Zugriff",
		"themeAccess": "Themenzugriff",
		"siteAccess": "Seitenzugriff",
		"deleteDistributorText": "Ein Händler kann nur gelöscht werden, wenn er keine Wiederverkäufer und keine bereitgestellten Themen hat.<br>Bitte bestätigen Sie, dass Sie diesen Händler löschen möchten?",
		"deleteDistributorFailedText": "Der Händler konnte nicht gelöscht werden: {details}",
		"distributorHasProvisionedContext": "es gibt bereitgestellte Wiederverkäufer und/oder Themen, die an den Händler gebunden sind",
		"deleteDomainText": "Eine Domain kann nur gelöscht werden, wenn sie keine Wiederverkäufer und keine bereitgestellten Themen hat.<br>Bitte bestätigen Sie, dass Sie diese Domain löschen möchten?",
		"deleteDomainFailedText": "Die Domain konnte nicht gelöscht werden: {details}",
		"domainHasProvisionedContext": "es gibt bereitgestellte Händler und/oder Themen, die an die Domain gebunden sind",
		"externalSystemsHeading": "Externe Systeminformationen können hier bearbeitet werden",
		"createPrivilegeSet": "Berechtigungen erstellen",
		"privilegeSetName": "Name der Berechtigung",
		"systemPrivilegeSet": "Systemberechtigung",
		"noPrivilegeSetsAdded": "Es wurden neue Berechtigungen hinzugefügt",
		"chooseTypePrivilegeSet": "Wählen Sie einen \"Kontexttyp\", bevor Sie Berechtigungen auswählen",
		"forceSSO": "SSO erzwingen",
		"forceSSOHint": "Verwenden Sie diese Option, wenn Sie den Benutzerzugang auf einen bestimmten SSO-Drittanbieter beschränken möchten",
		"idp": "SSO erzwingen",
		"copyRole": "Von der bestehenden Rolle kopieren",
		"roleProductsHint": "Diese Rolle ist nur für die ausgewählten Produkte verfügbar",
		"roleProductDisabledHint": "Gilt nur für Nicht-System-Bereitstellungsrollen",
		"copyOfName": "Kopie von {name}",
		"contextPrivilegeSetSelectionTitle": "Zugangsauswahl",
		"contextPrivilegeSelectionDescription": "Wählen Sie aus, zu welchen Portalmenüs und Untermenüs diese Rolle Zugang haben soll",
		"deletedContext": "Kontext löschen",
		"finalizeDelete": "Löschvorgang abschließen",
		"undeleteConfirmMsg": "Sind Sie sicher, dass Sie diesen Kontext wiederherstellen möchten? | Sind Sie sicher, dass Sie diese {amount} Kontexte wiederherstellen möchten?",
		"undelete": "Wiederherstellen",
		"undeleteSuccess": "Kontext wiederhergestellt | {amount} Kontexte wiederhergestellt",
		"undeleteFailed": "Wiederherstellen fehlgeschlagen",
		"finalizeDeleteConfirmMsg": "Sind Sie sicher, dass Sie das Löschen dieses Kontexts abschließen möchten? | Sind Sie sicher, dass Sie das Löschen dieser {amount} Kontexte abschließen möchten?",
		"finalizeDeleteSuccess": "Löschen des Kontexts fehlgeschlagen | {amount} Löschen der Kontexte fehlgeschlagen",
		"finalizeDeleteFailed": "Abschließen des Löschvorgangs fehlgeschlagen",
		"activateSite": "Website aktivieren",
		"activateSiteDescription": "Durch die Aktivierung einer Website werden alle Wasserzeichen entfernt und der Abrechnungsplan gestartet",
		"activateSiteConfirmTex": "Ich weiß, dass diese Maßnahme endgültig ist",
		"siteActivated": "Website aktiviert",
		"duplicateRegisteredClients": "Doppelt registrierter Kunde",
		"userId": "Benutzer-ID",
		"copiedAuthToken": "Authentifizierungstoken kopiert",
	},
	"hospitality": {
		"hospitality": "Gastgewerbe",
		"room": "Zimmer | Zimmer",
		"additionalRooms": "Zusätzliche Zimmer",
		"occupancy": "Belegung",
		"guest": "Gast | Gäste",
		"wakeup": "Aufwachen | Aufwachen",
		"guestMessage": "Nachrichten | Nachricht | Nachrichten",
		"stay": "Übernachtung | Übernachtungen",
		"account": "Konto | Konten",
		"virtualRemoteClients": {
			"session": "Sitzung | Sitzungen",
			"roomName": "Zimmer",
			"screenName": "TV",
			"sessionId": "Sitzungs-ID",
			"remoteClientId": "VRC ID",
			"created": "Erstellt in",
			"tvOnline": "TV Online",
			"vrcOnline": "VRC Online"
		},
		"config": {
			"chromecastCheckoutLabel": "Chromecast-Checkout",
			"chromecastCheckoutExplanation": "Mit dem Zimmer verknüpfte Chromecasts beim Checkout des Gastes zurücksetzen.",
			"chromecastFacilityIdLabel": "Chromecast-Facility-ID",
			"chromecastTokenLabel": "Chromecast-Token",
			"serviceMenuCodeLabel": "Service-Menücode",
			"nightTimeToggleLabel": "Nachtzeit",
			"nightTimeInfo": "Zeitraum, in dem Fernsehgeräte für alle Events außer Weckzeiten nicht eingeschaltet werden.",
			"nightTimeFromLabel": "Nachtzeit von",
			"nightTimeToLabel": "Nachtzeit bis"
		}
	},
	"dashboard": {
		"welcome": "Willkommen {name}",
		"signedInto": "Sie sind momentan auf der Seite {site} angemeldet",
		"occupancyLastNextWeek": "Belegung letzte & nächste Woche",
		"upcomingWakeups": "Anstehendes Aufwachen",
		"mediaChannels": "Medienkanäle definiert",
		"activeInstallCodes": "Aktive Installationscodes!",
		"newIssuesLastDays": "Neue Fehler in den letzten {days} Tagen",
		"newLastDays": "Neu in den letzten {days} Tagen",
		"account": "Benutzerkonto | Benutzerkonten",
		"apiAccount": "API-Konto | API-Konten",
		"occupancyPercent": "Belegung (%)",
		"sectionHelp": " Schließen und öffnen Sie Dashboard-Abschnitte, indem Sie auf ihre jeweilige Überschrift klicken",
		"filterSites": "Seiten filter nach",
		"filterCustomers": "Kunden filtern nach",
		"clickOnMap": "Klicken Sie auf einen Artikel für Details",
		"displayingData": "Daten für {amount} Seite wird angezeigt | Daten für {amount} Seiten werden angezeigt",
		"noFeaturesActivated": "Keine Features aktiviert...",
		"clearSelection": "Auswahl aufheben",
		"details": "Details",
		"openExternal": "Verwaltungsportal öffnen",
		"openSite": "Seite öffnen",
		"openSiteError": "Kann Seite vom Typ {type} nicht öffnen",
		"openTvPreview": "TV-Vorschau öffnen",
		"notManaged": "Nicht verwaltet",
		"vacant": "leer",
		"managementAccess": "Verwaltungszugriff",
		"mergeCCs": "Verfälschte Casts zusammenführen",
		"mapView": "Kartenansicht",
		"listView": "Listenansicht",
		"stats": "Statistik",
		"map": "Karte",
		"list": "Liste",
		"noReportGenerated": "Kein Bericht generiert",
		"buildReport": "Bericht erstellen",
		"rebuildReport": "Bericht neu erstellen",
		"generateStatisticsReport": "Statistikbericht erstellen",
		"processingData": "Die Daten werden verarbeitet ...",
		"configureFields": "Felder konfigurieren",
		"downloadSelected": "Download ausgewählt",
		"searchAllFields": "Alle sichtbaren Felder durchsuchen",
		"cluster": "Cluster",
		"installedTvs": "Installierte TVs",
		"product": "PRODUKT",
		"filter": "FILTER",
		"hideDemos": "Demos ausblenden",
		"hideWhitelabeled": "Whitelabel ausblenden",
		"hideWatermarked": "Wasserzeichen ausblenden",
		"licenseInfo": "Lizenzangaben",
		"tvLicenses": "TV-Lizenzen",
		"signageLicenses": "Signage-Lizenzen",
		"castLicenses": "Cast-Lizenzen",
		"products": "Produkte"
	},
	"drawer": {
		"userSettings": "Benutzereinstellungen",
		"contextButtonHelp": " Klicken Sie auf das Hamburger-Menü in der oberen Leiste, um zu einer anderen Seite oder zum Startbildschirm zu wechseln",
		"mainHelp": "Bewegen Sie den Mauszeiger über das linke Menü, um es aufzuklappen; dieses Menü enthält die Hauptnavigationselemente für die aktuelle Seite oder den Startbildschirm"
	},
	"userMenu": {
		"theme": "Thema",
		"dark": "Dunkel",
		"notification": "Benachrichtigung | Benachrichtigungen",
		"pinMenu": "Pin-Menü",
		"all": "Alle",
		"success": "Erfolgreich",
		"info": "Info",
		"resetHints": "Hinweise zurücksetzen",
		"settings": "Kontoeinstellungen",
		"logOut": "Abmelden",
		"confirmLogout": "Bitte bestätigen",
		"hintsReset": "Hinweise wurden zurückgesetzt",
		"timeDisplay": "12 Uhr"
	},
	"reorderList": {
		"dragDropHelp": "Listenartikel per Drag and drop neu ordnen"
	},
	"baseTable": {
		"selected": "{selected} ausgewählter {type} | {selected} ausgewählte {type}n",
		"enableMultiselect": "Multiauswahl aktivieren",
		"disableMultiselect": "Multiauswahl deaktivieren",
		"searchLabel": "Nach allen sichtbaren Feldern suchen",
		"searchTooltip": "Keywords mit '&' verbinden",
		"export": "Zu Excel exportieren"
	},
	"channelTables": {
		"channelTable": "TV-Kanal Tabelle | TV-Kanal Tabellen",
		"radioChannelTable": "Radiokanal Tabelle | Radiokanal Tabellen",
		"channelTableGroup": "Kanaltabelle Gruppe | Kanaltabelle Gruppen",
		"tableGroup": "Tabellengruppe | Tabellengruppen",
		"dragDropHere": "Kanäle per Drag and Drop nach hier ziehen",
		"newName": "Neuer Name",
		"availableChannels": "Verfügbare Kanäle",
		"addAllChannels": "Alle Kanäle hinzufügen",
		"selectedChannels": "Ausgewählte Kanäle",
		"removeAllChannels": "Alle Kanäle entfernen",
		"unknownChannel": "Unbekannter Kanal",
		"noGroups": "Keine Gruppen in dieser Tabelle",
		"table": "Tabelle | Tabellen",
		"clickToChange": "Hier klicken um Tabelle zu ändern",
		"createFirst": "Erstellen Sie Ihre erste Kanaltabelle",
		"headerSelectHint": "Klicken Sie auf den Tabellennamen um eine andere Tabelle auszuwählen",
		"dragDropChannels": "Kanäle per Drag und Drop ordnen",
		"channelTableName": "Kanaltabelle Name",
		"new": "Neu",
		"deleteText": "Kanaltabelle löschen \"{name}\"?<br>Bitte versichern Sie sich, dass diese Kanaltabelle in keiner Bildschirmkonfiguration verwendet wird.",
		"saveGroupError": "Kann Gruppe nicht speichern",
		"saveGroupChannelsError": "Fehler beim Speichern von Kanälen in Gruppen",
		"saveChannelsError": "Fehler beim Speichern von Kanälen",
		"appendToGroupError": "Fehler beim Speichern in Gruppe",
		"createGroupError": "Fehler beim Erstellen einer Gruppe",
		"createdGroup": "Gruppe {name} erstellt",
		"deletedGroup": "Gruppe {name} gelöscht",
		"channelsSaved": "Gespeicherte Kanäle"
	},
	"event": {
		"defaultEventValues": "Standardeventwerte | Standardeventwerte",
		"eventDeleteSuccess": "Event erfolgreich gelöscht",
		"eventDeleteFailed": "Event konnte nicht gelöscht werden",
		"createEvent": "Event erstellen",
		"editEvent": "Event bearbeiten",
		"deleteEvent": "Event löschen",
		"eventPrivate": "Privat",
		"startTime": "Startzeit",
		"startDate": "Startdatum",
		"endTime": "Enduhrzeit",
		"endDate": "Enddatum",
		"eventHeader": "Events – {date}",
		"eventPrivateHint": "(Titel und Host auf Wegbeschreibungen und Besprechungsbildschirmen ausblenden)",
		"eventInFuture": "Das Event muss in der Zukunft liegen",
		"eventEndBeforeStart": "Das Event muss nach der Startzeit enden",
		"eventOverlappingDescription": "An diesem Ort existiert bereits ein überlappendes Event. Sind Sie sicher, dass Sie dieses Event erstellen möchten?",
		"eventOverlapping": "Überlappende Events",
		"copyEvent": "Event {name} kopieren",
		"copyEventToLocation": "Event an den Ort kopieren",
		"makeCopyEvent": "Eine Kopie des Events erstellen",
		"privateTitle": "Privater Titel",
		"noLocations": "Kein Ort gefunden",
		"wayfindingConfiguration": "Richtungskonfiguration | Richtungskonfigurationen",
		"saveWayfindingConfig": "Richtungskonfiguration speichern",
		"saveWayfindingConfigFailed": "",
		"integrationLocations": "Integrationsort | Integrationsorte",
		"manageLogos": "Logos verwalten",
		"selectLogo": "Logo auswählen",
		"replaceLogo": "Logo ersetzen",
		"removeLogo": "Logo entfernen",
		"manageIcons": "Symbole verwalten",
		"eventRetention": "Speicherung des Events",
		"eventRetentionDescription": "Anzahl der Tage, die Events nach Abschluss im System gespeichert werden.",
		"maxEventDuration": "Dauer",
		"maxEventDurationDescription": "Maximale Dauer des Events bei Buchung über Zimmertafel",
		"applyLogoByHost": "Logo nach Hostnamen anwenden",
		"logoMappingDesc": "Verwende Sie beim Erstellen einer Veranstaltung automatisch ein Logo auf der Basis des Hostnamens. Denken Sie daran, Host Mapping zum Logo hinzuzufügen, damit dies funktioniert.",
		"hostMapping": "Host-Mapping",
		"addHostMapping": "Hostzuordnung hinzufügen",
		"failedToSaveMapping": "Mapping konnte nicht gespeichert werden",
		"mappingAlreadyExists": "Auf einem Logo ist bereits eine Hostzuordnung vorhanden"
	},
	"tvMenu": {
		"tvMenuPage": "TV-Menüseite | TV-Menüseiten",
		"menuPage": "Menüseite | Menüseiten",
		"subtitle": "Untertitel | Untertitel",
		"audioLanguage": "Audiosprache | Audiosprachen",
		"subtitleLanguage": "Untertitelsprache | Untertitelsprachen",
		"epgMenu": "EPG-Menü | EPG-Menüs",
		"offlineMenu": "Offline-Menü | Offline-Menüs",
		"externalInput": "Ext Inputmenü | Ext Inputmenüs",
		"wakeupMenu": "Aufwachreihenfolge Menü | Aufwachreihenfolge Menüs",
		"defaultWakeupAlarmMenu": "Menü Standard-Wakeup-Alarm",
		"powerOnMenu": "Menü einschalten | Menüs einschalten",
		"messageNotificationMenu": "Menü Benachrichtigungen",
		"messageDisplayMenu": "Menü Nachrichtenanzeige"
	},
	"documents": {
		"page": "Seite | Seiten",
		"document": "Dokument | Dokumente",
		"selectPage": "Seite auswählen"
	},
	"resources": {
		"resource": "Ressource | Ressourcen",
		"font": "Schriftart | Schriftarten",
		"image": "Bild | Bilder",
		"video": "Video | Videos",
		"playlist": "Playlist | Playlists",
		"template": "Vorlage | Vorlagen",
		"compositeMenuPages": "Signage-Vorlagen-Seite | Signage-Vorlagen-Seiten",
		"logo": "Logo | Logos",
		"wayfindingIcon": "Richtungssymbol | Richtungssymbole",
		"uploadedYet": "Noch keine {type}n hochgeladen",
		"selected": "{amount} {type} ausgewählt | {amount} {type}n ausgewählt",
		"copyUrl": "URL kopieren",
		"copyResources": "Ressource nach {dest} kopieren? | {count} Ressourcen nach {dest} kopieren?",
		"moveResources": "Ressource in Ordner {dest} verschieben? | {count} Ressourcen in Ordner {dest} verschieben?",
		"moveResource": "{src} in Ordner {dst} verschieben?",
		"copying": "Kopiert",
		"copyToFolderSuccess": "Kopieren {resourceName} in Ordner {folderName} erfolgreich",
		"copyToFolderFailure": "Kopieren {resourceName} in Ordner {folderName} fehlgeschlagen",
		"copyFinishedWithError": "Mit Fehler abgeschlossen",
		"copySuccessDescription": " | {amount} Ressourcen erfolgreich nach {context} kopiert | {amount} Ressourcen erfolgreich nach {context} kopiert",
		"circularReferenceErrorMsg": "{amount} {type} haben Zirkelverweise, bitte bearbeiten/entfernen Sie diese, um das Speichern zu ermöglichen.",
		"failedToCopy": "Kopieren fehlgeschlagen",
		"downloadFile": "Datei herunterladen | Dateien herunterladen",
		"downloadingName": "{name} wird heruntergeladen",
		"downloadFailed": "Download fehlgeschlagen!",
		"selectImage": "Bild auswählen",
		"selectFont": "Schriftart auswählen",
		"selectVideo": "Video auswählen",
		"newPlaylist": "Neue Playlist",
		"upload": "Hochladen",
		"uploadMedia": "Medien hochladen",
		"uploadResource": "Ressource hochladen",
		"uploadFileType": "Upload {type} | Upload {type}(s)",
		"uploadSuccessful": "Upload erfolgreich - {type} wird verarbeitet",
		"uploadError": "Fehler beim Hochladen – bitte erneut versuchen",
		"replaceResource": "{resource} ersetzen",
		"replaceResourceTitle": "{resource} ersetzen: {name}",
		"replaceResourceDescription": "WARNHINWEIS! Ersetzte Elemente werden weiterhin in der Cloud zwischengespeichert, sodass es einige Zeit dauern wird, bis das neue Bild auf den Bildschirmen angezeigt wird.<br><br>Sind Sie sicher, dass Sie das aktuelle {resource} durch <br>\"{name}\" ersetzen möchten?",
		"resourceReplaced": "{resource} ersetzt",
		"resourceReplacedFailed": "{resource} konnte nicht ersetzt werden",
		"editImageBefore": "Bild vor dem Hochladen bearbeiten",
		"uploaderTitle": "{type}n hochladen",
		"unsupportedFile": "Dateien vom Typ {type} werden nicht unterstützt",
		"unsupportedFileSize": "Dateien dürfen nicht größer sein als {size}",
		"unsupportedFileNameAndSize": "Datei {fileName} dürfen nicht größer sein als {size}",
		"exceedingMaxPdfPages": "Die maximale Anzahl von {limit} Seiten in der PDF wird überschritten",
		"wrongType": "Nur {type}n können hochgeladen werden",
		"quickBrownFox": " Franz jagt im komplett verwahrlosten Taxi quer durch Bayern",
		"addOneOrMore": "Bilder, Videos und Schriftarten die Sie hochladen möchten hinzufügen",
		"addOneOrMoreExcludeVideos": "Fügen Sie die Bilder, Schriftarten und PDFs hinzu, die Sie hochladen möchten",
		"addType": "Fügen Sie die {type} hinzu, die Sie hochladen möchten | Fügen Sie die {type} hinzu, die Sie hochladen möchten",
		"pressUpload": "Hochladen um die Dateien zum System hinzuzufügen",
		"rotateLeft": "45 Grad links drehen",
		"rotateRight": "45 Grad rechts drehen",
		"crop": "Zuschneiden",
		"addResources": "Ressourcen hinzufügen",
		"uploadSuccessfulHover": "Erfolgreich hochgeladen",
		"addDocument": "Dokument hinzufügen",
		"addTemplate": "Vorlage hinzufügen",
		"potentialUsageFound": "Die folgende potenzielle Nutzungsmöglichkeit wurde gefunden:",
		"potentialUsageFoundInItems": "Potentielle Nutzungsmöglichkeit wurde für diese Objekte gefunden:",
		"folderNotEmpty": "Ordner ist nicht leer",
		"deleteFolderWithUsage": "Diese Ordner enthalten Objekte mit potientieller Nutzungsmöglichkeit",
		"deleteFolderWarning": "Löschen dieses Ordners wird auch seinen Inhalt, eingeschlossen Unterordner und Ressourcen löschen.",
		"deleteFolderConfirm": "Ich bestätige, dass ich diesen Ordner und seine Inhalte permanent löschen möchte",
		"newPlaylistFromPdf": "Neue Playlist von PDF",
		"displayDuration": "Anzeigedauer",
		"pdfUploadFolderError": "Fehler beim Erstellen des Medienordners für PDF, Hochladen in den Medienstammordner",
		"tag": "Tag | Tags",
		"addTag": "Tag hinzufügen",
		"transcodeVideo4K": "Das Video wird kurz nach dem hochladen in 4K verfügbar sein",
		"transcodeVideoHD": "Das Video wird kurz nach dem hochladen in HD verfügbar sein"
	},
	"mediaChannels": {
		"mediaChannels": "Medienkanäle",
		"mediaChannel": "Medienkanal | Medienkanäle",
		"globalMediaChannels": "Globale Medienkanäle",
		"globalMapping": "Globale Zuordnung",
		"address": "Adresse",
		"port": "Port",
		"serviceId": "Service-ID",
		"frequency": "Frequenz (kHz)",
		"modulation": "Modulation",
		"symbolRate": "Symbolrate",
		"bandwidth": "Bandbreite",
		"satelliteId": "Satelliten-ID",
		"polarization": "Polarisation",
		"programNumber": "Programmnummer",
		"majorNumber": "Hauptkanal-Nummer",
		"minorNumber": "Nebenkanal-Nummer",
		"type": "Typ",
		"audioOnly": "Nur Audio",
		"createChannel": "{type} Kanal erstellen",
		"save": "Kanäle speichern",
		"saveTooltip": "Dies wird Kanäle aller Typen speichern",
		"clickToChange": "Hier klicken um Kanaltyp zu ändern",
		"reset": "Kanäle zurücksetzen und aktualisieren",
		"types": "Kanaltypen",
		"webRadioSearch": "Webradiosuche",
		"unknownBitrate": "Unbekannt",
		"placeholderChannelName": "Kanalname",
		"updateFailed": "Medienkanäle Update fehlgeschlagen",
		"deleteFailed": "Konnte Kanal nicht löschen!",
		"createFailed": "Konnte Kanal nicht erstellen!",
		"updateSuccess": "Medienkanäle aktualisiert!",
		"deleteSuccess": "Medienkanal gelöscht!",
		"createSuccess": "Medienkanal erstellt!",
		"validateFailed": "Ein Kanal vom Typ {type} hat einen leeren Namen"
	},
	"templates": {
		"templateErrorMessages": {
			"deserializationErrorMsg": " Template Engine konnte die API-Daten nicht analysieren. Überprüfen Sie die Einstellungen vom fixierten Parameter / Test / API Connector, um sicherzustellen, dass die richtigen Daten abgerufen werden."
		},
		"dataFetchErrorMessages": {
			"unknownHost": "URL ist unbekannt, wenn versucht wird, Daten von API abzurufen. Dies könnte durch einen Tippfehler in der API-Connector-URL und/oder vom fixierten/Testparameter verursacht werden.",
			"protocolError": "Fehler im HTTP-Protokoll beim Versuch, Daten von API abzurufen. Dies könnte durch einen Tippfehler in der API-Connector-URL und/oder vom fixierten/Testpfadparameter verursacht werden.",
			"unknownError": "Fehler beim Abrufen der API-Daten. Überprüfen Sie die API-Connector-/Fest-/Testparametereinstellungen.",
			"code300ErrorMsg": "Die API hat den Code 300 – Multiple Choices zurückgegeben, ein Hinweis darauf, dass für die angeforderte Ressource mehrere Optionen vorhanden sind. Test-/ feste Parametereinstellungen prüfen.",
			"code400ErrorMsg": "Die API hat den Code 400 – Ungültige Anfrage zurückgegeben. Die API konnte die Anfrage aufgrund einer ungültigen Syntax nicht verstehen. Connector / Test-/ feste Parametereinstellungen prüfen.",
			"code401ErrorMsg": "Die API hat den Code 401 zurückgegeben – Nicht autorisiert. Sie sind nicht berechtigt, diese Anfrage zu stellen. Überprüfen Sie den API-Connector auf korrekte Header",
			"code403ErrorMsg": "Die API hat den Statuscode 403 zurückgegeben – verboten. Dies könnte bedeuten, dass der Connector oder feste Parameter mit falscher, ungültiger oder veralteter Autorisierung eingerichtet sind.",
			"code404ErrorMsg": "Die API hat den Statuscode 404 zurückgegeben – Nicht gefunden. Dies kann bedeuten, dass die URL gültig ist, die Ressource selbst jedoch nicht existiert. APIs können diese Antwort auch anstelle von 403 senden, um das Vorhandensein einer Ressource vor einem nicht autorisierten Kunden zu verbergen. Stellen Sie sicher, dass die Einstellungen für Connector, Test und feste Parameter korrekt sind.",
			"code406ErrorMsg": "Die API hat den Code 406 zurückgegeben. Nicht zulässig. Die API hat keinen Inhalt gefunden, der den angegebenen Kriterien entspricht. Test-/ feste Parameter prüfen.",
			"code415ErrorMsg": "Die API hat den Code 415 zurückgegeben – Nicht unterstützter Medientyp. Überprüfen Sie die API-Connector-Einstellungen. Versuchen Sie, den benutzerdefinierten Header des Content-Typs auf ein von der API unterstütztes Format einzustellen",
			"code500ErrorMsg": "Die API hat den Statuscode 500 zurückgegeben – Interner Serverfehler. Test-/ feste Parametereinstellungen prüfen.",
			"apiTimeout": "Zeitüberschreitung bei der API-Antwort. Die API ist möglicherweise vorübergehend nicht verfügbar."
		},
		"serializationErrors": {
			"jsonParseError": "Die von der API zurückgegebenen JSON-Daten konnten nicht analysiert werden. Bitte überprüfen Sie die API-Connector-/festen Parametereinstellungen."
		},
		"internalErrors": {
			"code500ErrorMsg": "Es liegt ein Problem mit unseren Systemen vor. Bitte versuchen Sie es später noch einmal."
		},
		"noDataYet": "Noch keine Daten.",
		"noDataFoundYet": "Noch keine Daten gefunden!",
		"save": "Vorlage speichern ohne das Fenster zu schließen",
		"setATest": "Einen Test{name} festlegen",
		"infoUrlDefault": "URL zu API Infoseite",
		"infoUrlTooltip": "Wie Quelle benutzen",
		"error": "Fehler beim Auswerten der Vorlage",
		"httpMethodRequired": "HTTP-Methode benötigt",
		"nameRequired": "Name benötigt",
		"paramType": "Parametertyp",
		"addItem": "Objekt hinzufügen",
		"preview": "Vorschau generieren",
		"template": "Vorlage | Vorlagen",
		"templateRequired": "Vorlage benötigt",
		"reset": "Änderungen an dieser Vorlage seit dem letzten Speichern zurücksetzen",
		"source": "Quelle | Quellen",
		"sourceRequired": "Quelle benötigt",
		"fixedParameters": "Fixierte Parameter",
		"setAFixed": "Einen fixierten {name} festlegen",
		"templateExample": "Vorlage Beispiel",
		"dataExample": "Datenprobe",
		"httpMethod": "HTTP-Methode",
		"markup": "Aufschlag",
		"sourceError": "Fehler: Quelle konnte nicht geladen werden",
		"selectConfigure": "Vorlage auswählen und konfigurieren",
		"failedSource": "Fehler: Vorlagenquelle konnte nicht gefunden werden",
		"failedSourceFetch": "Fehler: Quelleninfo konnte nicht abgerufen werden",
		"templateSource": "Vorlagenquelle | Vorlagenquellen",
		"rootName": "Root Name",
		"apiConnector": "API-Connector | API-Connectors",
		"createTemplate": "Vorlage erstellen",
		"createWithoutClosing": "Vorlage erstellen ohne das Fenster zu schließen",
		"saveWithoutClosing": "Vorlage speichern ohne das Fenster zu schließen",
		"fetchApiData": "API-Daten abrufen",
		"cacheForSeconds": "Zwischenspeicherung nicht aktiviert (0 Sekunden) | API-Vorlage für {value} Sekunde zwischenspeichern | API-Vorlage für {value} Sekunden zwischenspeichern",
		"cacheForSecondsError": "Die Zahl muss zwischen 0 und 1800 liegen",
		"defaultTemplateErrorExplanation": "Auf dem Fernseher anzuzeigender Inhalt, wenn die Vorlage Fehler enthält."
	},
	"configSchedules": {
		"defaultConfig": "Standard",
		"dailyOverrides": "Tägliche Überschreibungen",
		"weeklyOverrides": "Wöchentliche Überschreibungen",
		"editTimeslot": "Zeitfenster bearbeiten",
		"deleteTimeslot": "Zeitfenster löschen",
		"dragStopTime": "Zum Ändern der Stoppzeit ziehen",
		"defaultContentExplanation": "Diese Konfiguration läuft den ganzen Tag auf dem Bildschirm, sofern sie nicht durch tägliche, wöchentliche oder kalenderbasierte Konfigurationen überschrieben wird.",
		"editScheduleName": "Name des Zeitplans bearbeiten",
		"tabDefault": "Standard",
		"tabDaily": "Täglich",
		"tabWeekly": "Wöchentlich",
		"tabCalendar": "Kalender",
		"addTimeslotHelpText": "Klicken Sie, um ein neues Zeitfenster hinzuzufügen, oder klicken Sie einfach auf eine freie Stelle im Kalender."
	},
	"scheduleMapping": {
		"createNew": "Neue Planzuordnung erstellen",
		"rename": "{title} umbennen",
		"created": "Neue Planzuordnung erstellt",
		"updated": "Planzuordnung aktualisiert",
		"currentSchedule": "Momentaner Plan",
		"deleteHeading": "Planzuordnung {title} löschen?",
		"deleteText": "Alle Bildschirme die Teil dieser Zurordnung sind werden in die Standard-Planzuordnungsgruppe verschoben.",
		"addMapping": "Zuordnung hinzufügen",
		"screens": "Bildschirm | Bildschirme",
		"screensHeadingInfo": "TV-Bildschirme die ein Ruleset benutzen. Rechtsklick zum verschieben.",
		"moveTo": "Verschieben zu...",
		"rulesHeading": "Regeln werde in der Reihenfolge in der sie hier erscheinen ausgewertet",
		"rulesDescription": "Benutzerdefinierte Regeln werden noch nicht unterstützt.",
		"newRule": "Neue Regel",
		"defaultSchedule": "Standardplan",
		"screensMoved": "Bildschirme verschoben",
		"createFailed": "Mapping konnte nicht erstellt werden",
		"updateFailed": "Mapping konnte nicht aktualisiert werden"
	},
	"schedulePlans": {
		"editor": {
			"notInUse": "Zeitpläne werden nicht verwendet. Klicken Sie auf \"Erstellen\", um mit der Verwendung zu beginnen.\n Wir generieren automatisch einen \"Standard\"-Zeitplan, der zusätzlich zu dem von Ihnen erstellten neuen Zeitplan Ihre aktuelle Zeitplan-/Bildschirmeinrichtung enthält.",
			"newForEach": "Getrennte Zeitpläne für jeden Bildschirm",
			"singleNew": "Gemeinsamer Zeitplan für alle Bildschirme",
			"copyExisting": "Vorhandenen Zeitplan kopieren",
			"removeWhenActive": "<b>Dieser Zeitplan ist derzeit auf \"Aktiv\" eingestellt</b>. Wenn Sie ihn entfernen, werden alle Bildschirme auf den Standardplan umgestellt. <br><br> Sind Sie sicher, dass Sie diesen Zeitplan entfernen möchten?"
		},
		"screenEditor": {
			"newScheduleForEach": "Erstellen Sie für jeden Zeitplan einen neuen Zeitplan",
			"individualSelectionDescription": "Individuelle Auswahl"
		},
		"type": "Zeitplan | Zeitpläne",
		"setActive": "Auf aktiv gesetzt",
		"setActiveConfirmTitle": "Zeitplan auf aktive gesetzt?",
		"disabledDeleteForDefault": "\"Standardzeitplan löschen\" ist nur aktiviert, wenn keine anderen Zeitpläne vorhanden sind",
		"setActiveConfirmText": "Durch diese Aktion wird der Zeitplan \"{title}\" aktiviert. Für alle Bildschirme auf dieser Website sind unter \"Zeitplan {title}\" definierte Zeitpläne als aktive Zeitpläne festgelegt. Möchten Sie fortfahren?",
		"setActiveSuccess": "Zeitplan {name} auf aktiv gesetzt",
		"setActiveFailed": "Zeitplan {name} konnte nicht auf aktiv gesetzt werden"
	},
	"screens": {
		"screensMissingSchedulesTitle": "Bildschirme mit fehlenden Zeitplänen",
		"screensMissingSchedulesText": "Bei den folgenden Bildschirmen fehlen Zeitpläne. Dies kann durch die Unterbrechung des 2-stufigen Prozesses zum Erstellen/Verschieben von Bildschirmen verursacht worden sein.",
		"screenStatus": "Bildschirmstatus | Bildschirmstatus",
		"tvScreenStatus": "TV-Bildschirmstatus",
		"signageDisplayStatus": "Signage-Anzeigestatus",
		"screens": "Bildschirme",
		"livePreview": "Live-Vorschau",
		"displays": "Anzeigen",
		"onlineScreens": "Online TV-Bildschirme",
		"screenSetup": "Bildschirmsetup | Bildschirmsetups",
		"screenSetupCreated": "Bildschirmsetup erstellt",
		"screenSetupUpdated": "Bildschirmsetup aktualisiert",
		"defaultScreenConfig": "Standardkonfiguration",
		"tvScreen": "TV-Bildschirm | TV-Bildschirme",
		"signageScreen": "Signage-Bildschirm | Signage-Bildschirme",
		"tvType": "TV-Typ | TV-Typen",
		"clientType": "Kunden-Typ | Kunden-Typen",
		"tvSoftware": "TV-Software",
		"editName": "Name bearbeiten",
		"tvClient": "TV-Klient | TV-Klienten",
		"unknownSetup": "Unbekanntes Setup",
		"createFirstSchedule": "Erstellen Sie Ihren ersten Plan",
		"schedule": "Plan | Pläne",
		"copyFromConfig": "Aus Konfiguration kopieren (optional)",
		"quicklyCopyConfig": "Schnell eine andere Konfiguration kopieren",
		"mainMenu": "Hauptmenü | Hauptmenüs",
		"welcomeMenu": "Willkommensmenü | Willkommensmenüs",
		"channelMenu": "TV-Kanal Menü | TV-Kanal Menüs",
		"radioChannelMenu": "Radiokanal Menü | Radiokanal Menüs",
		"startVolume": "Startlautstärke",
		"maxVolume": "Maximale Lautstärke",
		"autoPowerOffHrs": "Automatische Abschaltung nach {hrs} Stunden",
		"enableAutoPowerOff": "Atomatische Abschaltung aktivieren",
		"audioLanguageMenu": "Audiosprache Menü",
		"subtitleLanguageMenu": "Untertitelsprache Menü",
		"externalInputMenu": "Externes Input Menü | Externes Input Menüs",
		"noScreenSetups": "Noch keine Bildschirmsetups erstellt",
		"selectSlotType": "Slottyp auswählen",
		"scheduleEditorCalendarHint": "Irgendwo auf dem Kalender klicken um einen neuen Zeitfenster hinzuzufügen",
		"editSlot": "Slot bearbeiten",
		"newSlot": "Neuer Slot",
		"createNewConfig": "Neue Konfiguration erstellen",
		"timeslotOverlap": "Die ausgewählte Start- und Endzeit überlappt mit einem anderen Zeitfenster. Bitte mit anderen Zeiten erneut versuchen.",
		"volume": "Lautstärke",
		"durationRequired": "Dauer benötigt",
		"durationLongerThan": "Dauer muss länger als 0 Sekunden sein",
		"messageInJson": "Nachricht im JSON-Format",
		"validJson": "Muss gültige JSON sein",
		"sendMessageToScreen": "{type} Nachricht an {screens} Bildschirm schicken | {type} Nachricht an {screens} Bildschirme schicken",
		"sendMessage": "Nachricht senden",
		"updateStatus": "Status aktualisieren",
		"pushConfig": "Push-Konfiguration",
		"pushContent": "Push-Inhalt",
		"upgradeAgentSoftware": "Agent SW erweitern",
		"upgradeAgentSoftwareNotification": "Dadurch wird eine Nachricht an den Agenten zur Aktualisierung der Software gesendet. Im Rahmen des Upgrades wird der Agent möglicherweise neu gestartet, was zu einem kurzen Ausfall des Agent-Dienstes führt. Bitte beachten Sie, dass keine Aktionen ausgeführt werden, wenn keine Updates verfügbar sind.",
		"poweredOn": "Angeschaltet",
		"poweredOff": "Ausgeschaltet",
		"powerOn": "An",
		"powerOff": "Aus",
		"reboot": "Neustart",
		"rebootRequired": "Das Anwenden der Bildschirmdrehung erfordert einen Neustart.<br><br>Möchten Sie jetzt einen Neustart durchführen?",
		"jvmUptime": "JVM-Einschaltzeit",
		"rebootRequested": "Neustart benötigt",
		"appUpdateRequested": "Applikationsupdate benötigt",
		"setVolume": "Lautstärke einstellen",
		"displayMessage": "Nachricht anzeigen",
		"displayAddress": "Adresse anzeigen",
		"displayName": "Name anzeigen",
		"resetSoftAP": "SoftAP-Passwort zurücksetzen",
		"displayPage": "Seite anzeigen",
		"tuneChannel": "Kanal einstellen",
		"enableDebug": "Debug aktivieren",
		"reAuthenticate": "Erneut authentifizieren",
		"reAuthenticateMsg": "Sie sind dabei, den Bildschirm {screen} erneut zu authentifizieren. Bitte bestätigen Sie, um fortzufahren.",
		"getScreenshot": "Screenshot erhalten",
		"sendPushMessage": "Push-Nachricht senden",
		"openClientPreview": "Client-Vorschau öffnen",
		"sendMgmntMessage": "Management-Nachricht senden",
		"moveToLocation": "An Ort verschieben",
		"changeSchedule": "Zeitplan ändern",
		"selectSchedule": "Zeitplan auswählen",
		"manage": "Verwalten",
		"moveScreens": "{screens} Bildschirm verschieben | {screens} Bildschirme verschieben",
		"deleteScreens": "{screens} Bildschirm löschen? | {screens} Bildschirme löschen?",
		"setContentScheduleForScreenExistingNameMsg": "Der Zeitplan mit dem Namen {screenName} ist bereits vorhanden. Ein neuer Zeitplan mit dem Namen {uniqueName} wird erstellt.",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Namenskonflikt in den folgenden Zeitplänen:<br>",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> - Neuer Zeitplan mit dem Namen <span style='font-style: italic;'>{uniqueName}</span> wird erstellt.<br>",
		"move": "Verschieben",
		"name": "Name",
		"ip": "IP",
		"mac": "MAC",
		"rotation": "Drehung",
		"deviceType": "Gerätetyp",
		"appVersion": "Appversion",
		"firmwareVersion": "Firmware Version",
		"osVersion": "OS-Version",
		"details": "Details",
		"occupied": "Beschäftigt",
		"online": "Online",
		"offline": "Offline",
		"power": "Stromzufuhr",
		"state": "Status",
		"public": "Öffentlich",
		"clientId": "Client-ID",
		"setRotation": "Drehung für Bildschirm festlegen | Drehung für {count} Bildschirme festlegen",
		"id": "Kunden-ID",
		"locationTitle": "Standort",
		"previewOnlyOne": "Bitte nur einen Bildschirm für die Vorschau auswählen",
		"screensMoved": "{screens} Bildschirm an einen anderen Ort verschoben| {screens} Bildschirme an einen anderen Ort verschoben",
		"installCode": "Installationscode | Installationscodes",
		"createInstallCode": "Installationscode erstellen",
		"code": "Code",
		"createSchedule": "Plan erstellen",
		"created": "Erstellt",
		"createdBy": "Erstellt von",
		"expiryDate": "Ablaufdatum",
		"configInUse": "Fehler: Die Konfiguration wird verwendet",
		"configInUseDescription": "{name} wird von den folgenden Zeitplänen verwendet:",
		"scheduleInUse": "Fehler: Der Zeitplan wird verwendet",
		"scheduleInUseDescription": "{name} wird von den folgenden Zeitplan-Mappings verwendet:",
		"configTvSettingsHeading": "TV-Einstellungen",
		"configChannelsHeading": "Kanäle",
		"configMenusHeading": "Menüs",
		"addConfiguration": "Konfiguration hinzufügen",
		"groupBy": "Gruppe von",
		"setSchedule": "Plan festlegen...",
		"upgradeApp": "Upgrade der Anwendung durchführen",
		"helpText": "Nachricht eingeben die beim Ausschalten angezeigt wird.<br> Wird keine Nachricht eingestellt, schalten sich die Bildschirme sofort aus..",
		"newTvScreen": "Neue TV-Bildschirme",
		"clientIdRequired": "Client-ID benötigt",
		"pickLocation": "Ort auswählen",
		"noLocation": "Keine verfügbaren Orte",
		"roomRequired": "Zimmer benötigt",
		"roomSearchPlaceholder": "Geben Sie etwas ein um zu suchen",
		"tvPreRegisteredOn": "TV vorregistriert an",
		"tvRegisteredOn": "TV registriert an",
		"tvRegisteredCloseText": "Bitte schließen Sie diese Registerkarte.",
		"pickContext": "Kontext auswählen",
		"noContext": "Es gibt keine verfügbaren Kontexte",
		"contextRequired": "Kontext benötigt",
		"clientToken": "Client-Token",
		"clientTokenRequired": "Client-Token benötigt",
		"couldNotFindItemToEdit": "Kein Objekt zum bearbeiten gefunden",
		"uptime": "Laufzeit",
		"memoryUsage": "Speichernutzung",
		"requestScreenshot": "Screenshot angefordert, bitte warten...",
		"requestScreenshotOffline": "Kann keinen Screenshot von Offline-Gerät anfordern...",
		"requestScreenshotPoweredOff": "Kann keinen Screenshot von ausgeschaltetem Gerät anfordern...",
		"requestNewScreenshot": "Klicken um neuen Screenshot anzufordern...",
		"requestUpdate": "Update anfordern",
		"serial": "Serie",
		"firmware": "Firmware",
		"resolution": "Auflösung",
		"system": "System",
		"publicIp": "Öffentliche IP",
		"softAP": "Soft AP",
		"currentConfig": "Momentane Konfiguration",
		"rcAddr": "RC-Adresse",
		"network": "Netzwerk",
		"timeOnDevice": "Zeit auf dem Gerät",
		"openPreview": "Vorschau öffnen",
		"screenInfo": "Bildschirminfo",
		"reloadAllMediaTitle": "Alle Medien neu laden",
		"reloadAllMediaDescription": "Alle heruntergeladenen Medien auf dem {amount} Gerät löschen und erneut herunterladen? | Alle heruntergeladenen Medien auf den {amount} Geräten löschen und erneut herunterladen?",
		"moveToScheduleTitle": "Planzuordnung festlegen",
		"changeScreenScheduleDescription": "Bildschirmzeitplan ändern in {mapping}?",
		"changeScreenScheduleSuccess": "Bildschirmzeitplan geändert in {mapping}",
		"moveToScheduleDescription": "{amount} Bildschirme zu Plan {mapping} verschieben?",
		"moveToScheduleSuccess": "Neuer Plan für {amount} Bildschirme festgelegt",
		"moveToSite": "Bildschirm auf Seite verschieben",
		"moveScreenToSiteMsg": "Achtung! Sie sin im Begriff diesen Bildschirm auf eine andere Seite zu verschieben, bitte bestätigen um fortzufahren.",
		"moveScreenToSiteSuccess": "Bildschirm auf neue Seite verschoben",
		"moveScreenToSiteNotAllowed": "Der Bildschirm kann nicht auf diese Seite verschoben werden",
		"moveScreenToSiteNotAllowedNoLicense": "Der Bildschirm darf nicht auf diese Website verschoben werden: Für die Website ist keine {license} Lizenz aktiviert.",
		"moveScreenToSiteNotAllowedLimitReached": "Der Bildschirm darf nicht auf diese Website verschoben werden: Das Lizenzlimit für diesen Bildschirmtyp wurde erreicht.",
		"createScreenLimitReachedMsg": "Neuer Bildschirm kann nicht erstellt werden. Das Lizenzlimit für diesen Bildschirmtyp wurde erreicht",
		"successfullyAuthenticated": "Bildschirm erfolgreich erneut authentifiziert",
		"canNotReAuthenticate": "Ein bereits authentifizierter Bildschirm kann nicht erneut authentifiziert werden",
		"appMode": "Anwendungsmodus",
		"forceUpdate": "Update erzwingen",
		"services": "Dienste",
		"upgradablePackages": "Erweiterbare Pakete",
		"authCodeNotFound": "Der Authentifizierungscode ist ungültig oder abgelaufen. Bitte überprüfen Sie den Bildschirm auf einen gültigen Code.",
		"lastReboot": "Letzter Neustart",
		"storage": "Speicher",
		"authCreateNewSchedule": "Dadurch wird ein neuer Zeitplan mit demselben Namen wie der erstellte Bildschirm erstellt und der Bildschirm in diesen Zeitplan eingefügt.<br>Wenn Sie dieses Kontrollkästchen deaktivieren, können Sie den Bildschirm zu einem bereits vorhandenen Zeitplan hinzufügen.",
		"authUseExistingSchedule": "Wählen Sie einen bereits vorhandenen Zeitplan aus, zu dem Sie Ihren neuen Bildschirm hinzufügen möchten:",
		"registrationCode": "Bildschirmregistrierungscode",
		"registrationCodeLength": "6-stellig"
	},
	"booking": {
		"booking": "Buchung | Buchungen",
		"numberOfBookings": "{number} Buchung | {number} Buchungen",
		"book": "Buchen",
		"bookNights": "{nights} Übernachtungen buchen",
		"night": "Nacht | Nächte",
		"stayFromTo": "{from} bis {to}",
		"stayCheckedIn": " – Anreise",
		"calendar": "Kalender",
		"availabilityError": "Konnte Buchungsverfügbarkeit nicht abrufen",
		"bookingsForDate": "Buchungen für {date}",
		"noBookingsForDate": "Keine Buchungen für {date}",
		"failedToRetrieve": "Konnte Buchungen nicht abrufen",
		"timeslotTaken": "Dieses Zeitfenster ist belegt, bitte wählen Sie ein anderes"
	},
	"guests": {
		"anonymous": "Anonym",
		"language": "Sprache",
		"unspecified": "Undefiniert",
		"pickRoom": "Wählen Sie ein verfügbares Zimmer",
		"checkInDate": "Anreisedatum",
		"checkInTime": "Anreisezeit",
		"checkOutDate": "Abreisedatum",
		"checkOutTime": "Abreisezeit",
		"newCheckIn": "Neue Anreise",
		"checkIn": "Anreise",
		"checkInGuest": "Gast einchecken",
		"editGuest": "Gast bearbeiten",
		"checkOut": "Abreise",
		"getBill": "Rechnung erhalten",
		"guestCheckOut": "Gast auschecken",
		"billItemName": "Objektname",
		"billItemAmountMax4Chars": "Menge",
		"billItemCurrencyMax3Chars": "Währung",
		"billDataError": "Konnte Rechnungsstatus nicht abrufen ({status})",
		"confirmCheckout": "Abreise bestätigen",
		"editStay": "Aufenthalt bearbeiten",
		"editBooking": "Buchung bearbeiten",
		"newBooking": "Neue Buchung",
		"checkInBeforeOut": "Abreise muss nach Anreise liegen.",
		"noGuests": "Keine Gäste",
		"overrideTvConfig": "TV-Konfig. überschreiben",
		"setTvConfigConfirm": "Löschen Sie die TV-Konfigurationszuordnung \"{configName}\" für den (die) ausgewählten {guestCnt} Gast (Gäste)?<br>Diese Konfiguration wird beim Checkout zurückgesetzt.",
		"clearTvConfigConfirm": "Löschen Sie die überschriebene TV-Konfigurationszuordnung für den (die) ausgewählten {guestCnt} Gast (Gäste)?<br>Dadurch wird die vom System festgelegte Konfigurationszuordnung angewendet.",
		"setTvConfigSuccess": "{amount} Fernseher wurde neu konfiguriert|{amount} Fernseher wurden neu konfiguriert",
		"sendMessage": "Nachricht senden",
		"guestsNumber": "{number} Gast | {number} Gäste",
		"person": "Person | Personen",
		"forceCheckOutTitle": "Abreise erzwingen",
		"forceCheckOutMessage": " Dieser Gast wurde automatisch über ein externes System eingecheckt. Das externe System ist für das erneute Auschecken des Gastes verantwortlich. Wenn Sie dieses Auschecken erzwingen, wird der Gast nicht aus dem externen System ausgecheckt, was zu Problemen führen kann. Fortfahren?"
	},
	"guestMessages": {
		"to": "Bis",
		"unreadMessages": "Ungelesene Nachrichten",
		"subject": "Betreff",
		"create": "Neue Nachricht",
		"view": "Nachricht anzeigen",
		"createdHeader": "Gesendet",
		"readHeader": "Lesen",
		"displayedHeader": "Benachrichtigt",
		"senderName": "Von",
		"instant": "Sofortige Anzeige",
		"messageTemplate": "Nachrichtenvorlage | Nachrichtenvorlagen",
		"useTemplate": "Vorlage verwenden"
	},
	"wakeups": {
		"roomAccount": "Zimmer (account)",
		"tvMenu": "angezeigtes TV-Menü",
		"customTvMenu": "Verwenden Sie zur Anzeige das benutzerdefinierte TV-Menü",
		"wakeupInFuture": "Aufwachzeit muss in der Zukunft liegen",
		"wakeupExists": "Wakeup bereits vorhanden",
		"create": "Aufwachzeit erstellen",
		"daily": "Täglich",
		"status": "Status",
		"delivered": "Zugestellt",
		"notDelivered": "Nicht zugestellt",
		"displayed": "Angezeigt",
		"notDisplayed": "Nicht angezeigt",
		"confirmed": "Bestätigt",
		"notConfirmed": "Nicht bestätigt",
		"editWakeup": "Aufwachzeit bearbeiten",
		"deliveredWakeup": "Aufwachzeit zugestellt",
		"newWakeup": "Neue Aufwachzeit"
	},
	"scheduledTasks": {
		"dailyNewSheets": "Täglich neues Bettzeug",
		"roomType": "Zimmerart | Zimmerarten",
		"allTypes": "Alle Arten",
		"roomTypeEmpty": "Frei lassen um  diese Aufgabe allen Räumen zuzuordnen",
		"when": "Wann",
		"createTask": "Aufgabe erstellen",
		"specificRooms": "Spezifische Zimmer"
	},
	"issues": {
		"maintenanceType": "Wartungsart | Wartungsarten",
		"changeStatus": "Status ändern",
		"setIssueTo": "Problem ändern auf...",
		"editIssue": "Problem bearbeiten",
		"created": "Erstellt",
		"closed": "Geschlossen",
		"issueSetTo": "Problem mit {room} geändert auf {status}",
		"issuesSetTo": "{issues} Probleme geändert auf {status}",
		"newIssue": "Neues Problem | Neue Probleme",
		"editingIssues": "{issues} Probleme bearbeiten",
		"issueUpdated": "{type} Problem mit {room} aktualisiert",
		"issueAdded": "{type} Problem hinzugefügt zu {room}",
		"urgent": "Dringend",
		"flagAsClosed": "Als geschlossen markieren",
		"issueSetup": "Problem Set-Up"
	},
	"locations": {
		"location": "Ort | Orte",
		"createRooms": "Create rooms",
		"createLocations": "Orte erstellen",
		"occupation": "Beschäftigung",
		"locatedIn": "Gelegen in",
		"locationSetTo": "{location} zu {status} geändert",
		"locationsSetTo": "{locations} Räume zu {status} geändert",
		"locationSetup": "Ort Set-Up",
		"category": "Kategorie | Kategorien",
		"setRoomTo": "{room} ändern zu...",
		"editRoom": "Zimmer bearbeiten",
		"changeStatus": "Status ändern",
		"changeCategory": "Kategorie ändern",
		"changeLocation": "Ort ändern",
		"addTag": "Tag hinzufügen",
		"removeTag": "Markierung entfernen",
		"addIssue": "Problem hinzufügen",
		"tagRemoved": "Markierung {tag} von {rooms} Zimmern entfernt",
		"tagAdded": "Makierung {tag} zu {rooms} Zimmern hinzugefügt",
		"categoryChange": "{room} Kategorie geändert zu {category} | {rooms} Kategorie geändert zu {category}",
		"newRooms": "Neue Zimmer",
		"newLocations": "Neue Orte",
		"roomNumber": "Zimmernummer",
		"locationNumber": "Ortsnummer",
		"startRange": "Startbereich",
		"endRange": "Endbereich",
		"parentLocations": "Hauptort",
		"startRangeRequired": "Startbereich ist benötigt",
		"startRangeLower": "Startbereich muss niedriger als Endbereich sein",
		"endRangeRequired": "Endbereich ist benötigt",
		"endRangeHigher": "Endbereich muss höher als Startbereich sein",
		"change": "Ändern",
		"newLocation": "Neuer ort",
		"changeLocationOf": "Ort von {room} Zimmer ändern | Ort von {rooms} Zimmern ändern",
		"locationChangeSuccess": "Ortsänderung erfolgreich",
		"roomTag": "Raumtag | Raumtags",
		"newTag": "Neuer Tag",
		"newStatus": "Neuer Status",
		"issueDeletedFrom": "Problem entfernt von {room}",
		"locationCategory": "Ortskategorie | Ortskategorien",
		"roomCategory": "Raumkategorie | Raumkategorien",
		"newCategory": "Neue Kategorie",
		"bedroomTv": "Schlafzimmer-TV",
		"meetingRoomTV": "TV Besprechungszimmer"
	},
	"serviceConfig": {
		"saveConfig": "Konfiguration speichern",
		"disableDailyRebootWarning": "Warnhinweis: Dadurch wird verhindert, dass einige Geräte automatische Software-Updates erhalten",
		"forceTvMode": "Dadurch werden beim Einschalten des Fernsehers die TV-Kanäle anstelle des konfigurierten Hauptmenüs angezeigt",
		"rebootTime": "Neustartzeit",
		"rebootTimeInvalid": "Ungültige Neustartzeit",
		"instantContentPush": "Sofortiger Content-Push",
		"instantContentPushDescription": "Wenn Sie dies aktivieren, werden alle Inhaltsänderungen sofort und ohne Aufforderung auf die betroffenen Geräte übertragen.",
		"useInternalURL": "Dadurch verwendet der Fernseher die interne TV-URL"
	},
	"login": {
		"terms": "Fristen",
		"resetPassword": "Passwort zurücksetzen",
		"language": "Sprache",
		"username": "Benutzername",
		"password": "Passwort",
		"logIn": "Anmelden",
		"cloudManagementPortal": "Verwaltungsportal",
		"enterUsername": "Benutzername und Passwort eingeben",
		"enterEmail": "Email-Adresse eingeben",
		"invalidEmail": "Email-Adresse ungültig",
		"resetMessage": "Weitere Anweisungen wurden an {email} gesendet",
		"resetFailedMessage": "Passwort zurücksetzen fehlgeschlagen: {message}",
		"setNewPassword": "Neues Passwort eingeben",
		"redirectToLoginPageTitle": "Zur Anmeldeseite weiterleiten",
		"redirectToLoginPageText": "Sie werden jetzt zur Anmeldeseite weitergeleitet."
	},
	"latLng": {
		"openMap": "Geöffnet",
		"lat": "Breitengrad",
		"lng": "Längengrad",
		"selectPosition": "Ort auswählen",
		"addressHint": "Sie können die Adresse manuell eingeben",
		"addressWarning": "Stellen Sie sicher, dass die Adresse mit Breiten- und Längengrad übereinstimmt",
		"searchFieldLabel": "Nach Adresse suchen",
		"address": "Adresse",
		"locationAddressErrorMessage": "Kontaktieren Sie Ihren Lizenzadministrator um Adressdaten hinzuzufügen",
		"maxLength": "Maximale Länge"
	},
	"folder": {
		"newResources": "Neue Ressourcen",
		"uploadFile": "Datei hochladen",
		"folder": "Ordner | Ordner",
		"noFolders": "Es gibt noch keine Ordner",
		"doubleClickSelect": "Doppelklick zum auswählen",
		"newFolder": "Neuer Ordner",
		"copy": "Kopieren",
		"cut": "Ausschneiden",
		"paste": "Einfügen",
		"selected": "{amount} ausgewählter {type} | {amount} ausgewählte {type}n",
		"deleting": "Löscht {amount} Objekt | Löscht {amount} Objekte",
		"root": "Root",
		"home": "Startseite",
		"emptyFolder": "Dieser Ordner ist leer",
		"createFolder": "Ordner erstellen",
		"deleteFolder": "Ordner löschen",
		"moveToFolder": "In Ordner verschieben",
		"copyToFolder": "In Ordner kopieren",
		"editFolder": "Ordner bearbeiten",
		"imageSize": "Bildgröße",
		"unknownImageSize": "Unbekannte Bildgröße"
	},
	"auth": {
		"loginNotAvailable": "Service nicht verfügbar – bitte später erneut versuchen",
		"wrongUserPass": "Falscher Benutzername und/oder Passwort",
		"responseError": "Fehler bei der Antwort vom Server",
		"noCredentials": "Anmeldedaten nicht gegeben – Anmeldung nicht möglich",
		"errorMirageSession": "Sie haben sich erfolgreich angemeldet, aber beim Laden des Portals ist ein Problem aufgetreten.<br><br>Versuchen Sie es erneut oder wenden Sie sich an Ihren Manager/Support.",
		"errorMirageSessionPPDS": "Sie haben sich erfolgreich angemeldet, aber Ihr Benutzerkonto hat keinen Zugang zu PPDS Wave-Kunden.<br><br>Versuchen Sie es erneut oder wenden Sie sich an Ihren Manager/Support.",
		"invalidMirageSession": "Sie haben sich erfolgreich angemeldet, aber der Zugang zum Cloud-Portal wurde deaktiviert.<br><br>Versuchen Sie es mit einem anderen Anmeldeanbieter oder wenden Sie sich an Ihren Manager/Support.",
		"invalidMirageSessionPPDS": "Sie haben sich erfolgreich angemeldet, aber der Zugang zum PPDS Wave-Ersteller wurde deaktiviert.<br><br>Versuchen Sie es mit einem anderen Anmeldeanbieter oder wenden Sie sich an Ihren Manager/Support.",
		"unknownMirageSession": "Sie haben sich erfolgreich angemeldet, aber es ist ein unbekannter Fehler aufgetreten.<br><br>Versuchen Sie es mit einem anderen Anmeldeanbieter oder wenden Sie sich an Ihren Manager/Support."
	},
	"accessPanel": {
		"resellerTitle": "Reseller Zugriffsverwaltung",
		"resellerDescription": " Konfigurieren Sie den Benutzer- und API-Zugriff, um:<br>a) den Reseller zu verwalten, um Seiten und Lizenzen bereitzustellen (<span style='font-weight: bold;'>Kontozugriff</span>). Dies gewährt KEINEN Zugriff auf die Seiten.<br>b) Zugriff auf die Sites des Resellers (<span style='font-weight: bold;'>Site Access</span>). Dies gewährt die angegebene Zugriffsstufe für ALLE Seiten, die zu diesem Reseller gehören.",
		"distributorTitle": "Distributor Zugriffsverwaltung",
		"distributorDescription": "Konfigurieren Sie den Benutzer- und API-Zugriff, um:<br>a) den Distributor zu verwalten, um Reseller bereitzustellen (<span style='font-weight: bold;'>Kontozugriff</span>). Dies gewährt KEINEN Zugriff auf die Reseller.<br>b) Zugriff auf die Reseller dieses Distributors (<span style='font-weight: bold;'>Resellerzugriff</span>). Dies gewährt die angegebene Zugriffsebene für ALLE Reseller, die zu diesem Distributor gehören.",
		"domainTitle": "Zugangsverwaltung der Domainbenutzer",
		"domainDescription": "Konfigurieren Sie den Benutzer- und API-Zugriff, um:<br>a) die Domain zu verwalten, um Händler bereitzustellen(<span style='font-weight: bold;'>Kontozugang</span>). Dies gewährt KEINEN Zugang zu den Händlern.<br>b) Zugang zu den Händlern dieser Domain(<span style='font-weight: bold;'>Händlerzugang</span>). Dadurch wird die angegebene Zugangsebene allen Händlern gewährt, die zu dieser Domäne gehören.",
		"resellerAccess": "Resellerzugriff",
		"sharingContextsAccess": "Zugang Freigabekontext",
		"deploymentAccess": "Seitenzugriff",
		"themeAccess": "Themenzugriff",
		"distributorAccess": "Händlerzugang",
		"domainAccess": "Domainzugang",
		"accessControl": "Zugriffskontrolle",
		"accessControlDescription": "Den Reseller zur Bearbeitung auswählen",
		"accessError": "Sie haben keinen Zugriff auf Benutzeradministration für {contextType}n"
	},
	"theme": {
		"theme": "Thema | Themen",
		"importWindowHeader": "Zielordner festlegen",
		"editThemeImage": "Themenbild festlegen",
		"imagePlaceholder": "Kein Bild festgelegt",
		"editThemeCard": "Themenkarte bearbeiten",
		"publishSnapshot": "Thema veröffentlichen",
		"publishDescription": "Ein Thema zu veröffentlichen wird alle vorherigen Versionen überschreiben. Es hat keine Auswirkung auf Seiten die vorherige Versionen importiert haben.",
		"publishSharingDescription": "Die Freigabe der veröffentlichten Version wird unter Home -> Wiederverkäufer -> Themen eingerichtet. Für die Einrichtung benötigen Sie die Zugriffsfreigabe des Wiederverkäufers.",
		"lastPublished": "Zuletzt veröffentlicht",
		"ThemeNotPublished": "Das Thema wird derzeit nicht veröffentlicht",
		"resourceStats": "Ressourcenstatistiken",
		"editorInfo": " Beachten Sie, dass Sie die Bild-Copyrights und die allgemeine Bereitschaft des gesamten Bündels von Elementen überprüfen sollten, bevor Sie auf \"Snapshot veröffentlichen\" klicken, um allen vorgesehenen Benutzerkonten den Import des Themas zu ermöglichen",
		"noFeaturesActivated": "Keine Features aktiviert...",
		"importInfo": "Importieren Sie komplette interactive Menüthemen. Keiner der existierenden Inhalte wird geändert",
		"importInfoTheme": "Importieren Sie andere Themen in dieses Thema.",
		"import": "Importieren",
		"folderExists": "Ordner existiert bereits",
		"importLoader": "Thema wird importiert",
		"importSuccess": "Ihr Thema wurde erfolgreich importiert und kann unter \"Content\" gefunden werden",
		"importSuccessAsync": "Die Importaufgabe wurde gestartet. Bitte warten Sie einige Minuten, bevor Sie mit dem importierten Design arbeiten.",
		"importError": "Löschen Sie Ordner mit dem Namen: {folder} unter \"Content\" und versuchen Sie erneut zu importieren",
		"previewConfiguration": "Themenvorschau Konfigurstion",
		"savePreviewError": "Konfiguration konnte nicht gespeichert werden!",
		"launchPreview": "Vorschau starten",
		"noConfigurationFound": "FEHLER: Keine Konfigurationssets gefunden...",
		"themePublished": "Thema veröffentlicht",
		"published": "veröffentlicht",
		"themePublishedError": "Thema könnte nicht veröffentlicht werden",
		"themeSharingSuccess": "Thema teilen erfolgreich",
		"themeSharingError": "Konnte teilen nicht aktualisieren",
		"themeEmptyError": "Kann vor der Veröffentlichung nicht geteilt werden",
		"fetchError": "Momentane Teilen-Daten können nicht abgerufen werden",
		"editTitle": "Karte bearbeiten um einen Titel festzulegen.",
		"editDescription": "Karte bearbeiten um eine Beschreibung festzulegen.",
		"shareWith": "Teilen mit:",
		"shareAll": "Alle",
		"shareSubGroup": "Untergruppe",
		"shareSingle": "Einzeln",
		"successfullyImported": "Erfolgreich importiert",
		"successfullyImportedAsync": "Importjob gestartet",
		"importFailed": "Import fehlgeschlagen",
		"newTheme": "Neues Thema",
		"preview": "Themenvorschau",
		"previewNotAvailable": "Vorschau nicht verfügbar – Support kontaktieren",
		"previewInfo": "Starten Sie einen virtuellen TV-Client in Ihrem Browser um das Thema zu testen.<br>Diese Vorschau wird kein Wasserzeichen haben.",
		"loadThemesError": "Kann Themen nicht abrufen",
		"themeControl": "Themenkontrolle",
		"themeControlDescription": "Thema zum Bearbeiten auswählen",
		"enablePublishInfo": "Legen Sie alle Veröffentlichungsinformationen (Titel, Beschreibung und Themenbild) fest, um die Veröffentlichung zu ermöglichen",
		"enableSharing": "Sie müssen mindestens eine Freigabe für Ihr Thema festlegen, um es veröffentlichen zu können veröffentlicht",
		"panel": {
			"title": "Reseller Themenverwaltung",
			"description": "Themen konfigurieren"
		},
		"themeName": "Themenname"
	},
	"sharingContext": {
		"type": "Kontext teilen | Kontexte teilen",
		"rootFolderPrefix": "Bereitgestellt",
		"new": "Neuer Ressourcenanbieter",
		"deleteConfirmTitle": "Ressourcenanbieter löschen",
		"deleteConfirmMsg": "Bitte bestätigen Sie, dass Sie diesen Ressourcenanbieter löschen möchten",
		"editorInfo": "Beachten Sie, dass alle Änderungen, die an den Medien in diesem Ressourcenanbieter-Kontext vorgenommen werden, möglicherweise sofort für alle Websites verfügbar sind, für die dieser Anbieter Inhalte freigibt.<br>Seien Sie außerdem SEHR vorsichtig beim Löschen von Elementen, da diese möglicherweise von Websites verwendet werden.",
		"deleteWarning": "WARNHINWEIS! Seien Sie SEHR vorsichtig beim Löschen von Elementen, da diese möglicherweise von Websites verwendet werden",
		"deleteConfirm": "Bestätigen, dass ich dieses {type} löschen möchte | Bestätigen, dass ich diese {type} löschen möchte"
	},
	"signage": {
		"signage": "Signage",
		"schedule": "Zeitplan|Zeitpläne",
		"addTimeslot": "Zeitfenster hinzufügen",
		"contentSchedule": "Content-Zeitplan|Content-Zeitpläne",
		"noSchedules": "Es wurden keine Zeitpläne eingerichtet. Fügen Sie mithilfe des Installer-Codes einen Bildschirm hinzu oder erstellen Sie einen Zeitplan.",
		"mergeDefaultTitle": "Mit Standardinhalten zusammenführen",
		"mergeDefaultConfirm": "Sind Sie sicher, dass Sie dieses Zeitfenster mit Standardinhalten zusammenführen möchten?",
		"mergeDefaultTimeslotTitle": "ZUSAMMENFÜHREN MIT STANDARDINHALTEN"
	},
	"pms": {
		"pmsHeader": "Besitzverwaltung Systemkonfiguration",
		"vendor": "Verkäufer",
		"accessToken": "Zugriffstoken",
		"tigerAccessTokenHint": "Authentifizierungscode für die TigerTMS-API – in TigerTMS-Systemen auch „wsUserKey“ genannt",
		"siteName": "Seitenname",
		"tigerSiteNameHint": "Eindeutige Kennung für dieses Hotel, wird von TigerTMS generiert",
		"directoryId": "Verzeichnis-ID",
		"soapApiUrl": "SOAP-API-Endpunkt",
		"soapApiUrlHint": "URL für die Kommunikation mit TigerTMS iLink – Standardmäßig 'https://checkout.tigertms.com/GenericEXT/', wenn leer gelassen",
		"linkCheckerIn": "Aktivieren Sie die Statusüberwachung für die EINGEHENDE Kommunikation mit TigerTMS",
		"linkCheckerOut": "Aktivieren Sie die Statusüberwachung für die AUSGEHENDE Kommunikation mit TigerTMS",
		"enableDailyReset": "Tägliches Zurücksetzen aktivieren",
		"pmsLink": "PMS-Link",
		"lastMsgReceived": "(Zuletzt erhalten vor {time})",
		"hour": "Stunde",
		"minute": "Minute",
		"forceSync": "Synchronisieren erzwingen",
		"forceSyncSuccess": "Synchronisierungsanfrage erfolgreich gesendet!",
		"forceSyncError": "Synchronisierungsanfrage fehlgeschlagen!",
		"pmsGetVendorError": "Konnte die {pms} Verkäuferliste nicht abrufen!",
		"ip": "IP",
		"port": "Port",
		"useChecksum": "Verwenden Sie die Prüfsumme",
		"pmsVendor": "PMS-Anbieter|PMS-Anbieter",
		"defaultTimeoutSeconds": "Standardzeitlimit für Nachrichten (Sekunden)",
		"charSet": "Zeichensatz"
	},
	"cast": {
		"config": {
			"adminConfigHeader": "Konfiguration des Chromecast-Verwaltungssystems",
			"authToken": "Authentifizierungstoken",
			"authTokenHint": "Sicherheitstoken für das Chromecast-Verwaltungssystem.",
			"networks": {
				"createNew": "Neu erstellen",
				"addNetworkWithType": "{type}-Netzwerk hinzufügen",
				"addChromecastNetwork": "Chrom hinzufügen",
				"useExisting": "Bestehende verwenden",
				"interfaceName": "Schnittstellenname",
				"parentInterface": "Übergeordnete Schnittstelle",
				"networkPrefixLength": "Länge des Netzwerkpräfixes",
				"vlanTag": "VLAN-Tag",
				"chromecastNetworks": "Chromecast-Netzwerke",
				"clientNetworks": "Kunden-Netzwerke",
				"proxyIp": "Proxy IP",
				"vlanId": "VlanId",
				"interface": "Schnittstelle",
				"parentIface": "Übergeordnete Schnittst",
				"ipAddressHint": "Geben Sie die IP-Adresse für diese Schnittstelle ein. Das ist die Adresse, über die Geräte in diesem Netzwerk diesen Server erreichen können.",
				"ipPlaceholder": "192.168.0.10",
				"networkPrefixPlaceholder": "24",
				"vlanTagPlaceholder": "10"
			},
			"hostPlaceholder": "0.0.0.0",
			"networkProxyHost": "Netzwerk-Proxy-Host",
			"networkProxyHostHint": "Die Hostadresse zum Einrichten der Verbindung zwischen Portal und Chromecast-Verwaltungssystem.",
			"proxy": "Proxy",
			"chromecastProxy": "Chromecast-Proxy-Konfiguration",
			"chromecastNetworkType": "Chromecast-Netzwerk",
			"chromecastProxyServerNetworkConfig": "Netzwerkkonfiguration Chromecast-Proxyserver",
			"chromecastProxyServerStatus": "Status Chromecast-Proxyserver",
			"networkInterfaceStatus": "Status der Netzwerkschnittstelle",
			"chromecastProxyServerSignageStatus": "Signage-Status Chromecast-Proxyserver",
			"proxyIsInternetGateway": "Proxy ist ein Internet-Gateway",
			"internetInterface": "Internetschnittstelle",
			"isGatewayForChromecasts": "Ist Gateway für Chromecasts",
			"isDHCPForChromecasts": "Ist DHCP für Chromecasts",
			"isGatewayForClients": "Ist Gateway für Kunden",
			"isDHCPForClients": "Ist DHCP für Kunden",
			"chromecastProxyServerForcedReturnPath": "Erzwungener Rückkehrpfad Chromecast-Proxyserver",
			"webInterfaceForceReturnPath": "Webschnittstelle erzwungener Rückkehrpfad",
			"webInterfaceForceReturnPathHint": "Lassen Sie das Feld leer, es sei denn, es sind mehrere Kunden-Netzwerke vorhanden. Dies wird für die Angabe verwendet, über welche Schnittstelle der Server die Antwort auf jede Anfrage der Kunden zurückgeben soll.",
			"forcedReturnPathGateway": "Gateway erzwungener Rückkehrpfad",
			"forcedReturnPathGatewayPlaceholder": "Standard-Gateway",
			"forcedReturnPathGatewayHint": "Das Gateway, das mit dem erzwungenen Rückkehrpfad-Netzwerk verbunden ist. Lassen Sie das Feld leer, um das Standard-Gateway des Chromecast-Servers zu verwenden",
			"advancedNetworking": "Erweitertes Networking",
			"proxyServerConfig": "Proxy-Server-Konfiguration",
			"chromecastConfig": "Chromecast-Konfiguration",
			"pairingCodeConfig": "Konfiguration des Kopplungscodes",
			"clientRegistrationHost": "Host Kundenregistrierung",
			"clientRegistrationHostHint": "Geben Sie die Domain oder IP-Adresse ein, die auf diesen Server im Kunden-Netzwerk verweist. Fügen Sie weder http:// noch ein URL-Schema hinzu.",
			"clientRegistrationHostPlaceholder": "cast.example.com",
			"guestWifiName": "Name Gäste-WLAN",
			"guestWifiNameHint": "WLAN-Name, der im Standalone-Modus auf dem Bildschirm angezeigt wird. Weitere Informationen finden Sie in der Dokumentation zur Personalisierung.",
			"guestWiFiPassword": "Passwort Gäste-WLAN",
			"guestWiFiPasswordHint": "WLAN-Passwort, das im Standalone-Modus auf dem Bildschirm angezeigt wird. Weitere Informationen finden Sie in der Dokumentation zur Personalisierung.",
			"enableSignageIntegration": "Signage-Integration aktivieren",
			"globalChromecastName": "Globaler Chromecast-Name",
			"globalChromecastNameHint": "Chromecast-Name, der allen Gästen angezeigt werden soll.",
			"globalChromecastNamePlaceholder": "Chromecast im Zimmer",
			"timeZone": "Zeitzone",
			"timeZoneHint": "Das ist die Zeitzone für alle verbundenen Chromecasts.",
			"locale": "Gebietsschema",
			"localeHint": "Dies ist die Anzeigesprache aller verbundenen Chromecasts.",
			"pairingCodeSet": "Zeichensatz für Kopplungscode",
			"pairingCodeSetHint": "Zeichensatz zum Generieren des Kopplungscode. Die hier eingegebenen Zeichen sind die einzigen, die zur Generierung des Codes verwendet werden.",
			"pairingCodeIsCaseSensitive": "Beim Kopplungscode muss die Groß-/Kleinschreibung beachtet werden",
			"pairingCodeLength": "Länge des Kopplungscodes",
			"pairingCodeTimeoutMinutes": "Zeitüberschreitung des Kopplungscodes in Minuten",
			"pairingCodeTimeoutMinutesHint": "Dauer der Gültigkeit eines Codes, bevor ein neuer Code angezeigt wird. Bei Verwendung wird trotzdem ein neuer Code angezeigt. (Standard: 10 Minuten)",
			"pairTimeoutHours": "Zeitüberschreitung der Kopplung in Stunden",
			"pairTimeoutHoursHint": "Wie lange ein gekoppelter Gast gekoppelt sein sollte, bevor die Verbindung automatisch getrennt wird. (Standard: 5 Stunden). Zur Verwendung einer festgelegten Zeit pro Tag nutzen Sie das Kontrollkästchen unten und konfigurieren Sie jeden Tag separat.",
			"useCustomTimeoutTimePerDayInstead": "Verwenden Sie stattdessen eine benutzerdefinierte Timout-Zeit pro Tag",
			"networkProxySaveError": "Fehler beim Speichern der Netzwerk-Proxy-Konfiguration",
			"saveError": "Fehler beim Speichern der Konfiguration",
			"saveSuccess": "Konfiguration gespeichert",
			"connectionSuccess": "Verbindung hergestellt",
			"connectionFailure": "Es konnte keine Verbindung hergestellt werden",
			"interfaceFetchFailure": "Schnittstellen konnten nicht abgerufen werden",
			"reloadTooltipDefault": "Gespeicherte Daten werden abgerufen",
			"resetTooltip": "Die aktuelle Konfiguration wird durch Abrufen einer gespeicherten Konfiguration zurückgesetzt.",
			"routingOfClientChromecastSessions": "Unterstützt das Routing von Kunden-Chromecast-Sitzungen",
			"routingOfClientNetworkMulticast": "Unterstützt das Routing von Kunden-Netzwerk-Multicast",
			"supportNetflix": "Unterstützen Sie Netflix auf Gen4-Casts (AndroidTV)",
			"failedToLoadMsg": "Konfiguration konnte nicht geladen werden"
		},
		"eventLogs": {
			"typeDescription": "Eventprotokoll",
			"time": "Zeit",
			"eventName": "Eventname",
			"castUdn": "Cast-UDN",
			"castName": "Cast-Name",
			"clientVid": "Kunden-VID",
			"additionalData": "Zusätzliche Daten"
		},
		"response": {
			"chromecastFetchFailed": "Chromecasts konnten nicht abgerufen werden.",
			"unregisteredCastFetchFailed": "Nicht registrierte Chromecasts konnten nicht abgerufen werden",
			"activeSessionFetchFailed": "Aktive Sitzungen konnten nicht abgerufen werden",
			"statusFetchFailed": "Der Chromecast-Status konnte nicht abgerufen werden",
			"statisticsFetchFailed": "Statistiken konnten nicht abgerufen werden",
			"eventLogFetchFailed": "Das Eventprotokoll konnte nicht abgerufen werden",
			"chromecastProxyConfigFetchFailed": "Die Chromecast-Proxy-Konfiguration konnte nicht abgerufen werden",
			"networksFetchFailed": "Netzwerke konnten nicht abgerufen werden",
			"advancedNetworkingFetchFailed": "Die erweiterte Netzwerkkonfiguration konnte nicht abgerufen werden"
		},
		"status": {
			"reassociationBtnText": "Erneute Verknüpfung mit DS",
			"reassociationTitle": "Erneut mit Signage verknüpft?",
			"reassociationWarning": "Die aktuelle Verknüpfung mit Digital Signage geht verloren. Dies sollte nur in Fällen verwendet werden, in denen Cast sich nicht bei Digital Signage registrieren kann oder der zuvor verknüpfte Bildschirm in Digital Signage gelöscht wurde. \n Neue Verknüpfung erstellen?",
			"reassociationMsg": "Neue Verknüpfung erstellen?",
			"reassociationSuccess": "Erneut mit DS verknüpft",
			"reassociationFailed": "Die erneute Verknüpfung mit DS ist fehlgeschlagen",
			"version": "Version",
			"serverId": "Server-ID",
			"licensedFor": "Lizenziert für",
			"status": "Status",
			"eno1": "eno1",
			"wip58s0": "wip58s0",
			"clientId": "Kunden-ID",
			"clientToken": "Kunden-Token",
			"installerToken": "Installer-Token",
			"installerCode": "Installer-Code",
			"connected": "Mit Push-Server verbunden",
			"authenticated": "Authentifiziert"
		},
		"chromecasts": {
			"info": {
				"castConfig": "Chromecast-Konfiguration",
				"deviceAlias": "Geräte-Alias",
				"deviceName": "Gerätename",
				"uptime": "Betriebszeit",
				"isConfigurable": "Ist konfigurierbar",
				"wifiMac": "WLAN MAC",
				"ssid": "SSID"
			},
			"refreshStatusBtn": "Status aktualisieren",
			"refreshStatusSuccess": "Chromecast-Daten werden aktualisiert.\n Dies kann je nach der Anzahl Ihrer Chromecasts eine Minute dauern. Klicken Sie auf \"Neu laden\", um die aktualisierten Daten anzuzeigen",
			"refreshStatusFailed": "Status konnte nicht aktualisiert werden.",
			"saveSuccess": "Chromecast wurde erfolgreich aktualisiert",
			"saveFailed": "Die Aktualisierung von Chromecast ist fehlgeschlagen",
			"unregister": "Abmelden",
			"showConfig": "Konfig anzeigen",
			"unregisterConfirmTitle": "Bei Chromecast abmelden?",
			"unregisterConfirmMsg": "Sind Sie sicher, dass Sie sich abmelden möchten {cast}",
			"unregisterSuccess": "Abgemeldet {cast}",
			"unregisterFailed": "Abmeldung {cast} fehlgeschlagen.",
			"updateStatusSuccess": "Status für {number} Chromecast aktualisiert | Status für {number} Chromecasts aktualisiert.",
			"updateStatusFailed": "Die Aktualisierung des Status ist fehlgeschlagen.",
			"showNameSuccess": "Name für {number} Chromecast wird angezeigt | Namen für {number} Chromecasts werden angezeigt.",
			"showNameFailed": "Name konnte nicht angezeigt werden.",
			"rebootSuccess": "{number} Chromecast neu gestartet | {number} Chromecasts neu gestartet.",
			"rebootFailed": "Chromecasts konnten nicht neu gestartet werden.",
			"deletePairingsConfirmTitle": "Kopplungen löschen",
			"deletePairingsConfirmMsg": "Kopplungen für Zimmer löschen?",
			"deletePairingsSuccess": "Gelöschte Kopplungen für {number} Zimmer | Gelöschte Kopplungen für {number} Zimmer.",
			"deletePairingsFailed": "Die Kopplungen für das Zimmer {room} konnten nicht gelöscht werden.",
			"forceStartCastingFailed": "Der Casting-Start für Cast {cast} konnte nicht erzwungen werden.",
			"chromecastPairingCode": "Chromecast-Kopplungscode"
		},
		"unregisteredCasts": {
			"title": "Nicht registrierter Cast",
			"register": "Registrieren",
			"registerChromecast": "Chromecast registrieren",
			"registerSuccess": "Chromecast {cast} registriert in Zimmer {room}",
			"genericFailure": "Chromecast konnte nicht registriert werden",
			"limitReachedFailure": "Das Chromecast-Registrierungslimit wurde erreicht",
			"deleteCastConfirmTitle": "Chromecast löschen",
			"deleteCastConfirmMsg": "Sind Sie sicher, dass Sie diesen Chromecast löschen möchten?",
			"deleteCastFailed": "Chromecast konnte nicht gelöscht werden",
			"deleteCastSuccess": "Chromecast gelöscht"
		},
		"statistics": {
			"fetch": "Abrufen",
			"usageInHours": "Cast-Nutzung in Stunden",
			"noOfPairingsPerDay": "Anzahl der Kopplungen pro Tag",
			"appUsageInHours": "App-Nutzung in Stunden",
			"consecutiveHourFilterLabel": "Events filtern",
			"consecutiveHourFilterHint": "Filtert Events mit mehr Stunden als der eingestellten Anzahl heraus.",
			"downloadVisiblePeriod": "Sichtbaren Zeiträume herunterladen",
			"exportPairings": "Kopplungen exportieren",
			"exportAppUsage": "App-Nutzung exportieren"
		},
		"activeSessions": {
			"title": "Aktive Sitzung",
			"pinOrUnpin": "Diese Kopplung anpinnen oder abpinnen",
			"pinned": "Angepinnt",
			"pinSuccess": "Kunde angepinnt",
			"unpinSuccess": "Kunde abgepinnt",
			"multiplePinUnpinSuccess": "{clients} angepinnt/abgepinnt",
			"pinFailed": "Der Kunde konnte nicht angepinnt werden",
			"disconnect": "Kunde trennen",
			"disconnectConfirmTitle": "Kunde trennen",
			"disconnectConfirmMsg": "Kunde von Chromecast trennen?",
			"disconnectSuccess": "Kunde getrennt",
			"disconnectFailed": "Der Kunde konnte nicht getrennt werden"
		},
		"type": "Chromecast | Chromecasts",
		"runningApp": "Laufende App",
		"available": "Verfügbar",
		"connection": "Verb.",
		"mac": "MAC",
		"macAddress": "MAC-Adresse",
		"paired": "Gekoppelt",
		"udn": "UDN",
		"signalNoiseLevel": "S/N",
		"client": "Cli",
		"configurable": "Konfigurierbar",
		"connected": "Verbunden",
		"createdAt": "Erstellt in",
		"actions": {
			"updateStatus": "Status aktualisieren",
			"deletePairings": "Kopplungen löschen",
			"showName": "Name anzeigen",
			"stopCurrentSession": "Neustart",
			"generatePairingCode": "Kopplungscode erstellen",
			"exportSelectedToCsv": "Ausgewählte Datei in CSV exportieren",
			"forceStartCasting": "Start erzwingen"
		},
		"networkProxySaveConfirmationMsg": "Sind Sie sicher, dass Sie speichern möchten? \n Wenn die Konfiguration falsch eingestellt ist, wird die Chromecast-Funktion unterbrochen.",
		"castServiceOfflineMsg": "Der Chromecast-Dienst ist derzeit nicht verfügbar, möglicherweise weil der Server offline ist oder ein Konfigurationsfehler in den Konfigurationseinstellungen \"Administration/Chromecast\" vorliegt."
	},
	"streamManagement": {
		"allocatedStreams": {
			"device": "Gerät | Geräte",
			"allocatedStream": "Zugeordneter Stream",
			"itemType": "Gerät",
			"addDevice": "Gerät hinzufügen",
			"editDevice": "Gerät bearbeiten",
			"deallocateStreamTitle": "Streamzuordnung aufheben?",
			"deallocateStreamConfirmMsg": "Sind Sie sicher, dass Sie die Streamzuordnung aufheben möchten?",
			"editAllocatedStream": "Zugeordneten Stream bearbeiten",
			"mediaUrl": "Medien-URL",
			"streamAllocatorConfig": "Konfiguration Stream-Zuordner",
			"deallocate": "Zuordnung aufheben",
			"deleteDeviceMsg": "WARNHINWEIS: Durch das Löschen von Geräten wird die Sitzung für alle zugewiesenen Fernseher sofort beendet",
			"deleteDeviceConfirm": "Sind Sie sicher, dass Sie dieses Gerät löschen möchten?",
			"chromecastIntegrationDescription": "Wählen Sie diese Option zur Aktivierung der Chromecast-Integration"
		},
		"config": {
			"minTimeoutWarn": "Die minimale Zeitüberschreitung beträgt 2 Minuten"
		},
		"allocationLogEntries": {
			"eventType": {
				"allocationFailure": "Stream konnte nicht zugeordnet werden"
			},
			"eventReason": {
				"noAvailableDevices": "Keine verfügbaren Geräte"
			},
			"title": "Stream-Zuordnungsprotokoll",
			"itemType": "Stream-Zuordner Protokolleintrag"
		}
	},
	"jobs": {
		"jobList": {
			"title": "Benachrichtigungen"
		},
		"startMsgs": {
			"imageUpload": "Hochladen des Bilds {title} gestartet",
			"fontUpload": "Hochladen der Schrift {title} gestartet",
			"themeImport": "Import des Themas {title} gestartet",
			"logoUpload": "Hochladen des Logos {title} gestartet"
		},
		"successMsgs": {
			"imageUpload": "Hochladen des Bilds {title} erfolgreich",
			"fontUpload": "Hochladen der Schrift {title} erfolgreich",
			"logoUpload": "Hochladen des Logos {title} erfolgreich"
		},
		"failedMsgs": {
			"imageUpload": "Hochladen des Bilds {title} fehlgeschlagen",
			"fontUpload": "Hochladen der Schrift {title} fehlgeschlagen",
			"logoUpload": "Hochladen des Logos {title} fehlgeschlagen"
		}
	},
	"content": {
		"config": {
			"resourceserver": "Ressourcen-Server",
			"resourcesyncservice": "Ressourcen-Synchronisierungsdienst",
			"networkproxy": "Netzwerk-Proxy",
			"resourceServerPort": "Port Ressourcen-Server",
			"resourceServerPortHint": "Geben Sie den Port für den Ressourcen-Server ein",
			"resourceServerFileLocation": "Dateispeicherort für Ressourcen-Server",
			"resourceServerFileLocationHint": "Geben Sie den Dateispeicherort für den Ressourcen-Server ein",
			"resourceSyncServiceFileLocation": "Dateispeicherort Ressourcen-Synchronisierungsdienst",
			"resourceSyncServiceFileLocationHint": "Geben Sie den Dateispeicherort ein, an dem alle Dateien für den Ressourcen-Synchronisierungsdienst gespeichert werden sollen",
			"enableChangingURL": "Zum Ändern der URL aktivieren",
			"saveConfig": "Konfiguration speichern",
			"saveConfigWarn": "Sind Sie sicher, dass Sie speichern möchten? Wenn die Konfiguration falsch eingestellt ist, wird die Bereitstellungsfunktion unterbrochen.",
			"saveSuccess": "Konfiguration gespeichert",
			"configSaveError": "Fehler beim Speichern der Konfiguration",
			"rangeError": "Es sind nur Ports zwischen 1024 und 49151 zulässig",
			"checkConfig": "Konfiguration prüfen",
			"configurationOK": "Konfiguration OK",
			"errorWithConfiguration": "Fehler bei der Konfiguration",
			"missingConfig": "Drücken Sie NEU LADEN, um die Konfiguration abzurufen",
			"changeURLWarn": "Warnhinweis: Wenn die URL falsch eingestellt ist, wird die Bereitstellungsfunktion unterbrochen."
		}
	}
}
