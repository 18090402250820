/* French translations */
export default {
	"generic": {
		"set": "Définir",
		"onboard": "Embarqué",
		"view": "Afficher",
		"open": "Ouvrir",
		"days": "Jours",
		"minutes": "minutes",
		"log": "Journal",
		"event": "Événement",
		"eventReason": "Raison",
		"occuredAt": "Survenu à",
		"lastKeepalive": "Dernier keepalive",
		"allocated": "Alloué",
		"active": "Actif",
		"inactive": "Inactif",
		"firmware": "Firmware",
		"from": "De",
		"to": "À",
		"ipValidationErrorMsg": "Ce champ doit contenir une adresse IP valide",
		"networks": "Réseaux",
		"ipAddress": "Adresse IP",
		"host": "Hôte",
		"total": "Total",
		"add": "Ajouter",
		"all": "Tous",
		"addWithType": "Ajouter {type} | Ajouter {type}s",
		"agent": "Agent | Agents",
		"signageScreen": "Écran d’affichage",
		"agentStatus": "Statut de l’agent",
		"delete": "Supprimer",
		"deleteAll": "Supprimer tout",
		"deleteWithType": "Supprimer {type} | Supprimer {amount} {type}s",
		"deleteConfirm": "Êtes-vous sûr de vouloir supprimer ce {type}? | Êtes-vous sûr de vouloir supprimer ces {amount} {type}s?",
		"deleteConfirmWithIdentifier": "Êtes-vous sûr de vouloir supprimer {type} {id}?",
		"deleted": "{type} } supprimé avec succès | {type}s supprimés avec succès",
		"deleteFailed": "{type} n'a pas été supprimé | {type}s n'a pas été supprimé",
		"deletedWithIdentifier": "{id} a été supprimé avec succès",
		"deletedWithTypeAndIdentifier": "{type} {id} supprimé",
		"deleteUserConfirmation": "Je confirme que je veux supprimer ce  {type}. Je reconnais que cette opération est définitive et que {type} ne pourra pas être récupéré.| Je confirme que je souhaite supprimer ces {amount} {type}s. Je reconnais que cette opération est définitive et que les {types} ne peuvent être récupérés.",
		"fetchFailed": "Erreur de récupération {itemName}",
		"imported": "{cnt} {type} importé avec succès | {cnt} {type}s importés avec succès ",
		"saved": "{type} sauvegardé avec succès | {type}s sauvegardés avec succès",
		"updated": "{type} mis à jour avec succès | {type}s mis à jour avec succès",
		"created": "{type} créé avec succès | {type}s créés avec succès",
		"selectType": "Sélectionnez {type}",
		"manageType": "Gérer {type}",
		"itemReverted": "Elément inversé|Eléments inversés",
		"valueDuplicate": "{value} existe déjà",
		"remove": "Supprimer",
		"disable": "Désactiver",
		"disabled": "Désactivé",
		"download": "Télécharger",
		"enable": "Activer",
		"enabled": "Activé",
		"systemDefault": "Système par défaut",
		"removeWithType": "Supprimer {type} | Supprimer les {type}s",
		"removeConfirm": "Êtes-vous sûr de vouloir supprimer ce {type}? | Êtes-vous sûr de vouloir supprimer ces {type}s?",
		"removeConfirmWithTarget": "Êtes-vous sûr de vouloir supprimer ce {type} de ce {target}? | Êtes-vous sûr de vouloir supprimer ces {type}s de ce {target}?",
		"group": "Groupe  | Groupes",
		"rename": "Renommer",
		"renameWithType": "Renommer {type}",
		"renamedTo": "{type} renommé a {name}",
		"create": "Créer",
		"createToolTip": "Créer sans fermer",
		"createAndClose": "Créer et fermer",
		"createWithType": "Créer {type}",
		"createNew": "Créer nouveau",
		"createNewWithType": "Créer nouveau {type} | Créer nouveaux {type}s",
		"takeEffectImmediatly": "Ceci prendra effet immédiatement",
		"newWithType": "Nouveau {type} | Nouveaux {type}s",
		"actions": "Actions",
		"yes": "Oui",
		"no": "Non",
		"none": "Aucune",
		"move": "Déplacer",
		"loading": "Chargement",
		"unknown": "Inconnu",
		"typeToSearch": "Commencez à taper pour rechercher",
		"enterSearchTerm": "Saisir le terme recherché",
		"noSearchMatches": "Aucune recherche ne correspond",
		"cancel": "Annuler",
		"close": "Fermer",
		"gotIt": "C'est fait",
		"required": "Requis",
		"numberRequired": "Nombre requis",
		"fieldRequired": "Champ requis",
		"inputRequired": "Entrée requis",
		"integration": "Intégration | Intégrations",
		"selectionRequired": "Sélection requis",
		"nameRequired": "Nom requis",
		"inputLongerThan": "La saisie doit être supérieure à {length} caractère | La saisie doit être supérieure à {length} caractères ",
		"countLessThan": "Le nombre doit être inférieur à {length}",
		"inputShorterThan": "La saisie doit être inférieure à {length} caractères",
		"nameInvalidFormat": "Le nom ne doit contenir que des lettres, des chiffres et des espaces",
		"madeYet": "Aucun {type} n'a encore été créé | Aucun {type}s n'a encore été créés",
		"noMoreItemsAvailable": "Il n’y a plus de {type} disponible | Il n’y a plus de {type}s disponibles",
		"searchCriteria": "Aucun {type}s } ne correspond aux critères de recherche donnés",
		"search": "Rechercher",
		"save": "Sauvegarder",
		"saveAndClose": "Sauvegarder et fermer",
		"saveWithType": "Sauvegarder {type} | Enregistrer les {type}s",
		"editItem": "Editer élément | Editer éléments",
		"edit": "Editer | Editer ",
		"editWithType": "Editer {type} | Editer {type}s",
		"shareName": "Partager {type}",
		"action": "Action | Actions",
		"refresh": "Rafraîchir",
		"reset": "Réinitialiser",
		"resetRefresh": "Réinitialiser et rafraîchir {type}",
		"lostChanges": "Tous changements seront perdus",
		"noAvailable": "Aucun {type}s disponible",
		"channelTable": "Table des canaux",
		"done": "Terminé",
		"name": "Nom",
		"nickname": "Surnom",
		"externalId": "Identifiant externe",
		"crmRef": "Réf. CRM",
		"industry": "Industrie",
		"filename": "Nom de fichier",
		"content": "Contenu",
		"fullscreen": "Plein écran",
		"exitFullscreen": "Quitter le mode plein écran",
		"copy": "Copier",
		"copyToClipboard": "Copier dans le presse-papiers",
		"copiedToClipboard": "Contenu copié dans le presse-papiers",
		"copyToSite": "Copier sur le site",
		"preview": "Aperçu",
		"reload": "Recharger",
		"unsaved": "Les modifications non sauvegardées seront perdues",
		"type": "Type | Types",
		"text": "Texte",
		"tag": "Balise | Balises",
		"item": "Élément | Éléments",
		"title": "Titre",
		"fullTitle": "Titre complet",
		"editor": "Éditeur",
		"number": "Numéro | Numéros",
		"top": "Haut",
		"left": "Gauche",
		"width": "Largeur",
		"height": "Hauteur",
		"zIndex": "z-index",
		"field": "Champ | Champs",
		"key": "Clé | Clés",
		"description": "Déscription",
		"setup": "Installation",
		"status": "Statut | Statut",
		"multiple": "Multiple",
		"default": "Par défaut",
		"general": "Général",
		"configuration": "Configuration",
		"config": "Config",
		"select": "Sélectionner",
		"addAll": "Ajouter tout",
		"removeAll": "Supprimer tout",
		"previous": "Précédent",
		"next": "Suivant",
		"finish": "Terminer",
		"nameLonger": "Le nom doit être supérieur à  {length} caractères",
		"selectAll": "Sélectionner tout",
		"deselectAll": "Désélectionner tout",
		"code": "Code",
		"continue": "Continuer",
		"stay": "Rester",
		"back": "Retour",
		"language": "Langue",
		"languageName": "Nom de la langue",
		"languageCode": "Code du langue",
		"reseller": "Revendeur | Revendeurs",
		"resource": "Ressource | Ressources",
		"override": "Remplacement | Remplacements",
		"insufficientPrivileges": "Le compte ne dispose pas de privilèges suffisants",
		"theme": "Thème | Thèmes",
		"distributor": "Distributeur | Distributeurs",
		"domain": "Domaine | Domaines",
		"preregister": "Pré-enregistrement",
		"pleaseConfirm": "Veuillez confirmer",
		"switchTo": "Passer à...",
		"home": "Accueil",
		"lastLoggedIn": "Dernière connexion",
		"summary": "Résumé",
		"noData": "Aucune donnée à afficher...",
		"vendor": "Vendeur | Vendeurs",
		"model": "Modèle | Modèles",
		"user": "Utilisateur",
		"schedule": "Planification | Planifications",
		"revert": "Revenir en arrière",
		"revertAllChanges": "Rétablir toutes les modifications depuis la dernière sauvegarde ?",
		"revertNoChanges": "Aucune modification à annuler",
		"channels": "Canal | Canaux",
		"deployment": "Déploiement | Déploiements",
		"filter": "Filtre",
		"accept": "Accepter",
		"api": "API",
		"timeZone": "Fuseau horaire | Fuseau horaires",
		"register": "Registre",
		"caseSensitive": "Sensible à la casse",
		"optional": "Optionnel",
		"errorOccurred": "Une erreur s'est produite",
		"on": "Allumé",
		"off": "Éteint",
		"standby": "En attente",
		"idle": "Libre",
		"notSet": "Non défini",
		"exportCSV": "Exporter CSV",
		"importCSV": "Importer CSV",
		"success": "Succès",
		"uploaded": "Téléchargé",
		"hasUnsavedChangesHeader": "Modifications non sauvegardées",
		"hasUnsavedChangesMessage": "Vos modifications seront perdues si vous fermez.<br>Continuer?",
		"defaultErrorMsg": "Message d’erreur par défaut",
		"notes": "Remarques",
		"genericNotes": "Notes génériques",
		"internalNotes": "Notes internes",
		"whitelabelDomain": "Domaine en marque blanche",
		"createNewSchedule": "Créer une nouvelle planification",
		"addToSchedule": "Ajouter à une planification existante",
		"readOnly": "En lecture seule",
		"information": "informations",
		"custom": "Personnalisé",
		"url": "URL",
		"realm": "Domaine Keycloak",
		"noResult": "Pas de résultat | Pas de résultats",
		"sharingContext": "Fournisseur de ressources | Fournisseurs de ressources",
		"sharingSuccess": "Partage mis à jour",
		"sharingError": "Impossible de mettre à jour le partage",
		"lastPublished": "Dernière publication",
		"notPublished": "{0} n’est actuellement pas publié",
		"itemType": "{0}",
		"shareAll": "Tous",
		"shareSubGroup": "Sous-groupe",
		"shareSingle": "Unique"
	},
	"validation": {
		"characterMaxLength": "Ne peut dépasser les {value} caractères",
		"valueHigherThan": "La valeur doit être supérieure à {value}",
		"valueHigherThanOrEqualTo": "La valeur doit être supérieure ou égale à {value}",
		"valueLowerOrEqualTo": "La valeur doit être inférieure ou égale à {value}",
		"powerOnOverrideFrom": "Doit être avant « Override to » (remplacer par)",
		"powerOnOverrideTo": "Doit être après « Override from » (remplacer à partir)"
	},
	"help": {
		"getSupportTitle": "Contacter le support",
		"getSiteInformationTitle": "Informations sur le site",
		"notAvailable": "Non disponible",
		"openDocumentation": "Ouvrir la documentation",
		"getSupportBody": "<span style=\"font-weight: bold;\">E-mail:</span> {email}<br><span style=\"font-weight: bold;\">Phone:</span> {phone}<br><br>{text}",
		"notAvailableInHomeScreen": "Les informations de support ne sont pas disponibles sur l'écran d'accueil.<br>Veuillez passer à un contexte de site.",
		"documentation": "Documentation",
		"contactSupport": "Contacter le support",
		"mobileNavigationTip": "Tapez sur le bouton supérieur gauche pour ouvrir le tiroir de navigation"
	},
	"eula": {
		"updated": {
			"title": "Conditions d'utilisation mises à jour",
			"text": "<span style='font-size: 16px;font-style: italic;'>Nous avons mis à jour notre contrat de licence d’utilisateur final. Veuillez le lire et l’accepter pour continuer</span> <br><br>"
		}
	},
	"welcome": {
		"title": "Bienvenue {user}",
		"languageHeader": "Langue",
		"languageText": "Bienvenue sur le portail de gestion Enterprise Cloud.<br><br>C'est le hub principal de la solution de médias d'accueil Enterprise Cloud, le point d'accès pour les distributeurs, les revendeurs, les propriétaires de sites et les utilisateurs de sites.<br><br>Débutez en sélectionnant votre langue préférée :",
		"homeScreenText": "En fonction de votre compte, vous serez connecté à un \"Site Dashboard\" ou un \"Portal Dashboard\" et ce sera votre futur écran d'accueil pour travailler avec Enterprise Cloud.<br><br><div style=\"font-weight: bold;\">Site Dashboard</div>Pour les utilisateurs ayant un accès standard à un seul site, vous serez amené directement à votre propre \"Site Dashboard\" lors de la connexion au système.<br>À ce point de départ, vous avez un aperçu des écrans d'installation, des statistiques d'accueil et des infos administratives.\nLes éléments de menu spécifiques, les ressources et les options disponibles peuvent varier en fonction de votre type de compte, des licences actives et du niveau d'accès accordé.<br><br><div style=\"font-weight: bold;\">Portal Dashboard</div>Pour les revendeurs, les propriétaires de sites et les utilisateurs de sites ayant accès à plusieurs installations Enterprise Cloud, le \"Portal Dashboard\" est le point de départ initial sur la plateforme.<br>C'est ici que vous gérez les ressources et accédez aux statistiques combinées pour tous vos sites, créez des utilisateurs, configurez de nouvelles installations, vérifiez les détails des licences et bien d'autres tâches administratives.<br>Les éléments de menu et les options spécifiques de cette zone administrative de la plateforme varient en fonction de votre type de compte, des licences actives et du niveau d'accès accordé.<br><br>Vous pouvez toujours accéder au \"Portal Dashboard\" en le sélectionnant dans le menu contextuel supérieur gauche depuis n'importe quelle page.Depuis le même menu contextuel haut-gauche, vous pouvez également accéder à tous vos sites pour travailler sur chaque installation individuelle disponible sur votre compte.",
		"siteText": "Si vous êtes un utilisateur d'un seul site, vous serez automatiquement connecté à votre site lorsque vous vous connecterez au système. Pour les utilisateurs ayant un accès multisite, vous sélectionnez le contexte du site dans le menu contextuel en haut à gauche.<br>Le niveau d'accès que vous avez déterminera les ressources qui sont disponibles pour vous dans le contexte du site."
	},
	"language": {
		"english": "Anglais",
		"norwegian": "Norvégien",
		"swedish": "Suédois",
		"finnish": "Finnois",
		"russian": "Russe",
		"italian": "Italien",
		"french": "Français",
		"german": "Allemand",
		"spanish": "Espagnol",
		"estonian": "Estonien",
		"atLeastOne": "Au moins une langue doit être rempli"
	},
	"date": {
		"today": "Aujourd'hui",
		"yesterday": "Hier",
		"daysAgo": "{days} jours auparavant",
		"dateRequired": "Date requise",
		"dateNotSet": "Non définie",
		"timeRequired": "Heure requise",
		"timeFormat": "L'heure doit être au format 12:00",
		"amPmTimeFormat": "L’heure doit être au format h:mm AM/PM",
		"daily": "Quotidiennement",
		"weekly": "Hebdomadaire",
		"time": "Heure",
		"date": "Date",
		"timeOfDay": "Heure du jour",
		"monday": "Lundi",
		"tuesday": "Mardi",
		"wednesday": "Mercredi",
		"thursday": "Jeudi",
		"friday": "Vendredi",
		"saturday": "Samedi",
		"sunday": "Dimanche",
		"startTime": "Heure de début",
		"endTime": "Heure de fin",
		"weekday": "Jour de la semaine",
		"endTimeAfter": "L'heure de fin doit venir après l'heure de début",
		"duration": "Durée",
		"inSeconds": "en secondes"
	},
	"router": {
		"agents": "Agents",
		"contentConfiguration": "Configuration",
		"documents": "Documents",
		"resource": "Ressource",
		"resources": "Ressources",
		"hospitality": "Hospitalité",
		"dashboard": "Tableau de bord",
		"media": "Média",
		"housekeeping": "Ménage",
		"screens": "Écrans",
		"tv": "Télévision",
		"playlists": "Listes de lecture",
		"signage": "Affichage",
		"sites": "Sites",
		"customersAndLicenses": "Clients et licences",
		"sitesAndLicenses": "Sites & Licences",
		"externalSystems": "Systèmes externes",
		"reseller": "Revendeur",
		"resellers": "Revendeurs",
		"manageResellers": "Gérer les revendeurs",
		"distributor": "Distributeur",
		"distributors": "Distributeurs",
		"domain": "Domaine",
		"domains": "Domaines",
		"usageReport": "Rapport d'utilisation",
		"accessReport": "Rapport d'accès",
		"resellerUsersAndAccess": "Utilisateurs et accès",
		"distributorUsersAndAccess": "Utilisateurs et accès",
		"resellerThemes": "Thèmes",
		"distributorThemes": "Thèmes",
		"content": "Contenu",
		"admin": "Admin",
		"administration": "Administration",
		"system": "Système",
		"rooms": "Chambres",
		"virtualRemoteClients": "Sessions VRC",
		"housekeepingRooms": "Chambres",
		"locations": "Emplacements",
		"locationSetup": "Configuration de l'emplacement",
		"guests": "Invités",
		"guestMessages": "Messages",
		"booking": "Réservation",
		"wakeups": "Réveils",
		"config": "Config",
		"screensConfig": "Config",
		"housekeepingConfig": "Config",
		"streamAllocatorConfig": "Configuration",
		"configuration": "Configuration",
		"issues": "Problèmes",
		"scheduledTasks": "Tâches planifiées",
		"screen": "Écran",
		"screenConfigurations": "Configurations",
		"tvChannels": "Canaux de télévision",
		"tvScreens": "Écrans de télévision",
		"tvScreensTheme": "Écrans de télévision",
		"signageScreens": "Écrans d’affichage",
		"signageOverview": "Aperçu",
		"signageSchedules": "Planifications",
		"signageConfig": "Configuration",
		"event": "Événement",
		"events": "Événements",
		"wayfinding": "Directions",
		"wayfindingIcons": "Directions",
		"logos": "Logos",
		"integrationLocations": "Intégrations",
		"eventConfig": "Configuration",
		"schedules": "Planifications",
		"schedulemapping": "Mappage des horaires",
		"installCodes": "Installer codes",
		"interactivePages": "Menus interactifs",
		"templatePages": "Pages de modèles",
		"channelTables": "Tableaux des canaux",
		"mediaChannels": "Canaux de médias",
		"templates": "Modèles",
		"apiTemplates": "Modèles API",
		"pages": "Pages",
		"imageresources": "Images",
		"videoresources": "Vidéos",
		"fontresources": "Polices",
		"contexts": "Contextes",
		"sharingContexts": "Fournisseurs de ressources",
		"accounts": "Comptes",
		"access": "Accès",
		"themeImport": "Thèmes",
		"themes": "Thèmes",
		"usersAndAccess": "Utilisateurs et Accès",
		"userAccounts": "Comptes d'utilisateurs",
		"apiAccounts": "Comptes API ",
		"owners": "Propriétaires",
		"areas": "Domaines",
		"licenses": "Licences",
		"products": "Produits",
		"roles": "Rôles",
		"roleManagement": "Rôles",
		"privilegeSets": "Jeux de privilèges",
		"requiredPermissions": "Vous n'avez pas les permissions requises pour cette vue",
		"systemConfig": "Configuration du système",
		"tvSearch": "Recherche TV",
		"pmsConfiguration": "Configuration PMS ",
		"pmsLogEntries": "Entrées du journal PMS",
		"resourceSharing": "Partager",
		"resourcePublishing": "Publier",
		"publishAndShare": "Publier et partager",
		"themePreview": "Prévisualisation",
		"watermark": "Filigrane",
		"castManagement": "Gestion des casts",
		"chromecasts": "Chromecasts",
		"chromecastConfig": "Configuration du Chromecast",
		"unregisteredCasts": "Casts non enregistrés",
		"activeCastSessions": "Sessions actives",
		"castConfiguration": "Configuration",
		"castStatus": "Statut",
		"castStatistics": "Statistiques",
		"castEventLogs": "Journal des événements",
		"streamAllocator": "Dispositif d’allocation de flux",
		"streamManagement": "Flux",
		"streamAllocatorLog": "Journal d’allocation de flux",
		"deletedContexts": "Contextes supprimés",
		"duplicateRegisteredClients": "Clients enregistrés en double"
	},
	"appbar": {
		"helpAndSupport": "Aide et support",
		"notifications": "Notifications",
		"languages": "Sélection de langue",
		"settings": "Paramètres et utilitaires",
		"contextSelector": "Sélectionner le site",
		"availableSites": "Sites disponibles",
		"availableCustomers": "Clients disponibles",
		"availableThemes": "Thèmes disponibles",
		"searchSites": "Rechercher sites",
		"searchCustomers": "Rechercher des clients",
		"searchThemes": "Rechercher des thèmes",
		"availableSharingContexts": "Fournisseurs de ressources disponibles",
		"searchSharingContexts": "Rechercher des fournisseurs de ressources"
	},
	"admin": {
		"admin": "Admin",
		"account": "Compte | Comptes",
		"accountRemoved": "Compte supprimé avec succès",
		"accountRemoveFailed": "Le compte n'a pas été supprimé",
		"accountCreated": "Nouveau compte ajouté",
		"accountSaved": "Données d'accès sauvegardées",
		"accountType": "Type de compte",
		"accountEnabled": "Compte activé",
		"accountAccess": "Accès au compte",
		"apiAccount": "Compte API | Comptes API",
		"deleteAccount": "Supprimer le compte",
		"removeAccount": "Retirer le compte",
		"removeAccessRights": "Retirer les droits d'accès",
		"removeAccessRightsInfo": "Retirer les droits d'accès pour ce compte",
		"disableAPIAccount": "Désactiver le compte API",
		"enableAPIAccount": "Activer le compte API",
		"addApiInfo": "Le nouveau compte API sera automatiquement activé pour l'accès",
		"accountApiState": "Ce compte API est actuellement {state}",
		"accountApiStateWithContextId": "Ce compte API est actuellement {state} pour le contextId {contextId}",
		"disableApiAccountTitle": "Désactiver le compte API ?",
		"disableApiAccountWarning": "La désactivation d'un compte API entraîne la suppression définitive du jeton d'accès du système. Vous pouvez réactiver le compte, mais cela génèrera un nouveau jeton.",
		"disableApiAccountConfirm": "Veuillez confirmer que vous souhaitez supprimer définitivement le jeton d'accès.",
		"deleteUserAccountConfirm": "Veuillez confirmer que vous souhaitez retirer ce compte du système",
		"deleteUserAccountTitle": "Supprimer le compte utilisateur?",
		"deleteUserAccountSuccess": "Compte utilisateur supprimé",
		"deleteApiAccountSuccess": "Compte API supprimé",
		"deleteAccountGenericFailed": "Impossible de supprimer l'entrée!",
		"removedUserAccessSuccess": "Accès utilisateur retiré avec succès",
		"removedUserAccessFailed": "Échec de la suppression de l'accès utilisateur",
		"saveAccountFailed": "Impossible de sauvegarder l'entrée !",
		"accountDataSaved": "Données du compte sauvegardées",
		"apiTokenWarning": "Vous ne verrez ce jeton qu'une seule fois! Copiez ce jeton :",
		"apiTokenTitle": "Jeton d'accès à l'API",
		"customer": "Client | Clients",
		"role": "Rôle | Rôles",
		"privilegeSet": "Jeu de privilèges | Jeux de privilèges",
		"accountsWithRole": "\"{role}\" attribué aux comptes",
		"rolesWithProduct": "Rôles avec \"{product}\"",
		"site": "Site | Sites",
		"system": "Système | Systèmes",
		"country": "Pays | Des pays",
		"area": "Area | Areas",
		"brand": "Marque | Marques",
		"screensLimit": "Limite d'écrans",
		"licensed": "Licencié",
		"sitesLimit": "Limite de sites",
		"deleteCustomer": "Supprimer un client",
		"deleteSite": "Supprimer le site",
		"deleteLegacyWarningText": "AVERTISSEMENT : La suppression d’une référence à un système externe est une action non récupérable.<br>Cette action n’aura aucun effet sur le système externe, mais la référence sera supprimée et votre compte ne pourra plus voir les données d’état de ce système.<br>AUCUN DE CES ÉLÉMENTS NE PEUT ÊTRE RÉCUPÉRÉ.",
		"deleteSiteWarningText": "AVERTISSEMENT: La suppression d'un site est une action non récupérable.<br>La suppression d'un site entraîne également la suppression de toutes les données associées, y compris : les écrans, les pages de menu, les images/fontes, les modèles, les documents, les salles, les licences et plus encore.<br>Aucun de ces éléments ne peut être récupéré.",
		"deleteSiteWarningTextPPDS": "AVERTISSEMENT : La suppression d’un client est une action non récupérable.<br>La suppression d’un client entraîne également la suppression de toutes les données associées, y compris : les écrans, les modèles, les images/fontes, les horaires et plus encore.<br>AUCUN DE CES ÉLÉMENTS NE PEUT ÊTRE RÉCUPÉRÉ.",
		"deleteSiteConfirmationLabel": "Saisissez \"{title}\" dans ce champ de texte pour activer la suppression",
		"copyResources": "Copier les ressources",
		"copyResourcesToSite": "Copier les ressources vers {title}",
		"copyResourcesFromTo": "Copier toutes les ressources du site {from} vers un autre site {to}?",
		"moveSiteFromTo": "Déplacer le site du revendeur {from} au revendeur {to} ?",
		"moveSite": "Déplacer le site {title}",
		"provisioned": "Provisionné",
		"maxSizeProvisioned": "Nombre maximum de sites approvisionnés",
		"addReseller": "Ajouter un revendeur",
		"context": "Contexte | Contextes",
		"contextType": "Type de contexte | Types de contexte",
		"systemRole": "Rôle système | Rôles système ",
		"owner": "Propriétaire | Propriétaires",
		"license": "Licences | Licence | Licences",
		"totalLicenses": "Total des licences",
		"feature": "Fonctionnalité | Fonctionnalités",
		"changeSite": "Changer de site",
		"product": "Produit | Produis",
		"itemId": "ID de l’élément",
		"customId": "Identifiant personnalisé",
		"access": "Accès | Accès",
		"reseller": "Revendeurs | Revendeur | Revendeurs",
		"noResellerAccess": "Vous n'avez pas accès à aucun revendeurs",
		"noDistributorAccess": "Vous n'avez accès à aucun distributeurs",
		"distributor": "Distributeurs | Distributeur | Distributeurs",
		"domain": "Domaines | Domaine | Domaines",
		"resource": "Ressource | Ressources",
		"accountName": "Nom du compte",
		"displayName": "Nom d'affichage",
		"email": "Courriel",
		"phone": "Téléphone",
		"formDisabledSSO": "Les paramètres du compte d’utilisateur ont été désactivés parce que votre session actuelle est connectée par l’intermédiaire d’un fournisseur externe d’authentification unique.",
		"created": "Créé",
		"password": "Mot de passe",
		"repeatPassword": "Répéter mot de passe",
		"passwordHint": "Le mot de passe doit contenir au moins 8 caractères, une combinaison de minuscules/majuscules, de chiffres et de caractères spéciaux",
		"addNewAccess": "Ajouter un nouvel accès",
		"newAccount": "Nouveau compte utilisateur",
		"addAccount": "Ajouter un compte {type}",
		"newApiAccount": "Nouveau compte API",
		"editApiAccount": "Modifier compte API",
		"accessFetchFailed": "Echec de la récupération de l'accès au compte",
		"accessUpdateFailed": "L’accès aux comptes n’a pas pu être défini",
		"unknownAccess": "Accès inconnu",
		"accessToFeatures": "Accès à la fonctionnalité {features} | Accès aux fonctionnalités {features}",
		"passwordLength": "Le mot de passe doit comporter 8 caractères",
		"passwordMatch": "Les mots de passe doivent correspondre",
		"contextAccess": "Accès contextuel",
		"noFeaturesAccess": "Aucune fonctionnalité n'a été ajoutée à cet accès",
		"removeAccess": "Accès contextuelSupprimer l'accès",
		"home": "Accueil",
		"read": "Lire",
		"create": "Créer",
		"update": "Mettre à jour",
		"delete": "Supprimer",
		"oneAccessRelation": "Au moins une relation d'accès est requise",
		"editLicense": "Modifier la licence",
		"contractRef": "Référence du contrat",
		"activationDate": "Date d'activation",
		"expirationDate": "Date d'expiration",
		"noFeaturesSelected": "Aucune fonctionnalité n'a encore été sélectionnée",
		"availableFeatures": "Fonctionnalités disponibles",
		"expirationAfter": "La date d'expiration doit être après à la date d'activation",
		"newLicense": "Nouvelle licence",
		"userAccount": "Compte d'utilisateur",
		"userAdminError": "Le droit d'administration de l'utilisateur n'a pas pu être ajouté",
		"createUserAccount": "Créer un nouveau compte d'utilisateur",
		"noAvailableUsers": "Aucun compte d'utilisateur disponible",
		"selectUser": "Sélectionnez un compte utilisateur",
		"selectUserHelp": "Sélectionnez un utilisateur ici si vous souhaitez lui donner un accès administrateur au contexte sélectionné et aux fonctionnalités de cette nouvelle licence.<br><br>Si ce contexte est remis à un partenaire pour qu'il le maintienne et le configure, vous pouvez créer un nouvel utilisateur administrateur ici.<br><br>Vous pouvez également sélectionner votre propre utilisateur, si vous souhaitez avoir des droits d'administration complets sur ce nouveau contexte.<br><br>Ou vous pouvez laisser le champ vide pour sauter entièrement cette étape.",
		"createNewContext": "Créer un nouveau contexte",
		"noAvailableContexts": "Aucun contextes disponibles",
		"selectContext": "Sélectionnez un contexte",
		"selectContextHelp": "Un contexte peut être une instance d'Enterprise CTRL ou un espace de travail pour un gestionnaire de ressources, et est requis pour une licence.<br><br>Veuillez sélectionner ou en créer un maintenant .",
		"createNewSite": "Créer un nouveau site",
		"noAvailableSites": "Aucun site disponible",
		"selectSite": "Sélectionnez un site",
		"selectSiteHelp": "Un site est un emplacement physique dans lequel un contexte existe - un hôtel par exemple.<br><br>Dans cette étape, vous pouvez sélectionner ou créer un site à utiliser pour cette nouvelle licence.",
		"deploymentInfo": "Un déploiement d'entreprise standard avec un site d'appartenance",
		"managedBy": "Géré par",
		"deployedAt": "Déployé à",
		"locatedIn": "Situé à",
		"belongsTo": "Appartient à",
		"streetAddress": "Adresse postale",
		"postalCode": "Code postal",
		"contactName": "Nom du contact",
		"contactEmail": "Courriel du contact",
		"contactPhone": "Téléphone de contact",
		"contactInfo": "Informations de contact",
		"defaultLanguage": "Langue par défaut",
		"availableLanguages": "Langues disponibles",
		"createAccount": "Créer un compte",
		"createArea": "Créer une zone",
		"createLicense": "Créer une licence",
		"createDistributor": "Créer un distributeur",
		"createDomain": "Créer un domaine",
		"createReseller": "Créer un revendeur",
		"lastChange": "Dernier changement",
		"createOwner": "Créer propriétaire",
		"createProduct": "Créer produit",
		"createSite": "Créer site",
		"createCustomer": "Créer un client",
		"createResource": "Créer ressource",
		"createTheme": "Créer thème",
		"createSharingContext": "Créer un fournisseur de ressources",
		"createRole": "Créer rôle",
		"roleName": "Nom du rôle",
		"newRole": "Nouveau rôle",
		"newPrivilegeSet": "Nouveau jeu de privilèges",
		"newDistributor": "Nouveau distributeur",
		"newDomain": "Nouveau domaine",
		"selectRole": "Sélectionner rôles",
		"noRolesAccess": "Aucun rôle n'a été ajouté à cet accès",
		"removeRole": "Retirer",
		"accessToRoles": "{roles} Rôle  | {roles} Rôles ",
		"availableRole": "Rôle disponible | Rôle disponibles",
		"limits": "Limite | Limites",
		"oldPassword": "Ancien mot de passe",
		"newPassword": "Nouveau mot de passe",
		"repeatNewPassword": "Répéter le nouveau mot de passe",
		"vendorConfig": "Configuration du fournisseur",
		"usingCustomSupportInfo": "Info support personnalisé",
		"myAccount": "Mon compte",
		"userSaveFailedGeneral": "Impossible de sauvegarder les informations de l'utilisateur",
		"userSaveFailedEmail": "Impossible de mettre à jour l'adresse e-mail de l'utilisateu",
		"userChangeEmailHeader": "Pour modifier votre adresse e-mail, vous devez fournir votre mot de passe",
		"updateEmail": "Mettre à jour l'adresse e-mail",
		"userSaveFailedPassword": "Impossible de mettre à jour le mot de passe de l'utilisateur",
		"userUpdatePasswordTitle": "Mettre à jour le mot de passe",
		"userUpdatePasswordDescription": "En cas de succès, cela déconnectera toutes les sessions actives de votre utilisateur actuel. Procédez?",
		"changeYourPassword": "Changez votre mot de passe",
		"changePassword": "Changer le mot de passe",
		"share": "Partager",
		"addNewShare": "Partager avec...",
		"addApiAccount": "Ajouter un compte API",
		"addUserAccount": "Ajouter un compte utilisateur",
		"resellerLicenseInfoSite": "Les revendeurs peuvent approvisionner les sites et les licences. Vous gérez",
		"resellerLicenseInfoCustomer": "Les revendeurs peuvent approvisionner les clients et les licences. Vous gérez",
		"distributorLicenseInfo": "Les distributeurs peuvent approvisionner les revendeurs. Vous gérez",
		"newReseller": "Nouveau revendeur",
		"editAccessFor": "Modifier l'accès pour",
		"editAccessToReseller": "Modifier l'accès au revendeur",
		"distributorAccess": "Accès au distributeur",
		"domainAccess": "Accès au domaine",
		"resellerAccess": "Accès revendeur",
		"themeAccess": "Accès au thème",
		"siteAccess": "Accès au site",
		"deleteDistributorText": "Un distributeur ne peut être supprimé qu’en l’absence de revendeurs et de thèmes provisionnés.<br>Veuillez confirmer que vous souhaitez supprimer ce distributeur.",
		"deleteDistributorFailedText": "Impossible de supprimer le distributeur :{details}",
		"distributorHasProvisionedContext": "des revendeurs provisionnés et/ou des thèmes sont associés au distributeur",
		"deleteDomainText": "Un domaine peut être supprimé qu’en l’absence de distributeurs et de thèmes provisionnés.<br>Veuillez confirmer que vous souhaitez supprimer ce domaine.",
		"deleteDomainFailedText": "Impossible de supprimer le domaine : {details}",
		"domainHasProvisionedContext": "des distributeurs provisionnés et/ou des thèmes sont associés au domaine",
		"externalSystemsHeading": "Les informations du système externe peuvent être modifiées ici",
		"createPrivilegeSet": "Créer un ensemble de privilèges",
		"privilegeSetName": "Nom du jeu de privilèges",
		"systemPrivilegeSet": "Jeu de privilèges du système",
		"noPrivilegeSetsAdded": "Aucun jeu de privilèges n’a été ajouté",
		"chooseTypePrivilegeSet": "Choisir un \"type de contexte\" avant de sélectionner les ensembles de privilèges",
		"forceSSO": "Forcer le SSO",
		"forceSSOHint": "Utilisez cette option si vous souhaitez limiter l’accès des utilisateurs à un fournisseur SSO tiers spécifique",
		"idp": "Forcer le SSO",
		"copyRole": "Copier à partir d’un rôle existant",
		"roleProductsHint": "Ce rôle ne sera disponible que pour les produits sélectionnés",
		"roleProductDisabledHint": "S’applique uniquement aux rôles de déploiement hors système",
		"copyOfName": "Copier le {name}",
		"contextPrivilegeSetSelectionTitle": "Sélection d’accès",
		"contextPrivilegeSelectionDescription": "Choisir les menus et sous-menus du portail auxquels ce rôle aura accès",
		"deletedContext": "Contexte supprimé",
		"finalizeDelete": "Finaliser la suppression",
		"undeleteConfirmMsg": "Êtes-vous sûr de vouloir annuler la suppression de ce contexte ? Êtes-vous sûr de vouloir annuler la suppression de ces {amount} contextes ?",
		"undelete": "Annuler la suppression ",
		"undeleteSuccess": "Contexte non supprimé | {amount} contextes non supprimés",
		"undeleteFailed": "Échec de l’annulation de la suppression",
		"finalizeDeleteConfirmMsg": "Êtes-vous sûr de vouloir finaliser la suppression de ce contexte ? | Êtes-vous sûr de vouloir finaliser la suppression de ces {amount} contextes ?",
		"finalizeDeleteSuccess": "La suppression du contexte a été finalisée | La suppression des {amount} contextes a été finalisée",
		"finalizeDeleteFailed": "Échec de la finalisation de la suppression",
		"activateSite": "Activer le site",
		"activateSiteDescription": "L’activation d’un site supprimera tous les filigranes et lancera le plan de facturation",
		"activateSiteConfirmTex": "Je comprends que cette action est définitive",
		"siteActivated": "Site activé",
		"duplicateRegisteredClients": "Client enregistré en double",
		"userId": "ID de l'utilisateur",
		"copiedAuthToken": "Jeton d'authentification copié",
	},
	"hospitality": {
		"hospitality": "Hospitalité",
		"room": "Chambre | Chambres",
		"additionalRooms": "Salles supplémentaires",
		"occupancy": "Occupation",
		"guest": "Invité | Invités",
		"wakeup": "Réveil | Réveils",
		"guestMessage": "Messages | Message | Messages",
		"stay": "Séjour | Séjours",
		"account": "Compte | Comptes",
		"virtualRemoteClients": {
			"session": "session | sessions",
			"roomName": "Salle",
			"screenName": "Télévision",
			"sessionId": "ID de session",
			"remoteClientId": "VRC ID",
			"created": "Créé à",
			"tvOnline": "Télévision en ligne",
			"vrcOnline": "VRC en ligne"
		},
		"config": {
			"chromecastCheckoutLabel": "Vérification des Chromecasts",
			"chromecastCheckoutExplanation": "Réinitialiser les Chromecasts associés à la salle au moment du départ du client.",
			"chromecastFacilityIdLabel": "ID de l’installation Chromecast",
			"chromecastTokenLabel": "Jeton Chromecast",
			"serviceMenuCodeLabel": "Code du menu de service",
			"nightTimeToggleLabel": "Nuit",
			"nightTimeInfo": "Période pendant laquelle les télévisions ne s’allument pour aucun événement, à l’exception des réveils.",
			"nightTimeFromLabel": "Nuit à partir de",
			"nightTimeToLabel": "Nuit jusqu’à"
		}
	},
	"dashboard": {
		"welcome": "Bienvenue {name}",
		"signedInto": "Vous êtes actuellement connecté au site {site}",
		"occupancyLastNextWeek": "Occupation de la semaine dernière et de la semaine prochaine",
		"upcomingWakeups": "Réveils à venir",
		"mediaChannels": "Canaux médias définis",
		"activeInstallCodes": "Codes d'installation actifs!",
		"newIssuesLastDays": "Nouveaux problèmes les {days} derniers jours",
		"newLastDays": "Nouveautés des {days} derniers jours",
		"account": "Compte d'utilisateur | Comptes d'utilisateur",
		"apiAccount": "Compte API | Comptes API",
		"occupancyPercent": "Occupation (%)",
		"sectionHelp": "Fermez et ouvrez les sections du tableau de bord en cliquant sur leurs en-têtes respectifs",
		"filterSites": "Filtrer les sites par",
		"filterCustomers": "Filtrer les clients par",
		"clickOnMap": "Cliquez sur un élément de la carte pour obtenir des détails",
		"displayingData": "Affichage des données pour {amount} site | Affichage des données pour {amount} sites",
		"noFeaturesActivated": "Aucune fonctionnalité activée...",
		"clearSelection": "Effacer la sélection",
		"details": "Détails",
		"openExternal": "Portail de gestion ouvert",
		"openSite": "Ouvrir le site",
		"openSiteError": "Impossible d'ouvrir le site de type  {type}",
		"openTvPreview": "Ouvrir l’aperçu TV",
		"notManaged": "Non géré",
		"vacant": "Vacant",
		"managementAccess": "Accès à la gestion",
		"mergeCCs": "Fusionner les casts dupliqués",
		"mapView": "Vue de la carte",
		"listView": "Vue de la liste",
		"stats": "Statistiques",
		"map": "Carte",
		"list": "Liste",
		"noReportGenerated": "Aucun rapport généré",
		"buildReport": "Élaborer le rapport",
		"rebuildReport": "Rétablir le rapport",
		"generateStatisticsReport": "Générer un rapport de statistiques",
		"processingData": "Données en cours de traitement...",
		"configureFields": "Configurer les champs",
		"downloadSelected": "Télécharger la sélection",
		"searchAllFields": "Rechercher dans tous les champs visibles",
		"cluster": "Cluster",
		"installedTvs": "Télévisions installées",
		"product": "PRODUIT",
		"filter": "FILTRE",
		"hideDemos": "Cacher les démos",
		"hideWhitelabeled": "Cacher les marques blanches",
		"hideWatermarked": "Cacher le filigrane",
		"licenseInfo": "Informations sur la licence",
		"tvLicenses": "Licences TV",
		"signageLicenses": "Licences d’affichage",
		"castLicenses": "Licences de Cast",
		"products": "Produits"
	},
	"drawer": {
		"userSettings": "Paramètres de l'utilisateur",
		"contextButtonHelp": "Cliquez sur le menu hamburger dans la barre supérieure pour passer à un autre site ou à l'écran d'accueil",
		"mainHelp": "Survolez le menu de gauche pour le développer, ce menu contient les principaux éléments de navigation pour le site ou l'écran d'accueil actuel"
	},
	"userMenu": {
		"theme": "Thème",
		"dark": "Sombre",
		"notification": "Notification | Notifications",
		"pinMenu": "Menu d'épinglage",
		"all": "Tous",
		"success": "Succès",
		"info": "Info",
		"resetHints": "Réinitialiser les indices",
		"settings": "Paramètres du compte",
		"logOut": "Déconnexion",
		"confirmLogout": "Veuillez confirmer",
		"hintsReset": "Les indices ont été réinitialisés",
		"timeDisplay": "Horloge 12h"
	},
	"reorderList": {
		"dragDropHelp": "Glissez et déposez les éléments de la liste pour les réorganiser"
	},
	"baseTable": {
		"selected": "{selected} a sélectionnés {type} | {selected} a sélectionnés {type}s",
		"enableMultiselect": "Activer la sélection multiple",
		"disableMultiselect": "Désactiver la sélection multiple",
		"searchLabel": "Recherche par tous les champs visibles",
		"searchTooltip": "Combiner des mots-clés en utilisant '&'",
		"export": "Exporter vers excel"
	},
	"channelTables": {
		"channelTable": "Tableau de canal de télévision | Tableaux des canaux de télévision",
		"radioChannelTable": "Tableau de canal radio | Tableaux des canaux radio ",
		"channelTableGroup": "Groupe de tableaux de canaux | Groupes des tableaux de canaux",
		"tableGroup": "Groupe de table | Groupe de tables",
		"dragDropHere": "Faites glisser et déposez les canaux ici",
		"newName": "Nouveau nom",
		"availableChannels": "Canaux disponibles",
		"addAllChannels": "Ajouter tous les canaux",
		"selectedChannels": "Canaux sélectionnés",
		"removeAllChannels": "Retirer tous les canaux",
		"unknownChannel": "Canal inconnu",
		"noGroups": "Il n'y a pas de groupes dans cette table",
		"table": "Tableau | Tableaux",
		"clickToChange": "Cliquez ici pour changer de table",
		"createFirst": "Créez votre première table de canal",
		"headerSelectHint": "Cliquez sur le nom de la table dans l'en-tête pour sélectionner une autre table à éditer",
		"dragDropChannels": "Glissez et déposez les canaux pour les sélectionner et les réorganiser",
		"channelTableName": "Nom de la table de canal",
		"new": "Nouveau",
		"deleteText": "Supprimer la table de canal \"{name}\"?<br>Veuillez vous assurer que cette table de canaux n'est pas utilisée dans les configurations d'écran.",
		"saveGroupError": "Impossible d'enregistrer le groupe",
		"saveGroupChannelsError": "Erreur de sauvegarde du canal dans le groupe",
		"saveChannelsError": "Erreur de sauvegarde des canaux",
		"appendToGroupError": "Erreur d'enregistrement dans le groupe",
		"createGroupError": "Erreur en créant le groupe",
		"createdGroup": "Groupe {name} créé",
		"deletedGroup": "Groupe {name} supprimé",
		"channelsSaved": "Canaux enregistrés"
	},
	"event": {
		"defaultEventValues": "Valeurs par défaut de l’événement",
		"eventDeleteSuccess": "L’événement a été supprimé avec succès",
		"eventDeleteFailed": "Impossible de supprimer l’événement",
		"createEvent": "Créer un événement",
		"editEvent": "Modifier un événement",
		"deleteEvent": "Supprimer un événement",
		"eventPrivate": "Privé",
		"startTime": "Heure de début",
		"startDate": "Date de début",
		"endTime": "Heure de fin",
		"endDate": "Date de fin",
		"eventHeader": "Événements - {date}",
		"eventPrivateHint": "(masquer le titre et l’hôte sur les écrans de direction et de réunion)",
		"eventInFuture": "L’événement doit avoir une date future",
		"eventEndBeforeStart": "L’événement doit se terminer après l’heure de début",
		"eventOverlappingDescription": "Il y a déjà un chevauchement d’événements à cet emplacement. Êtes-vous sûr de vouloir créer cet événement ?",
		"eventOverlapping": "Chevauchement d’événements",
		"copyEvent": "Copier l’événement {name}",
		"copyEventToLocation": "Copier un événement dans un emplacement",
		"makeCopyEvent": "Faire une copie de l’événement",
		"privateTitle": "Titre privé",
		"noLocations": "Aucun emplacement n’a été trouvé",
		"wayfindingConfiguration": "Configuration de la direction | Configurations de la direction",
		"saveWayfindingConfig": "Sauvegarder la configuration de la direction",
		"saveWayfindingConfigFailed": "",
		"integrationLocations": "Emplacement d’intégration | Emplacements d’intégration",
		"manageLogos": "Gérer les logos",
		"selectLogo": "Sélectionner le logo",
		"replaceLogo": "Remplacer le logo",
		"removeLogo": "Supprimer le logo",
		"manageIcons": "Gérer les icônes",
		"eventRetention": "Conservation des événements",
		"eventRetentionDescription": "Nombre de jours pendant lesquels les événements seront conservés dans le système après leur achèvement.",
		"maxEventDuration": "Durée",
		"maxEventDurationDescription": "Durée maximale de l’événement lors d’une réservation sur le tableau de bord de la salle",
		"applyLogoByHost": "Appliquer un logo par nom d’hôte",
		"logoMappingDesc": "Appliquer automatiquement un logo lors de la création d’un événement, en fonction du nom de l’hôte. N’oubliez pas d’ajouter le mappage de l’hôte au logo pour que cela fonctionne.",
		"hostMapping": "Mappage de l’hôte",
		"addHostMapping": "Ajouter le mappage de l’hôte",
		"failedToSaveMapping": "Impossible de sauvegarder le mappage",
		"mappingAlreadyExists": "Le mappage de l’hôte existe déjà sur un logo"
	},
	"tvMenu": {
		"tvMenuPage": "Page de menu TV | Pages de menu TV",
		"menuPage": "Page de menu | Page de menu",
		"subtitle": "Sous-titre | Sous-titres",
		"audioLanguage": "Langue audio | Langues audio",
		"subtitleLanguage": "Langue de sous-titre | Langue des sous-titres",
		"epgMenu": "Menu EPG | Menus EPG",
		"offlineMenu": "Menu hors ligne | Menus hors ligne",
		"externalInput": "Menu d'entrée externe | Menus d'entrée externe",
		"wakeupMenu": "Menu d'ordre de réveil | Menus d'ordre de réveil",
		"defaultWakeupAlarmMenu": "Menu de l’alarme de réveil par défaut",
		"powerOnMenu": "Menu d’allumage | Menus d’allumage",
		"messageNotificationMenu": "Menu de notification des messages",
		"messageDisplayMenu": "Menu d’affichage des messages"
	},
	"documents": {
		"page": "Page | Pages",
		"document": "Document | Documents",
		"selectPage": "Sélectionner une page"
	},
	"resources": {
		"resource": "Ressource | Ressources",
		"font": "Police | Polices",
		"image": "Image | Images",
		"video": "Vidéo | Vidéos",
		"playlist": "Liste de lecture | Listes de lecture",
		"template": "Modèle | Modèles",
		"compositeMenuPages": "Page de modèle d’affichage | Pages de modèle d’affichage",
		"logo": "Logo | Logos",
		"wayfindingIcon": "Icône directionnelle | Icônes directionnelles",
		"uploadedYet": "Aucun {type}s n'a encore été téléchargés",
		"selected": "{amount} {type} sélectionnés | {amount} {type}s sélectionnés",
		"copyUrl": "Copier l'URL",
		"copyResources": "Copier la ressource vers {dest}? | Copier {count} les ressources vers {dest}?",
		"moveResources": "Déplacer la ressource vers le dossier {dest}? | Déplacer {count} ressources vers le dossier {dest}?",
		"moveResource": "Déplacer {src} vers le dossier {dst} ?",
		"copying": "Copie",
		"copyToFolderSuccess": "Copie de {resourceName} vers le dossier {folderName} réussie",
		"copyToFolderFailure": "Échec de la copie de {resourceName} vers le dossier {folderName}",
		"copyFinishedWithError": "Terminé avec erreur",
		"copySuccessDescription": " | {amount} ressource copiée avec succès dans {context} | {amount} ressources copiées avec succès dans {context}",
		"circularReferenceErrorMsg": "{amount} {type} comportent des références circulaires doivent être modifiées/supprimées pour permettre la sauvegarde.",
		"failedToCopy": "Échec de la copie",
		"downloadFile": "Télécharger le fichier | Télécharger les fichiers",
		"downloadingName": "Téléchargement de {name}",
		"downloadFailed": "Échec du téléchargement!",
		"selectImage": "Sélectionner une image",
		"selectFont": "Sélectionner une police",
		"selectVideo": "Sélectionner une vidéo",
		"newPlaylist": "Nouvelle liste de lecture",
		"upload": "Télécharger",
		"uploadMedia": "Télécharger média",
		"uploadResource": "Télécharger ressource",
		"uploadFileType": "Téléchargement {type} | Téléchargement(s) {type}",
		"uploadSuccessful": "Téléchargement réussi - {type} est en cours de traitement",
		"uploadError": "Erreur pendant le téléchargement - veuillez réessayer",
		"replaceResource": "Remplacer {resource}",
		"replaceResourceTitle": "Remplacer {resource}: {name}",
		"replaceResourceDescription": "AVERTISSEMENT ! les éléments remplacés sont toujours mis en cache dans le nuage, il faudra donc un certain temps avant que la nouvelle image ne soit affichée sur les écrans.<br><br>Êtes-vous sûr de vouloir remplacer l’image actuelle {resource} par <br>« {name} » ?",
		"resourceReplaced": "{resource} remplacé",
		"resourceReplacedFailed": "Échec du remplacement de {resource}",
		"editImageBefore": "Modifier l'image avant de la télécharger",
		"uploaderTitle": "Télécharger {type}s",
		"unsupportedFile": "Les fichiers de type {type} ne sont pas pris en charge",
		"unsupportedFileSize": "Les fichiers ne peuvent pas être plus volumineux que {size}",
		"unsupportedFileNameAndSize": "Le fichier {fileName} ne peuvent pas être plus volumineux que {size}",
		"exceedingMaxPdfPages": "Dépassement de la limite maximale de {limit} pages dans le PDF",
		"wrongType": "Seuls les fichiers de type {type}s sont autorisés pour le téléchargement",
		"quickBrownFox": "Le renard brun et rapide saute par-dessus le chien paresseux",
		"addOneOrMore": "Ajoutez les images, les vidéos et les polices que vous souhaitez télécharger",
		"addOneOrMoreExcludeVideos": "Ajouter les images, les polices et les PDF que vous souhaitez télécharger",
		"addType": "Ajouter le {type} que vous souhaitez télécharger | Ajouter le(s) {type}(s) que vous souhaitez télécharger",
		"pressUpload": "Appuyez sur télécharger pour sauvegarder les fichiers ajoutés dans le système",
		"rotateLeft": "Rotation de 45 degrés à gauche",
		"rotateRight": "Rotation de 45 degrés à droite",
		"crop": "Découpage",
		"addResources": "Ajouter ressources",
		"uploadSuccessfulHover": "Téléchargement réussi",
		"addDocument": "Ajouter document",
		"addTemplate": "Ajouter modèle",
		"potentialUsageFound": "L'utilisation potentielle suivante a été trouvée:",
		"potentialUsageFoundInItems": "Une utilisation potentielle a été trouvée pour les éléments:",
		"folderNotEmpty": "Le dossier n'est pas vide",
		"deleteFolderWithUsage": "Ces dossiers contiennent des éléments avec une utilisation potentielle",
		"deleteFolderWarning": "La suppression de ce dossier entraînera également la suppression définitive de tout son contenu, y compris les sous-dossiers et les ressources.",
		"deleteFolderConfirm": "Je confirme que je veux supprimer définitivement ce dossier et son contenu",
		"newPlaylistFromPdf": "nouvelle liste de lecture à partir d’un PDF",
		"displayDuration": "Durée d’affichage",
		"pdfUploadFolderError": "Échec de la création d’un dossier média pour le PDF, téléchargement dans le dossier média racine",
		"tag": "Étiquette | Étiquettes",
		"addTag": "Ajouter une étiquette",
		"transcodeVideo4K": "La vidéo sera disponible en 4K peu de temps après la mise en ligne",
		"transcodeVideoHD": "La vidéo sera disponible en HD peu de temps après la mise en ligne"
	},
	"mediaChannels": {
		"mediaChannels": "Canaux média",
		"mediaChannel": "Canal média | Canaux média",
		"globalMediaChannels": "Canaux média globaux",
		"globalMapping": "Mappage global",
		"address": "Adresse",
		"port": "Port",
		"serviceId": "ID de service",
		"frequency": "Fréq (kHz)",
		"modulation": "Modulation",
		"symbolRate": "Taux de symbole",
		"bandwidth": "Bande passante",
		"satelliteId": "ID du satellite",
		"polarization": "Polarisation",
		"programNumber": "Numéro de programme",
		"majorNumber": "Numéro du canal principal",
		"minorNumber": "Numéro du canal secondaire",
		"type": "Type",
		"audioOnly": "Audio uniquement",
		"createChannel": "Créer un canal de {type}",
		"save": "Sauvegarder les canaux",
		"saveTooltip": "Ceci permettra de sauvegarder les canaux de tous types",
		"clickToChange": "Cliquez ici pour changer de type de canal",
		"reset": "Réinitialiser et rafraîchir les canaux",
		"types": "Types de canaux",
		"webRadioSearch": "Recherche de radio Web",
		"unknownBitrate": "Inconnu",
		"placeholderChannelName": "Nom du canal",
		"updateFailed": "La mise à jour des canaux média a échoué",
		"deleteFailed": "Impossible de supprimer le canal!",
		"createFailed": "Impossible de créer un canal!",
		"updateSuccess": "Mise à jour des canaux média !",
		"deleteSuccess": "Canal média supprimé !",
		"createSuccess": "Canal média créé !",
		"validateFailed": "Un canal de type {type} a un nom vide"
	},
	"templates": {
		"templateErrorMessages": {
			"deserializationErrorMsg": "Le moteur de modèle n'a pas pu analyser les données de l'API. Vérifiez les paramètres de Paramètre Fixe / Test / Connecteur API pour vous assurer que les données correctes sont récupérées."
		},
		"dataFetchErrorMessages": {
			"unknownHost": "Inconnue URL lorsque vous essayez d'extraire des données de l'API. Cela peut être dû à une faute de frappe dans l'URL du connecteur API et/ou dans le paramètre de chemin fixe/test.",
			"protocolError": "Erreur dans le protocole HTTP lors de la tentative d'extraction des données de l'API. Cela peut être dû à une faute de frappe dans l'URL du connecteur d'API et/ou dans le paramètre de chemin d'accès fixe/test.",
			"unknownError": "Erreur lors de l'extraction des données de l'API. Vérifiez les paramètres du connecteur API / Fixe/test.",
			"code300ErrorMsg": "L’API a renvoyé le code 300 - Multiple Choices (choix multipes), ce qui indique qu’il existe plusieurs options pour la ressource demandée. Vérifier les paramètres de chemin fixe/test.",
			"code400ErrorMsg": "L’API a renvoyé le code 400 - Bad Request (mauvaise requête), l’API n’a pas pu traiter la demande en raison d’une syntaxe non valide. Vérifiez les paramètres du connecteur/les paramètre de chemin fixe/test.",
			"code401ErrorMsg": "L’API a renvoyé le code 401 - Unauthorized (non autorisé), vous n’êtes pas autorisé à faire cette demande. Vérifier que les en-têtes du connecteur API sont corrects",
			"code403ErrorMsg": "L’API renvoie le code de statut 403 - Forbidden (interdit) Cela peut indiquer que les paramètres du connecteur ou les paramètres fixes sont configurés avec une autorisation incorrecte, invalide ou obsolète.",
			"code404ErrorMsg": "L’API renvoie le code de statut 404 - Not Found (introuvable). Cela peut signifier que l’URL est valide mais que la ressource elle-même n’existe pas. Les API peuvent également envoyer cette réponse au lieu de la réponse 403 pour cacher l’existence d’une ressource à un client non autorisé. Assurez-vous que les paramètres du connecteur/de test/fixes sont corrects.",
			"code406ErrorMsg": "L’API a renvoyé le code 406 Not acceptable (inacceptable), l’API n’a pas trouvé de contenu correspondant aux critères indiqués. Vérifiez les paramètres de test/fixes.",
			"code415ErrorMsg": "L’API a renvoyé le code 415 - Unsupported Media Type (type de média non pris en charge). Vérifiez les paramètres du connecteur API. Essayez de définir l’en-tête personnalisé Content-Type (type de contenu) dans un format pris en charge par l’API.",
			"code500ErrorMsg": "L’API a renvoyé le code d’état 500 - Internal Server error (erreur interne du serveur). Vérifier les paramètres de chemin fixe/test.",
			"apiTimeout": "La réponse de l’API a expiré. L’API peut être temporairement indisponible."
		},
		"serializationErrors": {
			"jsonParseError": "Impossible d’analyser les données JSON renvoyées par l’API, veuillez vérifier les paramètres du connecteur API/les paramètres fixes."
		},
		"internalErrors": {
			"code500ErrorMsg": "Il y a un problème avec nos systèmes, veuillez réessayer dans quelques instants."
		},
		"noDataYet": "Pas encore de données.",
		"noDataFoundYet": "Aucune donnée n'a encore été trouvée!",
		"save": "Enregistrer le modèle sans fermer la fenêtre",
		"setATest": "Définir un test {name}",
		"infoUrlDefault": "URL de la page d'information sur l'API",
		"infoUrlTooltip": "Comment utiliser la source",
		"error": "Erreur d'évaluation du modèle",
		"httpMethodRequired": "Méthode HTTP requise",
		"nameRequired": "Nom requis",
		"paramType": "Type de paramètre",
		"addItem": "Ajouter élément",
		"preview": "Générer l'aperçu",
		"template": "Modèle | Modèles",
		"templateRequired": "Modèle requis",
		"reset": "Réinitialiser les modifications apportées à ce modèle depuis la dernière sauvegarde",
		"source": "Source | Sources",
		"sourceRequired": "Source requise",
		"fixedParameters": "Paramètres fixes",
		"setAFixed": "Définir un {name} fixe",
		"templateExample": "Exemple de modèle",
		"dataExample": "Exemple de données",
		"httpMethod": "Méthode HTTP",
		"markup": "Balisage",
		"sourceError": "Erreur: Le chargement de la source a échoué",
		"selectConfigure": "Sélectionner et configurer le modèle",
		"failedSource": "Erreur: Impossible de trouver la source du modèle",
		"failedSourceFetch": "Erreur: Impossible de récupérer l'information sur la source",
		"templateSource": "Source du modèle | Sources du modèle",
		"rootName": "Nom de la racine",
		"apiConnector": "Connecteur API | Connecteurs API ",
		"createTemplate": "Créer un modèle",
		"createWithoutClosing": "Créer un modèle sans fermer la fenêtre",
		"saveWithoutClosing": "Enregistrer le modèle sans fermer la fenêtre",
		"fetchApiData": "Récupérer les données API",
		"cacheForSeconds": "La mise en cache n’est pas activée (0 seconde) | Mettre en cache le modèle d’API pendant {value} seconde | Mettre en cache le modèle d’API pendant {value} secondes",
		"cacheForSecondsError": "Le nombre doit être compris entre 0 et 1800",
		"defaultTemplateErrorExplanation": "Contenu à afficher sur la télévision si le modèle contient des erreurs."
	},
	"configSchedules": {
		"defaultConfig": "Par défaut",
		"dailyOverrides": "Remplacements quotidiens",
		"weeklyOverrides": "Remplacements hebdomadaires",
		"editTimeslot": "Modifier le créneau horaire",
		"deleteTimeslot": "Supprimer un créneau horaire",
		"dragStopTime": "Faire glisser pour modifier l’heure d’arrêt",
		"defaultContentExplanation": "Cette configuration fonctionne toute la journée sur l’écran, sauf si elle est remplacée par des configurations quotidiennes, hebdomadaires ou basées sur le calendrier.",
		"editScheduleName": "Modifier le nom de l’horaire",
		"tabDefault": "Par défaut",
		"tabDaily": "Quotidien",
		"tabWeekly": "Hebdomadaire",
		"tabCalendar": "Calendrier",
		"addTimeslotHelpText": "Cliquez pour ajouter un nouveau créneau horaire, ou cliquez simplement sur un créneau dans le calendrier."
	},
	"scheduleMapping": {
		"createNew": "Créer un nouveau planification de mappage",
		"rename": "Renommer  {title}",
		"created": "Nouveau planification de mappage créé",
		"updated": "Planification de mappage mise à jour",
		"currentSchedule": "Planification actuelle",
		"deleteHeading": "Supprimer le planification de mappage {title}?",
		"deleteText": "Tous les écrans qui font partie de ce mappage seront déplacés vers le groupe de planification de mappage par défaut.",
		"addMapping": "Ajouter un mappage",
		"screens": "Écran | Écrans",
		"screensHeadingInfo": "Écrans de télévision utilisant un jeu de règles. Cliquez sur le bouton droit de la souris pour déplacer.",
		"moveTo": "Déplacer vers...",
		"rulesHeading": "Les règles sont évaluées dans l'ordre où elles apparaissent ici",
		"rulesDescription": "Les règles personnalisées ne sont pas encore prises en charge.",
		"newRule": "Nouvelle règle",
		"defaultSchedule": "Planification par défaut",
		"screensMoved": "Écrans déplacés",
		"createFailed": "Impossible de créer un mappage",
		"updateFailed": "Impossible de mettre à jour le mappage"
	},
	"schedulePlans": {
		"editor": {
			"notInUse": "Plans de planification non utilisés. Appuyez sur « Créer » pour commencer l’utilisation.\n Nous générons automatiquement un plan de planification « par défaut » qui contient votre configuration actuelle de planification et d’écran, en plus du nouveau plan de planification que vous créez.",
			"newForEach": "Planifications séparées pour chaque écran",
			"singleNew": "Planification partagée pour tous les écrans",
			"copyExisting": "Copier un plan de planification existant",
			"removeWhenActive": "<b>Ce plan de planification est actuellement activé</b>. Si vous décidez de le supprimer, tous les écrans passeront au plan de planification par défaut. <br><br> Êtes-vous sûr de vouloir supprimer ce plan de planification ?"
		},
		"screenEditor": {
			"newScheduleForEach": "Créer une nouvelle planification pour chaque plan de planification",
			"individualSelectionDescription": "Sélection individuelle"
		},
		"type": "Plan de planification | Plans de planification",
		"setActive": "Activer",
		"setActiveConfirmTitle": "Activer la planification ?",
		"disabledDeleteForDefault": "Supprimer le plan de planification par défaut n’est activé que lorsqu’il n’y a pas d’autres plans de planification",
		"setActiveConfirmText": "Cette action rendra le plan de planification « {title} » actif. Tous les écrans de ce site auront des planifications définies sous le plan de planification {title} comme étant leurs planifications actives. Souhaitez-vous poursuivre ?",
		"setActiveSuccess": "Plan de planification {name} actif",
		"setActiveFailed": "Impossible d’activer le plan de planification {name}"
	},
	"screens": {
		"screensMissingSchedulesTitle": "Écrans sans planification",
		"screensMissingSchedulesText": "Les écrans suivants sont dépourvus de planifications, ce qui peut être dû à l’interruption du processus en deux étapes de création/déplacement des écrans.",
		"screenStatus": "État de l'écran | État de l'écran",
		"tvScreenStatus": "Statut de l’écran TV",
		"signageDisplayStatus": "Statut de l’écran d’affichage",
		"screens": "Écrans",
		"livePreview": "Aperçu en direct",
		"displays": "Affichages",
		"onlineScreens": "écrans de télévision en ligne",
		"screenSetup": "Configuration d'écran | Configuration d'écran",
		"screenSetupCreated": "Configuration d'écran créée",
		"screenSetupUpdated": "Mise à jour de la configuration de l'écran",
		"defaultScreenConfig": "Configuration par défaut",
		"tvScreen": "Ecran TV | Ecrans TV",
		"signageScreen": "Écran d’affichage | Écrans d’affichage",
		"tvType": "Type de TV | Types de TV",
		"clientType": "Type de client | Types de clients",
		"tvSoftware": "Logiciel TV",
		"editName": "Modifier le nom",
		"tvClient": "Client de TV | Clients de TV",
		"unknownSetup": "Configuration inconnue",
		"createFirstSchedule": "Créez votre premier planification",
		"schedule": "Planification | Planifications",
		"copyFromConfig": "Copie de la configuration (optional)",
		"quicklyCopyConfig": "Copier rapidement une autre configuration",
		"mainMenu": "Menu principal | Menus principaux",
		"welcomeMenu": "Menu d'accueil | Menus d'accueil",
		"channelMenu": "Menu de canal TV | Menus des canaux TV",
		"radioChannelMenu": "Menu canal radio | Menus canal radio",
		"startVolume": "Volume de démarrage",
		"maxVolume": "Volume maximum",
		"autoPowerOffHrs": "Extinction automatique après {hrs} heures",
		"enableAutoPowerOff": "Activer l’extinction automatique",
		"audioLanguageMenu": "Menu langue audio",
		"subtitleLanguageMenu": "Menu langue des sous-titres",
		"externalInputMenu": "Menu entrée externe | Menus entrée externe",
		"noScreenSetups": "Aucune configuration d'écran n'a encore été créée",
		"selectSlotType": "Sélectionnez le type de créneau horaire",
		"scheduleEditorCalendarHint": "Cliquez n'importe où sur le calendrier pour ajouter un nouveau créneau horaire",
		"editSlot": "Modifier le créneau",
		"newSlot": "Nouveau créneau",
		"createNewConfig": "Créer une nouvelle configuration",
		"timeslotOverlap": "L'heure de début et de fin sélectionnée chevauche une autre créneau horaire. Veuillez réessayer avec des horaires différents.",
		"volume": "Volume",
		"durationRequired": "Durée requise",
		"durationLongerThan": "La durée doit être supérieure à 0 seconde",
		"messageInJson": "Message au format JSON",
		"validJson": "Doit être un valide JSON",
		"sendMessageToScreen": "Envoyer le message {type} à {screens} l'écran | Envoyer le message {type} à {screens} l'écrans",
		"sendMessage": "Envoyer message",
		"updateStatus": "Mettre à jour l'état",
		"pushConfig": "Configuration push",
		"pushContent": "Contenu push",
		"upgradeAgentSoftware": "Mettre à jour le logiciel de l’agent",
		"upgradeAgentSoftwareNotification": "Ceci enverra un message à l’agent pour qu’il mette à jour son logiciel. Dans le cadre de la mise à jour, l’agent peut être amené à redémarrer, ce qui entraînera une brève interruption du service de l’agent. Veuillez noter que si aucune mise à jour n’est disponible, aucune action ne sera effectuée.",
		"poweredOn": "Allumé",
		"poweredOff": "Éteint",
		"powerOn": "Allumer",
		"powerOff": "Éteint",
		"reboot": "Redémarrage",
		"rebootRequired": "L’application de la rotation d’écran nécessite un redémarrage.<br><br>Souhaitez-vous procéder à un redémarrage maintenant ?",
		"jvmUptime": "Temps de fonctionnement de la JVM",
		"rebootRequested": "Redémarrage requis",
		"appUpdateRequested": "Mise à jour de l'application requis",
		"setVolume": "Régler le volume",
		"displayMessage": "Afficher message",
		"displayAddress": "Afficher l'adresse",
		"displayName": "Afficher le nom",
		"resetSoftAP": "Réinitialiser le mot de passe SoftAP",
		"displayPage": "Afficher la page",
		"tuneChannel": "Accorder le canal",
		"enableDebug": "Activer le débogage",
		"reAuthenticate": "Ré-authentifier",
		"reAuthenticateMsg": "Vous êtes sur le point de ré-authentifier l’écran {screen}. Veuillez confirmer pour continuer.",
		"getScreenshot": "Obtenir une capture d'écran",
		"sendPushMessage": "Envoyer un message push",
		"openClientPreview": "Ouvrir un aperçu du client",
		"sendMgmntMessage": "Envoyer un message de gestion",
		"moveToLocation": "Déplacer vers l'emplacement",
		"changeSchedule": "Modifier la planification",
		"selectSchedule": "Sélectionner une planification",
		"manage": "Gérer",
		"moveScreens": "Déplacer l'écran {screens} | Déplacer les écrans {screens}",
		"deleteScreens": "Supprimer l'écran {screens}? | Supprimer les écrans {screens}?",
		"setContentScheduleForScreenExistingNameMsg": "Il existe déjà une planification intitulée {screenName}, une nouvelle planification intitulée {uniqueName} sera créée.",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "Conflit de noms dans les plans de planification suivants :<br>",
		"setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "<span style='font-weight: bold;'>{schedulePlanName}</span> - Une nouvelle planification intitulée <span style='font-style: italic;'>{uniqueName}</span> sera créée.<br>",
		"move": "Déplacer",
		"name": "Nom",
		"ip": "IP",
		"mac": "MAC",
		"rotation": "Rotation",
		"deviceType": "Type d'appareil",
		"appVersion": "Version de l'application",
		"firmwareVersion": "Version du logiciel",
		"osVersion": "Version du SE",
		"details": "Détails",
		"occupied": "Occupé",
		"online": "En ligne",
		"offline": "Hors ligne",
		"power": "Allumer",
		"state": "État",
		"public": "Public",
		"clientId": "ID du client",
		"setRotation": "Régler la rotation de l’écran | Régler la rotation des {count} écrans",
		"id": "ID du client",
		"locationTitle": "Emplacement",
		"previewOnlyOne": "Veuillez sélectionner un seul écran pour l'aperçu",
		"screensMoved": "{screens} écran déplacé vers un autre emplacement | {screens} écrans déplacés vers un autre emplacement",
		"installCode": "Code d'installation | Codes d'installation",
		"createInstallCode": "Créer un code d'installation",
		"code": "Code",
		"createSchedule": "Créer un planning",
		"created": "Créé",
		"createdBy": "Créé par",
		"expiryDate": "Date d'expiration",
		"configInUse": "Erreur : La configuration est en cours d’utilisation",
		"configInUseDescription": "{name} est utilisée par les planifications suivantes :",
		"scheduleInUse": "Erreur : La planification est en cours d’utilisation",
		"scheduleInUseDescription": "{name} est utilisé par les mappages de planification suivants :",
		"configTvSettingsHeading": "Paramètres TV",
		"configChannelsHeading": "Canaux",
		"configMenusHeading": "Menus",
		"addConfiguration": "Ajouter une configuration",
		"groupBy": "Grouper par",
		"setSchedule": "Définir planning...",
		"upgradeApp": "Mettre à jour l’application",
		"helpText": "Entrez le message à afficher avant  d’éteint.<br>Si vous ne définissez aucun message, vous éteindrez instantanément le(s) écran(s).",
		"newTvScreen": "Nouvel écran de télévision",
		"clientIdRequired": "ID client requis",
		"pickLocation": "Choisissez un emplacement",
		"noLocation": "Il n'y a pas d'emplacement disponible",
		"roomRequired": "Salle requise",
		"roomSearchPlaceholder": "Commencez à taper pour rechercher",
		"tvPreRegisteredOn": "TV pré-enregistrée sur",
		"tvRegisteredOn": "TV enregistrée sur",
		"tvRegisteredCloseText": "Veuillez fermer cet onglet.",
		"pickContext": "Choisissez un contexte",
		"noContext": "Il n'y a pas de contextes disponibles",
		"contextRequired": "Contexte requis",
		"clientToken": "Jeton client",
		"clientTokenRequired": "Jeton client requis",
		"couldNotFindItemToEdit": "Impossible de trouver l'élément à modifier",
		"uptime": "Temps de fonctionnement",
		"memoryUsage": "Utilisation de la mémoire",
		"requestScreenshot": "Demande de capture d'écran, veuillez patienter...",
		"requestScreenshotOffline": "Impossible de demander une capture d'écran à partir d'un appareil hors ligne...",
		"requestScreenshotPoweredOff": "Impossible de demander une capture d'écran à partir d'un appareil éteint...",
		"requestNewScreenshot": "Cliquez pour demander une nouvelle capture d'écran...",
		"requestUpdate": "Demande de mise à jour",
		"serial": "Série",
		"firmware": "Firmware",
		"resolution": "Résolution",
		"system": "Système",
		"publicIp": "IP publique",
		"softAP": "Soft AP",
		"currentConfig": "Configuration actuelle",
		"rcAddr": "Adresse RC",
		"network": "Réseau",
		"timeOnDevice": "Temps sur l'appareil",
		"openPreview": "Ouvrir l'aperçu",
		"screenInfo": "Informations sur l'écran",
		"reloadAllMediaTitle": "Recharger tous les médias",
		"reloadAllMediaDescription": "Supprimer tous les médias téléchargés sur l’appareil {amount} et les retélécharger ? Supprimer tous les médias téléchargés sur les appareils {amount} et les retélécharger ?",
		"moveToScheduleTitle": "Définir le planning de mappage",
		"changeScreenScheduleDescription": "Modifier la planification de l’écran en {mapping} ?",
		"changeScreenScheduleSuccess": "La planification de l’écran a été modifiée en {mapping}",
		"moveToScheduleDescription": "déplacer {amount} écrans  vers planning {mapping}?",
		"moveToScheduleSuccess": "Nouveau planning défini pour {amount} écrans",
		"moveToSite": "Déplacer l'écran vers le site",
		"moveScreenToSiteMsg": "Attention! Vous êtes sur le point de déplacer cet écran vers un autre site, veuillez confirmer pour continuer.",
		"moveScreenToSiteSuccess": "Écran déplacé vers un nouveau site",
		"moveScreenToSiteNotAllowed": "Pas autorisé à déplacer l'écran vers ce site",
		"moveScreenToSiteNotAllowedNoLicense": "Il n’est pas permis de déplacer l’écran vers ce site : Le site ne dispose pas d’une licence {license} activée.",
		"moveScreenToSiteNotAllowedLimitReached": "Il n’est pas permis de déplacer l’écran vers ce site : La limite de licence pour ce type d’écran a été atteinte.",
		"createScreenLimitReachedMsg": "Impossible de créer un nouvel écran. La limite de licence pour ce type d’écran a été atteinte",
		"successfullyAuthenticated": "Écran ré-authentifié avec succès",
		"canNotReAuthenticate": "Impossible de ré-authentifier un écran déjà authentifié",
		"appMode": "Mode d'application",
		"forceUpdate": "Forcer la mise à jour",
		"services": "Services",
		"upgradablePackages": "Paquets pouvant être mis à jour",
		"authCodeNotFound": "Le code d'authentification n'est pas valide ou a expiré. Veuillez vérifier l'écran pour un code valide.",
		"lastReboot": "Dernier redémarrage",
		"storage": "Stockage",
		"authCreateNewSchedule": "Cette option crée une nouvelle planification portant le même nom que l’écran créé et place l’écran dans cette planification.<br>En décochant cette case, vous pouvez choisir d’ajouter l’écran à une planification déjà existante.",
		"authUseExistingSchedule": "Sélectionner une planification déjà existante pour y ajouter votre nouvel écran :",
		"registrationCode": "Code d’enregistrement de l’écran",
		"registrationCodeLength": "6 chiffres"
	},
	"booking": {
		"booking": "Réservation | Réservations",
		"numberOfBookings": "{number} réservation | {number} réservations",
		"book": "Réserver",
		"bookNights": "Réserver un séjour de {nights} nuits",
		"night": "Nuit | Nuits",
		"stayFromTo": "de {from} à {to}",
		"stayCheckedIn": " - enregistré",
		"calendar": "Calendrier",
		"availabilityError": "Échec de la récupération des données de disponibilité de la réservation",
		"bookingsForDate": "Réservations pour {date}",
		"noBookingsForDate": "Aucune réservation pour {date}",
		"failedToRetrieve": "Échec de la récupération des réservations",
		"timeslotTaken": "Ce créneau horaire est occupé, veuillez en essayer un autre"
	},
	"guests": {
		"anonymous": "Anonyme",
		"language": "Langue",
		"unspecified": "Non spécifié",
		"pickRoom": "Choisissez une chambre disponible",
		"checkInDate": "Date d'enregistrement",
		"checkInTime": "Heure d'enregistrement",
		"checkOutDate": "Date de départ",
		"checkOutTime": "Heure de départ",
		"newCheckIn": "Nouvel enregistrement",
		"checkIn": "Enregistrement",
		"checkInGuest": "Enregistrer l'invité",
		"editGuest": "Modifier l'invité",
		"checkOut": "Départ",
		"getBill": "Obtenir la facture",
		"guestCheckOut": "Départ de l'invité",
		"billItemName": "Nom de l'article",
		"billItemAmountMax4Chars": "amnt",
		"billItemCurrencyMax3Chars": "cur",
		"billDataError": "Impossible de récupérer les informations sur la facture ({status})",
		"confirmCheckout": "Confirmer départ",
		"editStay": "Modifier le séjour",
		"editBooking": "Modifier la réservation",
		"newBooking": "Nouvelle réservation",
		"checkInBeforeOut": "Le départ doit venir après l'enregistrement.",
		"noGuests": "Aucun invité",
		"overrideTvConfig": "Remplacer la configuration TV",
		"setTvConfigConfirm": "Remplacer le mappage de la configuration TV « {configName} »  pour le(s) client(s) {guestCnt} sélectionné(s)?<br>Cette configuration sera rétablie au moment du départ.",
		"clearTvConfigConfirm": "Effacer le mappage de la configuration TV remplacée pour le(s) {guestCnt} invité(s) sélectionné(s) ?<br>Ceci appliquera le mappage de la configuration définie par le système.",
		"setTvConfigSuccess": "{amount} télévision ont été reconfigurée|{amount} télévisions ont été reconfigurées",
		"sendMessage": "Envoyer le message",
		"guestsNumber": "{number} d'invité | {number} d'invités",
		"person": "Personne | Personnes",
		"forceCheckOutTitle": "Forcer le départ",
		"forceCheckOutMessage": "Cet invité a été enregistré automatiquement par un système externe. Le système externe est responsable du contrôle de départ de l'invité. Le fait de forcer le départ de cet invité n'aura pas pour effet de l'extraire du système externe et pourrait causer des problèmes. Procédez?"
	},
	"guestMessages": {
		"to": "Vers",
		"unreadMessages": "Messages non lus",
		"subject": "Objet",
		"create": "Nouveau message",
		"view": "Voir le message",
		"createdHeader": "Envoyé",
		"readHeader": "Lire",
		"displayedHeader": "Notifié",
		"senderName": "De",
		"instant": "Affichage instantané",
		"messageTemplate": "Modèle de message | Modèles de message",
		"useTemplate": "Utiliser le modèle"
	},
	"wakeups": {
		"roomAccount": "Chambre (account)",
		"tvMenu": "Menu TV à afficher",
		"customTvMenu": "Utiliser le menu TV personnalisé pour l’affichage",
		"wakeupInFuture": "Le réveil doit être dans le futur",
		"wakeupExists": "Le réveil existe déjà",
		"create": "Créer un réveil",
		"daily": "Quotidiennement",
		"status": "Statut",
		"delivered": "Livré",
		"notDelivered": "Non livré",
		"displayed": "Affiché",
		"notDisplayed": "Non affiché",
		"confirmed": "Confirmé",
		"notConfirmed": "Non confirmé",
		"editWakeup": "Modifier le réveil",
		"deliveredWakeup": "Réveil livré",
		"newWakeup": "Nouveau réveil"
	},
	"scheduledTasks": {
		"dailyNewSheets": "Nouveaux draps quotidiens",
		"roomType": "Type de chambre | Types de chambres",
		"allTypes": "Tous les types",
		"roomTypeEmpty": "Laissez vide pour que cette tâche s'applique à toutes les chambres",
		"when": "Quand",
		"createTask": "Créer tâche",
		"specificRooms": "Chambres spécifiques"
	},
	"issues": {
		"maintenanceType": "Type d'entretien | Types d'entretien",
		"changeStatus": "Modifier le statut",
		"setIssueTo": "Définir le problème à...",
		"editIssue": "Modifier le problème",
		"created": "Créé",
		"closed": "Fermé",
		"issueSetTo": "Problème sur {room} réglé sur {status}",
		"issuesSetTo": "{issues} problèmes réglés sur {status}",
		"newIssue": "Nouvelle problème | Nouvelle problèmes",
		"editingIssues": "Modification {issues} problèmes",
		"issueUpdated": "{type} problème sur {room} mise à jour",
		"issueAdded": "{type} problème ajouter à {room}",
		"urgent": "Urgent",
		"flagAsClosed": "Marquer comme fermé",
		"issueSetup": "Configuration des problèmes"
	},
	"locations": {
		"location": "Emplacement | Emplacements",
		"createRooms": "Créer chambres",
		"createLocations": "Créer emplacements",
		"occupation": "Occupation",
		"locatedIn": "Situé à",
		"locationSetTo": "{location} réglé sur {status}",
		"locationsSetTo": "{locations} chambres réglées sur {status}",
		"locationSetup": "Configuration de l'emplacement",
		"category": "Catégorie | Catégories",
		"setRoomTo": "Régler {room} sur...",
		"editRoom": "Modifier la chambre",
		"changeStatus": "Modifier statut",
		"changeCategory": "Modifier de catégorie",
		"changeLocation": "Modifier l'emplacement",
		"addTag": "Ajouter une étiquette",
		"removeTag": "Retirer une étiquette",
		"addIssue": "Ajouter un problème",
		"tagRemoved": "Étiquette {tag} retirée des {rooms} chambres",
		"tagAdded": "Étiquette {tag} ajouté aux {rooms} chambres",
		"categoryChange": "{room} catégorie modifier à {category} | {rooms} catégories modifier à {category}",
		"newRooms": "Nouvelles chambres",
		"newLocations": "Nouveaux emplacements",
		"roomNumber": "Numéro de chambre",
		"locationNumber": "Numéro d'emplacements",
		"startRange": "Intervalle de début",
		"endRange": "Intervalle de fin",
		"parentLocations": "Emplacements parents",
		"startRangeRequired": "Intervalle de début est requis",
		"startRangeLower": "Intervalle de début doit être inférieure à l'intervalle de fin",
		"endRangeRequired": "L'intervalle de fin est requis",
		"endRangeHigher": "L'intervalle de fin doit être supérieure à l'intervalle de début",
		"change": "Modifier",
		"newLocation": "Nouvel emplacement",
		"changeLocationOf": "Modifier l'emplacement de {room} chambre | Modifier l'emplacement de {rooms} chambres",
		"locationChangeSuccess": "Location change successful",
		"roomTag": "Étiquette de chambre | Étiquettes de chambre",
		"newTag": "Nouvelle étiquette",
		"newStatus": "Nouveau statut",
		"issueDeletedFrom": "Problème supprimé de {room}",
		"locationCategory": "Catégorie d'emplacement | Catégories d'emplacement",
		"roomCategory": "Catégorie de chambre | Catégories de chambre",
		"newCategory": "Nouvelle catégorie",
		"bedroomTv": "TV de chambre",
		"meetingRoomTV": "Télévision de la salle de réunion"
	},
	"serviceConfig": {
		"saveConfig": "Sauvegarder la configuration",
		"disableDailyRebootWarning": "Avertissement : Cette fonction empêchera certains appareils de recevoir des mises à jour logicielles automatiques.",
		"forceTvMode": "Cette fonction permet d’afficher les canaux de télévision lorsque la télévision est allumée, au lieu du menu principal configuré.",
		"rebootTime": "Heure de redémarrage",
		"rebootTimeInvalid": "Heure de redémarrage non valide",
		"instantContentPush": "Push de contenu instantané",
		"instantContentPushDescription": "Activer cette option permet d’effectuer un push immédiat de toutes les modifications de contenu sur les appareils concernés, sans qu’aucune invite ne soit émise.",
		"useInternalURL": "Cette option fera en sorte que la télévision utilise l’URL interne de la télévision"
	},
	"login": {
		"terms": "Conditions",
		"resetPassword": "Réinitialiser le mot de passe",
		"language": "Langue",
		"username": "Nom d'utilisateur",
		"password": "Mot de passe",
		"logIn": "Se connecter",
		"cloudManagementPortal": "Portail de gestion",
		"enterUsername": "Entrez votre nom d'utilisateur et votre mot de passe",
		"enterEmail": "Entrez votre adresse e-mail",
		"invalidEmail": "Adresse e-mail non valide",
		"resetMessage": "Des instructions supplémentaires ont été envoyées à {email}",
		"resetFailedMessage": "La demande de réinitialisation du mot de passe a échoué: {message}",
		"setNewPassword": "Entrez un nouveau mot de passe",
		"redirectToLoginPageTitle": "Redirection vers la page de connexion",
		"redirectToLoginPageText": "Vous allez maintenant être redirigé vers la page de connexion."
	},
	"latLng": {
		"openMap": "Ouvrez",
		"lat": "Latitude",
		"lng": "Longitude",
		"selectPosition": "Sélectionnez un emplacement",
		"addressHint": "Vous pouvez taper manuellement l'adresse",
		"addressWarning": "Assurez-vous que la latitude et la longitude correspondent à l'adresse",
		"searchFieldLabel": "Rechercher une adresse",
		"address": "Adresse",
		"locationAddressErrorMessage": "Contactez votre administrateur de licence pour ajouter des informations d'adresse",
		"maxLength": "Longueur maximale"
	},
	"folder": {
		"newResources": "Nouvelles ressources",
		"uploadFile": "Télécharger un fichier",
		"folder": "Dossier | Dossiers",
		"noFolders": "Il n'y a pas encore de dossiers",
		"doubleClickSelect": "Double-cliquez pour sélectionner",
		"newFolder": "Nouveau dossier",
		"copy": "Copier",
		"cut": "Couper",
		"paste": "Coller",
		"selected": "{amount} de {type} sélectionné | {amount} de {type}s sélectionnés",
		"deleting": "Suppression de {amount} élément | Suppression de {amount} éléments",
		"root": "Racine",
		"home": "Accueil",
		"emptyFolder": "Ce dossier est vide",
		"createFolder": "Créer dossier",
		"deleteFolder": "Supprimer dossier",
		"moveToFolder": "Déplacer vers dossier",
		"copyToFolder": "Copier vers le dossier",
		"editFolder": "Modifier le dossier",
		"imageSize": "Taille de l'image",
		"unknownImageSize": "Taille d'image inconnue"
	},
	"auth": {
		"loginNotAvailable": "Service non disponible - veuillez réessayer plus tard",
		"wrongUserPass": "Nom d'utilisateur et/ou mot de passe erronés",
		"responseError": "Erreur dans la réponse du serveur",
		"noCredentials": "Les informations d'identification ne sont pas fournies - impossible de se connecter",
		"errorMirageSession": "Vous vous êtes connecté avec succès, mais il y a eu un problème de chargement du portail.<br><br>Essayez de recharger ou contactez votre gestionnaire/support.",
		"errorMirageSessionPPDS": "Vous vous êtes connecté avec succès, mais votre compte utilisateur n’a pas accès aux clients de PPDS Wave.<br><br>Essayez de recharger ou contactez votre gestionnaire/support.",
		"invalidMirageSession": "Vous vous êtes connecté avec succès, mais l’accès au portail Cloud a été désactivé.<br><br>Essayez un autre fournisseur d’accès ou contactez votre gestionnaire/support.",
		"invalidMirageSessionPPDS": "Vous vous êtes connecté avec succès, mais l’accès à PPDS Wave Creator a été désactivé.<br><br>Essayez un autre fournisseur d’accès ou contactez votre gestionnaire/support.",
		"unknownMirageSession": "Vous vous êtes connecté avec succès, mais une erreur inconnue s’est produite.<br><br>Essayez un autre fournisseur d’accès ou contactez votre gestionnaire/support."
	},
	"accessPanel": {
		"resellerTitle": "Gestion de l'accès des utilisateurs revendeurs",
		"resellerDescription": "Configurez l'accès des utilisateurs et de l'API pour:<br>a) gérer le revendeur pour provisionner les sites et les licences(<span style='font-weight: bold;'>Account Access</span>). Cela n'accorde PAS l'accès aux sites.<br>b) accéder aux sites de ce revendeur(<span style='font-weight: bold;'>Site Access</span>). Cela accorde le niveau d'accès donné à TOUS les sites qui appartiennent à ce revendeur.",
		"distributorTitle": "Gestion de l'accès des utilisateurs du distributeur",
		"distributorDescription": "Configurez l'accès des utilisateurs et de l'API pour:<br>a) gérer le distributeur pour approvisionner les revendeurs(<span style='font-weight: bold;'>Account Access</span>). n'accorde PAS d'accès aux revendeurs.<br>b) accéder aux revendeurs de ce distributeur(<span style='font-weight: bold;'>Reseller Access</span>). Ceci accorde le niveau d'accès donné à TOUS les revendeurs qui appartiennent à ce distributeur.",
		"domainTitle": "Gestion de l’accès des utilisateurs du domaine",
		"domainDescription": "Configurer l’accès des utilisateurs et de l’API pour:<br>a) gérer le domaine pour approvisionner les distributeurs(<span style='font-weight: bold;'>Account Access</span> [accès au compte]). Cela n’accorde PAS d’accès aux distributeurs.<br>b) accéder aux distributeurs de ce domaine<span style='font-weight: bold;'>Distributor Access</span> [accès aux distributeurs]). Cela permet d’accorder le niveau d’accès donné à TOUS les distributeurs appartenant à ce domaine.",
		"resellerAccess": "Accès revendeur",
		"sharingContextsAccess": "Partage de l’accès aux contextes",
		"deploymentAccess": "Accès au site",
		"themeAccess": "Accès au thème",
		"distributorAccess": "Accès aux distributeurs",
		"domainAccess": "Accès au domaine",
		"accessControl": "Contrôle d'accès",
		"accessControlDescription": "Sélectionnez le revendeur à modifier",
		"accessError": "Vous n'avez pas accès à l'administration des utilisateurs pour aucun {contextType}s"
	},
	"theme": {
		"theme": "Thème | Thèmes",
		"importWindowHeader": "Définir le dossier de destination",
		"editThemeImage": "Modifier l'image du thème",
		"imagePlaceholder": "Aucune image définie",
		"editThemeCard": "Modifier la carte du thème",
		"publishSnapshot": "Publier le thème",
		"publishDescription": "La publication d'un thème écrase toute version antérieure publiée. Elle n'a aucun effet sur les sites qui ont importé les versions précédentes.",
		"publishSharingDescription": "Le partage de la version publiée est configuré sous Home (accueil) -> Reseller (revendeur) -> Themes (thèmes). Vous devez disposer d’un accès revendeur pour le partage.",
		"lastPublished": "Dernière publication",
		"ThemeNotPublished": "Le thème n’est actuellement pas publié",
		"resourceStats": "Statistiques des ressources",
		"editorInfo": "Notez que vous devez vérifier les droits d'auteur des images et la disponibilité générale de l'ensemble des éléments avant de cliquer sur \"Publier thème\", permettant à tous les comptes utilisateurs désignés d'importer le thème",
		"noFeaturesActivated": "Aucune fonctionnalité activée...",
		"importInfo": "Importer des thèmes de menus interactifs complets. Aucun des contenus existants ne sera modifié",
		"importInfoTheme": "Importer d'autres thèmes dans ce thème.",
		"import": "Importer",
		"folderExists": "Le dossier existe déjà",
		"importLoader": "Importation du thème",
		"importSuccess": "Votre thème a été importé avec succès et se trouve sous \"Contenu\"",
		"importSuccessAsync": "La tâche d'importation a démarré. Veuillez patienter quelques minutes avant de travailler avec le thème importé.",
		"importError": "Supprimez les dossiers portant le nom: {folder} sous \"Contenu\" et réessayez d'importer",
		"previewConfiguration": "Configuration de l'aperçu du thème",
		"savePreviewError": "Impossible d'enregistrer la configuration!",
		"launchPreview": "Lancer l'aperçu",
		"noConfigurationFound": "ERREUR: Aucun configuration trouvé...",
		"themePublished": "Thème publié",
		"published": "publié",
		"themePublishedError": "Impossible de publier le thème",
		"themeSharingSuccess": "Le partage du thème a été mis à jour",
		"themeSharingError": "Impossible de mettre à jour le partage",
		"themeEmptyError": "Impossible de définir le partage avant de publier le thème",
		"fetchError": "Impossible de récupérer les données de partage actuelles",
		"editTitle": "Editer la carte pour définir un titre.",
		"editDescription": "Modifier la carte pour définir une déscription.",
		"shareWith": "Partager avec:",
		"shareAll": "Tous",
		"shareSubGroup": "Sous-groupe",
		"shareSingle": "Unique",
		"successfullyImported": "Importation réussie",
		"successfullyImportedAsync": "Tâche d'importation commencée",
		"importFailed": "Importation échoué",
		"newTheme": "Nouveau thème",
		"preview": "Aperçu du thème",
		"previewNotAvailable": "Aperçu non disponible - contactez le support",
		"previewInfo": "Lancez un client TV virtuel dans votre navigateur pour tester le thème.<br>Cette prévisualisation ne sera pas filigranée.",
		"loadThemesError": "Impossible de récupérer les thèmes",
		"themeControl": "Contrôle du thème",
		"themeControlDescription": "Sélectionnez le thème à modifier",
		"enablePublishInfo": "Définissez toutes les informations de publication (titre, description et image du thème) pour activer la publication",
		"enableSharing": "Vous devez définir au moins un partage pour votre thème afin de pouvoir le publier",
		"panel": {
			"title": "Gestion des thèmes pour les revendeurs",
			"description": "Configurer les thème"
		},
		"themeName": "Nom du thèm"
	},
	"sharingContext": {
		"type": "Contexte de partage | Contextes de partage",
		"rootFolderPrefix": "Fourni",
		"new": "Nouveau fournisseur de ressources",
		"deleteConfirmTitle": "Supprimer le fournisseur de ressources",
		"deleteConfirmMsg": "Veuillez confirmer que vous souhaitez supprimer ce fournisseur de ressources",
		"editorInfo": "N’oubliez pas que toute modification apportée aux médias dans le contexte de ce fournisseur de ressources sera potentiellement disponible instantanément pour tous les sites vers lesquels ce fournisseur partage des ressources.<br>Faites aussi TRÈS attention lorsque vous supprimez des éléments, car ils peuvent être utilisés par des sites.",
		"deleteWarning": "AVERTISSEMENT ! Faites TRÈS attention lorsque vous supprimez des éléments, car ils peuvent être utilisés par des sites",
		"deleteConfirm": "Confirmez que vous souhaitez supprimer ce {type} | Confirmez que vous souhaitez supprimer ces  {type}s"
	},
	"signage": {
		"signage": "Affichage",
		"schedule": "Planification|Planifications",
		"addTimeslot": "Ajouter un créneau horaire",
		"contentSchedule": "Planification du contenu|Planifications du contenu",
		"noSchedules": "Aucune planification n’a été configurée. Ajouter un écran à l’aide du code d’installation ou créer une planification.",
		"mergeDefaultTitle": "Fusionner avec le contenu par défaut",
		"mergeDefaultConfirm": "Êtes-vous sûr de vouloir fusionner ce créneau horaire avec le contenu par défaut ?",
		"mergeDefaultTimeslotTitle": "FUSIONNER AVEC LE CONTENU PAR DÉFAUT"
	},
	"pms": {
		"pmsHeader": "Configuration du système de gestion des biens",
		"vendor": "Fournisseur",
		"accessToken": "Jeton d'accès",
		"tigerAccessTokenHint": "Code d’authentification pour TigerTMS API - Aussi appelé \"wsUserKey\" dans les systèmes TigerTMS",
		"siteName": "Nom du site",
		"tigerSiteNameHint": "Identifiant unique pour cet hôtel, généré par TigerTMS",
		"directoryId": "ID du répertoire",
		"soapApiUrl": "Point terminal API SOAP",
		"soapApiUrlHint": "URL pour la communication avec TigerTMS iLink - L’URL par défaut est « https://checkout.tigertms.com/GenericEXT/ » si elle n’est pas renseignée",
		"linkCheckerIn": "Activer la surveillance de l’état pour la communication ENTRANTE vers TigerTMS",
		"linkCheckerOut": "Activer la surveillance de l’état pour la communication SORTANTE vers TigerTMS",
		"enableDailyReset": "Activer la réinitialisation quotidienne",
		"pmsLink": "Lien PMS",
		"lastMsgReceived": "(Dernière réception il y a {time})",
		"hour": "heure",
		"minute": "minute",
		"forceSync": "forcer la synchronisation",
		"forceSyncSuccess": "Demande de synchronisation envoyée avec succès !",
		"forceSyncError": "Échec de la demande de synchronisation !",
		"pmsGetVendorError": "Impossible d'obtenir la liste des fournisseurs de {pms}!",
		"ip": "IP",
		"port": "Port",
		"useChecksum": "Utiliser la somme de contrôle",
		"pmsVendor": "Fournisseur PMS|Fournisseurs PMS",
		"defaultTimeoutSeconds": "Délai d’expiration du message par défaut (secondes)",
		"charSet": "Jeu de caractères"
	},
	"cast": {
		"config": {
			"adminConfigHeader": "Configuration du système de gestion Chromecast",
			"authToken": "Jeton d’authentification",
			"authTokenHint": "Jeton de sécurité pour le système de gestion Chromecast.",
			"networks": {
				"createNew": "Créer nouveau",
				"addNetworkWithType": "Ajouter réseau {type}",
				"addChromecastNetwork": "Ajouter Chrom",
				"useExisting": "Utiliser l’interface existante",
				"interfaceName": "Nom de l’interface",
				"parentInterface": "Interface parent",
				"networkPrefixLength": "Longueur du préfixe réseau",
				"vlanTag": "Balise VLAN",
				"chromecastNetworks": "Réseaux Chromecast",
				"clientNetworks": "Réseaux clients",
				"proxyIp": "IP Proxy",
				"vlanId": "Identifiant VLAN",
				"interface": "Interface",
				"parentIface": "Interface parent",
				"ipAddressHint": "Saisissez l’adresse IP de cette interface. Il s’agit de l’adresse à partir de laquelle les périphériques de ce réseau peuvent accéder à ce serveur.",
				"ipPlaceholder": "192.168.0.10",
				"networkPrefixPlaceholder": "24",
				"vlanTagPlaceholder": "10"
			},
			"hostPlaceholder": "0.0.0.0",
			"networkProxyHost": "Hôte du proxy réseau",
			"networkProxyHostHint": "L’adresse de l’hôte pour établir la connexion entre le portail et le système de gestion Chromecast.",
			"proxy": "Proxy",
			"chromecastProxy": "Configuration du proxy Chromecast",
			"chromecastNetworkType": "Réseau Chromecast",
			"chromecastProxyServerNetworkConfig": "Configuration du réseau du serveur proxy Chromecast",
			"chromecastProxyServerStatus": "État du serveur proxy Chromecast",
			"networkInterfaceStatus": "Statut de l’interface réseau",
			"chromecastProxyServerSignageStatus": "État de l’affichage du serveur proxy Chromecast",
			"proxyIsInternetGateway": "Le proxy est une passerelle Internet",
			"internetInterface": "Interface Internet",
			"isGatewayForChromecasts": "Est une passerelle pour les Chromecasts",
			"isDHCPForChromecasts": "Est de type DHCP pour les Chromecasts",
			"isGatewayForClients": "Est une passerelle pour les clients",
			"isDHCPForClients": "Est de type DHCP pour les clients",
			"chromecastProxyServerForcedReturnPath": "Chemin de retour forcé du serveur proxy Chromecast",
			"webInterfaceForceReturnPath": "Chemin de retour forcé de l’interface Web",
			"webInterfaceForceReturnPathHint": "Laisser vide sauf s’il y a plusieurs réseaux clients. Cette option est utilisée pour indiquer l’interface à laquelle le serveur doit renvoyer la réponse pour toute demande émanant des clients.",
			"forcedReturnPathGateway": "Passerelle de retour forcée",
			"forcedReturnPathGatewayPlaceholder": "Passerelle par défaut",
			"forcedReturnPathGatewayHint": "Passerelle connectée au réseau de retour forcé. Laissez vide pour utiliser la passerelle par défaut du serveur Chromecast",
			"advancedNetworking": "Mise en réseau avancée",
			"proxyServerConfig": "Configuration du serveur proxy",
			"chromecastConfig": "Configuration du Chromecast",
			"pairingCodeConfig": "Configuration du code d’appariement",
			"clientRegistrationHost": "Hôte d’enregistrement du client",
			"clientRegistrationHostHint": "Saisir le domaine ou l’adresse IP qui pointe vers ce serveur sur le réseau du client. Ne pas ajouter http:// ni aucun schéma d’URL.",
			"clientRegistrationHostPlaceholder": "cast.example.com",
			"guestWifiName": "Nom du WiFi de l’invité",
			"guestWifiNameHint": "Nom du WiFi à afficher à l’écran lors de l’utilisation du mode autonome. Voir la documentation sur la personnalisation pour plus d’informations.",
			"guestWiFiPassword": "Mot de passe WiFi de l’invité",
			"guestWiFiPasswordHint": "Mot de passe WiFi à afficher à l’écran lors de l’utilisation du mode autonome. Voir la documentation sur la personnalisation pour plus d’informations.",
			"enableSignageIntegration": "Activer l’intégration de l’affichage",
			"globalChromecastName": "Nom global du Chromecast",
			"globalChromecastNameHint": "Nom du Chromecast qui doit être affiché pour tous les invités.",
			"globalChromecastNamePlaceholder": "Chromecast dans la salle",
			"timeZone": "Fuseau horaire",
			"timeZoneHint": "Il s’agit du fuseau horaire pour tous les Chromecasts connectés.",
			"locale": "Paramètre régional",
			"localeHint": "Il s’agit de la langue d’affichage de tous les Chromecasts connectés.",
			"pairingCodeSet": "Jeu de codes d’appariement",
			"pairingCodeSetHint": "Jeu de caractères pour générer le code d’appariement. Les caractères saisis ici seront les seuls à être utilisés pour générer le code.",
			"pairingCodeIsCaseSensitive": "Le code d’appariement est sensible à la casse",
			"pairingCodeLength": "Longueur du code d’appariement",
			"pairingCodeTimeoutMinutes": "Durée de validité des codes d’appairage (en minutes)",
			"pairingCodeTimeoutMinutesHint": "Durée pendant laquelle chaque code doit être valide avant l’affichage d’un nouveau code. Lorsqu’il est utilisé, un nouveau code est affiché dans tous les cas. (Valeur par défaut : 10 minutes)",
			"pairTimeoutHours": "Durée de validité de l’appariement (heures)",
			"pairTimeoutHoursHint": "Durée pendant laquelle un invité apparié doit rester apparié avant d’être automatiquement déconnecté. (Valeur par défaut : 5 heures). Pour utiliser une heure fixe par jour, utilisez la case à cocher ci-dessous et configurez chaque jour séparément.",
			"useCustomTimeoutTimePerDayInstead": "Utiliser plutôt un délai d’expiration personnalisé par jour",
			"networkProxySaveError": "Erreur lors de l’enregistrement de la configuration du proxy réseau",
			"saveError": "Erreur de sauvegarde de la configuration",
			"saveSuccess": "Configuration sauvegardée",
			"connectionSuccess": "Connexion établie",
			"connectionFailure": "Impossible d’établir la connexion",
			"interfaceFetchFailure": "Impossible de récupérer les interfaces",
			"reloadTooltipDefault": "Récupère les données sauvegardées",
			"resetTooltip": "Réinitialise la configuration actuelle en récupérant une configuration sauvegardée.",
			"routingOfClientChromecastSessions": "Prise en charge du routage des sessions Chromecast des clients",
			"routingOfClientNetworkMulticast": "Prise en charge du routage de la multidiffusion du réseau client",
			"supportNetflix": "Prise en charge de Netflix sur les casts Gen4 (AndroidTV)",
			"failedToLoadMsg": "Impossible de charger la configuration"
		},
		"eventLogs": {
			"typeDescription": "Journal des événements",
			"time": "Heure",
			"eventName": "Nom de l’événement",
			"castUdn": "UDN du cast",
			"castName": "Nom du cast",
			"clientVid": "VID client",
			"additionalData": "Données supplémentaires"
		},
		"response": {
			"chromecastFetchFailed": "Impossible de récupérer les Chromecasts.",
			"unregisteredCastFetchFailed": "Impossible de récupérer les Chromecasts non enregistrés",
			"activeSessionFetchFailed": "Impossible de récupérer les sessions actives",
			"statusFetchFailed": "Impossible de récupérer l’état des Chromecasts",
			"statisticsFetchFailed": "Impossible de récupérer les statistiques",
			"eventLogFetchFailed": "Impossible de récupérer le journal des événements",
			"chromecastProxyConfigFetchFailed": "Impossible de récupérer la configuration du proxy Chromecast",
			"networksFetchFailed": "Impossible de récupérer les réseaux",
			"advancedNetworkingFetchFailed": "Impossible de récupérer la configuration réseau avancée"
		},
		"status": {
			"reassociationBtnText": "Réassociation à DS",
			"reassociationTitle": "Réassocier à l’affichage ?",
			"reassociationWarning": "L’association actuelle avec Digital Signage (affichage numérique) sera perdue. Cette option ne doit être utilisée que dans les cas où Cast ne parvient pas à s’enregistrer dans Digital Signage ou lorsque l’écran précédemment associé à Digital Signage a été supprimé. \n Créer une nouvelle association ?",
			"reassociationMsg": "Créer une nouvelle association ?",
			"reassociationSuccess": "Réassocié à DS",
			"reassociationFailed": "Échec de la réassociation à DS",
			"version": "Version",
			"serverId": "ID du serveur",
			"licensedFor": "Licencié pour",
			"status": "Statut",
			"eno1": "eno1",
			"wip58s0": "wip58s0",
			"clientId": "ID du client",
			"clientToken": "Jeton client",
			"installerToken": "Jeton d’installation",
			"installerCode": "Code de l’installateur",
			"connected": "Connecté au serveur Push",
			"authenticated": "Authentifié"
		},
		"chromecasts": {
			"info": {
				"castConfig": "Configuration du Chromecast",
				"deviceAlias": "Alias de périphérique",
				"deviceName": "Nom du périphérique",
				"uptime": "Temps de fonctionnement",
				"isConfigurable": "Est configurable",
				"wifiMac": "WiFi MAC",
				"ssid": "SSID"
			},
			"refreshStatusBtn": "Actualiser le statut",
			"refreshStatusSuccess": "Les données du Chromecast sont en cours d’actualisation.\n Cela peut prendre une minute en fonction du nombre de Chromecasts dont vous disposez, appuyez sur « Recharger » pour voir les données mises à jour",
			"refreshStatusFailed": "Échec de l’actualisation du statut.",
			"saveSuccess": "Mise à jour du Chromecast réussie",
			"saveFailed": "Échec de la mise à jour du Chromecast",
			"unregister": "Annuler l’enregistrement",
			"showConfig": "Afficher la configuration",
			"unregisterConfirmTitle": "Annuler l’enregistrement du Chromecast ?",
			"unregisterConfirmMsg": "Êtes-vous sûr de vouloir annuler l’enregistrement du {cast}",
			"unregisterSuccess": "{cast} non enregistré",
			"unregisterFailed": "Échec de l’annulation de l’enregistrement du {cast}.",
			"updateStatusSuccess": "Statut d’{number} Chromecast mis à jour | Statut des {number}Chromecasts mis à jour.",
			"updateStatusFailed": "Échec de la mise à jour du statut.",
			"showNameSuccess": "Affichage du nom d’{number} Chromecast | Affichage des noms de {number} Chromecasts.",
			"showNameFailed": "Échec de l’affichage du nom.",
			"rebootSuccess": "{number} Chromecast redémarré | {number} Chromecasts redémarrés.",
			"rebootFailed": "Échec du redémarrage des Chromecasts.",
			"deletePairingsConfirmTitle": "Supprimer les appariements",
			"deletePairingsConfirmMsg": "Supprimer les appariements pour la salle ?",
			"deletePairingsSuccess": "Les appariements pour la salle {number} ont été supprimés | Les appariements pour les salles {number}.",
			"deletePairingsFailed": "Échec de la suppression des appariements pour la salle {room}.",
			"forceStartCastingFailed": "Échec du démarrage forcé du castage pour le cast {cast}.",
			"chromecastPairingCode": "Code d’appariement Chromecast"
		},
		"unregisteredCasts": {
			"title": "Cast non enregistré",
			"register": "Enregistrer",
			"registerChromecast": "Enregistrer le Chromecast",
			"registerSuccess": "Chromecast {cast} enregistré dans la salle {room}",
			"genericFailure": "Impossible d’enregistrer le Chromecast",
			"limitReachedFailure": "Limite d’enregistrement de Chromecasts atteinte",
			"deleteCastConfirmTitle": "Supprimer le Chromecast",
			"deleteCastConfirmMsg": "Êtes-vous sûr de vouloir supprimer ce Chromecast ?",
			"deleteCastFailed": "Impossible de supprimer le Chromecast",
			"deleteCastSuccess": "Chromecast supprimé"
		},
		"statistics": {
			"fetch": "Récupérer",
			"usageInHours": "Utilisation du cast en heures",
			"noOfPairingsPerDay": "Nombre d’appariements par jour",
			"appUsageInHours": "Utilisation de l’application en heures",
			"consecutiveHourFilterLabel": "Filtrer les événements",
			"consecutiveHourFilterHint": "Filtre les événements dont le nombre d’heures est supérieur à la valeur définie.",
			"downloadVisiblePeriod": "Télécharger la période visible",
			"exportPairings": "Exporter les appariements",
			"exportAppUsage": "Exporter l’utilisation de l’application"
		},
		"activeSessions": {
			"title": "Session active",
			"pinOrUnpin": "Épingler ou désépingler cet appariement",
			"pinned": "Épinglé",
			"pinSuccess": "Client épinglé",
			"unpinSuccess": "Client désépinglé",
			"multiplePinUnpinSuccess": "{clients} épinglé/non épinglé",
			"pinFailed": "Impossible d’épingler le client",
			"disconnect": "Déconnecter le client",
			"disconnectConfirmTitle": "Déconnecter le client",
			"disconnectConfirmMsg": "Déconnecter le client du Chromecast ?",
			"disconnectSuccess": "Client déconnecté",
			"disconnectFailed": "Impossible de déconnecter le client"
		},
		"type": "Chromecast | Chromecasts",
		"runningApp": "Application en cours d’exécution",
		"available": "Disponible",
		"connection": "Conn.",
		"mac": "MAC",
		"macAddress": "Adresse MAC",
		"paired": "Apparié",
		"udn": "UDN",
		"signalNoiseLevel": "S/N",
		"client": "Cli",
		"configurable": "Configurable",
		"connected": "Connecté",
		"createdAt": "Créé à",
		"actions": {
			"updateStatus": "Mettre à jour le statut",
			"deletePairings": "Supprimer les appariements",
			"showName": "Afficher le nom",
			"stopCurrentSession": "Redémarrer",
			"generatePairingCode": "Générer un code d’appariement",
			"exportSelectedToCsv": "Exporter la sélection au format CSV",
			"forceStartCasting": "Forcer le démarrage"
		},
		"networkProxySaveConfirmationMsg": "Êtes-vous sûr de vouloir sauvegarder ? \n Si la configuration est incorrecte, la fonctionnalité chromecast sera interrompue.",
		"castServiceOfflineMsg": "Le service Chromecast est actuellement indisponible, peut-être en raison de la mise hors ligne du serveur ou d’une erreur de configuration dans les paramètres de configuration Administration/Chromecast."
	},
	"streamManagement": {
		"allocatedStreams": {
			"device": "Appareil | Appareils",
			"allocatedStream": "Flux alloué",
			"itemType": "Appareil",
			"addDevice": "Ajouter un appareil",
			"editDevice": "Modifier l’appareil",
			"deallocateStreamTitle": "Désattribuer le flux ?",
			"deallocateStreamConfirmMsg": "Êtes-vous sûr de vouloir désattribuer le flux ?",
			"editAllocatedStream": "Modifier le flux attribué",
			"mediaUrl": "Url média",
			"streamAllocatorConfig": "Configuration d’allocation de flux",
			"deallocate": "Désattribuer",
			"deleteDeviceMsg": "AVERTISSEMENT : La suppression des appareils met immédiatement fin à la session pour toutes les télévisions attribuées",
			"deleteDeviceConfirm": "Êtes-vous sûr de vouloir supprimer l’appareil ?",
			"chromecastIntegrationDescription": "Sélectionner pour activer l’intégration Chromecast"
		},
		"config": {
			"minTimeoutWarn": "Le délai d’expiration minimum est de 2 minutes"
		},
		"allocationLogEntries": {
			"eventType": {
				"allocationFailure": "Échec de l’attribution du flux"
			},
			"eventReason": {
				"noAvailableDevices": "Aucun appareil disponible"
			},
			"title": "Journal d’allocation de flux",
			"itemType": "Saisie dans le journal d’allocation de flux"
		}
	},
	"jobs": {
		"jobList": {
			"title": "Notifications"
		},
		"startMsgs": {
			"imageUpload": "Démarrage du téléchargement de l’image {title}",
			"fontUpload": "Démarrage du téléchargement de la police {title}",
			"themeImport": "Démarrage de l’importation du thème {title}",
			"logoUpload": "Démarrage du téléchargement du logo {title}"
		},
		"successMsgs": {
			"imageUpload": "Téléchargement réussi de l’image {title}",
			"fontUpload": "Téléchargement réussi de la police {title}",
			"logoUpload": "Téléchargement réussi du logo {title}"
		},
		"failedMsgs": {
			"imageUpload": "Échec du téléchargement de l’image {title}",
			"fontUpload": "Échec du téléchargement de la police {title}",
			"logoUpload": "Échec du téléchargement du logo {title}"
		}
	},
	"content": {
		"config": {
			"resourceserver": "Serveur de ressources",
			"resourcesyncservice": "Service de synchronisation des ressources",
			"networkproxy": "Proxy réseau",
			"resourceServerPort": "Port du serveur de ressources",
			"resourceServerPortHint": "Saisir le port du serveur de ressources",
			"resourceServerFileLocation": "Emplacement du fichier du serveur de ressources",
			"resourceServerFileLocationHint": "Saisir l’emplacement du fichier du serveur de ressources",
			"resourceSyncServiceFileLocation": "Emplacement du fichier du service de synchronisation des ressources",
			"resourceSyncServiceFileLocationHint": "Saisir l’emplacement du fichier où tous les fichiers doivent être stockés pour le service de synchronisation des ressources.",
			"enableChangingURL": "Activer la modification de l’URL",
			"saveConfig": "Sauvegarder la configuration",
			"saveConfigWarn": "Êtes-vous sûr de vouloir sauvegarder ? Si la configuration est mal définie, la fonctionnalité de service sera interrompue.",
			"saveSuccess": "Configuration sauvegardée",
			"configSaveError": "Erreur de sauvegarde de la configuration",
			"rangeError": "Seuls les ports compris entre 1024 et 49151 sont autorisés",
			"checkConfig": "Vérifier la configuration",
			"configurationOK": "Configuration OK",
			"errorWithConfiguration": "Erreur de configuration",
			"missingConfig": "Appuyer sur RELOAD (recharger) pour récupérer la configuration",
			"changeURLWarn": "Avertissement : Si l’URL est mal définie, la fonctionnalité de service sera interrompue."
		}
	}
}
