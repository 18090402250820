<template>
    <div>
        <v-menu
                offset-y
                :close-on-content-click="false"
        >
            <template v-slot:activator="{on}">
                <v-btn icon v-on="on" data-testid="mmuVerticalDots">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>

            <v-list class="the-user-menu">
                <div v-if="!$app.portalThemeActive($app.portalThemes.THEME_PPDS)">
                    <v-subheader>{{$t('userMenu.theme')}}</v-subheader>
                    <v-list-item style="height: 40px;">
                        <v-switch
                            :label="$t('userMenu.dark')"
                            v-model="$app.darkMode"
                            data-testid="mmuDarkTheme"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item style="height: 40px;">
                        <v-switch
                            :label="$t('userMenu.pinMenu')"
                            v-model="$app.pinMenu"
                            data-testid="mmuPinMenu"
                        ></v-switch>
                    </v-list-item>
                    <v-list-item>
                        <v-switch
                            :label="$t('userMenu.timeDisplay')"
                            v-model="$app.amPmDisplay"
                            data-testid="mmuTimeDisplay"
                        >
                        </v-switch>
                    </v-list-item>
                </div>

                <v-list-item
                    :hidden="true"
                >
                    <v-btn
                            text
                            @click="resetHints"
                    >
                        {{$t('userMenu.resetHints')}}
                    </v-btn>
                </v-list-item>
                <BaseSizedBox :height="12"></BaseSizedBox>
<!--                <v-subheader>{{$tc('userMenu.notification', 2)}}</v-subheader>-->
<!--                <BaseSizedBox :height="5"></BaseSizedBox>-->
<!--                <v-list-item style="margin-bottom: 5px !important;">-->
<!--                    <v-btn-toggle mandatory v-model="$app.notificationMode">-->
<!--                        <v-btn text value="all" data-testid="mmuNotificationAll">-->
<!--                            {{$t('userMenu.all')}}-->
<!--                        </v-btn>-->
<!--                        <v-btn text value="success" data-testid="mmuNotificationSuccess">-->
<!--                            {{$t('userMenu.success')}}-->
<!--                        </v-btn>-->
<!--                        <v-btn text value="info" data-testid="mmuNotificationInfo">-->
<!--                            {{$t('userMenu.info')}}-->
<!--                        </v-btn>-->
<!--                    </v-btn-toggle>-->
<!--                </v-list-item>-->
                <BaseSizedBox :height="15"></BaseSizedBox>
                <v-list-item @click="openAccountEditor" data-testid="mmuAccountSettings">
                    <v-list-item-action>
                        <v-icon>account_circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{$t('userMenu.settings')}}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="doLogout" data-testid="mmuLogout">
                    <v-list-item-action>
                        <v-icon>exit_to_app</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{$t('userMenu.logOut')}}</v-list-item-title>
                </v-list-item>
            </v-list>
            <div class="versionText">{{getVersionString()}}</div>
        </v-menu>
        <UserAccountEditor
            v-model="accountEditor.show"
        ></UserAccountEditor>
    </div>
</template>

<script>
import BaseSizedBox from "@/components/base/BaseSizedBox.vue";
import UserAccountEditor from "./UserAccountEditor.vue";

export default {
        name: 'MainMenuUser',
        components: {
            UserAccountEditor,
            BaseSizedBox
        },
        data() {
            return {
                accountEditor: {
                    show: false,
                    record: {}
                }
            }
        },
        computed: {
            username() {
                if (this.$validator.isNotEmpty(this.$store.state.auth.account)) {
                    const contactInfo = this.$store.state.auth.account.contactInformation;

                    if (this.$validator.isNotEmpty(contactInfo) && this.$validator.isNotEmpty(contactInfo.name)) {
                        return contactInfo.name; // User name
                    } else {
                        return this.$store.state.auth.account.name; // Account name
                    }
                } else {
                    return '';
                }
            }
        },
        methods: {
            async doLogout() {
                this.$confirm.show(this.$t('userMenu.logOut') + '?', this.$t('userMenu.confirmLogout'),
                    async () => {
                        sessionStorage.clear();
                        await this.$store.dispatch('auth/logout');
                        await this.$store.dispatch('auth/logoutKeycloak', this.$keycloak);
                    },
                    () => {
                    }
                );
            },
            getVersionString() {
                return process.env.VUE_APP_GIT_HASH;
            },
            openAccountEditor() {
                this.accountEditor = {
                    show: true
                };
            },
            resetHints() {
                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i);

                    if (key.startsWith('help.')) {
                        this.$storage.set(key, null);
                    }
                }

                this.$notification.show('info', this.$t('userMenu.hintsReset'));
            }
        }
    }
</script>

<style lang="scss">
    .the-user-menu {
        padding-top: 15px;

        .v-subheader {
            height: 20px !important;
        }

        .language-menu {
            width: 100%;

            > div > div {
                width: 100%;
            }
        }
    }
    .versionText {
        color: gray;
        background: var(--v-background-base);
        text-align: center;
        font-size: 13px;
        font-style: italic;
    }
</style>
