/* English translations */
export default {
    "generic": {
        "value": "",
        "selectValue": "",
        "options": "",
        "insert": "",
        "moveEntryUp": "",
        "moveEntryDown": "",
        "set": "",
        "onboard": "",
        "view": "",
        "open": "",
        "days": "",
        "minutes": "",
        "log": "",
        "event": "",
        "eventReason": "",
        "occuredAt": "",
        "lastKeepalive": "",
        "allocated": "",
        "active": "",
        "inactive": "",
        "firmware": "",
        "from": "",
        "to": "",
        "ipValidationErrorMsg": "",
        "networks": "",
        "ipAddress": "",
        "host": "",
        "total": "",
        "add": "",
        "all": "",
        "addWithType": "",
        "agent": "",
        "signageScreen": "",
        "agentStatus": "",
        "delete": "",
        "deleteAll": "",
        "deleteWithType": "",
        "deleteConfirm": "",
        "deleteConfirmWithIdentifier": "",
        "deleted": "",
        "deleteFailed": "",
        "deletedWithIdentifier": "",
        "deletedWithTypeAndIdentifier": "",
        "deleteUserConfirmation": "",
        "fetchFailed": "",
        "imported": "",
        "saved": "",
        "updated": "",
        "created": "",
        "selectType": "",
        "manageType": "",
        "itemReverted": "",
        "valueDuplicate": "",
        "remove": "",
        "disable": "",
        "disabled": "",
        "download": "",
        "enable": "",
        "enabled": "",
        "systemDefault": "",
        "removeWithType": "",
        "removeConfirm": "",
        "removeConfirmWithTarget": "",
        "group": "",
        "rename": "",
        "renameWithType": "",
        "renamedTo": "",
        "create": "",
        "createToolTip": "",
        "createAndClose": "",
        "createWithType": "",
        "createNew": "",
        "createNewWithType": "",
        "takeEffectImmediatly": "",
        "newWithType": "",
        "actions": "",
        "yes": "",
        "no": "",
        "none": "",
        "move": "",
        "loading": "",
        "unknown": "",
        "typeToSearch": "",
        "enterSearchTerm": "",
        "noSearchMatches": "",
        "cancel": "",
        "close": "",
        "gotIt": "",
        "required": "",
        "numberRequired": "",
        "fieldRequired": "",
        "inputRequired": "",
        "integration": "",
        "selectionRequired": "",
        "nameRequired": "",
        "inputLongerThan": "",
        "countLessThan": "",
        "inputShorterThan": "",
        "nameInvalidFormat": "",
        "madeYet": "",
        "noMoreItemsAvailable": "",
        "searchCriteria": "",
        "search": "",
        "save": "",
        "saveAndClose": "",
        "saveWithType": "",
        "editItem": "",
        "edit": "",
        "editWithType": "",
        "shareName": "",
        "action": "",
        "refresh": "",
        "reset": "",
        "resetRefresh": "",
        "lostChanges": "",
        "noAvailable": "",
        "channelTable": "",
        "done": "",
        "name": "",
        "nickname": "",
        "externalId": "",
        "crmRef": "",
        "industry": "",
        "filename": "",
        "content": "",
        "fullscreen": "",
        "exitFullscreen": "",
        "copy": "",
        "copyToClipboard": "",
        "copiedToClipboard": "",
        "copyToSite": "",
        "preview": "",
        "reload": "",
        "unsaved": "",
        "type": "",
        "text": "",
        "tag": "",
        "item": "",
        "title": "",
        "fullTitle": "",
        "editor": "",
        "number": "",
        "top": "",
        "left": "",
        "width": "",
        "height": "",
        "zIndex": "",
        "field": "",
        "key": "",
        "description": "",
        "setup": "",
        "status": "",
        "multiple": "",
        "default": "",
        "general": "",
        "configuration": "",
        "config": "",
        "select": "",
        "addAll": "",
        "removeAll": "",
        "previous": "",
        "next": "",
        "finish": "",
        "nameLonger": "",
        "selectAll": "",
        "deselectAll": "",
        "code": "",
        "continue": "",
        "stay": "",
        "back": "",
        "language": "",
        "languageName": "",
        "languageCode": "",
        "reseller": "",
        "resource": "",
        "override": "",
        "insufficientPrivileges": "",
        "theme": "",
        "distributor": "",
        "domain": "",
        "preregister": "",
        "pleaseConfirm": "",
        "switchTo": "",
        "home": "",
        "lastLoggedIn": "",
        "summary": "",
        "noData": "",
        "vendor": "",
        "model": "",
        "user": "",
        "schedule": "",
        "revert": "",
        "revertAllChanges": "",
        "revertNoChanges": "",
        "channels": "",
        "deployment": "",
        "filter": "",
        "accept": "",
        "api": "",
        "timeZone": "",
        "register": "",
        "caseSensitive": "",
        "optional": "",
        "errorOccurred": "",
        "on": "",
        "off": "",
        "standby": "",
        "idle": "",
        "notSet": "",
        "exportCSV": "",
        "importCSV": "",
        "success": "",
        "uploaded": "",
        "hasUnsavedChangesHeader": "",
        "hasUnsavedChangesMessage": "",
        "defaultErrorMsg": "",
        "notes": "",
        "genericNotes": "",
        "internalNotes": "",
        "whitelabelDomain": "",
        "createNewSchedule": "",
        "addToSchedule": "",
        "readOnly": "",
        "information": "",
        "custom": "",
        "url": "",
        "realm": "",
        "noResult": "",
        "sharingContext": "",
        "sharingSuccess": "",
        "sharingError": "",
        "lastPublished": "",
        "notPublished": "",
        "itemType": "",
        "shareAll": "",
        "shareSubGroup": "",
        "shareSingle": "",
        "debug": ""
    },
    "validation": {
        "characterMaxLength": "",
        "valueHigherThan": "",
        "valueHigherThanOrEqualTo": "",
        "valueLowerOrEqualTo": "",
        "powerOnOverrideFrom": "",
        "powerOnOverrideTo": "",
        "frequency": "",
        "ipPort": "",
        "invalidContextType": ""
    },
    "help": {
        "getSupportTitle": "",
        "getSiteInformationTitle": "",
        "notAvailable": "",
        "openDocumentation": "",
        "getSupportBody": "",
        "notAvailableInHomeScreen": "",
        "documentation": "",
        "contactSupport": "",
        "mobileNavigationTip": ""
    },
    "eula": {
        "updated": {
            "title": "",
            "text": ""
        }
    },
    "welcome": {
        "title": "",
        "languageHeader": "",
        "languageText": "",
        "homeScreenText": "",
        "siteText": ""
    },
    "language": {
        "english": "",
        "norwegian": "",
        "swedish": "",
        "finnish": "",
        "russian": "",
        "italian": "",
        "french": "",
        "german": "",
        "spanish": "",
        "estonian": "",
        "atLeastOne": ""
    },
    "date": {
        "today": "",
        "yesterday": "",
        "daysAgo": "",
        "dateRequired": "",
        "dateNotSet": "",
        "timeRequired": "",
        "timeFormat": "",
        "amPmTimeFormat": "",
        "daily": "",
        "weekly": "",
        "time": "",
        "date": "",
        "timeOfDay": "",
        "monday": "",
        "tuesday": "",
        "wednesday": "",
        "thursday": "",
        "friday": "",
        "saturday": "",
        "sunday": "",
        "startTime": "",
        "endTime": "",
        "weekday": "",
        "endTimeAfter": "",
        "duration": "",
        "inSeconds": ""
    },
    "router": {
        "agents": "",
        "contentConfiguration": "",
        "documents": "",
        "resource": "",
        "resources": "",
        "hospitality": "",
        "dashboard": "",
        "media": "",
        "housekeeping": "",
        "screens": "",
        "tv": "",
        "playlists": "",
        "signage": "",
        "sites": "",
        "customersAndLicenses": "",
        "sitesAndLicenses": "",
        "externalSystems": "",
        "reseller": "",
        "resellers": "",
        "manageResellers": "",
        "distributor": "",
        "distributors": "",
        "domain": "",
        "domains": "",
        "usageReport": "",
        "accessReport": "",
        "resellerUsersAndAccess": "",
        "distributorUsersAndAccess": "",
        "resellerThemes": "",
        "distributorThemes": "",
        "content": "",
        "admin": "",
        "administration": "",
        "system": "",
        "rooms": "",
        "virtualRemoteClients": "",
        "housekeepingRooms": "",
        "locations": "",
        "locationSetup": "",
        "guests": "",
        "guestMessages": "",
        "booking": "",
        "wakeups": "",
        "config": "",
        "screensConfig": "",
        "housekeepingConfig": "",
        "streamAllocatorConfig": "",
        "configuration": "",
        "issues": "",
        "scheduledTasks": "",
        "screen": "",
        "screenConfigurations": "",
        "tvChannels": "",
        "tvScreens": "",
        "tvScreensTheme": "",
        "signageScreens": "",
        "signageOverview": "",
        "signageSchedules": "",
        "signageConfig": "",
        "event": "",
        "events": "",
        "wayfinding": "",
        "wayfindingIcons": "",
        "logos": "",
        "integrationLocations": "",
        "eventConfig": "",
        "schedules": "",
        "schedulemapping": "",
        "installCodes": "",
        "interactivePages": "",
        "templatePages": "",
        "channelTables": "",
        "mediaChannels": "",
        "templates": "",
        "apiTemplates": "",
        "pages": "",
        "imageresources": "",
        "videoresources": "",
        "fontresources": "",
        "contexts": "",
        "sharingContexts": "",
        "accounts": "",
        "access": "",
        "themeImport": "",
        "themes": "",
        "usersAndAccess": "",
        "userAccounts": "",
        "apiAccounts": "",
        "owners": "",
        "areas": "",
        "licenses": "",
        "products": "",
        "roles": "",
        "roleManagement": "",
        "privilegeSets": "",
        "requiredPermissions": "",
        "systemConfig": "",
        "tvSearch": "",
        "pmsConfiguration": "",
        "pmsLogEntries": "",
        "resourceSharing": "",
        "resourcePublishing": "",
        "publishAndShare": "",
        "themePreview": "",
        "watermark": "",
        "castManagement": "",
        "chromecasts": "",
        "chromecastConfig": "",
        "unregisteredCasts": "",
        "activeCastSessions": "",
        "castConfiguration": "",
        "castStatus": "",
        "castStatistics": "",
        "castEventLogs": "",
        "streamAllocator": "",
        "streamManagement": "",
        "streamAllocatorLog": "",
        "deletedContexts": "",
        "duplicateRegisteredClients": "",
        "airlineLogos": "",
        "systemAuditLogs": "",
        "deploymentAuditLogs": "",
        "auditLogConfig": "",
        "resourceCopy": ""
    },
    "appbar": {
        "helpAndSupport": "",
        "notifications": "",
        "languages": "",
        "settings": "",
        "contextSelector": "",
        "availableSites": "",
        "availableCustomers": "",
        "availableThemes": "",
        "searchSites": "",
        "searchCustomers": "",
        "searchThemes": "",
        "availableSharingContexts": "",
        "searchSharingContexts": ""
    },
    "admin": {
        "admin": "",
        "account": "",
        "accountRemoved": "",
        "accountRemoveFailed": "",
        "accountCreated": "",
        "accountSaved": "",
        "accountType": "",
        "accountEnabled": "",
        "accountAccess": "",
        "apiAccount": "",
        "deleteAccount": "",
        "removeAccount": "",
        "removeAccessRights": "",
        "removeAccessRightsInfo": "",
        "disableAPIAccount": "",
        "enableAPIAccount": "",
        "addApiInfo": "",
        "accountApiState": "",
        "accountApiStateWithContextId": "",
        "disableApiAccountTitle": "",
        "disableApiAccountWarning": "",
        "disableApiAccountConfirm": "",
        "deleteUserAccountConfirm": "",
        "deleteUserAccountTitle": "",
        "deleteUserAccountSuccess": "",
        "deleteApiAccountSuccess": "",
        "deleteAccountGenericFailed": "",
        "removedUserAccessSuccess": "",
        "removedUserAccessFailed": "",
        "saveAccountFailed": "",
        "accountDataSaved": "",
        "apiTokenWarning": "",
        "apiTokenTitle": "",
        "customer": "",
        "role": "",
        "privilegeSet": "",
        "accountsWithRole": "",
        "rolesWithProduct": "",
        "site": "",
        "system": "",
        "country": "",
        "area": "",
        "brand": "",
        "screensLimit": "",
        "licensed": "",
        "sitesLimit": "",
        "deleteCustomer": "",
        "deleteSite": "",
        "deleteLegacyWarningText": "",
        "deleteSiteWarningText": "",
        "deleteSiteWarningTextPPDS": "",
        "deleteSiteConfirmationLabel": "",
        "copyResources": "",
        "copyResourcesToSite": "",
        "copyResourcesFromTo": "",
        "importResources": "",
        "importResourcesToSite": "",
        "moveSiteFromTo": "",
        "moveSite": "",
        "provisioned": "",
        "maxSizeProvisioned": "",
        "addReseller": "",
        "context": "",
        "contextType": "",
        "systemRole": "",
        "systemProduct": "",
        "owner": "",
        "license": "",
        "totalLicenses": "",
        "feature": "",
        "changeSite": "",
        "product": "",
        "itemId": "",
        "customId": "",
        "access": "",
        "reseller": "",
        "noResellerAccess": "",
        "noDistributorAccess": "",
        "distributor": "",
        "domain": "",
        "resource": "",
        "accountName": "",
        "displayName": "",
        "email": "",
        "phone": "",
        "formDisabledSSO": "",
        "created": "",
        "password": "",
        "repeatPassword": "",
        "passwordHint": "",
        "addNewAccess": "",
        "newAccount": "",
        "addAccount": "",
        "newApiAccount": "",
        "editApiAccount": "",
        "accessFetchFailed": "",
        "accessUpdateFailed": "",
        "unknownAccess": "",
        "accessToFeatures": "",
        "passwordLength": "",
        "passwordMatch": "",
        "contextAccess": "",
        "noFeaturesAccess": "",
        "removeAccess": "",
        "home": "",
        "read": "",
        "create": "",
        "update": "",
        "delete": "",
        "oneAccessRelation": "",
        "editLicense": "",
        "contractRef": "",
        "activationDate": "",
        "expirationDate": "",
        "noFeaturesSelected": "",
        "availableFeatures": "",
        "expirationAfter": "",
        "newLicense": "",
        "userAccount": "",
        "userAdminError": "",
        "createUserAccount": "",
        "noAvailableUsers": "",
        "selectUser": "",
        "selectUserHelp": "",
        "createNewContext": "",
        "noAvailableContexts": "",
        "selectContext": "",
        "selectContextHelp": "",
        "createNewSite": "",
        "noAvailableSites": "",
        "selectSite": "",
        "selectSiteHelp": "",
        "deploymentInfo": "",
        "managedBy": "",
        "deployedAt": "",
        "locatedIn": "",
        "belongsTo": "",
        "streetAddress": "",
        "postalCode": "",
        "contactName": "",
        "contactEmail": "",
        "contactPhone": "",
        "contactInfo": "",
        "defaultLanguage": "",
        "availableLanguages": "",
        "createAccount": "",
        "createArea": "",
        "createLicense": "",
        "createDistributor": "",
        "createDomain": "",
        "createReseller": "",
        "lastChange": "",
        "createOwner": "",
        "createProduct": "",
        "createSite": "",
        "createCustomer": "",
        "createResource": "",
        "createTheme": "",
        "createSharingContext": "",
        "createRole": "",
        "roleName": "",
        "newRole": "",
        "newPrivilegeSet": "",
        "newDistributor": "",
        "newDomain": "",
        "selectRole": "",
        "noRolesAccess": "",
        "removeRole": "",
        "accessToRoles": "",
        "availableRole": "",
        "limits": "",
        "oldPassword": "",
        "newPassword": "",
        "repeatNewPassword": "",
        "vendorConfig": "",
        "notificationRecipients": "",
        "notificationGroup": "",
        "notificationRecipientsTitle": "",
        "notificationRecipientsInfo": "",
        "emailAlreadyExists": "",
        "usingCustomSupportInfo": "",
        "myAccount": "",
        "userSaveFailedGeneral": "",
        "userSaveFailedEmail": "",
        "userChangeEmailHeader": "",
        "updateEmail": "",
        "userSaveFailedPassword": "",
        "userUpdatePasswordTitle": "",
        "userUpdatePasswordDescription": "",
        "changeYourPassword": "",
        "changePassword": "",
        "share": "",
        "addNewShare": "",
        "addApiAccount": "",
        "addUserAccount": "",
        "resellerLicenseInfoSite": "",
        "resellerLicenseInfoCustomer": "",
        "distributorLicenseInfo": "",
        "newReseller": "",
        "editAccessFor": "",
        "editAccessToReseller": "",
        "distributorAccess": "",
        "domainAccess": "",
        "sharingAccess": "",
        "resellerAccess": "",
        "themeAccess": "",
        "siteAccess": "",
        "deleteDistributorText": "",
        "deleteDistributorFailedText": "",
        "distributorHasProvisionedContext": "",
        "deleteDomainText": "",
        "deleteDomainFailedText": "",
        "domainHasProvisionedContext": "",
        "externalSystemsHeading": "",
        "createPrivilegeSet": "",
        "privilegeSetName": "",
        "systemPrivilegeSet": "",
        "noPrivilegeSetsAdded": "",
        "chooseTypePrivilegeSet": "",
        "forceSSO": "",
        "forceSSOHint": "",
        "idp": "",
        "copyRole": "",
        "roleProductsHint": "",
        "roleProductDisabledHint": "",
        "copyOfName": "",
        "contextPrivilegeSetSelectionTitle": "",
        "contextPrivilegeSelectionDescription": "",
        "deletedContext": "",
        "finalizeDelete": "",
        "undeleteConfirmMsg": "",
        "undelete": "",
        "undeleteSuccess": "",
        "undeleteFailed": "",
        "finalizeDeleteConfirmMsg": "",
        "finalizeDeleteSuccess": "",
        "finalizeDeleteFailed": "",
        "activateSite": "",
        "activateSiteDescription": "",
        "activateSiteConfirmTex": "",
        "siteActivated": "",
        "duplicateRegisteredClients": "",
        "airlineName": "",
        "airlineIataCode": "",
        "airlineLogo": "",
        "newAirlineLogo": "",
        "duplicateIATAError": "",
        "incorrectAirlineLogoDimensions": "",
        "incorrectAirlineLogoFileType": "",
        "airlineLogoSaveFailed": "",
        "auditLogs": "",
        "auditLogBlacklist": "",
        "userId": "",
        "copiedAuthToken": "",
    },
    "hospitality": {
        "hospitality": "",
        "room": "",
        "additionalRooms": "",
        "occupancy": "",
        "guest": "",
        "wakeup": "",
        "guestMessage": "",
        "stay": "",
        "account": "",
        "virtualRemoteClients": {
            "session": "",
            "roomName": "",
            "screenName": "",
            "sessionId": "",
            "remoteClientId": "",
            "created": "",
            "tvOnline": "",
            "vrcOnline": ""
        },
        "config": {
            "chromecastCheckoutLabel": "",
            "chromecastCheckoutExplanation": "",
            "chromecastFacilityIdLabel": "",
            "chromecastTokenLabel": "",
            "serviceMenuCodeLabel": "",
            "nightTimeToggleLabel": "",
            "nightTimeInfo": "",
            "nightTimeFromLabel": "",
            "nightTimeToLabel": ""
        }
    },
    "dashboard": {
        "welcome": "",
        "signedInto": "",
        "occupancyLastNextWeek": "",
        "upcomingWakeups": "",
        "mediaChannels": "",
        "activeInstallCodes": "",
        "newIssuesLastDays": "",
        "newLastDays": "",
        "account": "",
        "apiAccount": "",
        "occupancyPercent": "",
        "sectionHelp": "",
        "filterSites": "",
        "filterCustomers": "",
        "clickOnMap": "",
        "displayingData": "",
        "noFeaturesActivated": "",
        "clearSelection": "",
        "details": "",
        "openExternal": "",
        "openSite": "",
        "openSiteError": "",
        "openTvPreview": "",
        "notManaged": "",
        "vacant": "",
        "managementAccess": "",
        "mergeCCs": "",
        "mapView": "",
        "listView": "",
        "stats": "",
        "map": "",
        "list": "",
        "noReportGenerated": "",
        "buildReport": "",
        "rebuildReport": "",
        "generateStatisticsReport": "",
        "processingData": "",
        "configureFields": "",
        "downloadSelected": "",
        "searchAllFields": "",
        "cluster": "",
        "installedTvs": "",
        "product": "",
        "filter": "",
        "hideDemos": "",
        "hideWhitelabeled": "",
        "hideWatermarked": "",
        "licenseInfo": "",
        "tvLicenses": "",
        "signageLicenses": "",
        "castLicenses": "",
        "products": ""
    },
    "drawer": {
        "userSettings": "",
        "contextButtonHelp": "",
        "mainHelp": ""
    },
    "userMenu": {
        "theme": "",
        "dark": "",
        "notification": "",
        "pinMenu": "",
        "all": "",
        "success": "",
        "info": "",
        "resetHints": "",
        "settings": "",
        "logOut": "",
        "confirmLogout": "",
        "hintsReset": "",
        "timeDisplay": ""
    },
    "reorderList": {
        "dragDropHelp": ""
    },
    "baseTable": {
        "selected": "",
        "enableMultiselect": "",
        "disableMultiselect": "",
        "searchLabel": "",
        "searchTooltip": "",
        "export": ""
    },
    "channelTables": {
        "channelTable": "",
        "radioChannelTable": "",
        "channelTableGroup": "",
        "tableGroup": "",
        "dragDropHere": "",
        "newName": "",
        "availableChannels": "",
        "addAllChannels": "",
        "selectedChannels": "",
        "removeAllChannels": "",
        "unknownChannel": "",
        "noGroups": "",
        "table": "",
        "clickToChange": "",
        "createFirst": "",
        "headerSelectHint": "",
        "dragDropChannels": "",
        "channelTableName": "",
        "new": "",
        "deleteText": "",
        "saveGroupError": "",
        "saveGroupChannelsError": "",
        "saveChannelsError": "",
        "appendToGroupError": "",
        "createGroupError": "",
        "createdGroup": "",
        "deletedGroup": "",
        "channelsSaved": "",
        "validationFailedMsg": ""
    },
    "event": {
        "defaultEventValues": "",
        "eventDeleteSuccess": "",
        "eventDeleteFailed": "",
        "createEvent": "",
        "editEvent": "",
        "deleteEvent": "",
        "eventPrivate": "",
        "startTime": "",
        "startDate": "",
        "endTime": "",
        "endDate": "",
        "eventHeader": "",
        "eventPrivateHint": "",
        "eventInFuture": "",
        "eventEndBeforeStart": "",
        "eventOverlappingDescription": "",
        "eventOverlapping": "",
        "copyEvent": "",
        "copyEventToLocation": "",
        "makeCopyEvent": "",
        "privateTitle": "",
        "noLocations": "",
        "eventsWithNoLocation": "",
        "wayfindingConfiguration": "",
        "saveWayfindingConfig": "",
        "saveWayfindingConfigFailed": "",
        "integrationLocations": "",
        "testConfiguration": "",
        "manageLogos": "",
        "selectLogo": "",
        "replaceLogo": "",
        "removeLogo": "",
        "manageIcons": "",
        "eventRetention": "",
        "eventRetentionDescription": "",
        "maxEventDuration": "",
        "maxEventDurationDescription": "",
        "applyLogoByHost": "",
        "logoMappingDesc": "",
        "hostMapping": "",
        "addHostMapping": "",
        "failedToSaveMapping": "",
        "mappingAlreadyExists": ""
    },
    "tvMenu": {
        "tvMenuPage": "",
        "menuPage": "",
        "subtitle": "",
        "audioLanguage": "",
        "subtitleLanguage": "",
        "epgMenu": "",
        "offlineMenu": "",
        "externalInput": "",
        "wakeupMenu": "",
        "defaultWakeupAlarmMenu": "",
        "powerOnMenu": "",
        "messageNotificationMenu": "",
        "messageDisplayMenu": ""
    },
    "documents": {
        "datasheet": {
            "contextMenu": {
                "columnMenuTooltip": "",
                "rowMenuTooltip": "",
                "insertColumnBefore": "",
                "insertColumnAfter": "",
                "pasteCopiedColumnBefore": "",
                "pasteCopiedColumnAfter": "",
                "insertRowAbove": "",
                "insertRowBelow": "",
                "pasteCopiedRowAbove": "",
                "pasteCopiedRowBelow": "",
                "moveColumnLeft": "",
                "moveColumnRight": "",
                "moveRowUp": "",
                "moveRowDown": "",
                "moveRowTop": "",
                "moveRowBottom": "",
                "changeType": "",
                "setPermissions": "",
                "manageEnums": ""
            },
            "type": "",
            "enumType": "",
            "mapCoordType": "",
            "textColumn": "",
            "imageColumn": "",
            "mapColumn": "",
            "enumColumn": "",
            "mapCoordColumn": "",
            "columnName": "",
            "copyColumn": "",
            "textCellValidationErrorMsg": ""
        },
        "page": "",
        "document": "",
        "selectPage": "",
        "dataSheetEnumEditor": "",
        "enum": ""
    },
    "resources": {
        "resource": "",
        "font": "",
        "image": "",
        "video": "",
        "document": "",
        "tables": "",
        "playlist": "",
        "template": "",
        "compositeMenuPages": "",
        "logo": "",
        "wayfindingIcon": "",
        "uploadedYet": "",
        "selected": "",
        "copyUrl": "",
        "copyResources": "",
        "moveResources": "",
        "moveResource": "",
        "copying": "",
        "copyToFolderSuccess": "",
        "copyToFolderFailure": "",
        "copyFinishedWithError": "",
        "copySuccessDescription": "",
        "circularReferenceErrorMsg": "",
        "failedToCopy": "",
        "downloadFile": "",
        "downloadingName": "",
        "downloadFailed": "",
        "selectImage": "",
        "selectFont": "",
        "selectVideo": "",
        "newPlaylist": "",
        "upload": "",
        "uploadMedia": "",
        "uploadResource": "",
        "uploadFileType": "",
        "uploadSuccessful": "",
        "uploadError": "",
        "cancelUploadConfirmTitle": "",
        "cancelUploadConfirmMessage": "",
        "replaceResource": "",
        "replaceResourceTitle": "",
        "replaceResourceDescription": "",
        "resourceReplaced": "",
        "resourceReplacedFailed": "",
        "editImageBefore": "",
        "uploaderTitle": "",
        "unsupportedFile": "",
        "unsupportedFileSize": "",
        "unsupportedFileNameAndSize": "",
        "exceedingMaxPdfPages": "",
        "wrongType": "",
        "quickBrownFox": "",
        "addOneOrMore": "",
        "addOneOrMoreExcludeVideos": "",
        "addType": "",
        "pressUpload": "",
        "rotateLeft": "",
        "rotateRight": "",
        "crop": "",
        "addResources": "",
        "uploadSuccessfulHover": "",
        "addDocument": "",
        "addTemplate": "",
        "potentialUsageFound": "",
        "potentialUsageFoundInItems": "",
        "folderNotEmpty": "",
        "deleteFolderWithUsage": "",
        "deleteFolderWarning": "",
        "deleteFolderConfirm": "",
        "newPlaylistFromPdf": "",
        "addDatasheet": "",
        "displayDuration": "",
        "pdfUploadFolderError": "",
        "tag": "",
        "addTag": "",
        "transcodeVideo4K": "",
        "transcodeVideoHD": ""
    },
    "mediaChannels": {
        "mediaChannels": "",
        "mediaChannel": "",
        "globalMediaChannels": "",
        "globalMapping": "",
        "address": "",
        "port": "",
        "serviceId": "",
        "frequency": "",
        "modulation": "",
        "symbolRate": "",
        "bandwidth": "",
        "satelliteId": "",
        "polarization": "",
        "programNumber": "",
        "majorNumber": "",
        "minorNumber": "",
        "type": "",
        "audioOnly": "",
        "createChannel": "",
        "save": "",
        "saveTooltip": "",
        "clickToChange": "",
        "reset": "",
        "types": "",
        "webRadioSearch": "",
        "unknownBitrate": "",
        "placeholderChannelName": "",
        "updateFailed": "",
        "deleteFailed": "",
        "createFailed": "",
        "updateSuccess": "",
        "deleteSuccess": "",
        "createSuccess": "",
        "validateFailed": ""
    },
    "templates": {
        "templateErrorMessages": {
            "deserializationErrorMsg": ""
        },
        "dataFetchErrorMessages": {
            "unknownHost": "",
            "protocolError": "",
            "unknownError": "",
            "code300ErrorMsg": "",
            "code400ErrorMsg": "",
            "code401ErrorMsg": "",
            "code403ErrorMsg": "",
            "code404ErrorMsg": "",
            "code406ErrorMsg": "",
            "code415ErrorMsg": "",
            "code500ErrorMsg": "",
            "apiTimeout": ""
        },
        "serializationErrors": {
            "jsonParseError": ""
        },
        "internalErrors": {
            "code500ErrorMsg": ""
        },
        "noDataYet": "",
        "noDataFoundYet": "",
        "save": "",
        "setATest": "",
        "infoUrlDefault": "",
        "infoUrlTooltip": "",
        "error": "",
        "httpMethodRequired": "",
        "nameRequired": "",
        "paramType": "",
        "addItem": "",
        "preview": "",
        "template": "",
        "templateRequired": "",
        "reset": "",
        "source": "",
        "sourceRequired": "",
        "fixedParameters": "",
        "setAFixed": "",
        "templateExample": "",
        "dataExample": "",
        "httpMethod": "",
        "markup": "",
        "sourceError": "",
        "selectConfigure": "",
        "failedSource": "",
        "failedSourceFetch": "",
        "templateSource": "",
        "rootName": "",
        "apiConnector": "",
        "createTemplate": "",
        "createWithoutClosing": "",
        "saveWithoutClosing": "",
        "fetchApiData": "",
        "cacheForSeconds": "",
        "cacheForSecondsError": "",
        "defaultTemplateErrorExplanation": ""
    },
    "configSchedules": {
        "defaultConfig": "",
        "dailyOverrides": "",
        "weeklyOverrides": "",
        "editTimeslot": "",
        "deleteTimeslot": "",
        "dragStopTime": "",
        "defaultContentExplanation": "",
        "editScheduleName": "",
        "tabDefault": "",
        "tabDaily": "",
        "tabWeekly": "",
        "tabCalendar": "",
        "addTimeslotHelpText": ""
    },
    "scheduleMapping": {
        "createNew": "",
        "rename": "",
        "created": "",
        "updated": "",
        "currentSchedule": "",
        "deleteHeading": "",
        "deleteText": "",
        "addMapping": "",
        "screens": "",
        "screensHeadingInfo": "",
        "moveTo": "",
        "rulesHeading": "",
        "rulesDescription": "",
        "newRule": "",
        "defaultSchedule": "",
        "screensMoved": "",
        "createFailed": "",
        "updateFailed": ""
    },
    "schedulePlans": {
        "editor": {
            "notInUse": "",
            "newForEach": "",
            "singleNew": "",
            "copyExisting": "",
            "removeWhenActive": ""
        },
        "screenEditor": {
            "newScheduleForEach": "",
            "individualSelectionDescription": ""
        },
        "type": "",
        "setActive": "",
        "setActiveConfirmTitle": "",
        "disabledDeleteForDefault": "",
        "setActiveConfirmText": "",
        "setActiveSuccess": "",
        "setActiveFailed": ""
    },
    "screens": {
        "screensMissingSchedulesTitle": "",
        "screensMissingSchedulesText": "",
        "screenStatus": "",
        "tvScreenStatus": "",
        "signageDisplayStatus": "",
        "screens": "",
        "extendedId": "",
        "livePreview": "",
        "displays": "",
        "onlineScreens": "",
        "screenSetup": "",
        "screenSetupCreated": "",
        "screenSetupUpdated": "",
        "defaultScreenConfig": "",
        "tvScreen": "",
        "signageScreen": "",
        "tvType": "",
        "clientType": "",
        "tvSoftware": "",
        "editName": "",
        "tvClient": "",
        "unknownSetup": "",
        "createFirstSchedule": "",
        "schedule": "",
        "copyFromConfig": "",
        "quicklyCopyConfig": "",
        "mainMenu": "",
        "welcomeMenu": "",
        "channelMenu": "",
        "radioChannelMenu": "",
        "startVolume": "",
        "maxVolume": "",
        "autoPowerOffHrs": "",
        "enableAutoPowerOff": "",
        "audioLanguageMenu": "",
        "subtitleLanguageMenu": "",
        "externalInputMenu": "",
        "noScreenSetups": "",
        "selectSlotType": "",
        "scheduleEditorCalendarHint": "",
        "editSlot": "",
        "newSlot": "",
        "createNewConfig": "",
        "timeslotOverlap": "",
        "volume": "",
        "durationRequired": "",
        "durationLongerThan": "",
        "messageInJson": "",
        "validJson": "",
        "sendMessageToScreen": "",
        "sendMessage": "",
        "updateStatus": "",
        "pushConfig": "",
        "pushContent": "",
        "upgradeAgentSoftware": "",
        "upgradeAgentSoftwareNotification": "",
        "poweredOn": "",
        "poweredOff": "",
        "powerOn": "",
        "powerOff": "",
        "reboot": "",
        "rebootRequired": "",
        "jvmUptime": "",
        "rebootRequested": "",
        "appUpdateRequested": "",
        "setVolume": "",
        "displayMessage": "",
        "displayAddress": "",
        "displayName": "",
        "resetSoftAP": "",
        "displayPage": "",
        "tuneChannel": "",
        "enableRemoteDebug": "",
        "enableOnscreenDebug": "",
        "disableOnscreenDebug": "",
        "reAuthenticate": "",
        "reAuthenticateMsg": "",
        "getScreenshot": "",
        "sendPushMessage": "",
        "openClientPreview": "",
        "sendMgmntMessage": "",
        "moveToLocation": "",
        "changeSchedule": "",
        "selectSchedule": "",
        "manage": "",
        "moveScreens": "",
        "deleteScreens": "",
        "setContentScheduleForScreenExistingNameMsg": "",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgPreface": "",
        "setContentScheduleForSchedulePlansForScreenExistingNameMsgIndividualMsg": "",
        "move": "",
        "name": "",
        "ip": "",
        "mac": "",
        "rotation": "",
        "deviceType": "",
        "appVersion": "",
        "firmwareVersion": "",
        "osVersion": "",
        "details": "",
        "occupied": "",
        "online": "",
        "offline": "",
        "power": "",
        "state": "",
        "public": "",
        "clientId": "",
        "setRotation": "",
        "id": "",
        "locationTitle": "",
        "previewOnlyOne": "",
        "screensMoved": "",
        "installCode": "",
        "createInstallCode": "",
        "code": "",
        "createSchedule": "",
        "created": "",
        "createdBy": "",
        "expiryDate": "",
        "configInUse": "",
        "configInUseDescription": "",
        "scheduleInUse": "",
        "scheduleInUseDescription": "",
        "configTvSettingsHeading": "",
        "configChannelsHeading": "",
        "configMenusHeading": "",
        "addConfiguration": "",
        "groupBy": "",
        "setSchedule": "",
        "upgradeApp": "",
        "helpText": "",
        "newTvScreen": "",
        "clientIdRequired": "",
        "pickLocation": "",
        "noLocation": "",
        "roomRequired": "",
        "roomSearchPlaceholder": "",
        "tvPreRegisteredOn": "",
        "tvRegisteredOn": "",
        "tvRegisteredCloseText": "",
        "pickContext": "",
        "noContext": "",
        "contextRequired": "",
        "clientToken": "",
        "clientTokenRequired": "",
        "couldNotFindItemToEdit": "",
        "uptime": "",
        "memoryUsage": "",
        "requestScreenshot": "",
        "requestScreenshotOffline": "",
        "requestScreenshotPoweredOff": "",
        "requestNewScreenshot": "",
        "requestUpdate": "",
        "serial": "",
        "firmware": "",
        "resolution": "",
        "system": "",
        "publicIp": "",
        "softAP": "",
        "currentConfig": "",
        "rcAddr": "",
        "network": "",
        "timeOnDevice": "",
        "openPreview": "",
        "screenInfo": "",
        "reloadAllMediaTitle": "",
        "reloadAllMediaDescription": "",
        "moveToScheduleTitle": "",
        "changeScreenScheduleDescription": "",
        "changeScreenScheduleSuccess": "",
        "moveToScheduleDescription": "",
        "moveToScheduleSuccess": "",
        "moveToSite": "",
        "moveScreenToSiteMsg": "",
        "moveScreenToSiteSuccess": "",
        "moveScreenToSiteNotAllowed": "",
        "moveScreenToSiteNotAllowedNoLicense": "",
        "moveScreenToSiteNotAllowedLimitReached": "",
        "createScreenLimitReachedMsg": "",
        "successfullyAuthenticated": "",
        "canNotReAuthenticate": "",
        "appMode": "",
        "forceUpdate": "",
        "services": "",
        "upgradablePackages": "",
        "authCodeNotFound": "",
        "lastReboot": "",
        "storage": "",
        "authCreateNewSchedule": "",
        "authUseExistingSchedule": "",
        "registrationCode": "",
        "registrationCodeLength": "",
        "startApp": ""
    },
    "booking": {
        "booking": "",
        "numberOfBookings": "",
        "book": "",
        "bookNights": "",
        "night": "",
        "stayFromTo": "",
        "stayCheckedIn": "",
        "calendar": "",
        "availabilityError": "",
        "bookingsForDate": "",
        "noBookingsForDate": "",
        "failedToRetrieve": "",
        "timeslotTaken": ""
    },
    "guests": {
        "anonymous": "",
        "language": "",
        "unspecified": "",
        "pickRoom": "",
        "checkInDate": "",
        "checkInTime": "",
        "checkOutDate": "",
        "checkOutTime": "",
        "newCheckIn": "",
        "checkIn": "",
        "checkInGuest": "",
        "editGuest": "",
        "checkOut": "",
        "getBill": "",
        "guestCheckOut": "",
        "billItemName": "",
        "billItemAmountMax4Chars": "",
        "billItemCurrencyMax3Chars": "",
        "billDataError": "",
        "confirmCheckout": "",
        "editStay": "",
        "editBooking": "",
        "newBooking": "",
        "checkInBeforeOut": "",
        "noGuests": "",
        "overrideTvConfig": "",
        "setTvConfigConfirm": "",
        "clearTvConfigConfirm": "",
        "setTvConfigSuccess": "",
        "sendMessage": "",
        "guestsNumber": "",
        "person": "",
        "forceCheckOutTitle": "",
        "forceCheckOutMessage": ""
    },
    "guestMessages": {
        "to": "",
        "unreadMessages": "",
        "subject": "",
        "create": "",
        "view": "",
        "createdHeader": "",
        "readHeader": "",
        "displayedHeader": "",
        "senderName": "",
        "instant": "",
        "messageTemplate": "",
        "useTemplate": ""
    },
    "wakeups": {
        "roomAccount": "",
        "tvMenu": "",
        "customTvMenu": "",
        "wakeupInFuture": "",
        "wakeupExists": "",
        "create": "",
        "daily": "",
        "status": "",
        "delivered": "",
        "notDelivered": "",
        "displayed": "",
        "notDisplayed": "",
        "confirmed": "",
        "notConfirmed": "",
        "editWakeup": "",
        "deliveredWakeup": "",
        "newWakeup": ""
    },
    "scheduledTasks": {
        "dailyNewSheets": "",
        "roomType": "",
        "allTypes": "",
        "roomTypeEmpty": "",
        "when": "",
        "createTask": "",
        "specificRooms": ""
    },
    "issues": {
        "maintenanceType": "",
        "changeStatus": "",
        "setIssueTo": "",
        "editIssue": "",
        "created": "",
        "closed": "",
        "issueSetTo": "",
        "issuesSetTo": "",
        "newIssue": "",
        "editingIssues": "",
        "issueUpdated": "",
        "issueAdded": "",
        "urgent": "",
        "flagAsClosed": "",
        "issueSetup": ""
    },
    "locations": {
        "location": "",
        "createRooms": "",
        "createLocations": "",
        "occupation": "",
        "locatedIn": "",
        "locationSetTo": "",
        "locationsSetTo": "",
        "locationSetup": "",
        "category": "",
        "setRoomTo": "",
        "editRoom": "",
        "changeStatus": "",
        "changeCategory": "",
        "changeLocation": "",
        "addTag": "",
        "removeTag": "",
        "addIssue": "",
        "tagRemoved": "",
        "tagAdded": "",
        "categoryChange": "",
        "newRooms": "",
        "newLocations": "",
        "roomNumber": "",
        "locationNumber": "",
        "startRange": "",
        "endRange": "",
        "parentLocations": "",
        "startRangeRequired": "",
        "startRangeLower": "",
        "endRangeRequired": "",
        "endRangeHigher": "",
        "change": "",
        "newLocation": "",
        "changeLocationOf": "",
        "locationChangeSuccess": "",
        "roomTag": "",
        "newTag": "",
        "newStatus": "",
        "issueDeletedFrom": "",
        "locationCategory": "",
        "roomCategory": "",
        "newCategory": "",
        "bedroomTv": "",
        "meetingRoomTV": ""
    },
    "serviceConfig": {
        "saveConfig": "",
        "disableDailyRebootWarning": "",
        "enableDefaultSubtitle": "",
        "enableDefaultSubtitleTooltip": "",
        "forceTvMode": "",
        "forceTvModeTooltip": "",
        "rebootTime": "",
        "rebootTimeInvalid": "",
        "instantContentPush": "",
        "instantContentPushDescription": "",
        "useInternalURL": ""
    },
    "login": {
        "terms": "",
        "resetPassword": "",
        "language": "",
        "username": "",
        "password": "",
        "logIn": "",
        "cloudManagementPortal": "",
        "enterUsername": "",
        "enterEmail": "",
        "invalidEmail": "",
        "resetMessage": "",
        "resetFailedMessage": "",
        "setNewPassword": "",
        "redirectToLoginPageTitle": "",
        "redirectToLoginPageText": ""
    },
    "latLng": {
        "openMap": "",
        "lat": "",
        "lng": "",
        "selectPosition": "",
        "addressHint": "",
        "addressWarning": "",
        "searchFieldLabel": "",
        "address": "",
        "locationAddressErrorMessage": "",
        "maxLength": ""
    },
    "folder": {
        "newResources": "",
        "uploadFile": "",
        "folder": "",
        "noFolders": "",
        "doubleClickSelect": "",
        "newFolder": "",
        "copy": "",
        "cut": "",
        "paste": "",
        "selected": "",
        "deleting": "",
        "root": "",
        "home": "",
        "emptyFolder": "",
        "createFolder": "",
        "deleteFolder": "",
        "moveToFolder": "",
        "copyToFolder": "",
        "editFolder": "",
        "imageSize": "",
        "unknownImageSize": ""
    },
    "auth": {
        "loginNotAvailable": "",
        "wrongUserPass": "",
        "responseError": "",
        "noCredentials": "",
        "errorMirageSession": "",
        "errorMirageSessionPPDS": "",
        "invalidMirageSession": "",
        "invalidMirageSessionPPDS": "",
        "unknownMirageSession": ""
    },
    "accessPanel": {
        "resellerTitle": "",
        "resellerDescription": "",
        "distributorTitle": "",
        "distributorDescription": "",
        "domainTitle": "",
        "domainDescription": "",
        "resellerAccess": "",
        "sharingContextsAccess": "",
        "deploymentAccess": "",
        "themeAccess": "",
        "distributorAccess": "",
        "domainAccess": "",
        "accessControl": "",
        "accessControlDescription": "",
        "accessError": ""
    },
    "theme": {
        "theme": "",
        "importWindowHeader": "",
        "editThemeImage": "",
        "imagePlaceholder": "",
        "editThemeCard": "",
        "publishSnapshot": "",
        "publishDescription": "",
        "publishSharingDescription": "",
        "lastPublished": "",
        "ThemeNotPublished": "",
        "resourceStats": "",
        "editorInfo": "",
        "noFeaturesActivated": "",
        "importInfo": "",
        "importInfoTheme": "",
        "import": "",
        "folderExists": "",
        "importLoader": "",
        "importSuccess": "",
        "importSuccessAsync": "",
        "importError": "",
        "previewConfiguration": "",
        "savePreviewError": "",
        "launchPreview": "",
        "noConfigurationFound": "",
        "themePublished": "",
        "published": "",
        "themePublishedError": "",
        "themeSharingSuccess": "",
        "themeSharingError": "",
        "themeEmptyError": "",
        "fetchError": "",
        "editTitle": "",
        "editDescription": "",
        "shareWith": "",
        "shareAll": "",
        "shareSubGroup": "",
        "shareSingle": "",
        "successfullyImported": "",
        "successfullyImportedAsync": "",
        "importFailed": "",
        "newTheme": "",
        "preview": "",
        "previewNotAvailable": "",
        "previewInfo": "",
        "loadThemesError": "",
        "themeControl": "",
        "themeControlDescription": "",
        "enablePublishInfo": "",
        "enableSharing": "",
        "panel": {
            "title": "",
            "description": ""
        },
        "themeName": ""
    },
    "sharingContext": {
        "type": "",
        "rootFolderPrefix": "",
        "new": "",
        "deleteConfirmTitle": "",
        "deleteConfirmMsg": "",
        "editorInfo": "",
        "deleteWarning": "",
        "deleteConfirm": ""
    },
    "signage": {
        "signage": "",
        "schedule": "",
        "addTimeslot": "",
        "contentSchedule": "",
        "noSchedules": "",
        "mergeDefaultTitle": "",
        "mergeDefaultConfirm": "",
        "mergeDefaultTimeslotTitle": "",
        "startEndTimeInvalidMsg": "",
        "durationInvalidMsg": "",
        "manageDefaultContentBtn": "",
        "defaultContentDescription": "",
        "upcoming": "",
        "addNewDailyRecurring": "",
        "addNewWeekly": "",
        "addNewCalendarTimeslotFor": "",
        "editDefaultContent": "",
        "editDailyRecurringTimeslot": "",
        "editWeeklyRecurringTimeslot": "",
        "editCalendarTimeslotFor": "",
        "contentListTitle": "",
        "durationLabel": "",
        "deleteContentListEntry": "",
        "interactivityDisplayDelayLabel": "",
        "interactivityDisplayDelay": ""
    },
    "pms": {
        "pmsHeader": "",
        "vendor": "",
        "accessToken": "",
        "propertyCode": "",
        "tigerAccessTokenHint": "",
        "siteName": "",
        "tigerSiteNameHint": "",
        "directoryId": "",
        "soapApiUrl": "",
        "soapApiUrlHint": "",
        "linkCheckerIn": "",
        "linkCheckerOut": "",
        "enableDailyReset": "",
        "pmsLink": "",
        "lastMsgReceived": "",
        "hour": "",
        "minute": "",
        "forceSync": "",
        "forceSyncSuccess": "",
        "forceSyncError": "",
        "pmsGetVendorError": "",
        "ip": "",
        "port": "",
        "useChecksum": "",
        "pmsVendor": "",
        "defaultTimeoutSeconds": "",
        "charSet": ""
    },
    "cast": {
        "config": {
            "adminConfigHeader": "",
            "authToken": "",
            "authTokenHint": "",
            "networks": {
                "createNew": "",
                "addNetworkWithType": "",
                "addChromecastNetwork": "",
                "useExisting": "",
                "interfaceName": "",
                "parentInterface": "",
                "networkPrefixLength": "",
                "vlanTag": "",
                "chromecastNetworks": "",
                "clientNetworks": "",
                "proxyIp": "",
                "vlanId": "",
                "interface": "",
                "parentIface": "",
                "ipAddressHint": "",
                "ipPlaceholder": "",
                "networkPrefixPlaceholder": "",
                "vlanTagPlaceholder": ""
            },
            "hostPlaceholder": "",
            "networkProxyHost": "",
            "networkProxyHostHint": "",
            "proxy": "",
            "chromecastProxy": "",
            "chromecastNetworkType": "",
            "chromecastProxyServerNetworkConfig": "",
            "chromecastProxyServerStatus": "",
            "networkInterfaceStatus": "",
            "chromecastProxyServerSignageStatus": "",
            "proxyIsInternetGateway": "",
            "internetInterface": "",
            "isGatewayForChromecasts": "",
            "isDHCPForChromecasts": "",
            "isGatewayForClients": "",
            "isDHCPForClients": "",
            "chromecastProxyServerForcedReturnPath": "",
            "webInterfaceForceReturnPath": "",
            "webInterfaceForceReturnPathHint": "",
            "forcedReturnPathGateway": "",
            "forcedReturnPathGatewayPlaceholder": "",
            "forcedReturnPathGatewayHint": "",
            "advancedNetworking": "",
            "proxyServerConfig": "",
            "chromecastConfig": "",
            "pairingCodeConfig": "",
            "clientRegistrationHost": "",
            "clientRegistrationHostHint": "",
            "clientRegistrationHostPlaceholder": "",
            "guestWifiName": "",
            "guestWifiNameHint": "",
            "guestWiFiPassword": "",
            "guestWiFiPasswordHint": "",
            "enableSignageIntegration": "",
            "globalChromecastName": "",
            "globalChromecastNameHint": "",
            "globalChromecastNamePlaceholder": "",
            "timeZone": "",
            "timeZoneHint": "",
            "locale": "",
            "localeHint": "",
            "pairingCodeSet": "",
            "pairingCodeSetHint": "",
            "pairingCodeIsCaseSensitive": "",
            "pairingCodeLength": "",
            "pairingCodeTimeoutMinutes": "",
            "pairingCodeTimeoutMinutesHint": "",
            "pairTimeoutHours": "",
            "pairTimeoutHoursHint": "",
            "useCustomTimeoutTimePerDayInstead": "",
            "networkProxySaveError": "",
            "saveError": "",
            "saveSuccess": "",
            "connectionSuccess": "",
            "connectionFailure": "",
            "interfaceFetchFailure": "",
            "reloadTooltipDefault": "",
            "resetTooltip": "",
            "routingOfClientChromecastSessions": "",
            "routingOfClientNetworkMulticast": "",
            "supportNetflix": "",
            "failedToLoadMsg": ""
        },
        "eventLogs": {
            "typeDescription": "",
            "time": "",
            "eventName": "",
            "castUdn": "",
            "castName": "",
            "clientVid": "",
            "additionalData": ""
        },
        "response": {
            "chromecastFetchFailed": "",
            "unregisteredCastFetchFailed": "",
            "activeSessionFetchFailed": "",
            "statusFetchFailed": "",
            "statisticsFetchFailed": "",
            "eventLogFetchFailed": "",
            "chromecastProxyConfigFetchFailed": "",
            "networksFetchFailed": "",
            "advancedNetworkingFetchFailed": ""
        },
        "status": {
            "reassociationBtnText": "",
            "reassociationTitle": "",
            "reassociationWarning": "",
            "reassociationMsg": "",
            "reassociationSuccess": "",
            "reassociationFailed": "",
            "version": "",
            "serverId": "",
            "licensedFor": "",
            "status": "",
            "eno1": "",
            "wip58s0": "",
            "clientId": "",
            "clientToken": "",
            "installerToken": "",
            "installerCode": "",
            "connected": "",
            "authenticated": ""
        },
        "chromecasts": {
            "info": {
                "castConfig": "",
                "deviceAlias": "",
                "deviceName": "",
                "uptime": "",
                "isConfigurable": "",
                "wifiMac": "",
                "ssid": ""
            },
            "refreshStatusBtn": "",
            "refreshStatusSuccess": "",
            "refreshStatusFailed": "",
            "saveSuccess": "",
            "saveFailed": "",
            "unregister": "",
            "showConfig": "",
            "unregisterConfirmTitle": "",
            "unregisterConfirmMsg": "",
            "unregisterSuccess": "",
            "unregisterFailed": "",
            "updateStatusSuccess": "",
            "updateStatusFailed": "",
            "showNameSuccess": "",
            "showNameFailed": "",
            "rebootSuccess": "",
            "rebootFailed": "",
            "deletePairingsConfirmTitle": "",
            "deletePairingsConfirmMsg": "",
            "deletePairingsSuccess": "",
            "deletePairingsFailed": "",
            "forceStartCastingFailed": "",
            "chromecastPairingCode": ""
        },
        "unregisteredCasts": {
            "title": "",
            "register": "",
            "registerChromecast": "",
            "registerSuccess": "",
            "genericFailure": "",
            "limitReachedFailure": "",
            "deleteCastConfirmTitle": "",
            "deleteCastConfirmMsg": "",
            "deleteCastFailed": "",
            "deleteCastSuccess": ""
        },
        "statistics": {
            "fetch": "",
            "usageInHours": "",
            "noOfPairingsPerDay": "",
            "appUsageInHours": "",
            "consecutiveHourFilterLabel": "",
            "consecutiveHourFilterHint": "",
            "downloadVisiblePeriod": "",
            "exportPairings": "",
            "exportAppUsage": ""
        },
        "activeSessions": {
            "title": "",
            "pinOrUnpin": "",
            "pinned": "",
            "pinSuccess": "",
            "unpinSuccess": "",
            "multiplePinUnpinSuccess": "",
            "pinFailed": "",
            "disconnect": "",
            "disconnectConfirmTitle": "",
            "disconnectConfirmMsg": "",
            "disconnectSuccess": "",
            "disconnectFailed": ""
        },
        "type": "",
        "runningApp": "",
        "available": "",
        "connection": "",
        "mac": "",
        "macAddress": "",
        "paired": "",
        "udn": "",
        "signalNoiseLevel": "",
        "client": "",
        "configurable": "",
        "connected": "",
        "createdAt": "",
        "actions": {
            "updateStatus": "",
            "deletePairings": "",
            "showName": "",
            "stopCurrentSession": "",
            "generatePairingCode": "",
            "exportSelectedToCsv": "",
            "forceStartCasting": ""
        },
        "networkProxySaveConfirmationMsg": "",
        "castServiceOfflineMsg": ""
    },
    "streamManagement": {
        "allocatedStreams": {
            "device": "",
            "allocatedStream": "",
            "itemType": "",
            "addDevice": "",
            "editDevice": "",
            "deallocateStreamTitle": "",
            "deallocateStreamConfirmMsg": "",
            "editAllocatedStream": "",
            "mediaUrl": "",
            "streamAllocatorConfig": "",
            "deallocate": "",
            "deleteDeviceMsg": "",
            "deleteDeviceConfirm": "",
            "chromecastIntegrationDescription": ""
        },
        "config": {
            "minTimeoutWarn": ""
        },
        "allocationLogEntries": {
            "eventType": {
                "allocationFailure": ""
            },
            "eventReason": {
                "noAvailableDevices": ""
            },
            "title": "",
            "itemType": ""
        }
    },
    "jobs": {
        "jobList": {
            "title": ""
        },
        "startMsgs": {
            "imageUpload": "",
            "fontUpload": "",
            "themeImport": "",
            "logoUpload": ""
        },
        "successMsgs": {
            "imageUpload": "",
            "fontUpload": "",
            "logoUpload": ""
        },
        "failedMsgs": {
            "imageUpload": "",
            "fontUpload": "",
            "logoUpload": ""
        }
    },
    "content": {
        "config": {
            "resourceserver": "",
            "resourcesyncservice": "",
            "networkproxy": "",
            "resourceServerPort": "",
            "resourceServerPortHint": "",
            "resourceServerFileLocation": "",
            "resourceServerFileLocationHint": "",
            "resourceSyncServiceFileLocation": "",
            "resourceSyncServiceFileLocationHint": "",
            "enableChangingURL": "",
            "saveConfig": "",
            "saveConfigWarn": "",
            "saveSuccess": "",
            "configSaveError": "",
            "rangeError": "",
            "checkConfig": "",
            "configurationOK": "",
            "errorWithConfiguration": "",
            "missingConfig": "",
            "changeURLWarn": "",
            "tables": "",
            "cellMaxLength": "",
            "cellMaxLengthHint": ""
        }
    }
}

