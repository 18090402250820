export default {
    data() {
        return {
            unregisterRouterGuard: null
        }
    },
    watch: {
        show(show) {
            if (show) {
                if (this.unregisterRouterGuard) {
                    this.unregisterRouterGuard();
                }
                this.unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
                    if (this.unregisterRouterGuard) {
                        this.unregisterRouterGuard();
                        this.unregisterRouterGuard = null;
                    }
                    this.$nextTick(() => {
                        this.show = false;
                    });
                    next(false);
                });
            } else {
                if(this.unregisterRouterGuard) {
                    this.unregisterRouterGuard();
                    this.unregisterRouterGuard = null;
                }
            }
        },
        beforeDestroy() {
            if(this.unregisterRouterGuard) {
                this.unregisterRouterGuard();
                this.unregisterRouterGuard = null;
            }
        }
    }
};
