// TODO (AHS): When would we actually want to lowercase characters by force?
//             Do we actually need this?
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.capitalizeFirst = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.titlecase = function () {
    const split = this.split(' ');
    let titlecase = '';

    for (let i = 0; i < split.length; i++) {
        if (i === split.length - 1) {
            titlecase += split[i].capitalizeFirst();
        } else {
            titlecase += split[i].capitalizeFirst() + ' ';
        }
    }

    return titlecase;
};
